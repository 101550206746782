import MuiCheckbox, { CheckboxProps as MuiCheckboxProps } from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel, { FormControlLabelProps } from '@material-ui/core/FormControlLabel';
import { connect, getIn } from 'formik';
import React, {
    Component,
    ComponentType,
    createContext,
    HTMLAttributes,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

const CheckboxGroupContext = createContext<{
    name: string;
    state: string[];
    setState: (state: string[]) => void;
    isChecked: (value: string) => boolean;
} | undefined>(undefined);

const useCheckboxGroup = () => {
    const context = useContext(CheckboxGroupContext);

    if (!context) {
        throw new Error('Can only use CheckboxGroup.Box as a child of CheckboxGroup component');
    }

    return context;
};

interface ICheckboxGroupProps extends HTMLAttributes<HTMLElement> {
    name: string;
    component?: React.ElementType;
    validate?: (x: any[]) => (string | undefined);
    [key: string]: any;
}

type CheckboxGroupBoxProps =
    & Partial<Omit<MuiCheckboxProps, 'name' | 'checked' | 'value'>>
    & {
        value: any;
        label?: string;
        labelPlacement?: FormControlLabelProps['labelPlacement'];
        fast?: boolean;
    };

type CheckboxGroupType =
    & ComponentType<ICheckboxGroupProps>
    & { Box: ComponentType<CheckboxGroupBoxProps> };

const CheckboxGroup = connect<PropsWithChildren<ICheckboxGroupProps>, any>((props) => {
    const {
        name,
        formik,
        children,
        component = 'div',
        validate,
        ...rest
    } = props;

    const formikState = getIn(formik.values, name);

    const state = useMemo(() => ({
        name,
        state: formikState ?? [],
        setState: (state) => formik.setFieldValue(name, state),
        isChecked: (value) => ((formikState as string[]) ?? []).includes(value),
    }), [name, getIn, formikState]);

    const Container = component;

    useEffect(() => {
        if (!validate) { return; }

        formik.registerField(name, new Component({ validate }));
        return () => formik.unregisterField(name);
    }, [validate]);

    return (
        <CheckboxGroupContext.Provider value={state}>
            <Container {...rest}>
                {children}
            </Container>
        </CheckboxGroupContext.Provider>
    );
}) as CheckboxGroupType;

CheckboxGroup.Box = (props: CheckboxGroupBoxProps & {labelProps?: FormControlLabelProps}) => {
    const {
        value,
        label,
        labelPlacement = 'end',
        onChange: _onChange,
        labelProps,
        ...muiCheckboxProps
    } = props;

    const { state, setState, isChecked } = useCheckboxGroup();
    const [checked, setChecked] = useState(false);

    useEffect(() => { setChecked(isChecked(value)); }, [state]);

    const onChange: typeof _onChange = useCallback((_, checked) => {
        const update = checked ? [...state, value] : state.filter((x) => x !== value);
        setState(update);
    }, [state, value, setState]);

    return useMemo(() => (
        <FormControl>
            <FormControlLabel
                label={label}
                labelPlacement={labelPlacement}
                control={(
                    <MuiCheckbox
                        color="primary"
                        {...muiCheckboxProps}
                        value={value}
                        onChange={onChange}
                        checked={checked} />
                )}
                {...labelProps}
            />
        </FormControl>
    ), [value, checked, onChange]);
};

export default CheckboxGroup as CheckboxGroupType;
