import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';

const useStyles = makeStyles(() => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
		height: '100%',
		padding: '9pt 0.125in',
		'& input, textarea': {
			borderWidth: '0px',
		},
	},
	name: {
		fontFamily: 'eq3-book',
		fontSize: '30px',
		lineHeight: '23pt',
		textTransform: 'uppercase',
		width: '100%',
	},
	descriptionPricingContainer: {
		display: 'flex',
		marginTop: '3px',
		width: '100%',
		height: '100%',
	},
	descriptionInput: {
        fontFamily: 'eq3-medium',
		fontSize: '11pt',
		lineHeight: '12px',
		width: '100%',
		resize: 'none',
		overflow: 'hidden',
		height: '100%',
		paddingTop: 0,
		'&::before': {
			content: '""',
		},
	},
}));
const CustomShelfTalkerTag = () => {
	const [name, setName] = useState('');
	const [description, setDescription] = useState('');

	const classes = useStyles();

	const handleChange =
		(setter: (string) => void) =>
		(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			setter(e.target.value);
		};

	return (
		<div className={classes.container}>
			<input
				type="text"
				className={classes.name}
				name="name"
				value={name}
				onChange={handleChange(setName)}
			/>
			<div className={classes.descriptionPricingContainer}>
				<textarea
					className={classes.descriptionInput}
					name="description"
					value={description}
					onChange={handleChange(setDescription)}
					maxLength={747}
				/>
			</div>
		</div>
	);
};

export default CustomShelfTalkerTag;
