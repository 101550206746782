import nonPrintable from '@eq3/containers/retail/priceTags/util/nonPrintable';
import {IconButton, Tooltip} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon} from '@material-ui/icons';
import * as React from 'react';
import {PropsWithChildren, useState} from 'react';

const useStyles = makeStyles(() => ({
    container: {
        position: 'relative',
    },
    toggleIcon: {
        position: 'absolute',
        top: 0,
        left: 0,
        transform: 'translate(-100%, -25%)',
    },
}));

interface IProps {
    initialVisibility: boolean;
    iconTitle: {
        visible: string;
        notVisible: string;
    };
}

const Hideable = ({initialVisibility, iconTitle, children}: PropsWithChildren<IProps>) => {
    const styles = useStyles();
    const [visible, setVisible] = useState(initialVisibility);

    const toggleVisibility = () => {
        setVisible(!visible);
    };

    return (
        <div className={styles.container}>
            {visible && children}
            <Tooltip title={visible && iconTitle.visible || iconTitle.notVisible} className={nonPrintable}>
                <IconButton onClick={toggleVisibility} className={styles.toggleIcon}>
                    {visible && <VisibilityIcon/> || <VisibilityOffIcon/>}
                </IconButton>
            </Tooltip>
        </div>
    );
};

export {Hideable};
