import { AuthDispatch } from '@eq3/redux/auth/actions';
import { logout } from '@eq3/redux/auth/thunks';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import { useDispatch } from 'react-redux';

interface IProps {
    title: string;
    message?: string;
    logoutButtonText: string;
}

const useStyles = makeStyles(() => ({
    root: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

const LoginError = (props: IProps) => {
    const { title, message, logoutButtonText } = props;

    const dispatch = useDispatch<AuthDispatch>();
    const classes = useStyles();

    const handleLogout = () => {
        // Redirect to the home page before we logout so we don't end up back at the current page if we log back in.
        return dispatch(logout()).subscribe();
    };

    return (
        <div className={classes.root}>
            <h1>{title}</h1>
            {message && (
                <p>{message}</p>
            )}
            <br/>
            <Button color="primary" variant="contained" onClick={handleLogout}>{logoutButtonText}</Button>
        </div>
    );
};

export const Forbidden = () => {
    return (
        <LoginError
            title="You are not authorized to access this content."
            logoutButtonText="Logout"
        />
    );
};

export default LoginError;
