import { defaultPageSize } from '@eq3/utils/pagination';
import { createReducer } from 'deox';
import Actions from './actions';
import { IShippingInventoryState } from './models/viewModels';

const { setOrders, setOrderListing, updateOrderListing } = Actions;

const initialState: IShippingInventoryState = {
    orders: {
        items: [],
        page: 0,
        pageSize: defaultPageSize,
        totalCount: 0,
    },
    orderIdentifiers: [],
    orderListing: {
        details: {
            items: [],
            page: 0,
            pageSize: defaultPageSize,
            totalCount: 0,
        },
        selectedSku: '',
        selectedSkuOrderIdentifiers: [],
    },
};

export interface IShippingInventoryListingReduxSlice {
    orderDetails: IShippingInventoryState;
}

export default createReducer(initialState, (handler) => [
    handler(setOrders, (state, { payload }) => ({
        ...state,
        orders: payload.orders,
        orderIdentifiers: payload.orderIdentifiers,
    })),
    handler(setOrderListing, (state, { payload }) => ({
        ...state,
        orderListing: {
            details: payload.details,
            selectedSku: payload.selectedSku,
            orderIdentifiers: payload.selectedSkuOrderIdentifiers,
        },
    })),
    handler(updateOrderListing, (state, { payload }) => {
        const index = state.orderListing.details.items?.findIndex(({ sku, orderId }) => sku === payload.sku && orderId === payload.orderId);
        const items = [...state.orderListing.details.items];

        if (index !== undefined && index >= 0) {
            items[index] = { ...payload };
        }

        return ({
            ...state,
            orderListing: {
                ...state.orderListing,
                details: {
                    ...state.orderListing.details,
                    items: [...items],
                },
            },
        });
    }),
]);
