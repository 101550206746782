import { StyleRules, WithStyles } from '@material-ui/core/styles';
import React from 'react';

import {connect} from 'react-redux';
import {compose} from 'redux';
import LocalizedPrice from '../../../components/LocalizedPrice';
import LocalizedString from '../../../components/LocalizedString';

import { withStyles } from '@material-ui/core';

interface IProps {
    product: any;
}

class RugTag extends React.Component<IProps & ReturnType<typeof mapStateToProps> & WithStyles<ReturnType<typeof styles>>> {

    public render() {

        const {product, classes, locale} = this.props;
        const localizedProduct = product[locale.id];

        return (
            <div className={classes.container}>
                <div className={classes.name}>
                    {localizedProduct.name}
                </div>
                <div className={classes.description}>
                    {localizedProduct.description}
                </div>
                {localizedProduct.customSizePrice && localizedProduct.value > 0 && (
                    <div className={classes.customSizes}>
                        <LocalizedString labelId="custom_sizes_available"/>
                        <LocalizedPrice price={localizedProduct.customSizePrice}/>
                        <LocalizedString labelId="per_square_foot"/>
                    </div>
                )}
                {localizedProduct.sizePrices && localizedProduct.sizePrices.length && (
                    <div className={classes.sizes}>
                        {localizedProduct.sizePrices.map(({size, price}, i) => (
                            <div key={i} className={classes.size}>
                                <div>
                                    {size}
                                </div>
                                <div className={classes.sizePrice}>
                                    <LocalizedPrice price={price}/>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    }
}

const styles = () => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        margin: '0 0.125in',
    },
    name: {
        marginTop: '10pt',
        fontSize: '18pt',
        fontFamily: 'eq3-heavy',
        lineHeight: '19pt',
    },
    description: {
        marginTop: '10pt',
        fontSize: '12pt',
        fontFamily: 'eq3-book',
    },
    customSizes: {
        fontSize: '12pt',
        fontFamily: 'eq3-heavy',
    },
    sizes: {
        position: 'absolute',
        bottom: '15pt',
        fontSize: '10pt',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignContent: 'stretch',
    },
    sizePrice: {
        fontSize: '13.5pt',
    },
    size: {
        display: 'flex',
        flexDirection: 'column',
        width: '0.95in',
    },
}) as StyleRules;

const mapStateToProps = (state) => ({
    locale: state.priceTags.locale,
});

export default (compose(
    connect(mapStateToProps),
    withStyles(styles),
)(RugTag)) as any as React.ComponentType<IProps>;
