import { StyleRules, WithStyles } from '@material-ui/core/styles';
import React from 'react';

import {connect} from 'react-redux';
import {compose} from 'redux';
import {upholsteryCollectionTagMenuMapper} from '../../../../../../reselect/priceTags';
import LocalizedString from '../../../components/LocalizedString';
import {i18n} from '../../../util/i18n';
import {Tags, TagSizes} from '../../Tags';
import CollectionsTagUploader from '../uploading/CollectionsTagUploader';
import TagUploader from '../uploading/TagUploader';

import { List, MenuItem, Typography, withStyles } from '@material-ui/core';

class LargeTagsUploader extends React.Component<ReturnType<typeof mapStateToProps> & WithStyles<ReturnType<typeof styles>>> {

    public state = {
        tag: Tags.upholstery,
    };

    private handleTagChange = (tag) => () => {
        this.setState({tag});
    };

    public render() {
        const {tag} = this.state;
        const {locale, upholsteryTagData, classes} = this.props;

        return (
            <div className={classes.container}>
                <div className={classes.tagSelector}>
                    <Typography variant="h5"><LocalizedString labelId="selectTag"/></Typography>
                    <List>
                        <MenuItem button onClick={this.handleTagChange(Tags.upholstery)} selected={tag === Tags.upholstery}>{i18n[locale.id][Tags.upholstery.labelId]}</MenuItem>
                        <MenuItem button onClick={this.handleTagChange(Tags.beds)} selected={tag === Tags.beds}>{i18n[locale.id][Tags.beds.labelId]}</MenuItem>
                        <MenuItem button onClick={this.handleTagChange(Tags.upholsteredBeds)} selected={tag === Tags.upholsteredBeds}>{i18n[locale.id][Tags.upholsteredBeds.labelId]}</MenuItem>
                        <MenuItem button onClick={this.handleTagChange(Tags.customizableProducts)} selected={tag === Tags.customizableProducts} >{i18n[locale.id][Tags.customizableProducts.labelId]}</MenuItem>
                        <MenuItem button onClick={this.handleTagChange(Tags.readyToShip)} selected={tag === Tags.readyToShip} >{i18n[locale.id][Tags.readyToShip.labelId]}</MenuItem>
                    </List>
                </div>

                {tag !== Tags.upholstery && <div><TagUploader tag={tag} previewComponent={TagSizes.large.singleTagElement}/></div>}
                {tag === Tags.upholstery && (
                    <CollectionsTagUploader
                        tag={Tags.upholstery}
                        collectionsTagData={upholsteryTagData.filter(({id}) => id !== Tags.sectionals.id)}
                        menuMapper={upholsteryCollectionTagMenuMapper}
                        previewTagElement={TagSizes.large.singleTagElement}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    locale: state.priceTags.locale,
    upholsteryTagData: state.priceTags.tagsData[Tags.upholstery.id],
});

const styles = () => ({
    tagSelector: {
        marginRight: '10px',
        paddingRight: '10px',
        borderRight: '1px solid black',
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        width: '100%',
        maxHeight: '800px',
    },
}) as StyleRules;

export default (compose(
    connect(mapStateToProps),
    withStyles(styles),
)(LargeTagsUploader));
