import { WithStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';

import { CircularProgress, withStyles } from '@material-ui/core';
import { compose } from 'redux';
import { fetchRugTagData } from '../../../../redux/priceTags';
import { rugTagDataSelector, rugTagMenuSelector } from '../../../../reselect/priceTags';
import RugPartialTag from './tags/rug/RugPartialTag';

class RugCardPage extends React.Component<WithStyles<ReturnType<typeof styles>> & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>> {

    public componentDidMount() {
        const { rugTagData } = this.props;
        if (!rugTagData) {
            this.props.fetchRugData();
        }
    }
    public render(): JSX.Element {
        const { classes, menu } = this.props;

        if (!menu) {
            return (
                <div className={classes.page}>
                    <CircularProgress
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                        }}
                    />
                </div>
            );
        }

        return (
            <div className={classes.page}>
                <RugPartialTag menu={menu} />
                <RugPartialTag menu={menu} />
                <RugPartialTag menu={menu} />
            </div>
        );
    }
}

const styles = (theme) => ({
    page: {
        width: '14in',
        height: '8.5in',
        padding: '0.25in 0.8125in',
        display: 'flex',
    },

});

const mapStateToProps = (state) => ({
    menu: rugTagMenuSelector(state),
    rugTagData: rugTagDataSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    fetchRugData: () => dispatch(fetchRugTagData()),
});
export default (compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
)(RugCardPage));
