import { IPromotionListItem } from '@eq3/redux/promotions/models';
import { createActions, handleActions } from 'redux-actions';
import {
    busyNotification,
    errorNotification,
    notify,
    successNotification
} from '../adminNotifications';
import { apiThunk } from '../store';
import { Locale } from '@eq3/utils/locales';
import {defaultPageSize, IOrderable, Pagination} from '@eq3/utils/pagination';
import { defer, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { PromotionThunkResult, baseApiPath } from '.';

export interface IPromotionFilter extends IOrderable {
    internalName?: string;
    startDate?: string;
    endDate?: string;
    promoCode?: string;
    isCurrentlyActive?: boolean;
    hasPromoCode?: boolean;
    localeAvailable?: Locale;

    pageNumber: number;
    pageSize: number;
}

export const defaultPromotionFilter: IPromotionFilter = {
    isCurrentlyActive: true,
    pageNumber: 0,
    pageSize: defaultPageSize,
    orderBy: 'internalName',
    orderDirection: 'ASC',
};

export const { setPromotionGroups, setPromotionGroup, updatePromotionGroupName, setPromotionFilter } = createActions({
    SET_PROMOTION_GROUPS: (d) => d,
    SET_PROMOTION_GROUP: (d) => d,
    UPDATE_PROMOTION_GROUP_NAME: (id, internalName) => ({ id, internalName }),
    SET_PROMOTION_FILTER: (filter: IPromotionFilter) => ({filter}),
});

export default handleActions({
    [setPromotionGroups]: (state, { payload: data }) => ({ ...state, paginatedList: data, loading: false }),
    [setPromotionGroup]: (state, { payload: data }) => ({ ...state, data: { [data.id]: data } }),
    [updatePromotionGroupName]: (state, { payload: { id, internalName } }) => ({
        ...state,
        paginatedList: state.paginatedList?.items?.map((s) => {
            if (s.id === id) {
                return { ...s, internalName };
            }
            return s;
        }),
    }),
    [setPromotionFilter]: (state, {payload: {filter}}) => {
        return {...state, filter};
    },
}, { loading: true, paginatedList: {}, data: {}, filter: defaultPromotionFilter});

export const fetchPromotionGroups = (query: Partial<IPromotionFilter>): PromotionThunkResult<Pagination<IPromotionListItem>> => (dispatch, getState, api) => {    
    const fetch = () => from(api<Pagination<IPromotionListItem>>(dispatch, getState, baseApiPath, 'GET', undefined, {
        params: query,
    }));

    return defer(fetch).pipe(
        map(({ data }) => data),
    );
};

export const fetchPromotionGroup = (id) => async (dispatch, getStore, api: apiThunk) => {
    try {
        dispatch(setPromotionGroup({id, loading: true}));
        const {data} = await api(dispatch, getStore, `/admin/promotion-groups/${id}`, 'GET');
        dispatch(setPromotionGroup(data));
    } catch (e) {
        dispatch(notify(errorNotification('Error getting Promotion Group', e)));
        throw e;
    }
};

export const createPromotionGroup = (values) => async (dispatch, getStore, api: apiThunk) => {
    try {
        dispatch(notify(busyNotification('Creating Promotion...')));
        const {data} = await api(dispatch, getStore, '/admin/promotion-groups', 'POST', values);

        dispatch(setPromotionGroup(data));
        dispatch(notify(successNotification('Promotion Group created!')));
        return data;
    } catch (e) {
        if (e.response.data.type === 'PROMO_CODE_ALREADY_EXISTS') {
            dispatch(notify(errorNotification(`${e.response.data.message}`, e)));
        } else {
            dispatch(notify(errorNotification('Error creating Promotion Group', e)));
        }
        throw e;
    }
};

export const savePromotionGroup = (id, values) => async (dispatch, getStore, api: apiThunk) => {
    try {
        const {data} = await api(dispatch, getStore, `/admin/promotion-groups/${id}`, 'PUT', values);

        dispatch(setPromotionGroup(data));
        dispatch(updatePromotionGroupName(data.id, data.internalName));
        dispatch(notify(successNotification('Promotion Group updated!')));

        return data;
    } catch (e) {
        dispatch(notify(errorNotification('Error updating Promotion Group', e)));
        throw e;
    }
};

export const deletePromotionGroup = (id) => async (dispatch, getStore, api: apiThunk) => {
    try {
        await api(dispatch, getStore, `/admin/promotion-groups/${id}`, 'DELETE');
        dispatch(notify(successNotification('Promotion Group deleted!')));
    } catch (e) {
        dispatch(notify(errorNotification('Error deleting Promotion Group', e)));
        throw e;
    }
};

export const syncPromotions = () => async (dispatch, getStore, api: apiThunk) => {
    try {
        await api(dispatch, getStore, `/admin/promotions/sync`, 'POST');
        dispatch(notify(successNotification('Promotions synchronized!')));
    } catch (e) {
        dispatch(notify(errorNotification('Error synchronizing Promotions', e)));
        throw e;
    }
};

export const downloadCodes = (id: string, internalName: string) => async (dispatch, getState, api: apiThunk) => {
    try {
        const { data } = await api(dispatch, getState, `/admin/promotion-groups/${id}/csv-export`, 'GET');

        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${internalName}MultiPromoCodes.csv`);
        document.body.appendChild(link);
        link.click();
    } catch (e) {
        dispatch(notify(errorNotification('Error retrieving codes', e)));
        throw e;
    }
};
