import { Translated } from '@eq3/utils/locales';

export interface IMenu {
    categories: ICategoryListItem[];
    subcategories: ISubcategoryListItem[];
    productLines: IProductLineListItem[];
}

export interface ICategoryListItem {
    id: string;
    name: string;
    pos: number;
}

export interface ISubcategoryListItem extends ICategoryListItem {
    categoryId: string;
}

export interface IProductLineListItem extends ICategoryListItem {
    subcategoryId: string;
}

export interface ICategory {
    id: string;
    internalName: string;
    photography: IPhotography[];
    taggedInstances: {
        [key in PhotographyType]?: TaggedInstance[];
    };
    name: Translated<string>;
    slug: Translated<string>;
    metaTitle: Translated<string>;
    metaDescription: Translated<string>;
    visible: Translated<boolean>;
    description: Translated<string>;
    summary: Translated<string>;
    seoDescription: Translated<string>;
}

export interface IUpdatePhotography {
    imageId?: string;
    type: PhotographyType;
    dimension: PhotographyDimension;
    newImage?: File;
    altImageText: Translated<string>;
    seoFileName?: string;
}

interface IUpdateMenuItem {
    internalName: string;
    photography: IUpdatePhotography[];
    taggedInstances: {
        [key in PhotographyType]?: string[];
    };
    translations: {
        name: Translated<string>;
        slug: Translated<string>;
        metaTitle: Translated<string>;
        metaDescription: Translated<string>;
        visible: Translated<boolean>;
        description: Translated<string>;
        seoDescription: Translated<string>;
        summary: Translated<string>;
    };
}

// tslint:disable-next-line no-empty-interface
export interface IUpdateCategory extends IUpdateMenuItem { }

// tslint:disable-next-line no-empty-interface
export interface IUpdateSubcategory extends IUpdateMenuItem { }

export interface IUpdateProductLine extends Omit<IUpdateMenuItem, 'translations'> {
    zucoraAvailable: boolean;
    translations: {
        name: Translated<string>;
        slug: Translated<string>;
        metaTitle: Translated<string>;
        metaDescription: Translated<string>;
        visible: Translated<boolean>;
        description: Translated<string>;
    };
}

export interface IPhotography {
    imageId: string;
    type: PhotographyType;
    dimension: PhotographyDimension;
    imageUrl: string;
    altImageText: Translated<string>;
    seoFileName: string;
}

export interface TaggedInstance {
    id: string;
    name: string;
}

export enum PhotographyDimension {
    DESKTOP = 'DESKTOP',
    TABLET = 'TABLET',
    MOBILE = 'MOBILE',
}

export enum PhotographyType {
    LIFESTYLE = 'LIFESTYLE',
    HERO = 'HERO',
    THUMBNAIL = 'THUMBNAIL',
}

export interface ISubcategory {
    id: string;
    categoryId: string;
    internalName: string;
    photography: IPhotography[];
    taggedInstances: {
        [key in PhotographyType]?: TaggedInstance[];
    };
    name: Translated<string>;
    slug: Translated<string>;
    metaTitle: Translated<string>;
    metaDescription: Translated<string>;
    visible: Translated<boolean>;
    description: Translated<string>;
    summary: Translated<string>;
    seoDescription: Translated<string>;
}

export interface IProductLine {
    id: string;
    subcategoryId: string;
    internalName: string;
    zucoraAvailable: boolean;
    photography: IPhotography[];
    taggedInstances: {
        [key in PhotographyType]?: TaggedInstance[];
    };
    name: Translated<string>;
    slug: Translated<string>;
    metaTitle: Translated<string>;
    metaDescription: Translated<string>;
    visible: Translated<boolean>;
    description: Translated<string>;
}
