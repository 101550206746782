import { WithStyles } from '@material-ui/core/styles';
import React from 'react';

import { compose } from 'redux';
import LocalizedPrice from '../../../components/LocalizedPrice';
import LocalizedString from '../../../components/LocalizedString';
import { ILocale } from '../../../util/i18n';

import { createStyles, withStyles } from '@material-ui/core';

interface IProps {
    locale: ILocale;
    slats: any[];
}
class SlatsTable extends React.Component<IProps & WithStyles<ReturnType<typeof styles>>> {
    public render() {
        const { classes, locale, slats } = this.props;
        return (
            <div>
                {slats && !!slats.length && (
                    <div className={classes.slatOptions}>
                        <div className={classes.slatsTitle}>
                            <LocalizedString labelId="slat_options" />
                        </div>
                        <div className={classes.slatsTable}>
                            {slats.map((slatOption, i) => (

                                <React.Fragment key={i}>
                                    <div className={classes.slatOptionName}>
                                        {slatOption[locale.id].name}
                                    </div>
                                    <div className={classes.slatOptionPrice}>
                                        <LocalizedPrice price={slatOption[locale.id].price}/>
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const styles = () => createStyles({
    slatTitle: {
        fontSize: '9pt',
        lineHeight: '12pt',
    },
    slatOptionName: {
        fontSize: '8pt',
    },
    slatOptions: {
    },
    slatsTable: {
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        fontSize: '8pt',
        gridAutoRows: '13pt',
    },
    slatOptionPrice: {
        textAlign: 'right',
        width: '100%',
    },
});

export default (compose(
    withStyles(styles),
)(SlatsTable)) as any as React.ComponentType<IProps>;
