import { ILeadTimeEstimate, LeadTimeEstimateTimeUnit } from '@eq3/redux/leadTimeManagement/models';
import { getLeadTimeEstimates } from '@eq3/redux/leadTimeManagement/thunks';
import { IOrderTaxes } from '@eq3/redux/orders/models';
import { fetchOrder } from '@eq3/redux/orders/thunks';
import { formatCurrency } from '@eq3/utils/currencyUtils';
import { dateLocale, Locale, localePaths } from '@eq3/utils/locales';
import { makeStyles } from '@material-ui/core';
import classnames from 'classnames';
import format from 'date-fns/format';
import qs from 'qs';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { ThunkDispatch } from 'redux-thunk';
import { merge } from 'rxjs';
import { delay } from 'rxjs/operators';
import { CustomerShippingTypes } from '@eq3/redux/orderShipments';
import Texxt from '@eq3/design/components/Texxt';
import classNames from 'classnames';
import { IShippingEstimatesInfo } from '@eq3/containers/orders/ShippingEstimatesInfo';
import { calculateLeadTime } from './LeadTimeEstimates';

enum Warranty {
    extend = 'extend',
    zucora = 'zucora',
}
const EXTEND_ITEM_ID = 'extend';
const EXTEND_STARTING_DATE = 1701756000000;

const displayLeadTimeEstimate = (estimate: Partial<ILeadTimeEstimate>, locale: Locale): string | undefined => {
    const { from, to, timeUnit } = estimate;

    if (!timeUnit || (!from && !to)) return undefined;
    if (timeUnit !== LeadTimeEstimateTimeUnit.BUSINESS_DAYS && timeUnit !== LeadTimeEstimateTimeUnit.WEEKS) {
        throw new Error(`Invalid time unit: ${timeUnit}`);
    }

    return `${getString('deliveryEstimate', locale)} ${from} ${getString('to', locale)} ${to} ${
        timeUnit === LeadTimeEstimateTimeUnit.BUSINESS_DAYS
            ? getString('businessDays', locale)
            : getString('weeks', locale)
    } ${getString('purchaseTime', locale)}`;
};

const LeadTimeEstimates = (props: {
    shippingEstimatesInfo?: IShippingEstimatesInfo;
    hasDropShippedItems: boolean;
    locale: Locale;
}) => {
    const { hasDropShippedItems, locale } = props;

    const deliveryPolicy =
        locale === Locale.CA_FR
            ? `Visitez ${process.env.WEBSITE_URL}${localePaths[locale]}/questions/shipping-delivery pour voir notre politique de livraison.`
            : `Visit ${process.env.WEBSITE_URL}${localePaths[locale]}/questions/shipping-delivery for our shipping & delivery policy.`;

    const { fromTime: from, toTime: to, timeUnit } = calculateLeadTime(props) ?? {};

    const leadTimeText = displayLeadTimeEstimate({ from, to, timeUnit }, locale);

    return (
        <>
            {!!leadTimeText && (
                <div>
                    <Texxt component="span" variant="label">
                        {leadTimeText}
                    </Texxt>
                </div>
            )}
            <div>{deliveryPolicy}</div>
            <div>{hasDropShippedItems && getString('dropShipText', locale)}</div>
        </>
    );
};

const PrintInvoiceSlip = () => {
    const classes = useStyles();
    const { search } = useLocation();
    const { orderIds = [] } = qs.parse(search, {
        comma: true,
        ignoreQueryPrefix: true,
    });
    const validOrderIds = (Array.isArray(orderIds) ? orderIds : [orderIds]).filter((orderId) => !!orderId);

    const dispatch = useDispatch<ThunkDispatch>();
    const orders = useSelector((state: { orders: { data: { [key: string]: any } } }) => {
        const allOrderData = state.orders.data;
        return Object.entries(allOrderData)
            .filter(([orderId]) => validOrderIds.includes(orderId))
            .map(([, order]) => order);
    });

    useEffect(() => {
        const observables = [
            ...validOrderIds.map((orderId) => dispatch(fetchOrder(orderId as string))),
            dispatch(getLeadTimeEstimates()),
        ];

        const subscription = merge(...observables)
            .pipe(delay(200))
            .subscribe({
                complete: () => {
                    window.print();
                },
            });

        return () => subscription.unsubscribe();
    }, []);

    const renderTaxes = (taxes: IOrderTaxes, locale: Locale) => {
        if (!taxes || !Object.entries(taxes.taxBreakdown).length) {
            return (
                <>
                    <div>{getString('taxes', locale)}:</div>
                    <div>{formatCurrency(0, locale)}</div>
                </>
            );
        }
        return (
            <>
                {Object.entries(taxes.taxBreakdown).map(([taxType, { amount, rate }]) => {
                    // Convert to percentage value with three decimal precision.
                    const taxLabel = rate ? `${taxType} (${Number((rate * 100).toFixed(3))}%)` : taxType;
                    return (
                        <React.Fragment key={taxType}>
                            <div>{taxLabel}:</div>
                            <div>{formatCurrency(amount, locale)}</div>
                        </React.Fragment>
                    );
                })}
            </>
        );
    };

    const renderOrder = (order) => {
        const locale: Locale = !!order ? Locale[order.locale] : Locale.CA_EN;
        const taxes: IOrderTaxes = !!order && order.taxes;
        const billingAddress = !!order && (order.billingAddress || order.address);
        const returnsLink = `${process.env.WEBSITE_URL}${localePaths[locale]}/questions/returns-exchanges`;

        const warranty =
            order?.zucoraSubtotal && order?.purchaseDate >= EXTEND_STARTING_DATE ? Warranty.extend : Warranty.zucora;

        const orderPaymentMethods = order.payments
            .filter(({ status }) => status === 'COMPLETED')
            .map(({ details }) =>
                details.type.toUpperCase() === 'PAYPAL'
                    ? 'Paypal'
                    : details.type.toUpperCase() === 'AFFIRM'
                    ? 'Affirm'
                    : `${details.cardType} (${details.lastFourDigits})`
            )
            .join(', ');

        const shippingEstimatesInfo: IShippingEstimatesInfo | undefined = order.shippingEstimatesInfo;

        const aoPromotion =
            order.products.addOns?.length > 0 &&
            order.products.addOns.some(({ promotion }) => !!promotion?.promotionGroupName);
        const nPromotion = order.products.nested?.promotion?.promotionGroupName?.length! > 0;
        const promotion = order.products.some(({ promotion }) => !!promotion?.promotionGroupName);
        const isPromotion = aoPromotion || nPromotion || promotion;
        const hasDropShippedItems = order.products.some(({ isEq3Plus }) => !!isEq3Plus);

        const lineItems = [
            ...(order.zucoraSubtotal
                ? warranty === Warranty.zucora
                    ? [
                          {
                              id: ZUCORA_LINE_ITEM_ID,
                              name: getString('zucoraSmart5Plan', locale),
                              quantity: 1,
                              thumbUrl: '/images/zucorahome_logo.svg',
                              price: order.zucoraSubtotal,
                              priceWithTax: order.zucoraSubtotal,
                              pricePer: order.zucoraSubtotal,
                              pricePerWithTax: order.zucoraSubtotal,
                              originalPricePer: order.zucoraSubtotal,
                              options: [],
                              skus: [],
                              addOns: [],
                              taxes: {},
                              manufacturer: 'EQ3',
                              isEq3Plus: false,
                              isZucoraApplied: false,
                          },
                      ]
                    : [
                          {
                              id: EXTEND_ITEM_ID,
                              name: getString('extendProtectionPlan', locale),
                              quantity: 1,
                              thumbUrl: '/images/extendLogo.svg',
                              price: order.zucoraSubtotal,
                              priceWithTax: order.zucoraSubtotal,
                              pricePer: order.zucoraSubtotal,
                              pricePerWithTax: order.zucoraSubtotal,
                              originalPricePer: order.zucoraSubtotal,
                              options: [],
                              skus: [],
                              addOns: [],
                              taxes: {},
                              manufacturer: 'EQ3',
                              isEq3Plus: false,
                              isZucoraApplied: false,
                          },
                      ]
                : []),
            ...order.products,
        ];

        return (
            <React.Fragment key={`invoice-${order.orderNumber}`}>
                <div>
                    {getString('invoice', locale)}
                    <hr />
                    <div className={classes.grid2}>
                        <div>{getString('orderNumber', locale)}:</div>
                        <div>{order.orderNumber}</div>
                        <div>{getString('dateOrdered', locale)}:</div>
                        <div>
                            {format(new Date(order.orderDate), 'd-MMM-yyyy h:mm a', {
                                locale: dateLocale[locale],
                            })}
                        </div>
                    </div>
                    <hr />
                    <div className={classes.grid4}>
                        <div>{getString('shippingAddress', locale)}:</div>
                        <div>
                            {order.address.firstName} {order.address.lastName}
                            <br />
                            {order.address.streetAddress}
                            {order.address.unitNumber && ', ' + order.address.unitNumber}
                            <br />
                            {order.address.city}, {order.address.province}, {order.address.postalCode},{' '}
                            {order.address.country}
                            <br />
                            {order.address.phoneNumbers.map((b, i) => (
                                <div key={`phone${i}`}>{`${b.slice(0, 2)} ${b.slice(2, 5)} ${b.slice(5, 8)} ${b.slice(
                                    8,
                                    12
                                )}`}</div>
                            ))}
                        </div>
                        <div>{getString('billingAddress', locale)}:</div>
                        <div>
                            {billingAddress.firstName} {billingAddress.lastName}
                            <br />
                            {billingAddress.streetAddress}
                            {billingAddress.unitNumber && ', ' + billingAddress.unitNumber}
                            <br />
                            {billingAddress.city}, {billingAddress.province}, {billingAddress.postalCode},{' '}
                            {billingAddress.country}
                            <br />
                            {billingAddress.phoneNumbers.map((b, i) => (
                                <div key={`phone${i}`}>{`${b.slice(0, 2)} ${b.slice(2, 5)} ${b.slice(5, 8)} ${b.slice(
                                    8,
                                    12
                                )}`}</div>
                            ))}
                        </div>
                        {/* Second row in the grid */}
                        <div>{getString('emailAddress', locale)}:</div>
                        <div>{order.email}</div>
                        <div>{getString('paymentMethod', locale)}:</div>
                        <div>{orderPaymentMethods}</div>
                        <div>{getString('shipmentMethod', locale)}:</div>
                        <div>{getShippingTypes(order.shippingType, locale)}</div>
                    </div>
                    <hr />
                    <div
                        className={classnames({
                            [classes.grid4f]: !isPromotion && !order.zucoraSubtotal,
                            [classes.grid5f]:
                                (isPromotion || !!order.zucoraSubtotal) && !(isPromotion && !!order.zucoraSubtotal),
                            [classes.grid6f]: isPromotion && !!order.zucoraSubtotal,
                        })}
                    >
                        <div>{getString('products', locale)}</div>
                        <div>{getString('model', locale)}</div>
                        <div>{getString('price', locale)}</div>
                        {isPromotion && <div>{getString('promotion', locale)}</div>}
                        {!!order.zucoraSubtotal && <div>Protection</div>}
                        <div>{getString('total', locale)}</div>

                        {lineItems.map(
                            (
                                {
                                    id,
                                    quantity,
                                    name,
                                    options,
                                    skus,
                                    nested,
                                    price,
                                    originalPricePer,
                                    pricePer,
                                    userSelectedOptions,
                                    addOns,
                                    promotion,
                                    isZucoraApplied,
                                },
                                pi
                            ) => (
                                <React.Fragment key={`${pi}_${id}`}>
                                    <div>
                                        <div>
                                            {quantity} x {name}
                                        </div>
                                        <div>
                                            {(userSelectedOptions || options).map(
                                                ({ name, value, sku, upholsteryName, upholsteryOptions }, oi) => (
                                                    <React.Fragment key={`${pi}_${id}_${oi}`}>
                                                        <div className={classes.options}>
                                                            -{name}: {value} {upholsteryName}
                                                            {sku && <span style={{ float: 'right' }}>{sku}</span>}
                                                        </div>
                                                        {!!upholsteryOptions &&
                                                            upholsteryOptions.map(({ optionName, valueName }, uoi) => (
                                                                <div
                                                                    key={`${pi}_${id}_${oi}_${uoi}`}
                                                                    className={classes.options}
                                                                >
                                                                    -{optionName}: {valueName}
                                                                </div>
                                                            ))}
                                                    </React.Fragment>
                                                )
                                            )}
                                        </div>
                                    </div>
                                    <div>
                                        {skus.concat(nested ? nested.skus : []).map((sku, i) => (
                                            <div key={`sku-${sku}-${i}`}>{sku}</div>
                                        ))}
                                        {addOns &&
                                            addOns.map((addOn, i) => (
                                                <div key={`addOn-${addOn.sku}-${i}`}>{addOn.sku}</div>
                                            ))}
                                        {options &&
                                            options.map((option, i) => (
                                                <div key={`addOn-${option.sku}-${i}`}>{option.sku}</div>
                                            ))}
                                    </div>
                                    <div>
                                        {promotion && (
                                            <span className={classes.originalPricePer}>
                                                {formatCurrency(originalPricePer, locale)}
                                            </span>
                                        )}{' '}
                                        {formatCurrency(pricePer, locale)}
                                    </div>
                                    {isPromotion && <div>{promotion?.promotionGroupName}</div>}
                                    {!!order.zucoraSubtotal && warranty === Warranty.zucora && (
                                        <div>
                                            {id !== ZUCORA_LINE_ITEM_ID &&
                                                getString(isZucoraApplied ? 'yes' : 'no', locale)}
                                        </div>
                                    )}
                                    {!!order.zucoraSubtotal && warranty === Warranty.extend && (
                                        <div>
                                            {id !== EXTEND_ITEM_ID && getString(isZucoraApplied ? 'yes' : 'no', locale)}
                                        </div>
                                    )}
                                    <div>{formatCurrency(price, locale)}</div>
                                </React.Fragment>
                            )
                        )}
                    </div>
                    <hr />
                    <div className={classes.grid2r}>
                        <div>{getString('subtotal', locale)}:</div>
                        <div>{formatCurrency(order.subtotal, locale)}</div>
                        <div>{getString('shippingFee', locale)}: </div>
                        <div>{formatCurrency(order.shippingPrice, locale)}</div>
                        <div
                            className={classNames({
                                [classes.retailDeliveryFee]: !order.retailDeliveryFee,
                            })}
                        >
                            Retail Delivery Fee:{' '}
                        </div>
                        <div
                            className={classNames({
                                [classes.retailDeliveryFee]: !order.retailDeliveryFee,
                            })}
                        >
                            {formatCurrency(order.retailDeliveryFee, locale)}
                        </div>
                        {renderTaxes(taxes, locale)}
                        <div>{getString('total', locale)}:</div>
                        <div>{formatCurrency(order.total, locale)}</div>
                    </div>
                    <hr />
                    <div>
                        {order.locale === Locale.CA_FR
                            ? `Notre liste de prix est sujet à changement sans préavis.
                                À moins d'avis contraire, les devis viendront à échéance dans les trente (30) jours ouvrables suivant leur date d'émission et peuvent être annulés ou modifiés pendant cette période après avis écrit à votre attention.
                                Assemblage requis sur certains articles. Visitez ${returnsLink} pour voir notre politique de retour et d'échange. TPS #83529 0859 RT0001 TVQ #1216159884 TQ0001`
                            : `Our price list is subject to change without notice. Price quotations, unless otherwise stated,
                                shall automatically expire thirty (30) calendar days from the date issued and may be canceled or amended within that period upon written notice to you.
                                Promotional offers and pricing are valid during the specified time period only.
                                Some items may require assembly. Visit ${returnsLink} for our return & exchange policy. GST #83529 0859RT0001${
                                  order.locale === Locale.CA_EN ? ' QST #1216159884 TQ0001' : ''
                              }.`}
                    </div>
                    <div className={classes.estimates}>
                        <LeadTimeEstimates
                            shippingEstimatesInfo={shippingEstimatesInfo}
                            hasDropShippedItems={hasDropShippedItems}
                            locale={locale}
                        />
                    </div>
                </div>

                <div style={{ pageBreakAfter: 'always' }} />
            </React.Fragment>
        );
    };

    return !!orders && !!orders.length ? <>{orders.map(renderOrder)}</> : null;
};

const strings = {
    invoice: {
        [Locale.CA_EN]: 'EQ3 - Invoice',
        [Locale.CA_FR]: `EQ3 - Facture d'achat`,
    },
    orderNumber: {
        [Locale.CA_EN]: 'Order Number',
        [Locale.CA_FR]: 'Numéro de la commande',
    },
    dateOrdered: {
        [Locale.CA_EN]: 'Date Ordered',
        [Locale.CA_FR]: 'Date de la commande',
    },
    shippingAddress: {
        [Locale.CA_EN]: 'Shipping Address',
        [Locale.CA_FR]: 'Adresse de livraison',
    },
    billingAddress: {
        [Locale.CA_EN]: 'Billing Address',
        [Locale.CA_FR]: 'Adresse de facturation',
    },
    emailAddress: {
        [Locale.CA_EN]: 'Email Address',
        [Locale.CA_FR]: 'Adresse de courriel',
    },
    paymentMethod: {
        [Locale.CA_EN]: 'Payment Method',
        [Locale.CA_FR]: 'Mode de paiement',
    },
    shipmentMethod: {
        [Locale.CA_EN]: 'Shipment Method',
        [Locale.CA_FR]: `Mode d'expédition`,
    },
    products: {
        [Locale.CA_EN]: 'Products',
        [Locale.CA_FR]: 'Produits',
    },
    model: {
        [Locale.CA_EN]: 'Model',
        [Locale.CA_FR]: 'Numéro de modèle',
    },
    price: {
        [Locale.CA_EN]: 'Price',
        [Locale.CA_FR]: 'Prix',
    },
    promotion: {
        [Locale.CA_EN]: 'Promotion',
        [Locale.CA_FR]: 'Vente',
    },
    total: {
        [Locale.CA_EN]: 'Total',
        [Locale.CA_FR]: 'Total',
    },
    subtotal: {
        [Locale.CA_EN]: 'Sub-Total',
        [Locale.CA_FR]: 'Sous-total',
    },
    shippingFee: {
        [Locale.CA_EN]: 'Shipping',
        [Locale.CA_FR]: 'Livraison',
    },
    taxes: {
        [Locale.CA_EN]: 'Taxes',
        [Locale.CA_FR]: 'Taxes',
    },
    zucoraSmart5Plan: {
        [Locale.CA_EN]: 'ZucoraHome Smart5 Plan',
        [Locale.CA_FR]: 'Le plan Smart5 de ZucoraHome',
    },
    extendProtectionPlan: {
        [Locale.CA_EN]: 'Extend Protection Plan',
        [Locale.CA_FR]: 'Plan de Protection Extend',
    },
    yes: {
        [Locale.CA_EN]: 'Yes',
        [Locale.CA_FR]: 'Oui',
    },
    no: {
        [Locale.CA_EN]: 'No',
        [Locale.CA_FR]: 'Non',
    },
    to: {
        [Locale.CA_EN]: '-',
        [Locale.CA_FR]: 'à',
    },
    weeks: {
        [Locale.CA_EN]: 'weeks',
        [Locale.CA_FR]: 'semaines',
    },
    businessDays: {
        [Locale.CA_EN]: 'business days',
        [Locale.CA_FR]: 'jours ouvrables',
    },
    deliveryEstimate: {
        [Locale.CA_EN]: 'DELIVERY ESTIMATE:',
        [Locale.CA_FR]: 'DÉLAI DE LIVRAISON ESTIMÉ:',
    },
    purchaseTime: {
        [Locale.CA_EN]: 'at the time of purchase.',
        [Locale.CA_FR]: `au moment de l'achat.`,
    },
    dropShipText: {
        [Locale.CA_EN]:
            'Made to order or drop-shipped items from our partner brands are not included in our own delivery estimates. Visit the product page for more details.',
        [Locale.CA_FR]:
            'Les articles fabriqués sur commande ou livrés directement par nos marques partenaires ne sont pas inclus dans nos propres estimations de livraison. Visitez la page du produit pour plus de détails.',
    },
};

const getShippingTypes = <K extends keyof typeof CustomerShippingTypes>(
    key: K,
    locale: Locale
): typeof shippingTypes[K][Exclude<Locale, 'US_EN'>] => {
    return shippingTypes[key][locale] || shippingTypes[key][Locale.CA_EN];
};

const shippingTypes = {
    PARCEL: {
        [Locale.CA_EN]: 'Parcel',
        [Locale.CA_FR]: 'Colis',
    },
    FREIGHT: {
        [Locale.CA_EN]: 'Freight Basic',
        [Locale.CA_FR]: 'Fret - de base',
    },
    FREIGHT_WHITEGLOVE: {
        [Locale.CA_EN]: 'Freight White Glove',
        [Locale.CA_FR]: 'Fret - Gants blancs',
    },
};

const ZUCORA_LINE_ITEM_ID = 'zucora';
type GetStringReturnType<K extends keyof typeof strings> = typeof strings[K][Exclude<Locale, 'US_EN'>];

function getString<K extends keyof typeof strings>(key: K, locale: Locale): GetStringReturnType<K> {
    return strings[key][locale] || strings[key][Locale.CA_EN];
}

const useStyles = makeStyles({
    '@global body': {
        background: 'white',
        margin: '1em',
    },
    '@global hr': {
        marginTop: 8,
        marginBottom: 8,
        border: 0,
        borderTop: '2px solid black',
    },
    grid2: {
        display: 'grid',
        gridTemplateColumns: 'max-content max-content ',
        gridGap: '16px',
        '& > *:nth-child(odd)': {
            fontWeight: 'bold',
        },
    },
    grid2r: {
        display: 'grid',
        gridTemplateColumns: 'max-content max-content ',
        gridRowGap: '8px',
        gridColumnGap: '24px',
        marginRight: '16px',
        '& > *:nth-child(odd)': {
            fontWeight: 'bold',
        },
        '& > *:nth-child(even)': {
            textAlign: 'right',
        },
        justifyContent: 'flex-end',
    },
    grid4f: {
        display: 'grid',
        gridTemplateColumns: '2fr 1fr 1fr 1fr',
        gridGap: '16px',
        marginRight: '16px',
        '& > *:nth-child(-n+4)': {
            fontWeight: 'bold',
        },
        '& > *:nth-child(4n+3), > *:nth-child(4n+4)': {
            justifySelf: 'flex-end',
            textAlign: 'right',
        },
    },
    grid5f: {
        display: 'grid',
        gridTemplateColumns: '2fr 1fr 1.5fr 1fr 1fr',
        gridGap: '16px',
        marginRight: '16px',
        '& > *:nth-child(-n+5)': {
            fontWeight: 'bold',
        },
        '& > *:nth-child(5n+3), > *:nth-child(5n+5)': {
            justifySelf: 'flex-end',
            textAlign: 'right',
        },
    },
    grid6f: {
        display: 'grid',
        gridTemplateColumns: '2fr 1fr 1.5fr 1fr 0.5fr 1fr',
        gridGap: '16px',
        marginRight: '16px',
        '& > *:nth-child(-n+6)': {
            fontWeight: 'bold',
        },
        '& > *:nth-child(6n+3), > *:nth-child(6n+6)': {
            justifySelf: 'flex-end',
            textAlign: 'right',
        },
    },
    grid4: {
        display: 'grid',
        gridTemplateColumns: 'max-content max-content max-content max-content',
        gridGap: '16px',
        '& > *:nth-child(odd)': {
            fontWeight: 'bold',
        },
    },
    options: {
        fontStyle: 'italic',
        marginLeft: 16,
        fontSize: '0.9em',
    },
    originalPricePer: {
        textDecorationLine: 'line-through',
    },
    retailDeliveryFee: {
        display: 'none',
    },
    estimates: {
        marginTop: 16,
    },
});

export default PrintInvoiceSlip;
