import { unwrap } from '@eq3/redux/utils';
import cuid from 'cuid';
import { ThunkResult as ReduxThunkResult } from 'redux-thunk';
import { defer, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { errorNotification, notify, successNotification } from '../adminNotifications';
import { setAllGroupsForSearch, setGroups } from './actions';
import { ITagGroup, ThunkResult } from './models';

export function blankTagGroup(): ITagGroup {
    return {
        id: cuid(),
        internalName: '',
        isFacet: true,
        order: undefined,
        name: {
            CA_EN: '',
            CA_FR: '',
            US_EN: '',
        },
        active: false,
        numOfTags: 0,
    };
}

export const fetchGroups = (): ThunkResult<void> => async (dispatch, getState, api) => {
    try {
        const { searchParams } = getState().tagging;
        const { data } = await api(dispatch, getState, '/admin/tagging/groups', 'GET', undefined, { params: searchParams });
        dispatch(setGroups(data));
    } catch (e) {
        dispatch(notify(errorNotification('Error fetching tag groups', e)));
    }
};

export const fetchAllGroupsForTagSearch = (): ThunkResult<void> => async (dispatch, getState, api) => {
    try {
        const { data } = await api(dispatch, getState, '/admin/tagging/groups-all', 'GET');
        dispatch(setAllGroupsForSearch(data));
    } catch (e) {
        dispatch(notify(errorNotification('Error fetching all tag groups', e)));
    }
};

export const fetchAllGroupsForTagSearch$ = (): ReduxThunkResult<Observable<ITagGroup[]>> => (dispatch, getState, api) => {
    return defer(() => {
        return api<ITagGroup[]>(dispatch, getState, '/admin/tagging/groups-all', 'GET');
    }).pipe(
        catchError((e) => {
            dispatch(notify(errorNotification('Error fetching all tag groups', e)));
            return throwError(e);
        }),
        unwrap,
        tap((data) => dispatch(setAllGroupsForSearch(data)))
    );
};

export const saveGroup = (group: ITagGroup): ThunkResult<boolean> => async (dispatch, getState, api) => {
    group.id = group.id || cuid(); // If it's new, give it a cuid

    try {
        await api(dispatch, getState, `/admin/tagging/groups/${group.id}`, 'PUT', group);
        dispatch(notify(successNotification('Group saved')));
        return true;
    } catch (e) {
        const { response: { status, data: { message } } } = e;
        if (status === 409) {
            const [, groupName] = JSON.parse(message).split(`-`);
            dispatch(notify(errorNotification(`Duplicate group ${groupName} exists`, e)));
            return false;
        } else {
            dispatch(notify(errorNotification('Error saving tag group', e)));
            return false;
        }
    }
};

export const deleteGroup = (group: ITagGroup): ThunkResult<boolean> => async (dispatch, getState, api) => {
    try {
        await api(dispatch, getState, `/admin/tagging/groups/${group.id}`, 'DELETE');
        dispatch(notify(successNotification('Group Deleted')));
        return true;
    } catch (e) {
        const { response: { status } } = e;
        if (status === 409) {
            dispatch(notify(errorNotification(`Deleting ${group.internalName} will cause duplicates in Tags list`, e)));
            return false;
        } else {
            dispatch(notify(errorNotification('Error deleting tag group', e)));
            return false;
        }
    }
};
