import { IAuthReduxSlice, ILoggedInUser } from '@eq3/redux/auth/models/viewModels';
import { createActionCreator } from 'deox';
import { ThunkDispatch } from 'redux-thunk';
import { apiThunk as ApiThunk } from '../store';

const prefix = '[AUTH]';
const authActionCreator: typeof createActionCreator = (type, resolver) => createActionCreator(`${prefix}\\${type}` as typeof type, resolver);

const actions = {
    setLoggedInUser: authActionCreator(
        'SET_LOGGED_IN_USER',
        (resolve) => (payload: ILoggedInUser | undefined) => resolve(payload),
    ),
};

export type AuthActionsType = ReturnType<typeof actions[keyof (typeof actions)]>;
export type AuthDispatch = ThunkDispatch<IAuthReduxSlice, ApiThunk, AuthActionsType>;

export default { ...actions };
