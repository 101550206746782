import { IAuthReduxSlice, ILoggedInUser } from '@eq3/redux/auth/models/viewModels';
import { doesHavePermissions, hasAnyPermission } from '@eq3/redux/auth/utils';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AuthPermissions } from './models';

export const useAuth = () => {
    return {
        loggedInUser: useSelector<IAuthReduxSlice, ILoggedInUser | undefined>((state) => state.auth.loggedInUser),
    };
};

/**
 * This is just a convenience hook to quickly check what (role) the current user "is" based on his/her permissions.
 */
export const useUserPermissions = () => {
    const { loggedInUser } = useAuth();
    const userPermissions = loggedInUser?.permissions ?? [];

    return useMemo(() => {
        return {
            permissions: userPermissions,
            hasPermissions: (...requiredPermissions: AuthPermissions[]) => doesHavePermissions(userPermissions, requiredPermissions),
            hasAnyPermission: (...requiredPermissions: AuthPermissions[]) => hasAnyPermission(userPermissions, requiredPermissions),
        };
    }, [userPermissions]);
};
