import { IManufacturer } from '@eq3/redux/manufacturers/models';
import { apiThunk } from '@eq3/redux/store';
import { IPaginationParameters } from '@eq3/utils/pagination';
import { createActions, handleActions } from 'redux-actions';
import { errorNotification, notify, successNotification } from '../adminNotifications';

export const {setManufacturersList, setManufacturerPaginationParameters} = createActions({
    SET_MANUFACTURERS_LIST: (manufacturers: IManufacturer[]) => ({manufacturers}),
    SET_MANUFACTURER_PAGINATION_PARAMETERS: (paginationParameters: IPaginationParameters) => ({paginationParameters}),
});

export const fetchManufacturers = () => async (dispatch, getStore, api: apiThunk) => {
    const {data} = await api(dispatch, getStore, '/admin/manufacturers', 'GET');

    dispatch(setManufacturersList(data));
    return data;
};

export const saveManufacturer = (manufacturer: IManufacturer) => async (dispatch, getStore, api: apiThunk) => {
    try {
        const {data} = await api(dispatch, getStore, `/admin/manufacturers/${manufacturer.id}`, 'PUT', manufacturer);
        dispatch(notify(successNotification('Manufacturer updated')));

        const manufacturers = [...getStore().manufacturers.manufacturers];
        const indexToReplace = manufacturers.findIndex((m) => m.id === manufacturer.id);

        if (indexToReplace >= 0) {
            manufacturers.splice(indexToReplace, 1, data);
            dispatch(setManufacturersList(manufacturers));
        }
    } catch (e) {
        dispatch(notify(errorNotification('Error updating manufacturer', e)));
        throw e;
    }
};

export const createManufacturer = (manufacturer: IManufacturer) => async (dispatch, getStore, api: apiThunk) => {
    try {
        const {data} = await api(dispatch, getStore, '/admin/manufacturers', 'POST', manufacturer);
        dispatch(notify(successNotification('Manufacturer created')));

        const manufacturers = [...getStore().manufacturers.manufacturers, data];

        dispatch(setManufacturersList(manufacturers));
    } catch (e) {
        dispatch(notify(errorNotification('Error creating manufacturer', e)));
        throw e;
    }
};

export const deleteManufacturer = (manufacturer: IManufacturer) => async (dispatch, getStore, api: apiThunk) => {
    try {
        await api(dispatch, getStore, `/admin/manufacturers/${manufacturer.id}`, 'DELETE', manufacturer);
        dispatch(notify(successNotification('Manufacturer deleted')));

        const manufacturers = [...getStore().manufacturers.manufacturers];
        const indexToRemove = manufacturers.findIndex((m) => m.id === manufacturer.id);

        if (indexToRemove >= 0) {
            manufacturers.splice(indexToRemove, 1);
            dispatch(setManufacturersList(manufacturers));
        }
    } catch (e) {
        dispatch(notify(errorNotification('Error deleting manufacturer', e)));
        throw e;
    }
};

const defaultPaginationParameters: IPaginationParameters = {
    pageSize: Number.MAX_SAFE_INTEGER,
    pageNumber: 0,
    orderBy: 'name',
    orderDirection: 'ASC',
};

const defaultManufacturersState = {
    manufacturers: [] as IManufacturer[],
    paginationParameters: defaultPaginationParameters,
};

export default handleActions({
    [setManufacturersList]: (state, {payload: {manufacturers}}) => ({...state, manufacturers}),
    [setManufacturerPaginationParameters]: (state, {payload: {paginationParameters}}) => ({...state, paginationParameters}),
}, defaultManufacturersState);

export type ManufacturerState = typeof defaultManufacturersState;
