import { ISelectOption } from '@eq3/design/components/inputs/SelectField';
import { ISearchSuggestion } from '@eq3/design/components/search/models';
import { busyNotification, errorNotification, notify, successNotification } from '@eq3/redux/adminNotifications';
import { apiThunk as ApiThunk } from '@eq3/redux/store';
import { Locale } from '@eq3/utils/locales';
import { Pagination } from '@eq3/utils/pagination';
import cuid from 'cuid';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { concat, defer, iif, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, last, map, mapTo, tap } from 'rxjs/operators';
import { unwrap } from '../utils';
import {
    ActionType, setGtin,
    setList,
    setCurrentProduct
} from './actions';
import {
    IComponentItemListItem,
    IComponentItemsQuery,
    IImage,
    IPartnerItem,
    IProduct,
    IProductDefinitionGallery,
    IProductDefinitionListItem,
    IProductDefinitionListQuery,
    IProductFamily,
    IResource,
    IUpholsteryItem,
    ProductDefinitionField
} from './models';
import { IReduxState } from './reducers';

type ThunkResult<T> = ThunkAction<Promise<T>, IReduxState, ApiThunk, ActionType>;
type ObservableThunkResult<T> = ThunkAction<Observable<T>, IReduxState, ApiThunk, ActionType>;
export type ProductDefinitionDispatch = ThunkDispatch<IReduxState, ApiThunk, ActionType>;

const handleError = (error: any, dispatch: Parameters<ThunkResult<void>>[0], fallbackMessage?: string) => {
    dispatch(notify(errorNotification(error?.response?.data?.message || fallbackMessage, error)));
};

const sortImages = (product: IProduct) => {
    const { galleries } = product.definition.images;

    Object.keys(galleries).forEach((key) => {
        galleries[key].images = galleries[key].images.sort((a, b) => (a.position ?? 0) - (b.position ?? 0));
    });

    return product;
};

export const fetchProductAutoSuggestions = (field: ProductDefinitionField, searchTerm: string, limit: number = 5): ObservableThunkResult<ISearchSuggestion<ProductDefinitionField>> => (dispatch, getState, api) => {
    return defer(() => api<ISearchSuggestion<ProductDefinitionField>>(dispatch, getState, '/admin/product/search-filters/auto-suggestions', 'GET', undefined, {
        params: { field, searchTerm, limit },
    })).pipe(unwrap);
};

export const fetchProductList = (query: Partial<IProductDefinitionListQuery> = {}): ObservableThunkResult<Pagination<IProductDefinitionListItem>> => (dispatch, getState, api) => {
    return defer(() => api<Pagination<IProductDefinitionListItem>>(dispatch, getState, '/admin/product/definition', 'GET', null, {
        params: query,
    })).pipe(
        catchError((e) => {
            handleError(e, dispatch, 'Error fetching products');
            return throwError(e);
        }),
        unwrap,
        tap((data) => setList(data))
    );
};

export const fetchProduct = (id: string): ObservableThunkResult<IProduct> => (dispatch, getState, api) => {
    return defer(() => api<IProduct>(dispatch, getState, `/admin/product/definition/${id}`, 'GET')).pipe(
        catchError((e) => {
            handleError(e, dispatch, `Error fetching product: ${id}`);
            return throwError(e);
        }),
        unwrap,
        map((product) => sortImages(product)),
        tap((product) => dispatch(setCurrentProduct(product)))
    );
};

export const fetchProp65s = (): ObservableThunkResult<Array<{ id: string, locale: Locale, internalName: string, name?: string, description?: string }>> => (dispatch, getStore, api) => {
    return defer(() => api(dispatch, getStore, '/admin/compliance/prop65', 'GET')).pipe(
        catchError((e) => {
            handleError(e, dispatch, `Error fetching prop65s`);
            return throwError(e);
        }),
        unwrap,
    );
};

export const fetchGoogleCategories = (query: string = ''): ObservableThunkResult<ISelectOption[]> => (dispatch, getStore, api) => {
    return defer(() => api(dispatch, getStore, '/admin/marketing/googlecategories', 'GET', null, {
        params: {
            query,
        },
    })).pipe(
        catchError((e) => {
            handleError(e, dispatch, 'Error retrieving google categories');
            return throwError(e);
        }),
        unwrap,
        map((data) => data.map(({ id, category }) => ({ label: category, value: id })))
    );
};

export const fetchGoogleCategory = (id: number): ObservableThunkResult<ISelectOption> => (dispatch, getStore, api) => {
    return defer(() => api(dispatch, getStore, `/admin/marketing/googlecategories/${id}`, 'GET')).pipe(
        catchError((e) => {
            handleError(e, dispatch, 'Error retrieving google categories');
            return throwError(e);
        }),
        unwrap,
        map(({ category, id: googleCategoryId }) => ({ label: category, value: googleCategoryId }))
    );
};

export const fetchComponentItems = (query: IComponentItemsQuery = { pageIndex: 0, pageSize: 10 }): ObservableThunkResult<Pagination<IComponentItemListItem>> => (dispatch, getStore, api) => {
    const cleanedQuery: IComponentItemsQuery = Object.entries(query)
        .filter(([, value]) => value !== undefined && value !== null && value !== '')
        .reduce((a, [key, value]) => ({
            ...a,
            [key]: value,
        }), {});

    return defer(() => api(dispatch, getStore, `/admin/lookup/componentItems/search/${cleanedQuery.searchTerm || ''}`, 'GET', null, {
        params: cleanedQuery,
    })).pipe(
        catchError((e) => {
            handleError(e, dispatch, 'Failed to retrieve component item options');
            return throwError(e);
        }),
        unwrap,
    );
};

export const saveProduct = (product: IProduct): ObservableThunkResult<IProduct> => (dispatch, getStore, api) => {
    const { images, specsheet, ...restOfDefinition } = product.definition;

    const formData = new FormData();

    const extractNewImage = (image: IImage): IImage => {
        const { id: _id, file, url, ...restOfImage } = image;
        if (!file) {
            return image;
        }

        const id = cuid();
        formData.append('newImages', file, id);

        return {
            id,
            url: '',
            ...restOfImage,
        };
    };

    const extractNewResources = (resource: IResource): IResource => {
        const { name, file } = resource || {};
        if (!file) {
            return resource;
        }

        return Object.keys(file)
            .reduce((acc, l) => {
                if (name[l]) {
                    formData.append('newResources', file[l], name[l]);
                }
                return acc;
            }, resource);
    };

    const galleries = !!(product?.definition?.images?.galleries)
        ? Object.keys(product.definition.images.galleries)
            .reduce((acc, key) => {
                const gallery = product.definition.images.galleries[key] as IProductDefinitionGallery;

                acc[key] = {
                    ...gallery,
                    images: [...gallery.images.filter((image) => !image.file)],
                };

                return acc;
            }, {})
        : {};

    const instances = (product?.instances || [])
        .map(({ thumbnail, ...restOfInstance }) => {
            const extracted = extractNewImage(thumbnail);

            const updatedInstance = {
                ...restOfInstance,
                thumbnail: extracted,
            };

            if (extracted.fileName) {
                formData.append('thumbnailFileName', extracted.fileName);
            }

            return updatedInstance;
        });

    const saveProduct = {
        instances,
        definition: {
            ...restOfDefinition,
            images: {
                model: images?.model ? extractNewImage(images.model) : {},
                galleries,
            },
            specsheet: specsheet ? extractNewResources(specsheet) : undefined,
        },
    };

    formData.append('product', new Blob([JSON.stringify(saveProduct)], { type: 'application/json' }));

    const numberOfImageSaveRequests = Object.keys(product?.definition?.images?.galleries || {}).reduce((sum, key) => {
        const gallery = product.definition.images.galleries[key] as IProductDefinitionGallery;

        return gallery.images.filter((image) => !!image.file).length + sum;

    }, 0);

    const numberOfSaveRequests = 1 + numberOfImageSaveRequests;

    return defer(() => {
        dispatch(notify(busyNotification(`Saving Product Step 1 of ${numberOfSaveRequests} ...`)));
        return api<IProduct>(dispatch, getStore, `/admin/product`, 'POST', formData);
    }).pipe(
        catchError((e) => {
            handleError(e, dispatch, 'Error saving product...');
            return throwError(e);
        }),
        unwrap,
        map((data) => sortImages(data)),
        concatMap((data) => {
            let numberCompleted = 1;

            return iif(
                () => numberOfImageSaveRequests > 0,
                concat(...Object.entries(product.definition.images.galleries).flatMap(([key, gallery]) => {
                    const createdGallery = data.definition.images.galleries[key];
                    const newGalleryImages = gallery.images.filter((image) => !!image.file);

                    return newGalleryImages.map(({ id: _id, file, url, ...restOfImage }: IImage) => {
                        const id = cuid();
                        const formDataForImageUpload = new FormData();
                        formDataForImageUpload.append('imageFile', file!, id);

                        const captionedImage = {
                            id,
                            url: '',
                            ...restOfImage,
                        };

                        formDataForImageUpload.append('captionedImage', new Blob([JSON.stringify(captionedImage)], { type: 'application/json' }));
                        return defer(() => api<IImage>(dispatch, getStore, `/admin/product/${data.definition.id}/image-galleries/${createdGallery.id}/images`, 'POST', formDataForImageUpload))
                            .pipe(
                                tap(() => {
                                    numberCompleted += 1;
                                    dispatch(notify(busyNotification(`Saving Product Image Step ${numberCompleted} of ${numberOfSaveRequests}`)));
                                }),
                                unwrap
                            );
                    });
                })).pipe(
                    last(),
                    concatMap(() => {
                        dispatch(notify(busyNotification('Save Complete. Refreshing data...')));
                        return dispatch(fetchProduct(data.definition.id)).pipe((
                            tap(() => dispatch(notify(successNotification('Save Complete!'))))
                        ));
                    })
                ),
                defer(() => {
                    dispatch(notify(successNotification('Save Complete!')));
                    return of(data);
                })
            );
        }),
        tap((data) => dispatch(setCurrentProduct(data)))
    );
};

export const uploadComponentItemImage = (image: File): ObservableThunkResult<[string, string]> => (dispatch, getState, api) => {
    return defer(() => {
        const formData = new FormData();
        formData.append('file', image, image.name);

        return api<[string, string]>(dispatch, getState, `/admin/products/definitions/component-item/image`, 'POST', formData);
    }).pipe(
        catchError((e) => {
            handleError(e, dispatch, 'Error uploading image');
            return throwError(e);
        }),
        unwrap
    );
};

export const deleteProduct = (id: string): ObservableThunkResult<void> => (dispatch, getStore, api) => {
    return defer(() => api(dispatch, getStore, `/admin/product/definition/${id}`, 'DELETE')).pipe(
        catchError((e) => {
            handleError(e, dispatch, 'Error deleting product');
            return throwError(e);
        }),
        mapTo(undefined),
    );
};

export const fetchTextBlockVariants = (): ObservableThunkResult<string[]> => (dispatch, getState, api) => {
    return defer(() => api<string[]>(dispatch, getState, '/admin/products/definitions/text-blocks', 'GET')).pipe(
        catchError((e) => {
            handleError(e, dispatch, 'Error Text Block Variants');
            return throwError(e);
        }),
        unwrap,
    );
};

export const apiFetchColours = (locale: string ) => async (dispatch, getState, api) => {
    const { data } = await api(dispatch, getState, '/colours', 'GET', null, {
        params: {
            locale,
        },
    });

    return data;
};

export const apiFetchPartnerItem = (id: string): ObservableThunkResult<IPartnerItem> => (dispatch, getState, api) => {
    // TODO: this should go into it's own proper area, when we redo admin of partner items and uphostery items to the new model
    return defer(() => api<IPartnerItem>(dispatch, getState, `/admin/lookup/partnerItem/${id}`, 'GET')).pipe(
        catchError((e) => {
            handleError(e, dispatch, `Error fetching partnerItem: ${id}`);
            return throwError(e);
        }),
        unwrap,
    );
};

export const apiFetchUpholsteryItem = (id: string): ObservableThunkResult<IUpholsteryItem> => (dispatch, getState, api) => {
    // TODO: this should go into it's own proper area, when we redo admin of partner items and uphostery items to the new model
    return defer(() => api<IUpholsteryItem>(dispatch, getState, `/admin/lookup/upholsteryItem/${id}`, 'GET')).pipe(
        catchError((e) => {
            handleError(e, dispatch, `Error fetching upholsteryItem: ${id}`);
            return throwError(e);
        }),
        unwrap,
    );
};

export const generateGtin = (instanceId: string) => async (dispatch, getStore, api) => {
    try {
        const { data: gtin } = await api(dispatch, getStore, `/admin/product/gtin/${instanceId}/generate`, 'POST');

        // Just set the gtin on the instance in redux instead of reloading the entire instance set.
        dispatch(setGtin({ instanceId, gtin }));
    } catch (e) {
        handleError(e, dispatch, 'Error generating GTIN');
        throw e;
    }
};

export const assignGtin = (instanceId: string, gtin: string) => async (dispatch, getStore, api) => {
    try {
        const { data: responseGtin } = await api(dispatch, getStore, `/admin/product/gtin/${instanceId}/assign?gtin=${gtin}`, 'POST');

        // Just set the gtin on the instance in redux instead of reloading the entire instance set.
        dispatch(setGtin({ instanceId, gtin: responseGtin }));
    } catch (e) {
        handleError(e, dispatch, 'Error assigning GTIN');
        throw e;
    }
};

export const fetchManufacturers = (): ObservableThunkResult<Array<{ id: string, name: string; description: string; }>> => (dispatch, getState, api) => {
    return defer(() => api(dispatch, getState, '/admin/manufacturers', 'GET')).pipe(
        catchError((e) => {
            handleError(e, dispatch, 'Error fetching manufacturers');
            return throwError(e);
        }),
        unwrap,
    );
};

export const fetchProductFamilyGroups = (): ObservableThunkResult<IProductFamily[]> => (dispatch, getState, api) => {
    return defer(() => api<IProductFamily[]>(dispatch, getState, '/admin/products/definitions/family-groups', 'GET')).pipe(
        catchError((e) => {
            handleError(e, dispatch, 'Error fetching family groups');
            return throwError(e);
        }),
        unwrap
    );
};

export const saveProductFamilyGroup = (familyGroup: IProductFamily): ObservableThunkResult<IProductFamily> => (dispatch, getState, api) => {
    return defer(() => api<IProductFamily>(dispatch, getState, '/admin/products/definitions/family-groups', 'POST', familyGroup)).pipe(
        catchError((e) => {
            handleError(e, dispatch, 'Error saving family group');
            return throwError(e);
        }),
        unwrap
    );
};

export const deleteProductFamilyGroup = (id: string): ObservableThunkResult<void> => (dispatch, getState, api) => {
    return defer(() => api<void>(dispatch, getState, `/admin/products/definitions/family-groups/${id}`, 'DELETE')).pipe(
        catchError((e) => {
            handleError(e, dispatch, 'Error deleting family group');
            return throwError(e);
        }),
        unwrap
    );
};