import * as colors from '@material-ui/core/colors';
import { Overrides } from '@material-ui/core/styles/overrides';
import { texxtStyles } from '@eq3/design/theme/typography';
import eq3Colours from './colours';
import palette from './palette';
import shape from './shape';
import spacing from './spacing';
import colours from '@eq3/design/theme/colours';
import typography from '@eq3/design/theme/typography';

const overrides: Overrides = {
    MuiBackdrop: {
        root: {
            backgroundColor: 'rgba(51, 51, 51, .9)'
        },
    },
    MuiButton: {
        contained: {
            padding: '6px 16px',
            minWidth: '100px',

            '&$disabled': {
                color: '#FFF',
                backgroundColor: 'rgba(13, 71, 161, .3)',
            },
        },
        containedPrimary: {
            height: '38px',
            '&:hover, &:focus, &:active': {
                backgroundColor: palette.primary.light,
            },
        },
        outlined: {
            padding: '6px 16px',
            textTransform: 'uppercase',
            minWidth: '100px',

            '&$disabled': {
                border: `1px solid ${palette.primary.main}`,
                color: palette.primary.main,
                backgroundColor: 'rgba(13, 71, 161, .3)',
            },
        },
        outlinedPrimary: {
            border: `1px solid ${palette.primary.main}`,
            '&:hover, &:focus, &:active': {
                backgroundColor: '#E8F0F9',
            },
        },
        textPrimary: {
            '&:hover, &:focus, &:active': {
                backgroundColor: '#E8F0F9',
            },
        },
        sizeSmall: {
            height: '24px',
            fontSize: '12px',
            textTransform: 'capitalize',
        },
        label: {
            whiteSpace: 'nowrap',
        },
        root: {
            textTransform: 'none',
        }
    },
    MuiCard: {
        root: {
            boxShadow: 'none',
            borderRadius: '12px',
            padding: spacing(2),
        },
    },
    MuiCardHeader: {
        root: {
            padding: 0,
            alignItems: 'flex-start',
        },
        action: {
            margin: '0px !important',
        },
    },
    MuiCardContent: {
        root: {
            padding: 0,
            '&:last-child': {
                paddingBottom: 0,
            },
        },
    },
    MuiCheckbox: {
        root: {
            color: '#C4C9CC',
        },
        indeterminate: {
            color: palette.primary.main,
        },
    },
    MuiCollapse: {
        wrapper: {
            padding: spacing(0),
        },
    },
    MuiDialogTitle: {
        root: {
            fontSize: '16px',
            lineHeight: '20px',
            letterSpacing: '-0.05px',
            color: '#263238',
        },
    },
    MuiDialogContent: {
        root: {
            padding: spacing(3),
            borderTop: '1px solid #EEEEEE',
            borderBottom: '1px solid #EEEEEE',
        },
    },
    MuiDialogContentText: {
        root: {
            fontSize: '14px',
            lineHeight: '21px',
            letterSpacing: '-0.05px',
            color: '#263238',
            marginBottom: 0,
        },
    },
    MuiDialogActions: {
        root: {
            padding: spacing(2, 3),
        },
        spacing: {
            '& > :not(:first-child)': {
                marginLeft: spacing(2),
            },
        },
    },
    MuiLinearProgress: {
        root: {
            borderRadius: 3,
            overflow: 'hidden',
        },
    },
    MuiSnackbar: {
        root: {
            borderRadius: shape.borderRadius,
            boxShadow: '0px 0px 40px 0px #00000080',
        },
    },
    MuiSvgIcon: {
        root: {
            fontSize: '16px',
        },
    },
    MuiInputBase: {
        root: {
            border: 'none',
        },
        input: {
            ...texxtStyles.inputAndButton,
            '&::placeholder': {
                opacity: 1,
            },
        },
    },
    MuiInputLabel: {
        formControl: {
            ...texxtStyles.label,
            transform: 'translate(4px, 24px) scale(1)',
            zIndex: 1,
            pointerEvents: 'none',
        },
    },
    MuiInput: {
        formControl: {
            background: eq3Colours.white,
            borderRadius: shape.borderRadius,
            zIndex: 0,
        },
        underline: {
            '&:before': {
                borderBottom: '1px solid rgba(0,0,0,0.12)',
                borderRadius: shape.borderRadius,
            },
        },
    },
    MuiSelect: {
        select: {
            fontSize: '14px',
            '&:focus': {
                background: 'inherit',
                borderRadius: 'inherit',
            },
        },
    },
    MuiSwitch: {
        colorPrimary: {
            color: eq3Colours.subdued, // Center of slider
            backgroundColor: 'transparent !important', // Ripple around slider
            '&$checked': {
                color: eq3Colours.primary, // Center of Slider when Checked
            },
            '&$colorPrimary + $track': {
                backgroundColor: eq3Colours.white, // Track when unchecked
            },
            '&$checked + $track': {
                backgroundColor: eq3Colours.white, // Track when checked
            },
        },
        // Use when page background is white
        colorSecondary: {
            color: eq3Colours.subdued, // Center of slider
            backgroundColor: 'transparent !important', // Ripple around slider
            '&$checked': {
                color: eq3Colours.primary, // Center of Slider when Checked
            },
            '&$colorSecondary + $track': {
                backgroundColor: eq3Colours.border, // Track when unchecked
            },
            '&$checked + $track': {
                backgroundColor: `${eq3Colours.backgroundDarker} !important`, // Track when checked
            },
        },
    },
    MuiDivider: {
        root: {
            backgroundColor: palette.divider,
        },
    },
    MuiListSubheader: {
        inset: {
            paddingTop: 16,
            paddingBottom: 16,
            paddingLeft: 32,
        },
    },
    MuiListItemText: {
        inset: {
            '&:first-child': {
                paddingLeft: 16,
            },
        },
    },
    MuiListItemIcon: {
        root: {
            minWidth: 32,
        },
    },
    MuiAccordion: {
        root: {
            background: 'transparent',
            marginTop: 0,
            marginBottom: 4,
            '&$expanded': {
                marginTop: 0,
                marginBottom: 4,
            },
        },
    },
    MuiAccordionSummary: {
        root: {
            background: 'white',
            height: '44px',
            minHeight: '44px',
            '&$expanded': {
                background: 'transparent',
            },
        },
    },
    MuiTableHead: {
        root: {
            '& .MuiTableCell-head': { 
                // Without this specificity, MuiTableCell's style overrides this one
                backgroundColor: eq3Colours.white,
                ...texxtStyles.tableHeading,
            },
        },
    },
    MuiTableSortLabel: {
        root: {
            '&$active': {
                color: eq3Colours.primary,
                '&& $icon': {
                    opacity: 1,
                    color: eq3Colours.primary,
                },
            },
        },
        icon: {
            opacity: 1,
        }
    },
    MuiTableRow: {
        root: {
            height: '44px',
            borderTopWidth: '0px',
            borderBottom: 'solid',
            borderBottomWidth: '1px',
            borderBottomColor: eq3Colours.border,
            backgroundColor: eq3Colours.white,
            '&:hover, &:focus, &:active': {
                backgroundColor: eq3Colours.backgroundDarker,
            },
        },
        head: {
            '&:hover, &:focus, &:active': {
                backgroundColor: 'inherit',
            },
        },
    },
    MuiTableCell: {
        root: {
            paddingTop: '8px',
            paddingBottom: '8px',
            borderBottom: 'inherit',
            ...texxtStyles.body,
        },
    },
    MuiTablePagination: {
        root: {
            backgroundColor: eq3Colours.white,
            ...texxtStyles.tableHeading,
        },
        toolbar: {
            height: '44px',
            minHeight: '44px',
        }
    },
    MuiTabs: {
        root: {
            backgroundColor: colours.backgroundDarker,
            borderRadius: '12px',
            width: 'max-content',
            minHeight: '44px',
        },
        indicator: {
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'transparent',
            height: '3px',
            '& > span': {
                maxWidth: '24px',
                width: '100%',
                backgroundColor: colours.main,
            },
        },
        flexContainer: {
            '& > :first-child': {
                paddingLeft: '16px',
            },
            '& > :last-child': {
                paddingRight: '16px',
            },
        },
    },
    MuiTab: {
        root: {
            minHeight: '44px',
            // Kind of a hack. This get overridden by an MUI breakpoint unless we use !important.
            minWidth: '0px !important',
            paddingLeft: '12px',
            paddingRight: '12px',

            '&$selected': {
                '& > span': {
                    fontWeight: 700
                },
            },
        },
        wrapper: {
            ...typography.body1,
            textTransform: 'none',
            color: colours.main,
            fontSize: '16px',
        },
        textColorInherit: {
            opacity: 'unset',
        },
    },
    MuiChip: {
        root: {
            backgroundColor: 'rgba(0,0,0,0.075)',
        },
        deleteIconColorPrimary: {
            color: colors.common.white,
        },
    },
    MuiPopover: {
        paper: {
            boxShadow: '0px 0px 40px rgba(0, 0, 0, 0.5)',
            borderRadius: '12px',
        },
    },
    MuiCssBaseline: {
        '@global': {
            html: {
                fontFamily: 'Poppins',
            },
        },
    },
};

export default overrides;
