import createAuth0Client, {
    Auth0Client,
    LogoutOptions,
    RedirectLoginOptions,
    RedirectLoginResult
} from '@auth0/auth0-spa-js';
import actions, { AuthActionsType } from '@eq3/redux/auth/actions';
import { IAuthReduxSlice, ILoggedInUser } from '@eq3/redux/auth/models/viewModels';
import jwtDecode from 'jwt-decode';
import { ThunkResult } from 'redux-thunk';
import { defer, EMPTY, forkJoin, from, iif, Observable, of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, tap } from 'rxjs/operators';
import * as FullStory from '@fullstory/browser';

type AuthThunkResult<T> = ThunkResult<Observable<T>, IAuthReduxSlice, AuthActionsType>;

const { setLoggedInUser } = actions;

let _cachedAuth0Client: Auth0Client;

const auth0Client = iif(
    () => !!_cachedAuth0Client,
    defer(() => of(_cachedAuth0Client)),
    defer(() =>
        from(createAuth0Client({
            domain: process.env.AUTH0_DOMAIN!,
            client_id: process.env.AUTH0_CLIENT_ID!,
            audience: process.env.AUTH0_AUDIENCE!,
            redirect_uri: process.env.AUTH0_REDIRECT_URI,
        })).pipe(tap((client) => {
            _cachedAuth0Client = client;
        }))),
);

export const isAuthenticated = (): AuthThunkResult<boolean> => () => {
    return auth0Client.pipe(
        mergeMap((client) => from(client.isAuthenticated())),
    );
};

export const login = (loginOptions?: RedirectLoginOptions): AuthThunkResult<void> => () => {
    return auth0Client.pipe(
        mergeMap((client) => client.loginWithRedirect(loginOptions)),
        tap(() => console.log(window.location)),
    );
};

export const logout = (logoutOptions?: LogoutOptions): AuthThunkResult<void> => () => {
    return auth0Client.pipe(
        mergeMap((client) => {
            console.log('logging out...');
            // Once we're logged out, we'll be redirected back to back to the home page (or to the returnTo link if provided),
            // and our auth credentials will be refreshed and set in redux.
            client.logout({ ...logoutOptions, returnTo: logoutOptions?.returnTo ?? window.location.origin });
            return EMPTY;
        }),
    );
};

export const refreshUserCredentials = (): AuthThunkResult<ILoggedInUser> => (dispatch) => {
    console.log('Refreshing auth token.');
    return auth0Client.pipe(
        // Fetch access token first.
        mergeMap((client) => from(client.getTokenSilently())
            // Then grab the user info (now that the auth token is refreshed).
            .pipe(concatMap((accessToken) => from(client.getUser())
                .pipe(map((user) => ({ user, accessToken }))))
            )),
        mergeMap(({ user, accessToken }) => {
            // NOTE: The parsed JWT access token can pretty much contain anything. We're specifically looking for the
            // "permissions" claim (it's just an array of strings) that Auth0 provides us;
            // sub is the auth0 given id of the user
            // exp is the expiration of the token
            const { permissions = [], exp } = (jwtDecode(accessToken) ?? {});

            return forkJoin({
                user: of(user),
                expiryTimeMs: iif(() => !!exp, of(exp * 1000), of(0)), // Auth0 gives us the token expiry time in seconds, but we want it in milliseconds.
                accessToken: of(accessToken),
                permissions: of(permissions),
            });
        }),
        map(({ user, accessToken, permissions, expiryTimeMs }) => {
            const loggedInUser: ILoggedInUser = {
                user,
                accessToken,
                permissions,
                expiryTimeMs,
            };
            return loggedInUser;
        }),
        tap((loggedInUser) => {
            FullStory.identify(
                loggedInUser.user.sub,
                {
                    email: loggedInUser.user.email,
                    name: loggedInUser.user.name,
                },
            );
        }),
        tap((loggedInUser) => {
            dispatch(setLoggedInUser(loggedInUser));
        }),
        catchError((err) => {
            // If refreshing your access token fails with this error, the only real "fix" for this
            // is to log back in. Else, all subsequent requests will just keep failing.
            if (err?.error === 'login_required') {
                console.error('Could not refresh token. Redirecting user to login splash.', err);
                return dispatch(logout())
                    .pipe(concatMap(() => EMPTY));
            }
            throw err;
        })
    );
};

export const handleRedirectCallback = (url?: string): AuthThunkResult<RedirectLoginResult> => () => {
    return auth0Client.pipe(
        mergeMap((client) => client.handleRedirectCallback(url)),
    );
};
