import { defaultPageSize, Pagination } from '@eq3/utils/pagination';
import { createReducer } from 'deox';
import { setFamilies, setJumps } from './actions';
import { IPriceCostJump } from './models';

interface IInitialState {
    jumpData: Pagination<IPriceCostJump>;
    families: string[];
}

export type IPriceCostJumpState = IInitialState;
export interface IPriceCostJumpReduxSlice { upholsteryPriceCostJumps: IPriceCostJumpState; }

export const initialState: IInitialState = {
    jumpData: {
        items: [],
        page: 0,
        pageSize: defaultPageSize,
        totalCount: 0,
    },
    families: [],
};

export default createReducer(initialState, (handle) => ([
    handle(setJumps, (state, { payload: jumpData }) => ({
        ...state,
        jumpData,
    })),
    handle(setFamilies, (state, { payload: families }) => ({
        ...state,
        families,
    })),
]));
