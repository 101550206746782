import {ClearButton} from '@eq3/containers/retail/priceTags/components/Buttons';
import { StyleRules, WithStyles } from '@material-ui/core/styles';
import React from 'react';
import {compose} from 'redux';
import ProductDroppable from '../../../components/ProductDroppable';
import SelectMenu from '../../../components/SelectMenu';
import RugCardTag from './RugCardTag';

import { withStyles } from '@material-ui/core';

interface IProps {
    menu: any;
}
class RugPartialTag extends React.Component<WithStyles<ReturnType<typeof styles>> & IProps> {
    public state = {
        product: undefined,
    };

    private handleClearTag = () => {
        this.setState({product: undefined});
    };

    private handleProductSelected = (menuItemPath) => {
        if (menuItemPath[menuItemPath.length - 1].data) {
            this.handleProduct(menuItemPath[menuItemPath.length - 1].data);
        }
    }

    private handleProduct = (product) => {
        this.setState({product});
    }

    public render(): JSX.Element {
        const {classes, menu} = this.props;
        const {product} = this.state;

        return (
            <ProductDroppable onProductDropped={this.handleProduct}>
                <div className={classes.tag}>
                    {!product &&
                    <SelectMenu itemTree={menu.itemTree} onLeafNodeSelected={this.handleProductSelected}/>
                    }
                    {product && (
                        <>
                            <RugCardTag product={product}/>
                            <ClearButton className={classes.clearTagButton} onClick={this.handleClearTag}/>
                        </>
                    )}
                </div>
            </ProductDroppable>
        );
    }
}

const styles = () => ({
    tag: {
        width: '4in',
        height: '8in',
        margin: '0 0.0625in',
        boxShadow: '0 0 0 1px silver',
        position: 'relative',
        '@media print': {
            boxShadow: 'unset',
        },
    },
    clearTagButton: {
        position: 'absolute',
        top: '0',
        right: '0',
        transform: 'translateY(-50%)',
    },
}) as StyleRules;

export default (compose(
    withStyles(styles),
)(RugPartialTag)) as React.ComponentType<IProps>;
