import { ILeadTimeEstimate } from '@eq3/redux/leadTimeManagement/models';
import { createAction as $createAction } from 'deox';

const prefix = '[LEAD_TIME_MANAGEMENT]';
const createAction: typeof $createAction = (type, executor) => $createAction(`${prefix}\\${type}` as typeof type, executor);

export const setLeadTimeEstimates = createAction('UPDATE_LEAD_TIME_ESTIMATES', (resolve) => {
    return (leadTimeEstimates: ILeadTimeEstimate[]) => resolve({ leadTimeEstimates });
});

export type LeadTimeActionsType =
    | ReturnType<typeof setLeadTimeEstimates>;
