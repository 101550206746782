import SelectField, { SelectFieldProps } from '@eq3/component/form/v2/SelectField';
import { CountryMap } from '@eq3/utils/locales';
import React, { useMemo } from 'react';

const CountrySelectField = (props: Omit<SelectFieldProps, 'options'>) => {

    const countrySelectOptions = useMemo<SelectFieldProps['options']>(() => {
        return [...CountryMap.entries()].map(([key, value]) => {
            return {
                label: value,
                value: key,
            };
        });
    }, []);

    return (
        <SelectField
            label="Country"
            {...props}
            options={countrySelectOptions}
        />
    );
};

export default CountrySelectField;
