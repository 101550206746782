import { TerminalLocationDtoSquareLocationId, TerminalLocationDtoStripeLocationId } from '@eq3-aws/payments-client';
import { Countries } from '@eq3/utils/locales';
import { createAction as $createAction } from 'deox';
import { ThunkDispatch } from 'redux-thunk';
import { IPosReduxSlice } from './reducers';
import { apiThunk } from '../store';

const prefix = '[POS]';
const createAction: typeof $createAction = (type, executor) => $createAction(`${prefix}\\${type}` as typeof type, executor);

export const setTerminalLocations = createAction('SET_TERMINAL_LOCATIONS', (resolve) => {
    return (country: Countries, terminalLocations: TerminalLocationDtoStripeLocationId[]) => resolve({ country, terminalLocations });
});

export const setSquareTerminalLocations = createAction('SET_SQUARE_TERMINAL_LOCATIONS', (resolve) => {
    return (country: Countries, squareTerminalLocations: TerminalLocationDtoSquareLocationId[]) => resolve({ country, squareTerminalLocations });
});

export type PosActionsType =
    | ReturnType<typeof setTerminalLocations>
    | ReturnType<typeof setSquareTerminalLocations>;

export type PosDispatch = ThunkDispatch<IPosReduxSlice, apiThunk, PosActionsType>;

