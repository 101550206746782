import React from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';
import {i18n, ILabels} from '../util/i18n';

interface IProps {
    labelId?: keyof ILabels | string;
}

const localizedString = ({locale, labelId, localizedPropReader}) => {
    return <>{(labelId && i18n[locale.id][labelId]) || localizedPropReader.data[localizedPropReader.localeKeys[locale.id]]}</>;
};

const mapStateToProps = (state) => ({
    locale: state.priceTags.locale,
});

export default (compose(connect(mapStateToProps))(localizedString)) as React.ComponentType<IProps>;
