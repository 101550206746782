import { DependencyList, EffectCallback, useEffect, useState } from 'react';
import { debounceTime, tap } from 'rxjs/operators';
import useRxSubject from './useRxSubject';

/**
 * useDebounce  
 * Creates a debounced version of the given value.
 * Useful for values that are expecting to be updated frequently (e.g. useEffect dependency)
 */
export default function useDebounce<T>(value: T, delay: number = 500) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => setDebouncedValue(value), delay);
        return () => clearTimeout(handler);
    }, [value]);

    return debouncedValue;
}

export const useDebounceEffect = (effect: EffectCallback, options: { deps?: DependencyList; delay?: number }) => {
    const { deps = [], delay = 500 } = options;
    const [debouncedDeps, setDebouncedDeps] = useState(deps);

    useEffect(effect, debouncedDeps);

    const debouncer = useRxSubject<DependencyList>((o) => {
        return o.pipe(
            debounceTime(delay),
            tap((deps) => setDebouncedDeps((previous) => {
                return (previous.length !== deps.length || deps.some((x, i) => previous[i] !== x))
                    ? deps
                    : previous;
            }))
        ).subscribe();
    });

    debouncer.next(deps);
};
