import { WithStyles } from '@material-ui/core/styles';
import React from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateTagFile } from '../../../../../../redux/priceTags';
import LocalizedString from '../../../components/LocalizedString';
import { ITagSize } from '../../Tags';
import TagUploader from './TagUploader';

import { createStyles, List, MenuItem, Typography, withStyles } from '@material-ui/core';

interface IProps {
    tagSize: ITagSize;
}

type Props = IProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> &
    WithStyles<typeof styles>;

interface IState {
    selectedTagId: string;
}

class TagSizeUploader extends React.Component<Props, IState> {
    public state = {
        selectedTagId: '',
    };

    private handleTagChange = (selectedTagId: string) => () => {
        this.setState({selectedTagId});
    };

    public render() {
        const {tagSize, classes} = this.props;
        const {selectedTagId} = this.state;

        const hasMoreThanOneTag = tagSize.tags.length > 1;
        const selectedTag = hasMoreThanOneTag
            ? selectedTagId && tagSize.tags.find(({id}) => id === selectedTagId)
            : tagSize.tags[0];

        return (
            <div className={classes.container}>
                {hasMoreThanOneTag && (
                    <div className={classes.tagSelector}>
                        <Typography variant="h5"><LocalizedString labelId="selectTag"/></Typography>
                        <List>
                            {tagSize.tags.filter(({requiresDataSource}) => requiresDataSource).map((tag) => (
                                <MenuItem button key={tag.id} onClick={this.handleTagChange(tag.id)}
                                          selected={selectedTagId === tag.id}>
                                    <LocalizedString labelId={tag.labelId}/>
                                </MenuItem>
                            ))}
                        </List>
                    </div>
                )}
                <div className={classes.uploader}>
                    {selectedTag && ((selectedTag.getTagUploaderElement && selectedTag.getTagUploaderElement()) || (
                        <TagUploader tag={selectedTag} previewComponent={tagSize.singleTagElement}/>))}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    locale: state.priceTags.locale,
});

const mapDispatchToProps = (dispatch) => ({
    updateTag: (tag, file, tagData) => dispatch(updateTagFile(tag, file, tagData)),
});

const styles = createStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
    },
    tagSelector: {

    },
    uploader: {

    },
});

export default (compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(TagSizeUploader)) as React.ComponentType<IProps>;
