import React from 'react';

import {connect} from 'react-redux';
import {compose} from 'redux';
import {i18n, Locale} from '../util/i18n';

interface IProps {
    superCents?: boolean | string;
    classes?: IClasses;
    price: {
        value: number | string;
        modifier?: string;
    };
}

interface IClasses {
    superCents?: string;
}

const numToStr = (num: number): string[] => {
    let str = num.toFixed(2);

    if (str.indexOf('.00') !== -1) {
        str = str.slice(0, -3);
    }

    return str.split('.');
};

class LocalizedPrice extends React.Component<IProps & ReturnType<typeof mapStateToProps>> {

    public render(): JSX.Element {
        const {superCents, locale, price: {value, modifier}, classes} = this.props;
        let dollars: string | undefined;
        let cents: string | undefined;
        let prefix: string = modifier || '';

        if (typeof value === 'number') {
            [ dollars, cents ] = numToStr(value);
        } else {
            const firstChar = value && value.charAt(0);
            if (firstChar === '+' || firstChar === '-') {
                prefix = firstChar;
                [ dollars, cents ] = numToStr(parseFloat(value.slice(1)));
            } else {
                const asNumber = parseFloat(value);

                if (isNaN(asNumber)) {
                    return <>{i18n[locale.id].not_applicable}</>;
                } else {
                    [ dollars, cents ] = numToStr(asNumber);
                }
            }
        }

        const dollarsValue = ((dollars && parseInt(dollars)) || 0);
        const centsValue = ((!!cents && parseInt(cents) / 100));

        if (!superCents) {
            const value = dollarsValue + centsValue;
            return <>{prefix + ' '}{locale.formatCurrency(value)}</>;
        }

        const dollarValueString = locale.formatCurrency(dollarsValue, false);
        return (
            <>
                {prefix + ' '}{(locale === Locale.en_CA || locale === Locale.en_US) && '$'}
                {dollarValueString}
                <sup className={classes && classes.superCents}>
                    {centsValue * 100}
                </sup>
                {locale === Locale.fr_CA && '$'}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    locale: state.priceTags.locale,
});

export default (compose(
    connect(mapStateToProps),
)(LocalizedPrice)) as any as React.ComponentType<IProps>;
