import { StyleRules, WithStyles } from '@material-ui/core/styles';
import React from 'react';

import {compose} from 'redux';
import LocalizedString from '../../../components/LocalizedString';
import PriceInput from '../../../components/PriceInput';
import {i18n, ILocale} from '../../../util/i18n';

import { withStyles } from '@material-ui/core';

interface IProps {
    locale: ILocale;
    isDescriptionConfigurable?: boolean;
}

class AsShownInput extends React.Component<IProps & WithStyles<ReturnType<typeof styles>>> {
    public state = {
        asShownInputOne: '',
        asShownInputTwo: '',
    };

    private updateAsShownInput = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }

    public render() {
        const {classes, locale, isDescriptionConfigurable} = this.props;
        const {asShownInputOne, asShownInputTwo} = this.state;
        return (
            <div className={classes.asShown}>
                <div className={classes.asShownFirstLine}>
                    <span className={classes.asShownInText}><LocalizedString labelId={isDescriptionConfigurable ? 'as_shown_in' : 'as_shown'}/>&nbsp;</span>
                    {isDescriptionConfigurable && (
                        <input className={classes.asShownInputOne} placeholder={i18n[locale.id].as_shown_upholstery_placeholder}
                            type="text"
                            value={asShownInputOne}
                            onChange={this.updateAsShownInput}
                            name="asShownInputOne"
                        />
                    )}
                </div>
                {isDescriptionConfigurable && (
                    <input className={classes.asShownInputTwo}
                        value={asShownInputTwo}
                        onChange={this.updateAsShownInput}
                        name="asShownInputTwo"
                    />
                )}
                <div className={classes.asShownPrice}>
                    <PriceInput className={classes.asShownPriceInput} />
                </div>
            </div>
        );
    }
}

const styles = () => ({
    asShownFirstLine: {
        width: '100%',
        display: 'flex',
        flexBasis: 'auto',
    },
    asShownInText: {
        fontSize: '9pt',
        whiteSpace: 'nowrap',
    },
    asShownInputOne: {
        borderWidth: '0px',
        height: '12pt',
        fontSize: '9pt',
        width: '100%',
    },
    asShownInputTwo: {
        width: '100%',
        borderWidth: '0px',
        height: '10pt',
        fontSize: '9pt',
    },
    asShownPrice: {
        width: '100%',
    },
    asShownPriceInput: {
        width: '100%',
        borderWidth: '0px',
        height: '20pt',
        fontSize: '18pt',
    },
}) as StyleRules;

export default (compose(withStyles(styles))(AsShownInput)) as React.ComponentType<IProps>;
