import { IUserDetails } from '@eq3/redux/userManagement/models/apiModels';
import { ThunkResult } from 'redux-thunk';
import { defer, iif, Observable, of } from 'rxjs';
import { mergeMapTo, tap } from 'rxjs/operators';
import { unwrap } from '../utils';
import actions from './actions';
import { IMyProfileReduxSlice } from './models';

type MyProfileThunkResult<T = any> = ThunkResult<Observable<T>>;

const baseUrl = '/admin/profile';
const { setMyProfile } = actions;

export const fetchMyProfile = (refresh: boolean = false): MyProfileThunkResult<IUserDetails> => (dispatch, getState, api) => {
    const { myProfileData }: IMyProfileReduxSlice = getState();

    return iif(
        () => refresh || !myProfileData.userDetails,
        defer(() => api<IUserDetails>(dispatch, getState, `${baseUrl}/my-profile`))
            .pipe(
                unwrap,
                tap((userDetails) => dispatch(setMyProfile(userDetails))),
            ),
        of(myProfileData.userDetails!),
    );
};

export const changeWorkingLocation = (locationId: string | undefined): MyProfileThunkResult<IUserDetails> => (dispatch, getState, api) => {
    return iif(
        () => !!locationId,
        defer(() => api<IUserDetails>(dispatch, getState, `${baseUrl}/working-location/${locationId}`, 'PUT')),
        defer(() => api<IUserDetails>(dispatch, getState, `${baseUrl}/working-location/`, 'DELETE'))
    ).pipe(
        mergeMapTo(dispatch(fetchMyProfile(true)))
    );
};
