import { SquareTerminalTransactionDetailsDto } from '@eq3-aws/payments-client';
import PaymentReceiptData from '@eq3/containers/pos-square/widgets/receipts/paymentReceipt/PaymentReceiptData';
import RefundReceiptData from '@eq3/containers/pos-square/widgets/receipts/refundReceipt/RefundReceiptData';
import { Language, LAST_SELECTED_RECEIPT_LANGUAGE_LS_KEY } from '@eq3/containers/pos-square/widgets/receipts/translations';
import { getPayment } from '@eq3/redux/pos/thunks/squarePaymentThunks';
import { CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import qs from 'qs';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { ThunkDispatch } from 'redux-thunk';

const SquareTransactionReceiptDetailsFrame = () => {
    const { paymentId } = useParams<{ paymentId: string }>();

    const { search } = useLocation();
    const query = qs.parse(search, { ignoreQueryPrefix: true });
    const refundId = query.refundId;

    const classes = useStyles();
    const dispatch = useDispatch<ThunkDispatch>();

    const [txDetails, setTxDetails] = useState<SquareTerminalTransactionDetailsDto>();
    const [error, setError] = useState<string>();

    const refundData = useMemo(() => {
        if (txDetails && refundId) {
            const refundData = txDetails?.squarePaymentCheckoutDetailsDto?.chargeData?.refunds?.find((x) => x.refundId === refundId);
            if (!refundData) {
                setError(`Could not find refund ID ${refundId}`);
            }
            return refundData;
        }
        return undefined;
    }, [refundId, txDetails]);

    useEffect(() => {
        const subscription = dispatch(getPayment(paymentId))
            .subscribe({
                next: (txDetails) => {
                    setTxDetails(txDetails);
                },
                error: (err) => {
                    console.error(err);
                    setError('Sorry. An error occurred while loading the payment.');
                },
            });

        return () => subscription.unsubscribe();
    }, []);

    useEffect(() => {
        // Prompt to print when the receipt is rendered.
        if (txDetails && !error) {
            // Needs a tiny delay so that it doesn't try to print until the title is set
            window.setTimeout(window.print, 10);
        }
    }, [txDetails, error]);

    const renderContent = () => {
        
        const key = window.localStorage.getItem(LAST_SELECTED_RECEIPT_LANGUAGE_LS_KEY);
        const language = (key !== null ? Language[key.toUpperCase()] : null) ?? Language.EN;
        
        return refundData
            ? <RefundReceiptData transactionDetails={txDetails!} refund={refundData} language={language} />
            : <PaymentReceiptData transactionDetails={txDetails!} language={language} />;
    };

    const renderError = () => (
        <Typography color="error">{error}</Typography>
    );

    const renderLoadingFrame = () => (
        <div className={classes.loadingFrame}>
            <CircularProgress size={64}/>
        </div>
    );

    return (
        <>
            {!!txDetails?.referenceNumber && (
                <Helmet>
                    <title>{`${txDetails?.storisReference}-${txDetails?.referenceNumber.toString().padStart(10, '0')}${refundData?.chargeRefundPositionReference ? '-' : ''}${refundData?.chargeRefundPositionReference || ''}.pdf`}</title>
                </Helmet>
            )}

            <div className={classes.root}>
                {!!error
                    ? renderError()
                    : !txDetails
                        ? renderLoadingFrame()
                        : renderContent()}
            </div>
        </>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '360px',
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(1),
    },
    loadingFrame: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        width: '100%',
        height: '100%',
    },
}));

export default SquareTransactionReceiptDetailsFrame;
