import { IBanner, ICarouselItem, IFeaturedCategory, IHeroImage, IMetaData, IMustHave } from '@eq3/redux/homepage/models/apiModels';
import { Locale } from '@eq3/utils/locales';
import { createAction as $createAction } from 'deox';

const prefix = '[HOMEPAGE]';
const createAction: typeof $createAction = (type, executor) => $createAction(`${prefix}\\${type}` as typeof type, executor);

// Carousel
export const addCarouselItemAction = createAction('ADD_CAROUSEL_ITEM', (resolve) => {
    return (locale: Locale, carouselItem: ICarouselItem) => resolve({ locale, carouselItem });
});
export const updateCarouselItemAction = createAction('UPDATE_CAROUSEL_ITEM', (resolve) => {
    return (locale: Locale, carouselItem: ICarouselItem) => resolve({ locale, carouselItem });
});
export const updateCarouselListAction = createAction('UPDATE_CAROUSEL', (resolve) => {
    return (locale: Locale, carouselItems: ICarouselItem[]) => resolve({ locale, carouselItems });
});
export const deleteCarouselItemAction = createAction('DELETE_CAROUSEL_ITEM', (resolve) => {
    return (locale: Locale, id: string) => resolve({ locale, id });
});

// Hero
export const addHeroImageAction = createAction('ADD_HERO_IMAGE', (resolve) => {
    return (locale: Locale, heroImage: IHeroImage) => resolve({ locale, heroImage });
});
export const updateHeroImageAction = createAction('UPDATE_HERO_IMAGE', (resolve) => {
    return (locale: Locale, heroImage: IHeroImage) => resolve({ locale, heroImage });
});
export const updateHeroListAction = createAction('UPDATE_HERO', (resolve) => {
    return (locale: Locale, heroImages: IHeroImage[]) => resolve({ locale, heroImages });
});
export const deleteHeroImageAction = createAction('DELETE_HERO_IMAGE', (resolve) => {
    return (locale: Locale, id: string) => resolve({ locale, id });
});

// Must-Haves
export const addMustHaveAction = createAction('ADD_MUST_HAVE', (resolve) => {
    return (locale: Locale, mustHave: IMustHave) => resolve({ locale, mustHave });
});
export const updateMustHaveItemAction = createAction('UPDATE_MUST_HAVE_ITEM', (resolve) => {
    return (locale: Locale, mustHave: IMustHave) => resolve({ locale, mustHave });
});
export const updateMustHavesListAction = createAction('UPDATE_MUST_HAVES_LIST', (resolve) => {
    return (locale: Locale, mustHaves: IMustHave[]) => resolve({ locale, mustHaves });
});
export const deleteMustHaveAction = createAction('DELETE_MUST_HAVE', (resolve) => {
    return (locale: Locale, id: string) => resolve({ locale, id });
});

// Featured Categories
export const addFeaturedCategoryAction = createAction('ADD_FEATURED_CATEGORY', (resolve) => {
    return (locale: Locale, featuredCategory: IFeaturedCategory) => resolve({ locale, featuredCategory });
});
export const updateFeaturedCategoryAction = createAction('UPDATE_FEATURED_CATEGORY', (resolve) => {
    return (locale: Locale, featuredCategory: IFeaturedCategory) => resolve({ locale, featuredCategory });
});
export const updateFeaturedCategoriesListAction = createAction('UPDATE_FEATURED_CATEGORIES_LIST', (resolve) => {
    return (locale: Locale, featuredCategories: IFeaturedCategory[]) => resolve({ locale, featuredCategories });
});
export const deleteFeaturedCategoryAction = createAction('DELETE_FEATURED_CATEGORY', (resolve) => {
    return (locale: Locale, id: string) => resolve({ locale, id });
});

// Banner
export const addBannerAction = createAction('ADD_BANNER', (resolve) => {
    return (locale: Locale, banner: IBanner) => resolve({ locale, banner });
});
export const updateBannerAction = createAction('UPDATE_BANNER', (resolve) => {
    return (locale: Locale, banner: IBanner) => resolve({ locale, banner });
});
export const updateBannersListAction = createAction('UPDATE_BANNERS_LIST', (resolve) => {
    return (locale: Locale, banners: IBanner[]) => resolve({ locale, banners });
});
export const deleteBannerAction = createAction('DELETE_BANNER', (resolve) => {
    return (locale: Locale, id: string) => resolve({ locale, id });
});

// MetaData
export const updateMetaDataListAction = createAction('UPDATE_METADATA_LIST', (resolve) => {
    return (data: IMetaData[]) => resolve({ data });
});

export type HomePageActionsType =
    | ReturnType<typeof addCarouselItemAction>
    | ReturnType<typeof updateCarouselItemAction>
    | ReturnType<typeof updateCarouselListAction>
    | ReturnType<typeof deleteCarouselItemAction>
    | ReturnType<typeof addHeroImageAction>
    | ReturnType<typeof updateHeroImageAction>
    | ReturnType<typeof updateHeroListAction>
    | ReturnType<typeof deleteHeroImageAction>
    | ReturnType<typeof addMustHaveAction>
    | ReturnType<typeof updateMustHaveItemAction>
    | ReturnType<typeof updateMustHavesListAction>
    | ReturnType<typeof deleteMustHaveAction>
    | ReturnType<typeof addFeaturedCategoryAction>
    | ReturnType<typeof updateFeaturedCategoryAction>
    | ReturnType<typeof updateFeaturedCategoriesListAction>
    | ReturnType<typeof deleteFeaturedCategoryAction>
    | ReturnType<typeof addBannerAction>
    | ReturnType<typeof updateBannerAction>
    | ReturnType<typeof updateBannersListAction>
    | ReturnType<typeof deleteBannerAction>
    | ReturnType<typeof updateMetaDataListAction>;
