import { WithStyles } from '@material-ui/core/styles';
import React from 'react';

import {connect} from 'react-redux';
import {compose} from 'redux';
import LocalizedPrice from '../../../components/LocalizedPrice';
import LocalizedString from '../../../components/LocalizedString';
import Grades from '../../../Grades';
import {formatLabel} from '../../../util/i18n';
import {getPrice} from '../../../util/Prices';
import AsShownInput from './AsShownInput';

import { createStyles, withStyles } from '@material-ui/core';

interface IProps {
    product: any;
    collection: any;
}
class UpholsteryTag extends React.Component<IProps & WithStyles<ReturnType<typeof styles>> & ReturnType<typeof mapStateToProps>> {

    private renderGrades = (materialType: 'fabric' | 'leather') => {  
        const {locale, product, classes} = this.props;      
        
        const grades = Object.values(Grades)
            .filter((grade) => product[grade[locale.country].name] && 
                                product[grade[locale.country].name] !== '' && 
                                product[grade[locale.country].name] !== 'N/A' && 
                                grade.material === materialType,
                    );                

        if (!grades || grades.length === 0) {
            return;
        }

        return (
            <div>
                <div className={classes.gradeHeader} ><LocalizedString labelId={materialType + '_grade'}/></div>
                {grades.map((grade, i) => {
                        const label = grade.id.replace('grade', '');                            
                        return (
                            <div className={classes.gradeRow} key={i} >
                                <div className={classes.gradeName} >
                                    {label}
                                </div>
                                <div className={classes.gradePrice}>
                                    <LocalizedPrice price={getPrice(product[grade[locale.country].name])}/>
                                </div>
                            </div>
                        );
                    })
                }             
                </div>
        );
    };

    public render() {
        const {locale, product, classes} = this.props;
        return (
            <div className={classes.container}>
                <div className={classes.name}>
                    {product[locale.id].name}
                </div>
                <div className={classes.dimensions}>{formatLabel('dimensions_fmt', locale.id, '' + product.width, '' + product.depth, '' + product.height)}</div>
                <div className={classes.from}><LocalizedString labelId="from"/></div>
                <div className={classes.fromPrice}><LocalizedPrice price={product[locale.id].startingPrice} /></div>
                <div className={classes.asShown}>
                    <AsShownInput locale={locale} isDescriptionConfigurable={true}/>
                </div>

                <div>
                <div className={classes.gradeTable}>
                        
                        {this.renderGrades('fabric')}

                        {this.renderGrades('leather')}
                                                                                            
                    </div>
                </div>
                <div className={classes.addOns}>
                    {product[locale.id].addOns}
                </div>
                <div className={classes.madeInCanada}>
                    <LocalizedString labelId="made_in_can"/>
                </div>
            </div>
        );
    }
}

const styles = () => createStyles({
    addOns: {
        fontFamily: 'eq3-heavy',
        fontSize: '8pt',
    },
    madeInCanada: {
        position: 'absolute',
        bottom: '5px',
        fontSize: '8pt',
    },
    gradeHeader: {
        fontFamily: 'eq3-book',
        textAlign: 'center',
    },    
    gradeName: {
        fontFamily: 'eq3-book',
        width: '100%',
    },
    material: {
        fontFamily: 'eq3-book',
        width: '100%',
    },
    gradeTable: {
        display: 'grid',
        gridTemplateColumns: '45% 45%',
        columnGap: '10%',
        fontSize: '8pt',
        marginBottom: '20px',
    },
    gradeRow: {
        padding: '0 8px',
        display: 'flex',
        '& > div': {
            flex: '45%',
            justifyContent: 'space-between',
        },
    },  
    gradePrice: {
        textAlign: 'right',
        width: '100%',
    },
    container: {
        height: '100%',
        '& > div:not(:first-child)': {
            marginTop: '8px',
        },
        position: 'relative',
    },
    name: {
        fontSize: '15pt',
        lineHeight: '19pt',
        fontFamily: 'eq3-heavy',
    },
    dimensions: {
        fontSize: '8pt',
        letterSpacing: '.4pt',
    },
    from: {
        fontSize: '9pt',
    },
    fromPrice: {
        fontSize: '23pt',
        marginTop: '5pt',
    },
    asShown: {
        fontSize: '9pt',
        marginTop: '20px',
    },
});

const mapStateToProps = (state) => ({
    locale: state.priceTags.locale,
});

export default (compose(
    connect(mapStateToProps),
    withStyles(styles),
)(UpholsteryTag)) as any as React.ComponentType<IProps>;
