import { useCallback, useState } from 'react';

// these are state refs. refs become available as soon the component is mounted
// typically, when a mutable ref updates, the components they are attached to don't know that it did because
// refs are just mutable objects that can hold anything.
// using state instead of useRef fixes this problem.
// see: https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node for more details
const useRefState = <T extends any = any>(): [T | null, (ref: T) => void] => {
    const [ref, setRef] = useState<T | null>(null);

    const setRefCallback = useCallback((ref) => {
        if (ref !== null) { setRef(ref); }
    }, []);

    return [ref, setRefCallback];
};

export default useRefState;
