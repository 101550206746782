import {makeStyles} from '@material-ui/core/styles';
import React, {useMemo, useState} from 'react';

import PriceInput from '../../../components/PriceInput';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        height: '100%',
        padding: '9pt 0.125in',
        '& input, textarea': {
            borderWidth: '0px',
        },
    },
    name: {
        fontFamily: 'eq3-book',
        fontSize: '23pt',
        lineHeight: '23pt',
        textTransform: 'uppercase',
        width: '100%',
    },
    descriptionPricingContainer: {
        display: 'flex',
        marginTop: '3px',
        width: '100%',
        height: '100%',
    },
    descriptionInput: {
        fontFamily: 'eq3-book',
        fontSize: '10pt',
        lineHeight: '28px',
        width: '100%',
        resize: 'none',
        overflow: 'hidden',
        height: '100%',
        marginRight: '7px',
        paddingTop: 0,
        '&::first-line': {
            lineHeight: '21px',
            verticalAlign: 'bottom',
        },
        '&::before': {
            content: '""',
        },
    },
    pricingsContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    pricing: {
        display: 'flex',
    },
    pricingNameInput: {
        marginRight: '5px',
        fontFamily: 'eq3-book',
        fontSize: '10pt',
        width: '100%',
        textTransform: 'uppercase',
    },
    priceInput: {
        width: '100%',
        fontFamily: 'eq3-book',
        fontSize: '10pt',
        textAlign: 'right',
        flex: '0 1 70%',
    },
}));
const FillableShelfTalkerTag = () => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    const [priceName1, setPriceName1] = useState('');
    const [priceName2, setPriceName2] = useState('');
    const [priceName3, setPriceName3] = useState('');

    const classes = useStyles();

    const handleChange = (setter: (string) => void) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setter(e.target.value);
    };

    const priceNames: Array<[string, (string) => void]> = useMemo(() => [
        [priceName1, setPriceName1],
        [priceName2, setPriceName2],
        [priceName3, setPriceName3],
    ], [priceName1, priceName2, priceName3]);

    return (
        <div className={classes.container}>
            <input type="text" className={classes.name} name="name" value={name} onChange={handleChange(setName)}/>
            <div className={classes.descriptionPricingContainer}>
                    <textarea className={classes.descriptionInput} name="description" value={description}
                          onChange={handleChange(setDescription)} maxLength={68}/>
                <div className={classes.pricingsContainer}>
                    {[0, 1, 2].map((index) => (
                        <div className={classes.pricing} key={index}>
                            <input type="text" className={classes.pricingNameInput} name="pricingName"
                                   value={priceNames[index][0]} onChange={handleChange(priceNames[index][1])}/>
                                <PriceInput className={classes.priceInput}/>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FillableShelfTalkerTag;
