export const deepCompare = (a: any, b: any): boolean => {
    if (typeof a !== typeof b) return false;
    if (a === null && b === null) return true;
    if (a === null || b === null) return false;
    if (typeof a === 'object' && Object.keys(a).length !== Object.keys(b).length) return false;
    if (typeof a !== 'function' && typeof a !== 'object') return a === b;

    return Object.keys(a).every((key) => deepCompare(a[key], b[key]));
};


export const deepCopy = <T>(obj: T): T => {
    return typeof obj === 'object' && obj !== null
        ? Array.isArray(obj)
            ? obj.reduce((a, b) => [...a, deepCopy(b)], [])
            : Object.entries(obj).reduce((a, [key, value]) => ({
                ...a,
                [key]: deepCopy(value)
            }), {})
        : obj;
};

/**
 * Deep deletes object properties which are either null or undefined.
 * _Warning_: **Modifies the passed in param. Use cautiously.**
 * @param obj - JavaScript Object
 */
export const removeEmptyProperties = (obj: { [x: string]: any; }) => Object.keys(obj)
    .forEach((key) => {
        // Empty value, remove it from the object
        if (obj[key] == null) {
            delete obj[key];
            return;
        }

        // It's not an object, with a validation error message, do nothing and return
        if (obj[key].constructor !== Object) {
            return;
        }

        // Then it's an object... remove it's empty properties
        removeEmptyProperties(obj[key]);

        // If the property object is now empty... remove it
        if (Object.keys(obj[key]).length === 0) {
            delete obj[key];
            return;
        }
    });

/**
 * Remove properties from a given object using a specific predicate
 * @param filter 
 * @example
 * const removeUndefined = removeByFilter((x) => typeof x === 'undefined');
 * ...
 * removeUndefined({ foo: 'bar', shmeh: { zaa: undefined } }); // yields { foo: 'bar', shmeh: {} }
 *
 */
export const removeByFilter = (filter: <X>(obj: X) => boolean) => <T>(obj: T): T => {
    return obj === null || typeof obj === 'undefined'
        ? obj
        : typeof obj === 'object'
            ? Array.isArray(obj)
                ? obj.reduce((a, b) => {
                    if (filter(b)) return a;

                    return [
                        ...a,
                        removeByFilter(filter)(b),
                    ];
                }, [])
                : Object.entries(obj).reduce((a, [key, value]) => {
                    if (filter(value)) return a;

                    return {
                        ...a,
                        [key]: removeByFilter(filter)(value),
                    };
                }, {})                
            : obj;    
};
