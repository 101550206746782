import { errorNotification, notify, successNotification } from '@eq3/redux/adminNotifications';
import { LeadTimeActionsType, setLeadTimeEstimates } from '@eq3/redux/leadTimeManagement/actions';
import { ILeadTimeEstimate, ILeadTimeEstimateUpdate } from '@eq3/redux/leadTimeManagement/models';
import { ILeadTimeManagementReduxSlice } from '@eq3/redux/leadTimeManagement/reducers';
import { apiThunk } from '@eq3/redux/store';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { defer, from, iif, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

type LeadTimeManagementThunkResult<T> = ThunkAction<Observable<T>, ILeadTimeManagementReduxSlice, apiThunk, LeadTimeActionsType>;
export type LeadTimeManagementDispatch = ThunkDispatch<ILeadTimeManagementReduxSlice, apiThunk, LeadTimeActionsType>;

export const getLeadTimeEstimates = (refresh: boolean = true): LeadTimeManagementThunkResult<ILeadTimeEstimate[]> => (dispatch, getState, api) => {
    return iif(
        () => refresh,
        from(api<ILeadTimeEstimate[]>(dispatch, getState, `/admin/leadTimeManagement/estimates`, 'GET', undefined))
        .pipe(
            map((response) => response.data),
            tap((estimates) => dispatch(setLeadTimeEstimates(estimates))),
            catchError((err) => {
                dispatch(notify(errorNotification('Error fetching lead time estimates.', err)));
                throw err;
            }),
        ),
        defer(() => {
            const { leadTimeManagement: { leadTimeEstimates } } = getState() as ILeadTimeManagementReduxSlice;
            return of(leadTimeEstimates);
        }),
    );
};

export const updateLeadTimeEstimates = (updates: ILeadTimeEstimateUpdate[]): LeadTimeManagementThunkResult<ILeadTimeEstimate[]> => (dispatch, getState, api) => {
    return from(api<ILeadTimeEstimate[]>(dispatch, getState, `/admin/leadTimeManagement/estimates`, 'PUT', updates))
        .pipe(
            map((response) => response.data),
            tap((estimates) => {
                dispatch(setLeadTimeEstimates(estimates));
                dispatch(notify(successNotification('Lead time estimates updated.')));
            }),
            catchError((err) => {
                dispatch(notify(errorNotification('Error updating lead time estimates.', err)));
                throw err;
            }),
        );
};
