import { Button, ButtonBaseProps, ButtonProps, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useHistory } from 'react-router';

export interface IActionButtonProps extends ButtonProps {
    fullWidth?: boolean;
    isLoading?: boolean;
    classes?: Partial<ReturnType<typeof useStyles> & ButtonBaseProps['classes']>;
    href?: string;
}

export default function ActionButton(props: IActionButtonProps) {
    const {
        isLoading = false,
        classes: basePropsClasses,
        href,
        onClick,
        children,
        ...buttonBaseProps
    } = props;

    const classes = useStyles(props);
    const history = useHistory();

    const handleClick: ButtonBaseProps['onClick'] = (e) => {
        onClick?.(e);

        // If a link was provided, then route to the link.
        if ( href ) {
            if (href.match('^http')) {
                window.location.assign(href);
            } else {
                history.push(href);
            }
        }
    };

    return (
        <Button
            {...buttonBaseProps}
            onClick={handleClick}
            disabled={isLoading || !!buttonBaseProps?.disabled}
            classes={{root: classes.actionButton}}
        >
            <span style={{visibility: isLoading ? 'hidden' : 'visible'}}>{children}</span>
            {isLoading && (
                <div className={classes.loader}>
                    <CircularProgress size={16} color="inherit" />
                </div>
            )}
        </Button>
    );
}

const useStyles = makeStyles(() => ({
    actionButton: {}, // Overridable
    loader: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));
