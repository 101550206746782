import {IReadyToShipProductFromFile, IReadyToShipProductLocalized} from '@eq3/containers/retail/priceTags/models/readyToShip';
import {dateUtils} from '@eq3/containers/retail/priceTags/util/dates';
import {createSelector} from 'reselect';
import {default as Grades, getLowestGradePrice} from '../containers/retail/priceTags/Grades';
import {Tags, TagSizes} from '../containers/retail/priceTags/pages/Tags';
import {i18n, ILocalizedLabels, Locale} from '../containers/retail/priceTags/util/i18n';
import {getPrice} from '../containers/retail/priceTags/util/Prices';

const tagSizeReducer = (state, tagSize) => {
    const result = tagSize.tags.reduce((accumulator, tag) => {
        if (!tag.requiresDataSource) {
            return accumulator;
        }

        return {...accumulator, [tag.id]: state.priceTags.tagsData[tag.id]};
    }, {});

    if (!Object.values(result).every((data) => !!data)) {
        return undefined;
    }

    return result;
};
export const largeTagDataSelector = (state) => {
    const result = tagSizeReducer(state, TagSizes.large);

    if (!result) {
        return;
    }

    const upholsteryCollections = result[Tags.upholstery.id]
        .filter(({id}) => id !== Tags.sectionals.id)
        .reduce((accumulator, {id: upholsteryId}) =>
            ({...accumulator, [upholsteryId]: state.priceTags.tagsData[upholsteryId]}), {});
    return {...result, ...upholsteryCollections};
};
export const mediumTagDataSelector = (state) => tagSizeReducer(state, TagSizes.medium);
export const smallTagDataSelector = (state) => tagSizeReducer(state, TagSizes.small);
export const rugTagDataSelector = (state) => tagSizeReducer(state, TagSizes.rugs);
export const pillowTagDataSelector = (state) => ({...tagSizeReducer(state, TagSizes.pillows), locale: state.priceTags.locale});

export const slatsDataSelector = (state) => state.priceTags.tagsData[Tags.slats.id];

export const slatsWithPricingSelector = createSelector(
    slatsDataSelector, (slatsData) => {

        return slatsData.map((slat) => {

            const {nameEN, nameFR, price, priceUS} = slat;

            return {...slat,
                [Locale.en_CA.id]: {
                    name: nameEN,
                    price: {...getPrice(price), modifier: '+'},
                },
                [Locale.fr_CA.id]: {
                    name: nameFR,
                    price: {...getPrice(price), modifier: '+'},
                },
                [Locale.en_US.id]: {
                    name: nameEN,
                    price: {...getPrice(priceUS), modifier: '+'},
                },
            };
        });
    },
);

export const upholsteryCollectionProductMapper = ({collection, ...product}) => {

    const {includeName, nameEN: collectionNameEN, nameFR: collectionNameFR} = collection;
    const {nameEN, nameFR, addonsEN, addonsFR} = product;

    const canadianStartingPrice = getPrice(getLowestGradePrice(product, Locale.en_CA));
    const usStartingPrice = getPrice(getLowestGradePrice(product, Locale.en_US));

    return {...product,
        tagTypeId: Tags.upholstery.id,
        [Locale.en_CA.id]: {
            name: ((includeName && collectionNameEN + ' ') || '') + nameEN,
            startingPrice: canadianStartingPrice,
            addOns: addonsEN,
        },
        [Locale.fr_CA.id]: {
            name: ((includeName && collectionNameFR + ' ') || '') + nameFR,
            startingPrice: canadianStartingPrice,
            addOns: addonsFR,
        },
        [Locale.en_US.id]: {
            name: ((includeName && collectionNameEN + ' ') || '') + nameEN,
            startingPrice: usStartingPrice,
            addOns: addonsEN,
        },
    };
};
export const upholsteryCollectionTagMenuMapper = (collection, collectionProductsData) => {
    return {
        itemTree: collectionProductsData.map((collectionProduct, i) => ({ // should iterate through respective upholstery collection
            id: i,
            labels: {
                en_CA: collectionProduct.nameEN,
                fr_CA: collectionProduct.nameFR,
                en_US: collectionProduct.nameEN,
            },
            data: upholsteryCollectionProductMapper({...collectionProduct, collection}),
        })),
    };
};

export const upholsteryTagMenuMapper = (tagData) => {

    const {[Tags.upholstery.id]: upholstery} = tagData;
    const sectionalsCollection = upholstery.find((collection) => collection.id === Tags.sectionals.id);
    const configurableSectionals = upholstery.filter((collection) => collection.includeName);
    const upholsteryCollections = upholstery.filter(({id}) => id !== Tags.sectionals.id).reduce((accumulator, {id}) => ({...accumulator, [id]: tagData[id]}), {});

    return {
        itemTree: [{
            id: Tags.upholstery.id,
            labelId: Tags.upholstery.labelId,
            children: [{
                id: Tags.sectionals.id,
                labels: {
                    en_CA: sectionalsCollection.nameEN,
                    fr_CA: sectionalsCollection.nameFR,
                    en_US: sectionalsCollection.nameEN,
                },
                data: {
                    ...sectionalsCollection,
                    tagTypeId: Tags.sectionals.id,
                    en_CA: {
                        sectionals: configurableSectionals.map(({nameEN: name, collectionAddOnsEN: description}) => ({name, description})),
                    },
                    fr_CA: {
                        sectionals: configurableSectionals.map(({nameFR: name, collectionAddOnsFR: description}) => ({name, description})),
                    },
                    en_US: {
                        sectionals: configurableSectionals.map(({nameEN: name, collectionAddOnsEN: description}) => ({name, description})),
                    },
                },
            }, ...upholstery.filter((collection) => collection.id !== Tags.sectionals.id).map((collection, i) => ({
                id: i,
                labels: {
                    en_CA: collection.nameEN,
                    fr_CA: collection.nameFR,
                    en_US: collection.nameEN,
                },
                data: collection,
                children: upholsteryCollectionTagMenuMapper(collection, upholsteryCollections[collection.id] || []).itemTree,
            }))],
        }],
    };
};

export const bedProductMapper = (product) => {

    const {nameEN,
        nameFR,
        descriptionEN,
        descriptionFR,
        fromPrice,
        fromPriceUS,
        priceTableLeadInEN,
        priceTableLeadInFR,
        priceTablePrices,
        priceTablePricesUS: priceTablePricesUsString,
        PriceTableItemsEN,
        PriceTableItemsFR,
        sizingEN,
        sizingFR,
    } = product;

    const canadianStartingPrice = getPrice(fromPrice);
    const usStartingPrice = getPrice(fromPriceUS);

    const priceTablePricesCanada = priceTablePrices.split(',').map((s) => s.trim());
    const priceTablePricesUS = priceTablePricesUsString.split(',').map((s) => s.trim());

    const priceTableItemsEnglish = PriceTableItemsEN.split(',').map((item) => item.trim());
    const priceTableItemsFrench = PriceTableItemsFR.split(',').map((item) => item.trim());

    const englishCanadaPrices = priceTableItemsEnglish.map((name, i) => ({name, price: getPrice(priceTablePricesCanada[i])})).filter(({name}) => name !== '');
    const frenchCanadaPrices = priceTableItemsFrench.map((name, i) => ({name, price: getPrice(priceTablePricesCanada[i])})).filter(({name}) => name !== '');
    const englishUSPrices = priceTableItemsEnglish.map((name, i) => ({name, price: getPrice(priceTablePricesUS[i])})).filter(({name}) => name !== '');

    return {...product,
        tagTypeId: Tags.beds.id,
        [Locale.en_CA.id]: {
            name: nameEN,
            description: descriptionEN,
            startingPrice: canadianStartingPrice,
            priceTableLeadIn: priceTableLeadInEN,
            prices: englishCanadaPrices,
            sizing: sizingEN,
        },
        [Locale.fr_CA.id]: {
            name: nameFR,
            description: descriptionFR,
            startingPrice: canadianStartingPrice,
            priceTableLeadIn: priceTableLeadInFR,
            prices: frenchCanadaPrices,
            sizing: sizingFR,
        },
        [Locale.en_US.id]: {
            name: nameEN,
            description: descriptionEN,
            startingPrice: usStartingPrice,
            priceTableLeadIn: priceTableLeadInEN,
            prices: englishUSPrices,
            sizing: sizingEN,
        },
    };
};
export const bedsTagMenuMapper = (tagData) => {

    const {[Tags.beds.id]: beds} = tagData;
    return {
        itemTree: [{
            id: Tags.beds.id,
            labelId: Tags.beds.labelId,
            children: beds.map((bedProduct, i) => ({
                id: i,
                labels: {
                    en_CA: bedProduct.nameEN,
                    fr_CA: bedProduct.nameFR,
                    en_US: bedProduct.nameEN,
                },
                data: bedProductMapper(bedProduct),
            })),
        }],
    };
};

export const upholsteredBedProductMapper = (product) => {

    const {nameEN, nameFR, addonsEN, addonsFR, sizingEN, sizingFR} = product;

    const startingCanadianPrice = getPrice(getLowestGradePrice(product, Locale.en_CA));
    const startingUsPrice = getPrice(getLowestGradePrice(product, Locale.en_US));

    return {...product,
        tagTypeId: Tags.upholsteredBeds.id,
        [Locale.en_CA.id]: {
            name: nameEN,
            addons: addonsEN,
            sizing: sizingEN,
            startingPrice: startingCanadianPrice,
        },
        [Locale.fr_CA.id]: {
            name: nameFR,
            addons: addonsFR,
            sizing: sizingFR,
            startingPrice: startingCanadianPrice,
        },
        [Locale.en_US.id]: {
            name: nameEN,
            addons: addonsEN,
            sizing: sizingEN,
            startingPrice: startingUsPrice,
        },
    };
};

export const upholsteredBedTagMenuMapper = (tagData) => {

    const {[Tags.upholsteredBeds.id]: upholsteredBeds} = tagData;
    return {
        itemTree: [{
            id: Tags.upholsteredBeds.id,
            labelId: Tags.upholsteredBeds.labelId,
            children: upholsteredBeds.map((upholsteredBed, i) => ({
                id: i,
                labels: {
                    en_CA: upholsteredBed.nameEN,
                    fr_CA: upholsteredBed.nameFR,
                    en_US: upholsteredBed.nameEN,
                },
                data: upholsteredBedProductMapper(upholsteredBed),
            })),
        }],
    };
};

export const customizableProductMapper = (product) => {
    const {
        fromPrice,
        fromPriceUS,
        fromLeadInEN,
        fromLeadInFR,
        priceTableLeadInEN,
        priceTableLeadInFR,
        PriceTableItemsEN,
        PriceTableItemsFR,
        priceTablePrices,
        priceTablePricesUS,
        nameEN,
        nameFR,
        descriptionEN,
        descriptionFR,
    } = product;

    const pricesCanada = (priceTablePrices && priceTablePrices.split(',').map((s) => s.trim())) || [];
    const pricesUS = (priceTablePricesUS && priceTablePricesUS.split(',').map((s) => s.trim())) || [];

    const priceNamesEnglish = (PriceTableItemsEN && PriceTableItemsEN.split(',').map((s) => s.trim())) || [];
    const priceNamesFrench = (PriceTableItemsFR && PriceTableItemsFR.split(',').map((s) => s.trim())) || [];

    return {
        ...product,
        tagTypeId: Tags.customizableProducts.id,
        en_CA: {
            fromPrice: getPrice(fromPrice),
            fromLeadIn: fromLeadInEN,
            priceTableLeadIn: priceTableLeadInEN,
            priceTableItems: priceNamesEnglish.map((name, i) => ({
                name,
                price: getPrice(pricesCanada[i]),
            })),
            name: nameEN,
            description: descriptionEN,
        },
        fr_CA: {
            fromPrice: getPrice(fromPrice),
            fromLeadIn: fromLeadInFR,
            priceTableLeadIn: priceTableLeadInFR,
            priceTableItems: priceNamesFrench.map((name, i) => ({
                name,
                price: getPrice(pricesCanada[i]),
            })),
            name: nameFR,
            description: descriptionFR,
        },
        en_US: {
            fromPrice: getPrice(fromPriceUS),
            fromLeadIn: fromLeadInEN,
            priceTableLeadIn: priceTableLeadInEN,
            priceTableItems: priceNamesEnglish.map((name, i) => ({
                name,
                price: getPrice(pricesUS[i]),
            })),
            name: nameEN,
            description: descriptionEN,
        },
    };
};
export const customizableProductsTagMenuMapper = (tagData) => {

    const {[Tags.customizableProducts.id]: customizableProducts} = tagData;

    return {
        itemTree: [{
            id: Tags.customizableProducts.id,
            labelId: Tags.customizableProducts.labelId,
            children: customizableProducts.map((product, i) => ({
                id: product.id,
                labels: {
                    en_CA: product.nameEN,
                    fr_CA: product.nameFR,
                    en_US: product.nameEN,
                },
                data: customizableProductMapper({...product, id: i}),
            })),
        }],
    };
};

export const saleTagMenuMapper = () => ({itemTree: [{id: Tags.saleTag.id, labelId: Tags.saleTag.labelId, data: {tagTypeId: Tags.saleTag.id}}]});

export const readyToShipProductMapper = (product: IReadyToShipProductFromFile): {[locale: keyof ILocalizedLabels]: IReadyToShipProductLocalized} => {
    const now = new Date();
    return {
        ...product,
        tagTypeId: Tags.readyToShip.id,
        en_CA: {
            id: product.id,
            name: product.nameEN,
            description: product.descriptionEN,
            madeIn: product.madeInEN,
            configurations: product.configurations
                .filter(({startDate, endDate}) => dateUtils.is(now).between({
                    start: startDate && new Date(startDate),
                    end: endDate && new Date(endDate),
                }))
                .map((configuration) => ({
                    name: configuration.nameEN,
                    icon: configuration.icon,
                    dimensions: configuration.dimensions,
                    pricing: (configuration.pricing && {
                        regularPrice: getPrice(configuration.pricing.regularPriceCA),
                        salePrice: configuration.pricing.salePriceCA && getPrice(configuration.pricing.salePriceCA),
                        skus: configuration.pricing.skus.map((sku) => ({name: sku.nameEN, sku: sku.sku})),
                    }) || (configuration.subConfigurations &&
                        {
                            left: {
                                name: configuration.subConfigurations.left.nameEN,
                                price: getPrice(configuration.subConfigurations.left.priceCA),
                                skus: configuration.subConfigurations.left.skus.map((sku) => ({
                                    name: sku.nameEN,
                                    sku: sku.sku,
                                })),
                            },
                            right: {
                                name: configuration.subConfigurations.right.nameEN,
                                price: getPrice(configuration.subConfigurations.right.priceCA),
                                skus: configuration.subConfigurations.right.skus.map((sku) => ({
                                    name: sku.nameEN,
                                    sku: sku.sku,
                                })),
                            },
                        }),
                })),
        },
        fr_CA: {
            id: product.id,
            name: product.nameFR,
            description: product.descriptionFR,
            madeIn: product.madeInFR,
            configurations: product.configurations.map((configuration) => ({
                name: configuration.nameFR,
                icon: configuration.icon,
                dimensions: configuration.dimensions,
                pricing: (configuration.pricing && {
                    regularPrice: getPrice(configuration.pricing.regularPriceCA),
                    salePrice: configuration.pricing.salePriceCA && getPrice(configuration.pricing.salePriceCA),
                    skus: configuration.pricing.skus.map((sku) => ({name: sku.nameFR, sku: sku.sku})),
                }) || (configuration.subConfigurations &&
                    {
                        left: {
                            name: configuration.subConfigurations.left.nameFR,
                            price: getPrice(configuration.subConfigurations.left.priceCA),
                            skus: configuration.subConfigurations.left.skus.map((sku) => ({
                                name: sku.nameFR,
                                sku: sku.sku,
                            })),
                        },
                        right: {
                            name: configuration.subConfigurations.right.nameFR,
                            price: getPrice(configuration.subConfigurations.right.priceCA),
                            skus: configuration.subConfigurations.right.skus.map((sku) => ({
                                name: sku.nameFR,
                                sku: sku.sku,
                            })),
                        },
                    }),
            })),
        },
        en_US: {
            id: product.id,
            name: product.nameEN,
            description: product.descriptionEN,
            madeIn: product.madeInEN,
            configurations: product.configurations.map((configuration) => ({
                icon: configuration.icon,
                name: configuration.nameEN,
                dimensions: configuration.dimensions,
                pricing: (configuration.pricing && {
                    regularPrice: getPrice(configuration.pricing.regularPriceUS),
                    salePrice: configuration.pricing.salePriceUS && getPrice(configuration.pricing.salePriceUS),
                    skus: configuration.pricing.skus.map((sku) => ({name: sku.nameEN, sku: sku.sku})),
                }) || (configuration.subConfigurations &&
                    {
                        left: {
                            name: configuration.subConfigurations.left.nameEN,
                            price: getPrice(configuration.subConfigurations.left.priceUS),
                            skus: configuration.subConfigurations.left.skus.map((sku) => ({
                                name: sku.nameEN,
                                sku: sku.sku,
                            })),
                        },
                        right: {
                            name: configuration.subConfigurations.right.nameEN,
                            price: getPrice(configuration.subConfigurations.right.priceUS),
                            skus: configuration.subConfigurations.right.skus.map((sku) => ({
                                name: sku.nameEN,
                                sku: sku.sku,
                            })),
                        },
                    }),
            })),
        },
    };
};

const byStartAndEndDate = () => {
    const now = new Date();
    return ({startDate, endDate}) => dateUtils.is(now).between({start: startDate && new Date(startDate), end: endDate && new Date(endDate)});
};
export const readyToShipProductsSelector = createSelector(
    largeTagDataSelector,
    ({[Tags.readyToShip.id]: tagData}) => {

        if (!tagData) {
            return;
        }

        return tagData.filter(byStartAndEndDate());
    },
);

export const readyToShipMenuMapper = (tagData) => {
    return {
        itemTree: [{
            id: Tags.readyToShip.id,
            labelId: Tags.readyToShip.labelId,
            children: tagData[Tags.readyToShip.id]
                .filter(byStartAndEndDate())
                .map((product, i) => ({
                    id: product.id,
                    labels: {
                        en_CA: product.nameEN,
                        fr_CA: product.nameFR,
                        en_US: product.nameEN,
                    }, // can replace with labels: product.name
                    data: readyToShipProductMapper({...product, id: i}),
                })),
        }],
    };
};

export const largeTagMenuSelector = createSelector(
    largeTagDataSelector, (largeTagData) => {
        if (!largeTagData) {
            return;
        }

        const upholsteryMenu = upholsteryTagMenuMapper(largeTagData);
        const bedMenu = bedsTagMenuMapper(largeTagData);
        const upholsteredBedMenu = upholsteredBedTagMenuMapper(largeTagData);
        const customizableProductsMenu = customizableProductsTagMenuMapper(largeTagData);
        const clearanceMenu = saleTagMenuMapper();
        const readyToShipMenu = readyToShipMenuMapper(largeTagData);

        const result = {
            itemTree: [
                upholsteryMenu.itemTree[0],
                bedMenu.itemTree[0],
                upholsteredBedMenu.itemTree[0],
                customizableProductsMenu.itemTree[0],
                clearanceMenu.itemTree[0],
                readyToShipMenu.itemTree[0],
            ],
        };

        return result;
    });

const formatRugSizeString = (size: string | null): string | null => {
    if (size == null) {
        return size;
    }

    size = size
        .replace(/x/g, '×')
        .replace(/\s*ft/g, '\'')
        .replace(/\s*in/g, '\"');

    return size;
};
export const rugProductMapper = (rugProduct) => {

    const canadaSizes = [];
    const usSizes = [];

    for (let i = 1; i <= 4; i++) {
        const size = formatRugSizeString(rugProduct['size' + i]);
        const canadianSizePrice = getPrice(rugProduct['price' + i]);
        const usSizePrice = getPrice(rugProduct['price' + i + 'US']);

        if (size && size.length) {
            if (canadianSizePrice) {
                canadaSizes.push({size, price: canadianSizePrice});
            }

            if (usSizePrice) {
                usSizes.push({size, price: usSizePrice});
            }
        }
    }

    return {...
        rugProduct,
        [Locale.en_CA.id]: {
            name: rugProduct.nameEN,
            description: rugProduct.descriptionEN,
            colours: rugProduct.coloursEN,
            sizePrices: canadaSizes,
            customSizePrice: getPrice(rugProduct.customSizePrice),
        },
        [Locale.fr_CA.id]: {
            name: rugProduct.nameFR,
            description: rugProduct.descriptionFR,
            colours: rugProduct.coloursFR,
            sizePrices: canadaSizes,
            customSizePrice: getPrice(rugProduct.customSizePrice),
        },
        [Locale.en_US.id]: {
            name: rugProduct.nameEN,
            description: rugProduct.descriptionEN,
            colours: rugProduct.coloursEN,
            sizePrices: usSizes,
            customSizePrice: getPrice(rugProduct.customSizePriceUS),
        },
    };
};

export const rugTagMenuMapper = (tagData) => {
    if (!tagData) {
        return;
    }

    return {
        itemTree: tagData[Tags.rugs.id].map((rugProduct, i) => ({
            id: i,
            labels: {
                en_CA: rugProduct.nameEN,
                fr_CA: rugProduct.nameFR,
                en_US: rugProduct.nameEN,
            },
            data: rugProductMapper(rugProduct),
        })),
    };
};

export const mediumTagMenuSelector = createSelector(
    mediumTagDataSelector,
    rugTagMenuMapper,
);

export const rugTagMenuSelector = createSelector(
    rugTagDataSelector,
    rugTagMenuMapper,
);

export const bedroomCasegoodsProductMapper = (product) => {
    const canadianPrice = getPrice(product.priceCA);
    const usPrice = getPrice(product.priceUS);

    return {
        ...product,
        [Locale.en_CA.id]: {
            name: product.nameEN,
            description: product.descriptionEN,
            price: canadianPrice,
        },
        [Locale.fr_CA.id]: {
            name: product.nameFR,
            description: product.descriptionFR,
            price: canadianPrice,
        },
        [Locale.en_US.id]: {
            name: product.nameEN,
            description: product.descriptionEN,
            price: usPrice,
        },
        tag: Tags.bedroomCaseGoods,
    };
};

export const bedroomCasegoodsTagMenuMapper = (tagData) => {
    if (!tagData) {
        return;
    }

    return {
        itemTree: [
            {
                id: Tags.bedroomCaseGoods.id,
                labels: {
                    en_CA: i18n.en_CA.bedroom_casegoods,
                    fr_CA: i18n.fr_CA.bedroom_casegoods,
                    en_US: i18n.en_US.bedroom_casegoods,
                },
                children: tagData[Tags.bedroomCaseGoods.id].map((product, i) => ({
                    id: i,
                    labels: {
                        en_CA: product.nameEN,
                        fr_CA: product.nameFR,
                        en_US: product.nameEN,
                    },
                    data: bedroomCasegoodsProductMapper(product),
                })),
            },
        ],
    };
};

export const accessoriesTagProductMapper = (productCollection) => {

    const getProductConfigurations = (item, nameLocale: string, priceLocale: string) => item.configurations.map((productConfig, i) => ({
        id: i,
        name: productConfig[nameLocale],
        price: getPrice(productConfig[priceLocale]),
    }));

    return {
        ...productCollection,
        tag: Tags.accessories,
        [Locale.en_CA.id]: {
            collectionName: productCollection.collectionNameEN,
            products: productCollection.items.map((item, i) => ({
                id: i,
                name: item.nameEN,
                configurations: getProductConfigurations(item, 'nameEN', 'priceCA'),
            })),
        },
        [Locale.fr_CA.id]: {
            collectionName: productCollection.collectionNameFR,
            products: productCollection.items.map((item, i) => ({
                id: i,
                name: item.nameFR,
                configurations: getProductConfigurations(item, 'nameFR', 'priceCA'),
            })),
            // configurations: getProductConfigurations('nameFR', 'priceCA'),
        },
        [Locale.en_US.id]: {
            collectionName: productCollection.collectionNameEN,
            products: productCollection.items.map((item, i) => ({
                id: i,
                name: item.nameEN,
                configurations: getProductConfigurations(item, 'nameEN', 'priceUS'),
            })),
            // configurations: getProductConfigurations('nameEN', 'priceUS'),
        },
    };
};

export const accessoriesTagMenuMapper = (id: string, labelId: string) => (tagData) => {
    if (!tagData) {
        return;
    }

    return {
        itemTree: [
            {
                id,
                labelId,
                children: tagData[id].map((collection, i) => ({
                    id: i,
                    labels: {
                        [Locale.en_CA.id]: collection.collectionNameEN,
                        [Locale.fr_CA.id]: collection.collectionNameFR,
                        [Locale.en_US.id]: collection.collectionNameEN,
                    },
                    data: Tags.accessories.productMapper(collection),
                    // data: Tags.accessories.productMapper(collection),
                })),
            },
        ],
    };
};

export const fillableShelfTalkerTagMenuMapper = () => ({
    itemTree: [{
        id: Tags.fillableShelfTalkers.id,
        labels: {
            en_CA: i18n.en_CA.fillable_shelf_talkers,
            fr_CA: i18n.fr_CA.fillable_shelf_talkers,
            en_US: i18n.en_US.fillable_shelf_talkers,
        },
        data: {tag: Tags.fillableShelfTalkers},
    }],
});

export const customShelfTalkerTagMenuMapper = () => ({
    itemTree: [{
        id: Tags.customShelfTalker.id,
        labels: {
            en_CA: i18n.en_CA.custom_shelf_talker,
            fr_CA: i18n.fr_CA.custom_shelf_talker,
            en_US: i18n.en_US.custom_shelf_talker,
        },
        data: {tag: Tags.customShelfTalker},
    }],
});

export const shelfTalkerTagMenuMapper = () => ({
    itemTree: [{
        id: Tags.saleShelfTalkers.id,
        labels: {
            en_CA: i18n.en_CA.sale_shelf_talkers,
            fr_CA: i18n.fr_CA.sale_shelf_talkers,
            en_US: i18n.en_US.sale_shelf_talkers,
        },
        data: {tag: Tags.saleShelfTalkers},
    }],
});

export const smallTagMenuSelector = createSelector(
    smallTagDataSelector, (smallTagData) => {
        if (!smallTagData) {
            return;
        }

        return {
            itemTree: TagSizes.small.tags.map(({menuMapper}) => menuMapper(smallTagData)).reduce((accumulator, tagMenu) => ([...accumulator, ...tagMenu.itemTree]), []),
        };
    },
);

export const pillowProductMapper = ({grade, ...product}) => {
    const {descriptionEN, descriptionFR} = product;
    return {...product,
        gradeId: grade.id,
        [Locale.en_CA.id]: {
            description: descriptionEN,
            price: getPrice(product[grade[Locale.en_CA.country].name]),
        },
        [Locale.fr_CA.id]: {
            description: descriptionFR,
            price: getPrice(product[grade[Locale.fr_CA.country].name]),
        },
        [Locale.en_US.id]: {
            description: descriptionEN,
            price: getPrice(product[grade[Locale.en_US.country].name]),
        },
    };
};

const pillowProductGradesMapper = (pillowProduct, locale) => {
    return Object.values(Grades).filter((grade) => pillowProduct[grade[locale.country].name]).map((grade) => {
        return {
            id: grade.id,
            labels: {
                en_CA: i18n.en_CA[grade.id],
                fr_CA: i18n.fr_CA[grade.id],
                en_US: i18n.en_US[grade.id],
            },
            data: pillowProductMapper({...pillowProduct, grade}),
        };
    });
};

const pillowGradeMenuMapper = (locale) => (pillowProduct) => {
    return {
        id: pillowProduct.item,
        labels: {
            en_CA: pillowProduct.descriptionEN,
            fr_CA: pillowProduct.descriptionFR,
            en_US: pillowProduct.descriptionEN,
        },
        children: pillowProductGradesMapper(pillowProduct, locale),
    };
};

export const pillowProductsSelector = createSelector(
    pillowTagDataSelector,
    ({[Tags.pillows.id]: tagData, locale}) => {

        if (!tagData) {
            return;
        }

        return tagData.map((pillowProduct) => (
            Object.values(Grades).filter((grade) => pillowProduct[grade[locale.country].name]).map((grade) => ({grade, ...pillowProduct}))
        )).reduce((accumulator, products) => ([...accumulator, ...products]), []);
    },
);

export const pillowTagMenuMapper = ({[Tags.pillows.id]: tagData, locale}) => {
    if (!tagData) {
        return;
    }

    const fills = ['fiber', 'feather', 'leather'];
    return {
        itemTree: fills.map((fill) => ({
            id: fill,
            labelId: fill,
            children: tagData.filter(({fill: productFill}) => productFill === fill).map(pillowGradeMenuMapper(locale)),
        })),
    };
};
export const pillowTagMenuSelector = createSelector(
    pillowTagDataSelector,
    pillowTagMenuMapper,
);

const selectProducts = (state) => ({
    products: Object.values(TagSizes).reduce((accumulator, tagSize) => {
        const {products} = selectProductsByTagSize(state, tagSize);

        return [...accumulator, ...products];
    }, []),
    locale: state.priceTags.locale,
});

const selectProductsByTagSize = (state, tagSize) => ({
    products: tagSize.tags.map((tagType) => {

        const tagData = tagType.allProductsSelector && tagType.allProductsSelector(state) || state.priceTags.tagsData[tagType.id];
        const productsByTagType = (tagType.hasCollections
            ? tagData &&
            tagData.reduce((collectionAccumulator, collection) =>
                ([...collectionAccumulator,
                    ...(state.priceTags.tagsData[collection.id]
                        && state.priceTags.tagsData[collection.id].map((product) => ({...product, collection}))
                        || []),
                ]), [])
            : tagData) || [];

        return {tagType, tagSize, products: productsByTagType};
    }),
    locale: state.priceTags.locale,
});

const selectProductsAsQueryable = ({products, locale}) => {

    return products.reduce((accumulator, {products: productsByTagType, tagType, tagSize}) => {

        const searchableProducts = productsByTagType.map((product) => {
            const mappedProduct = tagType.productMapper(product);
            return {
                ...mappedProduct,
                tagSize,
                queryableString: (tagType.queryableStringMapper && tagType.queryableStringMapper(mappedProduct, locale)) || product[tagType.productNameField[locale.id]],
            };
        });
        return [...accumulator, ...searchableProducts];
    }, []);
};

export const queryableProductsSelector = createSelector(
    selectProducts,
    selectProductsAsQueryable,
);
export const queryableProductsByTagSizeSelector = createSelector(
    selectProductsByTagSize,
    selectProductsAsQueryable,
);
