import LoadingFrame from '@eq3/component/LoadingFrame';
import LoginError, { Forbidden } from '@eq3/component/LoginError';
import { AuthDispatch } from '@eq3/redux/auth/actions';
import { useAuth } from '@eq3/redux/auth/hooks';
import { AuthPermissions } from '@eq3/redux/auth/models/apiModels';
import { handleRedirectCallback, isAuthenticated, login, refreshUserCredentials } from '@eq3/redux/auth/thunks';
import { doesHavePermissions } from '@eq3/redux/auth/utils';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { concat, defer, EMPTY, iif, of, throwError } from 'rxjs';
import { catchError, finalize, mergeMap, tap } from 'rxjs/operators';

/**
 * If user is not logged in, then redirects to the Auth0 hosted login
 * If user is logged in but does not have permission to access this app, then redirects to a "Forbidden" page.
 * Else, renders children normally.
 */
const Authorized = (props: PropsWithChildren<any>) => {
    const { children } = props;

    const [isInitializing, setIsInitializing] = useState(true);
    const [isRefreshingUserCredentials, setIsRefreshingUserCredentials] = useState(false);
    const [didLoginFail, setLoginFailed] = useState(false);

    const dispatch = useDispatch<AuthDispatch>();
    const { loggedInUser } = useAuth();
    const history = useHistory();

    // Initialize the auth0 client on-mount.
    useEffect(() => {
        const subscription = concat(
            defer(() => {
                if (window.location.search.includes('?code=')) {
                    return dispatch(handleRedirectCallback()).pipe(
                        tap(({ appState }) => {
                            history.replace(appState?.path ?? '/');
                            return EMPTY;
                        }),
                        catchError((e) => {
                            // If this failed, then log the error, strip out the path query, and move on.
                            // Either we're logged in or we aren't. If this exception goes unhandled, we'll be stuck staring at a spinner.
                            // NOTE: It is possible to run into this scenario when logging in, hitting the browser "back"
                            // button (navs back to the hosted login), then trying to log in again. The URL query args for `code`
                            // and `state` are stale b/c they have already been exchanged for an access token.
                            // Related -> https://github.com/auth0/auth0-spa-js/blob/master/FAQ.md#why-do-i-get-error-invalid-state-in-firefox-when-refreshing-the-page-immediately-after-a-login
                            console.error('Handle redirect callback failed.', e);
                            history.replace(window.location.pathname);
                            return EMPTY;
                        }),
                    );
                }

                return EMPTY;
            }),
            dispatch(isAuthenticated()).pipe(
                mergeMap((isAuthenticated) => iif(() => isAuthenticated, of(isAuthenticated))), // will return EMPTY if the user is not authenticated, meaning the pipeline will end here.
                tap(() => setIsRefreshingUserCredentials(true)),
                mergeMap(() => {
                    return dispatch(refreshUserCredentials()).pipe(
                        catchError((e) => {
                            // If this failed, then raise a flag and show something to the user.
                            console.error('Error fetching user credentials', e);
                            setLoginFailed(true);
                            return throwError(e);
                        }),
                        finalize(() => setIsRefreshingUserCredentials(false)),
                    );
                }),
            ),
        ).pipe(finalize(() => setIsInitializing(false)))
        .subscribe();

        return () => subscription.unsubscribe();
    }, []);

    if (didLoginFail) {
        return (
            <LoginError
                title="Something isn't right..."
                message="An error occurred during the authentication process."
                logoutButtonText="Click here to try again"
            />
        );
    } else if (isInitializing || isRefreshingUserCredentials) {
        // If the auth0 client is loading, or if we're refreshing our user credentials, then show a loading state.
        return (
            <div style={{ height: '100vh' }}>
                <LoadingFrame/>
            </div>
        );
    } else if (!loggedInUser) {
        // If we're not authenticated, then redirect to Auth0's hosted login page.
        // Add the current path to the appState so we can redirect to that route after login.
        dispatch(login({ appState: { path: window.location.pathname } }))
            .subscribe();

        // Doesn't really matter what we render. We're being redirected out of the site.
        return (
            <div style={{ height: '100vh' }}>
                <LoadingFrame/>
            </div>
        );

    } else if (!doesHavePermissions(loggedInUser?.permissions, [AuthPermissions.ACCESS_ADMIN_PORTAL])) {
        // If we're logged in, then ensure that we (at the very least) have the "access:admin_portal" permission.
        // All admin users must have this permission.
        return (<Forbidden/>);
    }
    return (
        <>{children}</>
    );
};

export default Authorized;
