import * as colors from '@material-ui/core/colors';
import eq3Colours from './colours';
import createPalette, { Palette } from '@material-ui/core/styles/createPalette';

// See https://material-ui.com/customization/palette for more details
// See https://material-ui.com/customization/default-theme/ for defaults
// see https://material-ui.com/customization/color/ for usage on how to use the material-ui color framework
const palette: Palette = createPalette({
    type: 'light',
    action: {
        active: colors.blueGrey[600],
    },
    background: {
        default: eq3Colours.background,
        paper: eq3Colours.white,
    },
    primary: {
        main: eq3Colours.primary,
        contrastText: eq3Colours.white,
    },
    secondary: {
        main: eq3Colours.primaryDarker, // Not sure what to put here
        contrastText: colors.common.white,
    },
    text: {
        ...eq3Colours,
        primary: eq3Colours.main,
        secondary: eq3Colours.primaryDarker,
        disabled: eq3Colours.subdued,
    },
    error: {
        main: eq3Colours.destructive,
    },
    success: {
        main: eq3Colours.primary,
    },
    divider: eq3Colours.border,
});

export default palette;
