import { defaultPageSize, Pagination } from '@eq3/utils/pagination';
import { createActions, handleActions } from 'redux-actions';
import { errorNotification, notify, successNotification } from '../adminNotifications';
import { apiThunk } from '../store';
import { IShippingOption } from './models';
import { defer, Observable, throwError } from 'rxjs';
import { ThunkResult } from 'redux-thunk';
import { catchError, map } from 'rxjs/operators';

export const { setShippingOptionsQuery, setLoadingShippingOptions, setShippingOptions } = createActions({
    SET_SHIPPING_OPTIONS_QUERY: (d) => d,
    SET_LOADING_SHIPPING_OPTIONS: (d) => d,
    SET_SHIPPING_OPTIONS: (d) => d,
});

export const initialState = {
    loading: false,
    query: {
        province: '',
        country: 'CA',
        postalUnit: '',
        page: 0,
        pageSize: defaultPageSize,
        city: '',
        county: ''
    },
    data: {
        items: [],
        page: 0,
        pageSize: 0,
        totalCount: 0,
    },
};

export interface IShippingOptionsState {
    shippingOptions: typeof initialState;
}

export interface IGetShippingOptionsQuery {
    country: string;
    province?: string;
    postalUnit?: string;
    city?: string;
    county?: string;
    page?: number;
    pageSize?: number;
}

export default handleActions({
    [setShippingOptionsQuery]: (state, { payload: query }) => ({ ...state, query }),
    [setLoadingShippingOptions]: (state) => ({...state, loading: true}),
    [setShippingOptions]: (state, { payload: data }) => ({ ...state, loading: false, data }),
}, initialState);

export const fetchShippingOptions = () => async (dispatch, getStore, api: apiThunk) => {
    const state = getStore();
    try {
        dispatch(setLoadingShippingOptions());
        const { data } = await api(dispatch, getStore, 'admin/shipping/', 'GET', undefined, {
            params: state.shippingOptions.query,
        });
        dispatch(setShippingOptions(data));
    } catch (e) {
        dispatch(notify(errorNotification('Error getting shipping options: ', e)));
    }
};

// This is basically the same thing as above except we're allowing the caller to pass in the query params instead of
// using Redux (which we shouldn't be using anyway for query params).
export const fetchShippingOptions2 = (params: IGetShippingOptionsQuery): ThunkResult<Observable<Pagination<IShippingOption>>> => (dispatch, getStore, api) => {
    return defer(() => api<Pagination<IShippingOption>>(
        dispatch,
        getStore,
        `/admin/shipping/`,
        'GET',
        undefined,
        {
            params,
        }),
    ).pipe(
        map(({ data }) => data),
        catchError((e) => {
            console.error(e);
            dispatch(notify(errorNotification('Error fetching Shipping options', e)));
            return throwError(e);
        }),
    );
};

export const fetchShippingOptionsCSV = () => async (dispatch, getStore, api: apiThunk): Promise<Blob> => {
    const state = getStore();
    try {
        const { data } = await api(dispatch, getStore, 'admin/shipping/export', 'GET', undefined, {
            params: state.shippingOptions.query,
        });
        return new Blob([data], {type: 'text/csv'});
    } catch (e) {
        dispatch(notify(errorNotification('Error exporting shipping options CSV: ', e)));
        throw e;
    }
};

export const importShippingOptionCSV = (shippingOptionsCSV: File) => async (dispatch, getStore, api: apiThunk) => {

    try {
        const formData = new FormData();
        formData.append('file', shippingOptionsCSV);

        await api(dispatch, getStore, `/admin/shipping/import`, 'POST', formData);
        dispatch(notify(successNotification('Shipping options CSV imported.')));
        dispatch(fetchShippingOptions());
    } catch (e) {
        dispatch(notify(errorNotification('Error importing shipping options CSV: ', e)));
        throw e;
    }
};

export const updateShippingOption = (shippingOption: IShippingOption) => async (dispatch, getStore, api: apiThunk) => {
    try {
        await api(dispatch, getStore, `/admin/shipping`, 'POST', shippingOption);
        dispatch(notify(successNotification('Shipping option updated')));
        dispatch(fetchShippingOptions());
    } catch (e) {
        dispatch(notify(errorNotification('Error deactiving shipping option', e)));
        throw e;
    }
};
