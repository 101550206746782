import { errorNotification, notify, successNotification } from '@eq3/redux/adminNotifications';
import { ActionType, setComOrders } from '@eq3/redux/comOrders/actions';
import { IReduxSlice } from '@eq3/redux/comOrders/reducers';
import { apiThunk as ApiThunk } from '@eq3/redux/store';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { defer, from, Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { IComOrderDictionary, IOrderComDetails } from './models';

type ComOrdersThunkResult<T> = ThunkAction<Observable<T>, IReduxSlice, ApiThunk, ActionType>;
export type ComOrdersDispatch = ThunkDispatch<IReduxSlice, ApiThunk, ActionType>;

export const fetchComNumbersForOrder = (orderId: string): ComOrdersThunkResult<IOrderComDetails> => (dispatch, getState, api) => {
    return defer(() => api<IOrderComDetails>(dispatch, getState, `/admin/orders/${orderId}/comData`, 'GET'))
        .pipe(
            map(({ data }) => data),
            tap((data) => {
                const orderIdComData: IComOrderDictionary = { [orderId]: data };
                dispatch(setComOrders(orderIdComData));
            }),
            catchError((e) => {
                console.error(e);
                dispatch(notify(errorNotification('Error fetching COM data for order', e)));
                return throwError(e);
            }));
};

export const createComNumber = (orderId: string, comNumber: string): ComOrdersThunkResult<void> => (dispatch, getState, api) => {
    
    return from(api(dispatch, getState, `/admin/orders/${orderId}/comNumber/${comNumber}`, 'POST'))
        .pipe(
            map(({ data }) => data),
            tap((order) => {
                const orderIdComData: IComOrderDictionary = { [orderId]: order.comData };
                dispatch(setComOrders(orderIdComData));
                dispatch(notify(successNotification('COM Number successfully added!')));
            }),
            catchError((e) => {
                const type = e?.response?.data?.type;
                const message = e?.response?.data?.message;
                if (type === 'DUPLICATED_COM_NUMBER' && message !== undefined) {
                    dispatch(notify(errorNotification(message, e)));
                } else {
                    dispatch(notify(errorNotification(`Error occurred trying to associate COM ${comNumber} to order`, e)));
                }
                
                return throwError(e);
            }));
};

export const deleteComNumber = (orderId: string, comNumber: string): ComOrdersThunkResult<void> => (dispatch, getState, api) => {    
    return defer(() => api(dispatch, getState, `/admin/orders/${orderId}/comNumber/${comNumber}`, 'DELETE'))
        .pipe(
            map(({ data }) => data),
            tap((order) => {
                const orderIdComData: IComOrderDictionary = { [orderId]: order.comData };
                dispatch(setComOrders(orderIdComData));
                dispatch(notify(successNotification('COM Number successfully removed!')));
            }),
            catchError((e) => {
                console.error(e);
                dispatch(notify(errorNotification(`Error deleting COM number from order`, e)));
                return throwError(e);
            }));
};
