import { Countries, Locale, localeStringFormat } from '@eq3/utils/locales';

export const formatCurrencyNoDollarSign = (n: number, locale: Locale = Locale.CA_EN) => n.toLocaleString(localeStringFormat[locale], {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
}).trim();

export type Currency = 'CAD' | 'USD';

export const currencies: Currency[] = ['CAD', 'USD'];

export const currencyDictionary: { [key in Countries]: Currency } = {
    [Countries.CA]: 'CAD',
    [Countries.US]: 'USD',
};

export const CurrencyFormat = {
    [Locale.CA_EN]: new Intl.NumberFormat(
        localeStringFormat.CA_EN,
        {
            style: 'currency',
            currency: 'CAD',
        },
    ),
    [Locale.CA_FR]: new Intl.NumberFormat(
        localeStringFormat.CA_FR,
        {
            style: 'currency',
            currency: 'CAD',
        },
    ),
    [Locale.US_EN]: new Intl.NumberFormat(
        localeStringFormat.US_EN,
        {
            style: 'currency',
            currency: 'USD',
        },
    ),
};

export const formatCurrency = (n: number, l: Locale = Locale.CA_EN) => CurrencyFormat[l].format(n);