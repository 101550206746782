import SelectField, { SelectFieldProps } from '@eq3/component/form/v2/SelectField';
import { currencies } from '@eq3/utils';
import { makeStyles } from '@material-ui/core/styles';
import React, { useMemo } from 'react';

export type ICurrencySelectFieldProps = Omit<SelectFieldProps, 'options'>;

const CurrencySelectField = (props: ICurrencySelectFieldProps) => {
    const classes = useStyles();

    const currencySelectOptions = useMemo<SelectFieldProps['options']>(() => {
        return currencies.map((currency) => {
            return {
                label: currency,
                value: currency,
            };
        });
    }, []);

    return (
        <SelectField
            label="Currency"
            classes={{ root: classes.root }}
            {...props}
            options={currencySelectOptions}
        />
    );
};

const useStyles = makeStyles(() => ({
    root: {
        minWidth: 'unset',
    },
}));

export default CurrencySelectField;
