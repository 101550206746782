import { createSelector } from 'reselect';
import { ITableData, TableTemplate } from './models';
import { IPriceCostJumpReduxSlice } from './reducers';

const baseSelector = (x: IPriceCostJumpReduxSlice) => x.upholsteryPriceCostJumps;

export const pieceCodesSelector = createSelector(
    baseSelector,
    (x): string[] => {
        return x.jumpData.items.map((x) => x.pieceCode).sort();
    },
);

export const gradeGroupSelector = createSelector(
    baseSelector,
    (x): string[] => {
        return x.jumpData.items[0] && x.jumpData.items[0].amounts.map((x) => x.gradeGroup).sort();
    },
);

export const familiesSelector = createSelector(
    baseSelector,
    (x): Array<{label: string, value: string}> => {
        return x.families.map((f) => ({ label: !!f ? f : 'All' , value: f }));
    },
);

export const tableTemplateSelector = createSelector(
    pieceCodesSelector,
    gradeGroupSelector,
    (pieceCodes, gradeGroups): TableTemplate => {
        return pieceCodes.map((x) => ({ [x]: [...gradeGroups] }));
    },
);

export const tableDataSelector = createSelector(
    baseSelector,
    (x): ITableData | null => {
        const record = x.jumpData && x.jumpData.items && x.jumpData.items[0];
        if (!record) {
            return null;
        }

        return x.jumpData.items.reduce((a, b) => ({
            ...a,
            data: {
                ...a.data,
                [b.pieceCode]: {
                    ...b.amounts.reduce((c, d) => ({
                        ...c,
                        [d.gradeGroup]: {
                            materialType: d.materialType,
                            price: d.price,
                            cost: d.cost,
                        },
                    }), {}),
                },
            },
        }), {
            family: record.family,
            currency: record.currency,
            data: {},
        } as ITableData);
    },
);
