import { createAction as $createAction } from 'deox';
import { IComOrderDictionary } from './models';

const prefix = '[COM_ORDERS]';
const createAction: typeof $createAction = (type, executor) => $createAction(`${prefix}\\${type}` as typeof type, executor);

export const setComOrders = createAction('SET_COM_ORDERS', (resolve) => (comOrderDictionary: IComOrderDictionary) => resolve(comOrderDictionary));

export type ActionType =
    | ReturnType<typeof setComOrders>;
