export enum ProductDefinitionField {
    PRODUCT_DEFINITION_NAME = 'PRODUCT_DEFINITION_NAME',
    PRODUCT_INSTANCE_NAME = 'PRODUCT_INSTANCE_NAME',
    PRODUCT_DEFINITION_ID = 'PRODUCT_DEFINITION_ID',
    PRODUCT_INSTANCE_ID = 'PRODUCT_INSTANCE_ID',
    GTIN = 'GTIN',
    BUILD_TYPE = 'BUILD_TYPE',
    PRODUCT_BRAND = 'PRODUCT_BRAND',
    SHIPPING_TYPE = 'SHIPPING_TYPE',
    INSTANCE_COUNT = 'INSTANCE_COUNT',
}

export const ProductDefinitionFieldTranslation = {
    [ProductDefinitionField.PRODUCT_DEFINITION_NAME]: 'Name',
    [ProductDefinitionField.PRODUCT_INSTANCE_NAME]: 'Instance Name',
    [ProductDefinitionField.PRODUCT_DEFINITION_ID]: 'ID',
    [ProductDefinitionField.PRODUCT_INSTANCE_ID]: 'Instance ID',
    [ProductDefinitionField.GTIN]: 'GTIN',
    [ProductDefinitionField.BUILD_TYPE]: 'Build Type',
    [ProductDefinitionField.PRODUCT_BRAND]: 'Product Band',
    [ProductDefinitionField.SHIPPING_TYPE]: 'Shipping Type',
    [ProductDefinitionField.INSTANCE_COUNT]: 'Instance Count'
};
