/**
 * @function deleteUndefinedKeys
 * @description Deletes undefined keys within a given object recursively
 */
const deleteUndefinedKeys = <T extends {}>(obj: T): Partial<T> => {
    return Object.entries(obj).reduce((cleaned, [key, value]) => {
        if (typeof value === 'undefined') {
            return cleaned;
        }

        return {
            ...cleaned,
            [key]: typeof value === 'object' && value !== null
                ? {...deleteUndefinedKeys(value)}
                : value,
        };
    }, {});
};

export default deleteUndefinedKeys;
