import { StyleRules, WithStyles } from '@material-ui/core/styles';
import React from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';
import LocalizedPrice from '../../../components/LocalizedPrice';
import LocalizedString from '../../../components/LocalizedString';

import { withStyles } from '@material-ui/core';

interface IProps {
    product: any;
}
class PillowTag extends React.Component<IProps & ReturnType<typeof mapStateToProps> & WithStyles<ReturnType<typeof styles>>> {

    public render() {
        const { classes, product, locale } = this.props;
        const localizedProduct = product[locale.id];
        return (
            <div className={classes.container}>
                <div>
                    {localizedProduct.description}
                </div>
                <div>
                    <LocalizedString labelId={product.fill} />
                </div>
                <div>
                    <LocalizedString labelId={product.gradeId} />
                </div>
                <div className={classes.footer}>
                    <div className={classes.price}>
                        <LocalizedPrice price={localizedProduct.price} />
                    </div>
                    <div>
                        {product.item}
                    </div>
                </div>
            </div>
        );
    }
}

const styles = () => ({
    container: {
        fontSize: '13px',
        fontFamily: 'eq3-book',
    },
    footer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    price: {
        fontFamily: 'eq3-medium',
    },
}) as StyleRules;

const mapStateToProps = (state) => ({
    locale: state.priceTags.locale,
});

export default (compose(
    connect(mapStateToProps),
    withStyles(styles),
)(PillowTag)) as any as React.ComponentType<IProps>;
