import {AddButton} from '@eq3/containers/retail/priceTags/components/Buttons';
import nonPrintable from '@eq3/containers/retail/priceTags/util/nonPrintable';
import { createStyles, Tooltip, withStyles } from '@material-ui/core';
import { WithStyles } from '@material-ui/core/styles';
import React from 'react';
import {compose} from 'redux';
import LocalizedString from '../../../components/LocalizedString';
import PriceInput from '../../../components/PriceInput';

interface IState {
    name: string;
    description: string;
    note: string;

    skus: Array<{sku: string, referenceNumber: string; }>;
}

class FillableSaleTag extends React.Component<WithStyles<ReturnType<typeof styles>>, IState> {
    public state = {
        name: '',
        description: '',
        skus: [{sku: '', referenceNumber: ''}],
        note: '',
    };

    private handleSkuChange = (skuIndex: number) => (e) => {
        const skus = [...this.state.skus];
        const sku = {...skus[skuIndex]};
        sku[e.target.name] = e.target.value;

        skus.splice(skuIndex, 1, sku);
        this.setState({skus});
    };

    private handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    private addSku = () => {
        const skus = [...this.state.skus, {sku: '', referenceNumber: ''}];
        this.setState({skus});
    };

    private deleteSku = (index: number) => () => {
        const skus = [...this.state.skus];
        skus.splice(index, 1);

        this.setState({skus});
    };

    public render() {
        const {classes} = this.props;
        const {name, description, skus, note} = this.state;

        return (
            <div className={classes.container}>
                <div>
                    <textarea className={classes.nameInput} name="name" value={name} onChange={this.handleChange}/>
                </div>

                <div>
                    <textarea className={classes.descriptionInput} name="description" value={description}
                              onChange={this.handleChange}/>
                </div>

                <div className={classes.references}>
                    <AddButton onClick={this.addSku} className={`${classes.addSkusButton} ${nonPrintable}`}/>
                    <div className={classes.referencesGrid}>
                        <div>
                            <LocalizedString labelId="sku"/>
                        </div>
                        <div style={{textAlign: 'end'}}>
                            <LocalizedString labelId="referenceNumber"/>
                        </div>
                        {skus.map(({sku, referenceNumber}, i) => (
                            <React.Fragment key={i}>

                                <Tooltip
                                    interactive
                                    title={<span onClick={this.deleteSku(i)}>REMOVE</span>}
                                    placement="left"
                                    classes={{
                                        tooltip: classes.skuTooltip,
                                    }}
                                >
                                    <div className={classes.reference}>
                                        <input className={classes.skuInput} name="sku" value={sku}
                                               onChange={this.handleSkuChange(i)}/>
                                    </div>
                                </Tooltip>
                                <div className={classes.reference}>
                                    <input
                                        className={classes.referenceInput}
                                        name="referenceNumber"
                                        value={referenceNumber}
                                        style={{textAlign: 'end'}}
                                        onChange={this.handleSkuChange(i)}/>
                                </div>
                            </React.Fragment>
                        ))}
                    </div>
                </div>

                <div>
                    <textarea className={classes.descriptionInput} name="note" value={note}
                              onChange={this.handleChange}/>
                </div>

                <div>
                    <div>
                        <LocalizedString labelId="regularPrice"/>
                    </div>
                    <PriceInput className={classes.priceInput}/>
                </div>

                <div>
                    <div>
                        <LocalizedString labelId="salePrice"/>
                    </div>
                    <PriceInput className={classes.priceInput}/>
                </div>
            </div>
        );
    }
}

const styles = () => createStyles({
    container: {
        height: '100%',
        '& > div:not(:first-child)': {
            marginTop: '10px',
        },
        position: 'relative',
        '& input, textarea': {
            borderWidth: '0px',
            width: '100%',
        },
    },
    nameInput: {
        fontSize: '15pt',
        lineHeight: '19pt',
        fontFamily: 'eq3-heavy',
        resize: 'vertical',
        overflow: 'hidden',
    },
    descriptionInput: {
        fontSize: '8pt',
        fontFamily: 'eq3-book',
        lineHeight: '12pt',
        resize: 'vertical',
        overflow: 'hidden',
        height: '54px',
    },
    addSkusButton: {
        position: 'absolute',
        transform: 'scale(.45, .45) translate(-185%, -85%)',
    },
    skuTooltip: {
        cursor: 'pointer',
    },
    referencesGrid: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridColumnGap: '13px',
        gridRowGap: '6px',
    },
    reference: {
        width: '100%',
    },
    skuInput: {
        fontSize: '9pt',
    },
    referenceInput: {
        fontSize: '9pt',
    },
    priceInput: {
        height: '20pt',
        fontSize: '18pt',
    },
});

export default (compose(
    withStyles(styles),
)(FillableSaleTag)) as any as React.ComponentType;
