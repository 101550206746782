import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React from 'react';

import {compose} from 'redux';
import {i18n, ILocalizedLabels} from '../util/i18n';

import { Collapse, MenuItem, MenuList, Typography } from '@material-ui/core';

export interface IMenuItem {
    labelId?: string;
    labels?: ILocalizedLabels;
    id: string;
    icon?: any;
    children?: IMenuItem[];
    data?: any;
}

interface IProps {
    item: IMenuItem;
    onSelected?: (item: IMenuItem[]) => void;
    locale: any;
}

interface IState {
    open: boolean;
}
class SelectMenuItem extends React.Component<IProps, IState> {

    public state = {
        open: false,
    };

    private handleMenuItemClick = () => {
        this.setState((state) => ({open: !state.open}));
        if (this.props.onSelected) {
            this.props.onSelected([this.props.item]);
        }
    }

    private handleChildSelected = (menuItems: IMenuItem[]) => {
        if (this.props.onSelected) {
            this.props.onSelected([this.props.item, ...menuItems]);
        }
    }

    public render() {
        const {item: {labelId, children, labels}, locale} = this.props;
        const {open} = this.state;

        return (
            <>
                <MenuItem button onClick={this.handleMenuItemClick}>
                    <Typography>{(labelId && i18n[locale.id][labelId]) || (labels && labels[locale.id])} {children && !!children.length && ((!open && <ExpandMore/>) ||
                        <ExpandLess/>)}</Typography>
                </MenuItem>
                {children && !!children.length && (
                    <Collapse unmountOnExit in={open}>
                        <MenuList>
                            {children.map((child) => (
                                <div key={child.id}><SelectMenuItemComponent item={child} locale={locale} onSelected={this.handleChildSelected}/></div>))}
                        </MenuList>
                    </Collapse>
                )}
            </>
        );
    }
}

const SelectMenuItemComponent = (compose(
)(SelectMenuItem)) as any as React.ComponentType<IProps>;

export default SelectMenuItemComponent;
