import { ISseMessage } from '@eq3/redux/serverSentEvents/models';
import { createAction as $createAction } from 'deox';

const prefix = '[SSE]';
const createAction: typeof $createAction = (type, executor) => $createAction(`${prefix}\\${type}` as typeof type, executor);

export const recordEvent = createAction('RECORD_EVENT', (resolve) => {
    return (event: ISseMessage<any>) => resolve({ event });
});

export type SseActionsType =
    | ReturnType<typeof recordEvent>;
