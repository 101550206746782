import { StyleRules, WithStyles } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/WarningRounded';
import React from 'react';
import { connect } from 'react-redux';

import { Typography, withStyles } from '@material-ui/core';
import { compose } from 'redux';
import { i18n } from '../../../util/i18n';

class Prop65UpholsteryTag extends React.Component<ReturnType<typeof mapStateToProps> & WithStyles<ReturnType<typeof styles>>> {
    public render() {
        const { locale, classes } = this.props;

        return (
            <div className={classes.container}>
                <div className={classes.warningTitle}>
                    <WarningIcon />
                    <Typography variant="h5">
                        {i18n[locale.id].warning.toUpperCase()}:
                    </Typography>
                </div>
                <div className={classes.description}>
                    {i18n[locale.id].prop65UpholsteryDescription}
                </div>
            </div>
        );
    }
}

const styles = () => ({
    container: {
        padding: '0.125in',
        height: '100%',
    },
    warningTitle: {
        display: 'flex',
        alignItems: 'center',
        float: 'left',
        paddingTop: '6px',
        marginRight: '8px',
    },
    description: {
        fontFamily: 'eq3-book',
        fontSize: '8pt',
    },
}) as StyleRules;

const mapStateToProps = (state) => ({
    locale: state.priceTags.locale,
});

export default (compose(
    connect(mapStateToProps),
    withStyles(styles),
)(Prop65UpholsteryTag)) as any as React.ComponentType;
