import cuid from 'cuid';
import { defer, iif, Observable, of, throwError } from 'rxjs';
import { errorNotification, notify, successNotification } from '../adminNotifications';
import { unwrap } from '../utils';
import { setAssociableTagsList, setTags, setTagsAssociatedToEntity } from './actions';
import { IAssociateEntityTagRequest, IEntity, IEntityTag, IOneOrMoreArray, ITag, ThunkResult } from './models';
import { ThunkResult as ReduxThunkResult } from 'redux-thunk';
import { catchError, tap } from 'rxjs/operators';

export function blankTag(): ITag {
    return {
        id: cuid(),
        internalName: '',
        active: false,
        excludeFromFacet: false,
        name: {
            CA_EN: '',
            CA_FR: '',
            US_EN: '',
        },
        numOfAssociations: 0,
        order: 0,
    };
}

export const fetchTags = (): ThunkResult<void> => async (dispatch, getState, api) => {
    try {
        const { searchParams } = getState().tagging;
        const { data } = await api(dispatch, getState, '/admin/tagging/tags', 'GET', undefined, { params: searchParams });
        dispatch(setTags(data));
    } catch (e) {
        dispatch(notify(errorNotification('Error fetching tags', e)));
    }
};

export const saveTag = (tag: ITag): ThunkResult<boolean> => async (dispatch, getState, api) => {
    tag.id = tag.id || cuid(); // If it's new, give it a cuid

    try {
        await api(dispatch, getState, `/admin/tagging/tags/${tag.id}`, 'PUT', tag);
        dispatch(notify(successNotification('Tag saved')));
        return true;
    } catch (e) {
        const { response: { status, data: { message } } } = e;
        if (status === 409) {
            const [, tagName, groupId] = JSON.parse(message).split(`-`);
            const { allGroupsList } = getState().tagging;
            const groupName = (allGroupsList.find((g) => g.id === groupId) || {}).internalName;
            dispatch(notify(errorNotification(`Duplicate tag ${tagName} exists ${groupName ? `in selected group ${groupName}` : ''}`, e)));
            return false;
        } else {
            dispatch(notify(errorNotification('Error saving tag', e)));
            return false;
        }
    }
};

export const saveTag$ = (tag: ITag): ReduxThunkResult<Observable<ITag>> => (dispatch, getState, api) => {
    tag.id = tag.id || cuid(); // If it's new, give it a cuid

    return defer(() => {
        tag.id = tag.id || cuid();
        return api<ITag>(dispatch, getState, `/admin/tagging/tags/${tag.id}`, 'PUT', tag);
    }).pipe(
        catchError((e) => {
            const { response: { status, data: { message } } } = e;
            if (status === 409) {
                const [, tagName, groupId] = JSON.parse(message).split(`-`);
                const { allGroupsList } = getState().tagging;
                const groupName = (allGroupsList.find((g) => g.id === groupId) || {}).internalName;
                dispatch(notify(errorNotification(`Duplicate tag ${tagName} exists ${groupName ? `in selected group ${groupName}` : ''}`, e)));
                return throwError(e);
            } else {
                dispatch(notify(errorNotification('Error saving tag', e)));
                return throwError(e);
            }
        }),
        tap(() => dispatch(notify(successNotification('Tag saved')))),
        unwrap,
    );
};

export const deleteTag = (tag: ITag): ThunkResult<boolean> => async (dispatch, getState, api) => {
    try {
        await api(dispatch, getState, `/admin/tagging/tags/${tag.id}`, 'DELETE');
        dispatch(notify(successNotification('Tag Deleted')));
        return true;
    } catch (e) {
        dispatch(notify(errorNotification('Error deleting tag', e)));
        return false;
    }
};

export const fetchAllAssociableTags = (searchTerm: string): ThunkResult<void> => async (dispatch, getState, api) => {
    try {
        const search = searchTerm && searchTerm.trim();

        if (search.length) {
            const { data } = await api(dispatch, getState, '/admin/tagging/typeahead', 'GET', undefined, { params: { search } });
            dispatch(setAssociableTagsList(data));
        } else {
            dispatch(setAssociableTagsList([]));
        }
    } catch (e) {
        dispatch(notify(errorNotification('Error fetching associable tags', e)));
    }
};

export const fetchAllAssociableTags$ = (searchTerm: string): ReduxThunkResult<Observable<IEntityTag[]>> => (dispatch, getState, api) => {
    return defer(() => {
        const search = searchTerm && searchTerm.trim();
        return api<IEntityTag[]>(dispatch, getState, '/admin/tagging/typeahead', 'GET', undefined, { params: { search } });
    }).pipe(
        catchError((e) => {
            dispatch(notify(errorNotification('Error fetching associable tags', e)));
            return throwError(e);
        }),
        unwrap,
    );
};

export const fetchTagsByEntityId = (entities: IOneOrMoreArray<IEntity>): ThunkResult<void> => async (dispatch, getState, api) => {
    try {
        if (!!entities[0].entityId && !!entities[0].entityType) {
            const { data } = await api(dispatch, getState, `/admin/tagging/entity`, 'POST', entities);
            dispatch(setTagsAssociatedToEntity(data));
        } else {
            dispatch(setTagsAssociatedToEntity([]));
        }
    } catch (e) {
        dispatch(notify(errorNotification(`Error fetching associated tags`, e)));
    }
};

export const fetchTagsByEntityId$ = (entities: IOneOrMoreArray<IEntity>): ReduxThunkResult<Observable<IEntity[]>> => (dispatch, getState, api) => {
    return iif(
        () => !!entities[0].entityId && !!entities[0].entityType,
        defer(() => api<IEntity[]>(dispatch, getState, `/admin/tagging/entity`, 'POST', entities)).pipe(
            catchError((e) => {
                dispatch(notify(errorNotification(`Error fetching associated tags`, e)));
                return throwError(e);
            }),
            unwrap,
        ),
        of([])
    ).pipe(
        tap((data) => dispatch(setTagsAssociatedToEntity(data)))
    );
};

export const associateTagWithEntity = ({ tagId, entityId, entityType, action }: IAssociateEntityTagRequest): ThunkResult<void> => async (dispatch, getState, api) => {
    try {
        if (!entityType || !entityId || !tagId) {
            throw new Error(`Request invalid (tagId=${tagId}, entityId=${entityId}, entityType=${entityType})`);
        }

        const { data = [] } = await api(dispatch, getState, `/admin/tagging/entity`, 'PUT', {
            tagId,
            entityId,
            entityType,
            action,
        });

        dispatch(notify(successNotification(`${action} Entity Tag Association Successful`)));

        const { tagsAssociatedToEntities } = getState().tagging;
        const mutateForIndex = tagsAssociatedToEntities.findIndex((entity) => entity.entityId === entityId && entity.entityType === entityType);

        if (mutateForIndex > -1) {
            tagsAssociatedToEntities[mutateForIndex].associatedTags = data ;
        } else {
            tagsAssociatedToEntities.push({
                entityId,
                entityType,
                associatedTags: data,
            });
        }

        dispatch(setTagsAssociatedToEntity(tagsAssociatedToEntities));
    } catch (e) {
        dispatch(notify(errorNotification(e.message || 'Error associating tag to entity', e)));
    }
};

export const associateTagWithEntity$ = ({ tagId, entityId, entityType, action }: IAssociateEntityTagRequest): ReduxThunkResult<Observable<IEntityTag[]>> => (dispatch, getState, api) => {
    return defer(() => {
        if (!entityType || !entityId || !tagId) {
            throw new Error(`Request invalid (tagId=${tagId}, entityId=${entityId}, entityType=${entityType})`);
        }

        return api<IEntityTag[]>(dispatch, getState, `/admin/tagging/entity`, 'PUT', {
            tagId,
            entityId,
            entityType,
            action,
        });
    }).pipe(
        catchError((e) => {
            dispatch(notify(errorNotification(e.message || 'Error associating tag to entity', e)));
            return throwError(e);
        }),
        unwrap,
        tap((entities) => {
            dispatch(notify(successNotification(`${action} Entity Tag Association Successful`)));

            const { tagsAssociatedToEntities } = getState().tagging;
            const mutateForIndex = tagsAssociatedToEntities.findIndex((entity) => entity.entityId === entityId && entity.entityType === entityType);

            if (mutateForIndex > -1) {
                tagsAssociatedToEntities[mutateForIndex].associatedTags = entities;
            } else {
                tagsAssociatedToEntities.push({
                    entityId,
                    entityType,
                    associatedTags: entities,
                });
            }

            dispatch(setTagsAssociatedToEntity(tagsAssociatedToEntities));
        })
    );
};
