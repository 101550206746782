import React from 'react';

class Error extends React.Component {

    public state = {
        error: undefined,
        info: undefined,
    };

    public componentDidCatch(error, info) {
        this.setState({ error, info });
        // const unregister = window.history.listen((location, action) => {
        //     console.log(location, action);
        //     this.setState({ error: undefined, info: undefined });
        //     unregister();
        // });
    }

    public render() {
        const { error, info } = this.state;

        if (error || info) {
            return (
                <pre style={{ color: 'red' }}>
                    {`${error.toString()}\n${info ? info.componentStack : ''}`}
                </pre>
            );
        }

        return this.props.children;
    }

}

export default Error;
