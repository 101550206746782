import { AnyAction } from 'redux';
import { createActions, handleActions } from 'redux-actions';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { errorNotification, notify, successNotification } from './../adminNotifications';
import { apiThunk } from './../store';
import { IUpholsteryOptionGroup } from './models';

const basePath = `/admin/upholstery/option-groups`;

const initialState = {
    optionGroups: null as IUpholsteryOptionGroup[],
};

export type State = typeof initialState;
type GetState = () => ({ upholsteryOptionGroups: State });

const { optionGroups: actionCreators } = createActions({
    OPTION_GROUPS: {
        SET_OPTION_GROUPS: (optionGroups: IUpholsteryOptionGroup[]) => optionGroups,
    },
});

export default handleActions({
    [actionCreators.setOptionGroups]: (state = initialState, { payload: optionGroups }) => ({ ...state, optionGroups }),
}, initialState);

export const fetchOptionGroups = (): ThunkAction<Promise<IUpholsteryOptionGroup>, State, apiThunk, AnyAction> => async (dispatch, getState, api) => {
    try {
        const { data } = await api(dispatch, getState, `${basePath}`, 'GET');
        return data;
    } catch (e) {
        console.error(e);
        dispatch(notify(errorNotification('Error retrieving option groups', e)));
        throw e;
    }
};

export const loadOptionGroups = (): ThunkAction<Promise<IUpholsteryOptionGroup>, State, apiThunk, AnyAction> => async (dispatch) => {
    try {
        const data = await dispatch(fetchOptionGroups());
        dispatch(actionCreators.setOptionGroups(data));
        return data;
    } catch (e) {
        console.error(e);
        dispatch(notify(errorNotification('Error retrieving option groups', e)));
        throw e;
    }
};

export const saveOptionGroups = (optionGroups: IUpholsteryOptionGroup[]) => async (dispatch: ThunkDispatch<State, undefined, any>, getState: GetState, api: apiThunk) => {
    try {
        const { data }: { data: IUpholsteryOptionGroup[] } = await api(dispatch, getState, `${basePath}`, 'POST', optionGroups);
        const { upholsteryOptionGroups: { optionGroups: current } } = getState();

        const updated = [...data, ...current.filter((x) => !data.some((j) => j.code === x.code))];

        dispatch(actionCreators.setOptionGroups(updated));
        dispatch(notify(successNotification('Option Groups saved!')));
    } catch (e) {
        console.error(e);
        dispatch(notify(errorNotification('Error saving option groups', e)));
        throw e;
    }
};

export const deleteOptionGroups = (optionGroupCodes: string[]) => async (dispatch: ThunkDispatch<State, undefined, any>, getState: GetState, api: apiThunk) => {
    try {
        const { data }: { data: IUpholsteryOptionGroup[] } = await api(dispatch, getState, `${basePath}`, 'DELETE', optionGroupCodes);
        const { upholsteryOptionGroups: { optionGroups } } = getState();

        dispatch(actionCreators.setOptionGroups(optionGroups.filter((x) => data.some((j) => j.code !== x.code))));
        dispatch(notify(successNotification('Option Groups deleted!')));
    } catch (e) {
        console.error(e);
        dispatch(notify(errorNotification('Error deleting option groups', e)));
        throw e;
    }
};
