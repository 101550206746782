export interface IComOrderDictionary {    
    [orderId: string]: IOrderComDetails;
}

export enum ComOrderStatusCodes {
    CANCELLED = '99',
}

export interface IComOrder {
    comNumber: string;
    poSuffix?: string;
    warehouse?: string;
    statusCode?: string;
    statusDesc?: string;
    createDate: number;
    deleteDate?: number;
    deleteUser?: string;
    deletedQty?: number;
    deleteComments?: string;
    wasManuallyEntered: boolean;
}

export interface IComInjectionLogEntry {
    type: string;
    item: string;
    message: string;
    recordedTime: number;
}

export interface IOrderComDetails {
    comOrderListings: IComOrder[];
    comInjectionLogs: IComInjectionLogEntry[];
}
