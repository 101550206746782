import { TerminalLocationDtoStripeLocationId } from '@eq3-aws/payments-client';
import { errorNotification, notify } from '@eq3/redux/adminNotifications';
import { useMyProfileData } from '@eq3/redux/myProfile/selectors';
import { getStripeTerminalConnectionTokenForPlatformLocation } from '@eq3/redux/pos';
import { IConnectReaderError } from '@eq3/redux/pos/models';
import { IPosReduxSlice } from '@eq3/redux/pos/reducers';
import {
    getStripeTerminalLocations,
    getTerminalLocationFromPlatformId,
} from '@eq3/redux/pos/thunks/terminalLocationsThunks';
import { Countries } from '@eq3/utils/locales';
import {
    ConnectionStatus,
    ConnectOptions,
    DiscoverResult,
    DiscoveryConfig,
    ErrorResponse,
    ExposedError,
    loadStripeTerminal as $loadStripeTerminal,
    PaymentStatus,
    Reader,
    StripeTerminal,
    Terminal,
} from '@stripe/terminal-js';
import React, { createContext, PropsWithChildren, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { defer, EMPTY, from, iif, of, Subscription } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';

/**
 * Refs:
 * https://stripe.com/docs/terminal/sdk/js
 * https://www.npmjs.com/package/@stripe/terminal-js
 *
 * Testing with physical cards -> https://stripe.com/docs/terminal/testing#physical-test-card
 */
export interface IStripeTerminalContext {
    isInitializing: boolean;
    stripeTerminal: StripeTerminal | undefined;
    terminal: Terminal | undefined;

    readerConnectionStatus: ConnectionStatus | undefined;
    terminalStatus: PaymentStatus | undefined;

    isLoadingCardReaders: boolean;
    discoveredReaders: Reader[] | undefined;
    discoverReadersError: ExposedError | undefined;

    isConnectingToReaderId: string | undefined;
    connectedReader: Reader | undefined;
    connectToReaderError: IConnectReaderError | undefined;
    isDisconnectingFromReader: boolean;

    isLoadingPosLocations: boolean;
    posLocationsForPlatformCountry: TerminalLocationDtoStripeLocationId[];

    // Data will appear on receipts.
    platformLocationId: string | undefined;
    platformLocationCountry: Countries | undefined;
    platformLocationName: string | undefined;
    cashierName: string | undefined;

    actions: {
        refreshCardReaders: () => void;
        refreshPosLocations: () => void;
        connectToReader: (reader: Reader, options?: ConnectOptions) => void;
        disconnectFromReader: () => void;
    };
}

interface IPlatformLocation {
    id?: string; // Super users do not have an assigned location.
    country: Countries;
}

const StripeTerminalContext = createContext<IStripeTerminalContext | undefined>(undefined);

export const useStripeTerminalContext = () => useContext(StripeTerminalContext);

const cardReaderDiscoveryConfig: DiscoveryConfig = {
    simulated: process.env.FEATURE_FLAG_STRIPE_TERMINAL_SIMULATOR_ENABLED === 'true',
};

export const StripeTerminalContextProvider = (props: PropsWithChildren<any>) => {
    const { children } = props;

    const dispatch = useDispatch<ThunkDispatch>();
    const { userDetails , executeFetch: fetchUserProfile } = useMyProfileData();

    // Initialized once both stripeTerminal and terminal instances are set.
    const [isInitializing, setIsInitializing] = useState(true);
    const [stripeTerminal, setStripeTerminal] = useState<StripeTerminal>();
    const [terminal, setTerminal] = useState<Terminal>();
    const [platformLocation, setPlatformLocation] = useState<IPlatformLocation>();

    const [isLoadingPosLocations, setIsLoadingPosLocations] = useState(false);
    const [isLoadingCardReaders, setIsLoadingCardReaders] = useState(false);
    const [discoveredReaders, setDiscoveredReaders] = useState<Reader[]>();
    const [discoverReadersError, setDiscoverReadersError] = useState<ExposedError>();

    const [readerConnectionStatus, setReaderConnectionStatus] = useState<ConnectionStatus>();
    const [terminalStatus, setTerminalStatus] = useState<PaymentStatus>();

    const [isConnectingToReaderId, setIsConnectingToReaderId] = useState<string>();
    const [connectedReader, setConnectedReader] = useState<Reader>();
    const [connectToReaderError, setConnectToReaderError] = useState<IConnectReaderError>();
    const [isDisconnectingFromReader, setIsDisconnectingFromReader] = useState(false);

    const refreshCardReadersSubscription = useRef<Subscription>();
    const refreshPosLocationsSubscription = useRef<Subscription>();
    const connectToReaderSubscription = useRef<Subscription>();
    const disconnectFromReaderSubscription = useRef<Subscription>();

    const cashierFirstName = useMemo(() => {
        const trimmedName = userDetails?.user.name.trim();
        const splitName = trimmedName?.split(' ');

        return splitName?.[0]?.length ?? 0 > 0
            ? splitName?.[0]
            : trimmedName;
    }, [userDetails?.user.name]);

    const posLocationsForPlatformCountry = useSelector<IPosReduxSlice, TerminalLocationDtoStripeLocationId[]>((state) => {
        return state.pos[platformLocation?.country!]?.terminalLocations ?? [];
    });

    const platformLocationName = useMemo(() => {
        return posLocationsForPlatformCountry.find((t) => t.platformLocationId === platformLocation?.id)?.name;
    }, [posLocationsForPlatformCountry, platformLocation?.id]);

    // Load Stripe Terminal (loads the Stripe.js script).
    // Only runs once; on page load.
    useEffect(() => {
        setIsInitializing(true);
        const loadSubscription = from($loadStripeTerminal())
            .pipe(
                tap((stripeTerminal) => setStripeTerminal(stripeTerminal ?? undefined)),
                // Also need to fetch the user's profile so we know which terminals are available to his/her location.
                tap(() => {
                    return fetchUserProfile();
                }),
            )
            .subscribe();
        return () => {
            loadSubscription.unsubscribe();
        };
    }, []);

    // When the user's profile is loaded, or when the user's current location has changed, then update the platform location.
    // This is used to record where transactions happen and is displayed on receipts).
    useEffect(() => {
        if (userDetails) {
            setPlatformLocation({
                id: userDetails?.currentWorkingLocation?.id,
                country: (userDetails?.currentWorkingLocation?.address.country?.toUpperCase() as Countries)
                    ?? Countries.CA,
            });
        }
    }, [userDetails]);

    // Once the StripeTerminal lib and platform location have been set (or changed),
    // then we can create (or re-create) a Terminal JS instance.
    useEffect(() => {
        if (stripeTerminal && platformLocation?.id) {
            initializeTerminalJsInstanceWithLocationId(stripeTerminal, platformLocation!.id);
        } else if (stripeTerminal) {
            // If we've switched the platform country, then ensure that we're disconnected from the previous terminal.
            _disconnectFromReaderRx()
                .pipe(finalize(() => setIsInitializing(false)))
                .subscribe();
        }
    }, [stripeTerminal, platformLocation?.id]);

    // Once the terminal JS instance is ready (or has changed),
    // then refresh the terminals list when the platform country (AKA Stripe account) changes.
    useEffect(() => {
        if (!isInitializing && terminal && platformLocation?.id) {
            refreshCardReaders();
        } else {
            // Else just kill the subscription to refresh card readers.
            refreshCardReadersSubscription.current?.unsubscribe();
        }
    }, [isInitializing, platformLocation?.id, terminal]);

    // If the platform country has changed,
    // then fetch the terminal locations (results are cached).
    useEffect(() => {
        if (platformLocation?.country) {
            refreshPosLocations();
        }
    }, [platformLocation?.country]);

    const initializeTerminalJsInstanceWithLocationId = async (
        stripeTerminal: StripeTerminal,
        platformLocationId: string,
    ) => {
        setIsInitializing(true);

        // Cleanup if we're already connected to a terminal.
        if (connectedReader && terminal) {
            console.log(`Disconnecting from ${connectedReader?.label}`);
            await _disconnectFromReaderRx().toPromise();
        }

        // Clear out current terminal instance. We're about to replace it.
        setTerminal(undefined);

        // Before to init the Stripe Terminal instance, ensure that the current platform location also a valid stripe location.
        try {
            await dispatch(getTerminalLocationFromPlatformId(platformLocationId)).toPromise();
        } catch (err) {
            if ((err as any)?.response.request?.status === 404) {
                // Just eat the error, else retail users that are logged in under a location that does NOT have a Stripe
                // location will always see errors on page loads.
                console.log(`Platform ID ${platformLocationId} is not a valid Stripe location.`);
                setIsInitializing(false);
                return;
            }
            throw err;
        }
        console.log('Initializing terminal.');
        const $terminal = stripeTerminal!.create({
            onFetchConnectionToken: async () => {
                console.log(`Fetching connection token for platform ID ${platformLocationId}...`);
                // If the logged in user has a current-working location, then filter the available terminals to only
                // the ones that are assigned this location.
                const connection = await dispatch(getStripeTerminalConnectionTokenForPlatformLocation(platformLocationId)).toPromise();
                console.log('Got connection', connection);
                return connection.connectionSecret;
            },
            onUnexpectedReaderDisconnect: (disconnectEvent) => {
                console.error('Unexpected reader disconnect', disconnectEvent);
                disconnectFromReader();
                dispatch(notify(errorNotification('Reader was unexpectedly disconnected.')));
            },
            onConnectionStatusChange: (event) => {
                console.log('onConnectionStatusChange', event);
                setReaderConnectionStatus(event.status);
            },
            onPaymentStatusChange: (event) => {
                console.log('onPaymentStatusChange', event);
                setTerminalStatus(event.status);
            },
        });
        setTerminal($terminal);
        setIsInitializing(false);
    };

    const resetCardReader = () => {
        setIsConnectingToReaderId(undefined);
        setConnectedReader(undefined);
        setConnectToReaderError(undefined);
    };

    const refreshPosLocations = () => {
        if (!platformLocation?.country) {
            // Can't refresh pos locations. Platform country not yet set.
            return;
        }
        refreshPosLocationsSubscription.current?.unsubscribe();

        console.log(`Refreshing POS locations for ${platformLocation?.country}`);
        setIsLoadingPosLocations(true);
        refreshPosLocationsSubscription.current = dispatch(getStripeTerminalLocations(platformLocation!.country))
            .pipe(finalize(() => setIsLoadingPosLocations(false)))
            .subscribe();
    };

    const refreshCardReaders = () => {
        if (!terminal || !platformLocation?.id) {
            // Can't refresh terminals list. Terminal instance or platform location not yet set.
            return;
        }
        console.log(`Refresh card readers for ${platformLocation?.country} ${platformLocation?.id}...`);
        refreshCardReadersSubscription.current?.unsubscribe();

        setIsLoadingCardReaders(true);
        setDiscoverReadersError(undefined);
        setDiscoveredReaders(undefined);

        refreshCardReadersSubscription.current = from(terminal!.discoverReaders(cardReaderDiscoveryConfig))
            .pipe(
                map((x) => (x as DiscoverResult & ErrorResponse)),
                finalize(() => setIsLoadingCardReaders(false)),
            ).subscribe({
                    next: (x) => {
                        console.log('Refresh card readers result', x);
                        setDiscoverReadersError(x.error);
                        setDiscoveredReaders(x.discoveredReaders);
                    },
                    error: (err) => {
                        // NOTE: The error message from Stripe isn't super friendly.
                        const msg = err?.response?.data?.errorDescription ?? 'Sorry! There was a problem refreshing the terminals list.';
                        console.error(msg, err);
                        dispatch(notify(errorNotification(msg, err)));
                    },
                },
            );
    };

    const connectToReader = (reader: Reader, options?: ConnectOptions) => {
        console.log('Connecting to reader...');
        connectToReaderSubscription.current?.unsubscribe();

        setIsConnectingToReaderId(reader.id);
        setConnectToReaderError(undefined);

        connectToReaderSubscription.current = from(terminal!.connectReader(reader, options))
            .pipe(
                map((x) => (x as ErrorResponse & { reader: Reader })),
                finalize(() => {
                    setIsConnectingToReaderId(undefined);
                }),
            ).subscribe({
                next: (connectedReader) => {
                    console.log('Connect to reader result', connectedReader);
                    setConnectToReaderError({ error: connectedReader.error, readerId: reader.id });
                    setConnectedReader(connectedReader.reader);
                },
                error: (err) => {
                    const msg = err?.response?.data?.errorDescription ?? `Sorry! Could not connect to the terminal "${reader.label}".`;
                    console.error(msg, err);
                    dispatch(notify(errorNotification(msg, err)));
                },
            });
    };

    const disconnectFromReader = () => {
        console.log(`Disconnecting from reader ${connectedReader?.label}...`);

        disconnectFromReaderSubscription.current?.unsubscribe();

        disconnectFromReaderSubscription.current = _disconnectFromReaderRx()
            .subscribe({
                complete: () => {
                    console.log(`Disconnected from reader ${connectedReader?.label}.`);
                },
                error: (err) => {
                    const msg = err?.response?.data?.errorDescription ?? `Sorry! An error occurred when disconnecting from reader: ${connectedReader?.label}.`;
                    console.error(msg, err);
                    dispatch(notify(errorNotification(msg, err)));
                },
            });
    };

    const _disconnectFromReaderRx = () => {
        return iif(
            () => !!terminal,
            defer(() => {
                setIsDisconnectingFromReader(true);

                return from(terminal!.disconnectReader());
            }),
            of(EMPTY),
        ).pipe(
            finalize(() => {
                resetCardReader();
                setIsDisconnectingFromReader(false);
            }),
        );
    };

    const context: IStripeTerminalContext = {
        isInitializing,
        stripeTerminal,
        terminal,
        isLoadingCardReaders,
        discoveredReaders,
        discoverReadersError,

        readerConnectionStatus,
        terminalStatus,

        isConnectingToReaderId,
        connectedReader,
        connectToReaderError,
        isDisconnectingFromReader,

        isLoadingPosLocations,
        posLocationsForPlatformCountry,
        platformLocationId: platformLocation?.id,
        platformLocationCountry: platformLocation?.country,
        platformLocationName,
        cashierName: cashierFirstName,

        actions: {
            refreshPosLocations,
            refreshCardReaders,
            connectToReader,
            disconnectFromReader,
        },
    };

    return (
        <StripeTerminalContext.Provider value={context}>
            {children}
        </StripeTerminalContext.Provider>
    );
};
