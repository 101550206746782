import { IconButtonProps } from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import LockIcon from '@material-ui/icons/Lock';
import SwapHorizontalIcon from '@material-ui/icons/SwapHoriz';
import React from 'react';

import { IconButton } from '@material-ui/core';

const button = (icon: JSX.Element, props: IconButtonProps) => (
    <IconButton style={{color: '#000000'}} {...props}>
        {icon}
    </IconButton>
);

export const AddButton = (props: IconButtonProps) => button(<AddIcon/>, props);
export const ClearButton = (props: IconButtonProps) => button(<ClearIcon/>, props);
export const DeleteButton = (props: IconButtonProps) => button(<DeleteIcon/>, props);
export const LockButton = (props: IconButtonProps) => button(<LockIcon/>, props);
export const SwapButton = (props: IconButtonProps) => button(<SwapHorizontalIcon/>, props);
