// TODO: move language/translations into util. Receipts are the first localized thing in admin portal?
export enum Language {
    EN = 'en',
    FR = 'fr',
}

export type Translations<T> = {
    [key in Language]: T;
};

export const LAST_SELECTED_RECEIPT_LANGUAGE_LS_KEY = 'terminal_receipts_last_selected_language';

type ReceiptTranslations = { [key in keyof typeof englishStrings]: typeof englishStrings[key] };

const englishStrings = {
    txReference: 'Reference #',
    txOrderNumber: 'Order #',
    txCashier: 'Cashier',
    txType: 'Type',
    txTypePurchase: 'PURCHASE',
    txTypeRefund: 'REFUND',
    txAmount: 'Amount',
    txAccount: 'Account',
    cardNumber: 'Card Number',
    completionApproved: 'Approved - Thank You',
    refundSubmitted: 'Refund Submitted',
    thankyou: 'Thank You',
    transactionNotCompleted: 'Transaction Not Completed',
    completionDeclined: 'Transaction Not Approved',
    authorizedNotCompleted: 'Authorized - Not Completed',
    refundSubmittedSubtextPart1: 'A refund request for your purchase has been sent to your card issuer. Funds will be deposited to your account within 10 business days. Contact EQ3 Customer Care at',
    refundSubmittedSubtextPart2: 'if you do not see your refund after 10 business days.'
};

const frenchStrings: ReceiptTranslations = {
    ...englishStrings,
    txReference: 'N° de référence',
    txOrderNumber: 'Commande n°',
    txCashier: 'Caissier',
    txTypePurchase: 'ACHAT',
    txTypeRefund: 'REMISE D\'ACHAT',
    txAmount: 'MONTANT',
    txAccount: 'Compte',
    cardNumber: 'N. De Carte',
    completionApproved: 'Approuvee - Merci',
    refundSubmitted: 'Remboursement soumis',
    thankyou: 'Merci',
    transactionNotCompleted: 'Operation Non-Completee',
    completionDeclined: 'Operation Refusee',
    authorizedNotCompleted: 'Autorisé - Non complété',
    refundSubmittedSubtextPart1: `Une demande de remboursement de votre achat a été envoyée à l'émetteur de votre carte. Les fonds seront déposés sur votre compte dans les 10 jours ouvrables. Contactez le Service Clientèle d'EQ3 au`,
    refundSubmittedSubtextPart2: `si vous ne voyez pas votre remboursement après 10 jours ouvrables.`
};

const translations: Translations<ReceiptTranslations> = {
    [Language.EN]: englishStrings,
    [Language.FR]: frenchStrings,
};

export default translations;
