import { Locale } from '@eq3/utils/locales';
import qs from 'qs';
import { createActions, handleActions } from 'redux-actions';
import { ThunkResult } from 'redux-thunk';
import { defer, forkJoin, from, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, reduce, tap } from 'rxjs/operators';
import { currencies } from '../utils/locales';
import { errorNotification, notify, successNotification } from './adminNotifications';
import { apiThunk } from './store';

export interface IGradeColour {
    id: string;
    internalName: string;
    swatchImage?: {
        swatchUrl: string;
        thumbnailUrl: string;
    };
    activeStartDate?: number;
    activeEndDate?: number;
    isActive: boolean;
    isDisabled: boolean;
    hasCylindoSwatch: boolean;
    familyId: string;
    translations: {
        [key in Locale]?: {
            colourName: string;
            details: string;
        }
    };
    newSwatchImage?: {
        file: File;
        preview: string;
    };
}

export interface IGradeColourFamily {
    id: string;
    name: string;
}

export const {
    setUpholstery,
    setPriceJumps,
    setGradeGroups,
    setGrades,
    setSearchResults,
    setGradeColours,
    setGradeColourFamilies,
    setGradeNameFilter,
    setUnassignedGrades,
    setPieceCodes,
    setSeriesData,
    setUpholsteryItemTagData,
    setPaginatedTotalCount,
    clearUpholsteryItemData,
    appendUpholsteryItemData,
    setUpholsteryItemPageNumber,
    setLoadingUpholsteryItemsData,
    updateUpholsteryItem,
    setGradeColourTree,
    setCostJumps,
    setItemColours,
} = createActions({
    SET_UPHOLSTERY: (data) => data,
    SET_PRICE_JUMPS: (pricejumps) => pricejumps,
    SET_GRADE_GROUPS: (gradegroups) => gradegroups,
    SET_GRADES: (grades) => grades,
    SET_SEARCH_RESULTS: (results) => results,
    SET_GRADE_COLOURS: (gradecolours) => gradecolours,
    SET_GRADE_COLOUR_FAMILIES: (families) => families,
    SET_GRADE_NAME_FILTER: (names: string[]) => names,
    SET_UNASSIGNED_GRADES: (unassignedGrades) => unassignedGrades,
    SET_PIECE_CODES: (piececodes) => piececodes,
    SET_SERIES_DATA: (seriesData) => ({ seriesData }),
    SET_UPHOLSTERY_ITEM_TAG_DATA: (tagData) => ({ tagData }),
    SET_PAGINATED_TOTAL_COUNT: (totalCount) => ({ totalCount }),
    CLEAR_UPHOLSTERY_ITEM_DATA: () => ({}),
    APPEND_UPHOLSTERY_ITEM_DATA: (upholsteryItemData, pageSize, pageNumber, pageSizeReceived) => ({ upholsteryItemData, pageSize, pageNumber, pageSizeReceived }),
    SET_UPHOLSTERY_ITEM_PAGE_NUMBER: (pageNumber) => ({ pageNumber }),
    SET_LOADING_UPHOLSTERY_ITEMS_DATA: (isLoading) => ({ isLoading }),
    UPDATE_UPHOLSTERY_ITEM: (item) => ({ item }),
    SET_GRADE_COLOUR_TREE: (gradeColourTree) => ({ gradeColourTree }),
    SET_COST_JUMPS: (costJumps) => ({ costJumps }),
    SET_ITEM_COLOURS: (itemId, colours) => ({ itemId, colours }),
});

export default handleActions({
    [setUpholstery]: (state, { payload: data }) => ({ ...state, list: data, loading: false }),
    [setPriceJumps]: (state, { payload: pricejumps }) => ({ ...state, pricejumps, loading: false }),
    [setGradeGroups]: (state, { payload: gradegroups }) => ({ ...state, gradegroups, loading: false }),
    [setGrades]: (state, { payload: grades }) => ({ ...state, grades, loading: false }),
    [setSearchResults]: (state, { payload: searchResults }) => ({ ...state, searchResults }),
    [setGradeColours]: (state, { payload: gradecolours }) => ({ ...state, gradecolours, loading: false }),
    [setGradeColourFamilies]: (state, { payload: families }) => ({ ...state, gradeColourFamilies: families }),
    [setGradeNameFilter]: (state, { payload: gradeNames }) => ({ ...state, gradeNames }),
    [setUnassignedGrades]: (state, { payload: unassignedGrades }) => ({ ...state, unassignedGrades, loading: false }),
    [setPieceCodes]: (state, { payload: piececodes }) => ({ ...state, piececodes, loading: false }),
    [setSeriesData]: (state, { payload: { seriesData } }) => ({ ...state, seriesData }),
    [setUpholsteryItemTagData]: (state, { payload: { tagData } }) => ({ ...state, tagData }),
    [setPaginatedTotalCount]: (state, { payload: { totalCount } }) => ({ ...state, pagination: { ...state.pagination, totalCount } }),
    [clearUpholsteryItemData]: (state) => ({ ...state, upholsteryItemData: [] }),
    [appendUpholsteryItemData]: (state, { payload: { upholsteryItemData, pageSize, pageNumber, pageSizeReceived } }) => {

        const existingData = [...state.upholsteryItemData];
        existingData.splice(pageNumber * pageSize, pageSizeReceived, ...upholsteryItemData);

        return {
            ...state,
            upholsteryItemData: existingData,
        };
    },
    [setUpholsteryItemPageNumber]: (state, { payload: { pageNumber } }) => ({ ...state, pagination: { ...state.pagination, pageNumber } }),
    [setLoadingUpholsteryItemsData]: (state, { payload: { isLoading } }) => ({ ...state, loading: isLoading }),
    [updateUpholsteryItem]: (state, { payload: { item } }) => {

        const upholsteryItemData = [...state.upholsteryItemData];

        const index = upholsteryItemData.findIndex(({ item: itemId }) => itemId === item.item);

        if (index >= 0) {
            upholsteryItemData.splice(index, 1, { ...upholsteryItemData[index], ...item });
        }

        return { ...state, upholsteryItemData };
    },
    [setGradeColourTree]: (state, { payload: { gradeColourTree } }) => ({ ...state, gradeColourTree }),
    [setCostJumps]: (state, { payload: { costJumps } }) => ({ ...state, costJumps }),
    [setItemColours]: (state, { payload: { colours, itemId } }) => ({ ...state, itemColours: { ...state.itemColours, [itemId]: colours } }),
}, { 
    loading: true,
    list: [],
    seriesData: [],
    tagData: [],
    pagination: { pageNumber: 0, pageSize: 25, totalCount: 0 },
    upholsteryItemData: [],
    searchResults: [],
    gradeNames: [],
    itemColours: {},
    gradecolours: [],
    gradeColourFamilies: [],
});

export const fetchUpholstery = () => async (dispatch, getStore, api: apiThunk) => {
    try {
        const { data } = await api(dispatch, getStore, '/admin/upholstery-grades');
        dispatch(setUpholstery(data));
    } catch (e) {
        dispatch(notify(errorNotification('Error getting Upholstery', e)));
        throw e;
    }
};

export const fetchPieceCodes = () => async (dispatch, getStore, api: apiThunk) => {
    try {
        const { data } = await api(dispatch, getStore, `/admin/upholstery/piece-codes/list-items`, 'GET', undefined);
        dispatch(setPieceCodes(data));
    } catch (e) {
        dispatch(notify(errorNotification('Error getting Piece Codes', e)));
        throw e;
    }
};

export const fetchGradeGroups = () => async (dispatch, getStore, api: apiThunk) => {
    try {
        const { data } = await api(dispatch, getStore, `/admin/upholstery/grade-groups`, 'GET', undefined);
        dispatch(setGradeGroups(data.map((gg) => ({ id: gg.gradeGroup, description: gg.internalName }))));
    } catch (e) {
        dispatch(notify(errorNotification('Error getting Grade Groups', e)));
        throw e;
    }
};

export const fetchGrades = (gradeGroup) => async (dispatch, getStore, api: apiThunk) => {
    try {
        const { data } = await api(dispatch, getStore, `/admin/upholstery/grades/${gradeGroup}`, 'GET');
        dispatch(setGrades(data));
    } catch (e) {
        dispatch(notify(errorNotification('Error getting Grades', e)));
        throw e;
    }
};

export const fetchGradeNamesFilter = () => async (dispatch, getStore, api: apiThunk) => {
    try {
        const { data } = await api(dispatch, getStore, `/admin/upholstery-grades/get-names`);
        dispatch(setGradeNameFilter(data));
    } catch (e) {
        dispatch(notify(errorNotification('Error getting grade names', e)));
        console.error(e);
    }
};

export const searchGrades = (query: string) => async (dispatch, getStore, api: apiThunk) => {
    try {
        const { data } = await api(dispatch, getStore, `/admin/upholstery/grades`, 'GET', null, {
            params: {
                searchTerm: query,
            },
        });

        dispatch(setSearchResults(data));
    } catch (e) {
        dispatch(notify(errorNotification('Error searching grades', e)));
        console.error(e);
    }
};

export const fetchGradeColours = (gradeGroup: string, gradeCode: string): ThunkResult<Observable<IGradeColour[]>> => (dispatch, getStore, api) => {
    return defer(() => api<IGradeColour[]>(dispatch, getStore, `/admin/upholstery/grades/${gradeGroup}/${gradeCode}/colours`, 'GET', undefined))
        .pipe(
            map(({ data }) => data),
            tap((colours) => dispatch(setGradeColours(colours))),
            catchError((e) => {
                console.error(e);
                dispatch(notify(errorNotification('Error getting Grade Colours', e)));

                return throwError(e);
            }),
        );
};

export const fetchColourFamilies = (): ThunkResult<Observable<IGradeColourFamily[]>> => (dispatch, getState, api) => {
    return defer(() => api<IGradeColourFamily[]>(dispatch, getState, '/admin/upholstery/colour-families', 'GET'))
        .pipe(
            map(({ data }) => data),
            tap((families) => dispatch(setGradeColourFamilies(families))),
            catchError((e) => {
                console.error(e);
                dispatch(notify(errorNotification('Error getting Grade Colours', e)));

                return throwError(e);
            }),
        );
};

export const saveGradeColours = (gradeGroup: string, gradeCode: string, values: Record<string, IGradeColour>): ThunkResult<Observable<IGradeColour[]>> => (dispatch, getStore, api) => {
    return from(Object.values(values)).pipe(
        filter((colour) => !!colour.newSwatchImage),
        mergeMap((colour) => defer(() => {
            const formData = new FormData();
            formData.append('file', colour.newSwatchImage!.file);

            return forkJoin({
                colourId: of(colour.id),
                swatches: from(api<IGradeColour['swatchImage']>(dispatch, getStore, `/admin/upholstery/grades/${gradeGroup}/${gradeCode}/colours/${colour.id}/image`, 'PUT', formData)).pipe(
                    map(({ data }) => data!),
                ),
            });
            
        })),
        reduce((swatches, { colourId, swatches: uploaded }) => ({
            ...swatches,
            [colourId]: {
                ...values[colourId],
                swatchImage: uploaded,
            },
        }), values),
        mergeMap((values) => api<IGradeColour[]>(dispatch, getStore, `/admin/upholstery/grades/${gradeGroup}/${gradeCode}/colours`, 'PUT', Object.values(values))),
        map(({ data }) => data),
        tap((colours) => dispatch(setGradeColours(colours))),
        tap(dispatch(notify(successNotification('Grade Colours saved!')))),
        catchError((e) => {
            dispatch(notify(errorNotification('Error saving Grade Colours', e)));
            return throwError(e);
        }),
    );
};

export const saveGrades = (gradeGroup, values) => async (dispatch, getStore, api: apiThunk) => {
    try {
        const { data } = await api(dispatch, getStore, `/admin/upholstery/grades/${gradeGroup}`, 'PUT', values);
        dispatch(setGrades(data));
        dispatch(notify(successNotification('Grades saved!')));
        return data;
    } catch (e) {
        dispatch(notify(errorNotification('Error saving Grades', e)));
        throw e;
    }
};

export const fetchUnassignedGrades = () => async (dispatch, getStore, api: apiThunk) => {
    try {
        const { data } = await api(dispatch, getStore, `/admin/upholstery/grades`, 'GET', undefined, {
            params: {
                unassigned: true,
            },
        });
        dispatch(setUnassignedGrades(data));
    } catch (e) {
        dispatch(notify(errorNotification('Error getting Unassigned Grades', e)));
        throw e;
    }
};

export const fetchSeriesData = () => async (dispatch, getStore, api: apiThunk) => {
    try {
        const { data } = await api(dispatch, getStore, '/admin/upholstery/series', 'GET');
        dispatch(setSeriesData(data));

    } catch (e) {
        dispatch(notify(errorNotification('Error retrieving upholstery series data', e)));
        throw e;
    }
};

export const fetchTagData = () => async (dispatch, getStore, api: apiThunk) => {
    try {
        const { data } = await api(dispatch, getStore, '/admin/upholstery/tags', 'GET');
        dispatch(setUpholsteryItemTagData(data));
    } catch (e) {
        dispatch(notify(errorNotification('Error retrieving Tag data', e)));
        throw e;
    }
};

export const fetchItems = (item, itemDescription, series, families, tags, pieceCodes, plcCodes, page, pageSize, { orderBy, ordering }, active, gradeNames, numberOfGrades) => async (dispatch, getStore, api: apiThunk) => {
    const params = {
        itemId: item,
        itemDescription,
        seriesNames: series,
        families,
        tags,
        pieceCodes,
        plcCodes,
        pageSize,
        page,
        orderBy,
        ordering,
        isActive: active === 'all' ? undefined : active === 'active',
        gradeNames: gradeNames && gradeNames.length > 0 ? gradeNames : undefined,
        numberOfGrades,
    };

    Object.keys(params).forEach((key) => ((params[key] === '' || params[key] === undefined) && delete params[key]));

    const { data } = await api(dispatch, getStore, '/admin/upholstery/items/', 'GET', undefined, {
        params,
        paramsSerializer: (params) => qs.stringify(params, {
            arrayFormat: 'repeat',
        }),
    });

    return data;
};

export const fetchItemColours = (item) => async (dispatch, getStore, api: apiThunk) => {
    try {
        const { data } = await api(dispatch, getStore, `/admin/upholstery/items/${item}/colours`, 'GET');
        dispatch(setItemColours(item, data));
    } catch (e) {
        dispatch(notify(errorNotification('Error retrieving upholstery item colours', e)));
        throw e;
    }
};

export const loadUpholsteryItems = (item, itemDescription, series, families, tags, pieceCodes, plcCodes, page, pageSize, { orderBy, ordering }, active, gradeNames, numberOfGrades) => async (dispatch, getStore, api: apiThunk) => {
    try {
        dispatch(setLoadingUpholsteryItemsData(true));

        const { items, page: pageNumberReceived, pageSize: pageSizeReceived, totalCount } = await dispatch(fetchItems(item, itemDescription, series, families, tags, pieceCodes, plcCodes, page, pageSize, { orderBy, ordering }, active, gradeNames, numberOfGrades));

        dispatch(setPaginatedTotalCount(totalCount));
        dispatch(appendUpholsteryItemData(items, pageSize, pageNumberReceived, pageSizeReceived));
        dispatch(setLoadingUpholsteryItemsData(false));
    } catch (e) {
        dispatch(notify(errorNotification('Error retrieving upholstery data', e)));
        throw e;
    }
};

export const updateSeries = (series) => async (dispatch, getStore, api: apiThunk) => {
    try {
        await api(dispatch, getStore, `/admin/upholstery/series/${series.id}`, 'PUT', series);
        const seriesData = [...getStore().upholstery.seriesData];

        const index = seriesData.findIndex(({ id }) => id === series.id);

        seriesData.splice(index, 1, series);

        dispatch(setSeriesData(seriesData));
        dispatch(notify(successNotification('Series has been updated!')));
    } catch (e) {
        dispatch(notify(errorNotification('Error updating series', e)));
        throw e;
    }
};

export const updatePieceCode = (pieceCode) => async (dispatch, getStore, api: apiThunk) => {
    try {
        await api(dispatch, getStore, `/admin/upholstery/piece-codes/${pieceCode.id}`, 'PUT', pieceCode);
        const pieceCodeData = [...getStore().upholstery.piececodes];

        const index = pieceCodeData.findIndex(({ id }) => id === pieceCode.id);

        pieceCodeData.splice(index, 1, pieceCode);

        dispatch(setPieceCodes(pieceCodeData));
        dispatch(notify(successNotification('Piece Code has been updated!')));
    } catch (e) {
        dispatch(notify(errorNotification('Error updating Piece Code', e)));
        throw e;
    }
};

export const updateItems = (items) => async (dispatch, getStore, api: apiThunk) => {
    try {
        const itemUpdates = items.map(({ item, isDisabled, activeStartDate, activeEndDate, prices, costs, grades }) => {
            const transformedPrices = {};
            const transformedCosts = {};
            currencies.forEach((c) => {
                c.locales.forEach((l) => {
                    transformedPrices[l] = prices[c.locales[0]] || undefined;
                    transformedCosts[l] = costs[c.locales[0]] || undefined;
                });
            });

            const update = {
                item,
                activeStartDate: activeStartDate || undefined,
                activeEndDate: activeEndDate || undefined,
                prices: transformedPrices || undefined,
                costs: transformedCosts || undefined,
                grades,
                isDisabled,
            };

            return update;
        });

        const { data: updatedItems } = await api(dispatch, getStore, '/admin/upholstery/items', 'PUT', itemUpdates);

        updatedItems.forEach(async (itemUpdate) => {
            await dispatch(updateUpholsteryItem(itemUpdate));
        });

        await dispatch(notify(successNotification('Upholstery items updated!')));
    } catch (e) {
        dispatch(notify(errorNotification('Error updating upholstery items', e)));
        throw e;
    }
};

export const fetchGradeColourTree = () => async (dispatch, getStore, api: apiThunk) => {
    try {
        const { data } = await api(dispatch, getStore, '/admin/upholstery/grade-colour-tree', 'GET');

        dispatch(setGradeColourTree(data));
    } catch (e) {
        dispatch(notify(errorNotification('Error retrieving upholstery grade colour tree', e)));
        throw e;
    }
};

interface ISearchQuery {
    item?: string;
    itemDescription?: string;
    page?: number;
    pageSize?: number;
}

// This method is only for Upholstery Item Search Dialogs. Use fetchItems for fetching and filtering items
export const searchUpholsteryItems = ({ item, itemDescription, page, pageSize }: ISearchQuery = { item: '', itemDescription: '', page: 0, pageSize: 50 }) => async (dispatch, getStore, api: apiThunk) => {
    const params = {
        itemId: item,
        itemDescription,
        pageSize,
        page,
    };

    Object.keys(params).forEach((key) => ((params[key] === '' || params[key] === undefined) && delete params[key]));

    const { data } = await api(dispatch, getStore, '/admin/upholstery/items/search', 'GET', undefined, {
        params,
    });

    return data;
};
