import { StyleRules, WithStyles } from '@material-ui/core/styles';
import React from 'react';

import {compose} from 'redux';
import {Tags} from './Tags';
import SmallPartialTag from './tags/small/SmallPartialTag';

import { withStyles } from '@material-ui/core';

class Proposition65Page extends React.Component<WithStyles<ReturnType<typeof styles>>> {

    private menu = {
        itemTree: [
            {
                id: 'prop65Generic',
                labelId: Tags.prop65Generic.labelId,
                data: {tag: Tags.prop65Generic},
            },
        ],
    };

    public render(): JSX.Element {

        const {classes} = this.props;

        return (
            <div className={classes.page}>
                <SmallPartialTag menu={this.menu} initialProduct={this.menu.itemTree[0].data}/>
                <SmallPartialTag menu={this.menu} initialProduct={this.menu.itemTree[0].data}/>
                <SmallPartialTag menu={this.menu} initialProduct={this.menu.itemTree[0].data}/>

                <SmallPartialTag menu={this.menu} initialProduct={this.menu.itemTree[0].data}/>
                <SmallPartialTag menu={this.menu} initialProduct={this.menu.itemTree[0].data}/>
                <SmallPartialTag menu={this.menu} initialProduct={this.menu.itemTree[0].data}/>

                <SmallPartialTag menu={this.menu} initialProduct={this.menu.itemTree[0].data}/>
                <SmallPartialTag menu={this.menu} initialProduct={this.menu.itemTree[0].data}/>
                <SmallPartialTag menu={this.menu} initialProduct={this.menu.itemTree[0].data}/>

                <SmallPartialTag menu={this.menu} initialProduct={this.menu.itemTree[0].data}/>
                <SmallPartialTag menu={this.menu} initialProduct={this.menu.itemTree[0].data}/>
                <SmallPartialTag menu={this.menu} initialProduct={this.menu.itemTree[0].data}/>

                <SmallPartialTag menu={this.menu} initialProduct={this.menu.itemTree[0].data}/>
                <SmallPartialTag menu={this.menu} initialProduct={this.menu.itemTree[0].data}/>
                <SmallPartialTag menu={this.menu} initialProduct={this.menu.itemTree[0].data}/>
            </div>
        );
    }
}

const styles = (theme) => ({
    page: {
        width: '11in',
        height: '8.5in',
        padding: '0.109375in 0.15625in',
        display: 'flex',
        flexWrap: 'wrap',
    },
}) as StyleRules;

export default (compose(
    withStyles(styles),
)(Proposition65Page));
