import { useRxSubject } from '@eq3/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { switchMap } from 'rxjs/operators';
import { IMyProfileReduxSlice, MY_PROFILE_REDUX_KEY } from './models';
import { fetchMyProfile } from './thunks';

export const useMyProfileData = () => {
    const userDetails = useSelector((state: IMyProfileReduxSlice) => state[MY_PROFILE_REDUX_KEY].userDetails);

    const dispatch = useDispatch<ThunkDispatch>();
    const fetchMyProfile$ = useRxSubject<{ refresh: boolean }>((o) => {
        return o.pipe(
            switchMap(({ refresh }) => dispatch(fetchMyProfile(refresh)))
        ).subscribe();
    });

    return {
        executeFetch: (refresh: boolean = true) => fetchMyProfile$.next({ refresh }),
        userDetails,
    };
};
