import { MenuItem, Select, SelectProps } from '@material-ui/core';
import React, { ComponentType, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const rugPrices = [
	{ key: 'Select Price Type', value: 'none' },
	{ key: 'Normal', value: 'normal' },
	{ key: 'Discount', value: 'discount' },
];

const RugPriceSelect = (props: SelectProps) => {
	const classes = useStyles();
	const nativeOptions = rugPrices.map(({ key, value }, index) => (
		<option key={`${key}-${index}`} value={value}>
			{key}
		</option>
	));
	const menuItemOptions = rugPrices.map(({ key, value }, index) => (
		<MenuItem key={`${key}-${index}`} value={value}>
			{key}
		</MenuItem>
	));

	const options = useMemo(() => {
		return props.native ? nativeOptions : menuItemOptions;
	}, []);

	return (
		<>
			<Select {...props} inputProps={{ className: classes.rugSelect }}>
				{options}
			</Select>
		</>
	);
};

const useStyles = makeStyles(() => ({
	rugSelect: {
		backgroundColor: '#e1f6fd',
		paddingLeft: '5px',
		borderRadius: '7px',
	},
}));

export default React.memo(RugPriceSelect) as ComponentType<SelectProps>;
