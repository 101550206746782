import { TerminalDetailsDto, TerminalLocationDtoSquareLocationId } from '@eq3-aws/payments-client';
import { errorNotification, notify } from '@eq3/redux/adminNotifications';
import { useMyProfileData } from '@eq3/redux/myProfile/selectors';
import { IPosReduxSlice } from '@eq3/redux/pos/reducers';
import {
    getSquareDevicesForPlatformEntity,
    getSquareTerminalLocations,
} from '@eq3/redux/pos/thunks/squareTerminalLocationsThunks';
import { Countries } from '@eq3/utils/locales';
import React, { PropsWithChildren, createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { IUseSquareProps } from '@eq3/hooks/payments';
import { getTerminalLocationFromPlatformId } from '@eq3/redux/pos/thunks/squareTerminalLocationsThunks';
import { testDevicesList } from './test-devices-list';

/**
 * Refs:
 * https://stripe.com/docs/terminal/sdk/js
 * https://www.npmjs.com/package/@stripe/terminal-js
 *
 * Testing with physical cards -> https://stripe.com/docs/terminal/testing#physical-test-card
 */
export interface ISquareTerminalContext {
    // isInitializing: boolean;
    // stripeTerminal: StripeTerminal | undefined;
    // terminal: Terminal | undefined;

    // readerConnectionStatus: ConnectionStatus | undefined;
    // terminalStatus: PaymentStatus | undefined;
    squareJsCredentials: IUseSquareProps | null;

    isLoadingDevices: boolean;
    devices: TerminalDetailsDto[] | undefined;
    devicesFetchError: any;

    // isConnectingToReader: string | undefined;
    connectedReader: TerminalDetailsDto | undefined;
    // connectToReaderError: IConnectReaderError | undefined;
    // isDisconnectingFromReader: boolean;

    isLoadingSquarePosLocations: boolean;
    squarePosLocationsForPlatformCountry: TerminalLocationDtoSquareLocationId[];

    // Data will appear on receipts.
    platformLocationId: string | undefined;
    platformLocationCountry: Countries | undefined;
    platformLocationName: string | undefined;
    cashierName: string | undefined;

    actions: {
        refreshCardReaders: () => void;
        refreshPosLocations: () => void;
        connectToReader: (device: TerminalDetailsDto) => void;
        disconnectFromReader: () => void;
    };
}

interface IPlatformLocation {
    id?: string; // Super users do not have an assigned location.
    country: Countries;
}

const SquareTerminalContext = createContext<ISquareTerminalContext | undefined>(undefined);

export const useSquareTerminalContext = () => useContext(SquareTerminalContext);

// const cardReaderDiscoveryConfig: DiscoveryConfig = {
//     simulated: process.env.FEATURE_FLAG_STRIPE_TERMINAL_SIMULATOR_ENABLED === 'true',
// };

export const SquareTerminalContextProvider = (props: PropsWithChildren<any>) => {
    const { children } = props;

    const dispatch = useDispatch<ThunkDispatch>();
    const { userDetails /*executeFetch: fetchUserProfile*/ } = useMyProfileData();

    // Initialized once both stripeTerminal and terminal instances are set.
    // const [isInitializing, setIsInitializing] = useState(true);
    // const [stripeTerminal, setStripeTerminal] = useState<StripeTerminal>();
    // const [terminal, setTerminal] = useState<Terminal>();
    const [platformLocation, setPlatformLocation] = useState<IPlatformLocation>();
    const [squareJsCredentials, setSquareJsCredentials] = useState<IUseSquareProps | null>(null);

    const [isLoadingSquarePosLocations, setIsLoadingSquarePosLocations] = useState(false);
    const [isLoadingDevices, setIsLoadingDevices] = useState(false);
    const [devices, setDevices] = useState<TerminalDetailsDto[]>();
    const [devicesFetchError, setDevicesFetchError] = useState<any>();

    // const [readerConnectionStatus, setReaderConnectionStatus] = useState<ConnectionStatus>();
    // const [terminalStatus, setTerminalStatus] = useState<PaymentStatus>();

    // const [isConnectingToReader, setIsConnectingToReader] = useState<string>();
    const [connectedReader, setConnectedReader] = useState<TerminalDetailsDto>();
    // const [connectToReaderError, setConnectToReaderError] = useState<IConnectReaderError>();
    // const [isDisconnectingFromReader, setIsDisconnectingFromReader] = useState(false);

    const refreshCardReadersSubscription = useRef<Subscription>();
    const refreshPosLocationsSubscription = useRef<Subscription>();
    // const connectToReaderSubscription = useRef<Subscription>();
    // const disconnectFromReaderSubscription = useRef<Subscription>();

    const cashierFirstName = useMemo(() => {
        const trimmedName = userDetails?.user.name.trim();
        const splitName = trimmedName?.split(' ');

        return splitName?.[0]?.length ?? 0 > 0 ? splitName?.[0] : trimmedName;
    }, [userDetails?.user.name]);

    const squarePosLocationsForPlatformCountry = useSelector<IPosReduxSlice, TerminalLocationDtoSquareLocationId[]>(
        (state) => {
            return state.pos[platformLocation?.country!]?.squareTerminalLocations ?? [];
        }
    );

    const platformLocationName = useMemo(() => {
        return (
            squarePosLocationsForPlatformCountry.find((t) => t.platformLocationId === platformLocation?.id)?.name ?? ''
        );
    }, [squarePosLocationsForPlatformCountry, platformLocation?.id]);

    // When the user's profile is loaded, or when the user's current location has changed, then update the platform location.
    // This is used to record where transactions happen and is displayed on receipts).
    useEffect(() => {
        if (userDetails) {
            setPlatformLocation({
                id: userDetails?.currentWorkingLocation?.id,
                country:
                    (userDetails?.currentWorkingLocation?.address.country?.toUpperCase() as Countries) ?? Countries.CA,
            });
        }
    }, [userDetails]);

    useEffect(() => {
        if (!platformLocation?.id) return;
        const subscription = dispatch(getTerminalLocationFromPlatformId(platformLocation.id)).subscribe(
            ({ address, locationId }) => {
                setSquareJsCredentials({
                    appId: address?.country === 'US' ? process.env.SQUARE_APP_ID_US! : process.env.SQUARE_APP_ID_CA!,
                    locationId: locationId.id,
                });
            }
        );

        return () => {
            if (!subscription?.closed) subscription.unsubscribe();
        };
    }, [platformLocation?.id]);

    // // Once the StripeTerminal lib and platform location have been set (or changed),
    // // then we can create (or re-create) a Terminal JS instance.
    // useEffect(() => {
    //     if (stripeTerminal && platformLocation?.id) {
    //         initializeTerminalJsInstanceWithLocationId(stripeTerminal, platformLocation!.id);
    //     } else if (stripeTerminal) {
    //         // If we've switched the platform country, then ensure that we're disconnected from the previous terminal.
    //         _disconnectFromReaderRx()
    //             .pipe(finalize(() => setIsInitializing(false)))
    //             .subscribe();
    //     }
    // }, [stripeTerminal, platformLocation?.id]);

    // Once the terminal JS instance is ready (or has changed),
    // then refresh the terminals list when the platform country (AKA Stripe account) changes.
    useEffect(() => {
        if (platformLocation?.id) {
            refreshCardReaders();
        } else {
            // Else just kill the subscription to refresh card readers.
            refreshCardReadersSubscription.current?.unsubscribe();
        }
    }, [platformLocation?.id]);

    // If the platform country has changed,
    // then fetch the terminal locations (results are cached).
    useEffect(() => {
        if (platformLocation?.country) {
            refreshPosLocations();
        }
    }, [platformLocation?.country]);

    // const initializeTerminalJsInstanceWithLocationId = async (
    //     stripeTerminal: StripeTerminal,
    //     platformLocationId: string,
    // ) => {
    //     setIsInitializing(true);

    //     // Cleanup if we're already connected to a terminal.
    //     if (connectedReader && terminal) {
    //         console.log(`Disconnecting from ${connectedReader?.label}`);
    //         await _disconnectFromReaderRx().toPromise();
    //     }

    //     // Clear out current terminal instance. We're about to replace it.
    //     setTerminal(undefined);

    //     // Before to init the Stripe Terminal instance, ensure that the current platform location also a valid stripe location.
    //     try {
    //         await dispatch(getTerminalLocationFromPlatformId(platformLocationId)).toPromise();
    //     } catch (err) {
    //         if ((err as any)?.response.request?.status === 404) {
    //             // Just eat the error, else retail users that are logged in under a location that does NOT have a Stripe
    //             // location will always see errors on page loads.
    //             console.log(`Platform ID ${platformLocationId} is not a valid Stripe location.`);
    //             setIsInitializing(false);
    //             return;
    //         }
    //         throw err;
    //     }
    //     console.log('Initializing terminal.');
    //     setIsInitializing(false);
    // };

    // const resetCardReader = () => {
    //     // setIsConnectingToReader(undefined);
    //     setConnectedReader(undefined);
    //     // setConnectToReaderError(undefined);
    // };

    const refreshPosLocations = () => {
        if (!platformLocation?.country) {
            // Can't refresh pos locations. Platform country not yet set.
            return;
        }
        refreshPosLocationsSubscription.current?.unsubscribe();

        console.log(`Refreshing POS locations for ${platformLocation?.country}`);
        setIsLoadingSquarePosLocations(true);
        refreshPosLocationsSubscription.current = dispatch(getSquareTerminalLocations(platformLocation!.country))
            .pipe(finalize(() => setIsLoadingSquarePosLocations(false)))
            .subscribe();
    };

    const refreshCardReaders = () => {
        if (!platformLocation?.id) {
            // Can't refresh terminals list. Platform location not yet set.
            return;
        }
        console.log(`Refresh Square devices for ${platformLocation?.country} ${platformLocation?.id}...`);
        refreshCardReadersSubscription.current?.unsubscribe();

        setIsLoadingDevices(true);
        setDevicesFetchError(undefined);
        setDevices(undefined);

        if (process.env.ENVIRONMENT !== 'prod') {
            setDevices(testDevicesList.terminals);
            setIsLoadingDevices(false);
            return;
        }

        refreshCardReadersSubscription.current = dispatch(getSquareDevicesForPlatformEntity(platformLocation!.id))
            .pipe(finalize(() => setIsLoadingDevices(false)))
            .subscribe({
                next: (x) => {
                    console.log('Refresh card readers result', x);
                    // setDevicesFetchError(x.error);
                    setDevices(x.terminals);
                },
                error: (err) => {
                    const msg =
                        err?.response?.data?.errorDescription ??
                        'Sorry! There was a problem refreshing the terminals list.';
                    console.error(msg, err);
                    setDevicesFetchError(msg);
                    dispatch(notify(errorNotification(msg, err)));
                },
            });
    };

    const connectToReader = (device: TerminalDetailsDto) => {
        console.log('Connecting to reader...');
        setConnectedReader(device);

        // connectToReaderSubscription.current?.unsubscribe();

        // setIsConnectingToReader(reader.id);
        // setConnectToReaderError(undefined);

        // connectToReaderSubscription.current = from(terminal!.connectReader(reader, options))
        //     .pipe(
        //         map((x) => (x as ErrorResponse & { reader: Reader })),
        //         finalize(() => {
        //             setIsConnectingToReaderId(undefined);
        //         }),
        //     ).subscribe({
        //         next: (connectedReader) => {
        //             console.log('Connect to reader result', connectedReader);
        //             setConnectToReaderError({ error: connectedReader.error, readerId: reader.id });
        //             setConnectedReader(connectedReader.reader);
        //         },
        //         error: (err) => {
        //             const msg = err?.response?.data?.errorDescription ?? `Sorry! Could not connect to the terminal "${reader.label}".`;
        //             console.error(msg, err);
        //             dispatch(notify(errorNotification(msg, err)));
        //         },
        //     });
    };

    const disconnectFromReader = () => {
        console.log(`Disconnecting from reader ${connectedReader?.terminalName}...`);
        setConnectedReader(undefined);

        // disconnectFromReaderSubscription.current?.unsubscribe();

        // disconnectFromReaderSubscription.current = _disconnectFromReaderRx()
        //     .subscribe({
        //         complete: () => {
        //             console.log(`Disconnected from reader ${connectedReader?.label}.`);
        //         },
        //         error: (err) => {
        //             const msg = err?.response?.data?.errorDescription ?? `Sorry! An error occurred when disconnecting from reader: ${connectedReader?.label}.`;
        //             console.error(msg, err);
        //             dispatch(notify(errorNotification(msg, err)));
        //         },
        //     });
    };

    // const _disconnectFromReaderRx = () => {
    //     return iif(
    //         () => !!terminal,
    //         defer(() => {
    //             setIsDisconnectingFromReader(true);

    //             return from(terminal!.disconnectReader());
    //         }),
    //         of(EMPTY),
    //     ).pipe(
    //         finalize(() => {
    //             resetCardReader();
    //             setIsDisconnectingFromReader(false);
    //         }),
    //     );
    // };

    const context: ISquareTerminalContext = {
        // isInitializing,
        // stripeTerminal,
        // terminal,
        squareJsCredentials,
        isLoadingDevices,
        devices,
        devicesFetchError,

        // readerConnectionStatus,
        // terminalStatus,

        // isConnectingToReaderId,
        connectedReader,
        // connectToReaderError,
        // isDisconnectingFromReader,

        isLoadingSquarePosLocations,
        squarePosLocationsForPlatformCountry,
        platformLocationId: platformLocation?.id,
        platformLocationCountry: platformLocation?.country,
        platformLocationName,
        cashierName: cashierFirstName,

        actions: {
            refreshPosLocations,
            refreshCardReaders,
            connectToReader,
            disconnectFromReader,
        },
    };

    return <SquareTerminalContext.Provider value={context}>{children}</SquareTerminalContext.Provider>;
};
