// TODO: move language/translations into util. Receipts are the first localized thing in admin portal?
export enum Language {
    EN = 'en',
    FR = 'fr',
}

export type Translations<T> = {
    [key in Language]: T;
};

export const LAST_SELECTED_RECEIPT_LANGUAGE_LS_KEY = 'terminal_receipts_last_selected_language';

type ReceiptTranslations = { [key in keyof typeof englishStrings]: typeof englishStrings[key] };

const englishStrings = {
    txReference: 'Reference #',
    txOrderNumber: 'Order #',
    txCashier: 'Cashier',
    txType: 'Type',
    txTypePurchase: 'PURCHASE',
    txTypeRefund: 'REFUND',
    txAmount: 'Amount',
    txAccount: 'Account',
    cardNumber: 'Card Number',
    completionApproved: 'Approved - Thank You',
    transactionNotCompleted: 'Transaction Not Completed',
    completionDeclined: 'Transaction Not Approved',
    authorizedNotCompleted: 'Authorized - Not Completed',
};

const frenchStrings: ReceiptTranslations = {
    ...englishStrings,
    txReference: 'N° de référence',
    txOrderNumber: 'Commande n°',
    txCashier: 'Caissier',
    txTypePurchase: 'ACHAT',
    txTypeRefund: 'REMISE D\'ACHAT',
    txAmount: 'MONTANT',
    txAccount: 'Compte',
    cardNumber: 'N. De Carte',
    completionApproved: 'Approuvee - Merci',
    transactionNotCompleted: 'Operation Non-Completee',
    completionDeclined: 'Operation Refusee',
    authorizedNotCompleted: 'Autorisé - Non complété',
};

const translations: Translations<ReceiptTranslations> = {
    [Language.EN]: englishStrings,
    [Language.FR]: frenchStrings,
};

export default translations;
