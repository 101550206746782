import qs from 'qs';
import { createActions, handleActions } from 'redux-actions';
import { currencies } from '../../utils/locales';
import { defaultPageSize, Pagination } from '../../utils/pagination';
import { camelToUnderscoreCase } from '../../utils/stringUtils';
import { errorNotification, notify, successNotification } from '../adminNotifications';
import { apiThunk } from '../store';
import { Fields, IFilters, IQuery, IRecord } from './models';

const initialState = {
    table: {
        records: new Pagination<IRecord>(0, defaultPageSize, 0, new Array<IRecord>()),
        currentQuery: {
            pageIndex: 0,
            pageSize: 10,
            sortKey: camelToUnderscoreCase(Fields.Sku),
            sortDirection: 'ASC',
        } as Partial<IQuery>,
        loading: true,
    },
    filters: {
        filters: {
            searchDesc: '',
            skus: [] as string[],
            vendors: [] as string[],
            itemClasses: [] as string[],
            itemTypes: [] as string[],
            isActive: undefined,
            plcCodes: undefined,
            isDeleted: undefined,
        } as IFilters,
        loading: true,
    },
};

export type PartnerItemsState = typeof initialState;
type GetState = () => ({ partnerItems: PartnerItemsState });

export const {
    partnerItemActions: {
        table: {
            fetchRecords: fetchTableRecordsAction,
            setCurrentQuery: setCurrentTableQueryAction,
            loading: setTableLoadingAction,
        },
        filters: {
            fetchFilters: fetchFiltersAction,
            loading: setFilterLoadingAction,
        },
    },
} = createActions({
    PARTNER_ITEM_ACTIONS: {
        TABLE: {
            FETCH_RECORDS: (records: Pagination<IRecord>) => records,
            SET_CURRENT_QUERY: (query: IQuery) => query,
            LOADING: (loading: boolean) => loading,
        },
        FILTERS: {
            FETCH_FILTERS: (filters: IFilters) => filters,
            LOADING: (loading: boolean) => loading,
        },
    },
});

export const fetchRecords = () => async (dispatch, getState: GetState, api: apiThunk) => {
    dispatch(setTableLoadingAction(true));

    try {
        const { partnerItems: { table } } = getState();
        const { currentQuery: query } = table;

        const { data }: { data: Pagination<IRecord> } = await api(dispatch, getState, `/admin/partner/items`, 'GET', null, {
            params: {
                ...query,
            },
            paramsSerializer: (params: any) => {
                return qs.stringify(params, {
                    arrayFormat: 'repeat',
                });
            },
        });

        dispatch(fetchTableRecordsAction(data));
    } catch (e) {
        console.error(e);
        dispatch(notify(errorNotification(e.message, e)));
    }

    dispatch(setTableLoadingAction(false));
};

export const fetchFilters = () => async (dispatch, getState: GetState, api: apiThunk) => {

    dispatch(setFilterLoadingAction(true));
    try {
        const { data } = await api(dispatch, getState, `/admin/partner/items/filters`, 'GET');
        dispatch(fetchFiltersAction(data));
    } catch (e) {
        console.error(e);
        dispatch(notify(errorNotification(e.message, e)));
    }

    dispatch(setFilterLoadingAction(false));
};

export const updatePartnerItems = (items) => async (dispatch, getStore, api: apiThunk) => {
    try {
        const itemUpdates = items.map(({localizedPrices, localizedCosts, ...rest}) => {
            const prices = localizedPrices && !!Object.keys(localizedPrices).length ? currencies.flatMap((c) => {
                return c.locales.map((l) => ({
                    [l]: localizedPrices[c.locales[0]],
                }));
            }).reduce((acc, x) => ({ ...acc, ...x }) , {}) : undefined;

            const costs = localizedCosts && !!Object.keys(localizedCosts).length ? currencies.flatMap((c) => {
                return c.locales.map((l) => ({
                    [l]: localizedCosts[c.locales[0]],
                }));
            }).reduce((acc, x) => ({ ...acc, ...x }) , {}) : undefined;

            return {
                prices,
                costs,
                ...rest,
            };
        });

        await api(dispatch, getStore, '/admin/partner/items', 'PUT', itemUpdates);

        await dispatch(notify(successNotification('Partner items updated!')));
    } catch (e) {
        dispatch(notify(errorNotification('Error updating partner items', e)));
        throw e;
    }
};

// This method is only for Item Search Dialogs. Use setCurrentTableQueryAction and fetchRecords for filtering items
export const searchPartnerItem = (query) => async (dispatch, getStore, api: apiThunk) => {
    try {
        const {data} = await api(dispatch, getStore, `/admin/partner/items/search`, 'GET', undefined, {
            params: { ...query },
            paramsSerializer: (params: any) => {
                return qs.stringify(params, {
                    arrayFormat: 'repeat',
                });
            },
        });

        return data;
    } catch (e) {
        dispatch(notify(errorNotification(`Error searching for Item '${query.searchDesc}'`, e)));
        throw e;
    }
};

export default handleActions({
    [setTableLoadingAction]: (state = initialState, { payload: loading }) => ({...state, table: { ...state.table, loading }}),
    [fetchTableRecordsAction]: (state = initialState, { payload: records }) => ({ ...state, table: { ...state.table, records }}),
    [setCurrentTableQueryAction]: (state = initialState, { payload: query }) => ({
        ...state,
        table: {
            ...state.table,
            currentQuery: {
                ...state.table.currentQuery,
                ...query,
            },
        },
    }),
    [fetchFiltersAction]: (state = initialState, { payload: filters }) => ({
        ...state,
        filters: {
            ...state.filters,
            filters,
        },
    }),
    [setFilterLoadingAction]: (state = initialState, { payload: loading }) => ({...state, filters: { ...state.filters, loading }}),
}, initialState);
