import { IOrderable, IPaginatable } from '@eq3/utils/pagination';
import { IChangeSet, IFilters, IGradeTree, IQuery, IUpholsteryItem, UpholsteryItemTab } from './models';

export const initialState = {
    records: {
        data: new Array<IUpholsteryItem>(),
        changes: new Array<IChangeSet>(),
    },
    filters: {
        items: null as IFilters,
        opened: false,
    },
    grades: {
        tree: null as IGradeTree,
        opened: false,
        expanded: [] as string[],
        checklist: null as { [itemId: string]: { [code: string]: boolean } },
    },
    pricing: {} as { [itemId: string]: IUpholsteryItem['prices'] },
    costs: {} as { [itemId: string]: IUpholsteryItem['costs'] },
    items: {} as { [itemId: string]: Pick<IUpholsteryItem, 'activeStartDate' | 'activeEndDate' | 'disabled'>},
    tab: 'items' as UpholsteryItemTab,
    pagination: null as IPaginatable,
    order: null as IOrderable,
    query: {
        active: true,
        disabled: false,
    } as Partial<IQuery>,
    loading: false,
    invalidItems: {} as {
        [validationCondition: string]: {
            countOfInvalid: number;
        };
    },
};

export type UpholsteryItemState = typeof initialState;
