import { deleteUndefinedKeys } from '@eq3/utils';
import { defaultPageSize, IPaginationParameters, pageSizes, Pagination } from '@eq3/utils/pagination';
import { Dispatch, SetStateAction, useState } from 'react';
import { forkJoin, iif, Observable, of } from 'rxjs';
import { catchError, debounceTime, mergeMap, switchMap, tap } from 'rxjs/operators';
import { useRxSubject } from '.';

export interface IPager<ResultType, FilterType extends Partial<IPaginationParameters>> {
    pagination: Pagination<ResultType> | null;
    filters: Partial<FilterType>;
    setFilters: Dispatch<SetStateAction<Partial<FilterType>>>;
    fetchData: (other?: Partial<FilterType> | ((previous: Partial<FilterType>) => Partial<FilterType>)) => void;
    isFetching: boolean;
    onFetchData$: Observable<Partial<FilterType>>;
    onDataFetched$: Observable<Pagination<ResultType>>;
    defaultPageSizes: number[];
}

export interface IPagerOptions<ResultType extends unknown, FilterType extends Partial<IPaginationParameters>> {
    dataFetcher: (pagination: Partial<FilterType>) => Observable<Pagination<ResultType>>;
    defaultPageSizes?: number[];
    initialFilters?: Partial<FilterType>;
}

const defaults: Pick<IPagerOptions<any, any>, 'initialFilters' | 'defaultPageSizes'> = {
    initialFilters: {
        offset: 0,
        pageIndex: 0,
        pageSize: defaultPageSize,
        limit: defaultPageSize,
        orderBy: '',
        orderDirection: 'ASC',
    },
    defaultPageSizes: pageSizes,
};

const usePager = <ResultType extends unknown, FilterType extends Partial<IPaginationParameters>>(options: IPagerOptions<ResultType, FilterType>): IPager<ResultType, FilterType> => {
    const [isFetching, setIsFetching] = useState(false);
    const [pagination, setPagination] = useState<Pagination<ResultType> | null>(null);
    const [filters, setFilters] = useState<Partial<FilterType>>(options.initialFilters ?? defaults.initialFilters as Partial<FilterType>);

    // Query string values are always strings, but we want real numbers for pagination.
    const cleansePaginationFilters = (filters: Partial<FilterType>): Partial<FilterType> => {
        const asNumber = (val: any | undefined) => {
            return val !== undefined
                ? Number(val)
                : undefined;
        };
        const pagination = deleteUndefinedKeys({
            pageIndex: asNumber(filters.pageIndex),
            limit: asNumber(filters.limit),
            offset: asNumber(filters.offset),
            pageSize: asNumber(filters.pageSize),
            totalCount: asNumber(filters.totalCount),
        });
        return {
            ...filters,
            ...pagination,
        };
    };

    const onFetchData = useRxSubject<Partial<FilterType>>((o) => o.pipe(
        debounceTime(100),
        tap(() => setIsFetching(true)),
        switchMap((filters) => forkJoin([of(filters), options.dataFetcher(filters)])),
        mergeMap(([filters, results]) => iif(
            // if the current page index we're on is greater than the result count
            // then we should return the first page of the results, otherwise
            // we'll be on an invalid page
            () => {
                // SPECIAL CASE:
                // If we have a negative total count, then that means the total count is indeterminate.
                if ( results.totalCount < 0 ) {
                    return false;
                }
                const totalPages = Math.ceil(results.totalCount / results.pageSize);
                const currentPage = results.page + 1;
                return currentPage > totalPages;
            },
            of({
                ...filters,
                pageIndex: 0,
                offset: 0,
            }).pipe(
                tap((filters) => setFilters(cleansePaginationFilters(filters))),
                mergeMap(options.dataFetcher),
            ),
            of(results).pipe(tap(() => setFilters(cleansePaginationFilters(filters))))
        )),
        // If the request fails, don't let the error complete the observable. We still want to be able to make more requests.
        catchError((err, caught) => {
            console.error(err);
            setIsFetching(false);
            return caught;
        })
    ).subscribe({
        next(results) {
            onDataFetched.next(results);
            setIsFetching(false);
        },
    }));

    const onDataFetched = useRxSubject<Pagination<ResultType>>((o) => o.pipe(
        tap((results) => setPagination(results)),
    ).subscribe());

    const fetchData = (other?: Partial<FilterType> | ((previous: Partial<FilterType>) => Partial<FilterType>)) => {
        const filterData = typeof other === 'function'
            ? other(filters)
            : {
                ...filters,
                ...other,
            };

        return onFetchData.next(filterData);
    };

    return {
        pagination,
        filters,
        setFilters,
        isFetching,
        fetchData,
        onFetchData$: onFetchData.asObservable(),
        onDataFetched$: onDataFetched.asObservable(),
        defaultPageSizes: options.defaultPageSizes ?? defaults.defaultPageSizes!,
    };
};

export default usePager;
