import React from 'react';

interface IReceiptFieldProps {
    fieldName?: string | undefined;
    seperator?: string | undefined;
    fieldValue: string | number | undefined;
}

const ReceiptField = ({ fieldName, seperator, fieldValue, ...rest }: IReceiptFieldProps) => {
    if (!fieldValue) {
        return null;
    }
    return (
        !!fieldName
            ?
            <pre {...rest}>{`${fieldName.toUpperCase()}${seperator ?? ':'} ${typeof fieldValue === 'string' ? fieldValue.toUpperCase() : fieldValue}`}</pre>
            : <pre {...rest}>{typeof fieldValue === 'string' ? fieldValue.toUpperCase() : fieldValue}</pre>
    );
};

export default ReceiptField;
