import {
    BeginRefundDto,
    BeginUnlinkedRefundDto,
    SquareTerminalTransactionDetailsDto,
    SquareUnlinkedRefundDetailsDto,
} from '@eq3-aws/payments-client';
import { paymentServiceClient } from '@eq3/clients/paymentService/paymentServiceClient';
import { errorNotification, notify } from '@eq3/redux/adminNotifications';
import { ThunkResult } from 'redux-thunk';
import { EMPTY, Observable, defer, interval, throwError, timer } from 'rxjs';
import { catchError, map, switchMap, takeUntil, takeWhile } from 'rxjs/operators';
import { PaymentThunkResult } from './squarePaymentThunks';

type RefundThunkResult<T> = ThunkResult<Observable<T>>;

export const createSquareUnlinkedRefund =
    (refundData: BeginUnlinkedRefundDto): RefundThunkResult<SquareUnlinkedRefundDetailsDto> =>
    (dispatch, getState) => {
        return defer(() =>
            paymentServiceClient(dispatch, getState).squareRefundController.createSquareUnlinkedRefund(refundData)
        ).pipe(
            map((x) => x.data),
            catchError((e) => {
                dispatch(notify(errorNotification('Error creating unlinked refund.', e)));
                return throwError(e);
            })
        );
    };

export const getSquareUnlinkedRefundDetails =
    (refundId: string): RefundThunkResult<SquareUnlinkedRefundDetailsDto> =>
    (dispatch, getState) => {
        return defer(() =>
            paymentServiceClient(dispatch, getState).squareRefundController.getSquareUnlinkedRefund(refundId)
        ).pipe(
            map((x) => x.data),
            catchError((e) => {
                dispatch(notify(errorNotification('Error fetching unlinked refund details.', e)));
                return throwError(e);
            })
        );
    };

export const pollForUnlikedRefundCompletion =
    (refundId?: string): RefundThunkResult<SquareUnlinkedRefundDetailsDto> =>
    (dispatch, getState) => {
        if (!refundId) {
            return EMPTY;
        }
        const pollingObservable = defer(() =>
            paymentServiceClient(dispatch, getState).squareRefundController.getSquareUnlinkedRefund(refundId)
        );
        const INTERVAL = 3000;
        const MINUTE_MULTIPLIER = 60000 / INTERVAL;
        const TIMER = 5 * MINUTE_MULTIPLIER * INTERVAL + INTERVAL; // 5 minutes timer + 1 addition interval to do a final check

        return interval(INTERVAL).pipe(
            switchMap(() => pollingObservable),
            map((x) => x.data),
            takeWhile((data) => data.status === 'PENDING', true),
            takeUntil(timer(TIMER)),
            catchError((e) => {
                dispatch(notify(errorNotification('Error confirming payment.', e)));
                return throwError(e);
            })
        );
    };

export const refundCardPayment =
    (paymentId: string, refundData: BeginRefundDto): RefundThunkResult<SquareTerminalTransactionDetailsDto> =>
    (dispatch, getState) => {
        return defer(() =>
            paymentServiceClient(dispatch, getState).squareRefundController.refundCardPayment(paymentId, refundData)
        ).pipe(
            map((x) => x.data),
            catchError((e) => {
                dispatch(notify(errorNotification('Error performing refund.', e)));
                return throwError(e);
            })
        );
    };

export const refundInteracPaymentViaTerminal =
    (paymentId: string, refundData: BeginRefundDto): RefundThunkResult<SquareTerminalTransactionDetailsDto> =>
    (dispatch, getState) => {
        return defer(() =>
            paymentServiceClient(dispatch, getState).squareRefundController.refundInteracPaymentViaTerminal(
                paymentId,
                refundData
            )
        ).pipe(
            map((x) => x.data),
            catchError((e) => {
                dispatch(notify(errorNotification('Error completing refund.', e)));
                return throwError(e);
            })
        );
    };

export const dismissInteracRefund =
    (paymentId: string, refundData: BeginRefundDto): RefundThunkResult<SquareTerminalTransactionDetailsDto> =>
    (dispatch, getState) => {
        if (!paymentId || !refundData.locationId) return EMPTY;
        return defer(() =>
            paymentServiceClient(dispatch, getState).squareRefundController.dismissInteracRefundTransaction(
                paymentId,
                refundData
            )
        ).pipe(
            map((x) => x.data),
            catchError((e) => {
                dispatch(notify(errorNotification('Error canceling refund.', e)));
                return throwError(e);
            })
        );
    };

export const pollForInPersonRefundCompletion =
    (paymentId?: string, expectedRefundsLength: number = 0): PaymentThunkResult<SquareTerminalTransactionDetailsDto> =>
    (dispatch, getState) => {
        if (!paymentId) {
            return EMPTY;
        }
        const pollingObservable = defer(() =>
            paymentServiceClient(dispatch, getState).squarePaymentController.getFullSquarePaymentDetailsByPaymentId(
                paymentId
            )
        );
        const INTERVAL = 3000;
        const MINUTE_MULTIPLIER = 60000 / INTERVAL;
        const TIMER = 5 * MINUTE_MULTIPLIER * INTERVAL + INTERVAL; // 5 minutes timer + 1 addition interval to do a final check

        return interval(INTERVAL).pipe(
            switchMap(() => pollingObservable),
            map((x) => x.data),
            takeWhile(
                (data) =>
                    (data.squarePaymentCheckoutDetailsDto?.chargeData?.refunds?.length ?? 0) < expectedRefundsLength,
                true
            ),
            takeUntil(timer(TIMER)),
            catchError((e) => {
                dispatch(notify(errorNotification('Error confirming refund.', e)));
                return throwError(e);
            })
        );
    };
