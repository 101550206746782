import LocalizedPrice from '@eq3/containers/retail/priceTags/components/LocalizedPrice';
import {ISubConfiguration} from '@eq3/containers/retail/priceTags/models/readyToShip';
import {CSSProperties} from '@material-ui/core/styles/withStyles';
import React from 'react';

interface IProps {
    subConfiguration: ISubConfiguration;
    styles?: {
        container?: CSSProperties;
    };
}
export default (props: IProps) => {
    const {subConfiguration: {price, name, skus}, styles} = props;
    return (
        <div style={styles && styles.container}>
            <LocalizedPrice price={price}/>

            <div style={{fontSize: '9px'}}>
                {name}
                <div style={{fontFamily: 'eq3-book'}}>
                    {skus.map(({sku}) => <div key={sku}>{sku}</div>)}
                </div>
            </div>
        </div>
    );
};
