import { Observable } from 'redux';
import { ThunkResult } from 'redux-thunk';
import { from } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { IShippingInventoryReduxSlice } from '.';
import actions from './actions';
import { OrdersActionType } from './actions';
import { IOrderQueryResponse, IShippingInventoryOrder, IShippingInventoryOrderListingQuery, IShippingInventoryOrderListingResponse, IShippingInventoryOrderQuery, IUpdateShippingInventoryOrderQuery } from './models/apiModels';

type OrdersThunkResult<T> = ThunkResult<Observable<T>, IShippingInventoryReduxSlice, OrdersActionType>;
const { setOrders, setOrderListing, updateOrderListing } = actions;

export const fetchShippingInventoryOrders = (locationId: string, query: IShippingInventoryOrderQuery): OrdersThunkResult<IOrderQueryResponse> => (dispatch, getState, api) => {
    return from(api<IOrderQueryResponse>(dispatch, getState, `/admin/shippingInventoryManagement/orders/${locationId}`, 'GET', undefined, {
        params: query,
    })).pipe(
        map((response) => response.data),
        tap((data) => dispatch(setOrders(data))),
    );
};

export const fetchShippingInventoryOrderListing = (query: IShippingInventoryOrderListingQuery): OrdersThunkResult<IShippingInventoryOrderListingResponse> => (dispatch, getState, api) => {
    return from(api<IShippingInventoryOrderListingResponse>(dispatch, getState, `/admin/shippingInventoryManagement/orders/${query.locationId}/listing`, 'GET', undefined, {
        params: query,
    })).pipe(
        map((response) => response.data),
        tap((data) => dispatch(setOrderListing(data))),
    );
};

export const updateDeliveryInventory = (locationId: string, query: IUpdateShippingInventoryOrderQuery): OrdersThunkResult<IShippingInventoryOrder> => (dispatch, getState, api) => {
    return from(api<IShippingInventoryOrder>(dispatch, getState, `/admin/shippingInventoryManagement/orders/${locationId}`, 'PUT', undefined, {
        params: query,
    })).pipe(
        map((response) => response.data),
        tap((data) => dispatch(updateOrderListing(data))),
    );
};
