import { StyleRules, WithStyles } from '@material-ui/core/styles';
import React from 'react';

import {connect} from 'react-redux';
import {compose} from 'redux';
import {slatsWithPricingSelector} from '../../../../../../reselect/priceTags';
import LocalizedPrice from '../../../components/LocalizedPrice';
import LocalizedString from '../../../components/LocalizedString';
import PriceInput from '../../../components/PriceInput';
import {formatLabel} from '../../../util/i18n';
import SlatsTable from './SlatsTable';

import { withStyles } from '@material-ui/core';

interface IProps {
    product: any;
}
class BedTag extends React.Component<IProps & WithStyles<ReturnType<typeof styles>> & ReturnType<typeof mapStateToProps>> {

    public render() {
        const {classes, product, locale, slats} = this.props;

        const localizedProduct = product[locale.id];
        return (
            <div className={classes.container}>
                <div className={classes.name}>
                    {localizedProduct.name}
                </div>
                <div
                    className={classes.dimensions}>{formatLabel('dimensions_fmt', locale.id, product.width, product.depth, product.height)}</div>
                <div className={classes.description}>
                    {localizedProduct.description}
                </div>
                <div className={classes.from}><LocalizedString labelId="bed_frame_from"/></div>
                <div className={classes.fromPrice}>
                    <LocalizedPrice price={localizedProduct.startingPrice}/></div>
                <div className={classes.asShown}>
                    <span className={classes.asShownInText}><LocalizedString labelId="as_shown"/></span>
                </div>
                <div className={classes.asShownPrice}>
                    <PriceInput className={classes.asShownPriceInput}/>
                </div>
                <div className={classes.addOns}>
                    <div className={classes.leadIn}>
                        {localizedProduct.priceTableLeadIn}
                    </div>
                    {localizedProduct.prices && !!localizedProduct.prices.length && (
                        <div className={classes.optionsTable}>
                            {localizedProduct.prices.map(({name, price}, i) => (
                                <React.Fragment key={i}>
                                    <div className={classes.optionName}>
                                        {name}
                                    </div>
                                    <div className={classes.optionPrice}>
                                        <LocalizedPrice price={price} />
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>
                    )}
                <SlatsTable locale={locale} slats={slats}/>
                </div>
                <div className={classes.sizing}>
                    <div className={classes.sizingTitle}>
                        <LocalizedString labelId="available_in"/>
                    </div>
                    <div className={classes.sizingValue}>
                        {localizedProduct.sizing}
                    </div>
                </div>
            </div>
        );
    }
}

const styles = () => ({
    container: {
        height: '100%',
        '& > div:not(:first-child)': {
            marginTop: '10px',
        },
        position: 'relative',
    },
    name: {
        fontSize: '15pt',
        fontFamily: 'eq3-heavy',
        lineHeight: '19pt',
    },
    dimensions: {
        marginTop: '20pt',
        fontSize: '8pt',
        letterSpacing: '0.4pt',
    },
    description: {
        fontSize: '8pt',
        fontFamily: 'eq3-book',
        lineHeight: '12pt',
    },
    from: {
        fontSize: '9pt',
    },
    fromPrice: {
        fontSize: '23pt',
    },
    asShownInText: {
        fontSize: '9pt',
    },
    asShownPriceInput: {
        width: '100%',
        borderWidth: '0px',
        height: '20pt',
        fontSize: '18pt',
    },
    leadIn: {
        fontSize: '9pt',
    },
    optionsTable: {
        display: 'grid',
        gridTemplateColumns: 'auto auto',
    },
    optionName: {
        fontFamily: 'eq3-book',
        fontSize: '8pt',
        lineHeight: '12pt',
    },
    optionPrice: {
        fontSize: '9pt',
        justifySelf: 'right',
    },
    sizingTitle: {
        fontFamily: 'eq3-heavy',
    },
}) as StyleRules;

const mapStateToProps = (state) => {
    return ({
        locale: state.priceTags.locale,
        slats: slatsWithPricingSelector(state),
    });
};

export default (compose(
    connect(mapStateToProps),
    withStyles(styles),
)(BedTag)) as any as React.ComponentType<IProps>;
