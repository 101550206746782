import { createReducer } from 'deox';
import { default as actions } from './actions';
import { IMyProfileReduxState } from './models';

const { setMyProfile } = actions;

const defaultState: IMyProfileReduxState = {
    userDetails: undefined,
};

export default createReducer(defaultState, (handle) => [
    handle(setMyProfile, (state, { payload: userDetails }) => ({
        ...state,
        userDetails,
    })),
]);
