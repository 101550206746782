import { Locale } from '@eq3/utils/locales';
import { defer, iif, of, throwError } from 'rxjs';
import { catchError, mergeMapTo, tap } from 'rxjs/operators';
import { errorNotification, notify, successNotification } from '../adminNotifications';
import { unwrap } from '../utils';
import actions from './actions';
import { ICategory, ICategoryListItem, IMenu, IProductLine, IProductLineListItem, ISubcategory, ISubcategoryListItem, IUpdateCategory, IUpdateProductLine, IUpdateSubcategory } from './models/api';
import { MenuThunkResult } from './models/redux';

export const fetchMenu = (refresh: boolean = false): MenuThunkResult<IMenu> => (dispatch, getState, api) => {
    const { siteNavigation: { categories } } = getState();

    return iif(
        () => refresh || !Object.keys(categories).length,
        defer(() => api<IMenu>(dispatch, getState, `/admin/menu`, 'GET')).pipe(
            unwrap,
            tap((menu) => dispatch(actions.setMenu(menu))),
            catchError((e) => {
                console.error(e);
                dispatch(notify(errorNotification('Unable to fetch menu', e)));

                return throwError(e);
            }),
        ),
    );
};

export const fetchCategory = (categoryId: string, refresh: boolean = false): MenuThunkResult<ICategory> => (dispatch, getState, api) => {
    const { siteNavigation: { cache } } = getState();
    const cachedCopy = cache.categories[categoryId];

    return iif(
        () => refresh || !cachedCopy,
        defer(() => api<ICategory>(dispatch, getState, `/admin/menu/categories/${categoryId}`)).pipe(
            unwrap,
            tap((category) => dispatch(actions.setCategory(category))),
            catchError((e) => {
                console.error(e);
                dispatch(notify(errorNotification('Error fetching category', e)));
    
                return throwError(e);
            }),
        ),
        of(cachedCopy),
    );
};

export const fetchSubcategory = (subcategoryId: string, refresh: boolean = false): MenuThunkResult<ISubcategory> => (dispatch, getState, api) => {
    const { siteNavigation: { cache } } = getState();
    const cachedCopy = cache.subcategories[subcategoryId];

    return iif(
        () => refresh || !cachedCopy,
        defer(() => api<ISubcategory>(dispatch, getState, `/admin/menu/subcategories/${subcategoryId}`)).pipe(
            unwrap,
            tap((subcategory) => dispatch(actions.setSubcategory(subcategory))),
            catchError((e) => {
                console.error(e);
                dispatch(notify(errorNotification('Error fetching subcategory', e)));
    
                return throwError(e);
            }),
        ),
        of(cachedCopy),
    );
};

export const fetchProductLine = (productLineId: string, refresh: boolean = false): MenuThunkResult<IProductLine> => (dispatch, getState, api) => {
    const { siteNavigation: { cache } } = getState();
    const cachedCopy = cache.productLines[productLineId];

    return iif(
        () => refresh || !cachedCopy,
        defer(() => api<IProductLine>(dispatch, getState, `/admin/menu/product-lines/${productLineId}`)).pipe(
            unwrap,
            tap((productLine) => dispatch(actions.setProductLine(productLine))),
            catchError((e) => {
                console.error(e);
                dispatch(notify(errorNotification('Error fetching product line', e)));
    
                return throwError(e);
            }),
        ),
        of(cachedCopy),
    );
};

export const sortCategories = (sortOrder: Record<string, number>): MenuThunkResult<ICategoryListItem[]> => (dispatch, getState, api) => {
    return defer(() => api<ICategoryListItem[]>(dispatch, getState, '/admin/menu/categories/sort', 'PUT', sortOrder)).pipe(
        unwrap,
        tap((sorted) => dispatch(actions.setCategoryOrder(sorted))),
        tap(() => dispatch(notify(successNotification('Categories sorted!')))),
        catchError((e) => {
            console.error(e);
            dispatch(notify(errorNotification('Unable to sort categories', e)));

            return throwError(e);
        }),
    );
};

export const saveCategory = (categoryId: string | 'add', values: IUpdateCategory): MenuThunkResult<ICategory> => (dispatch, getState, api) => {
    const formData = new FormData();
    
    formData.append('internalName', values.internalName);
    formData.append('translations', new Blob([JSON.stringify(values.translations)], { type: 'application/json' }));
    formData.append('taggedInstances', new Blob([JSON.stringify(values.taggedInstances)], { type: 'application/json' }));

    values.photography.forEach((p, i) => {
        if (p.imageId) formData.append(`photography[${i}].imageId`, p.imageId);
        formData.append(`photography[${i}].type`, p.type);

        Object.values(Locale).forEach((locale) => {
            formData.append(`photography[${i}].altImageText['${locale}']`, p.altImageText[locale] ?? '');
        });

        formData.append(`photography[${i}].seoFileName`, p.seoFileName);
        formData.append(`photography[${i}].dimension`, p.dimension);
        if (p.newImage) formData.append(`photography[${i}].newImage`, p.newImage);
    });
   
    return iif(
        () => categoryId === 'add',
        defer(() => api<ICategory>(dispatch, getState, `/admin/menu/categories`, 'POST', formData, {
            extraHeaders: {
                'Content-Type': 'multipart/form-data',
            },
        })),
        defer(() => api<ICategory>(dispatch, getState, `/admin/menu/categories/${categoryId}`, 'PUT', formData, {
            extraHeaders: {
                'Content-Type': 'multipart/form-data',
            },
        })),
    ).pipe(
        unwrap,
        tap((category) => dispatch(actions.setCategory(category))),
        tap(() => dispatch(fetchMenu(true))),
        tap(() => dispatch(notify(successNotification('Category has been updated!')))),
        catchError((e) => {
            console.error(e);
            dispatch(notify(errorNotification('Error updating category', e)));
            return throwError(e);
        }),
    );
};

export const saveSubcategory = (categoryId: string, subcategoryId: string, values: IUpdateSubcategory): MenuThunkResult<ISubcategory> => (dispatch, getState, api) => {
    const formData = new FormData();
    
    formData.append('categoryId', categoryId);
    formData.append('internalName', values.internalName);
    formData.append('translations', new Blob([JSON.stringify(values.translations)], { type: 'application/json' }));
    formData.append('taggedInstances', new Blob([JSON.stringify(values.taggedInstances)], { type: 'application/json' }));

    values.photography.forEach((p, i) => {
        if (p.imageId) formData.append(`photography[${i}].imageId`, p.imageId);
        formData.append(`photography[${i}].type`, p.type);

        Object.values(Locale).forEach((locale) => {
            formData.append(`photography[${i}].altImageText['${locale}']`, p.altImageText[locale] ?? '');
        });

        formData.append(`photography[${i}].seoFileName`, p.seoFileName);
        formData.append(`photography[${i}].dimension`, p.dimension);
        if (p.newImage) formData.append(`photography[${i}].newImage`, p.newImage);
    });
   
    return iif(
        () => subcategoryId === 'add',
        defer(() => api<ISubcategory>(dispatch, getState, `/admin/menu/subcategories`, 'POST', formData, {
            extraHeaders: {
                'Content-Type': 'multipart/form-data',
            },
        })),
        defer(() => api<ISubcategory>(dispatch, getState, `/admin/menu/subcategories/${subcategoryId}`, 'PUT', formData, {
            extraHeaders: {
                'Content-Type': 'multipart/form-data',
            },
        })),
    ).pipe(
        unwrap,
        tap((subcategory) => dispatch(actions.setSubcategory(subcategory))),
        tap(() => dispatch(notify(successNotification('Subcategory has been updated!')))),
        catchError((e) => {
            console.error(e);
            dispatch(notify(errorNotification('Error updating subcategory', e)));
            return throwError(e);
        }),
    );
};

export const saveProductLine = (subcategoryId: string, productLineId: string, values: IUpdateProductLine): MenuThunkResult<IProductLine> => (dispatch, getState, api) => {
    const formData = new FormData();

    const { translations, photography, taggedInstances, ...rest } = values;
    
    formData.append('subcategoryId', subcategoryId);
    formData.append('productLine', new Blob([JSON.stringify({
        ...rest,
        ...translations
    })], { type: 'application/json' }));

    formData.append('taggedInstances', new Blob([JSON.stringify(taggedInstances)], { type: 'application/json' }));

    photography.forEach((p, i) => {
        if (p.imageId) formData.append(`photography[${i}].imageId`, p.imageId);
        formData.append(`photography[${i}].type`, p.type);
        formData.append(`photography[${i}].seoFileName`, p.seoFileName);

        Object.values(Locale).forEach((locale) => {
            formData.append(`photography[${i}].altImageText['${locale}']`, p.altImageText[locale] ?? '');
        });

        formData.append(`photography[${i}].dimension`, p.dimension);
        if (p.newImage) formData.append(`photography[${i}].newImage`, p.newImage);
    });

    return iif(
        () => productLineId === 'add',
        defer(() => api<IProductLine>(dispatch, getState, `/admin/menu/product-lines`, 'POST', formData, {
            extraHeaders: {
                'Content-Type': 'multipart/form-data',
            },
        })),
        defer(() => api<IProductLine>(dispatch, getState, `/admin/menu/product-lines/${productLineId}`, 'PUT', formData, {
            extraHeaders: {
                'Content-Type': 'multipart/form-data',
            },
        })),
    ).pipe(
        unwrap,
        tap((productLine) => dispatch(actions.setProductLine(productLine))),
        tap(() => dispatch(notify(successNotification('Product line has been updated!')))),
        catchError((e) => {
            console.error(e);
            dispatch(notify(errorNotification('Error updating product line', e)));
            return throwError(e);
        }),
    );
};

export const sortSubcategories = (sortOrder: Record<string, number>): MenuThunkResult<ISubcategoryListItem[]> => (dispatch, getState, api) => {
    return defer(() => api<ISubcategoryListItem[]>(dispatch, getState, '/admin/menu/subcategories/sort', 'PUT', sortOrder)).pipe(
        unwrap,
        tap((sorted) => dispatch(actions.setSubcategoryOrder(sorted))),
        tap(() => dispatch(notify(successNotification('Subcategories sorted!')))),
        catchError((e) => {
            console.error(e);
            dispatch(notify(errorNotification('Unable to sort subcategories', e)));

            return throwError(e);
        }),
    );
};

export const sortProductLines = (sortOrder: Record<string, number>): MenuThunkResult<IProductLineListItem[]> => (dispatch, getState, api) => {
    return defer(() => api<IProductLineListItem[]>(dispatch, getState, '/admin/menu/product-lines/sort', 'PUT', sortOrder)).pipe(
        unwrap,
        tap((sorted) => dispatch(actions.setProductLineOrder(sorted))),
        tap(() => dispatch(notify(successNotification('Product lines sorted!')))),
        catchError((e) => {
            console.error(e);
            dispatch(notify(errorNotification('Unable to product lines', e)));

            return throwError(e);
        }),
    );
};

export const deleteCategory = (categoryId: string): MenuThunkResult<void> => (dispatch, getState, api) => {
    return defer(() => api<void>(dispatch, getState, `/admin/menu/categories/${categoryId}`, 'DELETE')).pipe(
        mergeMapTo(of(undefined)),
        tap(() => dispatch(actions.deleteCategory(categoryId))),
        tap(() => dispatch(notify(successNotification('Category has been deleted')))),
        catchError((e) => {
            console.error(e);
            dispatch(notify(errorNotification('Unable to delete category', e)));

            return throwError(e);
        }),
    );
};

export const deleteSubcategory = (subcategoryId: string): MenuThunkResult<void> => (dispatch, getState, api) => {
    return defer(() => api<void>(dispatch, getState, `/admin/menu/subcategories/${subcategoryId}`, 'DELETE')).pipe(
        mergeMapTo(of(undefined)),
        tap(() => dispatch(actions.deleteSubcategory(subcategoryId))),
        tap(() => dispatch(notify(successNotification('Subcategory has been deleted')))),
        catchError((e) => {
            console.error(e);
            dispatch(notify(errorNotification('Unable to delete subcategory', e)));

            return throwError(e);
        }),
    );
};

export const deleteProductLine = (productLineId: string): MenuThunkResult<void> => (dispatch, getState, api) => {
    return defer(() => api<void>(dispatch, getState, `/admin/menu/product-lines/${productLineId}`, 'DELETE')).pipe(
        mergeMapTo(of(undefined)),
        tap(() => dispatch(actions.deleteSubcategory(productLineId))),
        tap(() => dispatch(notify(successNotification('Product line has been deleted')))),
        catchError((e) => {
            console.error(e);
            dispatch(notify(errorNotification('Unable to delete product line', e)));

            return throwError(e);
        }),
    );
};
