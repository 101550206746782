import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const Chevron = (props: SvgIconProps) => {
    const { ...rest } = props;

    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" style={{transform: 'rotate(180deg)'}} {...rest}>
            <path fillRule="evenodd" clipRule="evenodd" d="M7.41 15L12 10.6734L16.59 15L18 13.668L12 8L6 13.668L7.41 15Z" />
        </SvgIcon>
    );
};

export default Chevron;
