import { apiThunk as ApiThunk } from '@eq3/redux/store';
import { handleError } from '@eq3/utils/exceptionUtils';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { errorNotification, notify, successNotification } from '../adminNotifications';
import { ActionType, setCurrentOrderReplacements, setOrderReplacementReasons } from './actions';
import {
    ICreateReplacementOrder,
    IOrderReplacementsState,
    IProductSearchQuery,
    IProductSearchSuggestion,
    IReplacementOrder,
    IReplacementReasons
} from './models';
import { Pagination } from '@eq3/utils';
import { IProductDefinitionLookup } from '@eq3/redux/productConfiguration/models/IProductLookup';
import { from, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { unwrap } from '@eq3/redux/utils';

type ThunkResult<T> = ThunkAction<Promise<T>, IOrderReplacementsState, ApiThunk, ActionType>;
type RxThunkResult<T> = ThunkAction<Observable<T>, IOrderReplacementsState, ApiThunk, ActionType>;

export type OrderReplacementsDispatch = ThunkDispatch<IOrderReplacementsState, ApiThunk, ActionType>;

export const fetchReplacementsForOrder = (originalOrderId: string): ThunkResult<void> => async (dispatch, getState, api) => {
    try {
        const { data }: { data: IReplacementOrder[] } = await api(dispatch, getState, `/admin/orders/${originalOrderId}/replacements`, 'GET');
        dispatch(setCurrentOrderReplacements(data));
    } catch (e) {
        handleError(e, dispatch, `Error fetching replacements for order ID: ${originalOrderId}`);
    }
};

export const createReplacementOrder = (originalOrderId: string, replacement: ICreateReplacementOrder): ThunkResult<void> => async (dispatch, getState, api) => {
    try {
        await api(dispatch, getState, `/admin/orders/${originalOrderId}/replacements`, 'POST', replacement);
        dispatch(notify(successNotification('Replacement successfully added!')));
        dispatch(fetchReplacementsForOrder(originalOrderId));
    } catch (e) {
        handleError(e, dispatch, `Error adding new replacement for order ID: ${originalOrderId}`);
    }
};

export const getComponentProductSearchSuggestions = (searchTerm: string): RxThunkResult<IProductSearchSuggestion[]> => (
    dispatch,
    getStore,
    api,
) => {
    return from(api<IProductSearchSuggestion[]>(dispatch, getStore, `/admin/lookup/componentProducts/search/suggestions?searchTerm=${searchTerm}`, 'GET'))
        .pipe(
            map((response) => response.data),
            catchError((err) => {
                dispatch(notify(errorNotification('Error fetching component product search suggestions.', err)));
                throw err;
            }),
        );
};

export const searchComponentProducts = (query: Partial<IProductSearchQuery>): RxThunkResult<Pagination<IProductDefinitionLookup>> => (
    dispatch,
    getStore,
    api,
) => {
    return from(api<Pagination<IProductDefinitionLookup>>(dispatch, getStore, `/admin/lookup/componentProducts/search/`, 'GET', null, {params: query}))
        .pipe(
            unwrap,
            catchError((err) => {
                dispatch(notify(errorNotification('Failed to retrieve component products.', err)));
                throw err;
            }),
        );
};

export const fetchReplacementsReasons = (): ThunkResult<void> => async (dispatch, getState, api) => {
    const { orderReplacements: { replacementReasons } } = getState();
    
    // Caching in Redux, the replacement reasons don't change often
    // since the replacement reasons are changed directly in the DB, the admin can just refresh the page to get new list
    if (Object.keys(replacementReasons).length > 0) {
        return;
    }
    
    try {
        const { data }: { data: IReplacementReasons } = await api(dispatch, getState, `/admin/orders/replacementReasons`, 'GET');
        dispatch(setOrderReplacementReasons(data));
    } catch (e) {
        handleError(e, dispatch, `Error fetching replacement reasons`);
    }
};
