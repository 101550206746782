import { useFindLeadTimeEstimate } from '@eq3/redux/leadTimeManagement/hooks';
import {
    ILeadTimeEstimate,
    LeadTimeEstimateTimeUnit,
    LeadTimeEstimateType,
} from '@eq3/redux/leadTimeManagement/models';
import { IShippingEstimatesInfo } from './ShippingEstimatesInfo';
import { Locale } from '@eq3/utils/locales';

interface ITimeRangeInDays {
    fromTime: number;
    toTime: number;
    timeUnit: LeadTimeEstimateTimeUnit;
}

const emptyTimeRange: ITimeRangeInDays = {
    fromTime: 0,
    toTime: 0,
    timeUnit: LeadTimeEstimateTimeUnit.BUSINESS_DAYS,
};

const convertLeadTimeToTimeRangeInDays = (leadTime: ILeadTimeEstimate): ITimeRangeInDays => {
    return {
        fromTime: leadTime.timeUnit === LeadTimeEstimateTimeUnit.WEEKS ? leadTime.from * 7 : leadTime.from,
        toTime: leadTime.timeUnit === LeadTimeEstimateTimeUnit.WEEKS ? leadTime.to * 7 : leadTime.to,
        timeUnit: LeadTimeEstimateTimeUnit.BUSINESS_DAYS,
    };
};

const addTimeRangesInDays = (range1: ITimeRangeInDays, range2: ITimeRangeInDays) => {
    return {
        fromTime: range1.fromTime + range2.fromTime,
        toTime: range1.toTime + range2.toTime,
        timeUnit: LeadTimeEstimateTimeUnit.BUSINESS_DAYS,
    };
};

export const calculateLeadTime = (props: { shippingEstimatesInfo?: IShippingEstimatesInfo; locale: Locale }) => {
    const { shippingEstimatesInfo, locale } = props;
    if (!shippingEstimatesInfo) return null;

    const { requiresCustomMadeManufacturing, isParcelShipping, isFreightShipping, isAllDropShip } =
        shippingEstimatesInfo;

    const findLeadTimeEstimate = useFindLeadTimeEstimate();

    const countryCode = locale.substring(0, 2) as 'CA' | 'US';
    const manufacturing = findLeadTimeEstimate(LeadTimeEstimateType.CUSTOM_UPHOLSTERY_MANUFACTURING, countryCode);
    const parcel = findLeadTimeEstimate(LeadTimeEstimateType.PARCEL_SHIPPING, countryCode);
    const freight = findLeadTimeEstimate(LeadTimeEstimateType.FREIGHT_SHIPPING, countryCode);

    const totalManufacturingTime =
        requiresCustomMadeManufacturing && manufacturing
            ? convertLeadTimeToTimeRangeInDays(manufacturing)
            : emptyTimeRange;
    const convertedParcelShippingTime =
        isParcelShipping && parcel ? convertLeadTimeToTimeRangeInDays(parcel) : emptyTimeRange;
    const convertedFrightShippingTime =
        (isFreightShipping || !isParcelShipping) && freight
            ? convertLeadTimeToTimeRangeInDays(freight)
            : emptyTimeRange;
    const totalTimeRange = addTimeRangesInDays(
        totalManufacturingTime,
        addTimeRangesInDays(convertedFrightShippingTime, convertedParcelShippingTime)
    );

    let displayFromTime = totalTimeRange.fromTime;
    let displayToTime = totalTimeRange.toTime;
    let displayUnit = LeadTimeEstimateTimeUnit.BUSINESS_DAYS;

    if (totalTimeRange.fromTime % 7 === 0 && totalTimeRange.toTime % 7 === 0) {
        displayToTime = totalTimeRange.toTime / 7;
        displayFromTime = totalTimeRange.fromTime / 7;
        displayUnit = LeadTimeEstimateTimeUnit.WEEKS;
    } else if (totalTimeRange.fromTime > 20) {
        displayToTime = Math.ceil(totalTimeRange.toTime / 7);
        displayFromTime = Math.ceil(totalTimeRange.fromTime / 7);
        displayUnit = LeadTimeEstimateTimeUnit.WEEKS;
        if (displayFromTime === displayToTime) {
            displayToTime += 1;
        }
    }

    if (isAllDropShip || (!displayToTime && !displayFromTime)) return emptyTimeRange;

    return {
        fromTime: displayFromTime,
        toTime: displayToTime,
        timeUnit: displayUnit,
    };
};
