import MomentUtils from '@date-io/moment';
import Authorized from '@eq3/component/auth/Authorized';
import PrivateRoute from '@eq3/component/auth/PrivateRoute';
import LoadingFrame from '@eq3/component/LoadingFrame';
import SseNotifier from '@eq3/component/SseNotifier';
import * as Sentry from '@sentry/react';
import PrintInvoiceSlip from '@eq3/containers/orders/PrintInvoiceSlip';
import { StripeTerminalContextProvider } from '@eq3/containers/pos/context/StripeTerminalContext';
import PrintPosDailyReport from '@eq3/containers/pos/reports/print/PrintPosdailyReport';
import PrintSquarePosDailyReport from '@eq3/containers/pos-square/reports/print/PrintPosdailyReport';
import TransactionReceiptDetailsFrame
    from '@eq3/containers/pos/transactions/transactionDetails/receipt/TransactionReceiptDetailsFrame';
import SquareTransactionReceiptDetailsFrame
    from '@eq3/containers/pos-square/transactions/transactionDetails/receipt/TransactionReceiptDetailsFrame';
import UnlinkedRefundReceiptFrame
    from '@eq3/containers/pos/unlinkedRefunds/unlinkedRefundDetails/receipt/UnlinkedRefundReceiptFrame';
    import SquareUnlinkedRefundReceiptFrame
    from '@eq3/containers/pos-square/unlinkedRefunds/unlinkedRefundDetails/receipt/UnlinkedRefundReceiptFrame';
import { AuthPermissions } from '@eq3/redux/auth/models/apiModels';
import useCachedLazyLoader from '@eq3/utils/routeLazyLoader';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import '@stripe/terminal-js';
import React, { Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Chrome from './component/Chrome';
import Error from './component/Error';
import ErrorGeneralDialog from './component/ErrorGeneralDialog';
import MyCurrentLocationSelectorDialog from './containers/myProfile/MyCurrentLocationSelectorDialog';
import NotFound from './containers/NotFound';
import { useLoggedInUser } from './hooks';
import { SquareTerminalContextProvider } from '@eq3/containers/pos-square/context/SquareTerminalContext';

const App = () => {

    const { load } = useCachedLazyLoader();
    const currentUser = useLoggedInUser();

    const HomeComponent = load('HomeComponent', () => import('@eq3/containers/home/HomeFrame'));
    const DashboardComponent = load('DashboardComponent', () => import('@eq3/containers/dashboard/DashboardFrame'));
    const PosComponent = load('PosComponent', () => import('@eq3/containers/pos/PosFrame'));
    const PosComponentSquare = load('PosComponentSquare', () => import('@eq3/containers/pos-square/PosFrame'));
    const OrdersComponent = load('OrdersComponent', () => import('@eq3/containers/orders/OrdersFrame'));
    const CustomersComponent = load('CustomersComponent', () => import('@eq3/containers/customers/CustomersFrame'));
    const FinanceComponent = load('FinanceComponent', () => import('@eq3/containers/finance/FinanceFrame'));
    const MarketingComponent = load('MarketingComponent', () => import('@eq3/containers/marketing/MarketingFrame'));
    const InventoryComponent = load('InventoryComponent', () => import('@eq3/containers/inventory/InventoryFrame'));
    const PurchaseOrderComponent = load('PurchaseOrderComponent', () => import('@eq3/containers/purchaseOrders/PurchaseOrderFrame'));
    const WarehouseComponent = load('WarehouseComponent', () => import('@eq3/containers/warehouse/WarehouseFrame'));
    const RetailComponent = load('RetailComponent', () => import('@eq3/containers/retail/RetailFrame'));
    const EcomComponent = load('EcomComponent', () => import('@eq3/containers/ecommerce/EcommerceAdminFrame'));
    const ReportingComponent = load('ReportingComponent', () => import('@eq3/containers/reporting/ReportingFrame'));
    const SystemComponent = load('SystemComponent', () => import('@eq3/containers/system/SystemFrame'));

    Sentry.setUser({
        id: currentUser?.user.sub,
        email: currentUser?.user.email,
        username: currentUser?.user.email
    });

    return (
        <Error>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <ErrorGeneralDialog />
                <BrowserRouter>
                    <Authorized>
                        <StripeTerminalContextProvider>
                            <SquareTerminalContextProvider>
                                <SseNotifier>
                                    <Switch>
                                        {/* @square-migration STRIPE */}
                                        <PrivateRoute
                                            exact
                                            path="/orders/printInvoiceSlip"
                                            component={PrintInvoiceSlip}
                                            requiredPermissions={[AuthPermissions.READ_ORDERS]} />
                                        <PrivateRoute
                                            exact
                                            path="/pos/transactions/:paymentIntentId/receipt"
                                            component={TransactionReceiptDetailsFrame}
                                            requiredPermissions={[AuthPermissions.READ_POS_TRANSACTIONS]} />
                                        <PrivateRoute
                                            exact
                                            path="/pos/unlinked-refunds/:refundId/receipt"
                                            component={UnlinkedRefundReceiptFrame}
                                            requiredPermissions={[AuthPermissions.READ_POS_TRANSACTIONS]} />
                                        <PrivateRoute
                                            exact
                                            path="/pos/reporting/print"
                                            component={PrintPosDailyReport}
                                            requiredPermissions={[AuthPermissions.READ_POS_TRANSACTIONS]} />

                                        {/* @square-migration SQUARE */}
                                        <PrivateRoute
                                            exact
                                            path="/pos-square/transactions/:paymentId/receipt"
                                            component={SquareTransactionReceiptDetailsFrame}
                                            requiredPermissions={[AuthPermissions.READ_POS_TRANSACTIONS]} />
                                        <PrivateRoute
                                            exact
                                            path="/pos-square/unlinked-refunds/:refundId/receipt"
                                            component={SquareUnlinkedRefundReceiptFrame}
                                            requiredPermissions={[AuthPermissions.READ_POS_TRANSACTIONS]} />
                                        <PrivateRoute
                                            exact
                                            path="/pos-square/reporting/print"
                                            component={PrintSquarePosDailyReport}
                                            requiredPermissions={[AuthPermissions.READ_POS_TRANSACTIONS]} />
                                        <Chrome>
                                            <Suspense fallback={(<LoadingFrame />)}>
                                                <Switch>
                                                    <Route
                                                        exact
                                                        path="/"
                                                        component={HomeComponent} />
                                                    <Route
                                                        path="/dashboard"
                                                        component={DashboardComponent} />
                                                    <Route
                                                        path="/pos"
                                                        component={PosComponent} />
                                                    <Route
                                                        path="/pos-square"
                                                        component={PosComponentSquare} />
                                                    <Route
                                                        path="/orders"
                                                        component={OrdersComponent} />
                                                    <Route
                                                        path="/customers"
                                                        component={CustomersComponent} />
                                                    <Route
                                                        path="/finance"
                                                        component={FinanceComponent} />
                                                    <Route
                                                        path="/marketing"
                                                        component={MarketingComponent} />
                                                    <Route
                                                        path="/inventory"
                                                        component={InventoryComponent} />
                                                    <Route
                                                        path="/purchase-orders"
                                                        component={PurchaseOrderComponent} />
                                                    <Route
                                                        path="/warehouse"
                                                        component={WarehouseComponent} />
                                                    <Route
                                                        path="/retail"
                                                        component={RetailComponent} />
                                                    <Route
                                                        path="/ecommerce-admin"
                                                        component={EcomComponent} />
                                                    <Route
                                                        path="/reporting"
                                                        component={ReportingComponent} />
                                                    <Route
                                                        path="/system"
                                                        component={SystemComponent} />

                                                    {/* Not found. */}
                                                    <Route component={NotFound} />
                                                </Switch>
                                            </Suspense>
                                        </Chrome>
                                    </Switch>
                                </SseNotifier>
                                <MyCurrentLocationSelectorDialog />
                            </SquareTerminalContextProvider>
                        </StripeTerminalContextProvider>
                    </Authorized>
                </BrowserRouter>
            </MuiPickersUtilsProvider>
        </Error>
    );
};

export default App;
