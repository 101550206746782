import { ILeadTimeEstimate, LeadTimeEstimateType } from '@eq3/redux/leadTimeManagement/models';
import { ILeadTimeManagementReduxSlice } from '@eq3/redux/leadTimeManagement/reducers';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

export const useLeadTimeEstimates = (): ILeadTimeEstimate[] => {
    return useSelector<ILeadTimeManagementReduxSlice, ILeadTimeEstimate[]>((state) => state.leadTimeManagement.leadTimeEstimates);
};

export const useFindLeadTimeEstimate = (estimates?: ILeadTimeEstimate[]) => {
    const leadTimeEstimates = estimates ?? useLeadTimeEstimates();

    return useCallback((type: LeadTimeEstimateType, countryCode: 'CA' | 'US' | undefined) =>
        leadTimeEstimates.find((e) =>
            e.type === type
                && (
                    !!e.countryCode
                    ? e.countryCode === countryCode
                    : true    
                ),
        ), []);
};
