import { StyleRules, WithStyles } from '@material-ui/core/styles';
import FileUpload from '@material-ui/icons/CloudUpload';
import React from 'react';

import {connect} from 'react-redux';
import {compose} from 'redux';
import LoadingButton from '../../../../../../component/LoadingButton';
import {updateCollectionTagData} from '../../../../../../redux/priceTags';
import {Tags} from '../../Tags';
import TagUploader from './TagUploader';

import { Button, List, MenuItem, Typography, withStyles } from '@material-ui/core';

interface IProps {
    collectionsTagData: any[];
    previewTagElement: (menu) => React.ReactNode;
    menuMapper: (selectedCollection, tagData) => any;
    tag: any;
}

class CollectionsTagUploader extends React.Component<IProps & WithStyles<ReturnType<typeof styles>>> {
    public state = {
        newCollectionsTagData: undefined,
        selectedCollectionId: undefined,
        uploadedCollectionFile: undefined,
        uploadedCollectionMenu: undefined,
        uploadedCollectionTagData: undefined,
        isSaving: false,
    };

    public componentDidMount() {
        this.setState({selectedCollectionId: this.props.collectionsTagData[0].id});
    }

    private handleCollectionTagFileLoaded = (tagData) => {
        this.setState({newCollectionsTagData: tagData});
    }

    private handleCollectionTagSelected = (id) => () => {
        this.setState({selectedCollectionId: id, uploadedCollectionFile: undefined, uploadedCollectionMenu: undefined});
    }

    private handleFileUpload = (e) => {
        const uploadedCollectionFile = e.currentTarget.files[0];
        const fileReader = new FileReader();
        fileReader.onloadend = (e) => {
            const tagData = JSON.parse(e.target.result);
            const collections = this.state.newCollectionsTagData || this.props.collectionsTagData;

            const selectedCollection = collections.find(({id}) => id === this.state.selectedCollectionId);
            this.setState({
                uploadedCollectionFile,
                uploadedCollectionMenu: this.props.menuMapper(selectedCollection, tagData),
                uploadedCollectionTagData: tagData,
            });
        };
        fileReader.readAsText(uploadedCollectionFile);
    }

    private uploadFile = async () => {
        const {selectedCollectionId, uploadedCollectionFile, uploadedCollectionTagData} = this.state;
        const {uploadFile, tag} = this.props;

        this.setState({isSaving: true});
        await uploadFile(tag, selectedCollectionId, uploadedCollectionFile, uploadedCollectionTagData);
        this.setState({isSaving: false});

    }

    public render() {
        const {classes, collectionsTagData, previewTagElement} = this.props;
        const {newCollectionsTagData, selectedCollectionId, uploadedCollectionFile, uploadedCollectionMenu, isSaving} = this.state;

        const collectionsTagDataToRender = newCollectionsTagData || collectionsTagData;

        return (
            <div className={classes.container}>
                <div className={classes.collections}>
                    <Typography variant="h5">Select Collection</Typography>
                    <div className={classes.selectCollection}>
                        <List component="nav">
                            {collectionsTagDataToRender.map(({nameEN, id}) => (
                                <MenuItem
                                    button
                                    selected={id === selectedCollectionId}
                                    key={id}
                                    onClick={this.handleCollectionTagSelected(id)}>
                                    {nameEN}
                                </MenuItem>
                            ))}
                        </List>
                    </div>
                    <TagUploader showPreview={false} tag={Tags.upholstery}
                                 onTagFileLoaded={this.handleCollectionTagFileLoaded}/>
                </div>
                <div className={classes.selectedCollection}>
                    <Typography variant="h5">Upload {selectedCollectionId}.json file</Typography>
                    {!uploadedCollectionFile && (
                        <div className={classes.uploadButton}>
                            <Button variant="contained" color="default" component="label">
                                <input type="file" accept="application/json,.json" style={{display: 'none'}}
                                    onChange={this.handleFileUpload}/>
                                <span>Upload File</span>
                                <FileUpload className={classes.rightIcon}/>
                            </Button>
                        </div>
                    )}
                    {uploadedCollectionFile && uploadedCollectionMenu && previewTagElement && (
                        <div>
                            {previewTagElement(uploadedCollectionMenu)}
                            <div>
                                <LoadingButton isLoading={isSaving} color="primary" variant="contained"
                                            onClick={this.uploadFile}>Save</LoadingButton>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const styles = () => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
    },
    collections: {
        marginRight: '10px',
        paddingRight: '10px',
        borderRight: '1px solid black',
        display: 'flex',
        flexDirection: 'column',
    },
    selectCollection: {
        minHeight: '400px',
        height: '100%',
        overflow: 'auto',
    },
    selectedCollection: {
        paddingLeft: '10px',
    },
    uploadButton: {
        display: 'flex',
        justifyContent: 'center',
    },
    rightIcon: {
        marginLeft: 8,
    },
}) as StyleRules;

const mapDispatchToProps = (dispatch) => ({
    uploadFile: (tag, collectionId, file, tagData) => dispatch(updateCollectionTagData(tag.id, collectionId, file, tagData)),
});

export default (compose(
    withStyles(styles),
    connect(undefined, mapDispatchToProps),
)(CollectionsTagUploader)) as any as React.ComponentType<IProps>;
