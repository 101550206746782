import upholsteryItems from '@eq3/containers/system/erp/upholsteryItemsAdministration/redux';
import { AuthDispatch } from '@eq3/redux/auth/actions';
import colours from '@eq3/redux/colours';
import orderComData from '@eq3/redux/comOrders';
import orderReplacements from '@eq3/redux/orderReplacements';
import orderShipments from '@eq3/redux/orderShipments';
import productConfiguration from '@eq3/redux/productConfiguration';
import upholsteryPriceCostJumps from '@eq3/redux/upholsteryPriceCostJumps';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { applyMiddleware, combineReducers, compose, createStore, Dispatch } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunkMiddleware from 'redux-thunk';
import { defer, EMPTY, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import adminNotifications from './adminNotifications';
import auth, { refreshUserCredentials } from './auth';
import customers from './customers';
import erpdata from './erpData';
import eventLog from './systemTools/reducers';
import finance from './finance';
import googleCategories from './googleCategory';
import homePage from './homepage';
import inventoryOrders from './inventoryOrders';
import { default as inventorySystem, default as supplyChain } from './inventorySystem';
import leadTimeManagement from './leadTimeManagement';
import legacyOrders from './legacyOrders';
import locations, { LOCATIONS_REDUX_KEY } from './locations';
import manufacturers from './manufacturers';
import marketing from './marketing';
import menu from './menu';
import myProfileReducer, { MY_PROFILE_REDUX_KEY } from './myProfile';
import orders from './orders/reducers';
import partnerItems from './partnerItems';
import pos from './pos';
import priceTags from './priceTags';
import productInstances from './productInstances';
import promotionGroups from './promotions/promotionGroups';
import prop65 from './prop65';
import quotes from './quotes/reducers';
import redirections from './redirections';
import reporting from './reporting';
import roleGroups from './roleGroups';
import serverSentEvents from './serverSentEvents';
import shippingOptions from './shippingOptions';
import shippingPartners from './shippingPartners';
import sitemap from './sitemap';
import siteNavigation, { SITE_NAVIGATION_REDUX_KEY } from './siteNavigation';
import tagging from './tagging';
import textBlocks from './textBlocksAdmin';
import upholstery from './upholstery';
import upholsteryGradeGroups from './upholsteryGradeGroups';
import upholsteryItemOptions from './upholsteryItemOptions/upholsteryItemOptions';
import upholsteryOptionGroups from './upholsteryOptionGroups';
import upholsteryPieceCodes from './upholsteryPieceCodes';
import utils from './utils';
import { ThunkResult } from 'redux-thunk';


const reducers = {
    auth,
    colours,
    customers,
    eventLog,
    finance,
    productConfiguration,
    productInstances,
    promotionGroups,
    reporting,
    orders,
    upholstery,
    utils,
    homePage,
    marketing,
    menu,
    orderComData,
    orderShipments,
    orderReplacements,
    adminNotifications,
    legacyOrders,
    [LOCATIONS_REDUX_KEY]: locations,
    priceTags,
    erpdata,
    prop65,
    googleCategories,
    upholsteryItemOptions,
    upholsteryOptionGroups,
    upholsteryGradeGroups,
    upholsteryPriceCostJumps,
    partnerItems,
    upholsteryPieceCodes,
    upholsteryItems,
    shippingPartners,
    sitemap,
    inventoryOrders,
    manufacturers,
    inventorySystem,
    tagging,
    textBlocks,
    redirections,
    serverSentEvents,
    supplyChain,
    shippingOptions,
    leadTimeManagement,
    roleGroups,
    pos,
    [MY_PROFILE_REDUX_KEY]: myProfileReducer,
    [SITE_NAVIGATION_REDUX_KEY]: siteNavigation,
    quotes,
};

const reducer = combineReducers(reducers);

export type apiThunk = typeof api;
export type ApiOptions = {
    extraHeaders?: { [key: string]: string },
    params?: object,
    paramsSerializer?: (params: any) => string,
    responseType?: AxiosRequestConfig['responseType'];
};

export const getAccessToken = (dispatch: AuthDispatch, getState): Observable<string> => {
    const state = typeof getState === 'function' ? getState() : getState;
    const loggedInUser = state.auth?.loggedInUser;

    if (!loggedInUser) {
        return EMPTY;
    }
    // If the token is expired, then refresh the token.
    // Else just return the access token that we already have.
    return loggedInUser.expiryTimeMs < Date.now()
        ? dispatch(refreshUserCredentials())
            .pipe(
                map((loggedInUser) => loggedInUser?.accessToken),
            )
        : of(loggedInUser?.accessToken ?? '');
};


export const api = async <Response, D extends Dispatch = Dispatch, State = any>(dispatch: D, getState: () => State, endpoint: string, method: 'POST' | 'GET' | 'DELETE' | 'PUT' | 'PATCH' = 'GET', body?: any, options: ApiOptions = {}): Promise<AxiosResponse<Response>> => {
    const accessToken = await getAccessToken(dispatch, getState).toPromise();

    const c = axios.create({
        method,
        baseURL: process.env.API_URL,
        data: body,
        params: options.params,
        paramsSerializer: options.paramsSerializer,
        responseType: options.responseType,
    });

    return c<Response>(endpoint, {
        headers: {
            ...(accessToken ? { Authorization: 'Bearer ' + accessToken } : undefined),
            ...options.extraHeaders,
        },
    });
};

export const store = createStore(
    reducer,
    compose(composeWithDevTools(applyMiddleware(thunkMiddleware.withExtraArgument(api)))),
);

export interface IApiRequest<T> {
    endpoint: Parameters<typeof api>[2];
    method: Parameters<typeof api>[3];
    body?: Parameters<typeof api>[4];
    options?: Parameters<typeof api>[5];
}

export function observableApi<T>({ endpoint, method, body, options }: IApiRequest<T>): ThunkResult<Observable<AxiosResponse<T>>> {
    return (dispatch, getState, api) => {
        return defer(() => api<T>(dispatch, getState, endpoint, method, body, options));
    };
}