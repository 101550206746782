import { Locale } from '@eq3/utils/locales';
import { IOrderable, IPaginatable } from '@eq3/utils/pagination';
import { createAction } from 'deox';
import {
    IChangeSet,
    IFilters,
    IGradeTree,
    IInvalidItems,
    IQuery,
    IUpholsteryItem,
    UpholsteryItemTab,
} from './models';
import { UpholsteryItemState } from './state';

const prefix = '[UPHOLSTERY_ITEMS]';
type FetchState = Pick<UpholsteryItemState, 'records' | 'query' | 'pagination' | 'order' | 'grades' | 'pricing' | 'costs' | 'items'>;

export const setTab = createAction(`${prefix} SET_TAB`, (resolve) => (tab: UpholsteryItemTab) => resolve(tab));
export const setFilters = createAction(`${prefix} SET_FILTERS`, (resolve) => (filters: IFilters) => resolve(filters));
export const toggleFilters = createAction(`${prefix} TOGGLE_FILTERS`, (resolve) => (toggle?: boolean) => resolve(toggle));
export const toggleBulkGrades = createAction(`${prefix} TOGGLE_BULK_GRADES`, (resolve) => (toggle?: boolean) => resolve(toggle));
export const setGradeTree = createAction(`${prefix} SET_GRADE_TREE`, (resolve) => (tree: IGradeTree) => resolve(tree));
export const setInvalidItems = createAction(`${prefix} SET_INVALID_ITEMS`, ((resolve) => (invalid: IInvalidItems) => resolve(invalid)));
export const setPagination = createAction(`${prefix} SET_PAGINATION`, (resolve) => (pagination: IPaginatable) => resolve(pagination));
export const setOrder = createAction(`${prefix} SET_ORDER`, (resolve) => (order: IOrderable) => resolve(order));
export const setQuery = createAction(`${prefix} SET_QUERY`, (resolve) => (query: Partial<IQuery>) => resolve(query));
export const setRecords = createAction(`${prefix} SET_RECORDS`, (resolve) => (records: IUpholsteryItem[]) => resolve(records));
export const setFetchState = createAction(`${prefix} SET_FETCH_STATE`, (resolve) => (fetchState: FetchState) => resolve(fetchState));
export const recordChangeSet = createAction(`${prefix} RECORD_CHANGE_SET`, (resolve) => (changeSet: IChangeSet) => resolve(changeSet));
export const toggleExpansionRow = createAction(`${prefix} TOGGLE_EXPANSION_ROW`, (resolve) => (itemId: string) => resolve(itemId));
export const toggleGradeCheckbox = createAction(`${prefix} TOGGLE_GRADE_CHECKBOX`, (resolve) => (payload: { itemId: string; code: string }) => resolve(payload));
export const updateChecklist = createAction(`${prefix} UPDATE_CHECKLIST`, (resovle) => (checklist: UpholsteryItemState['grades']['checklist']) => resovle(checklist));
export const updatePricing = createAction(`${prefix} UPDATE_PRICING`, (resolve) => (payload: { itemId: string, locale: Locale, value: number }) => resolve(payload));
export const updateCosts = createAction(`${prefix} UPDATE_COSTS`, (resolve) => (payload: { itemId: string, locale: Locale, value: number }) => resolve(payload));
export const updateItems = createAction(`${prefix} UPDATE_ITEMS`, (resolve) => (payload: { itemId: string, value: Partial<Pick<IUpholsteryItem, 'activeStartDate' | 'activeEndDate' | 'disabled'>>}) => resolve(payload));
export const reset = createAction(`${prefix} RESET`);
export const clearChanges = createAction(`${prefix} CLEAR_CHANGES`);
export const setLoading = createAction(`${prefix} SET_LOADING`, (resolve) => (payload: boolean) => resolve(payload));

export type ActionTypes =
    | ReturnType<typeof setTab>
    | ReturnType<typeof setFilters>
    | ReturnType<typeof toggleFilters>
    | ReturnType<typeof toggleFilters>
    | ReturnType<typeof setGradeTree>
    | ReturnType<typeof setPagination>
    | ReturnType<typeof setOrder>
    | ReturnType<typeof setQuery>
    | ReturnType<typeof setRecords>
    | ReturnType<typeof setFetchState>
    | ReturnType<typeof recordChangeSet>
    | ReturnType<typeof toggleExpansionRow>
    | ReturnType<typeof toggleGradeCheckbox>
    | ReturnType<typeof updateChecklist>
    | ReturnType<typeof updatePricing>
    | ReturnType<typeof updateCosts>
    | ReturnType<typeof updateItems>
    | ReturnType<typeof reset>
    | ReturnType<typeof clearChanges>
    | ReturnType<typeof setLoading>;
