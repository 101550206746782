import { createReducer } from 'deox';
import { setGradeGroups } from './actions';
import { IUpholsteryGradeGroup } from './models';

export const initialState = {
    gradeGroups: new Array<IUpholsteryGradeGroup>(),
};

export type GradeGroupsState = typeof initialState;
export interface IReduxState { upholsteryGradeGroups: GradeGroupsState; }

export default createReducer(initialState, (handle) => [
    handle(setGradeGroups, (state, { payload }) => ({
        ...state,
        gradeGroups: payload,
    })),
]);
