import { IOrderable, IPaginatable } from '@eq3/utils';

export interface ICustomerDemographic {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    primaryPhoneNumber: string;
    createdDate: string;
}

export interface ISaveCustomerDemographic {
    firstName: string;
    lastName: string;
    email: string;
    primaryPhoneNumber: string;
}

export interface ICustomerAddress {
    id: string;
    firstName: string;
    lastName: string;
    streetAddress: string;
    unitNumber?: string;
    city: string;
    province: string;
    postalCode: string;
    country: string;
    phoneNumbers: string[];
    isNew?: boolean;
}

export interface ICustomerDetails {
    demographic: ICustomerDemographic;
    notes?: string;
    addresses: ICustomerAddress[];
}

export interface IUpdateCustomerDetails {
    demographic: ISaveCustomerDemographic;
    notes: string | undefined;
    addresses: ICustomerAddress[];
}

export interface ICustomerListItem {
    id: string;
    fullName: string;
    email: string;
    phoneNumber: string;
    addressOne: string;
    city: string;
    province: string;
    country: string;
}

export interface ICustomersQuery extends Partial<IPaginatable & Omit<IOrderable, 'orderBy'>> {
    searchTerm?: string;
    orderNumber?: string;
    customerName?: string;
    customerEmail?: string;
    phoneNumber?: string;
    orderBy?: CustomersOrderField;
    customerId?: string;
}

export enum CustomersOrderField {
    ID = 'ID',
    FIRST_NAME = 'FIRST_NAME',
    LAST_NAME = 'LAST_NAME',
    EMAIL = 'EMAIL',
    PHONE_NUMBER = 'PHONE_NUMBER',
}

export interface IAutoSuggestableFields {
    customerId: string;
    orderNumber: string;
    customerName: string;
    customerEmail: string;
    phoneNumber: string;
}

export const AutoSuggestableFields: Array<keyof IAutoSuggestableFields> = ['orderNumber', 'customerName', 'customerEmail', 'phoneNumber', 'customerId'];

export interface ICustomerSearchSuggestion {
    field: keyof IAutoSuggestableFields | 'everywhere';
    suggestions: string[];
}
