import { createActions, handleActions } from 'redux-actions';
import { ThunkResult } from 'redux-thunk';
import { IUpholsteryColour } from '.';
import { errorNotification, notify } from '../adminNotifications';
import { defer, from, iif, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

const { setColours } = createActions({
    SET_COLOURS: (list) => list,
});

export default handleActions({
    [setColours]: (state, { payload: list }) => ({ ...state, list, loading: false }),
}, { loading: true, list: [] });

export const fetchAllColours = (forceRefresh: boolean = false): ThunkResult<Observable<IUpholsteryColour[]>> => (
    dispatch,
    getStore,
    api
) => {
    const { colours: { list: coloursList } } = getStore();

    return iif(
    () => coloursList?.length && !forceRefresh,
        defer(() => of<IUpholsteryColour[]>(coloursList)),
        defer(() => from(api<IUpholsteryColour[]>(dispatch, getStore, `/admin/lookup/colours/all`, 'GET')))
            .pipe(
                map((response) => response.data),
                tap((colours) => dispatch(setColours(colours))),
                catchError((err) => {
                    dispatch(notify(errorNotification('Error fetching colours', err)));
                    throw err;
                }),
            )
    );
};
