import {ITagSize, TagSizes} from '../pages/Tags';

export interface ILabels {
    // [ id: string ]: string;

    // Menus
    large: string;
    medium: string;
    small: string;
    rug_card: string;
    pillow_card: string;
    print: string;
    reload: string;
    search: string;
    no_results: string;

    template_large: string;
    template_medium: string;
    template_small: string;
    template_rug_card: string;
    template_pillow_card: string;

    upholstery: string;
    beds: string;
    upholstered_beds: string;
    customizable_products: string;
    rugs: string;
    fillable_shelf_talkers: string;
    custom_shelf_talker: string;
    sale_shelf_talkers: string;
    shelf_talkers: string;
    bedroom_casegoods: string;
    pillows: string;
    sectionals: string;
    readyToShip: string;
    lighting: string;

    // Common
    width_abbreviation: string;
    height_abbreviation: string;
    depth_abbreviation: string;
    dimensions_fmt: string;
    not_applicable: string;
    from: string;
    as_shown: string;
    price_input_placeholder: string;

    // Upholstery
    upholstery_fmt: string;
    fabric: string;
    leather: string;
    fabric_grade: string;
    leather_grade: string;
    as_shown_in: string;
    as_shown_upholstery_placeholder: string;
    made_in_can: string;
    grade10: string;
    grade20: string;
    grade30: string;
    grade40: string;
    grade50: string;
    grade60: string;
    grade70: string;
    grade41: string;
    grade42: string;
    gradeF: string;
    gradeI: string;
    gradeMM: string;
    grade100: string;
    grade200: string;
    grade300: string;
    grade400: string;
    grade500: string;
    grade600: string;
    grade700: string;
    grade800: string;
    gradeM: string;

    // Sectionals
    sectional_configuration: string;

    // Beds
    bed_frame_from: string;
    slat_options: string;
    available_in: string;

    // Customizable items
    custom_product: string;

    // Rug Cards
    custom_sizes_available: string;
    custom_sizes: string;
    per_square_foot: string;

    // pillows
    fiber: string;
    feather: string;

    upload: string;
    selectTag: string;
    saleTag: string;
    regularPrice: string;
    salePrice: string;
    sku: string;
    referenceNumber: string;
    prop65Accessories: string;
    prop65AccessoriesDescription: string;
    prop65Upholstery: string;
    prop65UpholsteryDescription: string;
    warning: string;
    prop65: string;
    prop65Generic: string;

    accessories: string;

    was: string;
    now: string;
}

export const i18n: { [id: string]: ILabels } = {
    en_CA: {

        // Menus
        large: 'Large Hang Tag',
        medium: 'Medium Hang Tag',
        small: 'Small Hang Tag',
        rug_card: 'Rug Card',
        pillow_card: 'Pillow Stickers',
        print: 'Print',
        reload: 'Reload',
        search: 'Search',
        no_results: 'No Results :(',

        template_large: '8.5 in × 14 in – Portrait',
        template_medium: '8.5 in × 11 in – Portrait',
        template_small: '8.5 in × 11 in – Landscape',
        template_rug_card: '8.5 in × 14 in – Landscape',
        template_pillow_card: '8.5 in × 11 in – Portrait',

        upholstery: 'Upholstery',
        beds: 'Beds',
        upholstered_beds: 'Upholstered Beds',
        customizable_products: 'Customizable items',
        rugs: 'Rugs',
        pillows: 'Pillows',
        sectionals: 'Sectionals',
        readyToShip: 'Ready to Ship',
        lighting: 'Lighting',

        fillable_shelf_talkers: 'Fillable Shelf Talkers',
        custom_shelf_talker: 'Custom Shelf Talker',
        sale_shelf_talkers: 'Sale Shelf Talkers',
        shelf_talkers: 'Shelf Talkers',
        bedroom_casegoods: 'Bedroom Casegoods',

        // Common
        width_abbreviation: 'w',
        height_abbreviation: 'h',
        depth_abbreviation: 'd',
        dimensions_fmt: '{1}w × {2}d × {3}h',
        not_applicable: 'N/A',
        from: 'From',
        as_shown: 'As shown',
        price_input_placeholder: 'price excluding $',

        // Upholstery
        upholstery_fmt: '{1} {2}',
        fabric: 'Fabric',
        leather: 'Leather',
        fabric_grade: 'Fabric Grade',
        leather_grade: 'Leather Grade',        
        as_shown_in: 'As shown in',
        as_shown_upholstery_placeholder: 'fabric/leather and leg',
        made_in_can: 'Made and Designed in Canada',
        grade10: 'Grade 10',
        grade20: 'Grade 20',
        grade30: 'Grade 30',
        grade40: 'Grade 40',
        grade50: 'Grade 50',
        grade60: 'Grade 60',
        grade70: 'Grade 70',
        grade41: 'Grade 41',
        grade42: 'Grade 42',
        gradeF: 'Grade F',
        gradeI: 'Grade I',
        gradeMM: 'Marimekko',
        grade100: 'Grade 100',
        grade200: 'Grade 200',
        grade300: 'Grade 300',
        grade400: 'Grade 400',
        grade500: 'Grade 500',
        grade600: 'Grade 600',
        grade700: 'Grade 700',
        grade800: 'Grade 800',
        gradeM: 'Grade M',

        // Sectionals
        sectional_configuration: '{1}-Piece Sectional',

        // Beds
        bed_frame_from: 'Bed frame from',
        slat_options: 'Slat Options',
        available_in: 'Available in ',

        // Customizable items
        custom_product: 'Customizable Product',

        // Rug Cards
        custom_sizes_available: 'Available in custom sizes – ',
        custom_sizes: 'Custom Sizes',
        per_square_foot: '/sq ft',

        fiber: 'Fiber',
        feather: 'Feather',
        upload: 'Upload',
        selectTag: 'Select Tag',
        saleTag: 'Sale Tag',
        regularPrice: 'Regular Price',
        salePrice: 'Sale Price',
        sku: 'SKU',
        referenceNumber: 'REF#',
        prop65Accessories: 'Prop 65 - Accessories',
        prop65AccessoriesDescription: 'Cancer and Reproductive harm  www.p65warnings.ca.gov',
        prop65Upholstery: 'Prop 65 - Upholstery',
        prop65UpholsteryDescription: 'This product can expose you to Saw Dust which is known to the State of Californa to cause cancer, and Di (2-ethylhexl) Phalate (DEHP) which is known to the State of California to cause birth defects or other reproductive harm. For more information go to www.p65warnings.ca.gov',
        warning: 'Warning',
        prop65: 'Proposition 65 - Warning',
        prop65Generic: 'Proposition 65 - Generic',
        accessories: 'Accessories',

        was: 'Was',
        now: 'Now',
    },
    fr_CA: {

        // Menus
        large: 'Grand format pendre marque',
        medium: 'Moyen format pendre marque',
        small: 'Petit format pendre marque',
        rug_card: 'Tapis',
        pillow_card: 'Oreiller',
        print: 'Imprimer',
        reload: 'Rafraîchir',
        search: 'Recherche',
        no_results: 'Aucun résultat :(',

        template_large: '8.5 po × 14 po – Portrait',
        template_medium: '8.5 po × 11 po – Portrait',
        template_small: '8.5 po × 11 po – Paysage',
        template_rug_card: '8.5 po × 14 po – Paysage',
        template_pillow_card: '8.5 in × 11 in – Portrait',

        upholstery: 'Meubles rembourrés',
        beds: 'Lits',
        upholstered_beds: 'Lits rembourrés',
        customizable_products: 'Articles personnalisables',
        rugs: 'Tapis',
        pillows: 'Oreiller',
        sectionals: 'Ensembles modulaires',
        fillable_shelf_talkers: 'Fillable Shelf Talkers',
        custom_shelf_talker: 'Custom Shelf Talker',
        sale_shelf_talkers: 'Sale Shelf Talker',
        shelf_talkers: 'Shelf Talkers',
        bedroom_casegoods: '??? Bedroom Casegoods',
        readyToShip: 'prêts à partir',
        lighting: 'Éclairage',

        // Common
        width_abbreviation: 'L',
        height_abbreviation: 'P',
        depth_abbreviation: 'H',
        dimensions_fmt: '{1}L × {2}P × {3}H',
        not_applicable: 'n/d',
        from: 'À partir de',
        as_shown: 'Comme montré',
        price_input_placeholder: 'Prix sans le $',

        // Upholstery
        upholstery_fmt: '{2} {1}',
        fabric: 'Tissu',        
        leather: 'Cuir',
        fabric_grade: 'Catégorie de Tissu',
        leather_grade: 'Catégorie de Cuir',        
        as_shown_in: 'Comme montré en',
        as_shown_upholstery_placeholder: 'Tissu/cuir et pattes',
        made_in_can: 'Conçu et fabriqué au Canada',
        grade10: 'Catégorie 10',
        grade20: 'Catégorie 20',
        grade30: 'Catégorie 30',
        grade40: 'Catégorie 40',
        grade50: 'Catégorie 50',
        grade60: 'Catégorie 60',
        grade70: 'Catégorie 70',
        grade41: 'Catégorie 41',
        grade42: 'Catégorie 42',
        gradeF: 'Catégorie F',
        gradeI: 'Catégorie I',
        gradeMM: 'Marimekko',
        grade100: 'Catégorie 100',
        grade200: 'Catégorie 200',
        grade300: 'Catégorie 300',
        grade400: 'Catégorie 400',
        grade500: 'Catégorie 500',
        grade600: 'Catégorie 600',
        grade700: 'Catégorie 700',
        grade800: 'Catégorie 800',
        gradeM: 'Catégorie M',

        // Sectionals
        sectional_configuration: 'ens. modulaire {1} pièces',

        // Beds
        bed_frame_from: 'Lit à partir de',
        slat_options: 'Options de lattes',
        available_in: 'Offert en ',

        // Customizable items
        custom_product: 'Article personnalisable',

        // Rug Cards
        custom_sizes_available: 'Dimensions sur mesure – ',
        custom_sizes: 'Sur mesure',
        per_square_foot: '/pi',

        fiber: 'Fibre',
        feather: 'Plume',
        upload: 'Télécharger',
        selectTag: 'Select Tag',
        saleTag: 'Étiquette de solde',
        regularPrice: 'Prix courant',
        salePrice: 'Prix soldé',
        sku: 'Code prod.',
        referenceNumber: 'N\u00B0 réf',
        prop65Accessories: 'Prop 65 - Accessories',
        prop65AccessoriesDescription: 'Cancer and Reproductive harm  www.p65warnings.ca.gov',
        prop65Upholstery: 'Prop 65 - Upholstery',
        prop65UpholsteryDescription: 'This product can expose you to Saw Dust which is known to the State of Californa to cause cancer, and Di (2-ethylhexl) Phalate (DEHP) which is known to the State of California to cause birth defects or other reproductive harm. For more information go to www.p65warnings.ca.gov',
        warning: 'Warning',
        prop65: 'Proposition 65 - Warning',
        prop65Generic: 'Proposition 65 - Generic',
        accessories: 'Accessoires',

        was: 'étaient',
        now: 'maintenant',
    },
    en_US: {

        // Menus
        large: 'Large Hang Tag',
        medium: 'Medium Hang Tag',
        small: 'Small Hang Tag',
        rug_card: 'Rug Card',
        pillow_card: 'Pillow Stickers',
        print: 'Print',
        reload: 'Reload',
        search: 'Search',
        no_results: 'No Results :(',

        template_large: '8.5 in × 14 in – Portrait',
        template_medium: '8.5 in × 11 in – Portrait',
        template_small: '8.5 in × 11 in – Landscape',
        template_rug_card: '8.5 in × 14 in – Landscape',
        template_pillow_card: '8.5 in × 14 in – Landscape',

        upholstery: 'Upholstery',
        beds: 'Beds',
        upholstered_beds: 'Upholstered Beds',
        customizable_products: 'Customizable items',
        rugs: 'Rugs',
        pillows: 'Pillows',
        sectionals: 'Sectionals',
        fillable_shelf_talkers: 'Fillable Shelf Talkers',
        custom_shelf_talker: 'Custom Shelf Talker',
        sale_shelf_talkers: 'Sale Shelf Talker',
        shelf_talkers: 'Shelf Talkers',
        bedroom_casegoods: 'Bedroom Casegoods',
        readyToShip: 'Ready to Ship',
        lighting: 'Lighting',

        // Common
        width_abbreviation: 'w',
        height_abbreviation: 'h',
        depth_abbreviation: 'd',
        dimensions_fmt: '{1}w × {2}d × {3}h',
        not_applicable: 'N/A',
        from: 'From',
        as_shown: 'As shown',
        price_input_placeholder: 'price excluding $',

        // Upholstery
        upholstery_fmt: '{1} {2}',
        fabric: 'Fabric',
        leather: 'Leather',
        fabric_grade: 'Fabric Grade',
        leather_grade: 'Leather Grade',          
        as_shown_in: 'As shown in',
        as_shown_upholstery_placeholder: 'fabric/leather and leg',
        made_in_can: 'Made and Designed in Canada',
        grade10: 'Grade 10',
        grade20: 'Grade 20',
        grade30: 'Grade 30',
        grade40: 'Grade 40',
        grade50: 'Grade 50',
        grade60: 'Grade 60',
        grade70: 'Grade 70',
        grade41: 'Grade 41',
        grade42: 'Grade 42',
        gradeF: 'Grade F',
        gradeI: 'Grade I',
        gradeMM: 'Marimekko',
        grade100: 'Grade 100',
        grade200: 'Grade 200',
        grade300: 'Grade 300',
        grade400: 'Grade 400',
        grade500: 'Grade 500',
        grade600: 'Grade 600',
        grade700: 'Grade 700',
        grade800: 'Grade 800',
        gradeM: 'Grade M',

        // Sectionals
        sectional_configuration: '{1}-Piece Sectional',

        // Beds
        bed_frame_from: 'Bed frame from',
        slat_options: 'Slat Options',
        available_in: 'Available in ',

        // Customizable items
        custom_product: 'Customizable Product',

        // Rug Cards
        custom_sizes_available: 'Available in custom sizes – ',
        custom_sizes: 'Custom Sizes',
        per_square_foot: '/sq ft',

        // pillows
        fiber: 'Fiber',
        feather: 'Feather',
        upload: 'Upload',
        selectTag: 'Select Tag',
        saleTag: 'Sale Tag',
        regularPrice: 'Regular Price',
        salePrice: 'Sale Price',
        sku: 'SKU',
        referenceNumber: 'REF#',
        prop65Accessories: 'Prop 65 - Accessories',
        prop65AccessoriesDescription: 'Cancer and Reproductive harm  www.p65warnings.ca.gov',
        prop65Upholstery: 'Prop 65 - Upholstery',
        prop65UpholsteryDescription: 'This product can expose you to Saw Dust which is known to the State of Californa to cause cancer, and Di (2-ethylhexl) Phalate (DEHP) which is known to the State of California to cause birth defects or other reproductive harm. For more information go to www.p65warnings.ca.gov',
        warning: 'Warning',
        prop65: 'Proposition 65 - Warning',
        prop65Generic: 'Proposition 65 - Generic',
        accessories: 'Accessories',

        was: 'Was',
        now: 'Now',
    },
};

export interface ILocale {
    id: string;
    name: string;
    isFrench: boolean;
    formatCurrency: (n: number, includeDollarSign?: boolean) => string;
    country: string;
    getAvailableTagSizes: () => ITagSize[];
}

const formatCurrency = (n: number, locale: string) => {
    if (Number.isNaN(n)) {
        return undefined;
    }
    const digits = Math.round(n) !== n ? 2 : 0;
    return n.toLocaleString(locale, { minimumFractionDigits: digits, maximumFractionDigits: digits });
};

export const Canada = 'canada';
export const US = 'us';

export const Locale: {[locale in keyof ILocalizedLabels]: ILocale} = {
    en_CA: {
        id: 'en_CA',
        name: 'English Canada',
        isFrench: false,
        formatCurrency: (value: number, includeDollarSign: boolean = true) => (includeDollarSign && '$' || '') + formatCurrency(value, 'en-US'),
        country: Canada,
        getAvailableTagSizes: () => Object.values(TagSizes).filter((tagSize) => tagSize !== TagSizes.prop65),
    },
    fr_CA: {
        id: 'fr_CA',
        name: 'French Canada',
        isFrench: true,
        formatCurrency: (value: number, includeDollarSign: boolean = true) => formatCurrency(value, 'fr-CA') + (includeDollarSign && '$' || ''),
        country: Canada,
        getAvailableTagSizes: () =>  Object.values(TagSizes).filter((tagSize) => tagSize !== TagSizes.prop65),
    },
    en_US: {
        id: 'en_US',
        name: 'USA',
        isFrench: false,
        formatCurrency: (value: number, includeDollarSign: boolean = true) => (includeDollarSign && '$' || '') + formatCurrency(value, 'en-US'),
        country: US,
        getAvailableTagSizes: () =>  Object.values(TagSizes),
    },
};

export interface ILocalizedLabels {
    en_CA: string;
    fr_CA: string;
    en_US: string;
}

export const formatLabel = (id: keyof ILabels, locale: keyof ILocalizedLabels | string, ...rest: any[]): string => {
    let result = i18n[locale][id];

    for (let i = 1; i <= rest.length; ++i) {
        result = result.replace(`{${i}}`, rest[i - 1]);
    }

    return result;
};
