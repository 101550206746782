import { makeStyles } from '@material-ui/core';
import React, {FC, forwardRef, memo, PropsWithChildren, RefObject} from 'react';
import { compose } from 'redux';
import Button, {IButtonProps} from './Button';

export type IIconButtonProps = Omit<IButtonProps , 'size' | 'iconStart' | 'iconEnd'>;

const useStyles = makeStyles((theme) => ({
    root: {
        height: theme.spacing(5.5),
        minWidth: 'unset',
        width: theme.spacing(5.5),
        padding: 0,
    },
    iconRoot: ({
        display: 'flex',
    })
}));


const IconButton = compose<FC<IIconButtonProps>>(
    memo,
    forwardRef,
)((props: PropsWithChildren<IIconButtonProps>, ref: RefObject<HTMLButtonElement>) => {
    const {children, ...remainingProps} = props;
    const classes = useStyles(props);

    return (
        <Button
            {...remainingProps}
            ref={ref}
            classes={{
                root: classes.root,
            }}>
            <div className={classes.iconRoot}>
                {children}
            </div>
        </Button>
    );

});

export default IconButton;
