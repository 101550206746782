import { createReducer } from 'deox';
import actions from './actions';
import { IMenuState } from './models/redux';

const defaultState: IMenuState = {
    categories: [],
    subcategories: [],
    productLines: [],
    cache: {
        categories: {},
        subcategories: {},
        productLines: {},
    },
};

export default createReducer(defaultState, (handle) => [
    handle(actions.setMenu, (state, { payload: menu }) => ({
        ...state,
        ...menu,
    })),

    handle(actions.setCategory, (state, { payload: category }) => {
        return ({
            ...state,
            cache: {
                ...state.cache,
                categories: {
                    ...state.cache.categories,
                    [category.id]: category,
                },
            },
        });
    }),

    handle(actions.setCategoryOrder, (state, { payload: sorted }) => ({
        ...state,
        categories: [
            ...state.categories.filter((c) => sorted.every((s) => s.id !== c.id)),
            ...sorted,
        ],
    })),

    handle(actions.setSubcategory, (state, { payload: subcategory }) => ({
        ...state,
        cache: {
            ...state.cache,
            subcategories: {
                ...state.cache.subcategories,
                [subcategory.id]: subcategory,
            },
        },
    })),

    handle(actions.setSubcategoryOrder, (state, { payload: sorted }) => ({
        ...state,
        subcategories: [
            ...state.subcategories.filter((sc) => sorted.every((s) => s.id !== sc.id)),
            ...sorted,
        ],
    })),

    handle(actions.setProductLine, (state, { payload: productLine }) => ({
        ...state,
        cache: {
            ...state.cache,
            productLines: {
                ...state.cache.productLines,
                [productLine.id]: productLine,
            },
        },
    })),

    handle(actions.setProductLineOrder, (state, { payload: sorted }) => ({
        ...state,
        productLines: [
            ...state.productLines.filter((pl) => sorted.every((s) => s.id !== pl.id)),
            ...sorted,
        ],
    })),

]);
