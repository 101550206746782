import { setProcessTypes, setTransactionSubtypes, setTransactionTypes } from '@eq3/redux/finance/actions';
import { createReducer } from 'deox';

export interface IFinanceState {
    types: {
        processTypes: string[];
        transactionTypes: string[],
        transactionSubtypes: string[],
    };
}

export interface IFinanceReduxSlice {
    finance: IFinanceState;
}

const initialState: IFinanceState = {
    types: {
        processTypes: [],
        transactionTypes: [],
        transactionSubtypes: [],
    }
};

export default createReducer(initialState, (handle) => [
    handle(setProcessTypes, (state, { payload: { processTypes } }) => {
        return ({
            ...state,
            types: {
                ...state.types,
                processTypes,
            },
        });
    }),
    handle(setTransactionTypes, (state, { payload: { transactionTypes } }) => {
        return ({
            ...state,
            types: {
                ...state.types,
                transactionTypes,
            },
        });
    }),
    handle(setTransactionSubtypes, (state, { payload: { transactionSubtypes } }) => {
        return ({
            ...state,
            types: {
                ...state.types,
                transactionSubtypes,
            },
        });
    }),
]);
