import { BeginPaymentDto, BeginPhoneInTransactionDto, TerminalTransactionDetailsDto } from '@eq3-aws/payments-client';
import { paymentServiceClient } from '@eq3/clients/paymentService/paymentServiceClient';
import { errorNotification, notify } from '@eq3/redux/adminNotifications';
import { ThunkResult } from 'redux-thunk';
import { Observable, defer, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

type PaymentThunkResult<T> = ThunkResult<Observable<T>>;

export const getPayment = (paymentIntentId: string): PaymentThunkResult<TerminalTransactionDetailsDto> => (dispatch, getState) => {
    return defer(() =>
        paymentServiceClient(dispatch, getState)
            .paymentController
            .getTransactionDetailsForPaymentIntent(paymentIntentId)
    ).pipe(
        map((x) => x.data),
        catchError((e) => {
            dispatch(notify(errorNotification('Error fetching payment.', e)));
            return throwError(e);
        }),
    );
};

/**
 * @deprecated Phasing this out. Payments will be made on quotes/orders which is a different endpoint and requires different data.
 */
export const beginPayment = (beginPayment: BeginPaymentDto): PaymentThunkResult<TerminalTransactionDetailsDto> => (dispatch, getState) => {
    return defer(() =>
        paymentServiceClient(dispatch, getState)
            .paymentController
            .createPaymentIntent(beginPayment)
    ).pipe(
        map((x) => x.data),
        catchError((e) => {
            dispatch(notify(errorNotification('Error creating payment.', e)));
            return throwError(e);
        }),
    );
};

/**
 * @deprecated Phasing this out. Payments will be made on quotes/orders which is a different endpoint and requires different data.
 */
export const completePayment = (paymentIntentId: string): PaymentThunkResult<TerminalTransactionDetailsDto> => (dispatch, getState) => {
    return defer(() =>
        paymentServiceClient(dispatch, getState)
            .paymentController
            .captureOrRecordLatestIntentState(paymentIntentId)
    ).pipe(
        map((x) => x.data),
        catchError((e) => {
            dispatch(notify(errorNotification('Error completing payment.', e)));
            return throwError(e);
        }),
    );
};

/**
 * @deprecated Phasing this out. Payments will be made on quotes/orders which is a different endpoint and requires different data.
 */
export const cancelPayment = (paymentIntentId: string): PaymentThunkResult<TerminalTransactionDetailsDto> => (dispatch, getState) => {
    return defer(() =>
        paymentServiceClient(dispatch, getState)
            .paymentController
            .cancelPayment(paymentIntentId)
    ).pipe(
        map((x) => x.data),
        catchError((e) => {
            dispatch(notify(errorNotification('Error cancelling payment.', e)));
            return throwError(e);
        }),
    );
};

export const processPhoneInPayment = (beginPhoneInTransaction: BeginPhoneInTransactionDto): PaymentThunkResult<TerminalTransactionDetailsDto> => (dispatch, getState) => {
    return defer(() =>
        paymentServiceClient(dispatch, getState)
            .paymentController
            .processPhoneInPayment(beginPhoneInTransaction)
    ).pipe(
        map((x) => x.data),
        catchError((e) => {
            dispatch(notify(errorNotification('Error processing virtual terminal payment.', e)));
            return throwError(e);
        }),
    );
};

export const syncPaymentIntent = (paymentIntentId: string): PaymentThunkResult<TerminalTransactionDetailsDto> => (dispatch, getState) => {
    return defer(() =>
        paymentServiceClient(dispatch, getState)
            .paymentController
            .recordLatestIntentState(paymentIntentId)
    ).pipe(
        map((x) => x.data),
        catchError((e) => {
            dispatch(notify(errorNotification('Error syncing payment intent.', e)));
            return throwError(e);
        }),
    );
};
