import { WithStyles } from '@material-ui/core/styles';
import React from 'react';

import {connect} from 'react-redux';
import {compose} from 'redux';
import LocalizedPrice from '../../../components/LocalizedPrice';
import LocalizedString from '../../../components/LocalizedString';
import {formatLabel} from '../../../util/i18n';
import AsShownInput from './AsShownInput';

import { createStyles, withStyles } from '@material-ui/core';

interface IProps {
    product: any;
}

class CustomizableProductTag extends React.Component<IProps & WithStyles<ReturnType<typeof styles>> & ReturnType<typeof mapStateToProps>> {

    public render() {
        const {product, classes, locale} = this.props;
        const localizedProduct = product[locale.id];

        const hasFromPrice = localizedProduct.fromPrice && localizedProduct.fromLeadIn;
        return (
            <div className={classes.container}>
                <div className={classes.name}>
                    {localizedProduct.name}
                </div>
                {hasFromPrice && (
                    <div
                        className={classes.dimensions}>{formatLabel('dimensions_fmt', locale.id, product.width, product.depth, product.height)}</div>
                )}
                <div className={classes.description}>
                    {localizedProduct.description}
                </div>
                {hasFromPrice && (
                    <div className={classes.from}>
                        <span className={classes.fromLabel}>{localizedProduct.fromLeadIn}</span>
                        <div className={classes.fromPrice}>
                            <LocalizedPrice price={localizedProduct.fromPrice}/>
                        </div>
                    </div>
                )}
                <AsShownInput locale={locale} isDescriptionConfigurable={false}/>
                {localizedProduct.priceTableItems && !!localizedProduct.priceTableItems.length && (
                    <div className={classes.prices}>
                        <div className={classes.priceTableLeadIn}>
                            {localizedProduct.priceTableLeadIn}
                        </div>
                        <div className={classes.priceTable}>
                            {localizedProduct.priceTableItems.map(({name, price}, i) => (
                                <React.Fragment key={i}>
                                    <div className={classes.priceTableItemName}>
                                        {name}
                                    </div>
                                    <div className={classes.priceTableItemPrice}>
                                        <LocalizedPrice price={price}/>
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>
                    </div>    
                )}
                <div className={classes.footer}>
                    <LocalizedString labelId={'custom_product'}/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    locale: state.priceTags.locale,
});

const styles = () => createStyles({
    container: {
        height: '100%',
        '& > div:not(:first-child)': {
            marginTop: '10px',
        },
        position: 'relative',
    },
    name: {
        fontSize: '15pt',
        lineHeight: '19pt',
        fontFamily: 'eq3-heavy',
    },
    dimensions: {
        fontSize: '8pt',
        letterSpacing: '0.4pt',
        marginTop: '5pt',
    },
    description: {
        fontSize: '8pt',
        fontFamily: 'eq3-book',
        lineHeight: '12pt',
    },
    fromLabel: {
        fontSize: '9pt',
    },
    fromPrice: {
        fontSize: '23pt',
        marginTop: '5pt',
    },
    prices: {
        marginTop: '20pt',
    },
    priceTableLeadIn: {
        fontSize: '9pt',
    },
    priceTable: {
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        fontSize: '8pt',
        lineHeight: '12pt',
    },
    priceTableItemName: {
        fontFamily: 'eq3-book',
        width: '100%',
        paddingRight: '5px',
    },
    priceTableItemPrice: {
        textAlign: 'right',
        width: '100%',
    },
    footer: {
        position: 'absolute',
        bottom: '15pt',
        fontSize: '10pt',
    },
});

export default (compose(connect(mapStateToProps), withStyles(styles))(CustomizableProductTag)) as any as React.ComponentType<IProps>;
