import {
    addBannerAction,
    addCarouselItemAction,
    addFeaturedCategoryAction,
    addHeroImageAction,
    addMustHaveAction,
    deleteBannerAction,
    deleteCarouselItemAction,
    deleteFeaturedCategoryAction,
    deleteHeroImageAction,
    deleteMustHaveAction,
    updateBannerAction,
    updateBannersListAction,
    updateCarouselItemAction,
    updateCarouselListAction,
    updateFeaturedCategoriesListAction,
    updateFeaturedCategoryAction,
    updateHeroImageAction,
    updateHeroListAction,
    updateMustHaveItemAction,
    updateMustHavesListAction,
    updateMetaDataListAction,
} from '@eq3/redux/homepage/actions';
import { IBanner, ICarouselItem, IFeaturedCategory, IHeroImage, IMetaData, IMustHave } from '@eq3/redux/homepage/models/apiModels';
import { IGrouping } from '@eq3/redux/homepage/models/viewModels';
import { createReducer } from 'deox';

export interface IHomePageState {
    banners: IGrouping<IBanner>;
    featuredCategories: IGrouping<IFeaturedCategory>;
    carouselItems: IGrouping<ICarouselItem>;
    heroImages: IGrouping<IHeroImage>;
    mustHaves: IGrouping<IMustHave>;
    metaData: IMetaData[];
}

export interface IHomePageReduxSlice {
    homePage: IHomePageState;
}

const initialState: IHomePageState = {
    banners: {},
    featuredCategories: {},
    carouselItems: {},
    heroImages: {},
    mustHaves: {},
    metaData: [],
};

export default createReducer(initialState, (handle) => [
    handle(addCarouselItemAction, (state, { payload: { locale, carouselItem } }) => {
        return ({
            ...state,
            carouselItems: {
                ...state.carouselItems,
                [locale]: [
                    ...state.carouselItems[locale],
                    { ...carouselItem },
                ],
            },
        });
    }),
    handle(updateCarouselItemAction, (state, { payload: { locale, carouselItem } }) => {
        const index = state.carouselItems[locale]?.findIndex(({ id }) => id === carouselItem.id);
        const carouselItems = [...state.carouselItems[locale]];

        if (index !== undefined && index >= 0) {
            carouselItems[index] = { ...carouselItem };
        }
        return ({
            ...state,
            carouselItems: {
                ...state.carouselItems,
                [locale]: [...carouselItems],
            },
        });
    }),
    handle(updateCarouselListAction, (state, { payload: { locale, carouselItems } }) => {
        return ({
            ...state,
            carouselItems: {
                ...state.carouselItems,
                [locale]: [...carouselItems],
            },
        });
    }),
    handle(deleteCarouselItemAction, (state, { payload: { locale, id } }) => {
        const newCarouselItems = [...state.carouselItems[locale]].filter((item) => item.id !== id);
        return ({
            ...state,
            carouselItems: {
                ...state.carouselItems,
                [locale]: [...newCarouselItems],
            },
        });
    }),
    handle(addHeroImageAction, (state, { payload: { locale, heroImage } }) => {
        return ({
            ...state,
            heroImages: {
                ...state.heroImages,
                [locale]: [
                    ...state.heroImages[locale],
                    { ...heroImage },
                ],
            },
        });
    }),
    handle(updateHeroImageAction, (state, { payload: { locale, heroImage } }) => {
        const index = state.heroImages[locale]?.findIndex(({ id }) => id === heroImage.id);
        const heroImages = [...state.heroImages[locale]];

        if (index !== undefined && index >= 0) {
            heroImages[index] = { ...heroImage };
        }
        return ({
            ...state,
            heroImages: {
                ...state.heroImages,
                [locale]: [...heroImages],
            },
        });
    }),
    handle(updateHeroListAction, (state, { payload: { locale, heroImages } }) => {
        return ({
            ...state,
            heroImages: {
                ...state.heroImages,
                [locale]: [...heroImages],
            },
        });
    }),
    handle(deleteHeroImageAction, (state, { payload: { locale, id } }) => {
        const newHeroImages = [...state.heroImages[locale]].filter((item) => item.id !== id);
        return ({
            ...state,
            heroImages: {
                ...state.heroImages,
                [locale]: [...newHeroImages],
            },
        });
    }),
    handle(addMustHaveAction, (state, { payload: { locale, mustHave } }) => {
        return ({
            ...state,
            mustHaves: {
                ...state.mustHaves,
                [locale]: [
                    ...state.mustHaves[locale],
                    { ...mustHave },
                ],
            },
        });
    }),
    handle(updateMustHaveItemAction, (state, { payload: { locale, mustHave } }) => {
        const index = state.mustHaves[locale]?.findIndex((mh) => mh.id === mustHave.id);
        const mustHaves = [...state.mustHaves[locale]];

        if (index !== undefined && index >= 0) {
            mustHaves[index] = { ...mustHave };
        }
        return ({
            ...state,
            mustHaves: {
                ...state.mustHaves,
                [locale]: [...mustHaves],
            },
        });
    }),
    handle(updateMustHavesListAction, (state, { payload: { locale, mustHaves } }) => {
        return ({
            ...state,
            mustHaves: {
                ...state.mustHaves,
                [locale]: [...mustHaves],
            },
        });
    }),
    handle(deleteMustHaveAction, (state, { payload: { locale, id } }) => {
        const newMustHaves = [...state.mustHaves[locale]].filter((item) => item.id !== id);
        return ({
            ...state,
            mustHaves: {
                ...state.mustHaves,
                [locale]: newMustHaves,
            },
        });
    }),
    handle(addFeaturedCategoryAction, (state, { payload: { locale, featuredCategory } }) => {
        return ({
            ...state,
            featuredCategories: {
                ...state.featuredCategories,
                [locale]: [
                    ...state.featuredCategories[locale],
                    { ...featuredCategory },
                ],
            },
        });
    }),
    handle(updateFeaturedCategoryAction, (state, { payload: { locale, featuredCategory } }) => {
        const index = state.featuredCategories[locale]?.findIndex((item) => item.id === featuredCategory.id);
        const featuredCategories = [...state.featuredCategories[locale]];

        if (index !== undefined && index >= 0) {
            featuredCategories[index] = { ...featuredCategory };
        }
        return ({
            ...state,
            featuredCategories: {
                ...state.featuredCategories,
                [locale]: [...featuredCategories],
            },
        });
    }),
    handle(updateFeaturedCategoriesListAction, (state, { payload: { locale, featuredCategories } }) => {
        return ({
            ...state,
            featuredCategories: {
                ...state.featuredCategories,
                [locale]: [...featuredCategories],
            },
        });
    }),
    handle(deleteFeaturedCategoryAction, (state, { payload: { locale, id } }) => {
        const newFeaturedCategories = [...state.featuredCategories[locale]].filter((item) => item.id !== id);
        return ({
            ...state,
            featuredCategories: {
                ...state.featuredCategories,
                [locale]: [...newFeaturedCategories],
            },
        });
    }),
    handle(addBannerAction, (state, { payload: { locale, banner } }) => {
        // NOTE: Banner's are inserted at the beginning of the list.
        return ({
            ...state,
            banners: {
                ...state.banners,
                [locale]: [
                    // New banners are prepended.
                    { ...banner },
                    ...state.banners[locale],
                ],
            },
        });
    }),
    handle(updateBannerAction, (state, { payload: { locale, banner } }) => {
        const index = state.banners[locale]?.findIndex((item) => item.id === banner.id);
        const banners = [...state.banners[locale]];

        if (index !== undefined && index >= 0) {
            banners[index] = { ...banner };
        }
        return ({
            ...state,
            banners: {
                ...state.banners,
                [locale]: [...banners],
            },
        });
    }),
    handle(updateBannersListAction, (state, { payload: { locale, banners } }) => {
        return ({
            ...state,
            banners: {
                ...state.banners,
                [locale]: [...banners],
            },
        });
    }),
    handle(deleteBannerAction, (state, { payload: { locale, id } }) => {
        const newBannersList = [...state.banners[locale]].filter((item) => item.id !== id);
        return ({
            ...state,
            banners: {
                ...state.banners,
                [locale]: [...newBannersList],
            },
        });
    }),
    handle(updateMetaDataListAction, (state, { payload: { data } }) => {
        return ({
            ...state,
            metaData: data,
        });
    }),
]);
