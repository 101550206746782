import {
    Configuration,
    DynamiteApiFactory,
    PaymentControllerApiFactory,
    RefundControllerPaymentsApiFactory,
    ServerSentEventsControllerApiFactory,
    SquareGiftCardControllerApiFactory,
    SquarePaymentControllerApiFactory,
    SquareRefundControllerApiFactory,
    SquareTerminalLocationControllerApiFactory,
    TerminalLocationControllerPaymentsApiFactory,
    TransactionHistoryControllerPaymentsApiFactory
} from '@eq3-aws/payments-client';
import { PosDispatch } from '@eq3/redux/pos';
import { getAccessToken } from '@eq3/redux/store';

interface IPaymentServiceClient {
    paymentController: ReturnType<typeof PaymentControllerApiFactory>;
    refundController: ReturnType<typeof RefundControllerPaymentsApiFactory>;
    serverSentEventsController: ReturnType<typeof ServerSentEventsControllerApiFactory>;
    squareGiftCardController: ReturnType<typeof SquareGiftCardControllerApiFactory>;
    squarePaymentController: ReturnType<typeof SquarePaymentControllerApiFactory>;
    squareRefundController: ReturnType<typeof SquareRefundControllerApiFactory>;
    squareTerminalLocationController: ReturnType<typeof SquareTerminalLocationControllerApiFactory>;
    terminalLocationController: ReturnType<typeof TerminalLocationControllerPaymentsApiFactory>;
    transactionHistoryController: ReturnType<typeof TransactionHistoryControllerPaymentsApiFactory>;
    testController: ReturnType<typeof DynamiteApiFactory>;
}

let _paymentServiceClient: IPaymentServiceClient;

const clientConfiguration = (dispatch: PosDispatch, getState) => {
    return new Configuration({
        basePath: process.env.PAYMENT_SERVICE_API_BASE_URL,
        accessToken: getAccessToken(dispatch as any, getState).toPromise(),
    });
};

export const paymentServiceClient = (dispatch: PosDispatch, getState): IPaymentServiceClient => {
    if (!_paymentServiceClient) {
        const config = clientConfiguration(dispatch, getState);
        _paymentServiceClient = {
            paymentController: PaymentControllerApiFactory(config),
            refundController: RefundControllerPaymentsApiFactory(config),
            serverSentEventsController: ServerSentEventsControllerApiFactory(config),
            squareGiftCardController: SquareGiftCardControllerApiFactory(config),
            squarePaymentController: SquarePaymentControllerApiFactory(config),
            squareRefundController: SquareRefundControllerApiFactory(config),
            squareTerminalLocationController: SquareTerminalLocationControllerApiFactory(config),
            terminalLocationController: TerminalLocationControllerPaymentsApiFactory(config),
            transactionHistoryController: TransactionHistoryControllerPaymentsApiFactory(config),
            testController: DynamiteApiFactory(config)
        };
    }
    return _paymentServiceClient;
};
