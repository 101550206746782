export const getPrice = (price: string | number): IPrice => {

    if (price && typeof price === 'string') {
        const firstChar: string | null = price.charAt(0);

        if (firstChar === '+' || firstChar === '-') {
            return {value: price.slice(1), modifier: firstChar};
        }
    }

    return {value: price};
};

export interface IPrice {
    value: string | number;
    modifier?: string;
}
