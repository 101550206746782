import { FulfillmentStatus, IFulfillment } from '@eq3/redux/fulfillments/models';
import { OrderStatus } from '@eq3/redux/pos';
import { RetailInjectionStatus } from '@eq3/redux/purchaseOrders/models';

export enum SseEventType {
    REMOVE_FULFILLMENT = 'REMOVE_FULFILLMENT',
    UPDATE_FULFILLMENT_STATUS = 'UPDATE_FULFILLMENT_STATUS',
    UPDATE_FULFILLMENT_ITEM_STATUS = 'UPDATE_FULFILLMENT_ITEM_STATUS',
    FULFILLMENT_SPLIT = 'FULFILLMENT_SPLIT',
    PROCESSING_FAILED = 'PROCESSING_FAILED',
    UPDATE_ORDER_STATUS = 'UPDATE_ORDER_STATUS',
    UPDATE_INJECTION_STATUS = 'UPDATE_INJECTION_STATUS',
    UNHANDLED_JMS_EVENT = 'UNHANDLED_JMS_EVENT',
    KEEP_ALIVE = 'KEEP_ALIVE',
}

export interface ISseMessage<T> {
    eventType: SseEventType;
    createdTime: number;
    data: T;
}

export interface SseUpdateFulfillmentStatusMessage {
    fulfillmentId: string;
    fulfillmentStatus: FulfillmentStatus;
}

export interface SseUpdateFulfillmentItemStatusMessage {
    fulfillmentId: string;
    fulfillmentItemId: string;
    fulfillmentItemStatus: FulfillmentStatus;
    statusQuantity: number;
}

export interface SseFulfillmentSplitMessage {
    originalFulfillment: IFulfillment;
    newFulfillment: IFulfillment;
}

export interface SseUpdateOrderStatusMessage {
    orderId: string;
    orderStatus: OrderStatus;
}

export interface SseUpdateInjectionStatusMessage {
    injectionStatus: RetailInjectionStatus;
    orderId: string;
    poInjectionRequestId: string;
}