import {ClearButton} from '@eq3/containers/retail/priceTags/components/Buttons';
import { StyleRules, WithStyles } from '@material-ui/core/styles';
import React from 'react';
import {compose} from 'redux';
import ProductDroppable from '../../../components/ProductDroppable';
import SelectMenu from '../../../components/SelectMenu';
import {IMenuItem} from '../../../components/SelectMenuItem';
import {Tags} from '../../Tags';
import BedTag from './BedTag';
import CustomizableProductTag from './CustomizableProductTag';
import FillableSaleTag from './FillableSaleTag';
import SectionalTag from './SectionalTag';
import UpholsteredBedTag from './UpholsteredBedTag';
import UpholsteryTag from './UpholsteryTag';

import { withStyles } from '@material-ui/core';

interface IProps {
    menu: any;
    initialProduct?: any;
}

class LargePartialTag extends React.Component<IProps & WithStyles<ReturnType<typeof styles>>> {

    public state = {
        product: undefined,
    };
    constructor(props: IProps) {
        super(props);
        this.state = {
            product: props.initialProduct,
        };
    }
    private tagRenderers = {
        [Tags.upholstery.id]: (product) => (<UpholsteryTag product={product} collection={product.collection}/>),
        [Tags.sectionals.id]: (data) => (<SectionalTag data={data}/>),
        [Tags.beds.id]: (product) => (<BedTag product={product}/>),
        [Tags.upholsteredBeds.id]: (product) => (<UpholsteredBedTag product={product}/>),
        [Tags.customizableProducts.id]: (product) => (<CustomizableProductTag product={product}/>),
        [Tags.saleTag.id]: () => (<FillableSaleTag />),
        [Tags.readyToShip.id]: (product) => (Tags.readyToShip.tagRenderer(product)),
    };

    private onProductSelected = (menuItemPath: IMenuItem[]) => {
        if (menuItemPath[menuItemPath.length - 1].data && menuItemPath[menuItemPath.length - 1].data.tagTypeId) {
            const product = menuItemPath[menuItemPath.length - 1].data;
            this.handleProduct(product);
        }
    };

    private clearTag = () => {
        this.setState({product: undefined});
    };

    private handleProduct = (product) => {
        this.setState({product});
    }

    public render() {
        const {classes, menu} = this.props;
        const {product} = this.state;

        return (
            <ProductDroppable onProductDropped={this.handleProduct}>
                <div className={classes.tag}>
                    {!product
                    && (<SelectMenu itemTree={menu.itemTree} onLeafNodeSelected={this.onProductSelected}/>)
                    || (
                        <>
                            {this.tagRenderers[product.tagTypeId](product)}
                            <ClearButton className={classes.clearButton} onClick={this.clearTag}/>
                        </>
                    )}

                </div>
            </ProductDroppable>
        );
    }
}

const styles = (theme) => ({
    tag: {
        width: '2.5in',
        height: '6in',
        margin: '0.25in 0 0.25in',
        padding: '.1in .125in',
        boxShadow: '0 0 0 1px silver',
        position: 'relative',
        '@media print': {
            boxShadow: 'unset',
        },
    },
    clearButton: {
        position: 'absolute',
        top: '0',
        right: '0',
        transform: 'scale(.75) translateY(-75%)',
    },
}) as StyleRules;

export default (compose(
    withStyles(styles),
)(LargePartialTag));
