import { Chip, makeStyles, MenuItem, Paper, TextField, Typography } from '@material-ui/core';
import { FormControlProps } from '@material-ui/core/FormControl';
import { Theme } from '@material-ui/core/styles';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import CancelIcon from '@material-ui/icons/Cancel';
import { FastField, FastFieldConfig, FastFieldProps, Field, getIn } from 'formik';
import React from 'react';
import ReactSelect from 'react-select';
import { SelectComponents } from 'react-select/src/components';
import { Props as ReactSelectProps } from 'react-select/src/Select';
import shouldUpdate$ from './shouldUpdate';

interface IOptionType {
    label: string;
    value: string | number;
}

export interface IProps<T> {
    name: string;
    label: string;
    options: IOptionType[];
    isMulti?: boolean;
    validate?: (value: any) => string | undefined;
    shouldUpdate?: FastFieldConfig<T>['shouldUpdate'];
    className?: string;
    style?: CSSProperties;
    onInputChange?: ReactSelectProps<IOptionType>['onInputChange'];
    renderAsRegularField?: true;
    classes?: Partial<ReturnType<typeof useStyles>>;
}

type Props<T> = IProps<T>
    & FormControlProps;

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        height: 250,
    },
    input: {
        display: 'flex',
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
        height: 'min-content',
    },
    chip: {
        margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`,
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08,
        ),
    },
    noOptionsMessage: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
    singleValue: {
        fontSize: 14,
    },
    paper: {
        position: 'absolute',
        zIndex: 99,
        marginTop: theme.spacing(),
        left: 0,
        right: 0,
    },
    divider: {
        height: theme.spacing(2),
    },
}));

function inputComponent({ inputRef, ...theRest }) {
    return (<div ref={inputRef} {...theRest} />);
}

const components: Partial<SelectComponents<IProps<any>['options'][number]>> = {
    NoOptionsMessage: (props) => {
        return (<Typography {...props.innerProps} className={props.selectProps.classes.noOptionsMessage}>{props.children}</Typography>);
    },
    Control: (props) => {
        const errorText: string = props.selectProps.errorText;
        const searchValue = props.selectProps.inputValue;

        return (
            <TextField
                error={!!errorText}
                helperText={errorText}
                fullWidth
                label={props.selectProps.placeholder}
                InputLabelProps={{
                    shrink: props.hasValue || !!searchValue,
                }}
                InputProps={{
                    inputComponent,
                    inputProps: {
                        className: props.selectProps.classes.input,
                        inputRef: props.innerRef,
                        children: props.children,
                        ...props.innerProps,
                    },
                }}
            />
        );
    },
    Option: (props) => {
        return (
            <MenuItem
                buttonRef={props.innerRef}
                selected={props.isFocused}
                component="div"
                style={{
                    fontWeight: props.isSelected ? 500 : 400,
                }}
                {...props.innerProps}
                children={props.children}
            />
        );
    },
    Placeholder: () => {
        return (<></>);
    },
    ValueContainer: (props) => (<div className={props.selectProps.classes.valueContainer} children={props.children} />),
    SingleValue: (props) => {
        return (
            <Typography {...props.innerProps} className={props.selectProps.classes.singleValue} children={props.children} />
        );
    },
    MultiValue: (props) => {
        return (
            <Chip
                tabIndex={-1}
                label={props.children}
                onDelete={props.removeProps.onClick}
                className={`${props.selectProps.classes.chip} ${props.isFocused ? props.selectProps.classes.chipFocused : undefined}`}
                deleteIcon={<CancelIcon {...props.removeProps} />}
            />
        );
    },
    Menu: (props) => {
        return (
            <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
                {props.children}
            </Paper>
        );
    },
};

function AutoCompleteFormik<T = any>(props: Props<T>) {
    const {
        name,
        label,
        options = [],
        isMulti = false,
        validate,
        shouldUpdate = shouldUpdate$,
        classes: overrideClasses,
        className,
        style = {},
        onInputChange,
        renderAsRegularField,
    } = props;

    const classes = useStyles(props);
    const FieldComponent = renderAsRegularField ? Field : FastField;

    return (
        <FieldComponent name={name} validate={validate} shouldUpdate={shouldUpdate}>
            {({ field, form }: FastFieldProps) => {
                const onChange = (value: IOptionType | IOptionType[]) => {
                    form.setFieldValue(
                        field.name,
                        value === null || value === undefined ? (isMulti ? [] : '') : Array.isArray(value) ? value.map((x) => x.value) : value.value,
                    );
                };

                const errorText = getIn(form.errors, field.name);

                return (
                    <ReactSelect
                        isClearable
                        isSearchable
                        classes={classes}
                        className={className}
                        styles={{
                            container: (base) => ({
                                ...base,
                                ...style,
                            }),
                        }}
                        id={field.name}
                        name={field.name}
                        placeholder={label}
                        isMulti={isMulti}
                        options={options}
                        components={components}
                        onChange={onChange}
                        onInputChange={onInputChange}
                        value={field.value ? options.find((x) => x.value === field.value) : null}
                        onBlur={field.onBlur}
                        errorText={errorText}
                    />
                );
            }}
        </FieldComponent>
    );
}

export default AutoCompleteFormik;
