import { SquareTerminalTransactionDetailsDto } from '@eq3-aws/payments-client';
import ReceiptField from '@eq3/containers/pos-square/widgets/receipts/ReceiptField';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import React, { useMemo } from 'react';
import { Language, default as receiptTranslations } from '../translations';

interface IReceiptProps {
    transactionDetails: SquareTerminalTransactionDetailsDto;
    language: Language;
}

const PaymentReceiptData = ({ transactionDetails, language }: IReceiptProps) => {
    const { referenceNumber, storisReference, createdDate, squarePaymentCheckoutDetailsDto, cashierName, location } =
        transactionDetails;

    const {
        currency,
        amount,
        // status,
        chargeData,
    } = squarePaymentCheckoutDetailsDto ?? {};

    const { cardData, paymentReceiptData, paid } = chargeData ?? {};

    const { lastFourDigits, cardBrand, isInterac } = cardData ?? {};

    const {
        // accountType,
        aid,
        applicationCryptogram,
        applicationName,
        authorizationResponseCode,
        // terminalVerificationResults,
        // transactionStatusInformation,
    } = paymentReceiptData ?? {};

    const classes = useStyles();
    const translations = receiptTranslations[language ?? Language.EN];

    const locationName = location?.name?.split('-')[1]?.trim() ?? 'EQ3';

    const dateAndTime = useMemo(() => {
        const jsDate = new Date(createdDate);
        return {
            time: jsDate.toLocaleTimeString(),
            date: jsDate.toLocaleDateString(),
        };
    }, [createdDate]);

    const cardNumber = useMemo(() => {
        return lastFourDigits ? `**** **** **** ${lastFourDigits}` : undefined;
    }, [lastFourDigits]);

    const getFormattedAmt = () => {
        return language === Language.FR ? `${amount?.toFixed(2)} $ ${currency}` : `$ ${amount?.toFixed(2)} ${currency}`;
    };

    const renderCardOrAccountTypeLine = (isInterac?: boolean, cardBrand?: string, accountType?: string) => {
        // TODO: add accountType support for interac
        if (!!isInterac) {
            return (
                <>
                    {accountType && accountType !== 'unknown' && (
                        <ReceiptField fieldValue={accountType} seperator="" data-cy="paymentReceiptCardType" />
                    )}
                </>
            );
        } else {
            return (
                <>
                    {cardBrand && cardBrand !== 'unknown' && (
                        <ReceiptField fieldValue={cardBrand} seperator="" data-cy="paymentReceiptCardType" />
                    )}
                </>
            );
        }
    };

    const getReceiptFooterMessage = () => {
        // TODO: Work on adding more footer messages
        if (!!chargeData) {
            // if (paid && status === StripePaymentIntentStatus.requires_capture ) {
            //     return translations.authorizedNotCompleted;
            // } else
            if (paid) {
                return translations.completionApproved;
            }
            return translations.completionDeclined;
        }
        return translations.transactionNotCompleted;
    };

    return (
        <div className={classes.root} data-cy="paymentReceiptCard">
            <div className={classes.centerTextContainer}>
                {!location ? (
                    <pre>EQ3</pre>
                ) : (
                    <>
                        <pre data-cy="paymentReceiptLocationName">{locationName}</pre>
                        {location?.address && (
                            <>
                                <pre>{location?.address?.addressLine1}</pre>
                                {location?.address?.addressLine2 && <pre>{location?.address?.addressLine2}</pre>}
                                <pre>
                                    {location?.address?.city}, {location?.address?.province}{' '}
                                    {location.address?.postalCode}
                                </pre>
                            </>
                        )}
                    </>
                )}
            </div>

            <div className={classnames(classes.centerTextContainer, classes.mt16)}>
                <pre>
                    {dateAndTime.date}&nbsp;&nbsp;&nbsp;{dateAndTime.time}
                </pre>
            </div>

            <div className={classes.mt16}>
                <ReceiptField
                    fieldName={translations.txOrderNumber}
                    fieldValue={storisReference?.toUpperCase()}
                    data-cy="paymentReceiptOrderNumber"
                />
                <ReceiptField
                    fieldName={translations.txReference}
                    fieldValue={referenceNumber.toString().padStart(10, '0')}
                />
                <ReceiptField fieldName={translations.txCashier} fieldValue={cashierName} />
                <ReceiptField fieldName={translations.txType} fieldValue={translations.txTypePurchase} />
            </div>

            {/* Amount */}
            <div className={classes.mt16}>
                <ReceiptField
                    fieldName={translations.txAmount}
                    fieldValue={getFormattedAmt()}
                    data-cy="paymentReceiptAmount"
                />
            </div>

            <div className={classes.mt16}>
                {renderCardOrAccountTypeLine(isInterac, cardBrand)}
                <ReceiptField fieldName={translations.cardNumber} fieldValue={cardNumber} />
            </div>

            <div className={classes.mt16}>
                <ReceiptField fieldValue={applicationName} data-cy="paymentReceiptApplicationName" />
                <ReceiptField fieldValue={aid} />
                <ReceiptField fieldValue={applicationCryptogram} />
                <ReceiptField fieldName="ARC" fieldValue={authorizationResponseCode} seperator="" />
                {/* <ReceiptField fieldName="TVR" fieldValue={terminalVerificationResults} seperator="" /> */}
                {/* <ReceiptField fieldName="TSI" fieldValue={transactionStatusInformation} seperator="" /> */}
            </div>

            <div className={classnames(classes.centerTextContainer, classes.mt16)}>
                <pre data-cy="paymentReceiptFooterText">{getReceiptFooterMessage()}</pre>
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        '& pre': {
            margin: 0,
            whiteSpace: 'normal',
        },
    },
    mt16: {
        marginTop: theme.spacing(2),
    },
    centerTextContainer: {
        textAlign: 'center',
    },
}));

export default PaymentReceiptData;
