import { IUserDetails } from '@eq3/redux/userManagement/models';

export const MY_PROFILE_REDUX_KEY = 'myProfileData';

export interface IMyProfileReduxState {
    userDetails: IUserDetails | undefined;
}

export interface IMyProfileReduxSlice {
    [MY_PROFILE_REDUX_KEY]: IMyProfileReduxState;
}
