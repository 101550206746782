import { unwrap } from '@eq3/redux/utils';
import { Action } from 'redux';
import { createActions, handleActions } from 'redux-actions';
import { ThunkAction, ThunkDispatch, ThunkResult as ReduxThunkResult } from 'redux-thunk';
import { defer, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { errorNotification, notify, successNotification } from '../adminNotifications';
import { apiThunk as ApiThunk } from '../store';
import { ITextBlock } from './models';

export const { setCurrentBlock, setTextBlockList } = createActions({
    SET_CURRENT_BLOCK: (doc) => ({ doc }),
    SET_TEXT_BLOCK_LIST: (list) => ({ list }),
});

const initialState: {
    list: ITextBlock[],
    current?: ITextBlock,
} = {
    list: [],
    current: undefined,
};

export type IReduxSharedDocSlice = typeof initialState;

type ThunkResult<T = void> = ThunkAction<Promise<T>, IReduxSharedDocSlice, ApiThunk, Action<any>>;
export type TextBlocksDispatch = ThunkDispatch<IReduxSharedDocSlice, ApiThunk, Action<any>>;

export default handleActions({
    [setCurrentBlock]: (state, { payload: { doc } }) => ({ ...state, current: doc }),
    [setTextBlockList]: (state, { payload: { list } }) => ({ ...state, list }),
}, initialState);

export const fetchAllSharedTextBlocks = (): ThunkResult => async (dispatch, getStore, api) => {
    try {
        const { data } = await api(dispatch, getStore, '/admin/text-blocks', 'GET');
        dispatch(setTextBlockList(data));
    } catch (e) {
        console.error(e);
        dispatch(notify(errorNotification('Error getting shared text blocks list', e)));
    }
};

export const fetchAllTextBlocks$ = (): ReduxThunkResult<Observable<ITextBlock[]>> => (dispatch, getState, api) => {
    return defer(() => {
        return api<ITextBlock[]>(dispatch, getState, '/admin/text-blocks', 'GET');
    }).pipe(
        catchError((e) => {
            dispatch(notify(errorNotification('Error getting shared text blocks list', e)));
            return throwError(e);
        }),
        unwrap,
        tap((data) => dispatch(setTextBlockList(data))),
    );
};

export const fetchTextBlockById = (id: string): ThunkResult<ITextBlock> => async (dispatch, getStore, api) => {
    try {
        const { data } = await api(dispatch, getStore, `/admin/text-blocks/${id}`, 'GET');
        dispatch(setCurrentBlock(data));
        return data;
    } catch (e) {
        console.error(e);
        dispatch(notify(errorNotification(`Error getting text block with id=${id}`, e)));
    }
};

export const save = (form: ITextBlock): ThunkResult<ITextBlock> => async (dispatch, getStore, api) => {
    try {
        const { data } = await api(dispatch, getStore, `/admin/text-blocks`, 'POST', form);
        await dispatch(fetchAllSharedTextBlocks());
        dispatch(setCurrentBlock(data));
        dispatch(notify(successNotification('Saved Successfully')));
        return data;
    } catch (e) {
        console.error(e);
        dispatch(notify(errorNotification('Error Saving Text Block', e)));
    }
};

export const remove = (id): ThunkResult => async (dispatch, getStore, api) => {
    try {
        await api(dispatch, getStore, `/admin/text-blocks/${id}`, 'DELETE');
        dispatch(notify(successNotification('Deleted successfully')));
        dispatch(setCurrentBlock(undefined));
        dispatch(fetchAllSharedTextBlocks());
    } catch (e) {
        dispatch(notify(errorNotification('Error deleting shared doc', e)));
        throw e;
    }
};
