import { TerminalLocationDtoSquareLocationId, TerminalLocationDtoStripeLocationId } from '@eq3-aws/payments-client';
import { setSquareTerminalLocations, setTerminalLocations } from '@eq3/redux/pos/actions';
import { createReducer } from 'deox';

export interface IPosState {
    [country: string]: {
        terminalLocations: TerminalLocationDtoStripeLocationId[];
        squareTerminalLocations: TerminalLocationDtoSquareLocationId[]
    };
}

export interface IPosReduxSlice {
    pos: IPosState;
}

const initialState: IPosState = {};

export default createReducer(initialState, (handle) => [
    handle(setTerminalLocations, (state, { payload: { country, terminalLocations } }) => {
        return ({
            ...state,
            [country]: {
                terminalLocations,
                squareTerminalLocations: state[country]?.squareTerminalLocations || [],
            },
        });
    }),
    handle(setSquareTerminalLocations, (state, { payload: { country, squareTerminalLocations } }) => {
        return ({
            ...state,
            [country]: {
                terminalLocations: state[country]?.terminalLocations || [],
                squareTerminalLocations,
            },
        });
    }),
]);
