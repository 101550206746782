import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AutosizeInput from 'react-input-autosize';
import { LockButton } from '@eq3/containers/retail/priceTags/components/Buttons';
import PriceInput from '../../../components/PriceInput';
import LocalizedString from '../../../components/LocalizedString';
import nonPrintable from '@eq3/containers/retail/priceTags/util/nonPrintable';
import classnames from 'classnames';

interface ILockWording {
	regularPrice: boolean;
	salePrice: boolean;
}

const RugPriceChange = () => {
	const classes = useStyles();
	const [regularPricingDescription, setRegularPricingDescription] = useState();
	const [salePricingDescription, setSalePricingDescription] = useState();

	const [lockWording, setLockWording] = useState({
		regularPrice: true,
		salePrice: true,
	});

	const handleRegularPricingDescription = (e) => {
		setRegularPricingDescription(e.target.value);
	};

	const handleSalePricingDescription = (e) => {
		setSalePricingDescription(e.target.value);
	};

	const toggleLock = (lock: keyof ILockWording) => () => {
		setLockWording({ ...lockWording, [lock]: !lockWording[lock] });
	};

	return (
		<div className={classes.pricing}>
			<div className={classes.regularPricing}>
				{lockWording.regularPrice ? (
					<div className={classes.regularPricingDescription}>
						<LocalizedString labelId="was" />
					</div>
				) : (
					<AutosizeInput
						inputClassName={classnames(
							classes.regularPricingDescription,
							classes.zeroBorder
						)}
						name="regularPricingDescription"
						value={regularPricingDescription}
						onChange={handleRegularPricingDescription}
					/>
				)}
				<PriceInput
					className={classnames(classes.regularPriceInput, classes.zeroBorder)}
				/>
				<LockButton
					className={`${classes.lockButton} ${nonPrintable}`}
					onClick={toggleLock('regularPrice')}
				/>
			</div>
			<div className={classes.salePricing}>
				{lockWording.salePrice ? (
					<div className={classes.salePricingDescription}>
						<LocalizedString labelId="now" />
					</div>
				) : (
					<AutosizeInput
						inputClassName={classnames(
							classes.salePricingDescription,
							classes.zeroBorder
						)}
						name="salePricingDescription"
						value={salePricingDescription}
						onChange={handleSalePricingDescription}
					/>
				)}
				<PriceInput
					className={classnames(classes.salePriceInput, classes.zeroBorder)}
				/>
				<LockButton
					className={`${classes.lockButton} ${nonPrintable}`}
					onClick={toggleLock('salePrice')}
				/>
			</div>
		</div>
	);
};

export default RugPriceChange;

const useStyles = makeStyles(() => ({
	descriptionInput: {
		fontFamily: 'eq3-book',
		fontSize: '11pt',
		lineHeight: 'normal',
		width: '100%',
		resize: 'none',
		overflow: 'hidden',
		height: '100%',
		flex: '1 1 40%',
		marginRight: '10px',
	},
	pricing: {
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column',
		fontFamily: 'eq3-book',
		flex: '1 1 60%',
	},
	lockButton: {
		position: 'absolute',
		right: -10,
		transform: 'scale(.5) translate(150%, -40%)',
	},
	regularPricing: {
		display: 'flex',
		flexDirection: 'row',
		position: 'relative',
		justifyContent: 'space-between',
	},
	regularPricingDescription: {
		minWidth: '10px',
		fontFamily: 'eq3-book',
		fontSize: '11pt',
		textTransform: 'uppercase',
		textAlign: 'left',
		flex: '1 1 50%',
		paddingTop: '5px',
	},
	regularPriceInput: {
		textDecoration: 'line-through',
		fontFamily: 'eq3-book',
		fontSize: '11pt',
		textAlign: 'right',
		width: '36%',
	},
	salePricing: {
		display: 'flex',
		flexDirection: 'row',
		alignSelf: 'flex-end',
		position: 'relative',
		justifyContent: 'space-between',
	},
	salePricingDescription: {
		whiteSpace: 'nowrap',
		fontFamily: 'eq3-book',
		fontSize: '11pt',
		minWidth: '10px',
		textTransform: 'uppercase',
		flex: '1 1 50%',
		textAlign: 'left',
		paddingTop: '5px',
	},
	salePriceInput: {
		fontFamily: 'eq3-book',
		fontSize: '11pt',
		textAlign: 'right',
		width: '36%',
	},
	zeroBorder: {
		border: 0,
	},
}));
