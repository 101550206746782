export const ImageGalleryTypes = {
    FEATURED: {
        name: 'Featured',
        newFilesParameterName: 'newFeaturedImages',
        newFileTranslationsParameterName: 'newFeaturedImageTranslations',
        newFileImageAltTag: 'newFeaturedImageAltTag',
    },
    COVE: {
        name: 'Cove',
        newFilesParameterName: 'newCoveImages',
        newFileTranslationsParameterName: 'newCoveImageTranslations',
        newFileImageAltTag: 'newCoveImageAltTag',
    },
    HERO: {
        name: 'Hero',
        newFilesParameterName: 'newHeroImages',
        newFileTranslationsParameterName: 'newHeroImageTranslations',
        newFileImageAltTag: 'newHeroImageAltTag',
    },
    DIMENSIONS: {
        name: 'Dimensions',
        newFilesParameterName: 'newDimensionImages',
        newFileTranslationsParameterName: 'newDimensionImageTranslations',
        newFileImageAltTag: 'newDimensionImageAltTag',
    },
};
