import { SelectFieldProps } from '@eq3/component/form/v2/SelectField';
import { TextBoxSection } from '@eq3/redux/textBlocksAdmin/models';
import { Pagination } from '@eq3/utils/pagination';
import { createAction as $createAction } from 'deox';
import {
    IComponentItemListItem,
    IComponentItemsQuery,
    IProduct,
    IProductDefinitionListItem,
    IProductDefinitionListQuery,
} from './models';

const prefix = '[PRODUCT_DEFINITION]';
const createAction: typeof $createAction = (type, executor) => $createAction(`${prefix}\\${type}` as typeof type, executor);

export const setList = createAction('SET_LIST', (resolve) => (list: Pagination<IProductDefinitionListItem>) => resolve(list));
export const setListQuery = createAction('SET_LIST_QUERY', (resolve) => (query: IProductDefinitionListQuery = {}) => resolve(query));
export const setListLoading = createAction('SET_LIST_LOADING', (resolve) => (loading: boolean) => resolve(loading));

export const setCurrentProduct = createAction('SET_CURRENT_PRODUCT', (resolve) => (product: IProduct) => resolve(product));
export const setCurrentProductLoading = createAction('SET_CURRENT_PRODUCT_LOADING', (resolve) => (loading: boolean) => resolve(loading));
export const clearCurrentProduct = createAction('CLEAR_CURRENT_PRODUCT');

type OptionsType = SelectFieldProps<any>['options'];
export const setProp65Options = createAction('SET_PROP65_OPTIONS', (resolve) => (options: OptionsType) => resolve(options));

export const setComponentItems = createAction('SET_COMPONENT_ITEMS', (resolve) => (options: Pagination<IComponentItemListItem>) => resolve(options));
export const setComponentItemsQuery = createAction('SET_COMPONENT_ITEMS_QUERY', (resolve) => (query: IComponentItemsQuery) => resolve(query));

export const setTextBlockVariants = createAction('SET_TEXT_BLOCK_VARIANTS', (resolve) => (textBlockVariants: TextBoxSection[]) => resolve(textBlockVariants));

export const setGtin = createAction(`SET_GTIN`, (resolve) => (payload: { instanceId: string, gtin: string }) => resolve(payload));

export type ActionType =
    | ReturnType<typeof setList>
    | ReturnType<typeof setListQuery>
    | ReturnType<typeof setListLoading>
    | ReturnType<typeof setCurrentProduct>
    | ReturnType<typeof setCurrentProductLoading>
    | ReturnType<typeof clearCurrentProduct>
    | ReturnType<typeof setProp65Options>
    | ReturnType<typeof setComponentItems>
    | ReturnType<typeof setComponentItemsQuery>
    | ReturnType<typeof setTextBlockVariants>
    | ReturnType<typeof setGtin>;
