import { Backdrop, Button, Card, CardActionArea, CardActions, CardContent, CardHeader, CardMedia, Collapse, IconButton, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import classNames from 'classnames';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';

export interface IImageUploaderProps {
    title: string;
    imageUrl: Nullable<string>;
    onDelete?: React.MouseEventHandler<HTMLButtonElement>;
    onImageUpload?: (e: React.ChangeEvent<HTMLInputElement>, previewUrl: string, file: File) => void;
    className?: string;
    classes?: Partial<ReturnType<typeof useStyles>>;
    customerHeader?: JSX.Element;
}

export const useStyles = makeStyles(() => ({
    root: {
        width: '400px',
        height: 'min-content',
    },
    collapseAction: { 
        alignSelf: 'center',
        margin: 0,
        transition: 'transform 300ms ease',
        '&.expanded': {
            transform: 'rotate(180deg)',
        },
    },
    actions: {
        justifyContent: 'flex-end',
    },
    imageContainer: {
        paddingTop: 'calc(9 / 16 * 100%)',
        position: 'relative',
        '& > .inner-frame': {
             display: 'flex',
             justifyContent: 'center',
             alignItems: 'center',
             width: '100%',
             height: '100%',
             position: 'absolute',
             top: 0,
             left: 0,
        },
    },
    previewOverlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        transition: 'opacity 300ms ease',
        '&:hover': { opacity: 1 },
        '& > svg': { fontSize: '40pt' },
    },
}));

const ImageUploader = (props: PropsWithChildren<IImageUploaderProps>) => {
    const classes = useStyles(props);
    
    const [imageUrl, setImageUrl] = useState(props.imageUrl);
    const [cardExpanded, setCardExpanded] = useState(true);
    const [previewOpen, setPreviewOpen] = useState(false);
    const hiddenInputRef = useRef<HTMLInputElement>(null);
    const onCardExpanded = () => setCardExpanded((x) => !x);
    const onPreview = () => setPreviewOpen((x) => !x);

    const onEdit: React.MouseEventHandler<HTMLButtonElement> = (e) => hiddenInputRef.current?.click();

    const onDelete: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        setImageUrl(null);
        hiddenInputRef.current!.value = '';
        props.onDelete?.(e);
    };

    const onImageUpload: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const files = e.target.files && Array.from(e.target.files);
        if (!files || !files.length) return;

        const [file] = files;
        const fr = new FileReader();

        fr.onload = (fileReaderEvent) => {
            const result = fileReaderEvent.target?.result;
            if (typeof result !== 'string') return;
            setImageUrl(result);
            props.onImageUpload?.(e, result, file);
        };

        fr.readAsDataURL(file);
    };

    useEffect(() => {
        setImageUrl(props.imageUrl);
    }, [props.imageUrl]);

    return (
        <Card className={classNames(classes.root, props.className)}>
            {
                props.customerHeader 
                    ? props.customerHeader
                    : (
                        <CardHeader
                            classes={{ action: classNames(classes.collapseAction, { expanded: cardExpanded }) }}
                            title={props.title}
                            action={(
                                <IconButton onClick={onCardExpanded}>
                                    <ExpandMoreIcon />
                                </IconButton>
                            )} />
                    )
            }

            <Collapse in={cardExpanded}>
                {
                    imageUrl && (
                        <CardActionArea onClick={onPreview}>
                            <CardMedia
                                image={imageUrl}
                                title="Click to preview"
                                className={classes.imageContainer}>
                                    <div className={classes.previewOverlay}>
                                        <ZoomInIcon htmlColor="white" />
                                    </div>
                            </CardMedia>
                        </CardActionArea>
                    )
                }
                {
                    !imageUrl && (
                        <div className={classes.imageContainer}>
                            <div className="inner-frame">
                                <Button variant="contained" onClick={onEdit}>Upload Image</Button>
                            </div>
                        </div>
                    )
                }
                <CardContent>
                    <input ref={hiddenInputRef} type="file" accept="image/png" style={{ display: 'none' }} onChange={onImageUpload} />
                    {props.children}
                </CardContent>
                <CardActions className={classes.actions}>
                    <IconButton onClick={onEdit}>
                        <EditIcon />
                    </IconButton>
                    <IconButton onClick={onDelete} disabled={imageUrl === null}>
                        <DeleteIcon />
                    </IconButton>
                </CardActions>
            </Collapse>

            {
                imageUrl && (
                    <Backdrop open={previewOpen} onClick={onPreview} style={{ zIndex: 1502, backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
                        <img src={imageUrl} />
                    </Backdrop>
                )
            }
           
        </Card>
    );
};

export default ImageUploader;
