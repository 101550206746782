export enum AuthPermissions {
    ACCESS_ADMIN_PORTAL = 'access:admin_portal',

    READ_ORDERS = 'read:orders',
    WRITE_ORDERS = 'write:orders',

    READ_KONA_KART_ORDERS = 'read:kona_kart_orders',
    WRITE_KONA_KART_ORDERS = 'write:kona_kart_orders',

    READ_SWATCH_REQUESTS = 'read:swatch_request',
    WRITE_SWATCH_REQUESTS = 'write:swatch_request',

    READ_QUOTES = 'read:quotes',
    WRITE_QUOTES = 'write:quotes',
    WRITE_QUOTE_DISCOUNTS = 'write:quote_discounts',

    READ_POS_ORDERS = 'read:pos_orders',
    WRITE_POS_ORDERS = 'write:pos_orders',

    READ_CUSTOMER = 'read:customer',
    WRITE_CUSTOMER = 'write:customer',

    READ_MARKETING_FEEDS = 'read:marketing_feeds',
    WRITE_MARKETING_FEEDS = 'write:marketing_feeds',

    READ_MARKETING_PROMOTIONS = 'read:marketing_promotions',
    WRITE_MARKETING_PROMOTIONS = 'write:marketing_promotions',

    READ_STOCK_STATUS = 'read:stock_status',
    WRITE_STOCK_STATUS = 'write:stock_status',

    READ_STOCK_ORDERS = 'read:stock_orders',
    WRITE_STOCK_ORDERS = 'write:stock_orders',

    READ_DEVELOPER = 'read:developer',
    WRITE_DEVELOPER = 'write:developer',

    READ_INVENTORY_ORDERS = 'read:inventory_orders',
    WRITE_INVENTORY_ORDERS = 'write:inventory_orders',

    READ_PRICE_TAGS = 'read:price_tags',
    WRITE_PRICE_TAGS = 'write:price_tags',

    // NOTE: There is no generic "write" permission for locations
    READ_LOCATIONS = 'read:locations',
    CREATE_LOCATIONS = 'create:locations',
    UPDATE_LOCATIONS = 'update:locations',
    DELETE_LOCATIONS = 'delete:locations',

    READ_TRADE_PROGRAM = 'read:trade_program',
    WRITE_TRADE_PROGRAM = 'write:trade_program',

    READ_ECOM_HOME_PAGE = 'read:ecom_home_page',
    WRITE_ECOM_HOME_PAGE = 'write:ecom_home_page',

    READ_ECOM_CATEGORIES = 'read:ecom_categories',
    WRITE_ECOM_CATEGORIES = 'write:ecom_categories',

    READ_ECOM_REDIRECTS = 'read:ecom_redirects',
    WRITE_ECOM_REDIRECTS = 'write:ecom_redirects',

    READ_ECOM_SITEMAP = 'read:ecom_sitemap',
    WRITE_ECOM_SITEMAP = 'write:ecom_sitemap',

    READ_FINANCE = 'read:finance',

    READ_REPORTS_DOMO = 'read:reports_domo',
    WRITE_REPORTS_DOMO = 'write:reports_domo',

    READ_REPORTS_DWS = 'read:reports_d_w_s',
    WRITE_REPORTS_DWS = 'write:reports_d_w_s',

    READ_REPORTS_PROMO_CODE = 'read:reports_promo_code',
    WRITE_REPORTS_PROMO_CODE = 'write:reports_promo_code',

    READ_REPORTS_STRIPE = 'read:reports_stripe',

    READ_REPORTS_ZUCORA = 'read:reports_zucora',

    READ_PRODUCT_DEFINITIONS = 'read:product_definitions',
    WRITE_PRODUCT_DEFINITIONS = 'write:product_definitions',

    READ_SHARED_TEXT_BLOCKS = 'read:shared_text_blocks',
    WRITE_SHARED_TEXT_BLOCKS = 'write:shared_text_blocks',

    READ_PRODUCT_MANUFACTURERS = 'read:product_manufacturers',
    WRITE_PRODUCT_MANUFACTURERS = 'read:product_manufacturers',

    READ_TAGGING = 'read:tagging',
    WRITE_TAGGING = 'read:tagging',

    READ_PROP65 = 'read:prop65',
    WRITE_PROP65 = 'read:prop65',

    READ_UPHOLSTERY_ITEMS = 'read:upholstery_items',
    WRITE_UPHOLSTERY_ITEMS = 'read:upholstery_items',

    READ_UPHOLSTERY_ITEM_OPTIONS = 'read:upholstery_item_options',
    WRITE_UPHOLSTERY_ITEM_OPTIONS = 'read:upholstery_item_options',

    READ_OPTION_GROUPS = 'read:option_groups',
    WRITE_OPTION_GROUPS = 'read:option_groups',

    READ_GRADES_AND_COLORS = 'read:grades_and_colors',
    WRITE_GRADES_AND_COLORS = 'read:grades_and_colors',

    READ_GRADE_GROUPS = 'read:grade_groups',
    WRITE_GRADE_GROUPS = 'read:grade_groups',

    READ_PIECE_CODES = 'read:piece_codes',
    WRITE_PIECE_CODES = 'read:piece_codes',

    READ_PRICE_AND_COST_JUMPS = 'read:price_and_cost_jumps',
    WRITE_PRICE_AND_COST_JUMPS = 'read:price_and_cost_jumps',

    READ_SERIES_AND_FAMILY = 'read:series_and_family',
    WRITE_SERIES_AND_FAMILY = 'read:series_and_family',

    READ_PARTNER_ITEMS = 'read:partner_items',
    WRITE_PARTNER_ITEMS = 'read:partner_items',

    READ_SYNC_LOG = 'read:sync_log',
    WRITE_SYNC_LOG = 'read:sync_log',

    READ_SHIPPING_RATES = 'read:shipping_rates',
    WRITE_SHIPPING_RATES = 'read:shipping_rates',

    READ_SHIPPING_PARTNERS = 'read:shipping_partners',
    WRITE_SHIPPING_PARTNERS = 'read:shipping_partners',

    READ_LEAD_TIME_MANAGEMENT = 'read:lead_time_management',
    WRITE_LEAD_TIME_MANAGEMENT = 'read:lead_time_management',

    READ_ROLE_GROUPS = 'read:role_groups',
    WRITE_ROLE_GROUPS = 'write:role_groups',

    READ_USERS = 'read:users',
    WRITE_USERS = 'read:users',

    WRITE_POS_PAYMENTS = 'write:pos_payments',
    WRITE_POS_VT_PAYMENTS = 'write:pos_vt_payments',
    READ_POS_TRANSACTIONS = 'read:pos_transactions',
    WRITE_POS_REFUNDS = 'write:pos_refunds',
    WRITE_POS_UNLINKED_REFUNDS = 'write:pos_unlinked_refunds',

    READ_POS_TERMINALS = 'read:pos_terminals',
    WRITE_POS_TERMINALS = 'write:pos_terminals',

    READ_POS_TERMINAL_LOCATIONS = 'read:pos_terminal_locations',
    WRITE_POS_TERMINAL_LOCATIONS = 'write:pos_terminal_locations',

    READ_INVENTORY_ADJUSTMENTS = 'read:inventory_adjustments',
    WRITE_INVENTORY_ADJUSTMENTS = 'write:inventory_adjustments',

    READ_INVENTORY_ITEMS = 'read:inventory_items',

    READ_WAREHOUSE_MANAGEMENT = 'read:warehouse_management',
    WRITE_WAREHOUSE_MANAGEMENT = 'write:warehouse_management',

    READ_PURCHASE_ORDERS = 'read:purchase_orders',
    WRITE_PURCHASE_ORDERS = 'write:purchase_orders',

    READ_TRANSFER_ORDERS = 'read:transfer_orders',
    WRITE_TRANSFER_ORDERS = 'write:transfer_orders',
}

export interface IAuth0User {
    nickname: string;
    name: string;
    email: string;
    picture: string;
    updated_at: string;
    email_verified: boolean;
    sub: string;
}
