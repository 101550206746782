import { defaultPageSize, Pagination } from '@eq3/utils/pagination';
import { handleActions } from 'redux-actions';
import {
    setAllGroupsForSearch,
    setAssociableTagsList,
    setGroups,
    setSearchParams,
    setTags,
    setTagsAssociatedToEntity,
} from './actions';
import { ITag, ITaggingState, ITagGroup, TaggingSearch } from './models';

const initialTagsState: Pagination<ITag> = {
    items: [],
    page: 0,
    pageSize: 0,
    totalCount: 0,
};

const initialTagGroupsState: Pagination<ITagGroup> = {
    items: [],
    page: 0,
    pageSize: 0,
    totalCount: 0,
};

const initialSearchParams: TaggingSearch = {
    tag: '',
    tagName: '',
    group: '',
    groupName: '',
    pageNumber: 0,
    pageSize: defaultPageSize,
    tagsOrderBy: 'TAG_NAME',
    tagsOrderDirection: 'ASC',
    groupsOrderBy: 'GROUP_NAME',
    groupsOrderDirection: 'ASC',
};

const defaultTaggingState: ITaggingState['tagging'] = {
    tags: initialTagsState,
    groups: initialTagGroupsState,
    allGroupsList: initialTagGroupsState.items,
    searchParams: initialSearchParams,
    allAssociableTags: [],
    tagsAssociatedToEntities: [],
};

export default handleActions({
    [setTags]: (state: ITaggingState['tagging'], { payload: tags }) => ({ ...state, tags }),
    [setGroups]: (state: ITaggingState['tagging'], { payload: groups }) => ({ ...state, groups }),
    [setAllGroupsForSearch]: (state: ITaggingState['tagging'], { payload: allGroupsList }) => ({ ...state, allGroupsList }),
    [setSearchParams]: (state: ITaggingState['tagging'], { payload: searchParams }) => ({ ...state, searchParams: { ...state.searchParams, ...searchParams } }),
    [setAssociableTagsList]: (state: ITaggingState['tagging'], { payload: allAssociableTags }) => ({ ...state, allAssociableTags }),
    [setTagsAssociatedToEntity]: (state: ITaggingState['tagging'], { payload: tagsAssociatedToEntities }) => {
        const associatedTags = [...state.tagsAssociatedToEntities];
        tagsAssociatedToEntities.forEach((entity) => {
            const { entityId: id, entityType: type } = entity;
            const index = associatedTags.findIndex(({ entityId, entityType }) => entityId === id && entityType === type);
            associatedTags[index === -1 ? associatedTags.length : index] = entity;
        });

        return {
            ...state,
            tagsAssociatedToEntities: associatedTags,
        };
    },
}, defaultTaggingState);
