import { ReportedPaymentTransactionDto, ReportedRefundTransactionDto, TransactionReportDto } from '@eq3-aws/payments-client';
import ReceiptField from '@eq3/containers/pos/widgets/receipts/ReceiptField';
import {
    ITransactionListingReportSummary
} from '@eq3/redux/pos';
import { getDailyTransactionListingReport } from '@eq3/redux/pos/thunks/transactionHistoryThunks';
import { CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import moment from 'moment';
import qs from 'qs';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { ThunkDispatch } from 'redux-thunk';
import { defer, of } from 'rxjs';
import { concatMap, finalize } from 'rxjs/operators';

const PrintPosDailyReport = () => {
    const { search } = useLocation();

    const classes = useStyles();
    const dispatch = useDispatch<ThunkDispatch>();

    const [report, setReport] = useState<TransactionReportDto>();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string>();

    const displayPeriodStart = useMemo(() => {
        if (!report) {
            return;
        }
        const jsDate = new Date(report!.startTime);
        return {
            time: jsDate.toLocaleTimeString(),
            date: jsDate.toLocaleDateString(),
        };
    }, [report]);

    const displayPeriodEnd = useMemo(() => {
        if (!report) {
            return;
        }
        const jsDate = new Date(report!.endTime);
        return {
            time: jsDate.toLocaleTimeString(),
            date: jsDate.toLocaleDateString(),
        };
    }, [report]);

    useEffect(() => {
        const subscription = defer(() => {
            const query = qs.parse(search, { ignoreQueryPrefix: true });
            const platformLocationId = query.platformLocationId as string;
            const queryReportDate = query.reportDate as string;

            const selectedDate = moment(queryReportDate);
            const startTimeEpoch = selectedDate.startOf('day').unix() * 1000;
            const endTimeEpoch = selectedDate.endOf('day').unix() * 1000;

            return of({
                startTimeEpoch,
                endTimeEpoch,
                platformLocationId,
            });
        }).pipe(
            concatMap(({ platformLocationId, endTimeEpoch, startTimeEpoch }) => {
                return dispatch(getDailyTransactionListingReport({
                    startTimeEpoch,
                    endTimeEpoch,
                    platformLocationId,
                    includeSquare: false
                }));
            }),
            finalize(() => setIsLoading(false)),
        ).subscribe({
            next: (report) => {
                setReport(report);
            },
            error: (err) => {
                console.error('Error fetching POS report', err);
                setError(err?.response?.data?.errorDescription ?? 'Sorry! An error occurred.');
            },
        });
        return () => subscription.unsubscribe();
    }, []);

    const renderError = () => (
        <Typography color="error">{error}</Typography>
    );

    const renderLoadingFrame = () => (
        <div className={classes.loadingFrame}>
            <CircularProgress size={64}/>
        </div>
    );

    const renderContent = () => (
        <div className={classes.root}>
            <div className={classes.centerTextContainer}>
                <pre>Transaction Report</pre>
                <pre>
                    {displayPeriodStart!.date} {displayPeriodStart!.time} - {displayPeriodEnd!.date} {displayPeriodEnd!.time}
                </pre>
            </div>

            <div className={classnames(classes.centerTextContainer, classes.mt16)}>
                <pre>{report!.stripeLocation?.name}</pre>
                <pre>{report!.stripeLocation?.address?.addressLine1}</pre>
                {report!.stripeLocation?.address?.addressLine2 && <pre>{report!.stripeLocation?.address?.addressLine2}</pre>}
                <pre>
                    {report!.stripeLocation?.address?.city}, {report!.stripeLocation?.address?.province} {report!.stripeLocation?.address?.postalCode}
                </pre>
            </div>

            <LineBreak />

            {/* Purchase Summary. */}
            <ReportSummary title="Purchase Summary" summary={report!.purchaseSummary} total={report!.purchaseTotal} />

            <LineBreak />

            {/* Refunds Summary. */}
            <ReportSummary title="Refunds Summary" summary={report!.refundSummary} total={report!.refundTotal} />

            <LineBreak />

            {/* Purchase line items. */}
            <ReportLineItems
                title="Purchase Line Items"
                transactions={report!.purchases as Array<ReportedPaymentTransactionDto & ReportedRefundTransactionDto>}
            />
            <LineBreak />

            {/* Refund line items. */}
            <ReportLineItems title="Refund Line Items" transactions={report!.refunds as Array<ReportedPaymentTransactionDto & ReportedRefundTransactionDto>} />
        </div>
    );

    return (
        <>
            {isLoading
                ? renderLoadingFrame()
                : !!error
                    ? renderError()
                    : renderContent()}
        </>
    );
};

interface IReportSummaryProps {
    title: string;
    summary: ITransactionListingReportSummary;
    total: number;
}

const ReportSummary = ({ summary, title, total }: IReportSummaryProps) => {
    const classes = useStyles();
    return (
        <div className={classes.mt8}>
            <div className={classes.centerTextContainer}>
                <pre>{title}</pre>
            </div>
            <div className={classes.mt8}>
                {Object.entries(summary).map(([cardType, amount]) => (
                    <ReceiptField
                        key={`${title}-${cardType}`}
                        fieldName={cardType}
                        fieldValue={`$${amount.toFixed(2)}`}/>
                ))}
                <div className={classes.mt8}>
                    <ReceiptField
                        fieldName="Total"
                        fieldValue={`$${total.toFixed(2)}`}/>
                </div>
            </div>
        </div>
    );
};

interface IReportLineItemsProps {
    title: string;
    transactions: Array<ReportedPaymentTransactionDto & ReportedRefundTransactionDto>;
}

const ReportLineItems = ({ transactions, title }: IReportLineItemsProps) => {
    const classes = useStyles();

    const txTimeLookup = useMemo(() => {
        const txTimeLookup = new Map<number, string>();
        transactions?.forEach((x, i) => {
            const date = new Date(x.transaction.time);
            txTimeLookup.set(i, `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`);
        });
        return txTimeLookup;
    }, [transactions]);

    return (
        <div className={classes.mt16}>
            <div className={classes.centerTextContainer}>
                <pre>{title}</pre>
            </div>
            {transactions?.map((tx, i) => (
                <div key={`${title}-${i}`} className={classes.mt8}>
                    <pre>{txTimeLookup.get(i)}</pre>
                    <pre>{tx.transaction.isInterac ? 'INTERAC' : tx.transaction.cardBrand.toUpperCase()}</pre>
                    <pre>{tx.transaction.storisReference}</pre>
                    {tx.transaction.comments && (<pre>{tx.transaction.comments}</pre>)}
                    <pre>{`$${tx.transaction.amount.toFixed(2)}`}</pre>
                </div>
            ))}
        </div>
    );
};

const LineBreak = () => {
    const classes = useStyles();

    return (
        <div className={classnames(classes.centerTextContainer, classes.mt8)}>
            <pre>---------------------------------------------</pre>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    errorContainer: {
        padding: theme.spacing(3),
    },
    root: {
        padding: theme.spacing(1),
        maxWidth: '360px',
        display: 'flex',
        flexDirection: 'column',
        '& pre': {
            margin: 0,
            whiteSpace: 'normal',
        },
    },
    mt16: {
        marginTop: theme.spacing(2),
    },
    mt8: {
        marginTop: theme.spacing(1),
    },
    centerTextContainer: {
        textAlign: 'center',
    },
    loadingFrame: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        width: '100%',
        height: '100%',
    },
}));

export default PrintPosDailyReport;
