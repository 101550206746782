import { IRole, IRoleGroup } from '@eq3/redux/roleGroups/models';
import { IRoleGroupsReduxSlice } from '@eq3/redux/roleGroups/reducers';
import { useSelector } from 'react-redux';

export const useRoleGroupsList = (): IRoleGroup[] => {
    return useSelector<IRoleGroupsReduxSlice, IRoleGroup[]>((state) => state.roleGroups.list);
};

export const useAllRoles = (): IRole[] => {
    return useSelector<IRoleGroupsReduxSlice, IRole[]>((state) => state.roleGroups.roles);
};
