import EQ3Snackbar from '@eq3/design/components/snackbars/Snackbar';
import Texxt from '@eq3/design/components/Texxt';
import {Dialog, DialogTitle, IconButton, Theme} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { PropsWithChildren, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideNotification, INotification } from '../redux/adminNotifications';
import SideBar from './Sidebar';
import InfoIcon from '@material-ui/icons/Info';
import AppBar from '@eq3/component/AppBar';
import {AppBarContextProvider} from '@eq3/component/AppBarContext';

const useStyles = makeStyles((theme: Theme) => ({
    chromeRoot: {
        display: 'flex',
        flexDirection: 'column',
    },
    mainContent: () => ({
        width: '100%',
        padding: theme.spacing(2, 2, 0),
        position: 'relative',
    }),
    notificationDialogContent: {
        margin: '10px',
    },
}));

const Chrome = (props: PropsWithChildren<{}>) => {
    const { children } = props;

    const classes = useStyles();
    const dispatch = useDispatch();

    const notificationOpen = useSelector<any, boolean>((state) => state.adminNotifications.open);
    const notification = useSelector<any, INotification>((state) => state.adminNotifications.notification);

    const [notificationDescriptionOpen, setNotificationDescriptionOpen] = useState(false);
    const [notificationDescription, setNotificationDescription] = useState('');
    const [notificationType, setNotificationType] = useState('');

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const snackbarClosed = () => {
        dispatch(hideNotification());
    };

    const onShowNotificationDescription = (description: string, type: string) => () => {
        setNotificationDescriptionOpen(true);
        setNotificationDescription(description);
        setNotificationType(type);
    };

    const onHideNotificationDescription = () => {
        setNotificationDescriptionOpen(false);
    };

    const getSnackbarVariant = () => {
        if (!notification) return 'general';

        switch (notification.status) {
            case 'busy':
            case 'error': return 'error';
            case 'warn': return 'warn';
            case 'success': return 'success';
            default:
                 return 'general';
        }
    };

    const openSideBar = () => setIsSidebarOpen(true);
    const closeSideBar = () => setIsSidebarOpen(false);

    return (
        <div className={classes.chromeRoot}>
            <AppBarContextProvider>
                <AppBar
                    isSidebarOpen={isSidebarOpen}
                    onSidebarOpen={openSideBar}
                    onSidebarClose={closeSideBar}
                />
                <SideBar
                    isSidebarOpen={isSidebarOpen}
                    onSidebarClose={closeSideBar}
                />
                <main className={classes.mainContent}>
                    {children}
                </main>
            </AppBarContextProvider>
            
            <EQ3Snackbar open={notificationOpen} variant={getSnackbarVariant()} onClose={snackbarClosed} autoHideDuration={notification?.autoHideDuration}>
                <Texxt style={{ color: 'inherit' }} data-cy="notification-message">
                    {notification?.message ?? ''}
                    {
                        notification?.description && notification?.type && (
                            <IconButton size="small" onClick={onShowNotificationDescription(notification.description, notification.type)}>
                                <InfoIcon />
                            </IconButton>
                        )
                    }
                </Texxt>
            </EQ3Snackbar>

            <Dialog open={notificationDescriptionOpen} onClose={onHideNotificationDescription}>
                <DialogTitle>Notification Description:</DialogTitle>
                <div className={classes.notificationDialogContent}>
                    {notificationType === 'json'
                        ? <pre>{notificationDescription}</pre>
                        : notificationDescription}
                </div>
            </Dialog>
        </div>
    );
};

export default Chrome;
