import muiTheme from '@eq3/design/theme';
import '@eq3/fonts/fonts.css';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import * as ReactSentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import { setConfig } from 'react-hot-loader';
import { hot } from 'react-hot-loader/root';
import { Provider } from 'react-redux';
import App from './App';
import { store } from './redux/store';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import * as FullStory from '@fullstory/browser';

setConfig({
    trackTailUpdates: false,
});

const Index = () => {

    FullStory.init({
        orgId: process.env.FULL_STORY_ORG_ID!!,
        // We won't record any sessions in dev mode.
        devMode: process.env.ENVIRONMENT !== 'prod',
        // Debug mode with extra browser console logging.
        debug: true,
    });

    return (
        <Provider store={store}>
            <MuiThemeProvider theme={muiTheme}>
                <CssBaseline/>
                <App/>
            </MuiThemeProvider>
        </Provider>
    );
};

ReactSentry.init({
    enabled: process.env.ENVIRONMENT !== 'local',
    dsn: process.env.SENTRY_DSN,
    sampleRate: 1.0,
    release: `${process.env.SENTRY_PROJECT}_${process.env.ENVIRONMENT}_${process.env.VERSION}`,
    environment: process.env.ENVIRONMENT,
    integrations: [new BrowserTracing()],
    tracesSampler: (samplingContext) => {
        if(process.env.ENVIRONMENT !== 'prod'){
            return 0;   // disable tracing in all environments except prod
        } else if(samplingContext.location?.pathname.indexOf('pos/') !== -1){
          // Retail/POS ops are critical & harder to diagnose.
          // Accept all transactions to provide as much detail as possible
          // and avoid missing important transactions
          return 1.0;
        }
        return 0.5; // default sample rate (avoid exceeding account quota)
    },
});

const rootEl = document.getElementById('root');
const HotIndex = hot(Index);

ReactDOM.render(<HotIndex/>, rootEl);
