export interface ILeadTimeEstimate {
    id: string;
    type: LeadTimeEstimateType;
    from: number;
    to: number;
    timeUnit: LeadTimeEstimateTimeUnit;
    countryCode?: string;
}

export interface ILeadTimeEstimateUpdate {
    id: string;
    from: number;
    to: number;
    timeUnit: LeadTimeEstimateTimeUnit;
}

export enum LeadTimeEstimateType {
    CUSTOM_UPHOLSTERY_MANUFACTURING = 'CUSTOM_UPHOLSTERY_MANUFACTURING',
    PARCEL_SHIPPING = 'PARCEL_SHIPPING',
    FREIGHT_SHIPPING = 'FREIGHT_SHIPPING',
    SWATCH_SHIPPING = 'SWATCH_SHIPPING',
}

export enum LeadTimeEstimateTimeUnit {
    BUSINESS_DAYS = 'BUSINESS_DAYS',
    WEEKS = 'WEEKS',
}
