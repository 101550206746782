import { ActionType, setOrderShipments } from '@eq3/redux/orderShipments/actions';
import {
    ICreateOrUpdateShipment,
    IShipment,
    IShipmentDetails,
    IShipmentProductFulfillment,
    IShipmentsDictionary
} from '@eq3/redux/orderShipments/models';
import { IReduxSlice } from '@eq3/redux/orderShipments/reducers';
import { apiThunk as ApiThunk } from '@eq3/redux/store';
import { handleError } from '@eq3/utils/exceptionUtils';
import { ThunkDispatch, ThunkResult } from 'redux-thunk';
import { notify, successNotification } from '../adminNotifications';

type ShipmentsThunkResult<T> = ThunkResult<Promise<T>, IReduxSlice>;
export type OrderShipmentsDispatch = ThunkDispatch<IReduxSlice, ApiThunk, ActionType>;

export const fetchShipmentsForOrder = (orderId: string): ShipmentsThunkResult<IShipment[]> => async (dispatch, getState, api) => {
    try {
        const { data } = await api<IShipment[]>(dispatch, getState, `/admin/order/${orderId}/shipments`, 'GET');
        const orderIdToShipmentsLookup: IShipmentsDictionary = { [orderId]: data };
        dispatch(setOrderShipments(orderIdToShipmentsLookup));
        return data;
    } catch (e) {
        handleError(e, dispatch, `Error fetching shipments for order`);
        return [];
    }
};

export const fetchShipment = (orderId: string, shipmentId: string): ShipmentsThunkResult<Optional<IShipmentDetails>> => async (dispatch, getState, api) => {
    try {
        const { data } = await api(dispatch, getState, `/admin/order/${orderId}/shipment/${shipmentId}`, 'GET');
        return data;
    } catch (e) {
        handleError(e, dispatch, `Error fetching shipments ${shipmentId}`);
        return undefined;
    }
};

export const createShipment = (orderId: string, shipment: ICreateOrUpdateShipment): ShipmentsThunkResult<Optional<IShipment>> => async (dispatch, getState, api) => {
    try {
        const { data } = await api<IShipment>(dispatch, getState, `/admin/order/${orderId}/shipment`, 'POST', shipment);
        fetchShipmentsForOrder(orderId)(dispatch, getState, api);
        dispatch(notify(successNotification('Shipment successfully added!')));
        return data;
    } catch (e) {
        handleError(e, dispatch, `Error creating shipment`);
        return undefined;
    }
};

export const updateShipment = (orderId: string, shipmentId: string, shipment: ICreateOrUpdateShipment): ShipmentsThunkResult<Optional<IShipment>> => async (dispatch, getState, api) => {
    try {
        const { data } = await api<IShipment>(dispatch, getState, `/admin/order/${orderId}/shipment/${shipmentId}`, 'PUT', shipment);
        fetchShipmentsForOrder(orderId)(dispatch, getState, api);
        dispatch(notify(successNotification('Shipment successfully saved!')));
        return data;
    } catch (e) {
        handleError(e, dispatch, `Error updating shipment`);
        return undefined;
    }
};

export const fetchItemsToFulfill = (orderId: string, shipmentId?: string): ShipmentsThunkResult<IShipmentProductFulfillment[]> => async (dispatch, getState, api) => {
    try {
        const { data } = await api<IShipmentProductFulfillment[]>(
            dispatch,
            getState,
            `/admin/order/${orderId}/fulfillment`,
            'GET',
            undefined,
            { 
                params: {
                    shipmentId,
                }
            }
        );

        return data;
    } catch (e) {
        handleError(e, dispatch, `Error fetching items for fulfillment`);
        return [];
    }
};
