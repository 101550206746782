import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

interface IBugIconProps extends SvgIconProps {
    iconColour?: string;
}

const BugIcon = (props: IBugIconProps) => {
    const { iconColour, ...rest } = props;

    return (
        <SvgIcon viewBox="0 0 24 24" fill="none" {...rest}>
            <path d="M7.125 6.5625H16.875C16.9781 6.5625 17.0625 6.47813 17.0625 6.375C17.0625 5.4375 16.8562 4.57734 16.4555 3.84141C16.0523 3.10312 15.4594 2.51016 14.7211 2.10703C13.9852 1.70625 13.125 1.5 12.1875 1.5H11.8125C10.875 1.5 10.0148 1.70625 9.27891 2.10703C8.54062 2.51016 7.94766 3.10312 7.54453 3.84141C7.14375 4.57734 6.9375 5.4375 6.9375 6.375C6.9375 6.47813 7.02187 6.5625 7.125 6.5625Z" />
            <path d="M22.0312 12H18.5625V9.65625C20.3625 9.65625 21.8203 8.19844 21.8203 6.39844C21.8203 6.29531 21.7359 6.21094 21.6328 6.21094H20.2266C20.1234 6.21094 20.0391 6.29531 20.0391 6.39844C20.0391 7.21406 19.3781 7.875 18.5625 7.875H5.4375C4.62188 7.875 3.96094 7.21406 3.96094 6.39844C3.96094 6.29531 3.87656 6.21094 3.77344 6.21094H2.36719C2.26406 6.21094 2.17969 6.29531 2.17969 6.39844C2.17969 8.19844 3.6375 9.65625 5.4375 9.65625V12H1.96875C1.86562 12 1.78125 12.0844 1.78125 12.1875V13.5C1.78125 13.6031 1.86562 13.6875 1.96875 13.6875H5.4375V15.9375C5.4375 16.0898 5.44219 16.2422 5.45391 16.3898C3.84609 17.0766 2.71875 18.6727 2.71875 20.5312C2.71875 20.6344 2.80313 20.7188 2.90625 20.7188H4.21875C4.32188 20.7188 4.40625 20.6344 4.40625 20.5312C4.40625 19.4953 4.96641 18.5883 5.80313 18.1008C5.94375 18.5039 6.12187 18.8883 6.33516 19.2492C6.90469 20.2219 7.71797 21.0352 8.69062 21.6047C9.36797 22.0008 10.1203 22.2797 10.9242 22.4133C11.0273 22.4133 11.1117 22.3289 11.1117 22.2258V11.3438C11.1117 11.2406 11.1961 11.1562 11.2992 11.1562H12.7055C12.8086 11.1562 12.893 11.2406 12.893 11.3438V22.2234C12.893 22.3266 12.9773 22.4109 13.0805 22.4109C13.8844 22.2773 14.6367 21.9984 15.3141 21.6023C16.2867 21.0328 17.1 20.2195 17.6695 19.2469C17.8828 18.8836 18.0609 18.4992 18.2016 18.0984C19.0336 18.5883 19.5938 19.4953 19.5938 20.5312C19.5938 20.6344 19.6781 20.7188 19.7812 20.7188H21.0938C21.1969 20.7188 21.2812 20.6344 21.2812 20.5312C21.2812 18.6727 20.1539 17.0766 18.5461 16.3898C18.5555 16.2398 18.5625 16.0898 18.5625 15.9375V13.6875H22.0312C22.1344 13.6875 22.2188 13.6031 22.2188 13.5V12.1875C22.2188 12.0844 22.1344 12 22.0312 12Z" />
        </SvgIcon>
    );
};

export default BugIcon;
