import {handleError} from '@eq3/utils/exceptionUtils';
import { defaultPageSize, Pagination } from '@eq3/utils/pagination';
import { createActionCreator, createReducer } from 'deox';
import qs from 'qs';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import {apiThunk} from '../store';
import { IImportStockStatus, IImportStockStatusState } from './models';

export type ProductInstancesDispatch = ThunkDispatch<IImportStockStatusState, apiThunk, AnyAction>;

const initialState: IImportStockStatusState = {
    loading: false,
    list: {
        items: [],
        totalCount: 0,
        page: 0,
        pageSize: defaultPageSize,
    },
};

export interface IImportStockStatusQuery {
    page: number;
    pageSize: number;
    sku: string;
    item: string;
    storisOrderNumber: string;
    plc: string[];
}

const createInventorySystemActionCreator: typeof createActionCreator = (type, resolver) => createActionCreator(`[INVENTORY_SYSTEM]\\${type}` as typeof type, resolver);

const actions = {
    setLoading: createInventorySystemActionCreator(
        'SET_LOADING',
        (resolve) => (loading: boolean) => resolve(loading),
    ),
    setImportStockStatus: createInventorySystemActionCreator(
        'SET_IMPORT_STOCK_STATUS',
        (resolve) => (data: Pagination<IImportStockStatus>) => resolve(data),
    ),
};

const { setLoading, setImportStockStatus } = actions;

export const fetchImportStockStatus = (query: IImportStockStatusQuery) => async (dispatch, getStore, api: apiThunk) => {
    try {
        dispatch(setLoading(true));
        const { data } = await api<Pagination<IImportStockStatus>>(dispatch, getStore, '/admin/retail-inventory/import-stock-status', 'GET', undefined, {params: query, paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }) });
        dispatch(setImportStockStatus(data));
    } catch (e) {
        handleError(dispatch, e, 'Error getting Import Stock Status');
        throw e;
    }
};

export const fetchRetailInventoryReportCSV = (query) => async (dispatch, getStore, api: apiThunk) => {
    try {
        const csvQuery = {
            ...query,
            pageSize: 100000,
        };
        
        const { data } = await api(dispatch, getStore, '/admin/retail-inventory/csv-export', 'GET', undefined, {params: csvQuery, paramsSerializer: (csvQuery) => qs.stringify(csvQuery, { arrayFormat: 'repeat' }) });
        return new Blob([data], {type: 'text/csv'});
    } catch (e) {
        handleError(dispatch, e, 'Error getting Retail Inventory Report CSV');
        throw e;
    }
};

export default createReducer(initialState, (handler) => [
    handler(setLoading, (state, { payload: loading }) => {
        return {...state, loading};
    }),
    handler(setImportStockStatus, (state, { payload: data }) => {
        return {...state, list: data, loading: false};
    }),
]);
