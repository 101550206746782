import { paymentServiceClient } from '@eq3/clients/paymentService/paymentServiceClient';
import { BeginPaymentDto, BeginPhoneInTransactionDto, BeginPhoneInTxPaymentLinkDto, SquareTerminalTransactionDetailsDto, TerminalTransactionDetailsDto } from '@eq3-aws/payments-client';
import { ThunkResult } from 'redux-thunk';
import { defer, EMPTY, interval, Observable, throwError, timer } from 'rxjs';
import { catchError, map, switchMap, takeUntil, takeWhile } from 'rxjs/operators';
import { errorNotification, notify } from '@eq3/redux/adminNotifications';

export type PaymentThunkResult<T> = ThunkResult<Observable<T>>;

export const getPayment = (paymentId: string): PaymentThunkResult<SquareTerminalTransactionDetailsDto> => (dispatch, getState) => {
    return defer(() =>
        paymentServiceClient(dispatch, getState)
            .squarePaymentController
            .getFullSquarePaymentDetailsByPaymentId(paymentId)
    ).pipe(
        map((x) => x.data),
        catchError((e) => {
            dispatch(notify(errorNotification('Error fetching payment.', e)));
            return throwError(e);
        }),
    );
};

export const createTerminalCheckout = (terminalPaymentRequest: BeginPaymentDto): PaymentThunkResult<SquareTerminalTransactionDetailsDto> => (dispatch, getState) => {
    return defer(() =>
        paymentServiceClient(dispatch, getState)
            .squarePaymentController
            .createTerminalCheckout(terminalPaymentRequest)
    ).pipe(
        map((x) => x.data),
        catchError((e) => {
            dispatch(notify(errorNotification('Error creating payment.', e)));
            return throwError(e);
        }),
    );
};

export const dismissTerminalCheckout = (checkoutId: string): PaymentThunkResult<SquareTerminalTransactionDetailsDto> => (dispatch, getState) => {
    return defer(() =>
        paymentServiceClient(dispatch, getState)
            .squarePaymentController
            .dismissTerminalCheckout(checkoutId)
    ).pipe(
        map((x) => x.data),
        catchError((e) => {
            dispatch(notify(errorNotification('Error creating payment.', e)));
            return throwError(e);
        }),
    );
};

export const completePayment = (paymentIntentId: string): PaymentThunkResult<TerminalTransactionDetailsDto> => (dispatch, getState) => {
    return defer(() =>
        paymentServiceClient(dispatch, getState)
            .paymentController
            .captureOrRecordLatestIntentState(paymentIntentId)
    ).pipe(
        map((x) => x.data),
        catchError((e) => {
            dispatch(notify(errorNotification('Error completing payment.', e)));
            return throwError(e);
        }),
    );
};

export const pollForPaymentCompletion = (checkoutId?: string): PaymentThunkResult<SquareTerminalTransactionDetailsDto> => (dispatch, getState) => {
    if (!checkoutId) { return EMPTY; }
    const pollingObservable = defer(() =>
        paymentServiceClient(dispatch, getState)
            .squarePaymentController
            .getFullSquarePaymentDetailsByCheckoutId(checkoutId)
    );
    const INTERVAL = 3000;
    const MINUTE_MULTIPLIER = 60000/INTERVAL;
    const TIMER = 5 * MINUTE_MULTIPLIER * INTERVAL + INTERVAL; // 5 minutes timer + 1 addition interval to do a final check
    
    return interval(INTERVAL).pipe(
        switchMap(() => pollingObservable),
        map((x) => x.data),
        takeWhile((data) => data.status !== 'COMPLETED' && data.status !== 'CANCELED', true),
        takeUntil(timer(TIMER)),
        catchError((e) => {
            dispatch(notify(errorNotification('Error confirming payment.', e)));
            return throwError(e);
        }),
    );
};

export const processVirtualTerminalPayment = (beginPhoneInTransaction: BeginPhoneInTransactionDto): PaymentThunkResult<SquareTerminalTransactionDetailsDto> => (dispatch, getState) => {
    return defer(() =>
        paymentServiceClient(dispatch, getState)
            .squarePaymentController
            .processVirtualTerminalPayment(beginPhoneInTransaction)
    ).pipe(
        map((x) => x.data),
        catchError((e) => {
            dispatch(notify(errorNotification('Error processing virtual terminal payment.', e)));
            return throwError(e);
        }),
    );
};

export const generateVirtualTerminalPaymentLink = (beginPhoneInTxPaymentLinkDto: BeginPhoneInTxPaymentLinkDto): PaymentThunkResult<SquareTerminalTransactionDetailsDto> => (dispatch, getState) => {
    return defer(() =>
        paymentServiceClient(dispatch, getState)
            .squarePaymentController
            .generateVirtualTerminalPaymentLink(beginPhoneInTxPaymentLinkDto)
    ).pipe(
        map((x) => x.data),
        catchError((e) => {
            dispatch(notify(errorNotification('Error processing virtual terminal payment.', e)));
            return throwError(e);
        }),
    );
};

export const pollForPaymentLinkConfirmation = (orderId?: string): PaymentThunkResult<SquareTerminalTransactionDetailsDto> => (dispatch, getState) => {
    if (!orderId) { return EMPTY; }
    const pollingObservable = defer(() =>
        paymentServiceClient(dispatch, getState)
            .squarePaymentController
            .getPaymentLinkStateByOrderId(orderId)
    );
    const INTERVAL = 3000;
    const MINUTE_MULTIPLIER = 60000/INTERVAL;
    const TIMER = 15 * MINUTE_MULTIPLIER * INTERVAL + INTERVAL; // 5 minutes timer + 1 addition interval to do a final check
    
    return interval(INTERVAL).pipe(
        switchMap(() => pollingObservable),
        map((x) => x.data),
        takeWhile((data) => data.status !== 'COMPLETED' && data.status !== 'CANCELED', true),
        takeUntil(timer(TIMER)),
        catchError((e) => {
            dispatch(notify(errorNotification('Error confirming payment.', e)));
            return throwError(e);
        }),
    );
};

export const deleteVirtualTerminalPaymentLink = (paymentLinkId: string, locationId: string): PaymentThunkResult<void> => (dispatch, getState) => {
    return defer(() =>
        paymentServiceClient(dispatch, getState)
            .squarePaymentController
            .deleteVirtualTerminalPaymentLink(paymentLinkId, locationId)
    ).pipe(
        map((x) => x.data),
        catchError((e) => {
            dispatch(notify(errorNotification('Error processing virtual terminal payment.', e)));
            return throwError(e);
        }),
    );
};

export const syncPaymentIntent = (paymentIntentId: string): PaymentThunkResult<TerminalTransactionDetailsDto> => (dispatch, getState) => {
    return defer(() =>
        paymentServiceClient(dispatch, getState)
            .paymentController
            .recordLatestIntentState(paymentIntentId)
    ).pipe(
        map((x) => x.data),
        catchError((e) => {
            dispatch(notify(errorNotification('Error syncing payment intent.', e)));
            return throwError(e);
        }),
    );
};
