import { createActions, handleActions } from 'redux-actions';

export interface INotification {
    status: 'success' | 'error' | 'warn' | 'info' | 'busy';
    message: string;
    description?: string;
    type?: 'json' | 'text';
    autoHideDuration?: number;
}

export const {showNotification: notify, hideNotification} = createActions({
    SHOW_NOTIFICATION: (notification: INotification) => ({notification}),
    HIDE_NOTIFICATION: () => false,
});

export default handleActions({
    [notify]: (state, {payload: {notification}}) => ({...state, notification, open: true}),
    [hideNotification]: (state) => ({...state, open: false}),
}, {notification: undefined, open: false});

export const successNotification = (message: string, description?: string) => ({
    status: 'success',
    message,
    description,
});

export const warnNotification = (message: string, description?: string) => ({
    status: 'warn',
    message,
    description,
});

export const busyNotification = (message: string, description?: string) => ({
    status: 'busy',
    message,
    description,
    autoHideDuration: 10000,
});

export const errorNotification = (message: string, error?, duration?: number) => ({
    status: 'error',
    message,
    description: error ? JSON.stringify(error, null, 2) : undefined,
    type: 'json',
    autoHideDuration: duration
});

export const infoNotification = (message: string, data?: any) => ({
    status: 'info',
    message,
    description: data,
});
