import createTypography from '@material-ui/core/styles/createTypography';
import { CSSProperties } from 'react';
import palette from './palette';


export const fontWeight = {
    Regular: 400,
    Medium: 500,
    Bold: 700,
};

export const texxtStyles = {
    headingOne: {
        fontWeight: fontWeight.Medium,
        fontSize: '32px',
        lineHeight: '140%',
        textTransform: 'none',
    } as any as CSSProperties,
    headingTwo: {
        fontWeight: fontWeight.Medium,
        fontSize: '24px',
        lineHeight: '140%',
        textTransform: 'none',
    } as any as CSSProperties,
    headingThree: {
        fontWeight: fontWeight.Medium,
        fontSize: '20px',
        lineHeight: '140%',
        textTransform: 'none',
    } as any as CSSProperties,
    label: {
        fontWeight: fontWeight.Medium,
        fontSize: '16px',
        lineHeight: '140%',
        textTransform: 'uppercase',
    } as any as CSSProperties,
    inputAndButton: {
        fontWeight: fontWeight.Medium,
        fontSize: '16px',
        lineHeight: '140%',
        textTransform: 'none',
    } as any as CSSProperties,
    inputHelp: {
        fontWeight: fontWeight.Regular,
        fontSize: '14px',
        lineHeight: '140%',
        textTransform: 'none',
    } as any as CSSProperties,
    tableHeading: {
        fontWeight: fontWeight.Medium,
        fontSize: '12px',
        lineHeight: '100%',
        textTransform: 'uppercase',
    } as any as CSSProperties,
    tableHeadingBold: {
        fontWeight: fontWeight.Bold,
        fontSize: '12px',
        lineHeight: '100%',
        textTransform: 'uppercase',
    } as any as CSSProperties,
    body: {
        fontWeight: fontWeight.Regular,
        fontSize: '16px',
        textTransform: 'none',
    } as any as CSSProperties,
    bodyBold: {
        fontWeight: fontWeight.Bold,
        fontSize: '16px',
        lineHeight: '140%',
        textTransform: 'none',
    } as any as CSSProperties,
    bodyBoldShort: {
        fontWeight: fontWeight.Bold,
        fontSize: '16px',
        lineHeight: '100%',
        textTransform: 'none',
    } as any as CSSProperties,
};

// TODO: at somepoint we need to update these to use the texxtStyles, we don’t 
//      need to translate every typography theme property over to a texxt style. 
//      Apparently we can disable the ones that we don’t use.
//      https://mui.com/material-ui/customization/typography/#adding-amp-disabling-variants
export default createTypography(palette, {
    fontFamily: 'Poppins',
    fontSize: 14,
    h1: {
        fontSize: '35px',
        lineHeight: '40px',
        letterSpacing: '-0.24px',
        color: '#263238',
    },
    h2: {
        fontSize: '29px',
        lineHeight: '32px',
        letterSpacing: '-0.24px',
        color: '#263238',
    },
    h3: {
        fontSize: '24px',
        lineHeight: '28px',
        letterSpacing: '-0.06px',
        color: '#263238',
    },
    h4: {
        fontSize: '20px',
        lineHeight: '24px',
        letterSpacing: '-0.05px',
        color: '#263238',
    },
    h5: {
        fontSize: '16px',
        lineHeight: '20px',
        letterSpacing: '-0.05px',
        color: '#263238',
    },
    h6: {
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '-0.05px',
        color: '#263238',
    },
    body1: {
        fontSize: '14px',
        lineHeight: '21px',
        letterSpacing: '-0.05px',
        color: '#263238',
    },
    body2: {
        fontSize: '12px',
        lineHeight: '18px',
        letterSpacing: '-0.04px',
        color: '#263238',
    },
    subtitle1: {
        fontSize: '16px',
        lineHeight: '25px',
        letterSpacing: '-0.09px',
        color: '#546E7A',
    },
    subtitle2: {
        fontSize: '14px',
        lineHeight: '22px',
        letterSpacing: '-0.05px',
        color: '#607D8B',
    },
    caption: {
        fontSize: '12px',
        lineHeight: '20px',
        letterSpacing: '-0.05px',
        color: '#263238',
    },
    overline: {
        fontSize: '11px',
        lineHeight: '13px',
        letterSpacing: '-0.33px',
    },
});
