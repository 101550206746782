import { FormControl, FormHelperText, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';
import { SelectProps } from '@material-ui/core/Select';
import { FastField, FastFieldConfig, FastFieldProps, Field, getIn } from 'formik';
import React from 'react';
import shouldUpdate$ from './shouldUpdate';

export type SelectFieldProps<T = any> =
    & {
        label?: string;
        options: Array<{ label: string; value: any }>;
        helperText?: string;
        renderAsRegularField?: true;
    }
    & Omit<SelectProps, 'name' | 'value'>
    & Pick<FastFieldConfig<T>, 'name' | 'validate' | 'shouldUpdate'>;

export default Component;

/**
 * Renders a Formik FastField dropdown.
 *
 * You may pass a `renderAsRegularField` prop to render regular Formik Field.
 */
function Component<T = any>(props: SelectFieldProps<T>) {
    const { name, label, validate, onChange, onBlur, helperText, shouldUpdate = shouldUpdate$, options, style, className, fullWidth, native, renderAsRegularField, variant, ...selectProps } = props;
    const classes = useStyles(props);

    const FieldComponent = !!renderAsRegularField ? Field : FastField;

    return (
        <FieldComponent name={name} validate={validate} shouldUpdate={shouldUpdate}>
            {({ form, field }: FastFieldProps) => {
                const $onChange: SelectProps['onChange'] = (e, child) => {
                    field.onChange(e);

                    if (onChange) {
                        onChange(e, child);
                    }
                };

                const $onBlur: SelectProps['onBlur'] = (e) => {
                    field.onBlur(e);

                    if (onBlur) {
                        onBlur(e);
                    }
                };

                const errorText = getIn(form.errors, name);
                const $helperText = errorText || helperText;

                return (
                    <FormControl error={Boolean(errorText)} style={style} className={className} fullWidth={fullWidth} variant={variant}>
                        {label && (
                            <InputLabel shrink={field.value !== undefined && field.value !== ''} htmlFor={name}>
                                {label}
                            </InputLabel>
                        )}

                        <Select
                            {...selectProps}
                            classes={classes}
                            native={native}
                            id={name}
                            name={name}
                            label={label}
                            value={field.value ?? ''}
                            onChange={$onChange}
                            onBlur={$onBlur}
                            data-cy={selectProps['data-cy'] || 'filterSelect'}>
                            {native
                                ? [
                                      <option key={name} />,
                                      ...options.map((o, i) => (
                                          <option key={`${i}-${o.value}`} value={o.value}>
                                              {o.label}
                                          </option>
                                      )),
                                  ]
                                : options.map((o, i) => (
                                      <MenuItem key={`${i}-${o.value}`} value={o.value}>
                                          {o.label}
                                      </MenuItem>
                                  ))}
                        </Select>

                        {(errorText || $helperText) && <FormHelperText>{errorText || $helperText}</FormHelperText>}
                    </FormControl>
                );
            }}
        </FieldComponent>
    );
}

const useStyles = makeStyles({
    root: {
        minWidth: 200,
    },
});
