import { TerminalLocationDtoStripeLocationId, TerminalRegistrationDto } from '@eq3-aws/payments-client';
import { CreateTerminalLocationAddressDtoCountryEnum } from '@eq3-aws/payments-client/models/create-terminal-location-address-dto';
import { paymentServiceClient } from '@eq3/clients/paymentService/paymentServiceClient';
import { ILocationDetails } from '@eq3/redux/locations/models';
import { PosActionsType, setTerminalLocations } from '@eq3/redux/pos';
import { IPosReduxSlice } from '@eq3/redux/pos/reducers';
import { apiThunk } from '@eq3/redux/store';
import { Countries } from '@eq3/utils/locales';
import { ThunkAction } from 'redux-thunk';
import { defer, iif, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ITerminalRegistrationFormValues } from '../models';

type TerminalLocationThunkResult<T> = ThunkAction<Observable<T>, IPosReduxSlice, apiThunk, PosActionsType>;

export const getStripeTerminalLocations = (country: Countries, refresh = false): TerminalLocationThunkResult<TerminalLocationDtoStripeLocationId[]> => (dispatch, getState) => {
    const cachedLocations = getState().pos?.[country]?.terminalLocations;

    return iif(
        () => !refresh && !!cachedLocations?.length,
        defer(() => of(cachedLocations)),
        defer(() =>
            paymentServiceClient(dispatch, getState)
                .terminalLocationController
                .getAllTerminalLocations(country)
        ).pipe(
            map((x) => x.data),
            map((x) => x.terminalLocations ?? []),
            tap((x) => dispatch(setTerminalLocations(country, x))),
        ),
    );
};

export const getTerminalLocationFromPlatformId = (platformLocationId: string): TerminalLocationThunkResult<TerminalLocationDtoStripeLocationId> => (dispatch, getState) => {
    return defer(() =>
        paymentServiceClient(dispatch, getState)
            .terminalLocationController
            .findTerminalLocationByPlatformLocationId(platformLocationId)
    ).pipe(map((x) => x.data));
};

export const registerTerminal = (platformLocationId: ITerminalRegistrationFormValues['platformLocationId'], terminalRegistration: TerminalRegistrationDto): TerminalLocationThunkResult<void> => (dispatch, getState) => {
    return defer(() =>
        paymentServiceClient(dispatch, getState)
            .terminalLocationController
            .registerTerminal(platformLocationId, terminalRegistration)
    ).pipe(map((x) => x.data));
};

export const createStripeLocationAssociation = (location: ILocationDetails): TerminalLocationThunkResult<TerminalLocationDtoStripeLocationId> => (dispatch, getState) => {
    return defer(() =>
        paymentServiceClient(dispatch, getState)
            .terminalLocationController
            .createNewStripeLocation({
                platformLocationId: location.id,
                platformLocationDescription: location.locationName,
                address: {
                    displayName: location.locationName,
                    addressLine1: location.address.streetAddress,
                    city: location.address.city,
                    province: location.address.province,
                    postalCode: location.address.postalCode,
                    country: location.address.country as any as CreateTerminalLocationAddressDtoCountryEnum,
                }
            })
    ).pipe(map((x) => x.data));
};
