import { createStyles, FormControl, FormHelperText, InputLabel, withStyles } from '@material-ui/core';
import { WithStyles } from '@material-ui/core/styles';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { FastField, FastFieldConfig, FastFieldProps, getIn } from 'formik';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import React, { useEffect, useState } from 'react';
import { Editor, EditorProps, RawDraftContentState } from 'react-draft-wysiwyg';
import { compose } from 'redux';
import shouldUpdate$ from './shouldUpdate';

// tslint:disable-next-line: interface-over-type-literal
export type DraftFieldProps<T = any> = {
    label: string;
    name: string;
    validate?: FastFieldConfig<T>['validate'];
    shouldUpdate?: FastFieldConfig<T>['shouldUpdate'];
    fullWidth?: boolean;
};

const toolbar: EditorProps['toolbar'] = {
    options: ['inline', 'blockType', 'link', 'list'],
    inline: { options: ['bold', 'italic'] },
    link: { inDropdown: true, popupClassName: 'link-popup' },
    blockType: { options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'] },
    image: false,
    previewImage: false,
};

type Props<T = any> = DraftFieldProps<T>
    & WithStyles<typeof styles>
    & FastFieldProps;

const styles = () => createStyles({
    draftFieldToolbar: {
        '& .link-popup': {
            height: 'auto',
            '& [for="openLinkInNewWindow"]': {
                display: 'none',
            },
        },
    },
    editorClass: {
        width: '100%',
        border: '1px solid #bbb',
        background: 'white',
        padding: '0 1em',
    },
    editorError: {
        borderColor: '#f44336',
    },
});

function DraftField({ field, form, label, classes, fullWidth }: Props) {
    const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());

    useEffect(() => {
        const newEditorState = compose(
            EditorState.createWithContent,
            convertFromRaw,
            markdownToDraft as (value: any) => RawDraftContentState,
        )(field.value);

        setEditorState(newEditorState);
    }, [field.value]);

    const $onBlur: EditorProps['onBlur'] = () => {
        const markdown = compose(
            draftToMarkdown as (raw: RawDraftContentState) => string,
            convertToRaw,
        )(editorState.getCurrentContent());

        form.setFieldValue(field.name, markdown);
        form.setFieldTouched(field.name, true);
    };

    const onEditorStateChange: EditorProps['onEditorStateChange'] = (editorState) => setEditorState(editorState);

    const errorText = getIn(form.errors, field.name);
    const hasError = !!errorText && getIn(form.touched, field.name);

    return (
        <FormControl error={hasError} margin="dense" fullWidth={fullWidth}>
            <InputLabel shrink>{label}</InputLabel>
            <div style={{marginTop: '1rem'}}>
                <Editor
                    editorClassName={`${classes.editorClass} ${hasError ? classes.editorError : ''}`}
                    onEditorStateChange={onEditorStateChange}
                    editorState={editorState}
                    onBlur={$onBlur}
                    stripPastedStyles
                    spellCheck
                    toolbar={toolbar}
                    toolbarClassName={classes.draftFieldToolbar}
                />
            </div>
            {hasError && <FormHelperText>{errorText}</FormHelperText>}
        </FormControl>
    );
}

const InnerComponent = withStyles(styles)(DraftField);

export default function Component<T = any>(props: DraftFieldProps<T>) {
    const { name, validate, shouldUpdate = shouldUpdate$, label, fullWidth = true } = props;

    return (
        <FastField name={name} validate={validate} shouldUpdate={shouldUpdate} label={label} component={InnerComponent} fullWidth={fullWidth}/>
    );
}
