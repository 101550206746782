import getYear from 'date-fns/getYear';

export const Months = {
    ORDERED_MONTHS: ['January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'],
};

export enum DaysOfWeekReference {
  Monday = 1,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
  Sunday,
}

const IMPLEMENTATION_YEAR = 2021;
export const Years = Array.from(Array(getYear(new Date()) - IMPLEMENTATION_YEAR + 1), (_, i) => IMPLEMENTATION_YEAR + i);
