import { isBrowser } from '@eq3/utils';
import qs from 'qs';
import React, { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import useForceUpdate from './useForceUpdate';

const createReplacer = (replace: <T>(x: any) => T) => (obj: any) => {
    if (typeof obj === 'undefined' || obj === null) return obj;

    if (Array.isArray(obj)) {
        return obj.map((x) => createReplacer(replace)(x));
    } else if (typeof obj === 'object') {
        return Object.entries(obj as object).reduce((a, [key, value]) => {
            return {
                ...a,
                [key]: createReplacer(replace)(value)
            };
        }, {});
    } else {
        return replace(obj);
    }
};

const booleanReplacer = createReplacer((x) => {
    if (typeof x === 'boolean') return x;
    else if (typeof x === 'string') {
        const cleaned = x.toLowerCase().trim();
        return cleaned === 'true'
            ? true
            : cleaned === 'false'
                ? false
                : x;
    }

    return x;
});

const useQueryString = () => {
    const search = isBrowser() && window.location.search;
    const forceUpdate = useForceUpdate();
    const qsObj = useMemo(
        () => booleanReplacer(qs.parse(!!search ? search : '', { ignoreQueryPrefix: true })),
        [search],
    );

    const parseData = <T extends unknown>(parser: (x: any) => T) => <X extends any>(obj: X) => {
        if (typeof obj === 'undefined' || obj === null) return obj;
    
        if (Array.isArray(obj)) {
            return obj.map((x) => parseData(parser)(x));
        } else if (typeof obj === 'object') {
            return Object.entries(obj as object).reduce((a, [key, value]) => {
                return {
                    ...a,
                    [key]: parseData(parser)(value)
                };
            }, {});
        } else {
            return parser(obj);
        }
    };

    const parseObj = parseData((x) => {
        if (typeof x === 'boolean') return x;
    
        if (typeof x === 'string') {
            const cleaned =  x.toLowerCase().trim();
    
            return cleaned === 'true'
                ? true
                : cleaned === 'false'
                    ? false
                    : x;
        }
    
        return x;
    });

    const formUrl = (search: string) => {
        const parts = [
            window.location.protocol,
            '//',
            window.location.host,
            window.location.pathname,
            '?',
            search,
            window.location.hash,
        ];

        return parts.join('');
    };

    const setMulti = <T extends {} = Record<string, any>>(obj: Partial<T>) => {
        if (!isBrowser()) {
            return;
        }

        const normalized = Object.entries({ ...obj }).reduce((cleaned, [key, value]) => {
            if (typeof value === 'undefined' || (typeof value === 'string' && value.trim().length === 0)) {
                delete cleaned[key];
                return cleaned;
            }

            return {
                ...cleaned,
                [key]: value,
            };
        }, {});

        window.history.replaceState(null, '', formUrl(qs.stringify(normalized, { addQueryPrefix: false })));
        forceUpdate();
    };

    return {
        getString: (): string | undefined => qs.stringify(qsObj, { addQueryPrefix: false }),
        getValue: <T extends any = string>(key: string): T | undefined => {
            return parseObj(qsObj[key]);
        },
        getAll: <T extends {} = Record<string, string>>(): T => parseObj(qsObj),
        set: <T extends any = undefined>(key: T extends undefined ? string : (keyof T), value: any) => {
            if (!isBrowser()) {
                return;
            }

            window.history.replaceState(null, '', formUrl(qs.stringify({
                ...qsObj,
                [key]: value,
            }, { addQueryPrefix: false })));

            forceUpdate();
        },
        remove: <T extends any = undefined>(...keys: Array<T extends undefined ? string : (keyof T)>) => {
            if (!isBrowser()) {
                return;
            }

            if (keys.length === 0) {
                window.history.replaceState(null, '', formUrl(''));
            } else {
                window.history.replaceState(null, '', formUrl(qs.stringify({
                    ...Object.entries(qsObj).reduce((a, [key, value]) => {
                        if (keys.some((k) => k === key)) { return a; }

                        return {
                            ...a,
                            [key]: value,
                        };
                    }, {}),
                }, { addQueryPrefix: false })));
            }

            forceUpdate();
        },
        setMulti: <T extends {} = Record<string, any>>(obj: Partial<T>) => setMulti({ ...qsObj, ...obj }),
        replace: <T extends {} = Record<string, any>>(obj: Partial<T>) => setMulti({...obj}),
    };
};

export type QueryString = ReturnType<typeof useQueryString>;
export default useQueryString;

const QueryStringContext = createContext({});

export const QueryStringContextProvider = ((props: PropsWithChildren<{}>) => {
    const { children } = props;
    const qsUtil = useQueryString();

    return (
        <QueryStringContext.Provider value={qsUtil}>
            {children}
        </QueryStringContext.Provider>
    );
});

export const useQueryStringContext = () => useContext(QueryStringContext) as ReturnType<typeof useQueryString>;
