import React, {useEffect, useState} from 'react';
import {AppBar, Avatar, Theme, Toolbar, useTheme} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {useMyProfileData} from '@eq3/redux/myProfile/selectors';
import {useAuth} from '@eq3/redux/auth/hooks';
import Texxt from '@eq3/design/components/Texxt';
import {MyProfileEditorPopover} from '@eq3/component/MyProfileEditorPopover';
import IconButton from '@eq3/design/components/buttons/IconButton';
import { Link } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import {useAppBarContext} from '@eq3/component/AppBarContext';
import {useDebounce} from '@eq3/hooks';
import eq3Colours from '@eq3/design/theme/colours';

interface IProps {
    isSidebarOpen: boolean;
    onSidebarOpen: () => void;
    onSidebarClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    appbar: {
        backgroundColor: eq3Colours.whiteTransparent,
        padding: theme.spacing(0, 2),
        display: 'flex',
        justifyContent: 'center',
    },
    container: {
        position: 'relative',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
    },
    menuNavContainer: {
        display: 'flex',
        flexDirection: 'row',
        cursor: 'pointer',
        alignItems: 'center',
    },
    userContainer: {
        display: 'flex',
        flexDirection: 'row',
        cursor: 'pointer',
        justifySelf: 'end',
        alignItems: 'center',
    },
    detailsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        margin: theme.spacing(0, 2),
    },
    eq3Logo: () => ({
        height: theme.spacing(4),
    }),
    eq3LogoContainer: () => ({
        justifySelf: 'center',
    }),
    arrowDownIcon: {
        fill: theme.palette.text.primary,
    }
}));

const AppBarComponent = (props: IProps) => {
    const {isSidebarOpen, onSidebarOpen, onSidebarClose } = props;
    const classes = useStyles(props);
    const {userDetails} = useMyProfileData();
    const {user} = useAuth()!.loggedInUser!;
    const appContext = useAppBarContext();
    const theme = useTheme();

    const [appbarZIndex, setAppbarZIndex] = useState(theme.zIndex.appBar);

    useEffect(() => {
        if(isSidebarOpen) {
            setAppbarZIndex(theme.zIndex.modal + 1);
        }
    }, [isSidebarOpen]);

    const debouncedSidebarClosing = useDebounce(isSidebarOpen, 225);

    useEffect(() => {
        if(!isSidebarOpen) {
            setAppbarZIndex(theme.zIndex.appBar);
        }
    }, [debouncedSidebarClosing]);

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = !!anchorEl;

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null); };

    return (
        <>
            <AppBar elevation={0} className={classes.appbar} data-cy="main-app-bar" style={{
                zIndex: appbarZIndex,
            }}>
                <Toolbar className={classes.container} disableGutters>
                    <div className={classes.menuNavContainer}>
                        <IconButton onClick={isSidebarOpen ? onSidebarClose : onSidebarOpen} data-cy="side-bar-toggle-button">
                            {isSidebarOpen ? <CloseIcon/> : <MenuIcon/>}
                        </IconButton>
                        {appContext ? (
                            <>
                                <div className={classes.detailsContainer}>
                                    <Texxt variant="bodyBoldShort">{isSidebarOpen ? 'Dashboard' : appContext.title}</Texxt>
                                    <Texxt variant="tableHeading">{isSidebarOpen ? 'Navigation & Quick Links' : appContext.description}</Texxt>
                                </div>
                            </>
                        ) : null}
                    </div>
                    <Link to="/" className={classes.eq3LogoContainer}>
                        <img src="/images/eq3LogoBlack.svg" alt="logo" className={classes.eq3Logo}/>
                    </Link>
                    <div className={classes.userContainer} onClick={handleMenu} data-cy="user-toolbar-button">
                        <Avatar src={userDetails?.profile.profileImageUrl ?? user.picture}/>
                        <div className={classes.detailsContainer}>
                            <Texxt variant="bodyBoldShort" data-cy="user-toolbar-user-email">{user?.name}</Texxt>
                            {userDetails?.currentWorkingLocation && (
                                <Texxt variant="tableHeading" data-cy="user-toolbar-location-name">{userDetails.currentWorkingLocation.locationName}</Texxt>
                            )}
                        </div>
                        <ArrowDownIcon className={classes.arrowDownIcon}/>

                    </div>
                </Toolbar>
            </AppBar>
            <Toolbar/> {/* this exists so the app bar above doesn't cover the rest of the page at the top */}
            <MyProfileEditorPopover
                popoverProps={{
                    open,
                    anchorEl,
                    onClose: handleClose,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                    BackdropProps:{ open }
                }}
                handleClose={handleClose}
            />
        </>
    );
};

export default AppBarComponent;


