import {SwapButton} from '@eq3/containers/retail/priceTags/components/Buttons';
import { StyleRules, WithStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import LocalizedPrice from '../../../components/LocalizedPrice';
import LocalizedString from '../../../components/LocalizedString';
import PriceInput from '../../../components/PriceInput';
import { i18n, Locale } from '../../../util/i18n';

import nonPrintable from '@eq3/containers/retail/priceTags/util/nonPrintable';
import { withStyles } from '@material-ui/core';

interface IProps {
    product: any;
}

interface IState {
    showPriceAsFrom: boolean;
}

class BedroomCaseGoodsTag extends React.Component<IProps & WithStyles<ReturnType<typeof styles>> & ReturnType<typeof mapStateToProps>, IState> {

    public constructor(props) {
        super(props);

        this.state = {
            showPriceAsFrom: !!props.product.asShown,
        };
    }

    private formatSize = (locale: any, size: string | null): string | null => {
        if (size == null) {
            return size;
        }

        size = size
            .replace(/x/g, '×');

        if (locale === Locale.fr_CA) {
            size = size
                .replace(/ft/g, ' pi')
                .replace(/in/g, ' po');
        } else {
            size = size
                .replace(/ft/g, '\'')
                .replace(/in/g, '\"');
        }

        return size;
    }

    private toggleFrom = () => {
        this.setState(({showPriceAsFrom}) => ({showPriceAsFrom: !showPriceAsFrom}));
    }

    public render() {
        const { product, classes, locale } = this.props;
        const localizedProduct = product[locale.id];

        const {showPriceAsFrom} = this.state;

        let dimensions: string = '';
        let sep = '';
        if (product.width) {
            dimensions += sep + product.width + (isNaN(+product.width) ? '' : i18n[locale.id].width_abbreviation);
            sep = ' x ';
        }
        if (product.depth) {
            dimensions += sep + product.depth + (isNaN(+product.depth) ? '' : i18n[locale.id].depth_abbreviation);
            sep = ' x ';
        }
        if (product.height) {
            dimensions += sep + product.height + (isNaN(+product.height) ? '' : i18n[locale.id].height_abbreviation);
            sep = ' x ';
        }
        dimensions = this.formatSize(locale, dimensions);

        return (
            <div className={classes.container}>
                <div className={classes.name}>
                    {localizedProduct.name}
                </div>
                <div className={classes.dimensions}>
                    {dimensions}
                </div>
                <div className={classes.description}>
                    {localizedProduct.description}
                </div>
                <div className={classes.pricing}>
                    <div style={{position: 'relative'}}>
                        <SwapButton className={`${classes.toggleFromButton} ${nonPrintable}`} onClick={this.toggleFrom}/>
                    </div>
                    <div className={classes.pricingLabels}>
                        <div className={classes.price}>
                            {showPriceAsFrom && (
                                <div className={classes.fromLabel}>
                                    <LocalizedString labelId="from" />
                                </div>
                            )}
                            <div className={classes.fromPrice}>
                                <LocalizedPrice price={localizedProduct.price} />
                            </div>
                        </div>
                        {showPriceAsFrom && (
                            <div className={classes.price}>
                                <div className={classes.asShownLabel}>
                                    <LocalizedString labelId="as_shown" />
                                </div>
                                <PriceInput className={classes.asShownPriceInput} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const styles = () => ({
    container: {
        padding: '0 0.125in',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        height: '100%',
    },
    name: {
        marginTop: '7pt',
        fontFamily: 'eq3-heavy',
        fontSize: '13pt',
    },
    dimensions: {
        fontFamily: 'eq3-book',
        fontSize: '7pt',
        letterSpacing: '0.4pt',
        lineHeight: 'normal',
    },
    description: {
        fontFamily: 'eq3-book',
        fontSize: '8pt',
        lineHeight: 'normal',
    },
    pricing: {
        position: 'absolute',
        bottom: '5px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignSelf: 'flex-end',
        width: '100%',
    },
    fromLabel: {
        fontSize: '8pt',
        whiteSpace: 'nowrap',
    },
    fromPrice: {
        fontSize: '14pt',
        whiteSpace: 'nowrap',
        marginLeft: '10px',
    },
    asShownPriceInput: {
        borderWidth: '0',
        fontSize: '14pt',
        width: '.75in',
        marginLeft: '10px',
    },
    asShownLabel: {
        fontSize: '8pt',
        whiteSpace: 'nowrap',
    },
    price: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: '20px',
        alignItems: 'flex-end',
    },
    toggleFromButton: {
        position: 'absolute',
        transform: 'scale(.5) translateY(-70%)',
    },
    pricingLabels: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
}) as StyleRules;

const mapStateToProps = (state) => ({
    locale: state.priceTags.locale,
});

export default (compose(
    connect(mapStateToProps),
    withStyles(styles),
)(BedroomCaseGoodsTag)) as any as React.ComponentType<IProps>;
