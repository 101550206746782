import { createActionCreator } from 'deox';
import { IAddress, IOrder, IOrderInjectionStatus, IOrderListing, IOrderQuery } from './models';

const createAction: typeof createActionCreator = (type, executor) => createActionCreator(`[ORDERS]\\${type}` as typeof type, executor);

const actions = {
    setOrders: createAction('SET_ORDERS', (resolve) => (list: IOrderListing[], count: number) => resolve({ list, count })),
    setOrder: createAction('SET_ORDER', (resolve) => (order: IOrder) => resolve(order)),
    setOrderAddress: createAction('SET_ORDER_ADDRESS', (resolve) => (orderId: string, address: IAddress) => resolve({ orderId, address })),
    setOrderBillingAddress: createAction('SET_ORDER_BILLING_ADDRESS', (resolve) => (orderId: string, address: IAddress) => resolve({ orderId, address })),
    setOrderInjectionStatus: createAction('SET_ORDER_INJECTION_STATUS', (resolve) => (orderId: string, injectionStatus: IOrderInjectionStatus) => resolve({ orderId, injectionStatus })),
    setOrderSearchFilters: createAction('SET_ORDER_SEARCH_FILTERS', (resolve) => (filters: IOrderQuery) => resolve(filters)),
};

type Actions = typeof actions;
export type OrderActionType = ReturnType<Actions[keyof Actions]>;
export const { setOrders, setOrder, setOrderAddress, setOrderBillingAddress, setOrderInjectionStatus, setOrderSearchFilters } = actions;
export default actions;
