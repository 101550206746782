import { fetchLocationDetails } from '@eq3/redux/locations';
import { ILocationDetails } from '@eq3/redux/locations/models';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { iif, of, Subject, Subscription } from 'rxjs';
import { switchMapTo, tap } from 'rxjs/operators';

const useLocation = (locationId: string) => {
    const dispatch = useDispatch<ThunkDispatch>();
    const [location, setLocation] = useState<ILocationDetails | undefined>(undefined);
    const { current: refresh } = useRef(new Subject<void>());
    const subscription = useRef<Subscription>();

    useEffect(() => {
        subscription.current = refresh.pipe(
            switchMapTo(iif(() => !!locationId, dispatch(fetchLocationDetails(locationId)), of(undefined))),
            tap((l) => setLocation(l)),
        ).subscribe();

        refresh.next();
        return () => subscription.current?.unsubscribe();
    }, [locationId]);

    return useMemo(() => ({
        location,
        refresh: () => refresh.next(),
    }), [location, refresh]);
};

export default useLocation;
