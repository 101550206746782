import { paymentServiceClient } from '@eq3/clients/paymentService/paymentServiceClient';
import { defer } from 'rxjs';
import { map } from 'rxjs/operators';

export const getStripeTerminalConnectionTokenForPlatformLocation = (platformLocationIdentifier: string) => (dispatch, getState) => {
    return defer(() =>
        paymentServiceClient(dispatch, getState)
            .terminalLocationController
            .getConnectionTokenSecret(platformLocationIdentifier)
    ).pipe(map((x) => x.data));
};
