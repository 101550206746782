export interface IRoleGroup {
    id: string;
    name: string;
    description: string;
    locationSecurity: RoleGroupLocationSecurityEnum;
}

export interface IRole {
    id: string;
    name: string;
    description: string;
    requiresAssignedLocation: boolean;
}

export interface IRoleGroupDetails {
    roleGroup: IRoleGroup;
    roles: IRole[];
}

export interface ICreateOrUpdateRoleGroup {
    name: string;
    description: string;
    roleIds: string[];
    locationSecurity: RoleGroupLocationSecurityEnum;
}

export enum RoleGroupLocationSecurityEnum {
    NOT_APPLICABLE = 'NOT_APPLICABLE',
    GRANT_ASSIGNED_LOCATIONS = 'GRANT_ASSIGNED_LOCATIONS',
    GRANT_ALL_LOCATIONS = 'GRANT_ALL_LOCATIONS'
}
