import { useAuth } from '@eq3/redux/auth/hooks';

export default function useFeatureFlags() {
    const { loggedInUser } = useAuth();

    const isUserSquareEnabled =
        !!loggedInUser?.user && process.env.FEATUREFLAG_SQUARE_USERS?.split(',')?.includes(loggedInUser.user.email);

    const isUserAllowedToIssueUnlikedRefunds =
        !!loggedInUser?.user &&
        process.env.FEATUREFLAG_UNLINKED_REFUND_USERS?.split(',')?.includes(loggedInUser.user.email);
    
    const isUserAllowedToUseVirtualTerminal =
        !!loggedInUser?.user &&
        process.env.FEATUREFLAG_VIRTUAL_TERMINAL_USERS?.split(',')?.includes(loggedInUser.user.email);
    
    const isUserAllowedToSeeGiftCardFeatures =
        !!loggedInUser?.user &&
        process.env.FEATUREFLAG_GIFT_CARDS_USERS?.split(',')?.includes(loggedInUser.user.email);

    return {
        SHOW_STRIPE_TERMINAL_SIMULATOR:
            process.env.FEATURE_FLAG_STRIPE_TERMINAL_SIMULATOR_ENABLED?.toLowerCase() === 'true',

        ENABLE_INVENTORY_ORDERS: process.env.FEATUREFLAG_ENABLE_INVENTORY_ORDERS?.toLowerCase() === 'true',

        ENABLE_SQUARE_POS: isUserSquareEnabled || process.env.FEATUREFLAG_ENABLE_SQUARE_POS?.toLowerCase() === 'true',

        ENABLE_UNLINKED_REFUNDS: isUserAllowedToIssueUnlikedRefunds,
        ENABLE_VIRTUAL_TERMINAL: isUserAllowedToUseVirtualTerminal || process.env.FEATUREFLAG_ENABLE_VT?.toLowerCase() === 'true',
        ENABLE_GIFT_CARDS: isUserAllowedToSeeGiftCardFeatures || process.env.FEATUREFLAG_ENABLE_GC?.toLowerCase() === 'true',
    };
}