import { Pagination } from '@eq3/utils/pagination';
import { createActionCreator } from 'deox';
import { ThunkDispatch } from 'redux-thunk';
import { IShippingInventoryOrder, IShippingInventoryOrderListingResponse } from '.';
import { apiThunk as ApiThunk } from '../store';
import { IShippingInventoryOrderIdentifier } from './models/apiModels';
import { IShippingInventoryReduxSlice } from './models/viewModels';

const createOrderActionCreator: typeof createActionCreator = (type, resolver) => createActionCreator(`[ORDERS]\\${type}` as typeof type, resolver);

const actions = {
    setOrders: createOrderActionCreator(
        'SET_ORDERS',
        (resolve) => (data: { orders: Pagination<IShippingInventoryOrder>, orderIdentifiers: IShippingInventoryOrderIdentifier[] }) => resolve(data),
    ),
    setOrderListing: createOrderActionCreator(
        'SET_ORDER_LISTING',
        (resolve) => (data: IShippingInventoryOrderListingResponse) => resolve(data),
    ),
    updateOrderListing: createOrderActionCreator(
        'UPDATE_ORDER_LISTING',
        (resolve) => (data: IShippingInventoryOrderListingResponse) => resolve(data),
    ),
};

export default { ...actions };

export type OrdersActionType = ReturnType<typeof actions[keyof (typeof actions)]>;
export type OrdersDispatch = ThunkDispatch<IShippingInventoryReduxSlice, ApiThunk, OrdersActionType>;
