import qs from 'qs';
import { AnyAction } from 'redux';
import { createActions, handleActions } from 'redux-actions';
import { ThunkAction } from 'redux-thunk';
import { defaultPageSize, Pagination } from './../../utils/pagination';
import { errorNotification, infoNotification, notify, successNotification } from './../adminNotifications';
import { apiThunk } from './../store';
import {
    Fields,
    IFilters,
    IItemOptionGrades,
    IQuery,
    IRecord,
    IUpholsteryItemOption,
    IUpholsteryOption,
    IUpholsteryOptionImage,
} from './models';

const initialState = {
    params: {
        itemId: '',
    },
    table: {
        records: new Pagination<IRecord>(0, defaultPageSize, 0, new Array<IRecord>()),
        currentQuery: {
            pageIndex: 0,
            pageSize: defaultPageSize,
            sortKey: Fields.Item,
            sortDirection: 'asc',
            isActive: true,
            isDeleted: false,
        } as Partial<IQuery>,
        loading: true,
    },
    filters: {
        filters: {
            searchTerm: '',
            items: [] as string[],
            erpOptionCodes: [] as string[],
            optionGroupCodes: [] as string[],
            itemClasses: [] as string[],
            isActive: true,
            isDeleted: false,
        } as IFilters,
        loading: true,
    },
    form: {
        open: false,
        currentRecord: { } as IRecord,
        submitting: false,
        optionGrades: {} as IItemOptionGrades,
        currentOption: undefined as any as IUpholsteryOption,
    },
};

export type UpholsteryItemOptionsState = typeof initialState;
type GetState = () => ({ upholsteryItemOptions: UpholsteryItemOptionsState });
export type ReduxState = ReturnType<GetState>;

const actionCreators = createActions({
    PARAMS: {
        SET_ITEM_ID: (itemId: string) => itemId,
    },
    TABLE: {
        FETCH_RECORDS: (itemId: string, records: Pagination<IRecord>) => ({ itemId, records }),
        REFRESH_RECORDS: (records: Pagination<IRecord>) => records,
        SET_CURRENT_QUERY: (query: IQuery) => query,
        LOADING: (loading: boolean) => loading,
    },
    FILTERS: {
        FETCH_FILTERS: (filters: IFilters) => filters,
        LOADING: (loading: boolean) => loading,
    },
    FORM: {
        OPEN: () => true,
        CLOSE: () => false,
        TOGGLE: () => ({ }),
        CURRENT: (record?: IRecord) => record,
        SUBMIT: (record: IRecord) => record,
        SUBMITTING: (submitting: boolean) => submitting,
        SET_ITEM_GRADES: (optionGrades: IItemOptionGrades) => optionGrades,
        SET_CURRENT_OPTION: (option?: IUpholsteryOption) => option,
    },
});

export const fetchUpholsteryItemOptions = (query: Partial<IQuery>): ThunkAction<Promise<{ data: Pagination<IUpholsteryItemOption> }>, any, apiThunk, AnyAction> => async (dispatch, getState, api) => {
    return await api(dispatch, getState, `/admin/upholstery/item/options`, 'GET', null, {
        params: {
            ...query,
        },
        paramsSerializer: (params: any) => {
            return qs.stringify(params, {
                arrayFormat: 'repeat',
            });
        },
    });
};

export const fetchRecords = (itemId?: string) => async (dispatch, getState: GetState) => {
    const { fetchRecords: fetch, loading: fetching } = actionCreators.table;
    dispatch(fetching(true));

    try {
        const { upholsteryItemOptions: { table } } = getState();
        const { upholsteryItemOptions: { params }} = getState();
        const { currentQuery: query } = table;
        itemId = itemId || params.itemId;

        const { data }: { data: Pagination<IRecord> } = await dispatch(fetchUpholsteryItemOptions({
            ...query,
            items: itemId ? [itemId] : (query && query.items),
        }));

        dispatch(fetch(itemId, data));
    } catch (e: any) {
        console.error(e);
        dispatch(notify(errorNotification(e.message, e)));
    }

    dispatch(fetching(false));
};

export const refreshRecords = () => async (dispatch, getState: GetState, api: apiThunk) => {
    const { refreshRecords: refresh, loading: refreshing } = actionCreators.table;

    dispatch(refreshing(true));

    try {
        const { upholsteryItemOptions: { table } } = getState();
        const { upholsteryItemOptions: { params } } = getState();
        const { currentQuery: query } = table;
        const { itemId } = params;

        const { data }: { data: Pagination<IRecord> } = await api(dispatch, getState, `/admin/upholstery/item/options`, 'GET', null, {
            params: {
                ...query,
                items: itemId ? [itemId] : query.items,
            },
            paramsSerializer: (params: any) => {
                return qs.stringify(params, {
                    arrayFormat: 'repeat',
                });
            },
        });

        dispatch(refresh(data));
    } catch (e: any) {
        console.error(e);
        dispatch(notify(errorNotification(e.message, e)));
    }

    dispatch(refreshing(false));
};

export const setCurrentQuery = actionCreators.table.setCurrentQuery;

export const fetchFilters = () => async (dispatch, getState: GetState, api: apiThunk) => {
    const { fetchFilters: fetch, loading: fetching } = actionCreators.filters;
    dispatch(fetching(true));
    try {
        const { data } = await api(dispatch, getState, `/admin/upholstery/item/options/filters`, 'GET');
        dispatch(fetch(data));
    } catch (e: any) {
        console.error(e);
        dispatch(notify(errorNotification(e.message, e)));
    }

    dispatch(fetching(false));
};

export const openForm = actionCreators.form.open;
export const closeForm = actionCreators.form.close;
export const toggleForm = actionCreators.form.toggle;
export const setCurrentFormRecord = actionCreators.form.current;
export const setCurrentOptionRecord = actionCreators.form.setCurrentOption;

export const submitForm = (form: IRecord) => async (dispatch) => {
    dispatch(updateItemOptions([form]));
};

export const updateItemOptions = (records: IRecord[]) => async (dispatch, getState: GetState, api: apiThunk) => {
    const { submitting, submit } = actionCreators.form;

    dispatch(submitting(true));

    try {

        const santizedRecords = records.map((record) => ({
            ...record,
            [Fields.OptionGroupCode]: record[Fields.OptionGroupCode] || undefined,
            [Fields.ActiveStartDate]: record[Fields.ActiveStartDate] || undefined,
            [Fields.ActiveEndDate]: record[Fields.ActiveEndDate] || undefined,
        }));
        const { status } = await api(dispatch, getState, `/admin/upholstery/item/options`, 'POST', santizedRecords);

        if (status === 200) {
            dispatch(submit(records));
            dispatch(notify(successNotification('Item Options saved successfully')));
            dispatch(fetchRecords());
        } else {
            throw new Error('Error saving Item Options');
        }
    } catch (e: any) {
        dispatch(notify(errorNotification(e.message, e)));
        throw e;
    }

    dispatch(submitting(false));
};

export const submitItemOptionGrades = (grades: IItemOptionGrades) => async (dispatch, getState: GetState, api: apiThunk) => {
    try {
        await api(dispatch, getState, `/admin/upholstery/item/${grades.item}/options/${grades.optionCode}/grades`, 'PUT', grades);

        dispatch(actionCreators.form.setItemGrades(grades));
        dispatch(notify(successNotification('Item Option Grades Saved!')));
    } catch (e) {
        dispatch(notify(errorNotification('Error saving item option grades', e)));
        throw e;
    }
};
export const fetchItemOptionGrades = (item: string, option: string) => async (dispatch, getState: GetState, api: apiThunk) => {
    try {
        const {data} = await api(dispatch, getState, `/admin/upholstery/item/${item}/options/${option}/grades`);

        dispatch(actionCreators.form.setItemGrades(data));
    } catch (e) {
        dispatch(notify(errorNotification('Error retrieving item option grades', e)));
        throw e;
    }
};

export const fetchOption = (optionCode: string) =>  async (dispatch, getState: GetState, api: apiThunk) => {
    try {
        const {data}: { data: IUpholsteryOption } = await api(dispatch, getState, `/admin/upholstery/options/${optionCode}`, 'GET');
        return data;

    } catch (e) {
        console.error(e);
        dispatch(notify(errorNotification('Error getting Upholstery Options', e)));
        throw e;
    }

};

export const saveOptionImageOrder = (optionCode: string, sortedImages: IUpholsteryOptionImage[]) => async (dispatch, getState: GetState, api: apiThunk) => {
    try {
        await api(dispatch, getState, `admin/upholstery/options/${optionCode}/images/sort`, 'PUT', {
            sortedKeys: sortedImages.map(({key}) => key),
        });
    } catch (e) {
        console.error(e);
        dispatch(notify(errorNotification('Error saving sorted images', e)));
        throw e;
    }
};

export const saveUpholsteryOption = (option: IUpholsteryOption) => async (dispatch, getState: GetState) => {
    await dispatch(saveOptionImageOrder(option.erpOptionCode, option.images));
    dispatch(notify(successNotification('Saved Upholstery Option!')));

    const currentOption = getState().upholsteryItemOptions.form.currentOption;

    if (currentOption && currentOption.erpOptionCode === option.erpOptionCode) {
        const data = await dispatch(fetchOption(option.erpOptionCode));
        setCurrentOptionRecord(data);
    }
};

export const uploadOptionImage = (optionCode: string, file: File) => async (dispatch, getState: GetState, api: apiThunk) => {
    try {

        if (file) {
            dispatch(notify(infoNotification('Uploading image...')));
            const formData = new FormData();
            formData.append('file', file);

            const {data}: {data: IUpholsteryOptionImage} = await api(dispatch, getState, `/admin/upholstery/options/${optionCode}/images`, 'POST', formData);

            const state = getState().upholsteryItemOptions.form;
            if (state.currentOption !== undefined && state.currentOption.erpOptionCode === optionCode) {
                const option = state.currentOption;
                const updatedOption = {...option, images: [...option.images, data]};

                dispatch(actionCreators.form.setCurrentOption(updatedOption));
            }
            dispatch(notify(successNotification('Image uploaded!')));
        }
    } catch (e) {
        dispatch(notify(errorNotification('Error uploading Image!', e)));
        throw e;
    }
};

const reducers = handleActions({
    [actionCreators.params.setItemId]: (state = initialState, { payload: itemId }) => ({...state, params: { itemId }}),
    [actionCreators.table.loading]: (state = initialState, { payload: loading }) => ({...state, table: { ...state.table, loading }}),
    [actionCreators.table.fetchRecords]: (state = initialState, { payload: { itemId, records }}) => ({ ...state, table: { ...state.table, records }, [itemId]: records }),
    [actionCreators.table.refreshRecords]: (state = initialState, { payload: records }) => ({ ...state, table: { ...state.table, records }}),
    [actionCreators.table.setCurrentQuery]: (state = initialState, { payload: query }) => ({
        ...state,
        table: {
            ...state.table,
            currentQuery: {
                ...state.table.currentQuery,
                ...query,
            },
        },
    }),
    [actionCreators.filters.fetchFilters]: (state = initialState, { payload: filters }) => ({
        ...state,
        filters: {
            ...state.filters,
            filters,
        },
    }),
    [actionCreators.filters.loading]: (state = initialState, { payload: loading }) => ({...state, filters: { ...state.filters, loading }}),
    [actionCreators.form.open]: (state = initialState, { payload: open }) => ({...state, form: { ...state.form, open }}),
    [actionCreators.form.close]: (state = initialState, { payload: close }) => ({...state, form: { ...state.form, open: close }}),
    [actionCreators.form.toggle]: (state = initialState) => ({...state, form: { ...state.form, open: !state.form.open }}),
    [actionCreators.form.current]: (state = initialState, { payload: currentRecord }) => ({...state, form: { ...state.form, currentRecord }}),
    [actionCreators.form.submit]: (state = initialState, { payload: form }) => ({...state, form: { ...state.form, form }}),
    [actionCreators.form.submitting]: (state = initialState, { payload: submitting }) => ({...state, form: { ...state.form, submitting }}),
    [actionCreators.form.setItemGrades]: (state = initialState, { payload: optionGrades}) => ({...state, form: { ...state.form, optionGrades}}),
    [actionCreators.form.setCurrentOption]: (state = initialState, {payload: currentOption}) => ({...state, form: {...state.form, currentOption}}),
}, initialState);

export default reducers;
