import { Pagination } from '@eq3/utils/pagination';
import { createActions } from 'redux-actions';
import { IEntity, IEntityTag, ITag, ITagGroup, TaggingSearch } from './models';

export const { setTags, setGroups, setAllGroupsForSearch, setSearchParams, setAssociableTagsList, setTagsAssociatedToEntity } = createActions({
    SET_TAGS: (tags: Pagination<ITag>) => tags,
    SET_GROUPS: (groups: Pagination<ITagGroup>) => groups,
    SET_ALL_GROUPS_FOR_SEARCH: (groups: ITagGroup[]) => groups,
    SET_SEARCH_PARAMS: (params: Partial<TaggingSearch>) => params,
    SET_ASSOCIABLE_TAGS_LIST: (entityTags: IEntityTag[]) => entityTags,
    SET_TAGS_ASSOCIATED_TO_ENTITY: (tagsAssociated: IEntity[]) => tagsAssociated,
}, { prefix: '[TAGGING]' });
