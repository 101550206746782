import { SquareRefundRecordDto, SquareTerminalTransactionDetailsDto } from '@eq3-aws/payments-client';
import ReceiptField from '@eq3/containers/pos-square/widgets/receipts/ReceiptField';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import React, { useMemo } from 'react';
import { default as receiptTranslations, Language } from '../translations';

export interface IRefundReceiptDataProps {
    transactionDetails: SquareTerminalTransactionDetailsDto;
    refund: SquareRefundRecordDto;
    language: Language;
}

const RefundReceiptData = ({ transactionDetails, refund, language }: IRefundReceiptDataProps) => {
    const {
        location,
        chargeRefundPositionReference,
        amount: refundedAmount,
        currency,
        refundedAt,
        cashierName,
        status,
    } = refund;

    const { storisReference, referenceNumber, squarePaymentCheckoutDetailsDto } = transactionDetails;

    const { chargeData } = squarePaymentCheckoutDetailsDto ?? {};

    const { cardData, paymentReceiptData } = chargeData ?? {};

    const { lastFourDigits, cardBrand, isInterac } = cardData ?? {};

    const {
        // accountType,
        aid,
        applicationName,
    } = paymentReceiptData ?? {};

    const classes = useStyles();
    const translations = receiptTranslations[language ?? Language.EN];

    const locationName = location?.name?.split('-')[1]?.trim() ?? 'EQ3';

    const dateAndTime = useMemo(() => {
        const jsDate = new Date(refundedAt);
        return {
            time: jsDate.toLocaleTimeString(),
            date: jsDate.toLocaleDateString(),
        };
    }, [refundedAt]);

    const cardNumber = useMemo(() => {
        return lastFourDigits ? `**** **** **** ${lastFourDigits}` : undefined;
    }, [lastFourDigits]);

    const getFormattedAmt = () => {
        return language === Language.FR
            ? `${refundedAmount?.toFixed(2)} $ ${currency}`
            : `$ ${refundedAmount?.toFixed(2)} ${currency}`;
    };

    const renderCardOrAccountTypeLine = (isInterac?: boolean, cardBrand?: string, accountType?: string) => {
        if (!!isInterac) {
            return (
                <>
                    {accountType && accountType !== 'unknown' && (
                        <ReceiptField fieldValue={accountType} seperator="" data-cy="refundReceiptCardType" />
                    )}
                </>
            );
        } else {
            return (
                <>
                    {cardBrand && cardBrand !== 'unknown' && (
                        <ReceiptField fieldValue={cardBrand} seperator="" data-cy="refundReceiptCardType" />
                    )}
                </>
            );
        }
    };

    const getReceiptFooterMessage = () => {
        if (status && status !== 'COMPLETED') {
            return (
                <div className={classnames(classes.centerTextContainer, classes.mt16)}>
                    <pre data-cy="refundReceiptFooterText">{translations.completionApproved}</pre>
                </div>
            );
        }
        return (
            <div className={classnames(classes.centerTextContainer, classes.mt16)}>
                <pre data-cy="refundReceiptFooterText">
                    {translations.refundSubmitted}
                    <sup>*</sup> - {translations.thankyou}
                </pre>
                <br />
                <pre style={{ fontSize: 10, textAlign: 'justify' }}>
                    <sup>*</sup>
                    {translations.refundSubmittedSubtextPart1}
                    {` `}
                    <span style={{ whiteSpace: 'nowrap' }}>+1(888) 988-2014</span>
                    {` `}
                    {translations.refundSubmittedSubtextPart2}
                </pre>
            </div>
        );
    };

    return (
        <div className={classes.root}>
            <div className={classes.centerTextContainer}>
                {!location ? (
                    <pre>EQ3</pre>
                ) : (
                    <>
                        <pre data-cy="refundReceiptLocationName">{locationName}</pre>
                        {location?.address && (
                            <>
                                <pre>{location?.address?.addressLine1}</pre>
                                {location?.address?.addressLine2 && <pre>{location?.address?.addressLine2}</pre>}
                                <pre>
                                    {location?.address?.city}, {location?.address?.province}{' '}
                                    {location.address?.postalCode}
                                </pre>
                            </>
                        )}
                    </>
                )}
            </div>

            <div className={classnames(classes.centerTextContainer, classes.mt16)}>
                <pre>
                    {dateAndTime.date}&nbsp;&nbsp;&nbsp;{dateAndTime.time}
                </pre>
            </div>

            <div className={classes.mt16}>
                <ReceiptField
                    fieldName={translations.txOrderNumber}
                    fieldValue={storisReference?.toUpperCase()}
                    data-cy="refundReceiptOrderNumber"
                />
                <ReceiptField
                    fieldName={translations.txReference}
                    fieldValue={`${referenceNumber.toString().padStart(10, '0')}-${chargeRefundPositionReference}`}
                />
                <ReceiptField fieldName={translations.txCashier} fieldValue={cashierName} />
                <ReceiptField fieldName={translations.txType} fieldValue={translations.txTypeRefund} />
            </div>

            {/* Amount */}
            <div className={classes.mt16}>
                <ReceiptField
                    fieldName={translations.txAmount}
                    fieldValue={getFormattedAmt()}
                    data-cy="refundReceiptAmount"
                />
            </div>

            <div className={classes.mt16}>
                {renderCardOrAccountTypeLine(isInterac, cardBrand /*accountType*/)}
                <ReceiptField fieldName={translations.cardNumber} fieldValue={cardNumber} />
            </div>

            <div className={classes.mt16}>
                <ReceiptField fieldValue={applicationName} data-cy="refundReceiptApplicationName" />
                <ReceiptField fieldValue={aid} />
            </div>

            {getReceiptFooterMessage()}
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        '& pre': {
            margin: 0,
            whiteSpace: 'normal',
        },
    },
    mt16: {
        marginTop: theme.spacing(2),
    },
    centerTextContainer: {
        textAlign: 'center',
    },
}));

export default RefundReceiptData;
