import locales, { Translation } from '@eq3/utils/locales';
import { createActions, handleActions } from 'redux-actions';
import { ThunkDispatch } from 'redux-thunk';
import { errorNotification, notify, successNotification } from './../adminNotifications';
import { apiThunk } from './../store';
import { IPieceCode } from './models';

export const blankPieceCodeObj: IPieceCode = {
    pieceCode: '',
    description: '',
    translations: locales.reduce((acc, cur) => ({ ...acc, [cur]: '' }), {} as Translation<string>),
};

const initialState = {
    selected: blankPieceCodeObj,
    listItems: new Array<IPieceCode>(),
};

type State = typeof initialState;
export interface IUpholsteryPieceCodeState { upholsteryPieceCodes: State; }
type GetState = () => IUpholsteryPieceCodeState;

const { pieceCodes } = createActions({
    PIECE_CODES: {
        SET_SELECTED: (pieceCode: IPieceCode) => pieceCode,
        SET_LIST_ITEMS: (listItems: IPieceCode[]) => listItems,
    },
});

const handleError = (dispatch, e, fallbackMessage) => {
    console.error(e);
    const { data: { message } = { message: '' } } = e;
    dispatch(notify(errorNotification(message || fallbackMessage, e)));

    throw e;
};

export const setSelected = pieceCodes.setSelected;

export const fetchListItems = () => async (dispatch: ThunkDispatch<State, undefined, any>, getState: GetState, api: apiThunk) => {
    try {
        const { data } = await api(dispatch, getState, `/admin/upholstery/piece-codes/list-items`, 'GET');
        dispatch(pieceCodes.setListItems(data));
    } catch (e) {
        handleError(dispatch, e, 'Error retrieving piece codes');
    }
};

export const fetchPieceCode = (code: string) => async (dispatch: ThunkDispatch<State, undefined, any>, getState: GetState, api: apiThunk) => {
    const { listItems } = getState().upholsteryPieceCodes;

    const selectedPieceCode = listItems.find(({ pieceCode }) => pieceCode === code);

    if (selectedPieceCode && selectedPieceCode.translations) {
        dispatch(setSelected(selectedPieceCode));
    } else {
        try {
            const { data } = await api(dispatch, getState, `/admin/upholstery/piece-codes/${code}`, 'GET');
            dispatch(setSelected(data));

            if (listItems.length) { // update only if the list items are already populated
                const updateListItems = [...listItems];
                updateListItems[updateListItems.findIndex(({ pieceCode }) => pieceCode === data.pieceCode)] = data;
                dispatch(pieceCodes.setListItems(updateListItems));
            }
        } catch (e) {
            handleError(dispatch, e, 'Error retrieving piece code');
        }
    }
};

export const updatePieceCode = (pieceCode: IPieceCode) => async (dispatch: ThunkDispatch<State, undefined, any>, getState: GetState, api: apiThunk) => {
    try {
        await api(dispatch, getState, `/admin/upholstery/piece-codes/${pieceCode.pieceCode}`, 'PUT', pieceCode);
        dispatch(setSelected(pieceCode));

        const { upholsteryPieceCodes: { listItems } } = getState();
        const updateListItems = [...listItems];
        updateListItems[updateListItems.findIndex((li) => li.pieceCode === pieceCode.pieceCode)] = pieceCode;
        dispatch(pieceCodes.setListItems(updateListItems));

        dispatch(notify(successNotification('Piece code updated successfully!')));
    } catch (e) {
        handleError(dispatch, e, 'Error updating piece code');
    }
};

export default handleActions({
    [pieceCodes.setSelected]: (state: State = initialState, { payload: selected }) => ({ ...state, selected }),
    [pieceCodes.setListItems]: (state: State = initialState, { payload: listItems }) => ({ ...state, listItems }),
}, initialState);
