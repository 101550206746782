import ImageUploader, { IImageUploaderProps } from '@eq3/component/ImageUploader';
import { makeStyles, Typography } from '@material-ui/core';
import { connect, getIn } from 'formik';
import React, { PropsWithChildren, useEffect } from 'react';

export interface IDefaultState {
    imageUrl: Nullable<string>;
    file: Nullable<File>;
}

export interface IImageUploaderFieldProps<T = IDefaultState> extends Omit<IImageUploaderProps, 'imageUrl' | 'classes'> {
    name: string;
    getUrl: (state: T) => Nullable<string>;
    validate?: (value: T) => Optional<string> | Promise<Optional<string>> | undefined;
    mapTo?: (value: IDefaultState, previousState: T) => T;
}

const useStyles = makeStyles((theme) => ({
    errorMsg: {
        color: theme.palette.error.main,
        marginTop: theme.spacing(2),
    },
}));

const ImageUploaderField = connect<IImageUploaderFieldProps, any>((props) => {
    const { 
        name,
        validate,
        formik,
        getUrl,
        onDelete: _onDelete,
        onImageUpload: _onImageUpload,
        mapTo,
        children,
        ...rest
    } = props;

    const classes = useStyles();

    useEffect(() => {
        if (typeof validate !== 'function') return;

        formik.registerField(name, new React.Component({ validate }));
        return () => formik.unregisterField(name);
    }, [validate]);

    const value = getIn(formik.values, name);
    const errorMsg = getIn(formik.errors, name);

    const onImageUpload: typeof _onImageUpload = (e, previewUrl, file) => {
        formik.setFieldValue(
            name, 
            (mapTo?.({ imageUrl: previewUrl, file }, value) ?? { imageUrl: previewUrl, file }),
        );
    
        _onImageUpload?.(e, previewUrl, file);
    };

    const onDelete: typeof _onDelete = (e) => {
        formik.setFieldValue(
            name,
            (mapTo?.({ imageUrl: null, file: null }, value) ?? { imageUrl: null, file: null }),
        );

        _onDelete?.(e);
    };

    return (
        <ImageUploader {...rest}
            imageUrl={getUrl(value)}
            onImageUpload={onImageUpload}
            onDelete={onDelete}>
            {children}
            {errorMsg && (<Typography className={classes.errorMsg}>{errorMsg}</Typography>)}
        </ImageUploader>
    );
});

export default ImageUploaderField as (<T extends any>(props: PropsWithChildren<IImageUploaderFieldProps<T>>) => React.ReactElement);
