import { Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import ActionButton from './ActionButton';

const LoadingFrame = (props: { msg?: string, actionBtn?: { label: string, action: () => void } }) => {
    const classes = useStyles();
    const { msg, actionBtn } = props;

    return (
        <div className={classes.loadingFrame}>
            <CircularProgress color="primary" size={64} />
            {!!msg && (
                <>
                    <br />
                    <Typography className={classes.msgDisplay}>{msg}</Typography>
                </>
            )}
            {!!actionBtn && (
                <>
                    <br />
                    <ActionButton
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={actionBtn.action}
                    >
                        {actionBtn.label}
                    </ActionButton>
                </>
            )}
        </div>
    );
};

const useStyles = makeStyles(() => ({
    loadingFrame: {
        flex: 1,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    msgDisplay: {
        fontSize: 16,
        maxWidth: 300,
        textAlign: 'center',
        wordBreak: 'normal',
    }
}));

export default LoadingFrame;
