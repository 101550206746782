import { createActions, handleActions } from 'redux-actions';
import { errorNotification, notify } from './adminNotifications';
import { apiThunk } from './store';

export interface IGoogleCategory {
    id: number;
    category: string;
}

export const { setGoogleCategories, setGoogleCategory } = createActions({
    SET_GOOGLE_CATEGORIES: (categories) => ({ categories }),
});

export default handleActions({
    [setGoogleCategories]: (state, { payload: { categories } }) => ({ ...state, categories }),
}, { loading: true, categories: [] });

export const fetchGoogleCategories = (query) => async (dispatch, getStore, api: apiThunk) => {
    try {
        const {data} = await api(dispatch, getStore, '/admin/marketing/googlecategories', 'GET', undefined, {
            params: { query },
        });

        dispatch(setGoogleCategories(data));
    } catch (e) {
        dispatch(notify(errorNotification('Error retrieving google categories', e)));
        throw e;
    }
};

export const initGoogleCategories = (id) => async (dispatch, getStore, api: apiThunk) => {
    try {
        const categories = [];
        const {data} = await api(dispatch, getStore, `/admin/marketing/googlecategories/${id}`, 'GET');
        categories.push(data);
        dispatch(setGoogleCategories(categories));
    } catch (e) {
        dispatch(notify(errorNotification('Error retrieving google categories', e)));
        throw e;
    }
};
