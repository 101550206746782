import LocalizedString from '@eq3/containers/retail/priceTags/components/LocalizedString';
import { StyleRules, WithStyles } from '@material-ui/core/styles';
import React from 'react';

import {LockButton} from '@eq3/containers/retail/priceTags/components/Buttons';
import AutosizeInput from 'react-input-autosize';
import { compose } from 'redux';
import PriceInput from '../../../components/PriceInput';

import nonPrintable from '@eq3/containers/retail/priceTags/util/nonPrintable';
import { withStyles } from '@material-ui/core';

interface IState {
    regularPricingDescription: string;
    salePricingDescription: string;
    name: string;
    description: string;
    lockWording: ILockWording;
}

interface ILockWording {
    regularPrice: boolean;
    salePrice: boolean;
}

class FillableSaleShelfTalkerTag extends React.Component<WithStyles<ReturnType<typeof styles>>, IState> {
    public state = {
        regularPricingDescription: '',
        salePricingDescription: '',
        name: '',
        description: '',
        lockWording: {
            regularPrice: true,
            salePrice: true,
        },
    };

    private handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    private toggleLock = (lock: keyof ILockWording) => () => {
        this.setState(({lockWording}) => ({lockWording: {...lockWording, [lock]: !lockWording[lock]}}));
    }

    public render() {
        const {classes} = this.props;
        const {name, description, regularPricingDescription, salePricingDescription, lockWording} = this.state;

        return (
            <div className={classes.container}>
                <input className={classes.collectionName} type="text" name="name" value={name}
                       onChange={this.handleChange}/>
                <div className={classes.productContainer}>
                    <textarea className={classes.descriptionInput} name="description" value={description}
                              onChange={this.handleChange}/>
                    <div className={classes.pricing}>
                        <div className={classes.regularPricing}>
                            {lockWording.regularPrice
                                ? (<div className={classes.regularPricingDescription}><LocalizedString labelId="was"/></div>)
                                : (
                                    <AutosizeInput
                                        inputClassName={classes.regularPricingDescription}
                                        name="regularPricingDescription"
                                        value={regularPricingDescription}
                                        onChange={this.handleChange}
                                    />
                                )
                            }
                            <PriceInput className={classes.regularPriceInput}/>
                            <LockButton
                                className={`${classes.lockButton} ${nonPrintable}`}
                                onClick={this.toggleLock('regularPrice')}
                                />
                        </div>
                        <div className={classes.salePricing}>
                            {lockWording.salePrice
                                ? (<div className={classes.salePricingDescription}><LocalizedString labelId="now"/></div>)
                                : (
                                    <AutosizeInput
                                        inputClassName={classes.salePricingDescription}
                                        name="salePricingDescription"
                                        value={salePricingDescription}
                                        onChange={this.handleChange}
                                    />
                                )
                            }
                            <PriceInput className={classes.salePriceInput}/>
                            <LockButton
                                className={`${classes.lockButton} ${nonPrintable}`}
                                onClick={this.toggleLock('salePrice')}
                                />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const styles = () => ({
    collectionName: {
        marginTop: '9pt',
        fontFamily: 'eq3-book',
        fontSize: '23pt',
        lineHeight: '23pt',
        textTransform: 'uppercase',
    },
    container: {
        padding: '0 0.125in',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        height: '100%',
        '& input, textarea': {
            borderWidth: '0px',
        },
    },
    productContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        height: '100%',
        paddingBottom: '10px',
        marginTop: '3px',
    },
    descriptionInput: {
        fontFamily: 'eq3-book',
        fontSize: '11pt',
        lineHeight: 'normal',
        width: '100%',
        resize: 'none',
        overflow: 'hidden',
        height: '100%',
        flex: '1 1 40%',
        marginRight: '10px',
    },
    pricing: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'eq3-book',
        flex: '1 1 60%',
    },
    lockButton: {
        position: 'absolute',
        right: -10,
        transform: 'scale(.5) translate(150%, -75%)',
    },
    regularPricing: {
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        justifyContent: 'space-between',
    },
    regularPricingDescription: {
        minWidth: '10px',
        fontFamily: 'eq3-book',
        fontSize: '11pt',
        textTransform: 'uppercase',
        flex: '1 1 50%',
    },
    regularPriceInput: {
        textDecoration: 'line-through',
        fontFamily: 'eq3-book',
        fontSize: '11pt',
        textAlign: 'right',
        width: '36%',
    },
    salePricing: {
        display: 'flex',
        flexDirection: 'row',
        alignSelf: 'flex-end',
        position: 'relative',
        justifyContent: 'space-between',
    },
    salePricingDescription: {
        whiteSpace: 'nowrap',
        fontFamily: 'eq3-book',
        fontSize: '11pt',
        minWidth: '10px',
        textTransform: 'uppercase',
        flex: '1 1 50%',
    },
    salePriceInput: {
        fontFamily: 'eq3-book',
        fontSize: '11pt',
        textAlign: 'right',
        width: '36%',
    },

}) as StyleRules;

export default (compose(
    withStyles(styles),
)(FillableSaleShelfTalkerTag)) as any as React.ComponentType;
