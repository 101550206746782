import { useEffect, useRef } from 'react';

export default function useDidUpdateEffect(fn: React.EffectCallback, deps?: any[]) {
    const didMountRef = useRef(false);

    useEffect(() => {
        if (didMountRef.current) {
            fn();
        } else {
            didMountRef.current = true;
        }
    }, deps);
}
