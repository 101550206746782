import { StyleRules, WithStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';

import { CircularProgress, withStyles } from '@material-ui/core';
import { compose } from 'redux';
import { fetchMediumTagData } from '../../../../redux/priceTags';
import { mediumTagDataSelector, mediumTagMenuSelector } from '../../../../reselect/priceTags';
import MediumPartialTag from './tags/medium/MediumPartialTag';

class MediumPage extends React.Component<WithStyles<ReturnType<typeof styles>> & ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>> {

    public componentDidMount() {
        const { mediumTagData } = this.props;
        if (!mediumTagData) {
            this.props.fetchData();
        }
    }

    public render(): JSX.Element {
        const { classes, menu } = this.props;

        if (!menu) {
            return (
                <div className={classes.page}>
                    <CircularProgress
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                        }}
                    />
                </div>
            );
        }

        return (
            <div className={classes.page}>
                <MediumPartialTag menu={menu} />
                <MediumPartialTag menu={menu} />

                <MediumPartialTag menu={menu} />
                <MediumPartialTag menu={menu} />

                <MediumPartialTag menu={menu} />
                <MediumPartialTag menu={menu} />

                <MediumPartialTag menu={menu} />
                <MediumPartialTag menu={menu} />

                <MediumPartialTag menu={menu} />
                <MediumPartialTag menu={menu} />
            </div>
        );
    }
}

const styles = (theme) => ({
    page: {
        width: '8.5in',
        height: '11in',
        padding: '0.5in 0.125in',
        display: 'flex',
        flexWrap: 'wrap',

    },
}) as StyleRules;

const mapStateToProps = (state) => ({
    menu: mediumTagMenuSelector(state),
    mediumTagData: mediumTagDataSelector(state),
});
const mapDispatchToProps = (dispatch) => ({
    fetchData: () => {
        dispatch(fetchMediumTagData());
    },
});

export default (compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
)(MediumPage));
