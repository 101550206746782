import { ThunkResult } from 'redux-thunk';
import { Observable } from 'rxjs';
import { MenuAction } from './../actions';
import { ICategory, ICategoryListItem, IMenu, IProductLine, IProductLineListItem, ISubcategory, ISubcategoryListItem } from './api';

export const SITE_NAVIGATION_REDUX_KEY = 'siteNavigation';

export interface IMenuState extends IMenu {
    cache: {
        categories: Record<string, ICategory>;
        subcategories: Record<string, ISubcategory>;
        productLines: Record<string, IProductLine>;
    };
}

export interface IMenuReduxSlice {
    [SITE_NAVIGATION_REDUX_KEY]: IMenuState;
}

export type MenuThunkResult<T> = ThunkResult<Observable<T>, IMenuReduxSlice, MenuAction>;

export type ProductLineNode = IProductLineListItem & {
    type: 'productline';
};

export type SubcategoryNode = ISubcategoryListItem & { 
    type: 'subcategory';
    productLines: ProductLineNode[]
};

export type CategoryNode = ICategoryListItem & {
    type: 'category';
    subcategories: SubcategoryNode[];
};

export type MenuTree = CategoryNode[];

export interface INavItem {
    category: ICategoryListItem;
    subcategory?: ISubcategoryListItem;
    productLine?: IProductLineListItem;
}
