import { lazy } from 'react';

interface ILazyLoadDictionary {
    [key: string]: ReturnType<typeof lazy>;
}

const loadedComponentsDictionary: ILazyLoadDictionary = {};

/**
 * Utility which allows us to cache lazy-loaded react components. Lazy mounted components are not memoized, so they
 * must be recreated when unmounted (e.g. if we revisit a page, we'd expect that page to have already been created
 * and loading that page should be instantaneous).
 */
const useCachedLazyLoader = () => {
    const cachedComponentLoad = (key: string, factory: () => Promise<any>) => {
        if (!loadedComponentsDictionary[key]) {
            loadedComponentsDictionary[key] = lazy(factory);
        }
        return loadedComponentsDictionary[key];
    };
    return {
        load: (key: string, callToImport: () => Promise<any>) => cachedComponentLoad(key, callToImport),
    };
};

export default useCachedLazyLoader;
