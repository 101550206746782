import { errorNotification, notify, successNotification } from '@eq3/redux/adminNotifications';
import { Pagination } from '@eq3/utils/pagination';
import { AxiosError } from 'axios';
import { ThunkResult } from 'redux-thunk';
import { defer, from, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import actions, { CustomersActiontype } from './actions';
import {
    IAutoSuggestableFields,
    ICustomerSearchSuggestion,
    ICustomersReduxSlice,
    IUpdateCustomerDetails,
} from './models';
import {
    ICustomerAddress,
    ICustomerDemographic,
    ICustomerDetails,
    ICustomerListItem,
    ICustomersQuery,
    ISaveCustomerDemographic,
} from './models/apiModels';

type CustomersThunkResult<T> = ThunkResult<Observable<T>, ICustomersReduxSlice, CustomersActiontype>;
const { setCustomerDetails, setCustomerDemographic, setCustomerAddresses } = actions;

export const fetchCustomers = (query: ICustomersQuery): CustomersThunkResult<Pagination<ICustomerListItem>> => (dispatch, getState, api) => {
    const cleanedQuery = {...query, orderNumber: query?.orderNumber && Number.parseInt(query.orderNumber)};
    Object.keys(cleanedQuery).forEach((key) => !cleanedQuery[key] && delete cleanedQuery[key]);
    return from(api<Pagination<ICustomerListItem>>(dispatch, getState, '/admin/customers', 'GET', undefined, {
        params: cleanedQuery,
    })).pipe(
        map((response) => response.data),
    );
};

export const fetchCustomerDetails = (customerId: string): CustomersThunkResult<ICustomerDetails> => (dispatch, getState, api) => {
    const { customers } = getState();
    const customer = customers.customersById[customerId];

    return typeof customer !== 'undefined'
        ? of(customer)
        : from(api<ICustomerDetails>(dispatch, getState, `/admin/customers/${customerId}`, 'GET')).pipe(
            catchError((e: AxiosError) => {
                console.error(e);
                dispatch(notify(errorNotification(e.message, e)));
                return throwError(e);
            }),
            map(({ data }) => data),
            tap((customerDetails) => dispatch(setCustomerDetails(customerDetails))),
        );
};

export const createCustomer = (createCustomer: ISaveCustomerDemographic, onDuplicateEmailError?: (e: AxiosError) => void): CustomersThunkResult<ICustomerDetails> => (dispatch, getState, api) => {
    return from(api<ICustomerDetails>(dispatch, getState, '/admin/customers', 'POST', createCustomer))
        .pipe(
            catchError((e: AxiosError) => {
                if(e.response?.data?.type === 'DUPLICATE_CUSTOMER_EMAIL' && onDuplicateEmailError) {
                    onDuplicateEmailError(e);
                } else {
                    dispatch(notify(errorNotification(e.message, e)));
                }
                return throwError(e);
            }),
            map(({ data }) => data),
            tap(() => dispatch(notify(successNotification('Customer created successfully!')))),
            tap((customerDetails) => dispatch(setCustomerDetails(customerDetails))),
        );
};

export const saveCustomerDetails = (customerId: string, customerDetails: IUpdateCustomerDetails): CustomersThunkResult<ICustomerDetails> => (dispatch, getState, api) => {
    return from(api<ICustomerDetails>(dispatch, getState, `/admin/customers/${customerId}`, 'POST', customerDetails)).pipe(
            catchError((e: AxiosError) => {
                console.error(e);
                dispatch(notify(errorNotification(e.message, e)));
                return throwError(e);
            }),
            map(({ data }) => data),
            tap((customerDetails) => dispatch(setCustomerDetails(customerDetails))),
            tap(() => dispatch(notify(successNotification('Customer details updated successfully!')))),
        );
};

export const saveCustomerDemographic = (customerId: string, demographic: ISaveCustomerDemographic): CustomersThunkResult<ICustomerDemographic> => (dispatch, getState, api) => {
    return from(api<ICustomerDemographic>(dispatch, getState, `/admin/customers/${customerId}/demographic`, 'PUT', demographic)).pipe(
        catchError((e: AxiosError) => {
            console.error(e);
            dispatch(notify(errorNotification(e.message, e)));
            return throwError(e);
        }),
        map(({ data }) => data),
        tap((demographic) => dispatch(setCustomerDemographic({ customerId, demographic}))),
        tap(() => dispatch(notify(successNotification('Customer demographic updated successfully!')))),
    );
};

export const saveCustomerAddresses = (customerId: string, addresses: ICustomerAddress[]): CustomersThunkResult<ICustomerAddress[]> => (dispatch, getState, api) => {
    return from(api<ICustomerAddress[]>(dispatch, getState, `/admin/customers/${customerId}/addresses`, 'POST', addresses)).pipe(
        catchError((e: AxiosError) => {
            console.error(e);
            dispatch(notify(errorNotification(e.message, e)));
            return throwError(e);
        }),
        map(({ data }) => data),
        tap((addresses) => dispatch(setCustomerAddresses({ customerId, addresses }))),
        tap(() => dispatch(notify(successNotification('Customer addresses updated successfully!')))),
    );
};

export const getCustomerAddresses = (customerId: string): CustomersThunkResult<ICustomerAddress[]> => (dispatch, getState, api) => {
    return from(api<ICustomerAddress[]>(dispatch, getState, `/admin/customers/${customerId}/addresses`, 'GET')).pipe(
        catchError((e: AxiosError) => {
            console.error(e);
            dispatch(notify(errorNotification(e.message, e)));
            return throwError(e);
        }),
        map(({ data }) => data),
    );
};

export const deleteCustomerAddresses = (customerId: string, addressIds: string[]): CustomersThunkResult<ICustomerAddress[]> => (dispatch, getState, api) => {
    return from(api<ICustomerAddress[]>(dispatch, getState, `/admin/customers/${customerId}/addresses`, 'DELETE', addressIds)).pipe(
        catchError((e: AxiosError) => {
            console.error(e);
            dispatch(notify(errorNotification(e.message, e)));
            return throwError(e);
        }),
        concatMap(() => dispatch(getCustomerAddresses(customerId))
            .pipe(tap((addresses) => dispatch(setCustomerAddresses({ customerId, addresses })))),
        ),
    );
};

export const getAutoSuggestions = (field: keyof IAutoSuggestableFields, searchTerm: string, limit: number = 5): CustomersThunkResult<ICustomerSearchSuggestion> => (dispatch, getState, api) => {
    return defer(() => api<ICustomerSearchSuggestion[]>(dispatch, getState, '/admin/customers/search-filters/auto-suggestions', 'GET', undefined, {
        params: {field, searchTerm, limit},
    }))
        .pipe(map(({data}) => data));
};

export const getCustomerSuggestions = (fields: Array<keyof IAutoSuggestableFields>, searchTerm: string, limit: number = 5): CustomersThunkResult<ICustomerSearchSuggestion[]> => (dispatch, getState, api) => {
    return defer(() => api<ICustomerSearchSuggestion[]>(dispatch, getState, '/admin/customers/search-filters/auto-suggestions/multi', 'GET', undefined, {
        params: {fields, searchTerm, limit},
    })).pipe(map(({data}) => data));
};
