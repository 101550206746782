import { getIn } from 'formik';
// taken from source: https://github.com/jaredpalmer/formik/blob/b2868656c358e4a9804effe4401b7fc6cb04cd8d/src/FastField.tsx#L109
// the original formik code doesn't pass it's implementation so to modify the default behavior, have to copy it for now.
export default (prevProps, nextProps) => {
    return (
        prevProps.name !== nextProps.name ||
        getIn(nextProps.formik.initialValues, nextProps.name) !==
        getIn(prevProps.formik.initialValues, nextProps.name) ||
        getIn(nextProps.formik.values, nextProps.name) !==
        getIn(prevProps.formik.values, nextProps.name) ||
        getIn(nextProps.formik.errors, nextProps.name) !==
        getIn(prevProps.formik.errors, nextProps.name) ||
        getIn(nextProps.formik.touched, nextProps.name) !==
        getIn(prevProps.formik.touched, nextProps.name) ||
        Object.keys(nextProps).length !== Object.keys(prevProps).length ||
        nextProps.formik.isSubmitting !== prevProps.formik.isSubmitting
    );
};
