import { IShipmentsDictionary } from '@eq3/redux/orderShipments/models';
import { createAction as $createAction } from 'deox';

const prefix = '[ORDER_SHIPMENTS]';
const createAction: typeof $createAction = (type, executor) => $createAction(`${prefix}\\${type}` as typeof type, executor);

export const setOrderShipments = createAction('SET_ORDER_SHIPMENTS', (resolve) => (shipmentsDictionary: IShipmentsDictionary) => resolve(shipmentsDictionary));

export type ActionType =
    | ReturnType<typeof setOrderShipments>;
