
import { setComOrders } from '@eq3/redux/comOrders/actions';
import { IComOrderDictionary } from '@eq3/redux/comOrders/models';
import { createReducer } from 'deox';

const initialState: IComOrderState = {
    lastUpdated: Date.now(),
    comOrderDictionary: {},
};

export interface IComOrderState {
    lastUpdated: number;
    comOrderDictionary: IComOrderDictionary;
}

export interface IReduxSlice {
    orderComData: IComOrderState;
}

export default createReducer(initialState, (handle) => [
    handle(setComOrders, (state, { payload }) => {
        return ({
            ...state,
            lastUpdated: Date.now(),
            comOrderDictionary: {
                ...state.comOrderDictionary,                
                ...payload,                
            },
        });
    }),
]);
