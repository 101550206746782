import { createReducer } from 'deox';

import { setCurrentOrderReplacements, setOrderReplacementReasons } from './actions';
import { IOrderReplacementsState } from './models';

const initialState: IOrderReplacementsState['orderReplacements'] = {
    currentReplacementsList: [],
    searchResults: undefined,
    replacementReasons: {},
};

export default createReducer(initialState, (handle) => [
    handle(setCurrentOrderReplacements, (state, { payload }) => {
        return ({
            ...state,
            currentReplacementsList: payload,
        });
    }),
    handle(setOrderReplacementReasons, (state, { payload }) => {
        return ({
            ...state,
            replacementReasons: payload,
        });
    }),
]);
