import WarningIcon from '@material-ui/icons/WarningRounded';
import React from 'react';
import { compose } from 'redux';

import { createStyles, Typography, withStyles } from '@material-ui/core';

const Prop65GenericTag = ({ classes }) => {
    return (
        <div className={classes.container}>
            <div>
                <div className={classes.warningTitle}>
                    <WarningIcon />
                    <Typography variant="h5">
                        WARNING:
                    </Typography>
                </div>
                <div className={classes.description}>
                    This product can expose you to Saw Dust which is known to the State of California to cause
                    Cancer,
                    and one or more of the following chemicals:
                    <ul className={classes.chemicalList}>
                        <li>Benzene-Bisphenol A (BPA)</li>
                        <li>DI (2-Ethylhexl) Phthalate (DEHP)</li>
                        <li>Ethylene Glycol-Methanol - Toluene</li>
                    </ul>
                    Which are known to the State of California to cause birth defects or other reproductive harm.
                    <br />
                    <div className={classes.moreInformation}>
                        For more information, visit:
                        <br />
                        www.p65warnings.ca.gov
                    </div>
                </div>
            </div>
        </div>
    );
};

const styles = () => createStyles({
    container: {
        padding: '0in 0.15in',
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },
    chemicalList: {
        margin: '.045in 0in',
    },
    warningTitle: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '8px',
        float: 'left',
    },
    description: {
        fontFamily: 'eq3-book',
        fontSize: '8pt',
        lineHeight: '.11in',
    },
    moreInformation: {
        marginTop: '.045in',
    },
});

export default (compose(
    withStyles(styles),
)(Prop65GenericTag)) as any as React.ComponentType;
