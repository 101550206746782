import { Pagination } from '@eq3/utils/pagination';
import { createActionCreator } from 'deox';
import { IPriceCostJump } from './models';

const createAction: typeof createActionCreator = (type, executor) => createActionCreator(`[PRICE_COST_JUMPS]\\${type}` as typeof type, executor);
export const setJumps = createAction('SET_PRICE_COST_JUMPS', (resolve) => (jumps: Pagination<IPriceCostJump>) => resolve(jumps));
export const setFamilies = createAction('SET_FAMILIES', (resolve) => (families: string[]) => resolve(families));

export type ActionTypes =
    | ReturnType<typeof setJumps>
    | ReturnType<typeof setFamilies>;
