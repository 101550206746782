export const dateUtils = {
    is: (date: Date) => ({
        between: (dateRange: IDateRange) => (dateRange.start === undefined || dateRange.start <= date) && (dateRange.end === undefined || dateRange.end >= date),
    }),
};

interface IDateRange {
    start?: Date;
    end?: Date;
}
