import { errorNotification, notify, successNotification } from '@eq3/redux/adminNotifications';
import { ThunkResult } from 'redux-thunk';
import { from } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ActionTypes, setGradeGroups } from './actions';
import { IUpholsteryGradeGroup } from './models';
import { IReduxState } from './reducers';

const handleError = (dispatch, e, fallbackMessage) => {
    const { message } = (e.response && e.response.data) || { message: fallbackMessage };
    dispatch(notify(errorNotification(message, e)));
    throw e;
};

type GradeGroupThunkResult<T = void> = ThunkResult<Promise<T>, IReduxState, ActionTypes>;
export type GradeGroupDispatch = Parameters<GradeGroupThunkResult>[0];

const baseUrl = '/admin/upholstery/grade-groups';

export const fetchGradeGroups = (): GradeGroupThunkResult => async (dispatch, getState, api) => {
    try {
        const { data } = await api(dispatch, getState, baseUrl, 'GET');
        dispatch(setGradeGroups(data));
    } catch (e) {
        handleError(dispatch, e, 'Error fetching grade groups');
    }
};

export const saveGradeGroups = (toSave: IUpholsteryGradeGroup[]): GradeGroupThunkResult => async (dispatch, getState, api) => {
    from(api(dispatch, getState, baseUrl, 'POST', toSave)).pipe(
        concatMap(() => from(dispatch(fetchGradeGroups()))),
    ).subscribe({
        complete: () => {
            dispatch(notify(successNotification('Grade groups saved successfully!')));     
        },
        error: (e) => {
            console.error(e);
            handleError(dispatch, e, 'Error deleting grade groups');
        },
    });
};

export const deleteGradeGroups = (toDelete: string[]): GradeGroupThunkResult => async (dispatch, getState, api) => {
    from(api(dispatch, getState, baseUrl, 'DELETE', toDelete)).pipe(
        concatMap(() => from(dispatch(fetchGradeGroups()))),
    ).subscribe({
        complete: () => {
            dispatch(notify(successNotification('Grade groups deleted successfully!')));
        },
        error: (e) => {
            console.error(e);
            handleError(dispatch, e, 'Error deleting grade groups');
        },
    });
};
