import { IOrderable, IPaginatable, Pagination } from '@eq3/utils';

export interface IShippingInventoryOrder {
    orderId: string;
    orderStatus: string;
    orderNumber: number;
    createdTime: string;
    customerName: string;
    sku: string;
    shipmentId: string;
    orderLineItemId: string;
}

export interface IShippingInventoryOrderListing {
    sku: string;
    orderNumber: number;
    receivedQuantity: number;
    pickedQuantity: number;
    description: string;
    shipmentId: string;
    orderLineItemId: string;
}

export interface IShippingInventoryOrderListingResponse {
    details: Pagination<IShippingInventoryOrderListing>;
    selectedSku: string;
    orderIdentifiers: IShippingInventoryOrderIdentifier[];
}

export interface IShippingInventoryOrderIdentifier {
    orderId: string;
    orderNumber: number;
}

export interface IShippingInventoryOrderQuery extends Partial<IPaginatable & Omit<IOrderable, 'orderBy'>> {
    orderBy?: ShippingInventoryOrderByField;
    deliveryStatus: ShippingInventoryOrderStatus;
}

export interface IShippingInventoryOrderListingQuery extends Partial<IPaginatable & Omit<IOrderable, 'orderBy'>> {
    orderBy: ShippingInventoryOrderByListingField | undefined;
    orderIds: string[];
    locationId: string | undefined;
    sku?: string;
}

export interface IOrderQueryResponse {
    orders: Pagination<IShippingInventoryOrder>;
    orderIdentifiers: IShippingInventoryOrderIdentifier[];
}

export interface IShippingInventoryOrderListingQueryResponse {
    details: Pagination<IShippingInventoryOrderListing>;
    orderIdentifiers: IShippingInventoryOrderIdentifier[];
}

export interface IUpdateShippingInventoryOrderQuery {
    sku: string;
    quantity: number;
    type: ShippingInventoryOrderAction;
    shipmentId: string;
    orderLineItemId: string;
}

export enum ShippingInventoryOrderByField {
    ORDER_NUMBER = 'ORDER_NUMBER',
    ORDER_STATUS = 'ORDER_STATUS',
    CREATED_TIME = 'CREATED_TIME',
    CUSTOMER_NAME = 'CUSTOMER_NAME',
    SKU = 'SKU',
    QUANTITY = 'QUANTITY',
}

export enum ShippingInventoryOrderByListingField {
    ORDER = 'ORDER',
    SKU = 'SKU',
    DESCRIPTION = 'DESCRIPTION',
    PARENT = 'PARENT',
    SHIPPED_QUANTITY = 'SHIPPED_QUANTITY',
    RECEIVED_QUANTITY = 'RECEIVED_QUANTITY',
    PICKED_QUANTITY = 'PICKED_QUANTITY',
}

export enum ShippingInventoryOrderStatus {
    OUTSTANDING = 'OUTSTANDING',
    RECEIVED = 'RECEIVED',
}

export enum ShippingInventoryOrderAction {
    RECEIVE_AT_DW = 'RECEIVE_AT_DW',
    PICK_AT_DW = 'PICK_AT_DW',
}
