import LocationStatuses from '@eq3/containers/retail/locations/LocationStatuses';
import { Countries, Translated } from '@eq3/utils/locales';
import { IPaginationParameters } from '@eq3/utils/pagination';
import { IAddress } from '../orders';
import { LOCATIONS_REDUX_KEY } from './index';

export enum LocationType {
  STORE = 'STORE',
  DEALERSHIP = 'DEALERSHIP',
  OUTLET = 'OUTLET',
  WAREHOUSE = 'WAREHOUSE',
  TRADE = 'TRADE',
}

export const AllLocationTypes = Object.values(LocationType);

export interface ILocationsState {
  tradeRepsCache: Record<string, ITradeRepDto>;
  locations: Partial<Record<LocationType, ILocation[]>>;
}

export interface ILocationsReduxState {
  [LOCATIONS_REDUX_KEY]: ILocationsState;
}

export interface ILocation {
  id: string;
  type: LocationType;
  locationName: string;
  locationNumber: number;
  address: IAddress;
  isActive: boolean;
  primaryWarehouse?: {
    id: string;
    name: string;
  };
}

export interface ILocationImages {
  imageId: string;
  imageUrl: string;
  altText: string;
  fileName: string;
  images: Record<string, string>;
}

export interface ILocationDetails {
  id: string;
  isActive: boolean;
  type: LocationType;
  locationImages: ILocationImages;
  locationNumber?: number;
  erpCustomerNumber?: number;
  locationName: string;
  locationEmail?: string;
  managerName?: string;
  managerEmail?: string;
  lat: number;
  lng: number;
  address: IAddress;
  slug: string;
  designServicesBookingUrl?: string;
  translations: Translated<ILocationTranslation>;
  hours: ILocationHour[];
  pickupHours: ILocationHour[];
  holidays: ILocationHoliday[];
  website?: string;
  tradeRepEmail?: string;
  tradeRepIds: string[];
}

export interface ITradeRepDto {
  userId: string;
  userEmail: string;
  fullName: string;
  firstName?: string;
  lastName?: string;
  profileImageUrl?: string;
}

export interface IModifyLocation {
  altText: string[];
  fileNames: string[];
  isActive: boolean;
  type: LocationType;
  locationNumber?: number;
  locationName: string;
  locationEmail?: string;
  managerName?: string;
  managerEmail?: string;
  lat: number;
  lng: number;
  address: IAddress;
  slug: string;
  designServicesBookingUrl?: string;
  translations: Translated<ILocationTranslation>;
  hours: ILocationHour[];
  pickupHours: ILocationHour[];
  holidays: ILocationHoliday[];
  website?: string;
  tradeRepEmail?: string;
  tradeRepIds: string[];
}

export interface ILocationImagePayload {
  newImages: File[];
  imageIdsToDelete: string[];
}

export interface IFormikValues {
  location: ILocationDetails;
}

export interface ILocationTranslation {
  name: string;
  description?: string;
  metaTitle?: string;
  metaDescription?: string;
  pickupHours?: string;
  pickupPhone?: string;
  pickupLocation?: string;
  parkingInfo?: string;
}

export interface ILocationHour {
  opening?: string;
  closing?: string;
  dayOfTheWeek: number;
  isClosed: boolean;
}

export interface ILocationHoliday extends ILocationHour {
  day: string;
}

export interface ILocationQuery extends Partial<IPaginationParameters> {
  id: string;
  isActive: boolean | LocationStatuses;
  storeName: string;
  storeType: LocationType;
  country: Countries;
  province: FunctionStringCallback;
  city: string;
  searchText: string;
}

export interface ILocationStaffGroup {
  roleGroup: IRetailStaffRoleGroup;
  staff: IRetailStaffMember[];
}

export interface IRetailStaffRoleGroup {
  id: string;
  name: string;
  description: string;
}

export interface IRetailStaffMember {
  userId: string;
  name: string;
  email: string;
  roleGroup: IRetailStaffRoleGroup;
}

export interface ILocationListing {
  locationId: string;
  locationName: string;
  type: LocationType;
  country: string;
  province: string;
  city: string;
  isActive: boolean;
}

export enum LocationStatusOptions {
  TRUE = 'ACTIVE',
  FALSE = 'INACTIVE',
}

export enum LocationField {
  NAME = 'NAME',
  TYPE = 'TYPE',
  COUNTRY = 'COUNTRY',
  PROVINCE_STATE = 'PROVINCE_STATE',
  CITY = 'CITY',
  ACTIVE = 'ACTIVE',
}

export const LocationQueryFieldMap: Record<
  LocationField,
  keyof ILocationQuery
> = {
  [LocationField.NAME]: 'storeName',
  [LocationField.TYPE]: 'storeType',
  [LocationField.COUNTRY]: 'country',
  [LocationField.PROVINCE_STATE]: 'province',
  [LocationField.CITY]: 'city',
  [LocationField.ACTIVE]: 'isActive',
};

export const AutoSuggestableFields: LocationField[] = [
  LocationField.NAME,
  LocationField.CITY,
];

export interface ILocationSearchSuggestion {
  field: LocationField;
  suggestions: string[];
}
