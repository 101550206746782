import { StyleRules, WithStyles } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/WarningRounded';
import React from 'react';
import {connect} from 'react-redux';

import { Typography, withStyles } from '@material-ui/core';
import {compose} from 'redux';
import {i18n} from '../../../util/i18n';

class Prop65Accessories extends React.Component<ReturnType<typeof mapStateToProps> & WithStyles<ReturnType<typeof styles>>> {
    public render() {
        const {locale, classes} = this.props;

        return (
            <div className={classes.container}>
                <div className={classes.warningTitle}>
                    <WarningIcon/>
                    <Typography variant="h5">
                        {i18n[locale.id].warning.toUpperCase()}:
                    </Typography>
                </div>
                <div className={classes.description}>
                    {i18n[locale.id].prop65AccessoriesDescription}
                </div>
            </div>
        );
    }
}

const styles = () => ({
    container: {
        padding: '0 0.15in',
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    warningTitle: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '8px',
    },
    description: {
        fontFamily: 'eq3-book',
        fontSize: '8pt',
        width: '150px',
    },
}) as StyleRules;

const mapStateToProps = (state) => ({
    locale: state.priceTags.locale,
});

export default (compose(
    connect(mapStateToProps),
    withStyles(styles),
)(Prop65Accessories)) as any as React.ComponentType;
