import { upholsteryItemsHasInvalidItemsSelector } from '@eq3/containers/system/erp/upholsteryItemsAdministration/redux';
import { useFeatureFlags } from '@eq3/hooks';
import { AuthPermissions } from '@eq3/redux/auth';
import { useAuth, useUserPermissions } from '@eq3/redux/auth/hooks';
import {
    AccountBalance,
    AssignmentOutlined as Clipboard,
    BarChartOutlined as BarChart,
    BusinessOutlined as PurchaseOrder,
    Dashboard,
    DomainOutlined as Warehouse,
    PaymentOutlined as CreditCard,
    PeopleOutlineOutlined as People,
    Settings,
    StorefrontOutlined as Storefront,
    TrackChangesOutlined as TrackChanges,
    WebOutlined,
} from '@material-ui/icons';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

export interface ISidebarRoutes {
    key: string;
    title: string;
    icon?: JSX.Element;
    urls?: INavItem[];
    subCategory?: ISidebarRoutes[];
    alertText?: string;
}

export interface INavItem {
    title: string;
    path: string;
    requiredPermissions: AuthPermissions[];
    alertText?: string;
    hide?: boolean;
}

export const useMainNav = () => {
    const { loggedInUser } = useAuth();
    const { hasPermissions } = useUserPermissions();

    const allSidebarRoutes = useSidebarRoutes();

    // Returns leaf nav items that the user has permissions to access.
    const filterAccessibleRoutes = (routes: INavItem[]): INavItem[] => {
        return routes.filter((x) => {
            return hasPermissions(...x.requiredPermissions) && !x.hide;
        });
    };

    // Returns nav categories that actually contain child elements.
    const filterAccessibleNavCategories = (navCategories: ISidebarRoutes[]): ISidebarRoutes[] => {
        return navCategories.map((navCategory) => {
            if (navCategory.subCategory) {
                navCategory.subCategory = filterAccessibleNavCategories(navCategory.subCategory);
            }
            if (navCategory.urls) {
                navCategory.urls = filterAccessibleRoutes(navCategory.urls);
            }
            if (!navCategory.subCategory && !navCategory.urls) {
                throw Error(`Category "${navCategory.title}" has no route nor subcategory.`);
            }
            return navCategory;
        }).filter((navCategory) => {
            // Only show top-level menu items that actually have child items (we stripped out some child items base on
            // user permissions in the previous step).
            return navCategory.subCategory?.length || navCategory.urls?.length;
        });
    };

    const bubbleUpAlerts = (navCategories: ISidebarRoutes[]): ISidebarRoutes[] => {
        return navCategories.map((navCategory) => {

            const subcategory = navCategory.subCategory ? bubbleUpAlerts(navCategory.subCategory!) : undefined;

            const alertText = navCategory.urls?.find((x) => !!x.alertText)?.alertText
                ?? subcategory?.find((x) => !!x.alertText)?.alertText;

            navCategory.subCategory = subcategory;
            navCategory.alertText = alertText;

            return navCategory;
        });
    };

    const mainNav = useMemo(() => {
        const filteredRoutes = filterAccessibleNavCategories(allSidebarRoutes);
        return bubbleUpAlerts(filteredRoutes);
    }, [allSidebarRoutes, loggedInUser?.permissions]);

    return {
        mainNav,
    };
};

const useSidebarRoutes = (): ISidebarRoutes[] => {
    const featureFlags = useFeatureFlags();
    const invalidUpholsteryItemsAlert = useSelector<any, string | undefined>((state) => {
        const hasInvalidUpholsteryItems = upholsteryItemsHasInvalidItemsSelector(state);
        return hasInvalidUpholsteryItems
            ? 'There are invalid upholstery items'
            : undefined;
    });

    return [
        {
            key: 'dashboard',
            title: 'Dashboard',
            icon: <Dashboard />,
            urls: [
                {
                    title: 'Quotes and Sales Orders',
                    path: '/dashboard/order-search',
                    requiredPermissions: [AuthPermissions.READ_POS_ORDERS],
                },
                {
                    title: 'New Quote',
                    path: '/dashboard/quotes/',
                    requiredPermissions: [AuthPermissions.WRITE_POS_ORDERS],
                }
            ],
        },
        {
            key: 'pos',
            title: 'Point of Sale',
            icon: <Storefront/>,
            urls: [
                {
                    title: 'Payments',
                    path: '/pos/payments',
                    requiredPermissions: [AuthPermissions.WRITE_POS_PAYMENTS],
                    hide: featureFlags.ENABLE_SQUARE_POS,
                },
                {
                    title: 'Virtual Terminal',
                    path: '/pos/virtual-terminal',
                    requiredPermissions: [AuthPermissions.WRITE_POS_VT_PAYMENTS],
                    hide: featureFlags.ENABLE_SQUARE_POS,
                },
                {
                    title: 'Transactions Search',
                    path: '/pos/transactions',
                    requiredPermissions: [AuthPermissions.READ_POS_TRANSACTIONS],
                    hide: featureFlags.ENABLE_SQUARE_POS,
                },
                {
                    title: 'Unlinked Refunds',
                    path: '/pos/unlinked-refunds',
                    requiredPermissions: [AuthPermissions.WRITE_POS_UNLINKED_REFUNDS],
                    hide: true,
                },
                {
                    title: 'Terminals',
                    path: '/pos/terminals',
                    requiredPermissions: [AuthPermissions.READ_POS_TERMINALS],
                    hide: featureFlags.ENABLE_SQUARE_POS,
                },
                {
                    title: 'Terminal Registration',
                    path: '/pos/register',
                    requiredPermissions: [AuthPermissions.WRITE_POS_TERMINALS],
                    hide: featureFlags.ENABLE_SQUARE_POS,
                },
                {
                    title: 'Reporting',
                    path: '/pos/reporting',
                    requiredPermissions: [AuthPermissions.READ_POS_TRANSACTIONS],
                    hide: featureFlags.ENABLE_SQUARE_POS,
                },
            ],
        },
        {
            key: 'pos-square',
            title: 'Point of Sale (Square)',
            icon: <Storefront/>,
            urls: [
                {
                    title: 'Payments',
                    path: '/pos-square/payments',
                    requiredPermissions: [AuthPermissions.WRITE_POS_PAYMENTS],
                    hide: !featureFlags.ENABLE_SQUARE_POS,
                },
                {
                    title: 'Virtual Terminal',
                    path: '/pos-square/virtual-terminal',
                    requiredPermissions: [AuthPermissions.WRITE_POS_VT_PAYMENTS],
                    hide: !featureFlags.ENABLE_SQUARE_POS || !featureFlags.ENABLE_VIRTUAL_TERMINAL
                },
                {
                    title: 'Transactions Search',
                    path: '/pos-square/transactions',
                    requiredPermissions: [AuthPermissions.READ_POS_TRANSACTIONS],
                    hide: !featureFlags.ENABLE_SQUARE_POS,
                },
                {
                    title: 'Unlinked Refunds',
                    path: '/pos-square/unlinked-refunds',
                    requiredPermissions: [AuthPermissions.WRITE_POS_UNLINKED_REFUNDS],
                    hide: !featureFlags.ENABLE_SQUARE_POS || !featureFlags.ENABLE_UNLINKED_REFUNDS,
                },
                {
                    title: 'Terminals',
                    path: '/pos-square/terminals',
                    requiredPermissions: [AuthPermissions.READ_POS_TERMINALS],
                    hide: !featureFlags.ENABLE_SQUARE_POS,
                },
                {
                    title: 'Terminal Registration',
                    path: '/pos-square/register',
                    requiredPermissions: [AuthPermissions.WRITE_POS_TERMINALS],
                    hide: !featureFlags.ENABLE_SQUARE_POS,
                },
                {
                    title: 'Reporting',
                    path: '/pos-square/reporting',
                    requiredPermissions: [AuthPermissions.READ_POS_TRANSACTIONS],
                    hide: !featureFlags.ENABLE_SQUARE_POS,
                },
            ],
        },
        {
            key: 'orders',
            title: 'Orders',
            icon: <CreditCard/>,
            urls: [
                {
                    title: 'Order Search',
                    path: '/orders',
                    requiredPermissions: [AuthPermissions.READ_ORDERS],
                },
                {
                    title: 'Kona Kart',
                    path: '/orders/legacy',
                    requiredPermissions: [AuthPermissions.READ_KONA_KART_ORDERS],
                },
                {
                    title: 'Swatch Requests',
                    path: '/orders/swatch-requests',
                    requiredPermissions: [AuthPermissions.READ_SWATCH_REQUESTS],
                },
            ],
        },
        {
            key: 'customers',
            title: 'Customers',
            icon: <People/>,
            urls: [
                {
                    title: 'Customer Search',
                    path: '/customers',
                    requiredPermissions: [AuthPermissions.READ_CUSTOMER],
                },
            ],
        },
        {
            key: 'finance',
            title: 'Finance',
            icon: <AccountBalance/>,
            urls: [
                {
                    title: 'Journal Entry Search',
                    path: '/finance/search',
                    requiredPermissions: [AuthPermissions.READ_FINANCE],
                },
            ],
        },
        {
            key: 'marketing',
            title: 'Marketing',
            icon: <TrackChanges/>,
            urls: [
                {
                    title: 'Feeds',
                    path: '/marketing/feeds',
                    requiredPermissions: [AuthPermissions.READ_MARKETING_FEEDS],
                },
                {
                    title: 'Promotions',
                    path: '/marketing/promotions',
                    requiredPermissions: [AuthPermissions.READ_MARKETING_PROMOTIONS],
                },
            ],
        },
        {
            key: 'inventory',
            title: 'Inventory',
            icon: <Clipboard/>,
            urls: [
                {
                    title: 'Item Search',
                    path: '/inventory/item-search',
                    requiredPermissions: [AuthPermissions.READ_INVENTORY_ITEMS],
                },
                {
                    title: 'On Hand Inventory Search',
                    path: '/inventory/on-hand',
                    requiredPermissions: [AuthPermissions.READ_INVENTORY_ITEMS],
                },
                {
                    title: 'Import Stock Status',
                    path: '/inventory/import-stock-status',
                    requiredPermissions: [AuthPermissions.READ_STOCK_STATUS],
                },
                {
                    title: 'Orders',
                    path: '/inventory/orders',
                    requiredPermissions: [AuthPermissions.READ_INVENTORY_ORDERS],
                    hide: !featureFlags.ENABLE_INVENTORY_ORDERS,
                },
            ],
        },
        {
            key: 'purchaseOrders',
            title: 'Purchase Orders',
            icon: <PurchaseOrder/>,
            urls: [
                {
                    title: 'New',
                    path: '/purchase-orders/new',
                    requiredPermissions: [AuthPermissions.WRITE_PURCHASE_ORDERS],
                },
                {
                    title: 'Purchase Order Search',
                    path: '/purchase-orders/search',
                    requiredPermissions: [AuthPermissions.READ_PURCHASE_ORDERS],
                },
            ]
        },
        {
            key: 'warehouse',
            title: 'Warehouse',
            icon: <Warehouse/>,
            urls: [
                {
                    title: 'Order Fulfillment Search',
                    path: '/warehouse/fulfillment-search',
                    requiredPermissions: [AuthPermissions.READ_WAREHOUSE_MANAGEMENT],
                },
                {
                    title: 'Manual Transfer Order',
                    path: '/warehouse/transfer-order',
                    requiredPermissions: [AuthPermissions.WRITE_TRANSFER_ORDERS],
                },
            ]
        },
        {
            key: 'retail',
            title: 'Retail',
            icon: <Storefront/>,
            urls: [
                {
                    title: 'Price Tags',
                    path: '/retail/price-tags',
                    requiredPermissions: [AuthPermissions.READ_PRICE_TAGS],
                },
                {
                    title: 'Locations',
                    path: '/retail/locations',
                    requiredPermissions: [AuthPermissions.READ_LOCATIONS],
                },
                {
                    title: 'Regions',
                    path: '/retail/regions',
                    requiredPermissions: [AuthPermissions.READ_LOCATIONS],
                },
                {
                    title: 'Trade Members',
                    path: '/retail/trade-program/members',
                    requiredPermissions: [AuthPermissions.READ_TRADE_PROGRAM],
                },
            ],
        },
        {
            key: 'ecom',
            title: 'Ecommerce',
            icon: <WebOutlined/>,
            urls: [
                {
                    title: 'Navigation',
                    path: '/ecommerce-admin/categories',
                    requiredPermissions: [AuthPermissions.READ_ECOM_CATEGORIES],
                },
                {
                    title: 'Redirections',
                    path: '/ecommerce-admin/redirections',
                    requiredPermissions: [AuthPermissions.READ_ECOM_REDIRECTS],
                },
                {
                    title: 'Sitemap',
                    path: '/ecommerce-admin/sitemap',
                    requiredPermissions: [AuthPermissions.READ_ECOM_SITEMAP],
                },
            ],
            subCategory: [
                {
                    key: 'ecom-homepage',
                    title: 'Homepage',
                    urls: [
                        {
                            title: 'SEO Content',
                            path: '/ecommerce-admin/homepage/seo-content',
                            requiredPermissions: [AuthPermissions.READ_ECOM_HOME_PAGE],
                        },
                        {
                            title: 'Hero Image',
                            path: '/ecommerce-admin/homepage/hero-image',
                            requiredPermissions: [AuthPermissions.READ_ECOM_HOME_PAGE],
                        },
                        {
                            title: 'Carousel',
                            path: '/ecommerce-admin/homepage/carousel',
                            requiredPermissions: [AuthPermissions.READ_ECOM_HOME_PAGE],
                        },
                        {
                            title: 'Featured Categories',
                            path: '/ecommerce-admin/homepage/featured-categories',
                            requiredPermissions: [AuthPermissions.READ_ECOM_HOME_PAGE],
                        },
                        {
                            title: 'Must-Haves',
                            path: '/ecommerce-admin/homepage/must-haves',
                            requiredPermissions: [AuthPermissions.READ_ECOM_HOME_PAGE],
                        },
                        {
                            title: 'Banners',
                            path: '/ecommerce-admin/homepage/banners',
                            requiredPermissions: [AuthPermissions.READ_ECOM_HOME_PAGE],
                        },
                    ],
                },
            ],
        },
        {
            key: 'reporting',
            title: 'Reporting',
            icon: <BarChart/>,
            urls: [
                {
                    title: 'Domo Reports',
                    path: '/reporting/domo-reports',
                    requiredPermissions: [AuthPermissions.READ_REPORTS_DOMO],
                },
                {
                    title: 'Daily Written Sales',
                    path: '/reporting/daily-written-sales',
                    requiredPermissions: [AuthPermissions.READ_REPORTS_DWS],
                },
                {
                    title: 'Promo Code Performance',
                    path: '/reporting/promo-code-performance',
                    requiredPermissions: [AuthPermissions.READ_REPORTS_PROMO_CODE],
                },
                {
                    title: 'Reconciliation Report',
                    path: '/reporting/reconciliation-reports',
                    requiredPermissions: [AuthPermissions.READ_REPORTS_STRIPE],
                },
                {
                    title: 'Zucora Reports',
                    path: '/reporting/zucora-sales',
                    requiredPermissions: [AuthPermissions.READ_REPORTS_ZUCORA],
                },
            ],
        },
        {
            key: 'system',
            title: 'System',
            icon: <Settings/>,
            subCategory: [
                {
                    key: 'system-product',
                    title: 'Product',
                    urls: [
                        {
                            title: 'Product Definitions',
                            path: '/system/product/definitions',
                            requiredPermissions: [AuthPermissions.READ_PRODUCT_DEFINITIONS],
                        },
                        {
                            title: 'Shared Text Blocks',
                            path: '/system/product/shared-text-blocks',
                            requiredPermissions: [AuthPermissions.READ_SHARED_TEXT_BLOCKS],
                        },
                        {
                            title: 'Manufacturers',
                            path: '/system/product/manufacturers',
                            requiredPermissions: [AuthPermissions.READ_PRODUCT_MANUFACTURERS],
                        },
                        {
                            title: 'Tagging',
                            path: '/system/product/tagging',
                            requiredPermissions: [AuthPermissions.READ_TAGGING],
                        },
                        {
                            title: 'Prop 65',
                            path: '/system/product/prop65',
                            requiredPermissions: [AuthPermissions.READ_PROP65],
                        },
                    ],
                },
                {
                    key: 'system-erp',
                    title: 'ERP',
                    urls: [
                        {
                            title: 'Upholstery Items',
                            path: '/system/erp/upholstery',
                            requiredPermissions: [AuthPermissions.READ_UPHOLSTERY_ITEMS],
                            alertText: invalidUpholsteryItemsAlert,
                        },
                        {
                            title: 'Upholstery Item Options',
                            path: '/system/erp/item-options',
                            requiredPermissions: [AuthPermissions.READ_UPHOLSTERY_ITEM_OPTIONS],
                        },
                        {
                            title: 'Option Groups',
                            path: '/system/erp/option-groups',
                            requiredPermissions: [AuthPermissions.READ_OPTION_GROUPS],
                        },
                        {
                            title: 'Grades & Colours',
                            path: '/system/erp/colours',
                            requiredPermissions: [AuthPermissions.READ_GRADES_AND_COLORS],
                        },
                        {
                            title: 'Grade Groups',
                            path: '/system/erp/grade-groups',
                            requiredPermissions: [AuthPermissions.READ_GRADE_GROUPS],
                        },
                        {
                            title: 'Piece Codes',
                            path: '/system/erp/piece-codes',
                            requiredPermissions: [AuthPermissions.READ_PIECE_CODES],
                        },
                        {
                            title: 'Price & Cost Jumps',
                            path: '/system/erp/price-cost-jumps',
                            requiredPermissions: [AuthPermissions.READ_PRICE_AND_COST_JUMPS],
                        },
                        {
                            title: 'Series & Family',
                            path: '/system/erp/series-family',
                            requiredPermissions: [AuthPermissions.READ_SERIES_AND_FAMILY],
                        },
                        {
                            title: 'Partner Items',
                            path: '/system/erp/partner-items',
                            requiredPermissions: [AuthPermissions.READ_PARTNER_ITEMS],
                        },
                        {
                            title: 'Sync Log',
                            path: '/system/erp/sync-log',
                            requiredPermissions: [AuthPermissions.READ_SYNC_LOG],
                        },
                    ],
                },
                {
                    key: 'system-shipping',
                    title: 'Shipping',
                    urls: [
                        {
                            title: 'Partners',
                            path: '/system/shipping/partners',
                            requiredPermissions: [AuthPermissions.READ_SHIPPING_PARTNERS],
                        },
                        {
                            title: 'Rates',
                            path: '/system/shipping/rates',
                            requiredPermissions: [AuthPermissions.READ_SHIPPING_RATES],
                        },
                        {
                            title: 'Lead Time Management',
                            path: '/system/shipping/lead-time-management',
                            requiredPermissions: [AuthPermissions.READ_LEAD_TIME_MANAGEMENT],
                        },
                    ],
                },
                {
                    key: 'system-access',
                    title: 'System Access',
                    urls: [
                        {
                            title: 'User Management',
                            path: '/system/system-access/user-management',
                            requiredPermissions: [AuthPermissions.READ_USERS],
                        },
                        {
                            title: 'Role Groups',
                            path: '/system/system-access/role-groups',
                            requiredPermissions: [AuthPermissions.READ_ROLE_GROUPS],
                        },
                    ],
                },
            ],
            urls: [
                {
                    title: 'System Tools',
                    path: '/system/system-tools',
                    requiredPermissions: [AuthPermissions.WRITE_DEVELOPER],
                },
                {
                    title: 'Event Log',
                    path: '/system/event-log',
                    requiredPermissions: [AuthPermissions.READ_DEVELOPER],
                },
            ],
        },
    ];
};
