import { IconButton, makeStyles, Paper, Snackbar as MuiSnackbar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import React, { ComponentTypeWithRef, forwardRef, memo, FC, RefObject, PropsWithChildren } from 'react';
import Texxt from '../Texxt';
import { compose } from 'redux';

export interface ISnackbarProps {
    open: boolean;
    onClose?: () => void;
    variant?: 'error' | 'warn' | 'success' | 'general';
    classes?: Partial<{ [key in SnackbarClasskey]: string }>;
    className?: string;
    autoHideDuration?: number;
}

export type SnackbarClasskey = keyof ReturnType<typeof useStyles>;

const useStyles = makeStyles((theme) => {
    return ({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            padding: theme.spacing(2),
            flexGrow: 1,
            [theme.breakpoints.up('sm')]: {
                flexGrow: 'initial',
                minWidth: 400,
            },
            '& > .icon': {
                marginRight: theme.spacing(),
            },
            color: theme.palette.common.white,
        },
        general: {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper,
        },
        success: {
            backgroundColor: theme.palette.success.main,
        },
        error: {
            backgroundColor: theme.palette.error.main,
        },
        warn: {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.warning.main,
        }
    });
});

const Snackbar = compose<FC<ISnackbarProps>>(
    memo,
    forwardRef,
)((props: PropsWithChildren<ISnackbarProps>, ref: RefObject<HTMLDivElement>) => {
    const { 
        className,
        open = false, 
        onClose,
        variant = 'general',
        children,
        autoHideDuration = 5000
    } = props;

    const classes = useStyles(props);

    return (
        <MuiSnackbar
            autoHideDuration={autoHideDuration}
            open={open}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}>
            <Paper ref={ref} 
                elevation={0}
                className={classNames(classes.root, className, {
                    [classes.general]: variant === 'general',
                    [classes.success]: variant === 'success',
                    [classes.error]: variant === 'error',
                    [classes.warn]: variant === 'warn',
                })}>
                {
                    typeof children === 'string'
                        ? <Texxt style={{ color: 'inherit' }}>{children}</Texxt>
                        : children
                }
                <span style={{ flex: 1 }} />
                <IconButton onClick={onClose} size="small" style={{ color: 'inherit' }}>
                    <CloseIcon />
                </IconButton>
            </Paper>
        </MuiSnackbar>
    );
});

Object.assign(Snackbar, {
    General: React.memo((props: ISnackbarProps) => <Snackbar {...props} variant="general" />),
    Success: React.memo((props: ISnackbarProps) => <Snackbar {...props} variant="success" />),
    Error: React.memo((props: ISnackbarProps) => <Snackbar {...props} variant="error" />),
    Warn: React.memo((props: ISnackbarProps) => <Snackbar {...props} variant="warn" />),
});

export default Snackbar as any as (ComponentTypeWithRef<ISnackbarProps, HTMLDivElement> & {
    General: ComponentTypeWithRef<ISnackbarProps, HTMLDivElement>;
    Success: ComponentTypeWithRef<ISnackbarProps, HTMLDivElement>;
    Error: ComponentTypeWithRef<ISnackbarProps, HTMLDivElement>;
    Warn: ComponentTypeWithRef<ISnackbarProps, HTMLDivElement>;
});
