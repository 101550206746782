import { IGradeChecklistData, IGradeTree, IUpholsteryItem } from './models';
import { UpholsteryItemState } from './state';

export const createChecklist = (items: IUpholsteryItem[], gradeTree: IGradeTree): IGradeChecklistData => {
    return items.reduce((a, item) => ({
        ...a,
        [item.itemId]: Object.keys(gradeTree).reduce((a, group) => ({
            ...a,
            ...Object.keys(gradeTree[group])
                .reduce((b, code) => ({
                    ...b,
                    [code]: !!item.gradeCodes[code],
                }), {}),
        }), {}),
    }), {});
};

export const createPrices = (items: IUpholsteryItem[]): {[itemId: string]: IUpholsteryItem['prices']} => {
    return items.reduce((a, item) => ({
        ...a,
        [item.itemId]: item.prices,
    }), {});
};

export const createCosts = (items: IUpholsteryItem[]): {[itemId: string]: IUpholsteryItem['costs']} => {
    return items.reduce((a, item) => ({
        ...a,
        [item.itemId]: item.costs,
    }), {});
};

export const createItems = (items: IUpholsteryItem[]): UpholsteryItemState['items'] => {
    return items.reduce((a, item) => ({
        ...a,
        [item.itemId]: {
            disabled: item.disabled,
            activeStartDate: item.activeStartDate,
            activeEndDate: item.activeEndDate,
        },
    }), {});
};
