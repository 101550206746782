import { Forbidden } from '@eq3/component/LoginError';
import { useAuth } from '@eq3/redux/auth/hooks';
import { AuthPermissions } from '@eq3/redux/auth/models/apiModels';
import { doesHavePermissions } from '@eq3/redux/auth/utils';
import React from 'react';
import { Route, RouteProps } from 'react-router';

interface IPrivateRouteProps extends RouteProps {
    requiredPermissions: AuthPermissions[];
}

/**
 * Returns the route if the logged in user has the required permissions, else returns a <Forbidden> component.
 */
const PrivateRoute = (props: IPrivateRouteProps) => {
    const { requiredPermissions, component, ...routeProps } = props;
    const { loggedInUser } = useAuth();

    const getComponent = () => {
        return doesHavePermissions(loggedInUser?.permissions, requiredPermissions)
            ? component
            : Forbidden;
    };
    return (
        <Route component={getComponent()} {...routeProps}/>
    );
};

export default PrivateRoute;
