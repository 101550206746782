import { createAction as $createAction } from 'deox';
import {IUser} from '@eq3/redux/userManagement/models';

const prefix = '[EVENT_LOG]';
const createAction: typeof $createAction = (type, executor) => $createAction(`${prefix}\\${type}` as typeof type, executor);

export const setUsers = createAction('SET_USERS', (resolve) => {
    return (users: IUser[]) => resolve({ users });
});


export type EventLogActionsType =
    | ReturnType<typeof setUsers>
    ;
