import { createAction as $createAction } from 'deox';
import { IReplacementOrder, IReplacementReasons } from './models';

const prefix = '[ORDER_REPLACEMENTS]';
const createAction: typeof $createAction = (type, executor) => $createAction(`${prefix}\\${type}` as typeof type, executor);

export const setCurrentOrderReplacements = createAction('SET_REPLACEMENTS_LIST', (resolve) => (replacementsList: IReplacementOrder[]) => resolve(replacementsList));
export const setOrderReplacementReasons = createAction('SET_REPLACEMENT_REASONS', (resolve) => (replacementReasons: IReplacementReasons) => resolve(replacementReasons));

export type ActionType =
    | ReturnType<typeof setCurrentOrderReplacements>
    | ReturnType<typeof setOrderReplacementReasons>;
