import { KeyboardDatePicker as MuiDatePicker, KeyboardDatePickerProps as MuiDatePickerProps } from '@material-ui/pickers';
import { FastField, FastFieldProps, Field, FieldProps, getIn } from 'formik';
import moment from 'moment';
import React, { ComponentType } from 'react';

export interface IDatePickerFieldProps extends Omit<MuiDatePickerProps, 'name' | 'value' | 'onChange'> {
    name: string;
    onChange?: MuiDatePickerProps['onChange'];
    validate?: (value: any) => (string | undefined);
    fast?: boolean;
}

const DatePickerField = React.forwardRef((props: IDatePickerFieldProps, ref) => {
    const { 
        validate,
        name,
        fast = true,
        onChange,
        onBlur,
        format = 'DD/MM/YYYY',
        placeholder = 'DD/MM/YYYY',
        autoOk = true,
        variant = 'inline',
        clearable = variant === 'dialog' ? true : undefined,
        helperText: _helperText,
        ...muiDateTimePickerProps
    } = props;

    const Component = fast ? FastField : Field;
    
    return (
        <Component innerRef={ref} name={name} validate={validate}>
            {
                ({ field, form }: FastFieldProps<any> | FieldProps<any>) => {
                    const value = getIn(form.values, name);

                    const _onChange: typeof onChange = (date) => {
                        form.setFieldValue(name, date?.valueOf(), form.validateOnChange);
                        onChange?.(date);
                    };

                    const _onBlur: typeof onBlur = (e) => {
                        field.onBlur(e);
                        onBlur?.(e);
                    };

                    const errorMsg = getIn(form.errors, name);
                    const helperText = errorMsg ?? _helperText;
                    
                    return (
                        <MuiDatePicker {...muiDateTimePickerProps} 
                            error={!!errorMsg}
                            name={name}
                            autoOk={autoOk}
                            format={format}
                            placeholder={placeholder}
                            variant={variant}
                            clearable={clearable}
                            value={!!value ? moment(value) : null}
                            onChange={_onChange} 
                            onBlur={_onBlur}
                            helperText={helperText} />
                    );
                }
            }
        </Component>
    );

});

export default DatePickerField as ComponentType<IDatePickerFieldProps>;
