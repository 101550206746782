import LocalizedPrice from '@eq3/containers/retail/priceTags/components/LocalizedPrice';
import LocalizedString from '@eq3/containers/retail/priceTags/components/LocalizedString';
import {ISalePrice} from '@eq3/containers/retail/priceTags/models/readyToShip';
import { WithStyles } from '@material-ui/core/styles';
import React from 'react';
import {compose} from 'redux';

import { createStyles, withStyles } from '@material-ui/core';

interface IProps {
    price: ISalePrice;
}

const readyToShipConfigurationPrice = (props: IProps & WithStyles<ReturnType<typeof styles>>) => {
    const {price: {regularPrice, salePrice, skus}, classes} = props;
    return (
        <div className={classes.container}>
            <div className={classes.priceContainer}>
                <div>
                    <div style={{
                        marginRight: '20px',
                        textDecoration: salePrice ? 'line-through' : 'unset',
                    }}>
                        <LocalizedPrice price={regularPrice}/>
                    </div>
                    {salePrice && (
                        <div className={classes.priceDescription}>
                            <LocalizedString labelId="regularPrice"/>
                        </div>
                    )}
                </div>
                {salePrice && (
                    <div>
                        <div>
                            <LocalizedPrice price={salePrice}/>
                        </div>
                        <div className={classes.priceDescription}>
                            <LocalizedString labelId="readyToShip"/>
                        </div>
                    </div>
                )}
            </div>
            <div className={classes.skusContainer}>
                {skus.map(({name, sku}, i) => (
                    <React.Fragment key={i}>
                        <div>
                            {name}
                        </div>
                        <div style={name && {textAlign: 'end'}}>
                            {sku}
                        </div>
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};

const styles = (theme) => createStyles({
    priceDescription: {
        fontSize: '9px',
    },
    priceContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    skusContainer: {
        display: 'grid',
        gridTemplateColumns: 'max-content auto',
        fontFamily: 'eq3-book',
        fontSize: '9px',
        lineHeight: '15px',
    },
    container: {
        display: 'table',
    },
});

export default (compose(
    withStyles(styles),
)(readyToShipConfigurationPrice)) as React.ComponentType<IProps>;
