export enum ImageViewTypes {
    NO_TAG = 'NO_TAG',
    FRONT_VIEW = 'FRONT_VIEW',
    SIDE_VIEW = 'SIDE_VIEW',
    BACK_VIEW = 'BACK_VIEW',
    CORNER_VIEW = 'CORNER_VIEW',
    FEATURED_VIEW = 'FEATURED_VIEW',
    BIRDSEYE_VIEW = 'BIRDSEYE_VIEW',
    DETAIL_VIEW = 'DETAIL_VIEW',
}
