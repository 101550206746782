
import { createSelector } from 'reselect';
import { IMenuReduxSlice, INavItem, MenuTree, SITE_NAVIGATION_REDUX_KEY } from './models/redux';

const baseSelector = (state: IMenuReduxSlice) => state[SITE_NAVIGATION_REDUX_KEY];

export const selectCategoryListItems = (categoryId?: string) => createSelector(
    [baseSelector],
    ({ categories }) => categories.filter((c) => !!categoryId ? c.id === categoryId : true).sort((a, b) => a.pos - b.pos),
);

export const selectSubcategoriesListItems = (filters: { categoryId?: string, subcategoryId?: string } = {}) => createSelector(
    [baseSelector],
    ({ subcategories }) => subcategories.filter((subcategory) => {
            const { categoryId, subcategoryId } = filters;

            const categoryFilter = !!categoryId ? subcategory.categoryId === categoryId : true;
            const subcategoryFilter = !!subcategoryId ? subcategory.id === subcategoryId : true;

            return categoryFilter && subcategoryFilter;
    })
    .sort((a, b) => a.pos - b.pos),
);

export const selectProductLinesListItems = (filters: { subcategoryId?: string, productLineId?: string } = {}) => createSelector(
    [baseSelector],
    ({ productLines }) => productLines.filter((productLine) => {
        const { subcategoryId, productLineId } = filters;

        const subcategoryFilter = !!subcategoryId ? productLine.subcategoryId === subcategoryId : true;
        const productlineFilter = !!productLineId ? productLine.id === productLineId : true;

        return subcategoryFilter && productlineFilter;
    })
    .sort((a, b) => a.pos - b.pos),   
);

export const selectMenuAsTree = createSelector(
    [baseSelector],
    ({ categories, subcategories, productLines }): MenuTree => {
        return categories.map((c) => {
            return {
                ...c,
                type: 'category' as const,
                subcategories: subcategories.filter((sc) => sc.categoryId === c.id).map((sc) => {
                    return {
                        ...sc,
                        type: 'subcategory' as const,
                        productLines: productLines.filter((pl) => pl.subcategoryId === sc.id)
                            .map((pl) => ({...pl, type: 'productline' as const }))
                            .sort((a, b) => a.pos - b.pos),
                    };
                }).sort((a, b) => a.pos - b.pos),
            };
        }).sort((a, b) => a.pos - b.pos);
    },
);

export const selectNavItemsFn = createSelector(
    [baseSelector],
    ({ categories, subcategories, productLines }) => {
        return (...productLineIds: string[]) => {
            return productLines.filter((pl) => {
                if (productLineIds?.length) return productLineIds.includes(pl.id);
                return true;
            })
            .flatMap((pl) => {
                return subcategories
                    .filter((sc) => sc.id === pl.subcategoryId)
                    .flatMap((sc) => {
                        return categories
                            .filter((c) => c.id === sc.categoryId)
                            .flatMap((c): INavItem => {
                                return {
                                    category: c,
                                    subcategory: sc,
                                    productLine: pl,
                                };
                        });
                    });
            });
        };
    },
);
