import { IAuthState } from '@eq3/redux/auth/models/viewModels';
import { createReducer } from 'deox';
import Actions from './actions';

const { setLoggedInUser } = Actions;

const initialState: IAuthState = {
    loggedInUser: undefined,
};

export default createReducer(initialState, (handle) => [
    handle(setLoggedInUser, (state, { payload }) => ({
        ...state,
        loggedInUser: payload,
    })),
]);
