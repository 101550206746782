import { WithStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import LocalizedPrice from '../../../components/LocalizedPrice';

import nonPrintable from '@eq3/containers/retail/priceTags/util/nonPrintable';
import { createStyles, MenuItem, Select, withStyles } from '@material-ui/core';

interface IProps {
    product: any;
}

interface IState {
    selectedProductId: number | string;
    selectedConfigIds: Array<number | string>;
}

const maxNumberOfConfigs = 3;
class AccessoryTag extends React.Component<IProps & ReturnType<typeof mapStateToProps> & WithStyles<typeof styles>, IState> {
    private readonly emptyConfigs;
    public constructor(props) {
        super(props);
        this.emptyConfigs = Array(maxNumberOfConfigs).fill('', 0, maxNumberOfConfigs);
        this.state = {
            selectedProductId: -1,
            selectedConfigIds: this.emptyConfigs,
        };
    }

    private handleConfigSelected = (id: number) => (e: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState(({selectedConfigIds}) => {
            const updatedConfigIds = [...selectedConfigIds];
            updatedConfigIds[id] = e.target.value;

            return {selectedConfigIds: updatedConfigIds};
        });
    };

    private handleProductSelected = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedConfigIds = [...this.emptyConfigs];
        const {product: localizedCollection, locale} = this.props;

        if (localizedCollection[locale.id].products[e.target.value].configurations.length === 1) {
            selectedConfigIds.splice(0, 1, 0);
        }
        this.setState({selectedProductId: e.target.value, selectedConfigIds});
    }

    public componentDidMount(): void {
        const {product: localizedCollection, locale} = this.props;

        if (localizedCollection && localizedCollection[locale.id].products.length === 1) {
            this.setState({selectedProductId: 0});

            if (localizedCollection[locale.id].products[0].configurations.length === 1) {
                this.setState(({selectedConfigIds}) => {
                    const updatedConfigIds = [...selectedConfigIds];
                    updatedConfigIds[0] = 0;

                    return {selectedConfigIds: updatedConfigIds};
                });
            }
        }
    }

    public render() {
        const {classes, product, locale} = this.props;
        const localizedCollection = product[locale.id];
        const {selectedConfigIds, selectedProductId} = this.state;

        return (
            <div className={classes.container}>
                <div className={classes.collectionName}>
                    {localizedCollection.collectionName}
                </div>
                <div className={classes.productContent}>
                    {(localizedCollection.products.length > 1 || localizedCollection.products[0].name !== '') && (
                        <div className={classes.productSelect}>
                            <Select
                                className={classes.configSelect}
                                classes={{select: classnames(classes.configSelectLabel, classes.productNameLabel), icon: nonPrintable}}
                                onChange={this.handleProductSelected}
                                value={selectedProductId}
                                disableUnderline={true}>
                                    <MenuItem value={-1} key={-1} className={nonPrintable}/>
                                    {localizedCollection.products.map(({name}, i) => (<MenuItem value={i} key={i}>{name}</MenuItem>))}
                            </Select>
                        </div>
                    )}
                    {selectedProductId >= 0 && (
                        <div className={classes.configSelectors}>
                            {
                                selectedConfigIds.map((selectedConfigId, index) => (

                                    <React.Fragment
                                        key={index}
                                    >
                                        <Select
                                            className={classes.configSelect}
                                            onChange={this.handleConfigSelected(index)}
                                            value={selectedConfigId}
                                            classes={{select: classes.configSelectLabel, icon: nonPrintable}}
                                            disableUnderline={true}>

                                            <MenuItem value=""/>
                                            {localizedCollection.products[selectedProductId].configurations.map(({name}, i) => (
                                                <MenuItem value={i} key={i}>{name}</MenuItem>
                                            ))}
                                        </Select>

                                        <div className={classes.price}>
                                            {selectedConfigId !== '' && (
                                                <LocalizedPrice
                                                    price={localizedCollection.products[selectedProductId].configurations[selectedConfigId].price}/>
                                            )}
                                        </div>

                                    </React.Fragment>
                                ))
                            }
                        </div>
                    ) || <></>}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    locale: state.priceTags.locale,
});

const styles = () => createStyles({
    container: {
        padding: '0 0.12in',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
        textTransform: 'uppercase',
    },
    collectionName: {
        marginTop: '11pt',
        marginBottom: '5pt',
        fontFamily: 'eq3-book',
        fontSize: '23pt',
        lineHeight: '23pt',
        flex: '0 0 auto',
    },
    productNameLabel: {
        whiteSpace: 'normal',
    },
    configSelectLabel: {
        fontFamily: 'eq3-book',
        fontSize: '10pt',
        paddingTop: 0,
        paddingRight: '22px',
    },
    configSelect: {
        width: '100%',
        background: '#e1f6fd',
        '@media print': {
            background: 'transparent',
        },
        alignSelf: 'baseline',
    },
    productContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        flex: '1 0 auto',
    },
    productSelect: {
        flexGrow: 1,
    },
    configSelectors: {
        fontSize: '10pt',
        flexGrow: 1,
        display: 'grid',
        gridTemplateRows: `repeat(${maxNumberOfConfigs}, auto)`,
        gridTemplateColumns: 'auto max-content',
        fontFamily: 'eq3-book',
        alignItems: 'center',
    },
    price: {
        alignSelf: 'baseline',
    },
});

export default (compose(
    withStyles(styles),
    connect(mapStateToProps),
)(AccessoryTag)) as React.ComponentType<IProps>;
