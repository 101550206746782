import { createReducer } from 'deox';
import {setUsers} from '@eq3/redux/systemTools/actions';
import {IUser} from '@eq3/redux/userManagement/models';

export interface IEventLogState {
    types: {
        users: IUser[];
    };
}

export interface IEventLogReduxSlice {
    eventLog: IEventLogState;
}

const initialState: IEventLogState = {
    types: {
        users: [],
    }
};

export default createReducer(initialState, (handle) => [
    handle(setUsers, (state, { payload: { users } }) => {
        return ({
            ...state,
            types: {
                ...state.types,
                users,
            },
        });
    })
]);
