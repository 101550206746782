import { createActions, handleActions } from 'redux-actions';
import { errorNotification, notify } from './adminNotifications';
import { apiThunk } from './store';

const { setLoadingErpDataLog, setErpDataLog } = createActions({
    SET_LOADING_ERP_DATA_LOG: () => null,
    SET_ERP_DATA_LOG: (list) => list,
});

export default handleActions({
    [setLoadingErpDataLog]: (state) => ({...state, loading: true}),
    [setErpDataLog]: (state, { payload: list }) => ({ ...state, list, loading: false }),
}, { loading: true, list: [], data: {} });

export const syncErpData = () => async (dispatch, getStore, api: apiThunk) => {
    try {
        dispatch(setLoadingErpDataLog());
        api(dispatch, getStore, `/admin/erp/sync/`, 'GET');
    } catch (e) {
        dispatch(notify(errorNotification('Error synching ERP Data', e)));
        throw e;
    }
};

export const fetchSyncLogs = () => async (dispatch, getStore, api: apiThunk) => {
    try {
        const {data} = await api(dispatch, getStore, `/admin/erp/log`, 'GET');
        dispatch(setErpDataLog(data));
    } catch (e) {
        dispatch(notify(errorNotification('Error getting Sync Logs', e)));
        throw e;
    }
};
