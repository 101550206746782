import { StyleRules, WithStyles } from '@material-ui/core/styles';
import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { compose } from 'redux';
import LocalizedPrice from '../../../components/LocalizedPrice';
import { withStyles } from '@material-ui/core';
import RugSelect from './RugSelect';
import LocalizedString from '@eq3/containers/retail/priceTags/components/LocalizedString';
import RugPriceChange from './RugPriceChange';
import { LockButton } from '@eq3/containers/retail/priceTags/components/Buttons';
import nonPrintable from '@eq3/containers/retail/priceTags/util/nonPrintable';

interface IProps {
	product: any;
}
class RugCardTag extends React.Component<
	IProps &
		WithStyles<ReturnType<typeof styles>> &
		ReturnType<typeof mapStateToProps>
> {
	public state = {
		rugSelectPriceType: 'none',
		selectLock: true,
	};

	private handleRugSelectPriceType = ({
		target: { value: rugSelectPriceType },
	}) => {
		this.setState({ rugSelectPriceType });
	};

	private toggleLock = () => {
		this.setState(({ selectLock }) => ({ selectLock: !selectLock }));
	};

	public render() {
		const { product, classes, locale } = this.props;
		const localizedProduct = product[locale.id];
		const { rugSelectPriceType } = this.state;
		const name = localizedProduct.name.replace(/^Tapis\s*|\s*Rug$/i, '');
		return (
			<div className={classes.container}>
				<div className={classes.name}>{name}</div>
				<div className={classes.colours}>{localizedProduct.colours}</div>

				<div className={classes.description}>
					{localizedProduct.description}
				</div>
				<div className={this.state.selectLock ? classes.rugSelectWithLock : ''}>
					{this.state.selectLock && (
						<RugSelect
							value={rugSelectPriceType}
							onChange={this.handleRugSelectPriceType}
							variant="standard"
						/>
					)}
					<LockButton
						className={`${classes.lockButton} ${nonPrintable}`}
						onClick={this.toggleLock}
					/>
				</div>
				<div className={classes.sizes}>
					{localizedProduct.sizePrices &&
						localizedProduct.sizePrices.length &&
						localizedProduct.sizePrices.map(({ size, price }, i) => (
							<div key={i} className={classes.size}>
								<div className={classes.sizeDescription}>{size}</div>
								<div className={classes.sizePrice}>
									{rugSelectPriceType === 'normal' && (
										<div className={classes.sizePricefont}>
											<LocalizedPrice
												price={price}
												superCents
												classes={{ superCents: classes.superCents }}
											/>
										</div>
									)}
									{rugSelectPriceType === 'discount' && <RugPriceChange />}
								</div>
							</div>
						))}
					{localizedProduct.customSizePrice &&
						localizedProduct.customSizePrice.value > 0 && (
							<div className={classes.size}>
								<div className={classes.sizeDescription}>
									<LocalizedString labelId="custom_sizes" />
								</div>
								<div
									className={classnames(
										classes.sizePrice,
										classes.sizePricefont
									)}
								>
									<LocalizedPrice
										price={localizedProduct.customSizePrice}
										superCents
										classes={{ superCents: classes.superCents }}
									/>
									<span className={classes.perSqFoot}>
										<LocalizedString labelId="per_square_foot" />
									</span>
								</div>
							</div>
						)}
				</div>
			</div>
		);
	}
}

const styles = () =>
	({
		container: {
			margin: '0 0.25in',
			lineHeight: 'initial',
		},
		name: {
			paddingTop: '10pt',
			fontFamily: 'eq3-heavy',
			fontSize: '48pt',
		},
		colours: {
			fontSize: '14pt',
			fontFamily: 'eq3-heavy',
			textTransform: 'uppercase',
			letterSpacing: '0.05em',
			marginTop: '20pt',
		},
		description: {
			fontSize: '14pt',
			fontFamily: 'eq3-book',
		},
		customSizes: {
			fontSize: '14pt',
			fontFamily: 'eq3-book',
		},
		sizes: {
			fontSize: '16pt',
			fontFamily: 'eq3-book',
			display: 'flex',
			flexDirection: 'column',
			width: '100%',
		},
		size: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
			marginTop: '30pt',
		},
		sizePrice: {
			fontFamily: 'eq3-heavy',
			textAlign: 'right',
		},
		sizePricefont: {
			fontSize: '38pt',
		},
		perSqFoot: {
			fontSize: '16pt',
		},
		superCents: {
			fontSize: '.5em',
		},
		sizeDescription: {
			fontSize: '16pt',
		},
		lockButton: {
			position: 'absolute',
			right: -10,
			transform: 'scale(.5)',
		},
		rugSelectWithLock: {
			marginTop: '15px',
		},
	} as StyleRules);

const mapStateToProps = (state) => ({
	locale: state.priceTags.locale,
});

export default compose(
	connect(mapStateToProps),
	withStyles(styles)
)(RugCardTag) as any as React.ComponentType<IProps>;
