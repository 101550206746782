import React from 'react';

import AutosizeInput from 'react-input-autosize';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { i18n } from '../util/i18n';

interface IProps {
    className?: string;
    autosize?: boolean;
}

class PriceInput extends React.Component<ReturnType<typeof mapStateToProps> & IProps & NumberFormatProps> {
    public state = {
        isApplicablePrice: true,
        isFocused: false,
        value: '',
    };

    private valueChanged = (e) => {
        if (e.currentTarget.value === '') {
            this.setState({ isApplicablePrice: true });
        }
        this.setState({ value: e.currentTarget.value });
    };

    private priceInputKeyDown = (e) => {
        if (e.currentTarget.value === '' && /^[a-zA-Z]$/.test(e.key)) {
            this.setState({ isApplicablePrice: false });
        }
    }

    private onFocus = () => {
        this.setState({ isFocused: true });
    }

    private onBlur = () => {
        this.setState({ isFocused: false });
    }

    public render() {
        const { locale, autosize, dispatch, ...rest } = this.props;
        const { isApplicablePrice, isFocused, value } = this.state;
        const { className } = rest;

        return (isApplicablePrice ?
            (
                <NumberFormat
                    {...rest}
                    prefix={locale.isFrench ? '' : '$'}
                    suffix={locale.isFrench ? '$' : ''}
                    decimalSeparator={locale.isFrench ? ',' : '.'}
                    placeholder={i18n[locale.id].price_input_placeholder}
                    onKeyDown={this.priceInputKeyDown}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    autoFocus={isFocused}
                    customInput={autosize ? AutosizeInput : undefined}
                    inputClassName={className}
                />
            ) :
            (autosize ?
                (
                    <AutosizeInput
                        {...rest}
                        onChange={this.valueChanged}
                        autoFocus={isFocused}
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        inputClassName={className}
                        value={value}
                    />
                ) :
                (
                    <input
                        {...rest}
                        onChange={this.valueChanged}
                        autoFocus={isFocused}
                        onFocus={this.onFocus}
                        onBlur={this.onBlur} />
                )
            )
        );
    }
}

const mapStateToProps = (state) => ({
    locale: state.priceTags.locale,
});

export default (compose(
    connect(mapStateToProps),
)(PriceInput)) as any as React.ComponentType<IProps & NumberFormatProps>;
