import { createActions, handleActions } from 'redux-actions';
import Locales from '../utils/locales';
import { errorNotification, notify, successNotification } from './adminNotifications';
import { apiThunk } from './store';

export const { setLoading, setCurrentP65, setListing, saveP65 } = createActions({
    SET_LOADING: (loading) => ({ loading }),
    SET_CURRENT_P65: (p65 = '', details = {}) => ({ p65, details }),
    SET_LISTING: (listing) => ({ listing }),
    SAVE_P65: (p65, details) => ({ p65, details }),
});

export default handleActions({
    [setLoading]: (state, { payload: { loading } }) => ({ ...state, loading }),
    [setCurrentP65]: (state, { payload: { p65, details } }) => ({ ...state, p65, details }),
    [setListing]: (state, { payload: { listing } }) => ({ ...state, listing }),
    [saveP65]: (state, { payload: { p65, details } }) => ({ ...state, p65, details }),
}, { loading: true, listing: [], p65: '', details: {} });

export const fetchListing = () => async (dispatch, getStore, api: apiThunk) => {
    dispatch(setLoading(true));

    try {
        const { data } = await api(dispatch, getStore, '/admin/compliance/prop65', 'GET');
        const listing = data
            .map(({id, internalName}) => ({id, internalName}))
            .filter((v, i, arr) => i === arr.findIndex((t) => t.id === v.id));

        dispatch(setListing(listing));
    } catch (e) {
        console.error(e);
        dispatch(notify(errorNotification('Error getting prop65 listings', e)));
    }
    dispatch(setLoading(false));
};

export const fetchDetails = (id) => async (dispatch, getStore, api: apiThunk) => {
    dispatch(setLoading(true));

    try {
        const { data } = await api(dispatch, getStore, `/admin/compliance/prop65/${id}`, 'GET');
        const { internalName } = data[0];

        const details = data.reduce((acc, { locale, name, description }) => {
            return {
                [locale]: {
                    name,
                    description,
                },
                ...acc,
            };
        }, { id, internalName });

        dispatch(setCurrentP65(id, details));
    } catch (e) {
        console.error(e);
        dispatch(notify(errorNotification('Error getting prop65 listings', e)));
    }

    dispatch(setLoading(false));
};

export const save = (id, details) => async (dispatch, getStore, api: apiThunk) => {
    dispatch(setLoading(true));

    try {
        const { internalName } = details;
        const body = [];

        Locales.forEach((l) => {
            if (!details[l]) { return; }
            const { name, description } = details[l];

            body.push({
                id,
                locale: l,
                internalName,
                name,
                description,
            });
        });

        if (id) {
            await api(dispatch, getStore, `/admin/compliance/prop65/${id}`, 'PUT', body);
        } else {
            const { data }  = await api(dispatch, getStore, `/admin/compliance/prop65`, 'POST', body);
            id = data[0].id;
            dispatch(fetchDetails(id));
        }

        dispatch(notify(successNotification('Prop65 saved successfully')));
        dispatch(fetchListing());
    } catch (e) {
        console.error(e);
        dispatch(notify(errorNotification('Error saving prop65', e)));
    }

    dispatch(setLoading(false));
};

export const remove = (id) => async (dispatch, getStore, api: apiThunk) => {
    dispatch(setLoading(true));

    try {
        await api(dispatch, getStore, `/admin/compliance/prop65/${id}`, 'DELETE');
        dispatch(notify(successNotification('Prop65 deleted successfully')));
        dispatch(fetchListing());
        dispatch(setCurrentP65());
    } catch (e) {
        console.error(e);
        dispatch(notify(errorNotification('Error deleting prop65', e)));
    }

    dispatch(setLoading(false));
};
