import { createAction as $createAction } from 'deox';

const prefix = '[FINANCE]';
const createAction: typeof $createAction = (type, executor) => $createAction(`${prefix}\\${type}` as typeof type, executor);

export const setProcessTypes = createAction('SET_PROCESS_TYPES', (resolve) => {
    return (processTypes: string[]) => resolve({ processTypes });
});

export const setTransactionTypes = createAction('SET_TRANSACTION_TYPES', (resolve) => {
    return (transactionTypes: string[]) => resolve({ transactionTypes });
});

export const setTransactionSubtypes = createAction('SET_TRANSACTION_SUBTYPES', (resolve) => {
    return (transactionSubtypes: string[]) => resolve({ transactionSubtypes });
});

export type FinanceActionsType =
    | ReturnType<typeof setProcessTypes>
    | ReturnType<typeof setTransactionTypes>
    | ReturnType<typeof setTransactionSubtypes>;
