import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

interface ISignOutIconProps extends SvgIconProps {
    iconColour?: string;
}

const SignOutIcon = (props: ISignOutIconProps) => {
    const { iconColour, ...rest } = props;

    return (
        <SvgIcon viewBox="0 0 24 24" fill="none" {...rest}>
            <path fillRule="evenodd" clipRule="evenodd" d="M9.35259 16.449L10.8148 18L16 12.5L10.8148 7L9.35259 8.551L12.0281 11.4H2V13.6H12.0281L9.35259 16.449ZM18.8889 3H4.11111C2.93944 3 2 3.95 2 5.11111V9.33333H4.11111V5.11111H18.8889V19.8889H4.11111V15.6667H2V19.8889C2 21.05 2.93944 22 4.11111 22H18.8889C20.05 22 21 21.05 21 19.8889V5.11111C21 3.95 20.05 3 18.8889 3Z" />
        </SvgIcon>
    );
};

export default SignOutIcon;
