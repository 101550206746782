import Omit from '@eq3/utils/omit';
import TextField, { TextFieldProps as MuiTextFieldProps } from '@material-ui/core/TextField';
import { FastField, FastFieldConfig, FastFieldProps, Field, getIn } from 'formik';
import React from 'react';
import shouldUpdate$ from './shouldUpdate';

export type NumberFieldProps<T = any> = 
    & Partial<Omit<MuiTextFieldProps, 'name' | 'value'>> 
    & Partial<Pick<FastFieldConfig<T>, | 'validate' | 'shouldUpdate'>>
    & {
        name: string;
        label?: string;
        renderAsRegularField?: true;
    };

/**
 * Renders a Formik FastField `input[type="number"]` field.
 * 
 * You may pass a `renderAsRegularField` prop to render regular Formik Field.
 */
export default function Component<T = any>(props: NumberFieldProps<T>) {
    const { label, name, validate, onChange, onBlur, helperText, shouldUpdate = shouldUpdate$, renderAsRegularField, ...textFieldProps } = props;

    const FieldComponent = !!renderAsRegularField ? Field : FastField;

    return (
        <FieldComponent name={name} validate={validate} shouldUpdate={shouldUpdate}>
            {({ form, field }: FastFieldProps) => {

                const $onChange: MuiTextFieldProps['onChange'] = (e) => {
                    field.onChange(e);

                    if (onChange) {
                        onChange(e);
                    }
                };

                const $onBlur: MuiTextFieldProps['onBlur'] = (e) => {
                    field.onBlur(e);

                    if (onBlur) {
                        onBlur(e);
                    }
                };

                const errorText = getIn(form.errors, name);
                const $helperText = errorText || helperText;

                return (
                    <TextField {...textFieldProps as MuiTextFieldProps}
                        id={name}
                        name={name}
                        onChange={$onChange}
                        onBlur={$onBlur}
                        error={Boolean(errorText)}
                        helperText={$helperText}
                        label={label}
                        value={field.value === undefined ? '' : field.value}
                        type="number"
                    />
                );
            }}
        </FieldComponent>
    );
}
