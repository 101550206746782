import { ISearchSuggestion } from '@eq3/design/components/search/models';
import { Pagination } from '@eq3/utils/pagination';
import { createActions, handleActions } from 'redux-actions';
import { ThunkResult } from 'redux-thunk';
import { defer, iif, Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { errorNotification, notify, successNotification } from '../adminNotifications';
import { unwrap } from '../utils';
import {
    AllLocationTypes,
    ILocation,
    ILocationDetails,
    ILocationQuery,
    ILocationsReduxState,
    ILocationsState,
    ILocationStaffGroup,
    IModifyLocation,
    IRetailStaffMember,
    ITradeRepDto,
    LocationField,
    LocationType
} from './models';

export * from './selectors';

export const LOCATIONS_REDUX_KEY = 'locations';

export const { setTradeReps, setLocations } = createActions({
    SET_TRADE_REPS: (tradeReps) => tradeReps,
    SET_LOCATIONS: (locations) => locations,
}, {
    prefix: '[LOCATIONS]',
});

type LocationsThunkResult<T> = ThunkResult<T, ILocationsReduxState>;

const initialState: ILocationsState = {
    tradeRepsCache: {},
    locations: {},
};

export default handleActions({
    [setTradeReps]: (state, { payload: tradeReps }) => {
        return ({
            ...state,
            tradeRepsCache: tradeReps.reduce((a, b) => ({
                ...a,
                [b.userId]: b,
            }), state.tradeRepsCache)
        });
    },
    [setLocations]: (state, {payload: locations}) => {
        return {
            ...state,
            locations: {
                ...locations,
            },
        };
    }
}, initialState);

export const lookupLocation = (id: string): LocationsThunkResult<Observable<ILocation>> => (dispatch, getState, api) => {
    return defer(() => api<ILocation>(dispatch, getState, `/admin/locations/lookup/${id}`))
        .pipe(
            catchError((e) => {
                dispatch(notify(errorNotification('Error fetching location', e)));
                return throwError(e);
            }),
            unwrap
        );
};

export const fetchLocationDetails = (id: string): LocationsThunkResult<Observable<ILocationDetails>> => (dispatch, getState, api) => {
    return defer(() => api<ILocationDetails>(dispatch, getState, `/admin/locations/${id}`)).pipe(
        catchError((e) => {
            console.error(e);
            dispatch(notify(errorNotification('Error fetching location', e)));
            return throwError(e);
        }),
        unwrap
    );
};

export const getAllLocations = (
    filterLocationTypes: LocationType[] = AllLocationTypes,
    forceRefresh: boolean = false,
): LocationsThunkResult<Observable<Partial<Record<LocationType, ILocation[]>>>> => (
    dispatch,
    getState,
    api,
) => {
    const locations = getState()[LOCATIONS_REDUX_KEY].locations;

    // Refresh the data if one of the requested location types hasn't yet been loaded.
    const missingRequestedLocationType = filterLocationTypes!.some((requestedLocationType) => {
        const found = Object.keys(locations).some((loadedLocationType) => {
            return loadedLocationType === requestedLocationType;
        });
        return !found;
    });

    return iif(
        () => forceRefresh || Object.keys(locations).length === 0 || missingRequestedLocationType,
        defer(() => api<Partial<Record<LocationType, ILocation[]>>>(
            dispatch,
            getState,
            `/admin/locations/all?filterLocationTypes=${filterLocationTypes.join()}`,
            'GET',
        ))
            .pipe(
                map(({ data }) => data),
                tap((locations) => dispatch(setLocations(locations))),
                catchError((e) => {
                    dispatch(notify(errorNotification('Error retrieving Locations', e)));
                    return throwError(e);
                }),
            ),
        of(locations)
    );
};

export const searchLocations = (query: Partial<ILocationQuery> = {}): LocationsThunkResult<Observable<Pagination<ILocation>>> => (dispatch, getState, api) => {
    return defer(() => api<Pagination<ILocation>>(dispatch, getState, '/admin/locations', 'GET', undefined, { params: query }))
        .pipe(
            map(({ data }) => data),
            catchError((e) => {
                dispatch(notify(errorNotification('Error retrieving Locations', e)));
                return throwError(e);
            }),
        );
};

export const createLocation = (location: IModifyLocation, newImages: File[] = []): LocationsThunkResult<Observable<ILocationDetails>> => (dispatch, getState, api) => {
    const formData = new FormData();
    formData.append('form', new Blob([JSON.stringify(location)], { type: 'application/json' }));

    newImages.forEach((imageFile, index) => {
        formData.append(`newImages[${index}]`, imageFile);
    });

    return defer(() => api<ILocationDetails>(dispatch, getState, `/admin/locations`, 'POST', formData)).pipe(
        catchError((e) => {
            console.error(e);
            dispatch(notify(errorNotification('Error creating location', e)));
            return throwError(e);
        }),
        unwrap,
        tap(() => {
            dispatch(notify(successNotification('Location created successfully!')));
        })
    );
};

export const updateLocation = (locationId: string, location: IModifyLocation, newImages: File[] = [], imagesForDeletion: string[] = [], updateAltText): LocationsThunkResult<Observable<ILocationDetails>> => (dispatch, getState, api) => {
    const formData = new FormData();
    formData.append('form', new Blob([JSON.stringify(location)], { type: 'application/json' }));
    
    formData.append('updateAltText', new Blob([JSON.stringify(updateAltText || [])], { type: 'application/json' }));

    const imageMetadata = [];

    newImages.forEach((image, index) => {
        formData.append(`newImages[${index}]`, image.newImage);
        formData.append(`altText[${index}]`, location.altText[index]);
        formData.append(`fileNames[${index}]`, location.fileNames[index]);

        imageMetadata.push({
            altText: location.altText[index] || '',
            fileName: location.fileNames[index] || '',
            position: image.position,
            id: image.newImage.name
        });
    });

    formData.append(`imageMetaData`, new Blob([JSON.stringify(imageMetadata)], { type: 'application/json' }));

    imagesForDeletion.forEach((imageId, index) => {
        formData.append(`imageIdsToDelete[${index}]`, imageId);
    });

    return defer(() => api<ILocationDetails>(dispatch, getState, `/admin/locations/${locationId}`, 'PUT', formData)).pipe(
        catchError((e) => {
            console.error(e);
            dispatch(notify(errorNotification('Error updating location', e)));
            return throwError(e);
        }),
        tap(() => {
            dispatch(notify(successNotification('Location updated successfully!')));
        }),
        unwrap,
    );
};

export const getStaffGroupsForLocation = (locationId: string): LocationsThunkResult<Observable<ILocationStaffGroup[]>> => (dispatch, getState, api) => {
    return defer(() => api<ILocationStaffGroup[]>(dispatch, getState, `/admin/locations/${locationId}/get-staff-groups`, 'GET'))
        .pipe(
            map(({ data }) => data),
            catchError((e) => {
                console.error(e);
                dispatch(notify(errorNotification('Error fetching location staff groups.', e)));
                return throwError(e);
            }),
        );
};

export const getStaffForLocation = (locationId: string): LocationsThunkResult<Observable<IRetailStaffMember[]>> => (dispatch, getState, api) => {
    return getStaffGroupsForLocation(locationId)(dispatch, getState, api)
        .pipe(
            map((groups) =>
                groups.reduce((acc, group) => [...acc, ...group.staff], [] as IRetailStaffMember[])
                    .filter((staffMember, index, self) => self.findIndex((s) => s.userId === staffMember.userId) === index)
            )
        );
};

export const fetchAutoSuggestion = (field: LocationField, searchTerm: string, limit: number = 5): LocationsThunkResult<Observable<ISearchSuggestion<LocationField>>> => (dispatch, getState, api) => {
    return defer(() => api<ISearchSuggestion<LocationField>>(dispatch, getState, '/admin/locations/search-filters/auto-suggestions', 'GET', null, {
        params: {
            field,
            searchTerm,
            limit
        }
    })).pipe(unwrap);
};

export const fetchAllTradeReps = (): LocationsThunkResult<Observable<ITradeRepDto[]>> => (dispatch, getState, api) => {
    const { locations } = getState();

    return iif(() => !Object.keys(locations.tradeRepsCache).length,
        defer(() => api<ITradeRepDto[]>(dispatch, getState, '/admin/locations/trade-reps', 'GET')).pipe(
            catchError((e) => {
                console.error(e);
                dispatch(notify(errorNotification('Error fetching trade reps')));
                return throwError(e);
            }),
            unwrap,
            tap((tradeReps) => dispatch(setTradeReps(tradeReps))),
        ),
        of(Object.values(locations.tradeRepsCache))
    );
};

export const fetchUnassignedTradeReps = (): LocationsThunkResult<Observable<ITradeRepDto[]>> => (dispatch, getState, api) => {
    return defer(() => api<ITradeRepDto[]>(dispatch, getState, '/admin/locations/trade-reps', 'GET', null, { params: { unassigned: true } })).pipe(
        catchError((e) => {
            console.error(e);
            dispatch(notify(errorNotification('Error fetching trade reps')));
            return throwError(e);
        }),
        unwrap,
    );
};
