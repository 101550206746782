import { IAddress } from '@eq3/redux/orders/models';
import { CustomerShippingTypes } from '@eq3/redux/orderShipments';
import {ComponentItemType, IProductDefinition} from '@eq3/redux/productConfiguration';
import { IOrderable, IPaginatable, Pagination } from '@eq3/utils';
import {IProductDefinitionLookup} from '@eq3/redux/productConfiguration/models/IProductLookup';

export interface IOrderReplacementsState {
    orderReplacements: {
        currentReplacementsList: IReplacementOrder[];
        searchResults?: Pagination<IProductDefinitionLookup>;
        replacementReasons: IReplacementReasons;
    };
}

export interface IReplacementOrder {
    id: string;
    orderNumber: number;
    replacesOrderId?: string;
    replaces?: number;
    reason?: string;
    comment?: string;
    helpDeskTicketNumber?: string;
}

export interface ICreateReplacementOrder {
    reason: string;
    comment: string;
    shippingType: CustomerShippingTypes;
    swapDelivery: boolean;
    shippingAddress: IAddress;
    helpDeskTicketNumber: string;
    products: IReplacementLineItem[];
}

export interface IReplacementLineItem {
    replacedOrderLineItemId: string;
    qty: number;
    configuredProduct?: IProductDefinition;
}

export interface IOrderReplacementFormikValues extends ICreateReplacementOrder {
    // temp values which will be ignored before sending the form over the wire
    replacementItems: {
        [replacementItemId: string]: {
            qty: number;
            configuredProduct?: IProductDefinition;
        };
    };
    selectedReplacement?: IProductDefinition;
    currentReplacementQty?: number;
    currentReplacedLineItemId?: string;
    currentConfig?: any[];
}

export interface IReplacementItemTableData  {
    lineItemId: string;
    sku?: string;
    name?: string;
    qty: number;
    configuredProduct?: IProductDefinition;
}

// These can be referred in setFieldValue
// add other field names when needed
export enum ReplacementOrderFormFieldNames {
    CURRENT_CONFIG = 'currentConfig',
    CURRENT_REPLACED_LINE_ITEM_ID = 'currentReplacedLineItemId',
    CURRENT_REPLACEMENT_QTY = 'currentReplacementQty',
    REPLACEMENT_ITEMS = 'replacementItems',
    SELECTED_REPLACEMENT = 'selectedReplacement',
    SHIPPING_ADDRESS = 'shippingAddress',
}

export interface IComponentProduct {
    itemId: string;
    componentSku?: string;
    internalName: string;
    type: ComponentItemType;
}

export interface IReplacementReasons {
    [key: string]: string;
}

export function createReplacementReasonOptions(replacementReasons: IReplacementReasons) {
    return Object.entries(replacementReasons).map(([key, val]) => ({
        label: val,
        value: key,
    }));
}

export enum ReplacementQuery {
    HAS_REPLACEMENT = 'HAS_REPLACEMENT',
    IS_REPLACEMENT = 'IS_REPLACEMENT',
}

export const ReplacementQueryLabels: { [key in ReplacementQuery]: string } = {
    [ReplacementQuery.HAS_REPLACEMENT]: 'Has a Replacement',
    [ReplacementQuery.IS_REPLACEMENT]: 'Is a Replacement',
};

export interface IProductSearchSuggestion {
    queryParamName: string;
    label: string;
    suggestions: string[];
}

export interface IProductSearchQuery extends IOrderable, IPaginatable {
    orderLocale: string;
    sku?: string[];
    name?: string[];
    searchText?: string;
}

export const ProductSearchQueryMultiValueFilters: Array<keyof IProductSearchQuery> = [
    'sku',
    'name',
];
