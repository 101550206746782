import React from 'react';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';

// let errorMessage;

// const error = console.error.bind(console);
// console.error = function xerror() {
//     error.apply(console, arguments);
//     const args = Array.prototype.slice.call(arguments, 0);
//     args.unshift('ERROR:');
//     if (errorMessage !== args) {
//         errorMessage = args;
//         document.dispatchEvent(new Event('error'));
//     }
// };

class ErrorGeneralDialog extends React.Component {

    public state = {
        error: undefined,
    };

    public componentDidMount() {
        document.addEventListener('error', (e) => this.setState({ error: errorMessage }));
    }

    private clearError = () => this.setState({ error: undefined });

    public render() {
        const { error } = this.state;

        if (!error) {
            return null;
        }

        return (
            <Dialog open={!!error} onClose={this.clearError}>
                <DialogTitle >Error</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {error[1]}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.clearError} color="primary" autoFocus>Close</Button>
                </DialogActions>
            </Dialog>
        );
    }

}

export default (ErrorGeneralDialog);
