import { IOrderable, IPaginatable } from '@eq3/utils';

export interface IFinanceMessageDetails {
    message: IFinanceMessage;
    journalEntries: IJournalEntry[];
}

export interface IFinanceMessage {
    id: string;
    transactionTime: number;
    parentNumber: string;
    transactionReference: string;
    processType: string;
    transactionType: string;
    transactionSubtype: string;
    location?: IFinanceLocation;
    amount: {
        amount: number,
        currency: string,
    };
}

export interface IFinanceLocation {
    platformLocationId: string;
    locationNumber: number;
    locationName: string;
}

export interface IJournalEntry {
    id: string;
    messageId: string;
    transactionDivisionId: string;
    transactionTypeId: string;
    transactionNarrative: string;
    unitId: string;
    natureId: string;
    amount: {
        amount: number,
        currency: string,
    };
    glLineNarrative: string;
    transactionTime: number;
    insertDateTime: number;
}

export interface IGeneralLedgerSearchQuery extends IOrderable, IPaginatable {
    processType?: string[];
    transactionType?: string[];
    transactionSubtype?: string[];
    locationNumber?: number[];
    parentNumber?: string[];
    childNumber?: string[];
    searchText?: string;
}

export const GeneralLedgerSearchQueryMultipleValueFilters: Array<keyof IGeneralLedgerSearchQuery> = [
    'processType',
    'transactionType',
    'transactionSubtype',
    'locationNumber',
    'parentNumber',
    'childNumber',
];

export interface IGeneralLedgerSearchSuggestion {
    queryParamName: string;
    label: string;
    suggestions: string[];
}
