export enum OrderStatus {
    OPEN = 'OPEN',
    CLOSED = 'CLOSED',
    DRAFT = 'DRAFT',
}

export const OrderStatusTranslations = {
    OPEN: 'Open',
    CLOSED: 'Closed',
    DRAFT: 'Draft',
};