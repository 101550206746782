import { setOrderShipments } from '@eq3/redux/orderShipments/actions';
import { IShipmentsDictionary } from '@eq3/redux/orderShipments/models';
import { createReducer } from 'deox';

const initialState: IOrderShipmentsState = {
    lastUpdated: Date.now(),
    shipmentsDictionary: {},
};

export interface IOrderShipmentsState {
    lastUpdated: number;
    shipmentsDictionary: IShipmentsDictionary;
}

export interface IReduxSlice {
    orderShipments: IOrderShipmentsState;
}

export default createReducer(initialState, (handle) => [
    handle(setOrderShipments, (state, { payload }) => {
        return ({
            ...state,
            lastUpdated: Date.now(),
            shipmentsDictionary: {
                ...state.shipmentsDictionary,                
                ...payload,                
            },
        });
    }),
]);
