import { IRole, IRoleGroup } from '@eq3/redux/roleGroups/models';
import { createAction as $createAction } from 'deox';

const prefix = '[ROLE_GROUPS]';
const createAction: typeof $createAction = (type, executor) => $createAction(`${prefix}\\${type}` as typeof type, executor);

export const setRoleGroups = createAction('SET_ROLE_GROUPS', (resolve) => {
    return (roleGroups: IRoleGroup[]) => resolve({ roleGroups });
});

export const addRoleGroup = createAction('ADD_ROLE_GROUP', (resolve) => {
    return (newRoleGroup: IRoleGroup) => resolve({ newRoleGroup });
});

export const updateRoleGroupAction = createAction('UPDATE_ROLE_GROUP', (resolve) => {
    return (updatedRoleGroup: IRoleGroup) => resolve({ updatedRoleGroup });
});

export const deleteRoleGroupAction = createAction('DELETE_ROLE_GROUP_ACTION', (resolve) => {
    return (roleGroupId: string) => resolve({ roleGroupId });
});

export const setAllUserRoles = createAction('SET_ALL_USER_ROLES', (resolve) => {
    return (roles: IRole[]) => resolve({ roles });
});

export type RoleGroupsActionsType =
    | ReturnType<typeof setRoleGroups>
    | ReturnType<typeof deleteRoleGroupAction>
    | ReturnType<typeof setAllUserRoles>
    | ReturnType<typeof addRoleGroup>
    | ReturnType<typeof updateRoleGroupAction>;
