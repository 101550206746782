import React, {createContext, PropsWithChildren, useContext, useState} from 'react';

interface IAppBarContext {
    title: string;
    description: string;

    setTitle: (title: string) => void;
    setDescription: (description: string) => void;
}

export const AppBarContext = createContext<IAppBarContext>({
    title: '',
    description: '',
    setTitle: () => undefined,
    setDescription: () => undefined,
});

export const useAppBarContext = () => useContext(AppBarContext);

export const AppBarContextProvider = ({children}: PropsWithChildren<{}>) => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    const context: IAppBarContext = {
        title,
        setTitle,
        description,
        setDescription,
    };

    return (
        <AppBarContext.Provider value={context}>
            {children}
        </AppBarContext.Provider>
    );
};