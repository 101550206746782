import {Currency} from '@eq3/utils/currencyUtils';
import { Locale as DateLocale } from 'date-fns';
import { enCA, enUS, frCA } from 'date-fns/locale';

export enum Locale {
    CA_EN = 'CA_EN',
    CA_FR = 'CA_FR',
    US_EN = 'US_EN',
}

export type LocaleType = Locale.CA_EN | Locale.CA_FR | Locale.US_EN;

const locales = [Locale.CA_EN, Locale.CA_FR, Locale.US_EN];
export default locales;

export type Translation<T> = { [key in Locale]: T; };

export const localePaths: Translation<string> = {
    [Locale.CA_EN]: '/ca/en',
    [Locale.CA_FR]: '/ca/fr',
    [Locale.US_EN]: '/us/en',
};

export const localeStringFormat: Translation<string> = {
    [Locale.CA_EN]: 'en-CA',
    [Locale.CA_FR]: 'fr-CA',
    [Locale.US_EN]: 'en-US',
};

export const dateLocale: Translation<DateLocale> = {
    [Locale.CA_EN]: enCA,
    [Locale.CA_FR]: frCA,
    [Locale.US_EN]: enUS,
};

export enum Countries {
    CA = 'CA',
    US = 'US',
}

export const CountryLatLng = {
    [Countries.CA]: {
        lat: 49.9325796,
        lng: -97.0594764,
    },
    [Countries.US]: {
        lat: 37.0902,
        lng: -95.7129,
    },
};

export const CountryMap = new Map([
    [Countries.CA, 'Canada'],
    [Countries.US, 'United States'],
]);

export const ProvinceStates = {
    [Countries.CA]: ['Alberta', 'British Columbia', 'Manitoba', 'Newfoundland', 'New Brunswick', 'Nova Scotia', 'Northwest Territories', 'Nunavut', 'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan', 'Yukon Territory'],
    [Countries.US]: ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'District of Columbia', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'],
};

export const countrySelectList =  [...CountryMap.entries()].map(([key, val]) => ({ label: val, value: key }));

export const provinceSelectList = (country: string) => ProvincesStatesMap.filter((ps) => !country || ps.country === country).map(({ short, name }) => ({ label: name, value: short }));

export interface ICurrency {
    currency: Currency;
    locales: Locale[];
}

export const currencyCountriesMap: Record<Currency, Countries> = {
    CAD: Countries.CA,
    USD: Countries.US,
};

export const currencies: ICurrency[] = [
    { currency: 'CAD', locales: locales.filter((x) => x.startsWith(Countries.CA)) },
    { currency: 'USD', locales: locales.filter((x) => x.startsWith(Countries.US)) },
];

export const ProvincesStatesMap = [
    {short: 'AL', name: 'Alabama', country: 'US'},
    {short: 'AK', name: 'Alaska', country: 'US'},
    {short: 'AZ', name: 'Arizona', country: 'US'},
    {short: 'AR', name: 'Arkansas', country: 'US'},
    {short: 'CA', name: 'California', country: 'US'},
    {short: 'CO', name: 'Colorado', country: 'US'},
    {short: 'CT', name: 'Connecticut', country: 'US'},
    {short: 'DC', name: 'District of Columbia', alt: ['Washington DC', 'Washington D.C.'], country: 'US'},
    {short: 'DE', name: 'Delaware', country: 'US'},
    {short: 'FL', name: 'Florida', country: 'US'},
    {short: 'GA', name: 'Georgia', country: 'US'},
    {short: 'HI', name: 'Hawaii', country: 'US'},
    {short: 'ID', name: 'Idaho', country: 'US'},
    {short: 'IL', name: 'Illinois', country: 'US'},
    {short: 'IN', name: 'Indiana', country: 'US'},
    {short: 'IA', name: 'Iowa', country: 'US'},
    {short: 'KS', name: 'Kansas', country: 'US'},
    {short: 'KY', name: 'Kentucky', country: 'US'},
    {short: 'LA', name: 'Louisiana', country: 'US'},
    {short: 'ME', name: 'Maine', country: 'US'},
    {short: 'MD', name: 'Maryland', country: 'US'},
    {short: 'MA', name: 'Massachusetts', country: 'US'},
    {short: 'MI', name: 'Michigan', country: 'US'},
    {short: 'MN', name: 'Minnesota', country: 'US'},
    {short: 'MS', name: 'Mississippi', country: 'US'},
    {short: 'MO', name: 'Missouri', country: 'US'},
    {short: 'MT', name: 'Montana', country: 'US'},
    {short: 'NE', name: 'Nebraska', country: 'US'},
    {short: 'NV', name: 'Nevada', country: 'US'},
    {short: 'NH', name: 'New Hampshire', country: 'US'},
    {short: 'NJ', name: 'New Jersey', country: 'US'},
    {short: 'NM', name: 'New Mexico', country: 'US'},
    {short: 'NY', name: 'New York', country: 'US'},
    {short: 'NC', name: 'North Carolina', country: 'US'},
    {short: 'ND', name: 'North Dakota', country: 'US'},
    {short: 'OH', name: 'Ohio', country: 'US'},
    {short: 'OK', name: 'Oklahoma', country: 'US'},
    {short: 'OR', name: 'Oregon', country: 'US'},
    {short: 'PA', name: 'Pennsylvania', country: 'US'},
    {short: 'RI', name: 'Rhode Island', country: 'US'},
    {short: 'SC', name:  'South Carolina', country: 'US'},
    {short: 'SD', name:  'South Dakota', country: 'US'},
    {short: 'TN', name:  'Tennessee', country: 'US'},
    {short: 'TX', name:  'Texas', country: 'US'},
    {short: 'UT', name:  'Utah', country: 'US'},
    {short: 'VT', name:  'Vermont', country: 'US'},
    {short: 'VA', name:  'Virginia', country: 'US'},
    {short: 'WA', name:  'Washington', country: 'US'},
    {short: 'WV', name:  'West Virginia', country: 'US'},
    {short: 'WI', name:  'Wisconsin', country: 'US'},
    {short: 'WY', name:  'Wyoming', country: 'US'},
    {short: 'AS', name:  'American Samoa', country: 'US'},
    {short: 'GU', name:  'Guam', country: 'US'},
    {short: 'MP', name:  'Northern Mariana Islands', country: 'US'},
    {short: 'PR', name:  'Puerto Rico', country: 'US'},
    {short: 'UM', name:  'United States Minor Outlying Islands', country: 'US'},
    {short: 'VI', name:  'Virgin Islands', country: 'US'},
    {short: 'AB', name:  'Alberta', country: 'CA'},
    {short: 'BC', name:  'British Columbia', country: 'CA'},
    {short: 'MB', name:  'Manitoba', country: 'CA'},
    {short: 'NB', name:  'New Brunswick', country: 'CA'},
    {short: 'NL', name:  'Newfoundland and Labrador', country: 'CA', alt: ['Newfoundland', 'Labrador']},
    {short: 'NS', name:  'Nova Scotia', country: 'CA'},
    {short: 'NU', name:  'Nunavut', country: 'CA'},
    {short: 'NT', name:  'Northwest Territories', country: 'CA'},
    {short: 'ON', name:  'Ontario', country: 'CA'},
    {short: 'PE', name:  'Prince Edward Island', country: 'CA'},
    {short: 'QC', name:  'Quebec', country: 'CA'},
    {short: 'SK', name:  'Saskatchewan', country: 'CA'},
    {short: 'YT', name:  'Yukon', country: 'CA'},
];

export const ProvincesStatesMapCanadaFirst = [
    {short: 'AB', name:  'Alberta', country: 'CA'},
    {short: 'BC', name:  'British Columbia', country: 'CA'},
    {short: 'MB', name:  'Manitoba', country: 'CA'},
    {short: 'NB', name:  'New Brunswick', country: 'CA'},
    {short: 'NL', name:  'Newfoundland and Labrador', country: 'CA', alt: ['Newfoundland', 'Labrador']},
    {short: 'NS', name:  'Nova Scotia', country: 'CA'},
    {short: 'NU', name:  'Nunavut', country: 'CA'},
    {short: 'NT', name:  'Northwest Territories', country: 'CA'},
    {short: 'ON', name:  'Ontario', country: 'CA'},
    {short: 'PE', name:  'Prince Edward Island', country: 'CA'},
    {short: 'QC', name:  'Quebec', country: 'CA'},
    {short: 'SK', name:  'Saskatchewan', country: 'CA'},
    {short: 'YT', name:  'Yukon', country: 'CA'},
    {short: 'AL', name: 'Alabama', country: 'US'},
    {short: 'AK', name: 'Alaska', country: 'US'},
    {short: 'AZ', name: 'Arizona', country: 'US'},
    {short: 'AR', name: 'Arkansas', country: 'US'},
    {short: 'CA', name: 'California', country: 'US'},
    {short: 'CO', name: 'Colorado', country: 'US'},
    {short: 'CT', name: 'Connecticut', country: 'US'},
    {short: 'DC', name: 'District of Columbia', alt: ['Washington DC', 'Washington D.C.'], country: 'US'},
    {short: 'DE', name: 'Delaware', country: 'US'},
    {short: 'FL', name: 'Florida', country: 'US'},
    {short: 'GA', name: 'Georgia', country: 'US'},
    {short: 'HI', name: 'Hawaii', country: 'US'},
    {short: 'ID', name: 'Idaho', country: 'US'},
    {short: 'IL', name: 'Illinois', country: 'US'},
    {short: 'IN', name: 'Indiana', country: 'US'},
    {short: 'IA', name: 'Iowa', country: 'US'},
    {short: 'KS', name: 'Kansas', country: 'US'},
    {short: 'KY', name: 'Kentucky', country: 'US'},
    {short: 'LA', name: 'Louisiana', country: 'US'},
    {short: 'ME', name: 'Maine', country: 'US'},
    {short: 'MD', name: 'Maryland', country: 'US'},
    {short: 'MA', name: 'Massachusetts', country: 'US'},
    {short: 'MI', name: 'Michigan', country: 'US'},
    {short: 'MN', name: 'Minnesota', country: 'US'},
    {short: 'MS', name: 'Mississippi', country: 'US'},
    {short: 'MO', name: 'Missouri', country: 'US'},
    {short: 'MT', name: 'Montana', country: 'US'},
    {short: 'NE', name: 'Nebraska', country: 'US'},
    {short: 'NV', name: 'Nevada', country: 'US'},
    {short: 'NH', name: 'New Hampshire', country: 'US'},
    {short: 'NJ', name: 'New Jersey', country: 'US'},
    {short: 'NM', name: 'New Mexico', country: 'US'},
    {short: 'NY', name: 'New York', country: 'US'},
    {short: 'NC', name: 'North Carolina', country: 'US'},
    {short: 'ND', name: 'North Dakota', country: 'US'},
    {short: 'OH', name: 'Ohio', country: 'US'},
    {short: 'OK', name: 'Oklahoma', country: 'US'},
    {short: 'OR', name: 'Oregon', country: 'US'},
    {short: 'PA', name: 'Pennsylvania', country: 'US'},
    {short: 'RI', name: 'Rhode Island', country: 'US'},
    {short: 'SC', name:  'South Carolina', country: 'US'},
    {short: 'SD', name:  'South Dakota', country: 'US'},
    {short: 'TN', name:  'Tennessee', country: 'US'},
    {short: 'TX', name:  'Texas', country: 'US'},
    {short: 'UT', name:  'Utah', country: 'US'},
    {short: 'VT', name:  'Vermont', country: 'US'},
    {short: 'VA', name:  'Virginia', country: 'US'},
    {short: 'WA', name:  'Washington', country: 'US'},
    {short: 'WV', name:  'West Virginia', country: 'US'},
    {short: 'WI', name:  'Wisconsin', country: 'US'},
    {short: 'WY', name:  'Wyoming', country: 'US'},
    {short: 'AS', name:  'American Samoa', country: 'US'},
    {short: 'GU', name:  'Guam', country: 'US'},
    {short: 'MP', name:  'Northern Mariana Islands', country: 'US'},
    {short: 'PR', name:  'Puerto Rico', country: 'US'},
    {short: 'UM', name:  'United States Minor Outlying Islands', country: 'US'},
    {short: 'VI', name:  'Virgin Islands', country: 'US'},
];

export const getProvinceStatesOptions = (country: Countries) => ProvincesStatesMap
    .filter((ps) => ps.country === country)
    .map((ps) => ({
        label: ps.name,
        value: ps.short,
    }));

export type CurrenciesType = ICurrency['currency'];
export const usZipRegEx = /^[0-9]{5}(?:-[0-9]{4})?$/;
export const caPostalCodeRegEx = /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z]\s?[0-9][A-Z][0-9]$/;
export type Translated<T> = {
    [key in Locale]?: T
};
