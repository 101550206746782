import { createActions, handleActions } from 'redux-actions';
import { errorNotification, notify } from './adminNotifications';
import { apiThunk } from './store';

export interface IMarketingFeed {
    type: string;
    locale: Locale;
    s3Url: string;
}

const { setMarketingFeed } = createActions({
    SET_MARKETING_FEED: (list) => list,
});

export default handleActions({
    [setMarketingFeed]: (state, { payload: list }) => ({ ...state, list, loading: false }),
}, { loading: true, list: [], data: {} });

export const generateMarketingFeed = () => async (dispatch, getStore, api: apiThunk) => {
    try {
        const {data} = await api(dispatch, getStore, `/admin/marketing/generate/`, 'GET');
        dispatch(setMarketingFeed(data));
    } catch (e) {
        dispatch(notify(errorNotification('Error generating Marketing Feed', e)));
        throw e;
    }
};

export const fetchMarketingFeed = () => async (dispatch, getStore, api: apiThunk) => {
    try {
        const {data} = await api(dispatch, getStore, `/admin/marketing/feeds/`, 'GET');
        dispatch(setMarketingFeed(data));
    } catch (e) {
        dispatch(notify(errorNotification('Error getting Marketing Feed', e)));
        throw e;
    }
};
