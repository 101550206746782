import { TransactionHistoryListingDto, TransactionReportDto } from '@eq3-aws/payments-client';
import { paymentServiceClient } from '@eq3/clients/paymentService/paymentServiceClient';
import {
    IDailyTransactionListingReportRequest,
} from '@eq3/redux/pos';
import { ThunkResult } from 'redux-thunk';
import { defer, from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

type TransactionHistoryThunkResult<T> = ThunkResult<Observable<T>>;

export const searchPayments = (orderNumberOrReferenceQuery: string, includeSquare?: boolean): TransactionHistoryThunkResult<TransactionHistoryListingDto> => (dispatch, getState) => {
    return defer(() =>
        from(
            paymentServiceClient(dispatch, getState)
                .transactionHistoryController
                .searchPayments(orderNumberOrReferenceQuery, includeSquare)
        ).pipe(map((x) => x.data))
    );
};

export const getDailyTransactionListingReport = (request: IDailyTransactionListingReportRequest): TransactionHistoryThunkResult<TransactionReportDto> => (dispatch, getState) => {
    const { platformLocationId, startTimeEpoch, endTimeEpoch, includeSquare } = request;
    return defer(() =>
        paymentServiceClient(dispatch, getState)
            .transactionHistoryController
            .generateTransactionReport(platformLocationId, startTimeEpoch, endTimeEpoch, includeSquare)
    ).pipe(map((x) => x.data));
};
