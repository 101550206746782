import { IMetaData } from './models/apiModels';
import { errorNotification, notify, successNotification } from '@eq3/redux/adminNotifications';
import {
    addBannerAction,
    addCarouselItemAction,
    addFeaturedCategoryAction,
    addHeroImageAction,
    addMustHaveAction,
    deleteBannerAction as deleteBannerItemInRedux,
    deleteCarouselItemAction as deleteCarouselItemInRedux,
    deleteFeaturedCategoryAction as deleteFeaturedCategoryInRedux,
    deleteHeroImageAction as deleteHeroImageInRedux,
    deleteMustHaveAction as deleteMustHaveItemInRedux,
    HomePageActionsType,
    updateBannerAction as updateBannerInRedux,
    updateBannersListAction,
    updateCarouselItemAction as updateCarouselItemInRedux,
    updateCarouselListAction,
    updateFeaturedCategoriesListAction,
    updateFeaturedCategoryAction as updateFeaturedCategoryInRedux,
    updateHeroImageAction as updateHeroImageInRedux,
    updateHeroListAction,
    updateMetaDataListAction,
    updateMustHaveItemAction as updateMustHaveItemInRedux,
    updateMustHavesListAction,
} from '@eq3/redux/homepage/actions';
import {
    IBanner,
    ICarouselItem,
    IFeaturedCategory,
    IHeroImage,
    IMustHave,
    IUpdateMustHave,
} from '@eq3/redux/homepage/models/apiModels';
import {
    INewBanner,
    INewCarouselItem,
    INewFeaturedCategory,
    INewHeroImage, ISaveBanner,
    ISaveCarouselItem,
    ISaveFeaturedCategory,
    ISaveHeroImage,
    ISaveMustHave,
} from '@eq3/redux/homepage/models/viewModels';
import { IHomePageReduxSlice } from '@eq3/redux/homepage/reducers';
import { apiThunk } from '@eq3/redux/store';
import { Locale } from '@eq3/utils/locales';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { defer, from, iif, Observable, of } from 'rxjs';
import { catchError, flatMap, map, tap } from 'rxjs/operators';

type HomePageThunkResult<T> = ThunkAction<Observable<T>, IHomePageReduxSlice, apiThunk, HomePageActionsType>;
export type HomePageDispatch = ThunkDispatch<IHomePageReduxSlice, apiThunk, HomePageActionsType>;

export const getCarouselItems = (locale: Locale): HomePageThunkResult<ICarouselItem[]> => (dispatch, getState, api) => {
    return from(api<ICarouselItem[]>(dispatch, getState, `/admin/homepage/carousels`, 'GET', undefined, { params: { locale } }))
        .pipe(map((response) => response.data))
        .pipe(tap((carouselItems) => dispatch(updateCarouselListAction(locale, carouselItems))))
        .pipe(catchError((err) => {
            dispatch(notify(errorNotification('Error fetching carousel items.', err)));
            throw err;
        }));
};

export const sortCarouselItems = (sortIds: string[], locale: Locale): HomePageThunkResult<ICarouselItem[]> => (dispatch, getState, api) => {
    return from(api<ICarouselItem[]>(dispatch, getState, `/admin/homepage/carousels/sort`, 'PUT', { sortIds }, { params: { locale } }))
        .pipe(map((response) => response.data))
        .pipe(tap((carouselItems) => dispatch(updateCarouselListAction(locale, carouselItems))),
              tap(() => dispatch(notify(successNotification('Categories sorted!')))))
        .pipe(catchError((err) => {
            dispatch(notify(errorNotification('Error sorting carousel items.', err)));
            throw err;
        }));
};

export const createCarouselItem = (item: INewCarouselItem): HomePageThunkResult<ICarouselItem> => (dispatch, getState, api) => {
    const { carouselItem, locale, mobileImageFile, tabletImageFile, desktopImageFile } = item;

    const formData = new FormData();
    formData.append('desktopImageFile', desktopImageFile);
    formData.append('tabletImageFile', tabletImageFile);
    formData.append('mobileImageFile', mobileImageFile);

    Object.keys(carouselItem)
        .filter((key) => carouselItem[key])
        .forEach((key) => formData.append(key, carouselItem[key]));

    return from(api<ICarouselItem>(dispatch, getState, `/admin/homepage/carousels`, 'POST', formData, { params: { locale } }))
        .pipe(map((response) => response.data))
        .pipe(tap((carouselItem) => dispatch(addCarouselItemAction(locale, carouselItem))))
        .pipe(catchError((err) => {
            dispatch(notify(errorNotification('Error creating carousel item.', err)));
            throw err;
        }));
};

export const updateCarouselItem = (carouselData: ISaveCarouselItem): HomePageThunkResult<ICarouselItem> => (dispatch, getState, api) => {
    const { id, locale, carouselItem, desktopImageFile, tabletImageFile, mobileImageFile } = carouselData;

    // First, update the row.
    return from(api<ICarouselItem>(dispatch, getState, `/admin/homepage/carousels/${id}`, 'PUT', carouselItem, { params: { locale } }))
        .pipe(map((response) => response.data))
        .pipe(flatMap((carouselItem) => {
            // If we did not provide a new DESKTOP image, then just pass through the current carouselItem,
            // else upload the new image.
            return iif(
                () => !desktopImageFile,
                of(carouselItem),
                defer(() => {
                    const formData = new FormData();
                    formData.append('file', desktopImageFile!);

                    return from(api<ICarouselItem>(dispatch, getState, `/admin/homepage/carousels/${id}/DESKTOP`, 'PUT', formData))
                        .pipe(map((response) => response.data));
                }));
        }))
        .pipe(flatMap((carouselItem) => {
            // If we did not provide a new TABLET image, then just pass through the current carouselItem,
            // else upload the new image.
            return iif(
                () => !tabletImageFile,
                of(carouselItem),
                defer(() => {
                    const formData = new FormData();
                    formData.append('file', tabletImageFile!);

                    return from(api<ICarouselItem>(dispatch, getState, `/admin/homepage/carousels/${id}/TABLET`, 'PUT', formData))
                        .pipe(map((response) => response.data));
                }));
        }))
        // Same with the tablet image if we provided it.
        .pipe(flatMap((carouselItem) => {
            // If we did not provide a new MOBILE image, then just pass through the current carouselItem,
            // else upload the new image.
            return iif(
                () => !mobileImageFile,
                of(carouselItem),
                defer(() => {
                    const formData = new FormData();
                    formData.append('file', mobileImageFile!);

                    return from(api<ICarouselItem>(dispatch, getState, `/admin/homepage/carousels/${id}/MOBILE`, 'PUT', formData))
                        .pipe(map((response) => response.data));
                }));
        }))
        // Same with the mobile image if we provided it.
        .pipe(tap((carouselItem) => {
            dispatch(updateCarouselItemInRedux(locale, carouselItem));
            dispatch(notify(successNotification('Carousel item updated.')));
        }))
        .pipe(catchError((err) => {
            dispatch(notify(errorNotification('Error updating Carousel item.', err)));
            throw err;
        }));
};

export const deleteCarouselItem = (locale: Locale, id: string): HomePageThunkResult<void> => (dispatch, getState, api) => {
    return from(api<void>(dispatch, getState, `/admin/homepage/carousels/${id}`, 'DELETE'))
        .pipe(map((response) => response.data))
        .pipe(tap((_) => {
            dispatch(deleteCarouselItemInRedux(locale, id));
            dispatch(notify(successNotification('Carousel item delete.')));
        }))
        .pipe(catchError((err) => {
            dispatch(notify(errorNotification('Error deleting carousel item.', err)));
            throw err;
        }));
};

export const getHeroImages = (locale: Locale): HomePageThunkResult<IHeroImage[]> => (dispatch, getState, api) => {
    return from(api<IHeroImage[]>(dispatch, getState, `/admin/homepage/heroImages`, 'GET', undefined, { params: { locale } }))
        .pipe(map((response) => response.data))
        .pipe(tap((heroImages) => dispatch(updateHeroListAction(locale, heroImages))))
        .pipe(catchError((err) => {
            dispatch(notify(errorNotification('Error fetching hero images.', err)));
            throw err;
        }));
};

export const createHeroImage = (item: INewHeroImage): HomePageThunkResult<IHeroImage> => (dispatch, getState, api) => {
    const { locale, heroImage, mobileImageFile, tabletImageFile, desktopImageFile } = item;

    const formData = new FormData();
    formData.append('desktopImageFile', desktopImageFile);
    formData.append('tabletImageFile', tabletImageFile);
    formData.append('mobileImageFile', mobileImageFile);

    Object.keys(heroImage)
        .filter((key) => heroImage[key])
        .forEach((key) => formData.append(key, heroImage[key]));

    return from(api<IHeroImage>(dispatch, getState, `/admin/homepage/heroImages`, 'POST', formData, { params: { locale } }))
        .pipe(map((response) => response.data))
        .pipe(tap((heroImage) => dispatch(addHeroImageAction(locale, heroImage))))
        .pipe(catchError((err) => {
            dispatch(notify(errorNotification('Error creating hero image.', err)));
            throw err;
        }));
};

export const updateHeroImage = (heroData: ISaveHeroImage): HomePageThunkResult<IHeroImage> => (dispatch, getState, api) => {
    const { locale, id, heroImage, desktopImageFile, tabletImageFile, mobileImageFile } = heroData;

    // First, update the row.
    return from(api<IHeroImage>(dispatch, getState, `/admin/homepage/heroImages/${id}`, 'PUT', heroImage, { params: { locale } }))
        .pipe(map((response) => response.data))
        .pipe(flatMap((heroImage) => {
            // If we did not provide a new DESKTOP image, then just pass through the current hero image,
            // else upload the new image.
            return iif(
                () => !desktopImageFile,
                of(heroImage),
                defer(() => {
                    const formData = new FormData();
                    formData.append('file', desktopImageFile!);

                    return from(api<IHeroImage>(dispatch, getState, `/admin/homepage/heroImages/${id}/DESKTOP`, 'PUT', formData))
                        .pipe(map((response) => response.data));
                }));
        }))
        .pipe(flatMap((heroImage) => {
            // If we did not provide a new TABLET image, then just pass through the current hero image,
            // else upload the new image.
            return iif(
                () => !tabletImageFile,
                of(heroImage),
                defer(() => {
                    const formData = new FormData();
                    formData.append('file', tabletImageFile!);

                    return from(api<IHeroImage>(dispatch, getState, `/admin/homepage/heroImages/${id}/TABLET`, 'PUT', formData))
                        .pipe(map((response) => response.data));
                }));
        }))
        .pipe(flatMap((heroImage) => {
            // If we did not provide a new MOBILE image, then just pass through the current hero image,
            // else upload the new image.
            return iif(
                () => !mobileImageFile,
                of(heroImage),
                defer(() => {
                    const formData = new FormData();
                    formData.append('file', mobileImageFile!);

                    return from(api<IHeroImage>(dispatch, getState, `/admin/homepage/heroImages/${id}/MOBILE`, 'PUT', formData))
                        .pipe(map((response) => response.data));
                }));
        }))
        // Same with the mobile image if we provided it.
        .pipe(tap((heroImage) => {
            dispatch(updateHeroImageInRedux(locale, heroImage));
            dispatch(notify(successNotification('Hero image updated.')));
        }))
        .pipe(catchError((err) => {
            dispatch(notify(errorNotification('Error updating hero image.', err)));
            throw err;
        }));
};

export const deleteHeroImage = (locale: Locale, id: string): HomePageThunkResult<void> => (dispatch, getState, api) => {
    return from(api<void>(dispatch, getState, `/admin/homepage/heroImages/${id}`, 'DELETE'))
        .pipe(map((response) => response.data))
        .pipe(tap((_) => {
            dispatch(deleteHeroImageInRedux(locale, id));
            dispatch(notify(successNotification('Hero image item deleted.')));
        }))
        .pipe(catchError((err) => {
            dispatch(notify(errorNotification('Error deleting hero image.', err)));
            throw err;
        }));
};

export const getFeaturedCategories = (locale: Locale): HomePageThunkResult<IFeaturedCategory[]> => (dispatch, getState, api) => {
    return from(api<IFeaturedCategory[]>(dispatch, getState, `/admin/homepage/featured-categories`, 'GET', undefined, { params: { locale } }))
        .pipe(map((response) => response.data))
        .pipe(tap((featuredCategories) => dispatch(updateFeaturedCategoriesListAction(locale, featuredCategories))))
        .pipe(catchError((err) => {
            dispatch(notify(errorNotification('Error fetching featured categories ', err)));
            throw err;
        }));
};

export const sortFeaturedCategories = (locale: Locale, sortIds: string[]): HomePageThunkResult<IFeaturedCategory[]> => (dispatch, getState, api) => {
    return from(api<IFeaturedCategory[]>(dispatch, getState, `/admin/homepage/featured-categories/sort`, 'PUT', { sortIds }, { params: { locale } }))
        .pipe(map((response) => response.data))
        .pipe(tap((featuredCategories) => {
            dispatch(updateFeaturedCategoriesListAction(locale, featuredCategories));
            dispatch(notify(successNotification('Featured categories sorted.')));
        }))
        .pipe(catchError((err) => {
            dispatch(notify(errorNotification('Error sorting featured categories', err)));
            throw err;
        }));
};

export const deleteFeaturedCategory = (locale: Locale, id: string): HomePageThunkResult<void> => (dispatch, getState, api) => {
    return from(api(dispatch, getState, `/admin/homepage/featured-categories/${id}`, 'DELETE', undefined, { params: { locale } }))
        .pipe(map((response) => response.data))
        .pipe(tap((_) => {
            dispatch(deleteFeaturedCategoryInRedux(locale, id));
            dispatch(notify(successNotification('Featured category successfully deleted!')));
        }))
        .pipe(catchError((err) => {
            dispatch(notify(errorNotification('Error deleting featured category', err)));
            throw err;
        }));
};

export const createFeaturedCategory = (featuredCategoryData: INewFeaturedCategory): HomePageThunkResult<IFeaturedCategory> => (dispatch, getState, api) => {
    const { featuredCategory, locale } = featuredCategoryData;

    return from(api<IFeaturedCategory>(dispatch, getState, `/admin/homepage/featured-categories`, 'POST', featuredCategory, { params: { locale } }))
        .pipe(map((response) => response.data))
        .pipe(tap((newFeaturedCategory) => {
            dispatch(addFeaturedCategoryAction(locale, newFeaturedCategory));
            dispatch(notify(successNotification('Featured category successfully saved!')));
        }))
        .pipe(catchError((err) => {
            dispatch(notify(errorNotification('Error creating featured category', err)));
            throw err;
        }));
};

export const updateFeaturedCategory = (featuredCategoryData: ISaveFeaturedCategory): HomePageThunkResult<IFeaturedCategory> => (dispatch, getState, api) => {
    const { locale, id, featuredCategory, image, mobileImageFile, tabletImageFile, deleteDesktopImage, deleteMobileImage, deleteTabletImage, altText, fileName } = featuredCategoryData;
    return from(api<IFeaturedCategory>(dispatch, getState, `/admin/homepage/featured-categories/${id}`, 'PUT', { ...featuredCategory, deleteDesktopImage, deleteMobileImage, deleteTabletImage, altText, fileName}, { params: { locale } }))
        .pipe(map((response) => response.data))
        .pipe(flatMap((featuredCategory) => {
            // If we did not provide a new image, then just pass through the current featuredCategory,
            // else upload the new image.
            return iif(
                () => !image || deleteDesktopImage,
                of(featuredCategory),
                defer(() => {
                    const formData = new FormData();
                    formData.append('file', image!);

                    return from(api<IFeaturedCategory>(dispatch, getState, `/admin/homepage/featured-categories/${id}/image/DESKTOP`, 'PUT', formData))
                        .pipe(map((response) => response.data));
                }));
        }))
        .pipe(flatMap((featuredCategory) => {
            return iif(
                () => !mobileImageFile || deleteMobileImage,
                of(featuredCategory),
                defer(() => {
                    const formData = new FormData();
                    formData.append('file', mobileImageFile!);

                    return from(api<IFeaturedCategory>(dispatch, getState, `/admin/homepage/featured-categories/${id}/image/MOBILE`, 'PUT', formData))
                        .pipe(map((response) => response.data));
                }));
        }))
        .pipe(flatMap((featuredCategory) => {
            return iif(
                () => !tabletImageFile || deleteTabletImage,
                of(featuredCategory),
                defer(() => {
                    const formData = new FormData();
                    formData.append('file', tabletImageFile!);

                    return from(api<IFeaturedCategory>(dispatch, getState, `/admin/homepage/featured-categories/${id}/image/TABLET`, 'PUT', formData))
                        .pipe(map((response) => response.data));
                }));
        }))
        .pipe(tap((updatedFeaturedCategory) => {
            dispatch(updateFeaturedCategoryInRedux(locale, updatedFeaturedCategory));
            dispatch(notify(successNotification('Featured category updated.')));
        }))
        .pipe(catchError((err) => {
            dispatch(notify(errorNotification('Error updating featured category', err)));
            throw err;
        }));
};

export const getBanners = (locale: Locale): HomePageThunkResult<IBanner[]> => (dispatch, getState, api) => {
    return from(api<IBanner[]>(dispatch, getState, `/admin/homepage/banners`, 'GET', undefined, { params: { locale } }))
        .pipe(map((response) => response.data))
        .pipe(tap((banners) => {
            dispatch(updateBannersListAction(locale, banners));
        }))
        .pipe(catchError((err) => {
            dispatch(notify(errorNotification('Error fetching banners', err)));
            throw err;
        }));
};

export const createBanner = (banner: INewBanner): HomePageThunkResult<IBanner> => (dispatch, getState, api) => {
    const { locale, bannerItem } = banner;
    return from(api<IBanner>(dispatch, getState, `/admin/homepage/banners`, 'POST', bannerItem, { params: { locale } }))
        .pipe(map((response) => response.data))
        .pipe(tap((newBanner) => {
            dispatch(addBannerAction(locale, newBanner));
            dispatch(notify(successNotification('Banner created.')));
        }))
        .pipe(catchError((err) => {
            dispatch(notify(errorNotification('Error creating banner.', err)));
            throw err;
        }));
};

export const updateBanner = (banner: ISaveBanner): HomePageThunkResult<IBanner> => (dispatch, getState, api) => {
    const { locale, bannerItem } = banner;
    return from(api<IBanner>(dispatch, getState, `/admin/homepage/banners/${banner.id}`, 'PUT', bannerItem, { params: { locale } }))
        .pipe(map((response) => response.data))
        .pipe(tap((updatedBanner) => {
            dispatch(updateBannerInRedux(locale, updatedBanner));
            dispatch(notify(successNotification('Banner updated.')));
        }))
        .pipe(catchError((err) => {
            dispatch(notify(errorNotification('Error updating banner.', err)));
            throw err;
        }));
};

export const deleteBanner = (locale: Locale, id: string): HomePageThunkResult<void> => (dispatch, getState, api) => {
    return from(api(dispatch, getState, `/admin/homepage/banners/${id}`, 'DELETE'))
        .pipe(map((response) => response.data))
        .pipe(tap((_) => {
            dispatch(deleteBannerItemInRedux(locale, id));
            dispatch(notify(successNotification('Banner deleted.')));
        }))
        .pipe(catchError((err) => {
            dispatch(notify(errorNotification('Error deleting banner.', err)));
            throw err;
        }));
};

export const getMustHaves = (locale: Locale): HomePageThunkResult<IMustHave[]> => (dispatch, getState, api) => {
    return from(api<IMustHave[]>(dispatch, getState, `/admin/homepage/must-haves`, 'GET', undefined, { params: { locale } }))
        .pipe(map((response) => response.data))
        .pipe(tap((mustHaves) => {
            dispatch(updateMustHavesListAction(locale, mustHaves));
        }))
        .pipe(catchError((err) => {
            dispatch(notify(errorNotification('Error fetching must-haves.', err)));
            throw err;
        }));
};

export const sortMustHaves = (locale: Locale, sortIds: string[]): HomePageThunkResult<IMustHave[]> => (dispatch, getState, api) => {
    return from(api<IMustHave[]>(dispatch, getState, `/admin/homepage/must-haves/sort`, 'PUT', { sortIds }, { params: { locale } }))
        .pipe(map((response) => response.data))
        .pipe(tap((mustHaves) => {
            dispatch(updateMustHavesListAction(locale, mustHaves));
            dispatch(notify(successNotification('Must-haves sorted')));
        }))
        .pipe(catchError((err) => {
            dispatch(notify(errorNotification('Error sorting must-haves.', err)));
            throw err;
        }));
};

export const createMustHave = (locale: Locale, mustHave: IUpdateMustHave): HomePageThunkResult<IMustHave> => (dispatch, getState, api) => {
    return from(api<IMustHave>(dispatch, getState, `/admin/homepage/must-haves/`, 'POST', mustHave, { params: { locale } }))
        .pipe(map((response) => response.data))
        .pipe(tap((newMustHave) => {
            dispatch(addMustHaveAction(locale, newMustHave));
            dispatch(notify(successNotification('Must-have created!')));
        }))
        .pipe(catchError((err) => {
            dispatch(notify(errorNotification('Error creating must-have.', err)));
            throw err;
        }));
};

export const updateMustHave = (saveMustHave: ISaveMustHave): HomePageThunkResult<IMustHave> => (dispatch, getState, api) => {
    const { locale, id, mustHave, imageFile, mobileImageFile, tabletImageFile, deleteDesktopImage, deleteMobileImage, deleteTabletImage  } = saveMustHave;

    return from(api<IMustHave>(dispatch, getState, `/admin/homepage/must-haves/${id}`, 'PUT', {...mustHave, deleteDesktopImage, deleteMobileImage, deleteTabletImage}, { params: { locale } }))
        .pipe(map((response) => response.data))
        .pipe(flatMap((updatedMustHave) => {
            // If we did not provide a new image, then just pass through the current must-have instance,.
            // Else upload the new image.
            return iif(
                () => !imageFile || deleteDesktopImage,
                of(updatedMustHave),
                defer(() => {
                    const formData = new FormData();
                    formData.append('file', imageFile!);

                    return from(api<IMustHave>(dispatch, getState, `/admin/homepage/must-haves/${id}/image/DESKTOP`, 'PUT', formData))
                        .pipe(map((response) => response.data));
                }),
            );
        }))
        .pipe(flatMap((updatedMustHave) => {
            return iif(
                () => !mobileImageFile || deleteMobileImage,
                of(updatedMustHave),
                defer(() => {
                    const formData = new FormData();
                    formData.append('file', mobileImageFile!);

                    return from(api<IMustHave>(dispatch, getState, `/admin/homepage/must-haves/${id}/image/MOBILE`, 'PUT', formData))
                        .pipe(map((response) => response.data));
                }),
            );
        }))
        .pipe(flatMap((updatedMustHave) => {
            return iif(
                () => !tabletImageFile || deleteTabletImage,
                of(updatedMustHave),
                defer(() => {
                    const formData = new FormData();
                    formData.append('file', tabletImageFile!);

                    return from(api<IMustHave>(dispatch, getState, `/admin/homepage/must-haves/${id}/image/TABLET`, 'PUT', formData))
                        .pipe(map((response) => response.data));
                }),
            );
        }))
        .pipe(tap((updatedMustHave) => {
            dispatch(updateMustHaveItemInRedux(locale, updatedMustHave));
            dispatch(notify(successNotification('Must-have item updated.')));
        }))
        .pipe(catchError((err) => {
            dispatch(notify(errorNotification('Error updating must-have item', err)));
            throw err;
        }));
};

export const deleteMustHave = (locale: Locale, id: string, title: string): HomePageThunkResult<void> => (dispatch, getState, api) => {
    return from(api<void>(dispatch, getState, `/admin/homepage/must-haves/${id}`, 'DELETE', undefined, { params: { locale } }))
        .pipe(map((response) => response.data))
        .pipe(tap((_) => {
            dispatch(deleteMustHaveItemInRedux(locale, id));
            dispatch(notify(successNotification(`${title} successfully deleted!`)));
        }))
        .pipe(catchError((err) => {
            dispatch(notify(errorNotification('Error deleting must-have item', err)));
            throw err;
        }));
};

export const getMetaData = (): HomePageThunkResult<IMetaData[]> => (dispatch, getState, api) => {
    return from(api<IMetaData[]>(dispatch, getState, `/admin/homepage/meta-data`, 'GET', undefined))
        .pipe(map((response) => response.data))
        .pipe(tap((data) => dispatch(updateMetaDataListAction(data))))
        .pipe(catchError((err) => {
            dispatch(notify(errorNotification('Error fetching metadata ', err)));
            throw err;
        }));
};

export const updateMetaData = (data: IMetaData[]): HomePageThunkResult<IMetaData[]> => (dispatch, getState, api) => {
    return from(api<IMetaData[]>(dispatch, getState, `/admin/homepage/meta-data`, 'PUT', data))
        .pipe(map((response) => response.data))
        .pipe(tap(() => {
            dispatch(notify(successNotification('Metadata updated.')));
        }))
        .pipe(catchError((err) => {
            dispatch(notify(errorNotification('Error updating metadata.', err)));
            throw err;
        }));
};