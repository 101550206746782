import { createActions, handleActions } from 'redux-actions';
import { errorNotification, notify } from './adminNotifications';
import { apiThunk } from './store';

export const { updateMenu } = createActions({
    UPDATE_MENU: (d) => d,
});

export default handleActions({
    [updateMenu]: (state, { payload: data }) => ({ ...state, list: data, loading: false }),
}, { list: [], data: {}, locale: {}, loading: true });

export const fetchMenu = () => async (dispatch, getStore, api: apiThunk) => {
    try {
        const {data: menu} = await api(dispatch, getStore, '/admin/categories');
        dispatch(updateMenu(menu));
        return menu;
    } catch (e) {
        dispatch(notify(errorNotification('Error getting Categories for Menu', e)));
        throw e;
    }
};
