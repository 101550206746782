import { createReducer } from 'deox';
import { setOrder, setOrderAddress, setOrderBillingAddress, setOrderInjectionStatus, setOrders, setOrderSearchFilters } from './actions';
import { IOrder, IOrderInjectionStatus, IOrderListing, IOrderQuery } from './models';

export interface IOrderReduxSlice {
    orders: {
        loading: boolean;
        list: IOrderListing[];
        count: number;
        data: Record<string, IOrder>;
        injectionStatuses: Record<string, IOrderInjectionStatus>;
        searchFilters: Partial<IOrderQuery>;
    };
}

export const OrderSearchFilterInitialState: IOrderReduxSlice['orders'] = {
    loading: false,
    list: [],
    count: 0,
    data: {},
    injectionStatuses: {},
    searchFilters: {},
};

export default createReducer(OrderSearchFilterInitialState, (handle) => ([
    handle(setOrders, (state, { payload: { list, count } }) => ({ ...state, list, count })),
    handle(setOrder, (state, { payload: order }) => ({
        ...state,
        data: {
            ...state.data,
            [order.id]: order,
        },
        list: state.list.map((orderListing) => {
            if (orderListing.id === order.id) {
                return { ...orderListing, status: order.status };
            }

            return orderListing;
        }),
    })),
    handle(setOrderAddress, (state, { payload: { orderId, address }}) => ({
        ...state,
        data: {
            ...state.data,
            [orderId]: {
                ...state.data[orderId],
                address,
            },
        },
    })),
    handle(setOrderBillingAddress, (state, { payload: { orderId, address }}) => ({
        ...state,
        data: {
            ...state.data,
            [orderId]: {
                ...state.data[orderId],
                billingAddress: address,
            },
        },
    })),
    handle(setOrderInjectionStatus, (state, { payload: { orderId, injectionStatus } }) => ({
        ...state,
        injectionStatuses: {
            ...state.injectionStatuses,
            [orderId]: injectionStatus,
        },
    })),
    handle(setOrderSearchFilters, (state, { payload: searchFilters }) => ({...state, searchFilters})),
]));
