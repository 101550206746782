import {IQuote} from '@eq3/redux/quotes/models';
import {createActionCreator} from 'deox';

const createAction: typeof createActionCreator = (type, executor) => createActionCreator(`[QUOTES]\\${type}` as typeof type, executor);

const actions = {
    setCurrentQuote: createAction('SET_CURRENT_QUOTE', (resolve) => (quoteId: IQuote['quoteId']) => resolve(({quoteId}))),
    setQuote: createAction('SET_QUOTE', (resolve) => (quote: IQuote) => resolve(({quote}))),
    closeQuote: createAction('CLOSE_QUOTE', (resolve) => (quoteId: IQuote['quoteId']) => resolve(({quoteId}))),
};

type Actions = typeof actions;
export type QuoteActionType = ReturnType<Actions[keyof Actions]>;
export const { setQuote, setCurrentQuote, closeQuote} = actions;
