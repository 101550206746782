import {Canada, ILocale, US} from './util/i18n';

const Materials = {
    fabric: 'fabric',
    leather: 'leather',
};

const Grades = {
    grade10: {
        id: 'grade10',
        material: Materials.fabric,
        isPatternless: true,
        [Canada]: {
            name: 'grade10',
        },
        [US]: {
            name: 'grade10US',
        },
    },
    grade20: {
        id: 'grade20',
        material: Materials.fabric,
        isPatternless: true,
        [Canada]: {
            name: 'grade20',
        },
        [US]: {
            name: 'grade20US',
        },
    },
    grade30: {
        id: 'grade30',
        material: Materials.fabric,
        isPatternless: true,
        [Canada]: {
            name: 'grade30',
        },
        [US]: {
            name: 'grade30US',
        },
    },
    grade40: {
        id: 'grade40',
        material: Materials.fabric,
        isPatternless: true,
        [Canada]: {
            name: 'grade40',
        },
        [US]: {
            name: 'grade40US',
        },
    },
    grade50: {
        id: 'grade50',
        material: Materials.fabric,
        isPatternless: true,
        [Canada]: {
            name: 'grade50',

        },
        [US]: {
            name: 'grade50US',

        },
    },
    grade60: {
        id: 'grade60',
        material: Materials.fabric,
        isPatternless: true,
        [Canada]: {
            name: 'grade60',

        },
        [US]: {
            name: 'grade60US',

        },
    },
    grade70: {
        id: 'grade70',
        material: Materials.fabric,
        isPatternless: true,
        [Canada]: {
            name: 'grade70',

        },
        [US]: {
            name: 'grade70US',

        },
    },
    grade41: {
        id: 'grade41',
        material: Materials.fabric,
        isPatternless: false,
        [Canada]: {
            name: 'grade41',
        },
        [US]: {
            name: 'grade41US',
        },
    },
    gradeF: {
        id: 'gradeF',
        material: Materials.fabric,
        isPatternless: true,
        [Canada]: {
            name: 'gradeF',
        },
        [US]: {
            name: 'gradeFUS',
        },
    },
    gradeI: {
        id: 'gradeI',
        material: Materials.fabric,
        isPatternless: true,
        [Canada]: {
            name: 'gradeI',
        },
        [US]: {
            name: 'gradeIUS',
        },
    },
    grade100: {
        id: 'grade100',
        material: Materials.leather,
        isPatternless: true,
        [Canada]: {
            name: 'grade100',

        },
        [US]: {
            name: 'grade100US',

        },
    },
    grade200: {
        id: 'grade200',
        material: Materials.leather,
        isPatternless: true,
        [Canada]: {
            name: 'grade200',

        },
        [US]: {
            name: 'grade200US',

        },
    },
    grade300: {
        id: 'grade300',
        material: Materials.leather,
        isPatternless: true,
        [Canada]: {
            name: 'grade300',
        },
        [US]: {
            name: 'grade300US',
        },
    },
    grade400: {
        id: 'grade400',
        material: Materials.leather,
        isPatternless: true,
        [Canada]: {
            name: 'grade400',
        },
        [US]: {
            name: 'grade400US',
        },
    },
    grade500: {
        id: 'grade500',
        material: Materials.leather,
        isPatternless: true,
        [Canada]: {
            name: 'grade500',
        },
        [US]: {
            name: 'grade500US',
        },
    },
    grade600: {
        id: 'grade600',
        material: Materials.leather,
        isPatternless: true,
        [Canada]: {
            name: 'grade600',

        },
        [US]: {
            name: 'grade600US',

        },
    },
    grade700: {
        id: 'grade700',
        material: Materials.leather,
        isPatternless: true,
        [Canada]: {
            name: 'grade700',

        },
        [US]: {
            name: 'grade700US',

        },
    },
    grade800: {
        id: 'grade800',
        material: Materials.leather,
        isPatternless: true,
        [Canada]: {
            name: 'grade800',

        },
        [US]: {
            name: 'grade800US',

        },
    },
    gradeM: {
        id: 'gradeM',
        material: Materials.leather,
        isPatternless: true,
        [Canada]: {
            name: 'gradeM',

        },
        [US]: {
            name: 'gradeMUS',

        },
    },
};

export default Grades;

export const getLowestGradePrice = (product: any, locale: ILocale) => {

    return Math.min(...Object.values(Grades)
        .map((grade) => +product[grade[locale.country].name])
        .filter((gradePrice) => typeof gradePrice === 'number' && !isNaN(gradePrice)));
};
