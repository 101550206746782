import { handleError } from '@eq3/utils/exceptionUtils';
import { defaultPageSize } from '@eq3/utils/pagination';
import { AnyAction } from 'redux';
import { createActions, handleActions } from 'redux-actions';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { IProductInstance } from './productConfiguration';
import { apiThunk } from './store';

type ThunkResult<T> = ThunkAction<Promise<T>, IProductInstancesState, apiThunk, AnyAction>;
export type ProductInstancesDispatch = ThunkDispatch<IProductInstancesState, apiThunk, AnyAction>;

const initialSearchResults = {
    items: [] as IProductInstance[],
    page: 0,
    pageSize: defaultPageSize,
    totalCount: 0,
};

export const initialState = {
    loading: true,
    data: new Array<IProductInstance>(),
    categories: new Array<ICategory>(),
    searchResults: initialSearchResults,
};

export interface IProductInstancesState {
    productInstances: typeof initialState;
}

export const { setProductInstances, toggleLoading, setCategories, setGradeTree, setSearchedProductInstances } = createActions({
    SET_PRODUCT_INSTANCES: (data) => data,
    TOGGLE_LOADING: (loading?: boolean) => loading,
    SET_CATEGORIES: (categories) => categories,
    SET_SEARCHED_PRODUCT_INSTANCES: (paginatedData) => paginatedData,
}, { prefix: '[PRODUCT_INSTANCES]' });

export default handleActions({
    [setProductInstances]: (state, { payload: data }) => ({ ...state, data, loading: false }),
    [toggleLoading]: (state = initialState, { payload: loading }) => ({ ...state, loading: typeof loading === 'boolean' ? loading : !state.loading }),
    [setCategories]: (state = initialState, { payload: categories }) => ({ ...state, categories }),
    [setSearchedProductInstances]: (state, { payload: searchResults }) => ({ ...state, searchResults }),
}, initialState);

// export const fetchCategories = (): ThunkResult<void> => async (dispatch, getStore, api: apiThunk) => {
//     try {
//         const { data } = await api(dispatch, getStore, '/admin/categories');
//         dispatch(setCategories(data));
//     } catch (e) {
//         handleError(dispatch, e, 'Error getting Categories');
//         throw e;
//     }
// };

export const searchProductInstances = (query: IInstanceSearchQuery = { pageSize: defaultPageSize, pageIndex: 0 }): ThunkResult<void> => async (dispatch, getStore, api: apiThunk) => {
    if (!query.searchTerm) {
        dispatch(setSearchedProductInstances(initialSearchResults));
        return;
    }
    try {
        const { data }: { data: typeof initialState['searchResults'] } = await api(dispatch, getStore, `/admin/products/instances/search`, 'GET', undefined, { params: query });
        dispatch(setSearchedProductInstances(data));
    } catch (e) {
        handleError(dispatch, e, 'Error getting Product Definition');
    }
};

export interface IInstanceSearchQuery {
    searchTerm?: string;
    pageSize?: number;
    pageIndex?: number;
}

export interface ICategory {
    id: string;
    internalName: string;
    locales: Locale[];
    subcategories: ISubcategory[];
}

export interface ISubcategory extends ICategory {
    productLines: IProductLine[];
}

export interface IProductLine extends Omit<ICategory, 'subcategories'> { }
