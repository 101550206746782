import { ManufacturerState } from '@eq3/redux/manufacturers';
import { TextBoxSection } from '@eq3/redux/textBlocksAdmin/models';
import { defaultPageSize, Pagination } from '@eq3/utils/pagination';
import { createReducer } from 'deox';
import {
    clearCurrentProduct,
    setComponentItems,
    setComponentItemsQuery,
    setCurrentProduct,
    setCurrentProductLoading,
    setGtin, setProp65Options,
    setTextBlockVariants
} from './actions';
import {
    IComponentItemListItem,
    IComponentItemsQuery,
    IProduct
} from './models';

const initialState = {
    productConfiguration: {
        loading: false,
        prop65s: new Array<{ label: string; value: any; }>(),
        textBlockVariants: new Array<TextBoxSection>(),
        componentItems: {
            query: {
                pageIndex: 0,
                pageSize: defaultPageSize,
            } as IComponentItemsQuery,
            paginated: {
                items: new Array<IComponentItemListItem>(),
                page: 0,
                pageSize: defaultPageSize,
                totalCount: 0,
            } as Pagination<IComponentItemListItem>,
        },
        current: {} as IProduct,
    },
};

export type ProductConfigurationState = typeof initialState;

export interface IReduxState {
    productConfiguration: ProductConfigurationState;
    manufacturers: ManufacturerState;
}

export default createReducer(initialState, (handle) => [
    handle(setCurrentProductLoading, (state, { payload }) => ({
        ...state,
        productConfiguration: {
            ...state.productConfiguration,
            loading: payload,
        },
    })),
    handle(setCurrentProduct, (state, { payload }) => ({
        ...state,
        productConfiguration: {
            ...state.productConfiguration,
            current: payload,
        },
    })),
    handle(clearCurrentProduct, (state) => ({
        ...state,
        productConfiguration: {
            ...initialState.productConfiguration,
        },
    })),
    handle(setProp65Options, (state, { payload }) => ({
        ...state,
        productConfiguration: {
            ...state.productConfiguration,
            prop65s: payload,
        },
    })),
    handle(setComponentItems, (state, { payload }) => ({
        ...state,
        productConfiguration: {
            ...state.productConfiguration,
            componentItems: {
                ...state.productConfiguration.componentItems,
                paginated: payload,
            },
        },
    })),
    handle(setComponentItemsQuery, (state, { payload }) => ({
        ...state,
        productConfiguration: {
            ...state.productConfiguration,
            componentItems: {
                ...state.productConfiguration.componentItems,
                query: payload,
            },
        },
    })),
    handle(setTextBlockVariants, (state, { payload }) => ({
        ...state,
        productConfiguration: {
            ...state.productConfiguration,
            textBlockVariants: payload,
        },
    })),
    handle(setGtin, (state, { payload: { instanceId, gtin } }) => {

        const instances = [...state.productConfiguration.current.instances];
        const instanceIndexForNewGtin = instances.findIndex((x) => x.id === instanceId);
        instances[instanceIndexForNewGtin].gtin = gtin;

        return ({
            ...state,
            productConfiguration: {
                ...state.productConfiguration,
                current: {
                    ...state.productConfiguration.current,
                    instances: [
                        ...instances,
                    ],
                },
            },
        });
    }),
]);
