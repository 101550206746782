import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@material-ui/core';
import { CheckboxProps as MuiCheckboxProps } from '@material-ui/core/Checkbox';
import { FormControlLabelProps } from '@material-ui/core/FormControlLabel';
import { FastField, FastFieldConfig, FastFieldProps, Field, getIn } from 'formik';
import React from 'react';
import shouldUpdate$ from './shouldUpdate';

export type CheckboxProps<T = any> =
    & Partial<Omit<MuiCheckboxProps, 'name' | 'checked'>>
    & Partial<Pick<FastFieldConfig<T>, 'validate' | 'shouldUpdate'>>
    & { 
        name: string;
        label?: string;
        labelPlacement?: FormControlLabelProps['labelPlacement'];
        helperText?: string;
        fast?: boolean;
    };

export default function Component<T = any>(props: CheckboxProps<T>) {
    const { name, validate, onChange, onBlur, label, labelPlacement = 'end', shouldUpdate = shouldUpdate$, fast = true,  helperText, ...checkboxProps } = props;
    const FormikFieldComponent = shouldUpdate === shouldUpdate$ && fast
        ? FastField
        : Field;

    return (
        <FormikFieldComponent name={name} validate={validate} shouldUpdate={shouldUpdate}>
            {
                ({ form, field }: FastFieldProps) => {

                    const $onChange: MuiCheckboxProps['onChange'] = (e, checked) => {
                        form.setFieldValue(name, checked);

                        if (onChange) {
                            onChange(e, checked);
                        }
                    };

                    const $onBlur: MuiCheckboxProps['onBlur'] = (e) => {
                        field.onBlur(e);
                        
                        if (onBlur) {
                            onBlur(e);
                        }
                    };

                    const errorText = getIn(form.errors, name);
                    const $helperText = errorText || helperText;

                    return (
                        <FormControl error={Boolean(errorText)}>
                            <FormControlLabel
                                label={label}
                                labelPlacement={labelPlacement}
                                control={(
                                    <Checkbox
                                        id={name}
                                        {...checkboxProps}
                                        checked={Boolean(field.value)}
                                        onChange={$onChange}
                                        onBlur={$onBlur}
                                    />
                                )} />
                            {$helperText && <FormHelperText style={{ marginTop: 0 }}>{$helperText}</FormHelperText>}
                        </FormControl>
                    );
                }
            }
        </FormikFieldComponent>
    );
}
