import { recordEvent } from '@eq3/redux/serverSentEvents/actions';
import { ISseMessage } from '@eq3/redux/serverSentEvents/models';
import { createReducer } from 'deox';

export interface ISseState {
    events: Array<ISseMessage<any>>;
}

export interface ISseReduxSlice {
    serverSentEvents: ISseState;
}

const initialState: ISseState = {
    events: [],
};

const MAX_RECORDED_EVENTS = 20;

export default createReducer(initialState, (handle) => [
    handle(recordEvent, (state, { payload: { event } }) => {

        // Most recent events at index 0.
        const events = [event, ...state.events];

        if (events.length > MAX_RECORDED_EVENTS) {
            events.length = MAX_RECORDED_EVENTS;
        }
        return ({
            ...state,
            events,
        });
    }),
]);
