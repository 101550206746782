import { errorNotification, notify, successNotification } from '@eq3/redux/adminNotifications';
import { apiThunk as ApiThunk } from '@eq3/redux/store';
import { Pagination } from '@eq3/utils/pagination';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { ActionTypes, setFamilies, setJumps } from './actions';
import { IPriceCostJump, IPriceCostJumpQuery } from './models';
import { IPriceCostJumpReduxSlice } from './reducers';

type ThunkResult<T = void> = ThunkAction<Promise<T>, IPriceCostJumpReduxSlice, ApiThunk, ActionTypes>;
export type PriceCostJumpDispatch = ThunkDispatch<IPriceCostJumpReduxSlice, ApiThunk, ActionTypes>;

const handleError = (error: any, dispatch: Parameters<ThunkResult>[0], fallbackMessage?: string) => {
    dispatch(notify(errorNotification(error.message || fallbackMessage, error)));
};

export const fetchJumps = (query: IPriceCostJumpQuery = {}): ThunkResult => async (dispatch, getState, api) => {
    try {
        const { data }: { data: Pagination<IPriceCostJump> } = await api(dispatch, getState, `/admin/upholstery/price-cost-jumps`, 'GET', undefined, {
            params: {
                ...query,
            },
        });

        dispatch(setJumps(data));
    } catch (e) {
        handleError(e, dispatch, 'Error fetching price & cost jumps');
    }
};

export const submitJumps = (jumps: IPriceCostJump[]): ThunkResult => async (dispatch, getState, api) => {
    try {
        await api(dispatch, getState, `/admin/upholstery/price-cost-jumps`, 'POST', jumps);
        dispatch(notify(successNotification('Successfully saved jump data!')));
    } catch (e) {
        handleError(e, dispatch, 'Error saving price & cost jumps');
    }
};

export const fetchFamilies = (): ThunkResult => async (dispatch, getState, api) => {
    try {
        const { data } = await api(dispatch, getState, `/admin/upholstery/price-cost-jumps/families`, 'GET');
        dispatch(setFamilies(data));
    } catch (e) {
        handleError(e, dispatch, 'Error fetching families');
    }
};
