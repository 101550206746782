import { CreateOrUpdateShippingPartner } from '@eq3/redux/shippingPartners/models';
import { handleError } from '@eq3/utils/exceptionUtils';
import { createActions, handleActions } from 'redux-actions';
import { ThunkResult } from 'redux-thunk';
import { notify, successNotification } from '../adminNotifications';
import { apiThunk } from '../store';
import { IShippingPartnersState, IShippingPartner } from './models';

export const {setShippingPartners} = createActions({
    SET_SHIPPING_PARTNERS: (list) => ({list}),
});

type ShippingPartnersThunkResult<T> = ThunkResult<T, IShippingPartnersState>;

export const fetchShippingPartners = (): ShippingPartnersThunkResult<Promise<IShippingPartner[]>> => async (dispatch, getStore, api) => {
    try {
        const {data} = await api<IShippingPartner[]>(dispatch, getStore, '/admin/shippingPartners/?locale=CA_EN');
        dispatch(setShippingPartners(data));
        return data;
    } catch (e) {
        handleError(dispatch, e, 'Error getting Shipping Partners');
        throw e;
    }
};

export const createShippingPartner = (shippingPartner: CreateOrUpdateShippingPartner): ShippingPartnersThunkResult<Promise<void>> => async (dispatch, getStore, api: apiThunk) => {
    try {
        await api(dispatch, getStore, `/admin/shippingPartners`, 'POST', shippingPartner);
        dispatch(notify(successNotification(`Shipping partner created.`)));
        dispatch(fetchShippingPartners());
    } catch (e) {
        handleError(dispatch, e, 'Error creating Shipping Partner');
        throw e;
    }
};

export const updateShippingPartner = (shippingPartnerId: number, shippingPartner: CreateOrUpdateShippingPartner) => async (dispatch, getStore, api: apiThunk) => {
    try {
        await api(dispatch, getStore, `/admin/shippingPartners/${shippingPartnerId}`, 'PUT', shippingPartner);
        dispatch(notify(successNotification('Shipping Partner updated.')));
        dispatch(fetchShippingPartners());
    } catch (e) {
        handleError(dispatch, e, 'Error updating Shipping Partner');
        throw e;
    }
};

export const enableShippingPartner = (shippingPartnerId: number): ShippingPartnersThunkResult<Promise<void>> => async (dispatch, getStore, api: apiThunk) => {
    try {
        await api(dispatch, getStore, `/admin/shippingPartners/${shippingPartnerId}/enable`, 'POST');
        dispatch(fetchShippingPartners());
        dispatch(notify(successNotification('Shipping Partner enabled!')));
    } catch (e) {
        handleError(dispatch, e, 'Error enabling Shipping Partner');
        throw e;
    }
};

export const disableShippingPartner = (shippingPartnerId: number): ShippingPartnersThunkResult<Promise<void>> => async (dispatch, getStore, api: apiThunk) => {
    try {
        await api(dispatch, getStore, `/admin/shippingPartners/${shippingPartnerId}/disable`, 'POST');
        dispatch(fetchShippingPartners());
        dispatch(notify(successNotification('Shipping Partner disabled!')));
    } catch (e) {
        handleError(dispatch, e, 'Error disabling Shipping Partner');
        throw e;
    }
};

const initialState: IShippingPartnersState['shippingPartners'] = {
    list: [],
};

export default handleActions({
    [setShippingPartners]: (state, {payload: {list}}) => ({...state, list}),
}, initialState);
