import { useEffect, useRef } from 'react';
import { Subject, Observable, Subscription } from 'rxjs';

const useRxSubject = <T = unknown>(observer?: (observable: Observable<T>) => Subscription, dependencyList: React.DependencyList = []) => {
    const { current: subject } = useRef(new Subject<T>());

    useEffect(() => {
        if (!observer) return;
        const subscription = observer(subject.asObservable());
        return () => subscription.unsubscribe();
    }, dependencyList);

    return subject;
};

export default useRxSubject;