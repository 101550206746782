import { WithStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import GradesTable from '../../../components/GradesTable';
import LocalizedString from '../../../components/LocalizedString';
import PriceInput from '../../../components/PriceInput';
import { formatLabel, Locale } from '../../../util/i18n';
import AsShownInput from './AsShownInput';

import nonPrintable from '@eq3/containers/retail/priceTags/util/nonPrintable';
import { createStyles, MenuItem, Select, withStyles } from '@material-ui/core';

interface IProps {
    data: any;
}

const sectionalConfigurationNames = {
    en_CA: [],
    fr_CA: [],
    en_US: [],
};

for (let i = 2; i <= 10; ++i) {
    Object.values(Locale).forEach(({ id }) => {
        sectionalConfigurationNames[id].push(formatLabel('sectional_configuration', id, '' + i));
    });
}

class SectionalTag extends React.Component<IProps & WithStyles<ReturnType<typeof styles>> & ReturnType<typeof mapStateToProps>> {

    public state = {
        collectionName: '',
        configurationName: '',
    };

    private handleChanged = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    public render() {
        const { data, locale, classes } = this.props;
        const { sectionals } = data[locale.id];
        const { collectionName, configurationName } = this.state;
        return (
            <div className={classes.container}>
                <div>
                    <Select
                        className={classes.select}
                        onChange={this.handleChanged}
                        name="collectionName"
                        value={collectionName}
                        displayEmpty={true}
                        classes={{ icon: nonPrintable, select: classes.nameSelectLabel }}
                        disableUnderline={true}>
                        {sectionals.map(({ name }, i) => <MenuItem value={i} key={i}>{name}</MenuItem>)}
                    </Select>
                    <Select
                        className={classes.select}
                        onChange={this.handleChanged}
                        name="configurationName"
                        value={configurationName}
                        classes={{ select: classes.sizeSelectLabel, icon: nonPrintable }}
                        disableUnderline={true}>
                        {sectionalConfigurationNames[locale.id].map((name, i) => (<MenuItem value={i} key={i}>{name}</MenuItem>))}
                    </Select>
                </div>
                <div className={classes.from}>
                    <LocalizedString labelId="from" />
                </div>
                <PriceInput className={classes.fromInput} />
                <AsShownInput locale={locale} isDescriptionConfigurable={true} />
                <GradesTable />
                <div className={classes.description}>
                    {collectionName && sectionals[collectionName].description}
                </div>
                <div className={classes.madeInCanada}>
                    <LocalizedString labelId="made_in_can" />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    locale: state.priceTags.locale,
});

const styles = () => createStyles({
    container: {
        height: '100%',
        '& > div:not(:first-child)': {
            marginTop: '8px',
            width: '100%',
        },
        position: 'relative',
    },
    select: {
        width: '100%',
        background: '#e1f6fd',
        '@media print': {
            background: 'transparent',
        },
    },
    nameSelectLabel: {
        fontFamily: 'eq3-heavy',
        fontSize: '15pt',
        lineHeight: '19pt',
        paddingTop: 0,
        paddingBottom: 0,
    },
    sizeSelectLabel: {
        fontFamily: 'eq3-heavy',
        fontSize: '14pt',
        lineHeight: '19pt',
        overflow: 'visible',
        paddingBottom: 0,
    },
    from: {
        fontSize: '9pt',
        lineHeight: 'normal',
    },
    fromInput: {
        borderWidth: '0px',
        fontSize: '23pt',
        width: '100%',
    },
    madeInCanada: {
        position: 'absolute',
        bottom: '10px',
    },
    description: {
        lineHeight: 'normal',
    },
});

export default (compose(
    connect(mapStateToProps),
    withStyles(styles),
)(SectionalTag)) as React.ComponentType<IProps>;
