export class Pagination<T> {
    public readonly page: number;
    public readonly pageSize: number;
    public readonly totalCount: number;
    public readonly items: T[];

    constructor(
        page: number,
        pageSize: number,
        totalCount: number,
        items: T[]) {
        this.page = page;
        this.pageSize = pageSize;
        this.totalCount = totalCount;
        this.items = items;
    }
}

export interface IPaginatable {
    pageSize: number;
    pageIndex: number;
    totalCount?: number;
    limit?: number;
    offset?: number;
}

export interface IOrderable {
    orderBy: string;
    orderDirection: 'ASC' | 'DESC';
}

export interface IPaginationParameters extends IOrderable, IPaginatable { }

export const pageSizes = [10, 25, 50, 100];
export const defaultPageSize = pageSizes[0];

/**
 * @function getPageSize
 * @returns The closest valid page size value to the given value
 * @param pageSizeValue The value to derive the correct pageSize from
 */
export const getPageSize = (pageSizeValue: number) => {
    if (typeof pageSizeValue !== 'number') { return pageSizes[0]; }
    return pageSizes.reduce((a, b) => (Math.abs(b - pageSizeValue) < Math.abs(a - pageSizeValue) ? b : a));
};
