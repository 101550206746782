import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles(() => ({
    container: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

const NotFound = () => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <h1>Not Found</h1>
        </div>
    );
};

export default NotFound;
