import { Locale } from './../../utils/locales';
import { IPaginatable } from './../../utils/pagination';

export enum Fields {
    Sku = 'sku',
    Vendor = 'vendor',
    ERPItemDescription = 'description',
    PlcCode = 'plcCode',
    ItemType = 'itemType',
    ItemClass = 'itemClass',
    Language = 'language',
    SalesGroup = 'salesGroup',
    Cost = 'localizedCosts',
    Price = 'localizedPrices',
    MasterpackQuantity = 'masterpackQuantity',
    IsActive = 'isActive',
    ActiveStartDate = 'activeStartDate',
    ActiveEndDate = 'activeEndDate',
    IsDisabled = 'isDisabled',
    OptOutZucora = 'optOutZucora',
}
export interface IRecord { 
    [Fields.Sku]: string;
    [Fields.Vendor]: string;
    [Fields.ERPItemDescription]: string;
    [Fields.PlcCode]: string;
    [Fields.ItemType]: string;
    [Fields.ItemClass]: string;
    [Fields.Language]: Locale;
    [Fields.SalesGroup]: string;
    [Fields.Cost]: number;
    [Fields.Price]: number;
    [Fields.MasterpackQuantity]: number;
    [Fields.IsActive]: boolean;
    [Fields.ActiveStartDate]: boolean;
    [Fields.ActiveEndDate]: boolean;
    [Fields.IsDisabled]: boolean;
}

export interface IFilters {
    searchDesc: string;
    skus: string[];
    vendors: string[];
    plcCodes?: string[];
    itemClasses: string[];
    itemTypes: string[];
    isActive?: boolean;
    isDeleted?: boolean;
}

export interface IQuery extends Partial<IFilters>, Partial<IPaginatable>, Partial<ISortDefinition> {
}

export interface ISortDefinition {
    sortKey: Fields | string;
    sortDirection: 'ASC' | 'DESC';
}
