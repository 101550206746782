export const camelToCapitalCaseString = (str: string) => {
    return str.split('').map((char, index) => char === char.toUpperCase() ? ` ${char}` : index === 0 ? char.toUpperCase() : char).join('');
};

export const camelToUnderscoreCase = (str: string) => {
    return str.split('').map((char, index) => (index !== 0 && char === char.toUpperCase()) ? `_${char}` : char.toUpperCase()).join('');
};

export const slugify = (str: string) => str.toLowerCase().replace(/[^a-z\d]/g, '-');

// We are replacing spaces with underscores so we can later exclude every special chars in the name of the city.
export const slugForCity = (str: string) => str.toLowerCase().normalize('NFD').replace(/\s+/g, '-').toLowerCase();
