import {ThunkResult} from '@eq3/redux/tagging';
import {IOrderable} from '@eq3/utils';
import { createActions, handleActions } from 'redux-actions';
import {defer} from 'rxjs';
import {map} from 'rxjs/operators';
import { errorNotification, notify, successNotification } from './adminNotifications';
import { apiThunk } from './store';

export interface ILegacyOrder {
    id: number;
    orderNumber: number;
    status: string;
    datePurchased: number;
    customerName: string;
    customerEmail: string;
    total: number;
    addresses: ILegacyAddress[];
    statusUpdates: ILegacyStatusUpdates[];
    totals: ILegacyTotal[];
    payment: ILegacyPayment;
    products: ILegacyProduct[];
    trackingNumber: string;
    custom1: string;
    custom2: string;
    custom3: string;
    custom4: string;
    custom5: string;
}

interface ILegacyAddress {
    type: LegacyAddressType;
    customerName: string;
    primaryEmail: string;
    company?: string;
    streetAddress1: string;
    streetAddress2?: string;
    city: string;
    postalCode: string;
    province: string;
    country: string;
    primaryPhoneNumber: string;
    secondaryPhoneNumber?: string;
}

interface ILegacyStatusUpdates {
    dateUpdated: number;
    customerNotified: boolean;
    status: string;
    comment: string;
}

interface ILegacyTotal {
    title: string;
    amount: number;
    sortOrder: number;
}

interface ILegacyPayment {
    method: string;
    cardType?: string;
    cardName?: string;
    cardNumber?: string;
    cardExpiration?: string;
}

interface ILegacyProduct {
    sku?: string;
    model: string;
    name: string;
    price: number;
    quantity: number;
    attributes: ILegacyProductAttribute[];
}

interface ILegacyProductAttribute {
    option: string;
    value: string;
}

interface ILegacyOrderList {
    id: number;
    orderNumber: string;
    status: string;
    datePurchased: number;
    customerName: string;
    customerEmail: string;
    total: number;
}

enum LegacyAddressType {
    BILLING = 'BILLING',
    DELIVERY = 'DELIVERY', 
    CUSTOMER = 'CUSTOMER',
}

export interface ILegacyOrderReduxSlice {
    legacyOrders: {
        loading: boolean;
        list: ILegacyOrderList[];
        count: number;
        data: Record<string, ILegacyOrder>;
    };
}

const LegacyOrderInitialState: ILegacyOrderReduxSlice['legacyOrders'] = {
    loading: false,
    list: [],
    count: 0,
    data: {},
};

const { setLegacyOrdersLoading, setLegacyOrders, setLegacyOrder, addStatusUpdate, updateLegacyOrderData } = createActions({
    SET_LEGACY_ORDERS_LOADING: () => null,
    SET_LEGACY_ORDERS: (orders, count) => ({orders, count}),
    SET_LEGACY_ORDER: (order) => order,
    ADD_STATUS_UPDATE: (orderId, statusUpdate) => ({orderId, statusUpdate}),
    UPDATE_LEGACY_ORDER_DATA: (orderId, update) => ({orderId, update}),
});

export default handleActions ({
    [setLegacyOrdersLoading]: (state) => ({...state, loading: true}),
    [setLegacyOrders]: (state, {payload: { orders, count  }}) => ({...state, list: orders, count, loading: false }),
    [setLegacyOrder]: (state, {payload: order}) => ({...state, data: {...state.data, [order.id]: order}}),
    [addStatusUpdate]: (state, {payload: {orderId, statusUpdate}}) => ({...state, data: {...state.data, [orderId]: {...state.data[orderId], statusUpdates: state.data[orderId].statusUpdates.concat(statusUpdate), status: statusUpdate.status}}}),
    [updateLegacyOrderData]: (state, {payload: {orderId, update}}) => ({...state, data: {...state.data, [orderId]: {...state.data[orderId], ...update}}}),
}, LegacyOrderInitialState);

export interface ILegacyOrderListing {
    id: string;
    orderNumber: string;
    datePurchased: number;
    orderTotal: number;
    status: string;
    customerName: string;
    customerEmail: string;
}

export const fetchLegacyOrders = (params, orderBy: string, orderDirection: IOrderable['orderDirection']) => async (dispatch, getStore, api: apiThunk) => {
    try {
        dispatch(setLegacyOrdersLoading());
        const { data: { legacyOrders, count } } = await api(dispatch, getStore, '/admin/legacy/orders', 'GET', undefined, { params: {...params, orderBy, orderDirection: orderDirection.toUpperCase()}});
        dispatch(setLegacyOrders(legacyOrders, count));
    } catch (e) {
        dispatch(notify(errorNotification('Error retrieving KonaKart orders', e)));
        throw e;
    }
};

export const fetchLegacyOrder = (orderId: number): ThunkResult<void> =>  async (dispatch, getStore, api: apiThunk) => {
    try {
        const { data } = await api(dispatch, getStore, `/admin/legacy/orders/${orderId}`, 'GET');
        dispatch(setLegacyOrder(data));
    } catch (e) {
        dispatch(notify(errorNotification('Error retrieving KonaKart order ' + orderId, e)));
        throw e;
    }
};

export const addStatus = (orderId, statusUpdate) =>  async (dispatch, getStore, api: apiThunk) => {
    try {
        const { data } = await api(dispatch, getStore, `/admin/legacy/orders/${orderId}/statusUpdates`, 'POST', statusUpdate);

        dispatch(addStatusUpdate(orderId, data));
        dispatch(notify(successNotification('Status Update added!')));
    } catch (e) {
        dispatch(notify(errorNotification('Error adding Status Update', e)));
        throw e;
    }
};

export const updateLegacyOrder = (orderId, update) => async (dispatch, getStore, api: apiThunk) => {
    try {
        const { data } = await api(dispatch, getStore, `/admin/legacy/orders/${orderId}`, 'PUT', update);

        dispatch(updateLegacyOrderData(orderId, data));
        dispatch(notify(successNotification('KonaKart Order Details updated!')));
    } catch (e) {
        dispatch(notify(errorNotification('Error updating KonaKart Order Details', e)));
        throw e;
    }
};

interface IAutoSuggestableFields {
    orderNumber: string;
    customerName: string;
    customerEmail: string;
}

export type LegacyOrderSuggestableField = keyof IAutoSuggestableFields;

export const AutoSuggestableFields: LegacyOrderSuggestableField[] = ['orderNumber', 'customerName', 'customerEmail'];

interface ILegacyOrderSearchSuggestion {
    field: LegacyOrderSuggestableField;
    suggestions: string[];
}

export const getAutoSuggestions = (field: LegacyOrderSuggestableField, searchTerm: string, limit: number = 5): ThunkResult<ILegacyOrderSearchSuggestion> => (dispatch, getState, api) => {
    return defer(() => api<ILegacyOrderSearchSuggestion>(dispatch, getState, '/admin/legacy/orders/search-filters/auto-suggestions', 'GET', undefined, {
        params: {field, searchTerm, limit},
    }))
        .pipe(map(({data}) => data));
};
