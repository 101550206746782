import {AddButton} from '@eq3/containers/retail/priceTags/components/Buttons';
import nonPrintable from '@eq3/containers/retail/priceTags/util/nonPrintable';
import { Menu, withStyles } from '@material-ui/core';
import { StyleRules, WithStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import SelectMenuItem, { IMenuItem } from './SelectMenuItem';

export interface IMenuProps {
    itemTree: IMenuItem[];
    onLeafNodeSelected?: (menuItemPath: IMenuItem[]) => void;
    locale: any;
}

interface IState {
    anchorElement: any;
    menuOpen: boolean;
}

class SelectMenu extends React.Component<IMenuProps & WithStyles<ReturnType<typeof styles> & ReturnType<typeof mapStateToProps>>, IState> {

    public state = {
        anchorElement: undefined,
        menuOpen: false,
    };

    private handleMenuOpen = (e) => {
        this.setState({ menuOpen: true, anchorElement: e.currentTarget });
    }

    private menuItemSelected = (menuItemPath: IMenuItem[]) => {
        if (!(menuItemPath[menuItemPath.length - 1].children && !!menuItemPath[menuItemPath.length - 1].children.length) && this.props.onLeafNodeSelected) {
            this.props.onLeafNodeSelected(menuItemPath);
        }
    }

    private handleMenuClose = () => {
        this.setState({ menuOpen: false });
    }

    public render(): JSX.Element {
        const { classes, itemTree, locale } = this.props;
        const { menuOpen, anchorElement } = this.state;

        return (
            <div className={`${classes.menuContainer} ${nonPrintable}`}>

                <AddButton onClick={this.handleMenuOpen}/>

                <Menu
                    className={classes.menu}
                    anchorEl={anchorElement}
                    open={menuOpen}
                    onClose={this.handleMenuClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    transformOrigin={{ vertical: 'center', horizontal: 'center' }}>
                    {itemTree.map((menuItem) => <div key={menuItem.id}><SelectMenuItem item={menuItem} locale={locale} onSelected={this.menuItemSelected} /></div>)}

                </Menu>

            </div>
        );
    }
}

const styles = () => ({
    menuContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    menu: {
        maxHeight: '5in',
    },
}) as StyleRules;
const mapStateToProps = (state) => ({
    locale: state.priceTags.locale,
});
export default (compose(
    connect(mapStateToProps),
    withStyles(styles),
)(SelectMenu)) as any as React.ComponentType<IMenuProps>;
