import { createReducer } from 'deox';
import * as Actions from './actions';
import { IUpholsteryItem } from './models';
import { initialState } from './state';
import { createChecklist, createCosts, createItems, createPrices } from './utils';

export default createReducer(initialState, (handle) => [
    handle(Actions.setTab, (state, { payload }) => ({
        ...state,
        tab: payload,
    })),
    handle(Actions.setFilters, (state, { payload }) => ({
        ...state,
        filters: {
            ...state.filters,
            items: payload,
        },
    })),
    handle(Actions.toggleFilters, (state, { payload }) => ({
        ...state,
        filters: {
            ...state.filters,
            opened: typeof payload === 'boolean' ? payload : !state.filters.opened,
        },
    })),
    handle(Actions.toggleBulkGrades, (state, { payload }) => ({
        ...state,
        grades: {
            ...state.grades,
            opened: typeof payload === 'boolean' ? payload : !state.grades.opened,
        },
    })),
    handle(Actions.setGradeTree, (state, { payload }) => ({
        ...state,
        grades: {
            ...state.grades,
            tree: payload,
        },
    })),
    handle(Actions.setInvalidItems, (state, { payload }) => ({
        ...state,
        invalidItems: {...payload.invalidConfigurations},
    })),
    handle(Actions.setPagination, (state, { payload }) => ({
        ...state,
        pagination: payload,
    })),
    handle(Actions.setOrder, (state, { payload }) => ({
        ...state,
        order: payload,
    })),
    handle(Actions.setQuery, (state, { payload }) => ({
        ...state,
        query: payload,
    })),
    handle(Actions.setRecords, (state, { payload }) => ({
        ...state,
        records: {
            data: payload,
            changes: [],
        },
    })),
    handle(Actions.setFetchState, (state, { payload }) => ({
        ...state,
        ...payload,
    })),
    handle(Actions.recordChangeSet, (state, { payload }) => {
        return ({
            ...state,
            records: {
                ...state.records,
                changes: [...state.records.changes, payload],
            },
        });
    }),
    handle(Actions.toggleExpansionRow, (state, { payload: itemId }) => ({
        ...state,
        grades: {
            ...state.grades,
            expanded: state.grades.expanded.includes(itemId) ? state.grades.expanded.filter((x) => x !== itemId) : [...state.grades.expanded, itemId],
        },
    })),
    handle(Actions.toggleGradeCheckbox, (state, { payload }) => {
        return ({
            ...state,
            grades: {
                ...state.grades,
                checklist: {
                    ...state.grades.checklist,
                    [payload.itemId]: {
                        ...state.grades.checklist[payload.itemId],
                        [payload.code]: !state.grades.checklist[payload.itemId][payload.code],
                    },
                },
            },
        });
    }),
    handle(Actions.updateChecklist, (state, { payload: checklist }) => {
        return {
            ...state,
            grades: {
                ...state.grades,
                checklist,
            },
        };
    }),
    handle(Actions.updatePricing, (state, { payload }) => {
        return {
            ...state,
            pricing: {
                ...state.pricing,
                [payload.itemId]: {
                    ...state.pricing[payload.itemId],
                    [payload.locale]: payload.value,
                },
            },
        };
    }),
    handle(Actions.updateCosts, (state, { payload }) => {
        return {
            ...state,
            costs: {
                ...state.costs,
                [payload.itemId]: {
                    ...state.costs[payload.itemId],
                    [payload.locale]: payload.value,
                },
            },
        };
    }),
    handle(Actions.updateItems, (state, { payload }) => {
        return {
            ...state,
            items: {
                ...state.items,
                [payload.itemId]: {
                    ...state.items[payload.itemId],
                    ...payload.value,
                },
            },
        };
    }),
    handle(Actions.reset, (state) => {
        const records = state.records.data as IUpholsteryItem[];

        return {
            ...state,
            records: {
                ...state.records,
                changes: [],
            },
            items: createItems(records),
            grades: {
                ...state.grades,
                checklist: createChecklist(records, state.grades.tree),
            },
            pricing: createPrices(records),
            costs: createCosts(records),
        };
    }),
    handle(Actions.setLoading, (state, {payload: loading}) => ({
        ...state,
        loading,
    })),
]);
