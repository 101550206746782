import { createAction as _ } from 'deox';
import { ICategory, ICategoryListItem, IMenu, IProductLine, IProductLineListItem, ISubcategory, ISubcategoryListItem } from './models/api';

const createAction: typeof _ = (type, executor) => _(`[MENU]\\${type}` as typeof type, executor);

const actions = {
    setMenu: createAction('SET_MENU', (resolve) => (menu: IMenu) => resolve(menu)),
    
    setCategory: createAction('SET_CATEGORY', (resolve) => (category: ICategory) => resolve(category)),
    setCategoryOrder: createAction('SET_CATEGORY_ORDER', (resolve) => (categories: ICategoryListItem[]) => resolve(categories)),
    deleteCategory: createAction('DELETE_CATEGORY', (resolve) => (id: string) => resolve(id)),
    
    setSubcategory: createAction('SET_SUBCATEGORY', (resolve) => (subcategory: ISubcategory) => resolve(subcategory)),
    setSubcategoryOrder: createAction('SET_SUBCATEGORY_ORDER', (resolve) => (subcategories: ISubcategoryListItem[]) => resolve(subcategories)),
    deleteSubcategory: createAction('DELETE_SUBCATEGORY', (resolve) => (subcategoryId: string) => resolve(subcategoryId)),
    
    setProductLine: createAction('SET_PRODUCTLINE', (resolve) => (productline: IProductLine) => resolve(productline)),
    setProductLineOrder: createAction('SET_PRODUCTLINE_ORDER', (resolve) => (productlines: IProductLineListItem[]) => resolve(productlines)),
    deleteProductLine: createAction('DELETE_PRODUCTLINE', (resolve) => (productlineId: string) => resolve(productlineId)),
};

export type MenuAction = ReturnType<(typeof actions)[keyof typeof actions]>;
export default actions;
