import SofaIcon from '@eq3/containers/retail/priceTags/components/SofaIcon';
import {
    IDualSubConfiguration,
    IReadyToShipProductFromFile,
    IReadyToShipProductLocalized,
    ISalePrice,
} from '@eq3/containers/retail/priceTags/models/readyToShip';
import {ILocalizedLabels} from '@eq3/containers/retail/priceTags/util/i18n';
import { WithStyles } from '@material-ui/core/styles';
import React from 'react';

import {connect} from 'react-redux';
import {compose} from 'redux';
import {formatLabel} from '../../../../util/i18n';
import ReadyToShipConfigurationPrice from './ReadyToShipConfigurationPrice';
import ReadyToShipSubConfiguration from './ReadyToShipSubConfiguration';

import { createStyles, withStyles } from '@material-ui/core';

interface IProps {
    product: IReadyToShipProductFromFile & {
        [locale in keyof ILocalizedLabels]: IReadyToShipProductLocalized;
    };
}

type Props = IProps & ReturnType<typeof mapStateToProps> & WithStyles<ReturnType<typeof styles>>;
const ReadyToShipTag: React.ComponentType<Props> = (props: Props) => {

    const {product, locale, classes} = props;

    const localizedProduct = product[locale.id] as IReadyToShipProductLocalized;

    const pricingIsSale = (pricing: ISalePrice | IDualSubConfiguration): pricing is ISalePrice => {
        return pricing.regularPrice !== undefined;
    };

    return (
        <div className={classes.container}>
            <div className={classes.name}>
                {localizedProduct.name}
            </div>
            <div className={classes.description}>
                {localizedProduct.description}
            </div>
            <div className={classes.configurations}>
                {localizedProduct.configurations &&
                localizedProduct.configurations
                    .map((configuration, i) => (
                        <div key={i} style={{display: 'flex', flexDirection: 'row'}}>
                            {configuration.icon && <SofaIcon variant={configuration.icon} className={classes.configurationIcon}/>}
                            <div>
                            <div className={classes.configurationName}>
                                {configuration.name}
                            </div>
                            {configuration.dimensions && (
                                <div className={classes.configurationDimensions}>
                                    {formatLabel('dimensions_fmt', locale.id, configuration.dimensions.width, configuration.dimensions.depth, configuration.dimensions.height)}
                                </div>
                            )}
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                {pricingIsSale(configuration.pricing)
                                    ? (<ReadyToShipConfigurationPrice price={configuration.pricing} locale={locale}/>)
                                    : (
                                        <div className={classes.dualSubConfiguration}>
                                            <ReadyToShipSubConfiguration subConfiguration={configuration.pricing.left}
                                                                        styles={{container: {marginRight: '20px'}}}/>
                                            <ReadyToShipSubConfiguration subConfiguration={configuration.pricing.right}/>
                                        </div>
                                    )
                                }
                            </div>
                            </div>
                        </div>
                    ))}
            </div>
            <div>
                {localizedProduct.madeIn}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    locale: state.priceTags.locale,
});

const styles = (theme) => createStyles({
    container: {
        padding: '20px 7px 0px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    name: {
        fontSize: '15pt',
        lineHeight: '19pt',
        fontFamily: 'eq3-heavy',
    },
    description: {
        margin: '15px 0px',
    },
    configurations: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        flexGrow: 1,
    },
    configurationIcon: {
        width: '20%',
        alignSelf: 'start',
        marginRight: '15px',
    },
    dualSubConfiguration: {
        display: 'flex',
        flexDirection: 'row',
    },
    configurationName: {
        fontSize: '15px',
    },
    configurationDimensions: {
        fontSize: '9px',
    },
});

export default (compose(
    connect(mapStateToProps),
    withStyles(styles),
)(ReadyToShipTag));
