import locales, { CurrenciesType, Locale } from '@eq3/utils/locales';
import { IPaginatable } from './../../utils/pagination';

export enum Fields {
    Item = 'item',
    ERPOptionCode = 'erpOptionCode',
    ERPOptionDescription = 'erpOptionDescription',
    ERPOptionDescriptionOverride = 'erpOptionDescriptionOverride',
    CategoryName = 'categoryName',
    OptionQty = 'optionQty',
    OptionGroupCode = 'optionGroupCode',
    OptionGroupName = 'optionGroupName',
    ItemClass = 'itemClass',
    GradeGroup = 'gradeGroup',
    Prices = 'prices',
    Costs = 'costs',
    Amount = 'amount',
    IsDeleted = 'isDeleted',
    IsActive = 'isActive',
    ActiveStartDate = 'activeStartDate',
    ActiveEndDate = 'activeEndDate',
    IsDisabled = 'isDisabled',
    ActiveChangeTime = 'activeChangeTime',
    IsUpholsteryColourOption = 'isUpholsteryColourOption',
    Descriptions = 'descriptions',
}

export interface IItemOptionPricing {
    [Fields.Amount]: number;
    [Fields.GradeGroup]: string;
}
export interface IRecord {
    [Fields.Item]: string;
    [Fields.ERPOptionCode]: string;
    [Fields.ERPOptionDescription]: string;
    [Fields.ERPOptionDescriptionOverride]: string;
    [Fields.OptionGroupCode]: string;
    [Fields.OptionGroupName]: string;
    [Fields.ItemClass]: string;
    [Fields.Prices]: { [currency in CurrenciesType]: IItemOptionPricing; };
    [Fields.Costs]: { [currency in CurrenciesType]: IItemOptionPricing; };
    [Fields.IsDeleted]: boolean;
    [Fields.ActiveStartDate]: string;
    [Fields.ActiveEndDate]: string;
    [Fields.IsDisabled]: boolean;
    [Fields.IsActive]: boolean;
    [Fields.ActiveChangeTime]: Date;
    [Fields.IsUpholsteryColourOption]: boolean;
    [Fields.Descriptions]: { [locale in keyof typeof locales]: string; };
}

export interface IFilters {
    items: string[];
    erpOptionCodes: string[];
    optionGroupCodes: string[];
    itemClasses: string[];
    isActive?: boolean;
    isDeleted?: boolean;
}

export interface IQuery extends Partial<IFilters>, Partial<IPaginatable>, Partial<ISortDefinition> {
    searchTerm?: string;
    optionGroupName?: string;
    minPrice?: number;
    maxPrice?: number;
    startActiveChangeTime?: Date;
    endActiveChangeTime?: Date;
}

export interface ISortDefinition {
    sortKey: Fields;
    sortDirection: 'asc' | 'desc';
}

export interface IItemOptionGrades {
    item: string;
    optionCode: string;
    gradeCodes: string[];
}

export interface IUpholsteryOptionImage {
    key: string;
    imageUrl: string;
    position: number;
}

export interface IUpholsteryOptionItem {
    item: string;
    itemDescription: string;
}
export interface IUpholsteryOption {
    erpOptionCode: string;
    images: IUpholsteryOptionImage[];
    items: IUpholsteryOptionItem[];
}

export interface IUpholsteryItemOption {
    activeEndDate: string;
    activeStartDate: string;
    colours: IUpholsteryItemOptionColour[];
    costs: UpholsteryItemOptionCosts;
    descriptions: Descriptions;
    erpOptionCode: string;
    erpOptionDescription: string;
    erpOptionDescriptionOverride: string;
    isActive: boolean;
    isDeleted: boolean;
    isDisabled: boolean;
    isUpholsteryColourOption: boolean;
    item: string;
    itemClass: string;
    optionGroupCode: string;
    optionGroupName: string;
    prices: UpholsteryItemOptionPrices;
}

export interface IUpholsteryItemOptionColour {
    colourCode: string;
    colourName: string;
    erpOptionCode: string;
    gradeCode: string;
    gradeGroup: string;
    gradeName: string;
    isActive: boolean;
    item: string;
    swatchUrl: string;
}

export type UpholsteryItemOptionCosts = {
    [key in Locale]?: { amount: number; gradeGroup: string; };
};

export type UpholsteryItemOptionPrices = {
    [key in Locale]?: { amount: number; gradeGroup: string; };
};

export type Descriptions = {
    [key in Locale]?: string;
};
