import { UnlinkedRefundDetailsDto } from '@eq3-aws/payments-client';
import ReceiptField from '@eq3/containers/pos/widgets/receipts/ReceiptField';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import React, { useMemo } from 'react';
import { default as receiptTranslations, Language } from '../translations';

export interface IUnlinkedRefundReceiptDataProps {
    unlinkedRefundDetails: UnlinkedRefundDetailsDto;
    language: Language;
}

const UnlinkedRefundReceiptData = ({ unlinkedRefundDetails, language }: IUnlinkedRefundReceiptDataProps) => {
    const {
        refundedAt,
        amount,
        currency,
        referenceNumber,
        storisReference,
        cashierName,
        location,
        cardBrand,
        lastFourDigits,
    } = unlinkedRefundDetails;

    const classes = useStyles();
    const translations = receiptTranslations[language ?? Language.EN];

    const dateAndTime = useMemo(() => {
        const jsDate = new Date(refundedAt);
        return {
            time: jsDate.toLocaleTimeString(),
            date: jsDate.toLocaleDateString(),
        };
    }, [refundedAt]);

    const cardNumber = useMemo(() => {
        return lastFourDigits
            ? `**** **** **** ${lastFourDigits}`
            : undefined;
    }, [lastFourDigits]);

    return (
        <div className={classes.root}>
            <div className={classes.centerTextContainer}>
                {!location
                    ? <pre>EQ3</pre>
                    : (
                        <>
                            <pre data-cy="urReceiptLocationText">{location?.name ?? 'EQ3'}</pre>
                            {location?.address && (
                                <>
                                    <pre>{location?.address?.addressLine1}</pre>
                                    {location?.address?.addressLine2 && (
                                        <pre>{location?.address?.addressLine2}</pre>
                                    )}
                                    <pre>{location?.address?.city}, {location?.address?.province} {location.address?.postalCode}</pre>
                                </>
                            )}
                        </>
                    )}
            </div>

            <div className={classnames(classes.centerTextContainer, classes.mt16)}>
                <pre>{dateAndTime.date}&nbsp;&nbsp;&nbsp;{dateAndTime.time}</pre>
            </div>

            <div className={classes.mt16}>
                <ReceiptField fieldName={translations.txOrderNumber}
                              fieldValue={storisReference?.toUpperCase()}
                              data-cy="urReceiptOrderNumberText"/>
                <ReceiptField fieldName={translations.txReference}
                              fieldValue={referenceNumber.toString().padStart(10, '0')}/>
                <ReceiptField fieldName={translations.txCashier} fieldValue={cashierName}/>
                <ReceiptField fieldName={translations.txType} fieldValue={translations.txTypeRefund} data-cy="urReceiptTypeText"/>
            </div>

            {/*/!* Amount *!/*/}
            <div className={classes.mt16}>
                <ReceiptField fieldName={translations.txAmount}
                              fieldValue={`$ ${amount?.toFixed(2)} ${currency}`}
                              data-cy="urReceiptAmountText"/>
            </div>

            <div className={classes.mt16}>
                {cardBrand && cardBrand !== 'unknown' && <ReceiptField fieldValue={cardBrand} seperator=""/>}
                <ReceiptField fieldName={translations.cardNumber} fieldValue={cardNumber}/>
            </div>

            <div className={classnames(classes.centerTextContainer, classes.mt16)}>
                <pre data-cy="urReceiptFooterText">{translations.completionApproved}</pre>
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        '& pre': {
            margin: 0,
            whiteSpace: 'normal',
        },
    },
    mt16: {
        marginTop: theme.spacing(2),
    },
    centerTextContainer: {
        textAlign: 'center',
    },
}));

export default UnlinkedRefundReceiptData;
