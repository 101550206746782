import { createMuiTheme } from '@material-ui/core';
import * as colors from '@material-ui/core/colors';
import { Theme } from '@material-ui/core/styles';
import createPalette, { Palette } from '@material-ui/core/styles/createPalette';
import createSpacing, { Spacing } from '@material-ui/core/styles/createSpacing';
import { Shadows } from '@material-ui/core/styles/shadows';

export const eq3Book = {
    fontFamily: ['EQ3-Book', 'Helvetica', 'Arial', 'sans-serif'].join(','),
};

export const eq3Medium = {
    fontFamily: ['EQ3-Medium', 'Helvetica', 'Arial', 'sans-serif'].join(','),
};

export const palliMedium = {
    fontFamily: ['BasisGrotesque', 'Helvetica', 'Arial', 'sans-serif'].join(','),
};

export const eq3Heavy = {
    fontFamily: ['EQ3-Heavy', 'Helvetica', 'Arial', 'sans-serif'].join(','),
};

const shadows: Shadows = [
    'none',
    '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 2px 2px -2px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 3px 4px -2px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 3px 4px -2px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 4px 6px -2px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 4px 6px -2px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 4px 8px -2px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 5px 8px -2px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 6px 12px -4px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 7px 12px -4px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 6px 16px -4px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 7px 16px -4px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 8px 18px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 9px 18px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 10px 20px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 11px 20px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 12px 22px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 13px 22px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 14px 24px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 16px 28px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 18px 30px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 20px 32px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 22px 34px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 24px 36px -8px rgba(0,0,0,0.25)',
];

// Separated palette so that we can reference it in the theme below
const palette: Palette = createPalette({
    type: 'light',
    action: {
        active: colors.blueGrey[600],
    },
    background: {
        default: '#F4F6F8',
        paper: colors.common.white,
    },
    primary: {
        main: colors.blue[800],
        contrastText: '#FFFFFF',
        light: colors.blue[700],
    },
    secondary: {
        main: colors.blueGrey[700],
        contrastText: '#FFFFFF',
    },
    text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600],
    },
});

// Separated spacing so that we can reference it in the theme below
const spacing: Spacing = createSpacing(8);

// @Deprecated.
// Hey! Don't use this file! It's @Deprecated. If you want to mess around with default styles, then check out
// @eq3/design/theme (assuming it hasn't moved). This is only here b/c some legacy code still _directly_ imports this
// (even though we shouldn't be doing that #techdebt).
const theme: Theme = createMuiTheme({
    palette,
    spacing,
    typography: {
        ...eq3Book,
        fontSize: 14,
        h1: {
            ...eq3Medium,
            fontSize: '35px',
            lineHeight: '40px',
            letterSpacing: '-0.24px',
            color: '#263238',
        },
        h2: {
            ...eq3Medium,
            fontSize: '29px',
            lineHeight: '32px',
            letterSpacing: '-0.24px',
            color: '#263238',
        },
        h3: {
            ...eq3Medium,
            fontSize: '24px',
            lineHeight: '28px',
            letterSpacing: '-0.06px',
            color: '#263238',
        },
        h4: {
            ...eq3Medium,
            fontSize: '20px',
            lineHeight: '24px',
            letterSpacing: '-0.05px',
            color: '#263238',
        },
        h5: {
            ...eq3Medium,
            fontSize: '16px',
            lineHeight: '20px',
            letterSpacing: '-0.05px',
            color: '#263238',
        },
        h6: {
            ...eq3Medium,
            fontSize: '14px',
            lineHeight: '24px',
            letterSpacing: '-0.05px',
            color: '#263238',
        },
        body1: {
            ...eq3Book,
            fontSize: '14px',
            lineHeight: '21px',
            letterSpacing: '-0.05px',
            color: '#263238',
        },
        body2: {
            ...eq3Book,
            fontSize: '12px',
            lineHeight: '18px',
            letterSpacing: '-0.04px',
            color: '#263238',
        },
        subtitle1: {
            ...eq3Book,
            fontSize: '16px',
            lineHeight: '25px',
            letterSpacing: '-0.09px',
            color: '#546E7A',
        },
        subtitle2: {
            ...eq3Medium,
            fontSize: '14px',
            lineHeight: '22px',
            letterSpacing: '-0.05px',
            color: '#607D8B',
        },
        caption: {
            ...eq3Book,
            fontSize: '12px',
            lineHeight: '20px',
            letterSpacing: '-0.05px',
            color: '#263238',
        },
        overline: {
            ...eq3Medium,
            fontSize: '11px',
            lineHeight: '13px',
            letterSpacing: '-0.33px',
            color: '#263238',
        },
    },
    shadows,
    overrides: {
        MuiButton: {
            contained: {
                ...eq3Medium,
                padding: '6px 16px',
                minWidth: '100px',

                '&$disabled': {
                    color: '#FFF',
                    backgroundColor: 'rgba(13, 71, 161, .3)',
                },
            },
            containedPrimary: {
                height: '38px',
                '&:hover, &:focus, &:active': {
                    backgroundColor: palette.primary.light,
                },
            },
            outlined: {
                ...eq3Medium,
                padding: '6px 16px',
                textTransform: 'uppercase',
                minWidth: '100px',

                '&$disabled': {
                    border: `1px solid ${palette.primary.main}`,
                    color: palette.primary.main,
                    backgroundColor: 'rgba(13, 71, 161, .3)',
                },
            },
            outlinedPrimary: {
                border: `1px solid ${palette.primary.main}`,
                '&:hover, &:focus, &:active': {
                    backgroundColor: '#E8F0F9',
                },
            },
            textPrimary: {
                ...eq3Medium,
                '&:hover, &:focus, &:active': {
                    backgroundColor: '#E8F0F9',
                },
            },
            sizeSmall: {
                ...eq3Book,
                height: '24px',
                fontSize: '12px',
                textTransform: 'capitalize',
            },
            label: {
                whiteSpace: 'nowrap',
            },
        },
        MuiCard: {
            root: {
                boxShadow: '0px 0px 1px rgba(63, 63, 68, 0.05), 0px 1px 3px rgba(63, 63, 68, 0.15)',
                padding: spacing(3),
            },
        },
        MuiCardHeader: {
            root: {
                padding: 0,
                alignItems: 'flex-start',
            },
            action: {
                margin: '0px !important',
            },
        },
        MuiCardContent: {
            root: {
                padding: 0,
                '&:last-child': {
                    paddingBottom: 0,
                },
            },
        },
        MuiCheckbox: {
            root: {
                color: '#C4C9CC',
            },
            indeterminate: {
                color: palette.primary.main,
            },
        },
        MuiDialogTitle: {
            root: {
                ...eq3Medium,
                fontSize: '16px',
                lineHeight: '20px',
                letterSpacing: '-0.05px',
                color: '#263238',
            },
        },
        MuiDialogContent: {
            root: {
                padding: spacing(3),
                borderTop: '1px solid #EEEEEE',
                borderBottom: '1px solid #EEEEEE',
            },
        },
        MuiDialogContentText: {
            root: {
                ...eq3Book,
                fontSize: '14px',
                lineHeight: '21px',
                letterSpacing: '-0.05px',
                color: '#263238',
                marginBottom: 0,
            },
        },
        MuiDialogActions: {
            root: {
                padding: spacing(2, 3),
            },
            spacing: {
                '& > :not(:first-child)': {
                    marginLeft: spacing(2),
                },
            },
        },
        MuiLinearProgress: {
            root: {
                borderRadius: 3,
                overflow: 'hidden',
            },
        },
        MuiInputBase: {
            input: {
                fontSize: '14px',
                '&::placeholder': {
                    opacity: 1,
                    color: colors.blueGrey[900],
                },
            },
        },
        MuiInputLabel: {
            formControl: {
                transform: 'translate(4px, 24px) scale(1)',
                zIndex: 1,
                pointerEvents: 'none',
            },
        },
        MuiInput: {
            formControl: {
                background: 'white',
                borderRadius: 3,
                zIndex: 0,
            },
            underline: {
                '&:before': {
                    borderBottom: '1px solid rgba(0,0,0,0.12)',
                    borderRadius: 3,
                },
            },
        },
        MuiSelect: {
            select: {
                fontSize: '14px',
                '&:focus': {
                    background: 'inherit',
                    borderRadius: 'inherit',
                },
            },

        },
        MuiDivider: {
            root: {
                backgroundColor: '#EEEEEE',
            },
        },
        MuiListSubheader: {
            inset: {
                paddingTop: 16,
                paddingBottom: 16,
                paddingLeft: 32,
            },
        },
        MuiListItemText: {
            inset: {
                '&:first-child': {
                    paddingLeft: 16,
                },
            },
        },
        MuiListItemIcon: {
            root: {
                minWidth: 32,
            },
        },
        MuiAccordion: {
            root: {
                background: 'transparent',
            },
        },
        MuiAccordionSummary: {
            root: {
                background: 'white',
                '&$expanded': {
                    background: 'transparent',
                },
            },
        },
        MuiTableCell: {
            root: {
                fontSize: '0.75rem',
            },
        },
        MuiTabs: {
            root: {
                backgroundColor: '#E5E5E5',
            },
            indicator: {
                backgroundColor: palette.primary.main,
            },
        },
        MuiTab: {
            root: {
                ...eq3Medium,
                fontSize: '13px',
            },
            textColorPrimary: {
                color: '#263238',
            },
            textColorInherit: {
                opacity: 0.4,
            },
        },
        MuiChip: {
            root: {
                backgroundColor: 'rgba(0,0,0,0.075)',
            },
            deleteIconColorPrimary: {
                color: colors.common.white,
            },
        },
        MuiBreadcrumbs: {
            root: {
                '& > *': {
                    textTransform: 'uppercase',
                },
            },
        },
    },
    zIndex: {
        modal: 1500,
        snackbar: 1501,
    },
});

export default theme;
