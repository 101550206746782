import React from 'react';
import {Popover, PopoverProps} from '@material-ui/core';
import {MyProfileEditor} from '@eq3/containers/myProfile';

interface IProps {
    handleClose: () => void;
    popoverProps: PopoverProps;
}

const MyProfileEditorPopover = (props: IProps) => {
    return (
        <Popover
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            BackdropProps={{ open: props.popoverProps.open }}
            {...props.popoverProps}
        >
            <MyProfileEditor onDismiss={props.handleClose}/>
        </Popover>
    );
};

export {MyProfileEditorPopover};