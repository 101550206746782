import ReadyToShipTag from '@eq3/containers/retail/priceTags/pages/tags/large/readyToShip/ReadyToShipTag';
import FillableShelfTalkerTag from '@eq3/containers/retail/priceTags/pages/tags/small/FillableShelfTalkerTag';
import React from 'react';
import {
    fetchLargeTagData,
    fetchMediumTagData,
    fetchPillowTagData,
    fetchRugTagData,
    fetchSmallTagData,
} from '../../../../redux/priceTags';
import {
    accessoriesTagMenuMapper,
    accessoriesTagProductMapper,
    bedProductMapper,
    bedroomCasegoodsProductMapper,
    bedroomCasegoodsTagMenuMapper,
    bedsTagMenuMapper,
    customizableProductMapper,
    customizableProductsTagMenuMapper,
    fillableShelfTalkerTagMenuMapper,
    customShelfTalkerTagMenuMapper,
    largeTagDataSelector,
    mediumTagDataSelector,
    pillowProductMapper,
    pillowProductsSelector,
    pillowTagDataSelector,
    pillowTagMenuMapper,
    readyToShipMenuMapper,
    readyToShipProductMapper,
    readyToShipProductsSelector,
    rugProductMapper,
    rugTagDataSelector,
    rugTagMenuMapper,
    saleTagMenuMapper,
    shelfTalkerTagMenuMapper,
    smallTagDataSelector,
    upholsteredBedProductMapper,
    upholsteredBedTagMenuMapper,
    upholsteryCollectionProductMapper,
    upholsteryTagMenuMapper,
} from '../../../../reselect/priceTags';
import {i18n, ILocale, ILocalizedLabels} from '../util/i18n';
import LargePage from './LargePage';
import MediumPage from './MediumPage';
import PillowCardPage from './PillowCardPage';
import Proposition65Page from './Proposition65Page';
import RugCardPage from './RugCardPage';
import SmallPage from './SmallPage';
import LargePartialTag from './tags/large/LargePartialTag';
import LargeTagsUploader from './tags/large/LargeTagsUploader';
import MediumPartialTag from './tags/medium/MediumPartialTag';
import PillowPartialTag from './tags/pillow/PillowPartialTag';
import Prop65AccessoriesTag from './tags/prop65/Prop65AccessoriesTag';
import Prop65GenericTag from './tags/prop65/Prop65GenericTag';
import Prop65UpholsteryTag from './tags/prop65/Prop65UpholsteryTag';
import RugPartialTag from './tags/rug/RugPartialTag';
import AccessoryTag from './tags/small/AccessoryTag';
import BedroomCaseGoodsTag from './tags/small/BedroomCaseGoodsTag';
import FillableSaleShelfTalkerTag from './tags/small/FillableSaleShelfTalkerTag';
import SmallPartialTag from './tags/small/SmallPartialTag';
import TagSizeUploader from './tags/uploading/TagSizeUploader';
import CustomShelfTalkerTag from './tags/small/CustomShelfTalkerTag';

export interface ITag {
    id: string;
    labelId: string;
    hasCollections: boolean;
    menuMapper: (tagData: any) => any;
    productMapper: (productSource: any) => any;
    requiresDataSource: boolean;
    productNameField: ILocalizedLabels;
    queryableStringMapper: (product: any, locale: ILocale) => string;
    getTagUploaderElement?: () => React.ReactNode;
}
export const Tags: {[tagName: string]: ITag} = {
    upholstery: {
        id: 'UPHOLSTERY',
        labelId: 'upholstery',
        hasCollections: true,
        menuMapper: upholsteryTagMenuMapper,
        productMapper: upholsteryCollectionProductMapper,
        requiresDataSource: true,
        productNameField: {
            en_CA: 'nameEN',
            fr_CA: 'nameFR',
            en_US: 'nameEN',
        },
        queryableStringMapper: (product, locale) => product[locale.id].name,
    },
    sectionals: {
        id: 'sectionals',
        labelId: 'sectionals',
        hasCollections: false,
        requiresDataSource: true,
    },
    beds: {
        id: 'BEDS',
        labelId: 'beds',
        hasCollections: false,
        menuMapper: bedsTagMenuMapper,
        productMapper: bedProductMapper,
        requiresDataSource: true,
        productNameField: {
            en_CA: 'nameEN',
            fr_CA: 'nameFR',
            en_US: 'nameEN',
        },
    },
    upholsteredBeds: {
        id: 'UPHOLSTERED_BEDS',
        labelId: 'upholstered_beds',
        hasCollections: false,
        menuMapper: upholsteredBedTagMenuMapper,
        productMapper: upholsteredBedProductMapper,
        requiresDataSource: true,
        productNameField: {
            en_CA: 'nameEN',
            fr_CA: 'nameFR',
            en_US: 'nameEN',
        },
    },
    slats: {
        id: 'SLATS',
        labelId: 'slats',
        hasCollections: false,
        menuMapper: undefined,
        requiresDataSource: true,
    },
    customizableProducts: {
        id: 'CUSTOMIZABLE_PRODUCTS',
        labelId: 'customizable_products',
        hasCollections: false,
        menuMapper: customizableProductsTagMenuMapper,
        productMapper: customizableProductMapper,
        requiresDataSource: true,
        productNameField: {
            en_CA: 'nameEN',
            fr_CA: 'nameFR',
            en_US: 'nameEN',
        },
    },
    rugs: {
        id: 'RUGS',
        labelId: 'rugs',
        hasCollections: false,
        menuMapper: rugTagMenuMapper,
        productMapper: rugProductMapper,
        requiresDataSource: true,
        productNameField: {
            en_CA: 'nameEN',
            fr_CA: 'nameFR',
            en_US: 'nameEN',
        },
    },
    bedroomCaseGoods: {
        id: 'BEDROOM_CASEGOODS',
        labelId: 'bedroom_casegoods',
        hasCollections: false,
        menuMapper: bedroomCasegoodsTagMenuMapper,
        productMapper: bedroomCasegoodsProductMapper,
        requiresDataSource: true,
        productNameField: {
            en_CA: 'nameEN',
            fr_CA: 'nameFR',
            en_US: 'nameEN',
        },
        tagRenderer: (product) => (<BedroomCaseGoodsTag product={product} />),
    },
    pillows: {
        id: 'PILLOWS',
        labelId: 'pillows',
        hasCollections: false,
        menuMapper: pillowTagMenuMapper,
        productMapper: pillowProductMapper,
        allProductsSelector: pillowProductsSelector,
        requiresDataSource: true,
        productNameField: {
            en_CA: 'descriptionEN',
            fr_CA: 'descriptionFR',
            en_US: 'descriptionEN',
        },
        queryableStringMapper: (product, locale) => product[locale.id].description + ' ' + i18n[locale.id][product.fill] + ' ' + i18n[locale.id][product.gradeId],
    },
    fillableShelfTalkers: {
        id: 'fillableShelfTalkers',
        labelId: 'fillable_shelf_talkers',
        hasCollections: false,
        menuMapper: fillableShelfTalkerTagMenuMapper,
        requiresDataSource: false,
        tagRenderer: () => (<FillableShelfTalkerTag />),
    },
    customShelfTalker: {
        id: 'customShelfTalker',
        labelId: 'custom_shelf_talker',
        hasCollections: false,
        menuMapper: customShelfTalkerTagMenuMapper,
        requiresDataSource: false,
        tagRenderer: () => (<CustomShelfTalkerTag />),
    },
    saleShelfTalkers: {
        id: 'saleShelfTalkers',
        labelId: 'shelf_talkers',
        hasCollections: false,
        menuMapper: shelfTalkerTagMenuMapper,
        requiresDataSource: false,
        tagRenderer: () => (<FillableSaleShelfTalkerTag />),
    },
    saleTag: {
        id: 'saleTag',
        labelId: 'saleTag',
        hasCollections: false,
        menuMapper: saleTagMenuMapper,
        requiresDataSource: false,
    },
    prop65Accessories: {
        id: 'prop65Accessories',
        labelId: 'prop65Accessories',
        hasCollections: false,
        requiresDataSource: false,
        tagRenderer: () => (<Prop65AccessoriesTag />),
    },
    prop65Upholstery: {
        id: 'prop65Upholstery',
        labelId: 'prop65Upholstery',
        hasCollections: false,
        requiresDataSource: false,
        tagRenderer: () => (<Prop65UpholsteryTag />),
    },
    prop65Generic: {
        id: 'prop65Generic',
        labelId: 'prop65Generic',
        hasCollections: false,
        requiresDataSource: false,
        tagRenderer: () => (<Prop65GenericTag />),
    },
    accessories: {
        id: 'ACCESSORIES',
        labelId: 'accessories',
        hasCollections: false,
        menuMapper: accessoriesTagMenuMapper('ACCESSORIES', 'accessories'),
        productMapper: accessoriesTagProductMapper,
        requiresDataSource: true,
        productNameField: {
            en_CA: 'collectionNameEN',
            fr_CA: 'collectionNameFR',
            en_US: 'collectionNameEN',
        },
        tagRenderer: (product) => (<AccessoryTag product={product} />),
    },
    lighting: {
        id: 'LIGHTING',
        labelId: 'lighting',
        hasCollections: false,
        menuMapper: accessoriesTagMenuMapper('LIGHTING', 'lighting'),
        productMapper: accessoriesTagProductMapper,
        requiresDataSource: true,
        productNameField: {
            en_CA: 'collectionNameEN',
            fr_CA: 'collectionNameFR',
            en_US: 'collectionNameEN',
        },
        tagRenderer: (product) => (<AccessoryTag product={product} />),
    },
    readyToShip: {
        id: 'READY_TO_SHIP',
        labelId: 'readyToShip',
        hasCollections: false,
        requiresDataSource: true,
        menuMapper: readyToShipMenuMapper,
        productMapper: readyToShipProductMapper,
        tagRenderer: (product) => (<ReadyToShipTag product={product}/>),
        productNameField: {
            en_CA: 'searchNameEN',
            fr_CA: 'searchNameFR',
            en_US: 'searchNameEN',
        },
        allProductsSelector: readyToShipProductsSelector,
    },
};

const largePartialTag = (menu) => (<LargePartialTag menu={menu} />);
const mediumPartialTag = (menu) => (<MediumPartialTag menu={menu} />);
const smallPartialTag = (menu) => (<SmallPartialTag menu={menu} />);
const pillowPartialTag = (menu) => (<PillowPartialTag menu={menu} />);
const rugPartialTag = (menu) => (<RugPartialTag menu={menu} />);

export interface ITagSize {
    id: string;
    labelId: string;
    printSheet: {
        descriptionId: string;
        style: string;
    };
    getPageElement: () => React.ReactNode;
    getTagSizeUploaderElement: () => React.ReactNode;
    singleTagElement: (menu) => React.ReactNode;
    tags: ITag[];
}

export const TagSizes: {[size: string]: ITagSize} = {
    large: {
        id: 'large',
        labelId: 'large',
        printSheet: {
            descriptionId: 'template_large',
            style: '@page{size:legal portrait;margin:0}',
        },
        getPageElement: () => (<LargePage />),
        getTagSizeUploaderElement: () => (<LargeTagsUploader />),
        singleTagElement: largePartialTag,
        tags: [Tags.upholstery, Tags.beds, Tags.upholsteredBeds, Tags.customizableProducts, Tags.readyToShip],
        dataFetcher: () => fetchLargeTagData(),
        dataSelector: (state) => largeTagDataSelector(state),
    },
    medium: {
        id: 'medium',
        labelId: 'medium',
        printSheet: {
            descriptionId: 'template_medium',
            style: '@page{size:letter portrait;margin:0}',
        },
        getPageElement: () => (<MediumPage />),
        getTagSizeUploaderElement: () => (<TagSizeUploader tagSize={TagSizes.medium}/>),
        singleTagElement: mediumPartialTag,
        tags: [Tags.rugs],
        dataFetcher: () => fetchMediumTagData(),
        dataSelector: (state) => mediumTagDataSelector(state),
    },
    small: {
        id: 'small',
        labelId: 'small',
        printSheet: {
            descriptionId: 'template_small',
            style: '@page{size:letter landscape;margin:0}',
        },
        getPageElement: () => (<SmallPage />),
        getTagSizeUploaderElement: () => (<TagSizeUploader tagSize={TagSizes.small}/>),
        singleTagElement: smallPartialTag,
        tags: [Tags.fillableShelfTalkers, Tags.saleShelfTalkers, Tags.customShelfTalker, Tags.bedroomCaseGoods, Tags.accessories, Tags.lighting],
        dataFetcher: () => fetchSmallTagData(),
        dataSelector: (state) => smallTagDataSelector(state),
    },
    prop65: {
        id: 'prop65',
        labelId: 'prop65',
        printSheet: {
            descriptionId: 'template_small',
            style: '@page{size:letter landscape;margin:0}',
        },
        getPageElement: () => (<Proposition65Page />),
        singleTagElement: smallPartialTag,
        tags: [Tags.prop65Accessories, Tags.prop65Upholstery],
    },
    rugs: {
        id: 'rugs',
        labelId: 'rug_card',
        printSheet: {
            descriptionId: 'template_rug_card',
            style: '@page{size:legal landscape;margin:0}',
        },
        getPageElement: () => (<RugCardPage />),
        getTagSizeUploaderElement: () => (<TagSizeUploader tagSize={TagSizes.rugs}/>),
        singleTagElement: rugPartialTag,
        tags: [Tags.rugs],
        dataFetcher: () => fetchRugTagData(),
        dataSelector: (state) => rugTagDataSelector(state),
    },
    pillows: {
        id: 'pillows',
        labelId: 'pillow_card',
        printSheet: {
            descriptionId: 'template_pillow_card',
            style: '@page{size:letter portrait;margin:0}',
        },
        getPageElement: () => (<PillowCardPage />),
        getTagSizeUploaderElement: () => (<TagSizeUploader tagSize={TagSizes.pillows}/>),
        singleTagElement: pillowPartialTag,
        tags: [Tags.pillows],
        dataFetcher: () => fetchPillowTagData(),
        dataSelector: (state) => pillowTagDataSelector(state),
    },
};
