import { Pagination } from '@eq3/utils/pagination';
import { createActionCreator } from 'deox';
import { ThunkDispatch } from 'redux-thunk';
import { apiThunk as ApiThunk } from '../store';
import {
    ICustomerAddress,
    ICustomerDemographic,
    ICustomerDetails,
    ICustomerListItem,
    ICustomersReduxSlice,
} from './models';

const createCustomerActionCreator: typeof createActionCreator = (type, resolver) => createActionCreator(`[CUSTOMERS]\\${type}` as typeof type, resolver);

const actions = {
    setPagedCustomerListItem: createCustomerActionCreator(
        'SET_LIST_ITEMS',
        (resolve) => (data: Pagination<ICustomerListItem>) => resolve(data),
    ),
    setCustomerDetails: createCustomerActionCreator(
        'SET_DETAILS',
        (resolve) => (data: ICustomerDetails) => resolve(data),
    ),
    setCustomerDemographic: createCustomerActionCreator(
        'SET_DEMOGRAPHIC',
        (resolve) => (data: { customerId: string, demographic: ICustomerDemographic }) => resolve(data),
    ),
    setCustomerAddresses: createCustomerActionCreator(
        'SET_ADDRESSES',
        (resolve) => (data: { customerId: string, addresses: ICustomerAddress[] }) => resolve(data),
    ),
};

export default { ...actions };
export type CustomersActiontype = ReturnType<typeof actions[keyof (typeof actions)]>;
export type CustomersDispatch = ThunkDispatch<ICustomersReduxSlice, ApiThunk, CustomersActiontype>;
