import { useJsApiLoader } from '@react-google-maps/api';
import { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url';

const GOOGLE_LIBS: Libraries = ['places'];

const options = {
    googleMapsApiKey: process.env.GOOGLE_CLOUD_API_KEY ?? '',
    libraries: GOOGLE_LIBS
};

const useGoogleMapsApiLoader = () => {
    return useJsApiLoader(options);
};

export default useGoogleMapsApiLoader;
