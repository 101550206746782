import { SquareUnlinkedRefundDetailsDto } from '@eq3-aws/payments-client';
import { Language, LAST_SELECTED_RECEIPT_LANGUAGE_LS_KEY } from '@eq3/containers/pos-square/widgets/receipts/translations';
import UnlinkedRefundReceiptData from '@eq3/containers/pos-square/widgets/receipts/unlinkedRefundReceipt/UnlinkedRefundReceiptData';
import { getSquareUnlinkedRefundDetails } from '@eq3/redux/pos/thunks/squareRefundThunks';
import { CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { ThunkDispatch } from 'redux-thunk';

const UnlinkedRefundReceiptFrame = () => {
    const { refundId } = useParams<{ refundId: string }>();

    const classes = useStyles();
    const dispatch = useDispatch<ThunkDispatch>();

    const [unlinkedRefundDetails, setUnlinkedRefundDetails] = useState<SquareUnlinkedRefundDetailsDto>();
    const [error, setError] = useState<string>();

    useEffect(() => {
        const subscription = dispatch(getSquareUnlinkedRefundDetails(refundId))
            .subscribe({
                next: (ur) => {
                    setUnlinkedRefundDetails(ur);
                },
                error: (err) => {
                    console.error(err);
                    setError(`Sorry. An error occurred when loading unlinked refund ${refundId}.`);
                },
            });

        return () => subscription.unsubscribe();
    }, []);

    useEffect(() => {
        // Prompt to print when the receipt is rendered.
        if (unlinkedRefundDetails && !error) {
            window.print();
        }
    }, [unlinkedRefundDetails, error]);

    const renderError = () => (
        <Typography color="error">{error}</Typography>
    );

    const renderLoadingFrame = () => (
        <div className={classes.loadingFrame}>
            <CircularProgress size={64}/>
        </div>
    );

    const key = window.localStorage.getItem(LAST_SELECTED_RECEIPT_LANGUAGE_LS_KEY);
    const language = (key !== null ? Language[key.toUpperCase()] : null) ?? Language.EN;
    
    return (
        <div className={classes.root}>
            {!!error
                ? renderError()
                : !unlinkedRefundDetails
                    ? renderLoadingFrame()
                    : <UnlinkedRefundReceiptData unlinkedRefundDetails={unlinkedRefundDetails!} language={language} />}
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '360px',
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(1),
    },
    loadingFrame: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        width: '100%',
        height: '100%',
    },
}));

export default UnlinkedRefundReceiptFrame;
