import { WithStyles } from '@material-ui/core/styles';
import React from 'react';

import {DeleteButton} from '@eq3/containers/retail/priceTags/components/Buttons';
import Grades from '../Grades';
import LocalizedString from './LocalizedString';
import PriceInput from './PriceInput';

import nonPrintable from '@eq3/containers/retail/priceTags/util/nonPrintable';
import { createStyles, withStyles } from '@material-ui/core';

interface IState {
    shownGrades: {[id: string]: any};
}
class GradesTable extends React.Component<WithStyles<typeof styles>, IState> {
    public state = {
        shownGrades: Object.values(Grades).reduce((accumulator, grade) => ({...accumulator, [grade.id]: grade}), {}),
    };

    private deleteGrade = (idToDelete) => () => {
        const shownGrades = {...this.state.shownGrades};

        delete shownGrades[idToDelete];

        this.setState({shownGrades});
    };

    private renderColumn = (materialType: 'leather' | 'fabric') => {
            const {classes} = this.props;
            const {shownGrades} = this.state;

            const materials = Object.values(shownGrades).filter((g) => g.material === materialType);

            return (
                <div>
                    <div className={classes.gradeHeader} ><LocalizedString labelId={materialType + '_grade'}/></div>
                    {materials.map(({id, material}) => {
                        const label = id.replace('grade', '');
                        return (
                            <div className={classes.gradeRow} key={id}>
                                <div className={classes.gradeName}>
                                    <DeleteButton className={`${classes.deleteButton} ${nonPrintable}`} onClick={this.deleteGrade(id)}/>
                                    {label}
                                </div>
                                <div className={classes.gradePrice}>
                                    <PriceInput className={classes.gradePriceInput}/>
                                </div>
                            </div>
                        );
                    })}
                </div>
        );
    }

    public render() {
        const {classes} = this.props;
        return (
            <>
                <div className={classes.gradeTable}>
                    {this.renderColumn('fabric')}

                    {this.renderColumn('leather')}
                </div>

            </>
        );
    }
}

const styles = () => createStyles({
    deleteButton: {
        transform: 'scale(.4) translate(-180%, -70%)',
        position: 'absolute',
        top: 0,
        left: 0,
    },
    gradeTable: {
        display: 'grid',
        gridTemplateColumns: '45% 45%',
        columnGap: '10%',
        fontSize: '8pt',
        marginBottom: '20px',
    },
    gradeHeader: {
        fontFamily: 'eq3-book',
        textAlign: 'center',
    },
    gradeName: {
        fontFamily: 'eq3-book',
        fontSize: '8pt',
        position: 'relative',
    },
    gradeMaterial: {
        fontFamily: 'eq3-book',
        fontSize: '8pt',
        textAlign: 'center',
    },
    gradePrice: {
        width: '100%',
        textAlign: 'right',
    },
    gradePriceInput: {
        width: '60px',
        textAlign: 'right',
        borderWidth: '0px',
    },
    gradeRow: {
        padding: '0 8px',
        display: 'flex',
        justifyContent: 'space-between',
    },
});
export default (withStyles(styles)(GradesTable));
