import {ClearButton} from '@eq3/containers/retail/priceTags/components/Buttons';
import { StyleRules, WithStyles } from '@material-ui/core/styles';
import React from 'react';
import {compose} from 'redux';
import ProductDroppable from '../../../components/ProductDroppable';
import SelectMenu from '../../../components/SelectMenu';
import PillowTag from './PillowTag';

import { withStyles } from '@material-ui/core';

interface IProps {
    menu: any;
    initialProduct?: any;
}
class PillowPartialTag extends React.Component<IProps & WithStyles<ReturnType<typeof styles>>> {
    public state = {
        product: undefined,
    };
    constructor(props: IProps) {
        super(props);
        this.state = {
            product: props.initialProduct,
        };
    }

    private handleClearTag = () => {
        this.setState({product: undefined});
    };

    private handleProductSelected = (menuItemPath) => {
        if (menuItemPath[menuItemPath.length - 1].data) {
            this.handleProduct(menuItemPath[menuItemPath.length - 1].data);
        }
    }

    private handleProduct = (product) => {
        this.setState({product});
    }

    public render(): JSX.Element {
        const {classes, menu} = this.props;
        const {product} = this.state;
        return (
            <ProductDroppable onProductDropped={this.handleProduct}>
                <div className={classes.tag}>
                    {!product &&
                    <SelectMenu itemTree={menu.itemTree} onLeafNodeSelected={this.handleProductSelected}/>
                    }
                    {product && (
                        <>
                            <PillowTag product={product}/>
                            <ClearButton onClick={this.handleClearTag} className={classes.clearTagButton}/>
                        </>
                    )}
                </div>
            </ProductDroppable>
        );
    }
}

const styles = () => ({
    tag: {
        width: '1.5in',
        height: '1in',
        margin: '0in .125in 0in 0in',
        padding: '0.05in',
        boxShadow: '0 0 0 1px silver',
        borderRadius: '10px',
        position: 'relative',
        '@media print': {
            boxShadow: 'unset',
        },
    },
    clearTagButton: {
        position: 'absolute',
        top: '0',
        right: '0',
        transform: 'scale(.5) translateY(-75%)',
    },
}) as StyleRules;

export default (compose(
    withStyles(styles),
)(PillowPartialTag)) as React.ComponentType<IProps>;
