import createBreakpoints, {Breakpoints} from '@material-ui/core/styles/createBreakpoints';
import {Theme} from '@material-ui/core';

export const MOBILE_BREAKPOINT = 800;
const breakpoints: Breakpoints = createBreakpoints({
    values: {
        xs: 0,
        sm: 600,
        md: MOBILE_BREAKPOINT,
        lg: 1200,
        xl: 1563,
    },
});

export const isMobileView: (theme: Theme) => string = (theme) => theme.breakpoints.down('sm');

export default breakpoints;