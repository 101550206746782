import { INavItem, ISidebarRoutes, useMainNav } from '@eq3/component/SidebarRoutes';
import { logout } from '@eq3/redux/auth';
import {
    Accordion,
    AccordionDetails,
    AccordionProps,
    AccordionSummary,
    Drawer,
    Icon,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles, Paper,
    Toolbar,
    Tooltip,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import classnames from 'classnames';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import {NavLink} from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import BugIcon from './icons/BugIcon';
import Chevron from './icons/Chevron';
import SignOutIcon from './icons/SignOutIcon';
import eq3Colours from '@eq3/design/theme/colours';
import { texxtStyles } from '@eq3/design/theme/typography';
import {useAppBarContext} from '@eq3/component/AppBarContext';

export const NAV_WIDTH = '320px';

interface IProps {
    isSidebarOpen: boolean;
    onSidebarClose: () => void;
}

const Sidebar = ({isSidebarOpen, onSidebarClose}: IProps) => {
    const classes = useStyles();
    const {mainNav} = useMainNav();

    const [accordionState, setAccordionState] = useState<{ [key: string]: boolean }>({});

    const dispatch = useDispatch<ThunkDispatch>();
    const onLogout = () => dispatch(logout()).subscribe();

    const handleAccordionStateChange = (panelName: string): AccordionProps['onChange'] => (_, expanded) => {
        setAccordionState(((prevState) => ({
            ...prevState,
            [panelName]: expanded,
        })));
    };

    const calcIndent = (depth: number): number => (depth) ? (8 * depth) : 8;

    const reportBugDialog = () => {
        return (
            <Helmet>
                {/* Jira Report a Bug Modal for allowing internal users to submit bugs to the jira backlog */}
                <script type="text/javascript"
                        src="https://eq3furniture.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-7vyend/b/19/a44af77267a987a660377e5c46e0fb64/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=0e17b015"/>
                <script type="text/javascript">{`
                    window.ATL_JQ_PAGE_PROPS =  {
                        "triggerFunction": function(showCollectorDialog) {
                            jQuery("#report-a-bug, #report-a-bug-mobile").click(function(e) {
                            e.preventDefault();
                            showCollectorDialog();
                        });
                    }}`}
                </script>
            </Helmet>
        );
    };

    const appbarContext = useAppBarContext();
    const onNavLinkClicked = (parentNodeTitle: string, leafTitle: string) => () => {
        appbarContext.setTitle(parentNodeTitle);
        appbarContext.setDescription(leafTitle);
        onSidebarClose();
    };

    const renderNavLeaves = (navItems: INavItem[], parentNode: ISidebarRoutes, categoryDepth: number) => {
        return (
            <>
                {navItems.map((url) => (
                    <div key={`${parentNode.title}-${url.title}`}>
                        <NavLink
                            to={url.path}
                            activeClassName={classes.activeLink}
                            className={classnames(classes.links, classes.summaryRoot)}
                            style={{paddingLeft: calcIndent(categoryDepth)}}
                            onClick={onNavLinkClicked(parentNode.title, url.title)}
                        >

                            {/* Not really rendering an icon here. This is just to maintain the same padding as the subcategories do. */}
                            <Icon className={classes.menuItemIcon}/>

                            {url.title}
                        </NavLink>
                        {url.alertText && (
                            <Tooltip title={url.alertText} aria-label={url.alertText} className={classes.alertIcon}>
                                <ErrorOutlineIcon color={'error'}/>
                            </Tooltip>
                        )}
                    </div>
                ))}
            </>
        );
    };

    const renderNavCategories = (categories: ISidebarRoutes[], categoryDepth: number) => {
        return (
            <>
                {categories.map((category) => {
                    return (
                        <React.Fragment key={category.key}>
                            <Accordion
                                classes={{
                                    root: classes.expansionRoot,
                                    expanded: classes.expanded,
                                }}
                                onChange={handleAccordionStateChange(category.key)}
                                expanded={accordionState[category.key] ?? false}
                            >
                                <AccordionSummary
                                    classes={{
                                        root: classes.summaryRoot,
                                        expanded: classes.expanded,
                                        content: classes.summaryContent,
                                        expandIcon: classes.expandIcon,
                                    }}
                                    style={{paddingLeft: calcIndent(categoryDepth)}}
                                    expandIcon={<Chevron/>}
                                >
                                    <Icon className={classes.menuItemIcon}>{category.icon}</Icon>
                                    <Typography className={classes.navTitle}>{category.title}</Typography>
                                    {/* Don't need to show the alert if expanded; the nav leaf will display the alert. */}
                                    {category.alertText && !accordionState[category.key] && (
                                        <Tooltip title={category.alertText} aria-label={category.alertText}
                                                 className={classes.alertIcon}>
                                            <ErrorOutlineIcon color={'error'}/>
                                        </Tooltip>
                                    )}
                                </AccordionSummary>

                                <AccordionDetails classes={{root: classes.detailsRoot}}>
                                    {category.urls ? renderNavLeaves(category.urls, category, categoryDepth + 1) : null}
                                    {/* If the above category has a subCategory, render a nested expansion panel for each subCategory. */}
                                    {category.subCategory ? renderNavCategories(category.subCategory!, categoryDepth + 1) : null}
                                </AccordionDetails>
                            </Accordion>
                        </React.Fragment>
                    );
                })}
            </>
        );
    };

    return (
        <>
            <Drawer
                className={classes.drawer}
                variant="temporary"
                open={isSidebarOpen}
                elevation={0}
                onClose={onSidebarClose}
                classes={{
                    paper: classes.drawerPaper,
                }}
                PaperProps={{
                    ['data-cy']: 'side-bar-nav',
                }}
                ModalProps={{
                    BackdropProps: {
                        classes: {
                            root: classes.backdropRoot,
                        },
                    }
                }}
            >
                <Toolbar />

                <div className={classes.scrollContainer}>
                <Paper className={classes.innerDrawerPaper}>
                    <div className={classes.drawerContent}>
                        {renderNavCategories(mainNav, 0)}

                        <List className={classes.list}>
                            <ListItem
                                button
                                id="report-a-bug"
                                classes={{
                                    root: classes.expansionRoot,
                                    gutters: classes.gutters,
                                }}
                            >
                                <ListItemIcon classes={{root: classes.menuItemIcon}}>
                                    <BugIcon/>
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography className={classes.navTitle}>Report Bug</Typography>
                                </ListItemText>
                            </ListItem>

                            <ListItem
                                button
                                aria-haspopup="true"
                                classes={{
                                    root: classes.expansionRoot,
                                    gutters: classes.gutters,
                                }}
                                onClick={onLogout}
                            >
                                <ListItemIcon classes={{root: classes.menuItemIcon}}>
                                    <SignOutIcon/>
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography className={classes.navTitle}>Sign Out</Typography>
                                </ListItemText>
                            </ListItem>

                        </List>
                    </div>
                </Paper>
                </div>
                {reportBugDialog()}
            </Drawer>
        </>
    );
};

const useStyles = makeStyles((theme) => ({
    scrollContainer: {
        overflowY: 'auto',
    },
    headingToolbar: {
        padding: theme.spacing(1),
        minHeight: 'unset',
    },
    appContextDescription: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    logo: {
        height: '28px',
    },
    logoLink: {
        display: 'flex',
    },
    userToolbar: {
        cursor: 'pointer',
        minHeight: theme.spacing(8),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingLeft: theme.spacing(1),
    },
    avatar: {
        width: theme.spacing(5),
        height: theme.spacing(5),
    },
    drawer: {
        width: NAV_WIDTH,
    },
    drawerPaper: {
        background: 'transparent',
    },
    innerDrawerPaper: {
        margin: theme.spacing(2),
        background: eq3Colours.primary,
        top: 0,
        bottom: 0,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(2),
        width: NAV_WIDTH,
        overflowY: 'auto',
        ...texxtStyles.label,
        '& .MuiAccordionSummary-root': {
            background: `inherit`,
        },
        '& .MuiAccordion-rounded': {
            borderRadius: theme.shape.borderRadius,
        },
        position: 'static',
    },
    userInfoContainer: {
        color: eq3Colours.white,
        marginLeft: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
    menuItemIcon: {
        marginRight: theme.spacing(1),
        width: '16px',
        height: '16px',
        minWidth: 'auto',
        color: eq3Colours.white,
        '& svg': {
            verticalAlign: 'top',
        },
    },
    navTitle: {
        color: eq3Colours.white,
        ...texxtStyles.label,
    },
    links: {
        textDecorationLine: 'none',
        display: 'flex',
        alignItems: 'center',
        height: '46px',
        width: '100%',
        color: eq3Colours.white,
        ...texxtStyles.body,
    },
    activeLink: {
        backgroundColor: eq3Colours.primaryDarker,
    },
    gutters: {
        padding: theme.spacing(1),
        minHeight: '52px',
    },
    list: {
        padding: theme.spacing(0),
    },
    // Expansion panel classes
    expandIcon: {
        color: `${eq3Colours.white} !important`,
        padding: theme.spacing(0, 1),
    },
    expansionRoot: {
        borderRadius: theme.shape.borderRadius,
        overflow: 'hidden', // Without this, the contents escape the border, and it looks like the border isn't working
        boxShadow: 'none',
        '&:before': {
            display: 'none',
        },
        '&:hover': {
            background: eq3Colours.primaryDarker,
        }
    },
    expanded: {
        background: eq3Colours.primaryDarker,
    },
    detailsRoot: {
        flexDirection: 'column',
        padding: 0,
    },
    topLevelExpansionRoot: {
        padding: `0 26px 0 16px !important`,
    },
    summaryRoot: {
        padding: theme.spacing(0, 1, 0, 1),
        margin: theme.spacing(0, 0, 0, 0),
        display: 'flex',
        width: '100%',
        '&$expanded': {
            minHeight: 44,
        },
    },
    summaryContent: {
        display: 'flex',
        alignItems: 'center',
        margin: 0,
    },
    alertIcon: {
        marginLeft: theme.spacing(0.5),
    },
    navLeaf: {
        display: 'flex',
        flexDirection: 'row',
    },
    userLocation: {
        marginTop: theme.spacing(.3),
        fontWeight: 600,
    },
    closeButton: {
        color: 'black',
        width: 'max-content',
        marginLeft: NAV_WIDTH,
        left: 0,
    },
    backdropRoot: {
        backgroundColor: eq3Colours.whiteTransparent,
    },
}));

export default Sidebar;
