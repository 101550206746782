import { StyleRules, WithStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';

import { CircularProgress, withStyles } from '@material-ui/core';
import { compose } from 'redux';
import { fetchSmallTagData } from '../../../../redux/priceTags';
import { smallTagDataSelector, smallTagMenuSelector } from '../../../../reselect/priceTags';
import SmallPartialTag from './tags/small/SmallPartialTag';

class SmallPage extends React.Component<WithStyles<ReturnType<typeof styles>> & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>> {

    public componentDidMount() {
        const { smallTagData } = this.props;
        if (!smallTagData) {
            this.props.fetchSmallTagData();
        }
    }

    public render(): JSX.Element {

        const { classes, menu } = this.props;

        if (!menu) {
            return (
                <div className={classes.page}>
                    <CircularProgress
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                        }}
                    />
                </div>
            );
        }

        return (
            <div className={classes.page}>
                <SmallPartialTag menu={menu} />
                <SmallPartialTag menu={menu} />
                <SmallPartialTag menu={menu} />

                <SmallPartialTag menu={menu} />
                <SmallPartialTag menu={menu} />
                <SmallPartialTag menu={menu} />

                <SmallPartialTag menu={menu} />
                <SmallPartialTag menu={menu} />
                <SmallPartialTag menu={menu} />

                <SmallPartialTag menu={menu} />
                <SmallPartialTag menu={menu} />
                <SmallPartialTag menu={menu} />

                <SmallPartialTag menu={menu} />
                <SmallPartialTag menu={menu} />
                <SmallPartialTag menu={menu} />
            </div>
        );
    }
}

const styles = (theme) => ({
    page: {
        width: '11in',
        height: '8.5in',
        padding: '0.109375in 0.15625in',
        display: 'flex',
        flexWrap: 'wrap',
    },
}) as StyleRules;

const mapStateToProps = (state) => ({
    menu: smallTagMenuSelector(state),
    smallTagData: smallTagDataSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    fetchSmallTagData: () => dispatch(fetchSmallTagData()),
});

export default (compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
)(SmallPage));
