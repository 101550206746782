import { useRxSubject } from '@eq3/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { defer } from 'rxjs';
import { finalize, switchMapTo, tap } from 'rxjs/operators';
import { fetchAllTradeReps, fetchUnassignedTradeReps, getAllLocations } from '.';
import { ILocation, ILocationsReduxState, ITradeRepDto, LocationType } from './models';

export const useAllTradeReps = () => {
    const dispatch = useDispatch<ThunkDispatch>();

    useEffect(() => {
        const subscription = dispatch(fetchAllTradeReps()).subscribe();
        return () => subscription.unsubscribe();
    }, []);

    return useSelector(useCallback((state: ILocationsReduxState) => state.locations.tradeRepsCache, []));
};

export const useUnassignedTradeReps = () => {
    const dispatch = useDispatch<ThunkDispatch>();
    const [unassignedTradeReps, setUnassignedTradeReps] = useState<ITradeRepDto[]>([]);

    const fetchUnassignedTradeReps$ = useRxSubject((o) => {
        return o.pipe(
            switchMapTo(dispatch(fetchUnassignedTradeReps())),
            tap((reps) => setUnassignedTradeReps(reps))
        ).subscribe();
    });

    useEffect(() => {
        fetchUnassignedTradeReps$.next();
    }, []);

    return {
        unassignedTradeReps,
        refresh: () => fetchUnassignedTradeReps$.next(),
    };
};

export const useAllLocations = () => {
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch<ThunkDispatch>();
    const locationsByType = useSelector(useCallback((state: ILocationsReduxState) => state.locations.locations, []));
    const allLocations = useMemo(() => {
        return Object.entries(locationsByType)
            .flatMap(([, value]) => value as ILocation[])
            .sort((a, b) => a.locationName.localeCompare(b.locationName));
    }, [locationsByType]);

    const fetchLocations = (
        locationTypesFilter?: LocationType[],
        forceRefresh: boolean = false,
    ) => {
        setIsLoading(true);
        return defer(
            () => dispatch(getAllLocations(locationTypesFilter, forceRefresh))
        ).pipe(
            finalize(() => setIsLoading(false)),
        );
    };

    return {
        locationsByType,
        allLocations,
        isLoading,
        fetchLocations,
    };
};
