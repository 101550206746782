import { InputAdornment } from '@material-ui/core';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import React from 'react';
import TextField, { TextFieldProps } from './TextField';

export type CurrencyFieldProps = TextFieldProps & { min?: number; max?: number };

export default (props: CurrencyFieldProps) => {
    const { min, max, onChange, ...rest } = props;

    return (
        <TextField {...rest}
            inputProps={{
                ...rest.inputProps,
                min,
                max,
            }}
            InputProps={{
                type: 'number',
                startAdornment: (
                    <InputAdornment position="start">
                        <MoneyIcon />
                    </InputAdornment>
                ),
            }} 
        />
    );
};
