import { errorNotification, notify } from '@eq3/redux/adminNotifications';
import {
    addRoleGroup,
    deleteRoleGroupAction,
    RoleGroupsActionsType,
    setAllUserRoles,
    setRoleGroups,
    updateRoleGroupAction,
} from '@eq3/redux/roleGroups/actions';
import { ICreateOrUpdateRoleGroup, IRole, IRoleGroup, IRoleGroupDetails } from '@eq3/redux/roleGroups/models';
import { IRoleGroupsReduxSlice } from '@eq3/redux/roleGroups/reducers';
import { apiThunk } from '@eq3/redux/store';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { defer, EMPTY, from, iif, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';

type RoleGroupsThunkResult<T> = ThunkAction<Observable<T>, IRoleGroupsReduxSlice, apiThunk, RoleGroupsActionsType>;
export type RoleGroupsDispatch = ThunkDispatch<IRoleGroupsReduxSlice, apiThunk, RoleGroupsActionsType>;

export const getAllRoleGroups = (forceRefresh: boolean = false): RoleGroupsThunkResult<IRoleGroup[]> => (dispatch, getState, api) => {
    const { roleGroups: { list: allRoleGroups } } = getState();

    return iif(
        () => forceRefresh || allRoleGroups?.length > 0,
        defer(() => of(allRoleGroups)),
        defer(() => from(api<IRoleGroup[]>(dispatch, getState, `/admin/roleGroups`, 'GET'))
            .pipe(
                map((response) => response.data),
                tap((roleGroups) => dispatch(setRoleGroups(roleGroups))),
                catchError((err) => {
                    dispatch(notify(errorNotification('Error fetching role groups.', err)));
                    throw err;
                }),
            ),
        ),
    );
};

export const getRoleGroupDetails = (roleGroupId: string): RoleGroupsThunkResult<IRoleGroupDetails> => (dispatch, getState, api) => {
    return from(api<IRoleGroupDetails>(dispatch, getState, `/admin/roleGroups/${roleGroupId}`, 'GET'))
        .pipe(
            map((response) => response.data),
            catchError((err) => {
                dispatch(notify(errorNotification('Error fetching role group details.', err)));
                throw err;
            }),
        );
};

export const deleteRoleGroup = (roleGroupId: string): RoleGroupsThunkResult<void> => (dispatch, getState, api) => {
    return from(api<void>(dispatch, getState, `/admin/roleGroups/${roleGroupId}`, 'DELETE'))
        .pipe(
            // Delete the role group in redux.
            tap(() => dispatch(deleteRoleGroupAction(roleGroupId))),
            concatMap(() => EMPTY),
            catchError((err) => {
                dispatch(notify(errorNotification(`Error deleting role group with ID "${roleGroupId}".`, err)));
                throw err;
            }),
        );
};

export const getAllRoles = (): RoleGroupsThunkResult<IRole[]> => (dispatch, getState, api) => {
    const { roleGroups: { roles } } = getState();
    return iif(
        // If we already loaded the roles, then just use that.
        () => roles.length > 0,
        defer(() => of(roles)),
        defer(() => from(api<IRole[]>(dispatch, getState, `/admin/roleGroups/roles`, 'GET'))
            .pipe(
                map(({ data }) => data),
                tap((x) => dispatch(setAllUserRoles(x))),
                catchError((e) => {
                    dispatch(notify(errorNotification('Error fetching roles', e)));
                    return throwError(e);
                }),
            ),
        ),
    );
};

export const createRoleGroup = (newRoleGroup: ICreateOrUpdateRoleGroup): RoleGroupsThunkResult<IRoleGroupDetails> => (dispatch, getState, api) => {
    return from(api<IRoleGroupDetails>(dispatch, getState, `/admin/roleGroups`, 'POST', newRoleGroup))
        .pipe(
            map((response) => response.data),
            tap(({ roleGroup }) => dispatch(addRoleGroup(roleGroup))),
            catchError((err) => {
                dispatch(notify(errorNotification('Error creating role group.', err)));
                throw err;
            }),
        );
};

export const updateRoleGroup = (id: string, roleGroupToUpdate: ICreateOrUpdateRoleGroup): RoleGroupsThunkResult<IRoleGroupDetails> => (dispatch, getState, api) => {
    return from(api<IRoleGroupDetails>(dispatch, getState, `/admin/roleGroups/${id}`, 'PUT', roleGroupToUpdate))
        .pipe(
            map((response) => response.data),
            tap(({ roleGroup }) => dispatch(updateRoleGroupAction(roleGroup))),
            catchError((err) => {
                dispatch(notify(errorNotification('Error creating role group.', err)));
                throw err;
            }),
        );
};

export const syncRoles = (): RoleGroupsThunkResult<IRole[]> => (dispatch, getState, api) => {
    return from(api<IRole[]>(dispatch, getState, `/admin/roleGroups/roles/sync`, 'POST'))
        .pipe(
            map(({ data }) => data),
            tap((x) => dispatch(setAllUserRoles(x))),
            catchError((e) => {
                dispatch(notify(errorNotification('Error syncing roles', e)));
                return throwError(e);
            }),
        );
};
