import { createActions, handleActions } from 'redux-actions';
import { Tags, TagSizes } from '../containers/retail/priceTags/pages/Tags';
import { Locale } from '../containers/retail/priceTags/util/i18n';
import { errorNotification, notify, successNotification } from './adminNotifications';
import { apiThunk } from './store';

export const { setLocale, setTagData, setTagDataFiles} = createActions({
    SET_LOCALE: (locale) => ({locale}),
    SET_TAG_DATA: (tag, data) => ({tag, data}),
    SET_TAG_DATA_FILES: (dataFiles) => ({dataFiles}),
});

export default handleActions({
    [setLocale]: (state, {payload: {locale}}) => ({...state, locale}),
    [setTagData]: (state, {payload: {tag, data}}) => ({
        ...state,
        tagsData: {
            ...state.tagsData,
            [tag.id]: data,
        },
    }),
    [setTagDataFiles]: (state, {payload: {dataFiles}}) => ({
        ...state,
        tagDataFiles: dataFiles,
    }),
}, {
    locale: Locale.en_CA,
    tagsData: {},
    tagDataFiles: undefined,
});

export const fetchTagDataFileResources = () => async (dispatch, getStore, api: apiThunk) => {
    try {
        const {data} = await api(dispatch, getStore, '/admin/price-tags/v1/fileResources');

        await dispatch(setTagDataFiles(data));
        return data;
    } catch (e) {
        dispatch(notify(errorNotification('Error retrieving data files', e)));
    }
};

const fetchDataFile = (tag, fileUrl) => async (dispatch) => {
    try {

        const headers = new Headers();
        headers.append('pragma', 'no-cache');
        headers.append('cache-control', 'no-cache');

        const request = {
            method: 'GET',
            headers,
        };

        const products = await fetch(fileUrl, request).then(async (response) => {
            const data = await response.json();
            await dispatch(setTagData(tag, data));
            return data;
        });

        return products;
    } catch (e) {
        await dispatch(setTagData(tag, []));
        dispatch(notify(errorNotification('Error retrieving upholstery data', e)));
        throw e;
    }
};

export const updateTagFile = (tag, file, tagData) => async (dispatch, getStore, api: apiThunk) => {
    try {
        if (file) {
            const formData = new FormData();
            formData.append('file', file);

            await api(dispatch, getStore, `/admin/price-tags/v1/fileResources/${tag.id}`, 'PUT', formData);

            await dispatch(setTagData(tag, tagData));

            await dispatch(notify(successNotification(`${tag.id} tag uploaded!`)));
        }
    } catch (e) {
        dispatch(notify(errorNotification('Error saving Price Tag file!', e)));
        throw e;
    }

};

export const updateCollectionTagData = (tagId, collectionId, file, tagData) => async (dispatch, getStore, api: apiThunk) => {
    try {
        if (file) {
            const formData = new FormData();
            formData.append('file', file);

            await api(dispatch, getStore, `/admin/price-tags/v1/fileResources/${tagId}/${collectionId}`, 'PUT', formData);

            await dispatch(setTagData({id: collectionId}, tagData));

            dispatch(notify(successNotification(`${tagId}/${collectionId} tag uploaded!`)));
        }
    } catch (e) {
        dispatch(notify(errorNotification('Error saving collection file!', e)));
    }
};

export const fetchLargeTagData = () => async (dispatch, getStore, api: apiThunk) => {
    try {
        const state = getStore();

        const tagDataFiles = state.priceTags.tagDataFiles;
        if (!tagDataFiles) {
            return;
        }

        const upholsteryTagDataFiles = tagDataFiles.find(({id}) => id === Tags.upholstery.id);
        const bedTagDataFiles = tagDataFiles.find(({id}) => id === Tags.beds.id);
        const slatsTagDataFiles = tagDataFiles.find(({id}) => id === Tags.slats.id);
        const upholsteredBedsTagDataFiles = tagDataFiles.find(({id}) => id === Tags.upholsteredBeds.id);
        const customizableProductsTagDataFiles = tagDataFiles.find(({id}) => id === Tags.customizableProducts.id);
        const readyToShipTagDataFile = tagDataFiles.find(({id}) => id === Tags.readyToShip.id);

        const upholstery = await dispatch(fetchDataFile(Tags.upholstery, upholsteryTagDataFiles.fileUrl));
        (await Promise.all(upholstery.filter(({id}) => id !== Tags.sectionals.id).map(({id}) =>
            new Promise((r) => {
                r(dispatch(fetchDataFile({id}, `${upholsteryTagDataFiles.collectionsDirectory}/${id}.json`)));
            }).then((data) => ({id, data}))
                .catch((e) => {
                    dispatch(notify(errorNotification(`Error retrieving data for ${id}`, e)));
                    return [];
                }),
        ))).reduce((map, {id, data}) => {
            if (id) {
                map[id] = data;
            }
            return map;
        }, {});
        await dispatch(fetchDataFile(Tags.beds, bedTagDataFiles.fileUrl));
        await dispatch(fetchDataFile(Tags.slats, slatsTagDataFiles.fileUrl));
        await dispatch(fetchDataFile(Tags.upholsteredBeds, upholsteredBedsTagDataFiles.fileUrl));
        await dispatch(fetchDataFile(Tags.customizableProducts, customizableProductsTagDataFiles.fileUrl));
        await dispatch(fetchDataFile(Tags.readyToShip, readyToShipTagDataFile.fileUrl));
    } catch (e) {
        dispatch(notify(errorNotification('Error retrieving Large Tag data', e)));
        throw e;
    }
};

export const fetchMediumTagData = () => async (dispatch, getStore, api: apiThunk) => {
    const rugTagData = await dispatch(fetchRugTagData()); // medium has same tags as rugs

};

export const fetchRugTagData = () => async (dispatch, getStore, api: apiThunk) => {
    try {
        const state = getStore();
        const tagDataFiles = state.priceTags.tagDataFiles;
        if (!tagDataFiles) {
            return;
        }
        const data = await dispatch(fetchDataFile(Tags.rugs, tagDataFiles.find(({id}) => id === Tags.rugs.id).fileUrl));

        await dispatch(setTagData(TagSizes.rugs, data));
        return data;
    } catch (e) {
        dispatch(errorNotification('Error retrieving rug data', e));
        throw e;
    }
};

export const fetchSmallTagData = () => async (dispatch, getStore, api: apiThunk) => {
    try {
        const state = getStore();
        const tagDataFiles = state.priceTags.tagDataFiles;
        if (!tagDataFiles) {
            return;
        }
        await dispatch(fetchDataFile(Tags.bedroomCaseGoods, tagDataFiles.find(({id}) => id === Tags.bedroomCaseGoods.id).fileUrl));
        await dispatch(fetchDataFile(Tags.accessories, tagDataFiles.find(({id}) => id === Tags.accessories.id).fileUrl));
        await dispatch(fetchDataFile(Tags.lighting, tagDataFiles.find(({id}) => id === Tags.lighting.id).fileUrl));

    } catch (e) {
        dispatch(errorNotification('Error retrieving Small Tag data', e));
        throw e;
    }
};

export const fetchPillowTagData = () => async (dispatch, getStore, api: apiThunk) => {
    try {
        const state = getStore();
        const tagDataFiles = state.priceTags.tagDataFiles;
        if (!tagDataFiles) {
            return;
        }
        const data = await dispatch(fetchDataFile(Tags.pillows, tagDataFiles.find(({id}) => id === Tags.pillows.id).fileUrl));

        return data;
    } catch (e) {
        dispatch(notify(errorNotification('Error retrieving Pillow Tag data', e)));
        throw e;
    }
};
