import { connect, Form, Formik, FormikConfig, FormikContext as FormikContextType } from 'formik';
import React, { ComponentType, createContext, PropsWithChildren, useContext } from 'react';

const FormikContext = createContext<FormikContextType<any> | undefined>(undefined);

/**
 * A formik wrapper component that exposes the formik context to React's context api.
 * Custom formik components can now be created with React's useContext hook.
 */
export const FormikForm = <T extends any>(props: PropsWithChildren<FormikConfig<T> & { className?: string }>) => {
    const { children, className, ...formikProps } = props;

    return (
        <Formik {...formikProps}>
            <FormikBody className={className}>
                {children}
            </FormikBody>
        </Formik>
    );
};

const FormikBody = connect(({ className, formik, children }: PropsWithChildren<{ formik: FormikContextType<any>, className?: string }>) => {
    return (
        <FormikContext.Provider value={formik}>
            <Form className={className} noValidate>
                {children}
            </Form>
        </FormikContext.Provider>
    );
}) as ComponentType<{ className?: string }>;

/**
 * Context Hook for formik
 */
export const useFormik = <T extends any>() => useContext(FormikContext) as FormikContextType<T>;
