import {useAuth} from '@eq3/redux/auth/hooks';
import {ISseMessage, recordEvent, SseEventType} from '@eq3/redux/serverSentEvents';
import React, {PropsWithChildren, useEffect, useRef} from 'react';
import {useDispatch} from 'react-redux';
import {ThunkDispatch} from 'redux-thunk';
import {errorNotification, notify} from '@eq3/redux/adminNotifications';

const MAX_DISCONNECTS = 3;

const SseNotifier = (props: PropsWithChildren<any>) => {
    const { children } = props;

    const dispatch = useDispatch<ThunkDispatch>();
    const { loggedInUser } = useAuth();

    const eventSourceRef = useRef<EventSource>();
    const numDisconnectsRef = useRef(0);

    // Connect to SSE endpoint on-mount.
    useEffect(() => {
        eventSourceRef.current = connectToEventSource();

        return () => {
            eventSourceRef.current?.close();
        };
    }, []);

    const connectToEventSource = (): EventSource => {
        // https://developer.mozilla.org/en-US/docs/Web/API/EventSource
        const eventSource = new EventSource(
            `${process.env.API_URL}/notifications/subscribe?userId=${loggedInUser?.user?.sub}`,
            // TODO get this working with auth
            // {
            //     withCredentials: true,
            // },
        );
        eventSource.onopen = () => {
            console.log('[Event source opened]');
        };
        eventSource.onmessage = (messageEvent) => {
            const sseEvent = JSON.parse(messageEvent.data) as ISseMessage<any>;
            if (sseEvent.eventType === SseEventType.KEEP_ALIVE) {
                // These messages can be ignored.
                return;
            }
            console.log('[Event Source Message]:', sseEvent);
            dispatch(recordEvent(sseEvent));
            if (sseEvent.eventType === SseEventType.PROCESSING_FAILED) {
                if (process.env.ENVIRONMENT === 'prod') {
                    dispatch(notify(errorNotification(`Something went wrong during background processing. Please provide this correlation identifier "${sseEvent.data.correlationId}" to support for additional assistance`, undefined, 10000)));
                } else {
                    dispatch(notify(errorNotification('Something went wrong during background processing', sseEvent.data, 10000)));
                }
            }
        };
        // NOTE: This is also called on SSE timeout. The EventSource default impl will attempt to reconnect after three seconds.
        eventSource.onerror = (event) => {
            console.log('[Event Source Error]:', event);

            numDisconnectsRef.current++;

            // So we don't get stuck in an infinite loop trying to re-connect. Just give up after three failures.
            if (numDisconnectsRef.current >= MAX_DISCONNECTS) {
                console.log('Closing event source.');
                eventSource?.close();
            }
        };
        return eventSource;
    };

    return (
        <>
            {children}
        </>
    );
};


export default SseNotifier;
