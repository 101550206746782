import { CircularProgress, createStyles, PropTypes, withStyles } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import { WithStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import React from 'react';
import ActionButton from './ActionButton';

interface IProps {
    isLoading: boolean;
    disabled?: boolean;
    color?: PropTypes.Color;
}

class LoadingButton extends React.Component<WithStyles<ReturnType<typeof styles>> & ButtonProps & IProps> {

    public render() {
        const { isLoading, children, classes, disabled, color, ...props } = this.props;
        return (
            <ActionButton {...props} className={classnames(classes.loadingContainer, props.className)} disabled={isLoading || disabled} variant="contained" color={color ?? 'primary'}>
                <CircularProgress size={20} className={(isLoading ? classes.showLoading : classes.hideLoading) + ' ' + classes.smoothTransition} />
                <div className={(!isLoading ? classes.showText : classes.hideText) + ' ' + classes.smoothTransition}>{children}</div>
            </ActionButton>
        );
    }
}

const styles = () => createStyles({
    loadingContainer: {
        position: 'relative',
    },
    showLoading: {
        opacity: 1,
        position: 'absolute',
        left: 0,
        right: 0,
        margin: 'auto',
    },
    hideLoading: {
        opacity: 0,
        position: 'absolute',
    },
    showText: {
        display: 'inline-block',
    },
    hideText: {
        display: 'inline-block',
        opacity: 0,
    },
    smoothTransition: {
        transition: 'opacity 0.2s ease-in-out',
    },
});

export default withStyles(styles)(LoadingButton as any) as React.ComponentType<ButtonProps & IProps>;
