import { StyleRules, WithStyles } from '@material-ui/core/styles';
import FileUpload from '@material-ui/icons/CloudUpload';
import React from 'react';

import {connect} from 'react-redux';
import {compose} from 'redux';
import LoadingButton from '../../../../../../component/LoadingButton';
import {updateTagFile} from '../../../../../../redux/priceTags';
import LocalizedString from '../../../components/LocalizedString';

import { Button, Typography, withStyles } from '@material-ui/core';

interface IProps {
    tag: any;
    previewComponent?: (menu) => React.ReactNode;
    onTagFileLoaded?: (tagData) => void;
    showPreview?: boolean;
}

class TagUploader extends React.Component<IProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & WithStyles<ReturnType<typeof styles>>> {

    public static defaultProps: Partial<IProps> = {
        showPreview: true,
    };

    public state = {
        file: undefined,
        menu: undefined,
        isSaving: false,
    };

    private handleFileUpload = (e) => {
        const file = e.currentTarget.files[0];
        const fileReader = new FileReader();
        fileReader.onloadend = (e) => {
            const tagData = JSON.parse(e.target.result);
            const {locale, tag, onTagFileLoaded, showPreview} = this.props;

            if (showPreview) {
                this.setState({file, tagData, menu: tag.menuMapper({[tag.id]: tagData, locale})});
            } else {
                this.setState({file, tagData});
            }

            if (onTagFileLoaded) {
                onTagFileLoaded(tagData);
            }
        };
        fileReader.readAsText(file);
    }

    private uploadFile = async () => {
        const {file, tagData} = this.state;
        const {tag} = this.props;

        this.setState({isSaving: true});
        await this.props.updateTag(tag, file, tagData);
        this.setState({isSaving: false});
    }

    public componentDidUpdate(oldProps) {
        if (oldProps.tag !== this.props.tag) {
            this.setState({file: undefined, menu: undefined, isSaving: false});
        }
    }

    public render() {
        const {tag, classes, previewComponent, showPreview} = this.props;
        const {file, menu, isSaving} = this.state;

        return (
            <div>
                <Typography variant="h5" className={classes.title}>Upload <LocalizedString labelId={tag.labelId}/> .json file</Typography>
                {!file && (
                    <div className={classes.uploadButton}>
                        <Button variant="contained" color="default"
                                component="label">
                            <input type="file" accept="application/json,.json" style={{display: 'none'}}
                                onChange={this.handleFileUpload}/>
                            <span>Upload File</span>
                            <FileUpload className={classes.rightIcon}/>
                        </Button>
                    </div>
                )}
                {file && (
                    <div>
                        {menu && showPreview && previewComponent && (
                            <div>
                                <Typography variant="h6">Preview</Typography>
                                <div className={classes.preview}>
                                    {previewComponent(menu)}
                                </div>
                            </div>
                        )}

                        <div>
                            <LoadingButton isLoading={isSaving} color="primary" variant="contained" onClick={this.uploadFile}>Save</LoadingButton>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateTag: (tag, file, tagData) => dispatch(updateTagFile(tag, file, tagData)),
});

const styles = () => ({
    form: {
        width: '100%',
    },
    uploadButton: {
        display: 'flex',
        justifyContent: 'center',
    },
    title: {
        whiteSpace: 'nowrap',
    },
    preview: {
        padding: '5px',
    },
    rightIcon: {
        marginLeft: 8,
    },
}) as StyleRules;

const mapStateToProps = (state) => ({
    locale: state.priceTags.locale,
});

export default (compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(TagUploader)) as React.ComponentType<IProps>;
