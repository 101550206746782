import { IUserDetails } from '@eq3/redux/userManagement/models';
import { createActionCreator } from 'deox';

const actionCreatorFactory: typeof createActionCreator = (type, resolver) => createActionCreator(
    `[MY_PROFILE]\\${type}` as typeof type,
    resolver,
);

const actions = {
    setMyProfile: actionCreatorFactory('SET_MY_PROFILE', (resolve) => (myProfile: IUserDetails) => resolve(myProfile)),
};

export default { ...actions };
