import { errorNotification, notify } from '@eq3/redux/adminNotifications';
import {
    FinanceActionsType,
    setProcessTypes,
    setTransactionSubtypes,
    setTransactionTypes
} from '@eq3/redux/finance/actions';
import {
    IFinanceMessageDetails,
    IGeneralLedgerSearchQuery,
    IGeneralLedgerSearchSuggestion
} from '@eq3/redux/finance/models';
import { IFinanceReduxSlice } from '@eq3/redux/finance/reducers';
import { apiThunk } from '@eq3/redux/store';
import { unwrap } from '@eq3/redux/utils';
import { Pagination } from '@eq3/utils';
import { ThunkAction } from 'redux-thunk';
import { defer, iif, Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

type FinanceThunkResult<T> = ThunkAction<Observable<T>, IFinanceReduxSlice, apiThunk, FinanceActionsType>;

export const searchFinanceMessages = (
    query: Partial<IGeneralLedgerSearchQuery>,
): FinanceThunkResult<Pagination<IFinanceMessageDetails>> => (
    dispatch,
    getState,
    api,
) => {
    return defer(() => api<Pagination<IFinanceMessageDetails>>(dispatch, getState, `/admin/finance/messages`, 'GET', null, { params: query }))
        .pipe(unwrap);
};

export const getFinanceMessageAutoSuggestions = (
    searchTerm: string
): FinanceThunkResult<IGeneralLedgerSearchSuggestion[]> => (
    dispatch,
    getState,
    api,
) => {
    return defer(() => api<IGeneralLedgerSearchSuggestion[]>(dispatch, getState, '/admin/finance/messages/suggestions', 'GET', undefined, {
        params: { searchTerm },
    })).pipe(
        catchError((e) => {
            dispatch(notify(errorNotification('Error fetching suggestions ', e)));
            return throwError(e);
        }),
        unwrap
    );
};

export const getProcessTypes = (
    forceRefresh: boolean = false,
): FinanceThunkResult<string[]> => (
    dispatch,
    getState,
    api,
) => {
    const { finance: { types: { processTypes } } } = getState();

    return iif(
        () => processTypes.length === 0 || forceRefresh,
        defer(() => api<string[]>(dispatch, getState, `/admin/finance/types/process-types`, 'GET'))
            .pipe(
                unwrap,
                tap((x) => dispatch(setProcessTypes(x))),
            ),
        defer(() => of(processTypes)),
    );
};

export const getTransactionTypes = (
    forceRefresh: boolean = false,
): FinanceThunkResult<string[]> => (
    dispatch,
    getState,
    api,
) => {
    const { finance: { types: { transactionTypes } } } = getState();

    return iif(
        () => transactionTypes.length === 0 || forceRefresh,
        defer(() => api<string[]>(dispatch, getState, `/admin/finance/types/transaction-types`, 'GET'))
            .pipe(
                unwrap,
                tap((x) => dispatch(setTransactionTypes(x))),
            ),
        defer(() => of(transactionTypes))
    );
};

export const getTransactionSubtypes = (
    forceRefresh: boolean = false,
): FinanceThunkResult<string[]> => (
    dispatch,
    getState,
    api,
) => {
    const { finance: { types: { transactionSubtypes } } } = getState();

    return iif(
        () => transactionSubtypes.length === 0 || forceRefresh,
        defer(() => api<string[]>(dispatch, getState, `/admin/finance/types/transaction-subtypes`, 'GET'))
            .pipe(
                unwrap,
                tap((x) => dispatch(setTransactionSubtypes(x))),
            ),
        defer(() => of(transactionSubtypes)),
    );
};
