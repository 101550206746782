import ActionButton from '@eq3/component/ActionButton';
import { SelectField } from '@eq3/component/form/v2';
import { IAuthReduxSlice } from '@eq3/redux/auth/models/viewModels';
import { logout } from '@eq3/redux/auth/thunks';
import { useMyProfileData } from '@eq3/redux/myProfile/selectors';
import { changeWorkingLocation } from '@eq3/redux/myProfile/thunks';
import { Avatar, Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { Form, Formik, FormikActions, FormikContext } from 'formik';
import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

interface IMyProfileEditorProps extends PropsWithChildren<{}> {
    onDismiss: () => void;
}

interface IUpdateWorkingLocationFormValues {
    myCurrentLocationId: string | undefined;
}

const MyProfileEdtorFormContext = createContext<FormikContext<IUpdateWorkingLocationFormValues> | undefined>(undefined);

const useMyProfileEditorFormContext = () => {
    const context = useContext(MyProfileEdtorFormContext);

    if (!context) {
        throw new Error('MyProfileEditorFormContext not found');
    }

    return context;
};

const MyProfileEditorForm = (props: IMyProfileEditorProps) => {
    const { children, onDismiss } = props;
    const dispatch = useDispatch<ThunkDispatch>();
    const { userDetails } = useMyProfileData();

    const initialValues = useMemo(() => ({
        myCurrentLocationId: userDetails?.currentWorkingLocation?.id ?? '',
    }), [userDetails]);

    const subscription = useRef<Subscription>();

    const onSubmit = (values: IUpdateWorkingLocationFormValues, formik: FormikActions<IUpdateWorkingLocationFormValues>) => {
        subscription.current = dispatch(changeWorkingLocation(values.myCurrentLocationId))
            .pipe(finalize(() => {
                formik.setSubmitting(false);
                onDismiss();
            }))
            .subscribe();
    };

    useEffect(() => {
        return () => subscription.current?.unsubscribe();
    }, []);

    const render = useCallback((f: FormikContext<IUpdateWorkingLocationFormValues>) => (
        <Form>
            <MyProfileEdtorFormContext.Provider value={f}>
                {children}
            </MyProfileEdtorFormContext.Provider>
        </Form>
    ), [children]);

    return (
        <Formik<IUpdateWorkingLocationFormValues>
            enableReinitialize
            onSubmit={onSubmit}
            initialValues={initialValues}>
            {render}
        </Formik>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
        minWidth: '350px',
        padding: theme.spacing(3, 2),

        '& > *': {
            marginBottom: theme.spacing(3),
            '&:last-child': {
                marginBottom: theme.spacing(0),
            },
        },
    },
    title: {
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
        '& > *': {
            marginBottom: theme.spacing(1),
        },
    },
    actionsContainer: {
        marginBottom: theme.spacing(1),
    },
}));

const MyProfileEditor = () => {
    const dispatch = useDispatch<ThunkDispatch>();
    const avatarUrl = useSelector((state: IAuthReduxSlice) => state.auth.loggedInUser?.user.picture);
    const formik = useMyProfileEditorFormContext();
    const classes = useStyles();

    const { userDetails } = useMyProfileData();
    const myLocations = userDetails?.user.locations ?? [];
    const profileImageUrl = userDetails?.profile.profileImageUrl ?? avatarUrl;

    const locationOptions = useMemo(
        () => myLocations.map((l) => ({ label: l.label, value: l.value })),
        [myLocations]
    );

    const onLogout = () => dispatch(logout()).subscribe();

    const onClearWorkingLocation = () => {
        formik.setFieldValue('myCurrentLocationId', undefined);
    };

    return (
        <div className={classes.root}>
            {profileImageUrl && <Avatar src={profileImageUrl} style={{ width: 150, height: 150, alignSelf: 'center' }}/>}

            <div className={classes.title}>
                <Typography component="label" variant="body1">{userDetails?.user.email}</Typography>
                <Typography component="label" variant="body1">{userDetails?.user.name}</Typography>
            </div>
            {userDetails?.user.locations?.length
                ? (
                    <>
                        <SelectField
                            name="myCurrentLocationId"
                            label="Your location"
                            options={locationOptions}
                            data-cy="myCurrentLocationSelectBox"/>
                        <Grid container spacing={3} className={classes.actionsContainer}>
                            {userDetails?.isGrantedAllLocations && (
                                <Grid item xs={6}>
                                    <Button
                                        data-cy="user-profile-editor-clear-button"
                                        fullWidth
                                        variant="contained"
                                        color="secondary"
                                        disabled={!formik.values.myCurrentLocationId}
                                        onClick={onClearWorkingLocation}>Clear</Button>
                                </Grid>
                            )}
                            <Grid item xs={userDetails?.isGrantedAllLocations ? 6 : 12}>
                                <ActionButton
                                    fullWidth
                                    data-cy="user-profile-editor-save-button"
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    isLoading={formik.isSubmitting}
                                    disabled={formik.isSubmitting || !formik.isValid}>Save</ActionButton>
                            </Grid>
                        </Grid>
                    </>
                ) : null
            }
            <Button variant="contained" color="primary" onClick={onLogout} data-cy="sign-out-button">Sign Out</Button>
        </div>
    );
};

export default (props: IMyProfileEditorProps) => {
    return (
        <MyProfileEditorForm {...props}>
            <MyProfileEditor/>
        </MyProfileEditorForm>
    );
};
