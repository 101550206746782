import { handleError } from '@eq3/utils/exceptionUtils';
import { createActions, handleActions } from 'redux-actions';
import { notify, successNotification } from '../adminNotifications';
import { apiThunk } from '../store';
import { ThunkResult } from 'redux-thunk';

export const { setLastModified } = createActions({
    SET_LAST_MODIFIED: (date) => ({ date }),
});

export default handleActions({
    [ setLastModified ]: (state, { payload: { date }}) => ({ ...state, date }),
}, { date: '', });

export const uploadSitemap = (sitemap: File): (dispatch, getState, api: apiThunk) => Promise<void> => async (dispatch, getState, api: apiThunk) => {
    try {
        const formData = new FormData();
        // we set filename as sitemap.xml, regardless of selected name
        formData.append('file', sitemap, 'sitemap.xml');

        await api<[ string, string ]>(dispatch, getState, `/admin/sitemap/upload`, 'POST', formData);
        dispatch(notify(successNotification(`Sitemap uploaded.`)));

    } catch(e) {
        handleError(dispatch, e, 'Error uploading sitemap data');
        throw (e);
    }
};

export const fetchLastModified = (): ThunkResult<any> => async (dispatch, getState, api: apiThunk) => {
    try {
        const { data } = await api(dispatch, getState, '/admin/sitemap/lastModified', 'GET');
        await dispatch(setLastModified(data));

    } catch (e) {
        handleError(dispatch, e, 'Error getting sitemap data');
        throw e;
    }
};
