import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { setSearchParams } from './actions';
import {
    IAssociateEntityTagRequest,
    IEntity,
    IOneOrMoreArray,
    ITag,
    ITaggingState,
    ITagGroup,
    TaggingDispatch,
    TaggingSearch,
} from './models';
import { deleteGroup, fetchAllGroupsForTagSearch, fetchGroups, saveGroup } from './thunks-groups';
import {
    associateTagWithEntity,
    deleteTag,
    fetchAllAssociableTags,
    fetchTags,
    fetchTagsByEntityId,
    saveTag,
} from './thunks-tags';

export function useTaggingRedux() {
    const dispatch = useDispatch<TaggingDispatch>();

    return useMemo(() => ({
        fetchTags: () => dispatch(fetchTags()),
        saveTag: (tag: ITag) => dispatch(saveTag(tag)),
        deleteTag: (tag: ITag) => dispatch(deleteTag(tag)),
        fetchGroups: () => dispatch(fetchGroups()),
        fetchAllGroupsForTagSearch: () => dispatch(fetchAllGroupsForTagSearch()),
        saveGroup: (group: ITagGroup) => dispatch(saveGroup(group)),
        deleteGroup: (group: ITagGroup) => dispatch(deleteGroup(group)),
        setSearchParams: (params: Partial<TaggingSearch>) => dispatch(setSearchParams(params)),
        setPage: (pageNumber: number) => dispatch(setSearchParams({ pageNumber })),
        setPageSize: (pageSize: number) => dispatch(setSearchParams({ pageSize })),
        sort: (orderBy: string, orderDirection: string) => dispatch(setSearchParams({ orderBy, orderDirection })),
        fetchAllAssociableTags: (search: string) => dispatch(fetchAllAssociableTags(search)),
        fetchTagsByEntityId: (entities: IOneOrMoreArray<IEntity>) => dispatch(fetchTagsByEntityId(entities)),
        associateTagWithEntity: (request: IAssociateEntityTagRequest) => dispatch(associateTagWithEntity(request)),
    }), [dispatch]);
}

export function useActiveGroupsAsOptions() {
    const selector = useMemo(() => createSelector(
        (state: ITaggingState) => state.tagging.allGroupsList,
        (allGroups: ITagGroup[]) => [
            { label: '<no group>', value: null },
            ...allGroups.filter(({ active }) => active).map(({ id, internalName }) => ({ label: internalName, value: id })),
        ],
    ), []);

    return useSelector(selector);
}
