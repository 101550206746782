import { createReducer } from 'deox';
import Actions from './actions';
import { ICustomersState } from './models/viewModels';

const { setCustomerDetails, setPagedCustomerListItem, setCustomerDemographic, setCustomerAddresses } = Actions;

const initialState: ICustomersState = {
    customersById: {},
    pagedListItems: {
        items: [],
        page: 0,
        pageSize: 10,
        totalCount: 0,
    },
};

export default createReducer(initialState, (handler) => [
    handler(setCustomerDetails, (state, { payload }) => {
        return {
            ...state,
            customersById: {
                ...state.customersById,
                [payload.demographic.id]: payload,
            },
        };
    }),
    handler(setPagedCustomerListItem, (state, { payload }) => ({
        ...state,
        pagedListItems: payload,
    })),
    handler(setCustomerDemographic, (state, { payload: { customerId, demographic } }) => ({
        ...state,
        customersById: {
            ...state.customersById,
            [customerId]: {
                ...(state.customersById[customerId] ?? {}),
                demographic,
            },
        },
    })),
    handler(setCustomerAddresses, (state, { payload: { customerId, addresses } }) => ({
        ...state,
        customersById: {
            ...state.customersById,
            [customerId]: {
                ...(state.customersById[customerId] ?? {}),
                addresses,
            },
        },
    })),
]);
