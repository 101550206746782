import { changeWorkingLocation } from '@eq3/redux/myProfile';
import { useMyProfileData } from '@eq3/redux/myProfile/selectors';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Theme,
    Typography,
    useMediaQuery,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Subscription } from 'rxjs';

const useStyles = makeStyles((theme) => ({
    locationsList: {
        maxHeight: '212px',
        overflowY: 'auto',
        marginTop: theme.spacing(2),
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: theme.spacing(),
        '& > :nth-child(2n + 1)': {
            placeSelf: 'center start',
        },
    },
}));

const MyCurrentLocationSelectorDialog = () => {
    const classes = useStyles();
    const dispatch = useDispatch<ThunkDispatch>();
    const { userDetails , executeFetch: fetchMyProfileData } = useMyProfileData();
    const [open, setOpen] = useState(false);
    const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down(500));
    const subscription = useRef<Subscription>();

    const isLoaded = !!userDetails;
    const myLocation = userDetails?.currentWorkingLocation;

    const onOk = () => {
        if (!myLocation) {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        fetchMyProfileData(true);
    }, []);

    useEffect(() => {
        // If the user is assigned to multiple locations and does not yet have a "current working location",
        // then the user must select a current working location from his/her assigned locations.
        if (!myLocation && userDetails?.user.locations.length
            // Don't show this dialog for super users. They can use the site just fine without being forced to select a current location.
            && !userDetails?.isGrantedAllLocations) {
            setOpen(true);
        }
    }, [userDetails]);

    const setAsMyLocation = (locationId: string) => () => {
        subscription.current = dispatch(changeWorkingLocation(locationId)).subscribe();
    };

    if (!isLoaded) {
        return null;
    }

    return (
        <Dialog open={open} fullScreen={isMobile}>
            <DialogTitle>
                Welcome to the EQ3 Admin Portal, {userDetails!.user.name}!
            </DialogTitle>
            <DialogContent>
                <Typography>Please select which location you're working from today</Typography>
                {!!myLocation && (
                    <Typography>Your Location: <b>{myLocation.locationName}</b></Typography>
                )}

                <div className={classes.locationsList}>
                    {
                        userDetails!.user.locations?.map((l) => (
                            <React.Fragment key={`my-locations-${l.value}`}>
                                <label>{l.label}</label>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={setAsMyLocation(l.value)}
                                    disabled={myLocation?.id === l.value}>Set as My Location</Button>
                            </React.Fragment>
                        ))
                    }
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" disabled={!myLocation} onClick={onOk}>ok</Button>
            </DialogActions>
        </Dialog>
    );
};

export default MyCurrentLocationSelectorDialog;
