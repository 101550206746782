import OrderStatuses from '@eq3/containers/orders/OrderStatuses';
import { errorNotification, notify, successNotification } from '@eq3/redux/adminNotifications';
import { Pagination } from '@eq3/utils';
import { ThunkResult } from 'redux-thunk';
import {defer, iif, Observable, of, throwError} from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { OrderActionType, setOrder, setOrderAddress, setOrderBillingAddress, setOrderInjectionStatus, setOrders, setOrderSearchFilters } from './actions';
import {
    IAddress,
    IOrder,
    IOrderInjectionStatus,
    IOrderListing,
    IOrderQuery,
    IOrderSearchSuggestion,
    IUpdateOrder,
    OrderSearchSuggestionField,
} from './models';
import { IOrderReduxSlice } from './reducers';

type OrdersThunkResult<T> = ThunkResult<Observable<T>, IOrderReduxSlice, OrderActionType>;

export const fetchOrders = (query: IOrderQuery): OrdersThunkResult<Pagination<IOrderListing>> => (dispatch, getStore, api) => {
    Object.keys(query).forEach((key) => query[key] === undefined && query[key] === '' && delete query[key]);

    dispatch(setOrderSearchFilters(query));
    return defer(() => api<Pagination<IOrderListing>>(dispatch, getStore, '/admin/orders/', 'GET', undefined, {
        params: query,
    })).pipe(
        map(({ data }) => data),
        tap(({ items = [], totalCount = 0 }) => dispatch(setOrders(items, totalCount))),
        catchError((e) => {
            console.error(e);
            dispatch(notify(errorNotification('Error retrieving Orders', e)));
            return throwError(e);
        }),
    );
};

export const getAutoSuggestions = (field: OrderSearchSuggestionField, searchTerm: string, limit: number = 5): OrdersThunkResult<IOrderSearchSuggestion> => (dispatch, getState, api) => {
    return defer(() => api<IOrderSearchSuggestion>(dispatch, getState, '/admin/orders/search-filters/auto-suggestions', 'GET', undefined, {
        params: { field, searchTerm, limit },
    }))
    .pipe(map(({data}) => data));
};

export const fetchOrder = (id: string, refresh = false): OrdersThunkResult<IOrder> => (dispatch, getStore, api) => {
    const { orders: { data: orders } } = getStore();

    return iif(
        () => refresh || !orders[id],
        defer(() => api<IOrder>(dispatch, getStore, `/admin/orders/${id}`, 'GET')).pipe(
            map(({ data }) => data),
            tap((order) => dispatch(setOrder(order))),
            catchError((e) => {
                console.error(e);
                dispatch(notify(errorNotification('Error retrieving Order', e)));
                return throwError(e);
            }),
        ),
        of(orders[id])
    );
};

export const updateOrderShippingAddress = (id: string, values: IAddress): OrdersThunkResult<IAddress> => (dispatch, getStore, api) => {
    return defer(() => api<IAddress>(dispatch, getStore, `/admin/orders/${id}/shipping-address`, 'PUT', values)).pipe(
        map(({ data }) => data),
        tap((address) => dispatch(setOrderAddress(id, address))),
        tap(() => dispatch(notify(successNotification('Order Shipping Address saved!')))),
        catchError((e) => {
            console.error(e);
            dispatch(notify(errorNotification('Error updating Order Shipping Address', e)));
            return throwError(e);
        }),
    );
};

export const updateOrderBillingAddress = (id: string, values: IAddress): OrdersThunkResult<IAddress> => (dispatch, getStore, api) => {
    return defer(() => api<IAddress>(dispatch, getStore, `/admin/orders/${id}/billing-address`, 'PUT', values)).pipe(
        map(({ data }) => data),
        tap((address) => dispatch(setOrderBillingAddress(id, address))),
        tap(() => dispatch(notify(successNotification('Order Billing Address saved!')))),
        catchError((e) => {
            console.error(e);
            dispatch(notify(errorNotification('Error updating Order Billing Address', e)));
            return throwError(e);
        }),
    );
};

export const addNewComment = (id: string, comment: string): OrdersThunkResult<IOrder> => (dispatch, getStore, api) => {
    return defer(() => api<IOrder>(dispatch, getStore, `/admin/orders/${id}/comments`, 'POST', { comment })).pipe(
        map(({ data }) => data),
        tap((order) => {
            const { orders: { data = {}}} = getStore();

            dispatch(setOrder({
                ...data[id],
                comments: order.comments,
            }));
        }),
        tap(() => dispatch(notify(successNotification('Comment successfully added!')))),
        catchError((e) => {
            console.error(e);
            dispatch(notify(errorNotification('Error adding Order Comment', e)));
            return throwError(e);
        }),
    );  
};

export const fetchEligibleStatuses = (orderId: string): OrdersThunkResult<Record<OrderStatuses, string>> => (dispatch, getState, api) => {
    return defer(() => api<Record<OrderStatuses, string>>(dispatch, getState, `/admin/orders/${orderId}/eligible-statuses`, 'GET')).pipe(
        map(({ data }) => data),
        catchError((e) => {
            console.error(e);
            dispatch(notify(errorNotification('Error retrieving eligible order statuses', e)));
            return throwError(e);    
        }),
    );
};

export const updateOrderWithStatus = (orderId: string, update: IUpdateOrder): OrdersThunkResult<IOrder> => (dispatch, getStore, api) => {
    return defer(() => api<IOrder>(dispatch, getStore, `/admin/orders/${orderId}`, 'PUT', update)).pipe(
        map(({ data }) => data),
        tap((order) => dispatch(setOrder(order))),
        tap(() => dispatch(notify(successNotification('Order status saved!')))),
        catchError((e) => {
            console.error(e);
            dispatch(notify(errorNotification('Error updating order', e)));
            return throwError(e);
        }),
    );
};

export const submitOrderForInjection = (orderId: string): OrdersThunkResult<IOrderInjectionStatus> => (dispatch, getState, api) => {
    return defer(() => api<IOrderInjectionStatus>(dispatch, getState, `/admin/orders/${orderId}/inject`, 'PUT')).pipe(
        map(({ data }) => data),
        tap((injectionStatus) => dispatch(setOrderInjectionStatus(orderId, injectionStatus))),
        tap(() => dispatch(notify(successNotification('Order submitted for injection')))),
        catchError((e) => {
            console.error(e);
            dispatch(notify(errorNotification('Error submitting order for injection', e)));
            return throwError(e);        
        }),
    );
};

export const getOrderInjectionStatus = (orderId: string): OrdersThunkResult<IOrderInjectionStatus> => (dispatch, getState, api) => {
    return defer(() => api<IOrderInjectionStatus>(dispatch, getState, `/admin/orders/${orderId}/injection-status`, 'GET')).pipe(
        map(({ data }) => data),
        tap((injectionStatus) => dispatch(setOrderInjectionStatus(orderId, injectionStatus))),
        catchError((e) => {
            console.error(e);
            dispatch(notify(errorNotification('Error retrieving order injection status', e)));    
            return throwError(e);
        }),
    );
};
