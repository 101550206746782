import React, {ImgHTMLAttributes} from 'react';

const icons: {[key: string]: string; } = {
    cello_sectional_left: 'cello_sectional_left.svg',
    cello_sectional_right: 'cello_sectional_right.svg',
    oskar_sofa: 'oskar_sofa.svg',
    reverie_extended_seat: 'reverie_extended_seat.svg',
    reverie_extended_sofa: 'reverie_extended_sofa.svg',
    reverie_sofa: 'reverie_sofa.svg',
    salema_sectional_left: 'salema_sectional_left.svg',
    salema_sectional_right: 'salema_sectional_right.svg',
    salema_sofa: 'salema_sofa.svg',
    solo_sectional_left: 'solo_sectional_left.svg',
    solo_sectional_right: 'solo_sectional_right.svg',
    solo_sofa: 'solo_sofa.svg',
};

interface IProps {
    variant: keyof typeof icons | string;
}

const urlBase = '/images/sofas/';
export default ({variant, ...rest}: IProps & ImgHTMLAttributes<HTMLImageElement>) => {
    if (!variant || icons[variant] === undefined) {
        return <></>;
    }

    return <img {...rest} src={`${urlBase}${icons[variant]}`} />;
};
