// The Stripe JS SDK doesn't have this typed for some reason, so we'll define it here.
// https://stripe.com/docs/payments/intents
export enum StripePaymentIntentStatus {
    requires_payment_method = 'requires_payment_method',
    requires_confirmation = 'requires_confirmation',
    requires_action = 'requires_action',
    requires_capture = 'requires_capture',
    processing = 'processing',
    succeeded = 'succeeded',
    canceled = 'canceled',
}

// https://stripe.com/docs/terminal/js-api-reference#errors
export enum StripeErrorCodes {
    no_established_connection = 'no_established_connection',
    no_active_collect_payment_method_attempt = 'no_active_collect_payment_method_attempt',
    no_active_read_reusable_card_attempt = 'no_active_read_reusable_card_attempt',
    canceled = 'canceled',
    cancelable_already_completed = 'cancelable_already_completed',
    cancelable_already_canceled = 'cancelable_already_canceled',
    network_error = 'network_error',
    network_timeout = 'network_timeout',
    already_connected = 'already_connected',
    failed_fetch_connection_token = 'failed_fetch_connection_token',
    discovery_too_many_readers = 'discovery_too_many_readers',
    invalid_reader_version = 'invalid_reader_version',
    reader_error = 'reader_error',
    command_already_in_progress = 'command_already_in_progress',
}
