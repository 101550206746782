
export interface IShipmentsDictionary {    
    [orderId: string]: IShipment[];
}

export enum ShipmentStatus {
    SHIPPED = 'SHIPPED',
    PENDING = 'PENDING',
    CANCELLED = 'CANCELLED',
}

export const shipmentStatuses = [ShipmentStatus.PENDING, ShipmentStatus.SHIPPED, ShipmentStatus.CANCELLED];

export interface IShipment {
    id: string;
    shippingPartnerId: string;
    shippingPartnerName: string;
    trackingNumber: string;
    status: ShipmentStatus;
    createdTime: string;
    shippedTime: string;
    distributionWarehouse?: string;
}

export interface ICreateOrUpdateShipment {
    shippingPartnerId?: string;
    trackingNumber?: string;
    status: ShipmentStatus;
    shipmentItems: ICreateShipmentItem[];
    notifyCustomer: boolean;
    distributionWarehouse?: string;
}

export interface ICreateShipmentItem {
    orderLineItemId: string;
    sku: string;
    quantity?: number;
}

export interface IShipmentDetails {
    id: string;
    shippingPartnerId: string;
    trackingNumber: string;
    status: ShipmentStatus;
    items: IShipmentDetailsLineItem[];
    distributionWarehouse: string;
}

export interface IShipmentDetailsLineItem {
    orderLineItemId: string;
    internalName: string;
    quantity: number;
    skus: IShipmentDetailsSku[];
}

export interface IShipmentDetailsSku {
    sku: string;
    quantity: number;
}

export interface IShipmentItem {
    orderLineItemId: string;
    sku: string;
    quantity: number;
}

export interface IShipmentItemFulfillment {
    sku: string;
    optionId: string;
    internalName: string;
    quantityForShipment: number;
    leftToShip: number;
    totalShipped: number;
    totalForOrder: number;
}

export interface IShipmentProductFulfillment {
    orderLineItemId: string;
    productId: string;
    internalName: string;
    quantity: number;
    items: IShipmentItemFulfillment[];
}

export enum SearchShippingTypes {
    FREIGHT = 'FREIGHT',
    PARCEL = 'PARCEL',
}

export enum CustomerShippingTypes {
    PARCEL = 'PARCEL',
    FREIGHT = 'FREIGHT',
    FREIGHT_WHITEGLOVE = 'FREIGHT_WHITEGLOVE',
}

export const CustomerShippingTypeLabels: { [key in CustomerShippingTypes]: string } = {
    [CustomerShippingTypes.PARCEL]: 'Parcel',
    [CustomerShippingTypes.FREIGHT]: 'Freight Basic',
    [CustomerShippingTypes.FREIGHT_WHITEGLOVE]: 'Freight White Glove',
};
