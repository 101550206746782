import React from 'react';
import {Tags} from '../pages/Tags';

const ProductDroppable = ({
    onProductDropped,
    children,
    ...rest}) => {

    const handleDrop = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const product = JSON.parse(e.dataTransfer.getData('text/plain'));
        if (product) {
            if (typeof(product.tag) === 'string') {
                product.tag = Object.values(Tags).find((tag) => tag.id === product.tag);
            }
            onProductDropped(product);
        }
    };

    const handleDragOver = (e) => {
        e.stopPropagation();
        e.preventDefault();

    };

    const handleDragLeave = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };

    return (
        <div {...rest} onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop}>
            {children}
        </div>
    );
};

export default ProductDroppable;
