import { AxiosResponse } from 'axios';
import { createActions, handleActions } from 'redux-actions';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

const { updateCopyData } = createActions({
    UPDATE_COPY_DATA: (data) => data,
});

export default handleActions({
    [updateCopyData]: (state, { payload: data }) => ({ ...state, copyData: data }),
}, { copyData: { skus: [] } });

export const updateCopyDataSKUs = (data) => (dispatch) => {
    dispatch(updateCopyData({ skus: data }));
};

export const unwrap = <T extends any>(x: Observable<AxiosResponse<T>>) => x.pipe(map(({ data }) => data));

/**
 * To use this, you'll have to set the responseType of the request to 'blob', otherwise the data won't come back properly
 * @example
 * api(dispatch, getState, '[your url here]', 'GET', {...}, {
 *    responseType: 'blob',
 * })
 * @param fallbackFilename fallback filename to give the downloaded file if one cannot be found in the response headers
 */
export const downloadFile = (fallbackFilename?: string) => <T extends BlobPart>(x: Observable<AxiosResponse<T>>) => {
    return x.pipe(        
        tap((response) => {
            const { data, headers } = response;
            
            const getFileName = () => (headers['content-disposition'] as string)
                ?.split(';')
                .map((x) => x.trim())
                .find((x) => x.startsWith('filename'))
                ?.split('=')[1] ?? fallbackFilename ?? 'NO_FILE_NAME';

            const url = URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.style.display = 'none';
            link.setAttribute('download', getFileName());
            document.body.appendChild(link);
            link.click();

            setTimeout(() => {
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            }, 200);
        }),
        unwrap
    );
};