import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { IOrderComDetails } from './models';
import { IReduxSlice as IComOrdersReduxSlice } from './reducers';
import { ComOrdersDispatch, createComNumber, deleteComNumber, fetchComNumbersForOrder } from './thunks';

const selectComOrderData = createSelector(
    [
        (state: IComOrdersReduxSlice) => state.orderComData.comOrderDictionary
    ],
    (comData) => {
        return (orderId: string): IOrderComDetails | undefined => comData[orderId];
    }
);

export function useComOrderRedux() {
    const dispatch = useDispatch<ComOrdersDispatch>();
    const getComOrderData = useSelector(selectComOrderData);

    return useMemo(() => ({
        fetchComNumbersForOrder: (orderId: string) => dispatch(fetchComNumbersForOrder(orderId)),
        createComNumber: (orderId: string, comNumber: string) => dispatch(createComNumber(orderId, comNumber)),
        deleteComNumber: (orderId: string, comNumber: string) => dispatch(deleteComNumber(orderId, comNumber)),
        getComOrderData,
    }), [dispatch, getComOrderData]);
}
