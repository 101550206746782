import { ComponentsProps } from '@material-ui/core/styles/props';
import { UnfoldMore } from '@material-ui/icons';
import React from 'react';

const defaultProps: ComponentsProps = {
    // Latest designs (as of May 18, 2022) don't use box shadows for depth.
    MuiCard: {
        elevation: 0,
    },
    MuiPaper: {
        elevation: 0,
    },
    MuiTableSortLabel: {
        hideSortIcon: false,
        IconComponent: UnfoldMore,
    },
    MuiTabs: {
        TabIndicatorProps: {
            children: <span/>
        }
    },
    MuiSwitch: {
        disableFocusRipple: true,
        disableRipple: true,
    }
};

export default defaultProps;
