import { BeginRefundDto, BeginUnlinkedRefundDto, TerminalTransactionDetailsDto, UnlinkedRefundDetailsDto } from '@eq3-aws/payments-client';
import { paymentServiceClient } from '@eq3/clients/paymentService/paymentServiceClient';
import { ThunkResult } from 'redux-thunk';
import { defer, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { errorNotification, notify } from '@eq3/redux/adminNotifications';

type RefundThunkResult<T> = ThunkResult<Observable<T>>;

export const createUnlinkedRefund = (refundData: BeginUnlinkedRefundDto): RefundThunkResult<UnlinkedRefundDetailsDto> => (dispatch, getState) => {
    return defer(() =>
        paymentServiceClient(dispatch, getState)
            .refundController
            .createUnlinkedRefund(refundData)
    ).pipe(
        map((x) => x.data),
        catchError((e) => {
            dispatch(notify(errorNotification('Error creating unlinked refund.', e)));
            return throwError(e);
        }),
    );
};

export const refundCapturedPayment = (paymentIntentId: string, refundData: BeginRefundDto): RefundThunkResult<TerminalTransactionDetailsDto> => (dispatch, getState) => {
    return defer(() =>
        paymentServiceClient(dispatch, getState)
            .refundController
            .refundCapturedPayment(paymentIntentId, refundData)
    ).pipe(
        map((x) => x.data),
        catchError((e) => {
            dispatch(notify(errorNotification('Error performing refund.', e)));
            return throwError(e);
        }),
    );
};

export const completeRefund = (paymentIntentId: string, refundId: string, refundData: BeginRefundDto): RefundThunkResult<TerminalTransactionDetailsDto> => (dispatch, getState) => {
    return defer(() =>
        paymentServiceClient(dispatch, getState)
            .refundController
            .completeInPersonRefund(paymentIntentId, refundId, refundData)
    ).pipe(
        map((x) => x.data),
        catchError((e) => {
            dispatch(notify(errorNotification('Error completing refund.', e)));
            return throwError(e);
        }),
    );
};

export const getUnlinkedRefundDetails = (refundId: string): RefundThunkResult<UnlinkedRefundDetailsDto> => (dispatch, getState) => {
    return defer(() =>
        paymentServiceClient(dispatch, getState)
            .refundController
            .getUnlinkedRefund(refundId)
    ).pipe(
        map((x) => x.data),
        catchError((e) => {
            dispatch(notify(errorNotification('Error fetching unlinked refund details.', e)));
            return throwError(e);
        }),
    );
};
