import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import React, { ComponentProps, createContext, PropsWithChildren, useContext, useMemo } from 'react';

type Context = { classKey: 'input-action-group', isGrouped: boolean };
const InputActionGroupContext = createContext<Context>({ classKey: 'input-action-group', isGrouped: false });
export const useInputActionGroupContext = () => useContext(InputActionGroupContext);

interface IInputActionGroupProps extends ComponentProps<'div'> {
    classes?: Partial<ReturnType<typeof useStyles>>;
}

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexFlow: 'row wrap',
        '& > :nth-child(1n)': {
            borderLeft: '1px solid #C4CDD5 !important',
        },
        '& > :first-child': {
            borderRadius: '4px 0 0 4px',
        },
        '& > :last-child': {
            borderRight: '1px solid #C4CDD5 !important',
            borderRadius: '0 4px 4px 0',
        }

    }
}));

const InputActionGroup = (props: PropsWithChildren<IInputActionGroupProps>) => {
    const { children, classes: _classes, className, ...rest } = props;
    const classes = useStyles(props);
    const context = useMemo((): Context => ({
        classKey: 'input-action-group',
        isGrouped: true,
    }), []);

    return (
        <InputActionGroupContext.Provider value={context}>
            <div className={classNames(context.classKey, classes.root, className)} {...rest}>
                {children}
            </div>
        </InputActionGroupContext.Provider>
    );
};

export default InputActionGroup;
