import { setLeadTimeEstimates } from '@eq3/redux/leadTimeManagement/actions';
import { ILeadTimeEstimate } from '@eq3/redux/leadTimeManagement/models';
import { createReducer } from 'deox';

export interface ILeadTimeManagementState {
    leadTimeEstimates: ILeadTimeEstimate[];
}

export interface ILeadTimeManagementReduxSlice {
    leadTimeManagement: ILeadTimeManagementState;
}

const initialState: ILeadTimeManagementState = {
    leadTimeEstimates: [],
};

export default createReducer(initialState, (handle) => [
    handle(setLeadTimeEstimates, (state, { payload: { leadTimeEstimates } }) => {
        return ({
            ...state,
            leadTimeEstimates,
        });
    }),
]);
