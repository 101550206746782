import {IQuote} from '@eq3/redux/quotes/models';
import {createReducer} from 'deox';
import {setQuote, setCurrentQuote, closeQuote} from './actions';

export interface IQuoteReduxSlice {
    quotes: {
        currentOpen: IQuote['quoteId']
        data: Record<IQuote['quoteId'], IQuote>;
    };
}

const QuotesInitialState: IQuoteReduxSlice['quotes'] = {
    currentOpen: '',
    data: { },
};

export default createReducer(QuotesInitialState, (handle) => ([
    handle(setCurrentQuote, (state, { payload: {quoteId}}) => ({...state, currentOpen: quoteId})),
    handle(setQuote, (state, { payload: {quote}}) => ({...state, data: {...state.data, [quote.quoteId]: {estimates: state.data[quote.quoteId]?.estimates, ...quote}}})),
    handle(closeQuote, (state, { payload: {quoteId}}) => {
        return ({
            ...state,
            data: Object.entries(state.data)
                .filter(([key]) => key !== quoteId)
                .reduce((acc, [key, value]) => ({
                    ...acc,
                    [key]: value,
                }), {}),
            currentOpen: quoteId === state.currentOpen ? Object.keys(state.data)[0] : state.currentOpen,
        });
    }),
]));
