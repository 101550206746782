import { ButtonBase, ButtonBaseProps, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import React, {FC, forwardRef, memo, ReactElement, RefObject} from 'react';
import { useInputActionGroupContext } from '../inputs/InputActionGroup';
import Texxt from '../Texxt';
import { compose } from 'redux';
import eq3Colours from '@eq3/design/theme/colours';

export interface IButtonProps extends ButtonBaseProps, React.RefAttributes<HTMLButtonElement> {
    variant?: 'primary' | 'destructive';
    wire?: boolean;
    size?: 'small' | 'medium' | 'large';
    classes?: { [key in ButtonClassKey]?: string };
    iconStart?: ReactElement;
    iconEnd?: ReactElement;
}

export type ButtonClassKey = keyof ReturnType<typeof useStyles>;

const useStyles = makeStyles((theme) => {
    const primaryColor = theme.palette.primary.main;
    const primaryDarkColor = theme.palette.secondary.main;
    const primaryContrastColor = theme.palette.primary.contrastText;

    const destructiveColor = eq3Colours.destructive;
    const destructiveDarkColor = eq3Colours.destructiveDarker;

    return {
        root: ({wire}: IButtonProps) => ({
            borderRadius: '12px',
            boxSizing: 'border-box',
            minWidth: '100px',
            backgroundColor: primaryColor,
            '&:hover': {
                backgroundColor: primaryDarkColor,
            },
            '& *': {
                color: primaryContrastColor,
            },
            '&.disabled': {
                opacity: .35,
            },

            ...(wire ? {
                backgroundColor: 'transparent',
                '&:hover': {
                    backgroundColor: primaryColor,
                    '& *': {
                        color: primaryContrastColor,
                    },
                },
                '& *': {
                    color: primaryColor,
                },
            } : {})
        }),
        small: {
            minWidth: 'min-content',
            height: '36px',
            padding: theme.spacing(),
        },
        medium: {
            minWidth: '100px',
            height: '44px',
            padding: `12px ${theme.spacing(3)}px`,
        },
        large: {
            minWidth: '100px',
            height: '86px',
            padding: theme.spacing(2, 3),
        },
        outline: ({variant}: IButtonProps) => ({
            border: `1.75px solid ${variant === 'destructive' ? destructiveColor : primaryColor}`,
        }),
        destructive: ({wire}: IButtonProps) => ({
            backgroundColor: destructiveColor,
            '& *': {
                color: primaryContrastColor,
            },
            '&:hover': {
                backgroundColor: destructiveDarkColor,
            },
            ...(wire ? {
                backgroundColor: 'transparent',
                '&:hover': {
                    backgroundColor: destructiveColor,
                },
                '& *': {
                    color: destructiveColor,
                },
            } : {})
        }),
        grouped: {
            borderRadius: 0,
            borderLeft: 'none',
            borderRight: 'none',
        },
        iconContainer: {
            width: theme.spacing(2),
            height: theme.spacing(2),
            padding: theme.spacing(.25),
            '&>svg': {
                width: 'unset',
                height: 'unset',
            },
        },
        iconStart: {
            marginRight: theme.spacing(1),
        },
        iconEnd: {
            marginLeft: theme.spacing(1),
        }
    };
});

const Button = compose<FC<IButtonProps>>(
    memo,
    forwardRef,
)((props: IButtonProps, ref: RefObject<HTMLButtonElement>) => {
    const {
        children,
        className,
        size = 'medium',
        variant = 'primary',
        disableRipple = true,
        disabled = false,
        classes: _classes,
        wire = false,
        iconStart,
        iconEnd,
        ...rest
    } = props;

    const { isGrouped } = useInputActionGroupContext();
    const classes = useStyles(props);

    return (
        <ButtonBase ref={ref}
            className={classNames(classes.root, className, {
                [classes.small]: size === 'small',
                [classes.medium]: size === 'medium',
                [classes.large]: size === 'large',
                [classes.outline]: wire,
                [classes.destructive]: variant === 'destructive',
                [classes.grouped]: isGrouped,
                disabled,
            })}
            disableRipple={disableRipple}
            disabled={disabled}
            {...rest}>
            {iconStart && <div className={classNames(classes.iconContainer, classes.iconStart)}>{iconStart}</div>}
            {
                (typeof children === 'string' || (Array.isArray(children) && children.every(x => typeof x === 'string')))
                    ? <Texxt align="center" noWrap normalized={false} variant="inputAndButton">{children}</Texxt>
                    : children
            }
            {iconEnd && <div className={classNames(classes.iconContainer, classes.iconEnd)}>{iconEnd}</div>}
        </ButtonBase>
    );
});

export default Button;
