import moment from 'moment';
import { createActions, handleActions } from 'redux-actions';
import { errorNotification, notify } from '../adminNotifications';
import { apiThunk } from '../store';
import { IReconciliationReportState } from './models';

const { setDailySalesReportLoading, setDailySalesReport, setPromoCodePerformanceReportLoading, setPromoCodePerformanceReport, setReconciliationReport, setReconciliationReportLoading, setZucoraSalesReport } = createActions({
    SET_DAILY_SALES_REPORT_LOADING: () => null,
    SET_DAILY_SALES_REPORT: (list) => list,

    SET_PROMO_CODE_PERFORMANCE_REPORT_LOADING: () => null,
    SET_PROMO_CODE_PERFORMANCE_REPORT: (list) => list,

    SET_RECONCILIATION_REPORT_LOADING: () => null,
    SET_RECONCILIATION_REPORT: (list) => list,

    SET_ZUCORA_SALES_REPORT: (list) => list,
});

export default handleActions({
    [setDailySalesReportLoading]: (state) => ({ ...state, list: [], loading: true }),
    [setDailySalesReport]: (state, { payload: list }) => ({ ...state, list, loading: false }),

    [setPromoCodePerformanceReportLoading]: (state) => ({ ...state, list: [], loading: true}),
    [setPromoCodePerformanceReport]: (state, { payload: list }) => ({ ...state, list, loading: false }),

    [setReconciliationReportLoading]: (state) => ({ ...state, loading: true }),
    [setReconciliationReport]: (state, { payload }) => ({ ...state, ...payload}),

    [setZucoraSalesReport]: (state, { payload: list }) => ({ ...state, list }),
}, {
    loading: false,
    list: [],
    stripeReconciliationReport: {
        reportDate: undefined,
        transactions: [],
    }
});

export const fetchDailySales = (date) => async (dispatch, getStore, api: apiThunk) => {
    try {
        dispatch(setDailySalesReportLoading());
        const {data} = await api(dispatch, getStore, `/admin/reporting/dailysales/${date}`, 'GET', undefined);
        dispatch(setDailySalesReport(data));
    } catch (e) {
        dispatch(notify(errorNotification('Error getting Daily Written Sales', e)));
        throw e;
    }
};

export const fetchPromoCodePerformance = (promoCode, startDate, endDate) => async (dispatch, getStore, api: apiThunk) => {
    try {
        dispatch(setPromoCodePerformanceReportLoading());

        const {data} = await api(dispatch, getStore, `/admin/reporting/promo-code-performance/${encodeURIComponent(promoCode)}`, 'GET', undefined, {
            params: {
                startDate,
                endDate: (endDate || undefined),
            },
        });
        dispatch(setPromoCodePerformanceReport(data));
    } catch (e) {
        dispatch(notify(errorNotification('Error getting Promo Code Performance Report', e)));
        throw e;
    }
};

export const fetchReconciliationReport = (reportDate: string) => async (dispatch, getStore, api: apiThunk) => {

    const startTime = moment(reportDate).startOf('day').unix() * 1000;
    const endTime = moment(reportDate).endOf('day').unix() * 1000;

    const params = { startTime, endTime };

    try {
        const { data } = await api<IReconciliationReportState>(dispatch, getStore, `/admin/reporting/daily-reconciliation-report`, 'GET', undefined, { params });
        dispatch(setReconciliationReport({
            stripeReconciliationReport: {
                reportDate,
                transactions: data,
            },
        }));
    } catch (e) {
        dispatch(notify(errorNotification('Error fetching reconciliation report', e)));
        throw e;
    }
};

export const fetchReportCsv = (startTimeEpoch: number, endTimeEpoch: number) => async (dispatch, getStore, api: apiThunk): Promise<Blob> => {

    const params = { startTime: startTimeEpoch, endTime: endTimeEpoch };

    try {
        const { data } = await api(dispatch, getStore, `/admin/reporting/export-reconciliation-report`, 'GET', undefined, { params });
        return new Blob([data], {type: 'text/csv'});
    } catch (e) {
        dispatch(notify(errorNotification('Error exporting report CSV', e)));
        throw e;
    }
};

export const fetchZucoraSales = (date) => async (dispatch, getStore, api: apiThunk) => {
    try {
        dispatch(setZucoraSalesReport([]));
        const { data } = await api(dispatch, getStore, `/admin/reporting/zucora-sales/${date}`, 'GET');
        dispatch(setZucoraSalesReport(data));
    } catch (e) {
        dispatch(notify(errorNotification('Error getting Zucora Sales Reports', e)));
        throw e;
    }
};

export const exportZucoraSalesCsv = (date) => async (dispatch, getStore, api: apiThunk) => {
    try {
        const { data } = await api(dispatch, getStore, `/admin/reporting/zucora-sales/${date}/download`, 'GET');
        return new Blob([data], { type: 'text/csv' });
    } catch (e) {
        dispatch(notify(errorNotification('Error exporting Zucora Sales Reports', e)));
        throw e;
    }
};
