import { Pagination } from '@eq3/utils/pagination';
import { createActions, handleActions } from 'redux-actions';
import { errorNotification, notify, successNotification } from '../adminNotifications';
import { apiThunk } from '../store';
import { FormValues, IRedirection, RedirectionType } from './models';

export const { setRedirectionsList, setRedirectionSearchFilters } = createActions({
    SET_REDIRECTIONS_LIST: (d) => d,
    SET_REDIRECTION_SEARCH_FILTERS: (d) => d,
});

const initialDataState: Pagination<IRedirection> = {
    items: [],
    page: 0,
    pageSize: 0,
    totalCount: 0,
};

export default handleActions({
    [setRedirectionsList]: (state, { payload: data }) => ({ ...state, data }),
    [setRedirectionSearchFilters]: (state, { payload: searchFilters }) => ({ ...state, searchFilters }),
}, { data: initialDataState, searchFilters: {} });

export const fetchRedirectById = (id: string) => async (dispatch, getStore, api: apiThunk) => {
    try {
        const { data } = await api(dispatch, getStore, `admin/redirects/${id}`, 'GET');
        return data;
    } catch (e) {
        dispatch(notify(errorNotification(`Error fetching redirect ID ${id}`, e)));
        throw e;
    }
};

export const fetchRedirections = (values?) => async (dispatch, getStore, api: apiThunk) => {
    try {
        const { data } = await api(dispatch, getStore, 'admin/redirects/', 'GET', undefined, {
            params: {
                ...values,
            },
        });
        dispatch(setRedirectionsList(data));
    } catch (e) {
        dispatch(notify(errorNotification('Error getting redirections', e)));
        throw e;
    }
};

export const deleteRedirection = (id: string) => async (dispatch, getStore, api: apiThunk) => {
    try {
        await api(dispatch, getStore, `/admin/redirects/${id}`, 'DELETE', id);
        dispatch(notify(successNotification('Redirection deleted')));
        dispatch(fetchRedirections());
    } catch (e) {
        dispatch(notify(errorNotification('Error deleting redirection', e)));
        throw e;
    }
};

export const saveRedirection = (redirection: FormValues) => async (dispatch, getStore, api: apiThunk) => {
    const { id, redirectionType, srcInstanceId, destInstanceId, srcUrl, destUrl } = redirection; // pull out only necessary values to send over the wire

    const method = id ? 'PUT' : 'POST';
    const apiEndpoint = `/admin/redirects${id ? `/${id}` : ''}`;

    let requestBody = {};
    if (redirectionType === RedirectionType.PID_TO_PID) {
        requestBody = { srcInstanceId, destInstanceId };
    } else if (redirectionType === RedirectionType.URL_TO_URL) {
        requestBody = { srcUrl, destUrl };
    }

    try {
        await api(dispatch, getStore, apiEndpoint, method, requestBody);
        dispatch(notify(successNotification('Redirection saved')));
        dispatch(fetchRedirections());
    } catch (e) {
        const { message = '' } = (e && e.response && e.response.data) || {};
        dispatch(notify(errorNotification(`Error: ${message}`, e)));
        throw e;
    }
};
