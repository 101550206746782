import { searchLocations } from '@eq3/redux/locations';
import { ILocation, ILocationDetails, ILocationQuery, LocationType } from '@eq3/redux/locations/models';
import React, { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { EMPTY, forkJoin, of } from 'rxjs';
import { catchError, map, mergeMap, reduce, switchMap, tap } from 'rxjs/operators';
import useRxSubject from './useRxSubject';

const defaultQuery: Partial<ILocationQuery> = {
    isActive: true,
    pageSize: 1000,
};

const useLocations = (query: Partial<ILocationQuery> = {}) => {
    query = {
        ...defaultQuery,
        ...query,
    };

    const dispatch = useDispatch<ThunkDispatch>();
    const [locations, setLocations] = useState<ILocation[]>([]);
    const locations$ = useRxSubject<Partial<ILocationQuery>>((o) => {
        return o.pipe(
            switchMap((q) => dispatch(searchLocations(q))),
            tap(({ items }) => setLocations(items)),
            catchError((e) => {
                console.error(e);
                return EMPTY;
            })
        ).subscribe();
    });

    useEffect(() => {
        locations$.next(query);
    }, [
        query.isActive,
        query.pageIndex,
        query.pageSize,
        query.city,
        query.province,
        query.country,
        query.storeName,
        query.storeType,
    ]);

    return useMemo(() => ({
        locations,
        refresh: () => locations$.next(query),
    }), [
        locations,
        query.isActive,
        query.pageIndex,
        query.pageSize,
        query.city,
        query.province,
        query.country,
        query.storeName,
        query.storeType,
    ]);
};

export default useLocations;

const AllLocationsContext = createContext<Map<LocationType, ILocationDetails[]> | undefined>(undefined);

/**
 * @deprecated This code has been moved over to the redux side (See src/redux/locations/index.ts). We load partial
 * location details instead of the full-blown models (too much overhead/unnecessary payload size).
 */
export const useAllLocations = () => {
    const locations = useContext(AllLocationsContext);

    if (!locations) {
        throw new Error('Must use useAllLocations within a <AllLocationsContextProvider />');
    }

    return locations;
};

interface IAllLocationsContextProviderProps {
    includeInactive?: boolean;
    exclude?: LocationType[];
}

/**
 * @deprecated This code has been moved over to the redux side (See src/redux/locations/index.ts). We load partial
 * location details instead of the full-blown models (too much overhead/unnecessary payload size).
 *
 * The location data is global data. Redux can be accessed globally without wrapping your component in this context provider.
 */
export const AllLocationsContextProvider = (props: PropsWithChildren<IAllLocationsContextProviderProps>) => {
    const {
        includeInactive = false,
        exclude = [],
        children,
    } = props;

    const defaults = {
        isActive: !includeInactive,
        pageSize: 1000,
    };

    const dispatch = useDispatch<ThunkDispatch>();
    const [locations, setLocations] = useState<Map<LocationType, ILocationDetails[]>>(new Map());

    useEffect(() => {
        const subscription = of(exclude).pipe(
            mergeMap((e) => {
                return Object.values(LocationType)
                    .filter((value) => !e.includes(value));
            }),
            mergeMap((locationType) => {
                return forkJoin({
                    type: of(locationType),
                    locations: dispatch(searchLocations({ ...defaults, storeType: locationType })).pipe(map((l) => l.items)),
                });
            }),
            reduce((a, b) => {
                a.set(b.type, b.locations);
                return a;
            }, new Map<LocationType, ILocationDetails[]>()),
        ).subscribe(setLocations);

        return () => subscription.unsubscribe();
    }, [includeInactive, exclude]);

    return (
        <AllLocationsContext.Provider value={locations}>
            {children}
        </AllLocationsContext.Provider>
    );
};