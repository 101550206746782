import { makeStyles, Theme } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import React, { ComponentProps, FC, forwardRef, memo, Ref } from 'react';
import { compose } from 'redux';
import { texxtStyles } from '../theme/typography';

interface ITexxtProps<X extends keyof JSX.IntrinsicElements = 'span'> {
    component?: X;
    className?: string;
    classes?: Partial<ReturnType<typeof useStyles>>;
    variant?: Variant;
    subdued?: boolean;
    color?: ((theme: Theme) => string) | string;
    align?: 'center' | 'end' | 'justify' | 'left' | 'match-parent' | 'right' | 'start';
    weight?: CSSProperties['fontWeight'];
    normalized?: boolean;
    noWrap?: boolean;
    reversed?: boolean;
    required?: boolean;
    /**
     * Sets CSS display: 'block'.
     */
    block?: boolean;
}

export type TexxtProps<X extends keyof JSX.IntrinsicElements = 'span'> =
    ITexxtProps<X>
    & Omit<ComponentProps<X>, keyof ITexxtProps<X>>;

export type Variant = 
    'headingOne' | 
    'headingTwo' | 
    'headingThree' | 
    'label' | 
    'inputAndButton' | 
    'inputHelp' | 
    'tableHeading' | 
    'tableHeadingBold' | 
    'body' | 
    'bodyBold' | 
    'bodyBoldShort';

export type TexxtClassKey = keyof ReturnType<typeof useStyles>;
type TexxtComponent = <X extends keyof JSX.IntrinsicElements>(props: TexxtProps<X>) => JSX.Element | null;

export const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
        padding: 0,
        fontFamily: theme.typography.fontFamily,
        letterSpacing: 0,
        color: ({ subdued = false, color }: TexxtProps) => {
            return typeof color === 'string'
                ? color
                : typeof color === 'function'
                    ? color(theme)
                    : subdued
                        ? theme.palette.text.subdued
                        : theme.palette.text.primary;
        },
        textAlign: ({ align = 'left' }: TexxtProps) => align,
        'a&': {
            cursor: 'pointer',
            color: blue[500],
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
            }
        }
    },

    headingOne: {
        ...texxtStyles.headingOne,
    },
    headingTwo: {
        ...texxtStyles.headingTwo,
    },
    headingThree: {
        ...texxtStyles.headingThree,
    },
    label: {
        ...texxtStyles.label,
    },
    inputAndButton: {
        ...texxtStyles.inputAndButton,
    },
    inputHelp: {
        ...texxtStyles.inputHelp,
    },
    tableHeading: {
        ...texxtStyles.tableHeading,
    },
    tableHeadingBold: {
        ...texxtStyles.tableHeadingBold,
    },
    body: {
        ...texxtStyles.body,
    },
    bodyBold: {
        ...texxtStyles.bodyBold,
    },
    bodyBoldShort: {
        ...texxtStyles.bodyBoldShort,
    },

    noWrap: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    reversed: {
        '$root&': {
            backgroundColor: '#919EAB',
            padding: '1px 6px',
            color: theme.palette.common.white,
            borderRadius: '3px',
        }
    },
    required: {
        '&::after': {
            content: '" *"'
        }
    },
    block: {
        display: 'block',
    },
}));

const getRootComponent = (variant: Variant): React.ElementType => {
    switch (variant) {
        case 'headingOne': return 'h1';
        case 'headingTwo': return 'h2';
        case 'headingThree': return 'h3';
        case 'label': return 'h4';
        case 'tableHeading': 
        case 'tableHeadingBold': return 'h5';
        default: return 'span';
    }
};

const Texxt = compose<FC<TexxtProps>>(
    memo,
    forwardRef,
)((props: TexxtProps, ref: Ref<unknown>) => {
    const {
        component,
        variant = 'body',
        children,
        className,
        color,
        subdued,
        align,
        weight,
        normalized = true,
        noWrap = false,
        reversed = false,
        required,
        block,
        ...rest
    } = props;

    const classes = useStyles(props);

    // Wrapped in a self executing function, so we can translate old -> new variant names (see below where it's called)
    return ((variant) => {
        const RootComponent = !!component
            ? component
            : getRootComponent(variant);

        return (
            <RootComponent
                ref={ref}
                className={classNames(classes.root, className, {
                    [classes.noWrap]: noWrap,
                    [classes.reversed]: reversed,
                    [classes.required]: required,
                    [classes.block]: block,
                    [classes.headingOne]: variant === 'headingOne',
                    [classes.headingTwo]: variant === 'headingTwo',
                    [classes.headingThree]: variant === 'headingThree',
                    [classes.label]: variant === 'label',
                    [classes.inputAndButton]: variant === 'inputAndButton',
                    [classes.inputHelp]: variant === 'inputHelp',
                    [classes.tableHeading]: variant === 'tableHeading',
                    [classes.tableHeadingBold]: variant === 'tableHeadingBold',
                    [classes.body]: variant === 'body',
                    [classes.bodyBold]: variant === 'bodyBold',
                    [classes.bodyBoldShort]: variant === 'bodyBoldShort',
                })}
                {...rest}>
                {
                    /* if the given children prop is a string, normalize. css will take care of the formatting. */
                    (typeof children === 'string' && 
                        normalized && 
                        ![  // These variants should display "as-typed" - https://eq3furniture.atlassian.net/browse/POS-439
                            '', // No variant
                            'headingOne',
                            'headingTwo',
                            'headingThree',
                            'inputAndButton',
                            'inputHelp',
                            'body',
                            'bodyBold',
                            'bodyBoldShort',
                        ].includes(variant)
                    )
                        ? children.toLowerCase().trim()
                        : children
                }
            </RootComponent>
        );
    })(((variant: string): Variant => {
        // Map old -> new variant names, ultimately this should get deleted when they're all changed in code
        switch(variant) {
            case 'xl' : return 'headingOne';
            case 'l' : return 'headingTwo';
            case 'm' : return 'headingThree';
            case 'sm' : return 'label';
            case 'heading' : return 'label';
            case 'subheading' : return 'label';
            case 'caption' : return 'body';
            default: return variant as any as Variant; // Got to assume the rest are valid values
        }
    })(variant ?? ''));
});

export default Texxt as TexxtComponent;
