import { errorNotification, infoNotification, notify, successNotification } from '@eq3/redux/adminNotifications';
import { apiThunk } from '@eq3/redux/store';
import { defaultPageSize, IOrderable, IPaginatable } from '@eq3/utils/pagination';
import { AxiosError } from 'axios';
import moment from 'moment';
import qs from 'qs';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import * as Actions from './actions';
import { IFilters, IGradeTree, IQuery, IUpholsteryItem, UpholsteryItemUpdate } from './models';
import { UpholsteryItemState } from './state';
import { createChecklist, createCosts, createItems, createPrices } from './utils';

interface IState { upholsteryItems: UpholsteryItemState; }
type ThunkResult<R> = ThunkAction<R, IState, apiThunk, Actions.ActionTypes>;

const handleError = (dispatch: ThunkDispatch<IState, apiThunk, Actions.ActionTypes>) => (e: Error & AxiosError, fallbackMessage: string) => {
    const { message } = (e.response && e.response.data) || { message: fallbackMessage };
    dispatch(notify(errorNotification(message, e)));
    throw e;
};

export const fetchFilters = (): ThunkResult<Promise<void>> => async (dispatch, getState, api) => {
    try {
        const { data } = await api(dispatch, getState, '/admin/upholstery/items/filters', 'GET');
        const filters: IFilters = {
            gradeNames: Object.keys(data.gradeNames).map((key) => ({ label: data.gradeNames[key], value: key })),
            pieceCodes: Object.keys(data.pieceCodes).map((key) => ({ label: data.pieceCodes[key], value: key })),
            seriesFamilies: Object.keys(data.seriesFamilies).map((key) => ({ label: data.seriesFamilies[key], value: key })),
            seriesNames: Object.keys(data.seriesNames).map((key) => ({ label: data.seriesNames[key], value: key })),
            plcCodes: Object.keys(data.plcCodes).map((key) => ({label: data.plcCodes[key], value: key})),
            validationConditions: data.validationConditions.map(({id: value, description: label}) => ({label, value})),
        };

        dispatch(Actions.setFilters(filters));
    } catch (e) {
        handleError(dispatch)(e, 'Error fetching filters');
    }
};

export const fetchGradeTree = (): ThunkResult<Promise<void>> => async (dispatch, getState, api) => {
    try {
        const { data }: { data: IGradeTree } = await api(dispatch, getState, '/admin/upholstery/grade-groups/grade-tree', 'GET');
        dispatch(Actions.setGradeTree(data));
    } catch (e) {
        handleError(dispatch)(e, 'Error fetching upholstery item filters');
    }
};

export const fetchInvalidItems = () => async (dispatch, getState, api) => {
    try {
        const {data} = await api(dispatch, getState, '/admin/upholstery/items/invalid', 'GET');

        dispatch(Actions.setInvalidItems(data));
    } catch (e) {
        handleError(dispatch)(e, 'Error fetching invalid upholstery items');
    }
};

export const fetch = (query?: Partial<IQuery>, pagination?: IPaginatable, order?: IOrderable): ThunkResult<Promise<void>> => async (dispatch, getState, api) => {
    dispatch(Actions.setLoading(true));

    try {
        const { upholsteryItems: { query: q, order: o, pagination: p, grades: g } } = getState();

        const cleaned = Object.entries(query || q || {})
            .filter((pair) => {
                const value = pair[1];

                if (typeof value === 'boolean') { return true; }
                if (typeof value === 'string' || Array.isArray(value)) { return value.length > 0; }

                return !!value;
            })
            .reduce((a, b) => ({
                ...a,
                [b[0]]: b[1] instanceof Date ? moment(b[1]).toISOString() : b[1],
            }), {});

        const { data: { items, page, pageSize, totalCount } } = await api(dispatch, getState, '/admin/upholstery/items', 'GET', null, {
            params: {
                ...cleaned,
                ...(pagination || p || { pageSize: defaultPageSize }),
                ...(order || o || ({ orderBy: 'item', orderDirection: 'ASC' } as IOrderable)),
            },
            paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
        });

        if (Array.from(items).length === 0) {
            dispatch(notify(infoNotification('No records found!')));
        }

        dispatch(Actions.setFetchState({
            grades: {
                ...g,
                expanded: [],
                checklist: createChecklist(items, g.tree),
            },
            records: {
                data: items,
                changes: [],
            },
            query: cleaned,
            order,
            pagination: {
                pageSize,
                pageIndex: page,
                totalCount,
            },
            pricing: createPrices(items),
            costs: createCosts(items),
            items: createItems(items),
        }));

    } catch (e) {
        handleError(dispatch)(e, 'Error fetching filters');
    }

    dispatch(Actions.setLoading(false));
};

export const saveChanges = (): ThunkResult<Promise<void>> => async (dispatch, getState, api) => {
    dispatch(Actions.setLoading(true));
    try {
        const { upholsteryItems: { records: { data, changes } } } = getState();
        const reducedChanges = {} as { [itemId: string]: Partial<IUpholsteryItem> };

        changes.forEach(({ itemId, change }) => {
            if (!reducedChanges[itemId]) {
                reducedChanges[itemId] = {
                    itemId,
                    ...change,
                };
            } else {
                reducedChanges[itemId] = {
                    ...reducedChanges[itemId],
                    ...change,
                };
            }
        });

        Object.keys(reducedChanges).forEach((itemId) => {
            reducedChanges[itemId] = {
                ...data.find((x) => x.itemId === itemId),
                ...reducedChanges[itemId],
            };
        });

        const payload: UpholsteryItemUpdate[] = Object.values(reducedChanges).map((x) => ({
            itemId: x.itemId,
            disabled: x.disabled,
            activeStartDate: x.activeStartDate,
            activeEndDate: x.activeEndDate,
            gradeCodes: Object.keys(x.gradeCodes),
            prices: x.prices,
            costs: x.costs,
        } as UpholsteryItemUpdate));

        await api(dispatch, getState, '/admin/upholstery/items', 'PUT', payload);
        dispatch(fetch());
        dispatch(notify(successNotification('Saved successfully!')));
        dispatch(fetchInvalidItems());
    } catch (e) {
        handleError(dispatch)(e, 'Error savings changes...');
    }

    dispatch(Actions.setLoading(false));
};
