// The colours from https://eq3furniture.atlassian.net/browse/POS-439
const eq3Colours = {
    primary: '#5F7E58',
    primaryDarker: '#4C6546',
    main: '#333333',
    subdued: '#757575',
    destructive: '#950000',
    destructiveDarker: '#720708',
    warning: '#DEB361',
    white: '#FEFEFE',
    whiteTransparent: 'rgba(254, 254, 254, .9)',
    border: '#E4E4E4',
    background: '#E0E6DE',
    backgroundDarker: '#C7D2C4',
};

export default eq3Colours;