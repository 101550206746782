import { IRedirectProductInstance } from '../productInstances';

export interface IRedirection {
    id: string;
    redirectionType: RedirectionType;
    srcInstanceId?: string;
    srcInstanceName?: string;
    destInstanceId?: string;
    destInstanceName?: string;
    srcUrl?: string;
    destUrl?: string;
}

export type FormValues = IRedirection & {
    destProduct: IRedirectProductInstance;
    srcProduct: IRedirectProductInstance;
};

export enum RedirectionType {
    PID_TO_PID = 'PID_TO_PID',
    URL_TO_URL = 'URL_TO_URL',
}

export const RedirectionTypeOptions = Object.entries(RedirectionType).map(([value, label]) => ({label, value}));
