import { WithStyles } from '@material-ui/core/styles';
import React from 'react';

import {connect} from 'react-redux';
import {compose} from 'redux';
import {slatsWithPricingSelector} from '../../../../../../reselect/priceTags';
import LocalizedPrice from '../../../components/LocalizedPrice';
import LocalizedString from '../../../components/LocalizedString';
import Grades from '../../../Grades';
import {formatLabel} from '../../../util/i18n';
import {getPrice} from '../../../util/Prices';
import AsShownInput from './AsShownInput';
import SlatsTable from './SlatsTable';

import {Hideable} from '@eq3/containers/retail/priceTags/components/Hideable';
import { createStyles, withStyles } from '@material-ui/core';

interface IProps {
    product: any;
}
class UpholsteredBedTag extends React.Component<IProps & WithStyles<ReturnType<typeof styles>> & ReturnType<typeof mapStateToProps>> {
    public render() {

        const {classes, locale, slats, product} = this.props;

        const localizedProduct = product[locale.id];

        const renderGrades = (materialType: 'fabric' | 'leather') => {
            const grades = Object.values(Grades)
                                .filter((grade) => grade.isPatternless &&
                                    product[grade[locale.country].name] &&
                                    product[grade[locale.country].name] !== '' &&
                                    product[grade[locale.country].name] !== 'N/A' &&
                                    grade.material === materialType,
                                );

            if (!grades || grades.length === 0) {
                return;
            }

            return (
                <div>
                    <div className={classes.gradeHeader} ><LocalizedString labelId={materialType + '_grade'}/></div>
                    {grades.map((grade, i) => {
                            const label = grade.id.replace('grade', '');
                            return (
                                <div className={classes.gradeRow} key={i}>
                                    <div className={classes.gradeName}>
                                        {label}
                                    </div>
                                    <div className={classes.gradePrice}>
                                        <LocalizedPrice price={getPrice(product[grade[locale.country].name])}/>
                                    </div>
                                </div>
                            );
                        })
                    }
                    </div>
            );
        };

        return (
            <div className={classes.container}>
                <div className={classes.name}>
                    {localizedProduct.name}
                </div>
                <div className={classes.dimensions}>
                    {formatLabel('dimensions_fmt', locale.id, product.width, product.depth, product.height)}
                </div>
                <div className={classes.from}>
                    <LocalizedString labelId="from"/>
                </div>
                <div className={classes.fromPrice}  >
                    <LocalizedPrice price={localizedProduct.startingPrice}/>
                </div>
                <div className={classes.asShown}>
                    <AsShownInput locale={locale} isDescriptionConfigurable={true}/>
                </div>
                <div className={classes.gradesTable}>

                    {renderGrades('fabric')}

                    {renderGrades('leather')}

                </div>
                <div className={classes.addOns}>
                    {localizedProduct.addons}
                </div>
                <div>
                    <Hideable initialVisibility={true} iconTitle={{visible: 'Hide Slats', notVisible: 'Show Slats'}}>
                        <SlatsTable locale={locale} slats={slats} />
                    </Hideable>
                </div>
                <div className={classes.sizing}>
                    <LocalizedString labelId="available_in"/>
                    {localizedProduct.sizing}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    locale: state.priceTags.locale,
    slats: slatsWithPricingSelector(state),
});

const styles = () => createStyles({
    container: {
        height: '100%',
        '& > div:not(:first-child)': {
            marginTop: '5px',
        },
        position: 'relative',
    },
    name: {
        fontFamily: 'eq3-heavy',
        fontSize: '15pt',
        lineHeight: '19pt',
    },
    dimensions: {
        fontSize: '8pt',
    },
    from: {
        fontSize: '9pt',
    },
    fromPrice: {
        fontSize: '23pt',
    },
    addOns: {
        fontSize: '8pt',
        lineHeight: 'normal',
    },
    gradesTable: {
        display: 'grid',
        gridTemplateColumns: '45% 45%',
        columnGap: '10%',
        fontSize: '8pt',
        marginBottom: '20px',
    },
    gradeRow: {
        padding: '0 8px',
        display: 'flex',
        '& > div': {
            flex: '50%',
            justifyContent: 'space-between',
        },
    },
    gradeHeader: {
        fontFamily: 'eq3-book',
        textAlign: 'center',
    },
    gradeName: {
        fontFamily: 'eq3-book',
    },
    material: {
        fontFamily: 'eq3-book',
    },
    gradePrice: {
        textAlign: 'right',
    },
});

export default (compose(
    connect(mapStateToProps),
    withStyles(styles),
)(UpholsteredBedTag)) as any as React.ComponentType<IProps>;
