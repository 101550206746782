import { createMuiTheme } from '@material-ui/core';
import overrides from './overrides';
import palette from './palette';
import shadows from './shadows';
import spacing from './spacing';
import shape from './shape';
import typography from './typography';
import breakpoints from './breakpoints';
import defaultProps from '@eq3/design/theme/defaultProps';

export default createMuiTheme({
    breakpoints,
    palette,
    typography,
    shadows,
    spacing,
    shape,
    overrides,
    props: defaultProps,
});
