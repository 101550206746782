import { StyleRules, WithStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';

import { CircularProgress, withStyles } from '@material-ui/core';
import {compose} from 'redux';
import {fetchPillowTagData} from '../../../../redux/priceTags';
import {pillowTagDataSelector, pillowTagMenuSelector} from '../../../../reselect/priceTags';
import PillowPartialTag from './tags/pillow/PillowPartialTag';

class PillowCardPage extends React.Component<WithStyles<ReturnType<typeof styles>> & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>> {

    public componentDidMount() {
        const {pillowTagData} = this.props;
        if (!pillowTagData || !pillowTagData.menu || !pillowTagData.menu.itemTree || !pillowTagData.menu.itemTree.length) {
            this.props.fetchData();
        }
    }

    public render(): JSX.Element {

        const {classes, menu} = this.props;

        if (!menu || !menu.itemTree || !menu.itemTree.length) {
            return (
                <div className={classes.page}>
                    <CircularProgress
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                        }}
                    />
                </div>
            );
        }
        const tags = new Array(50).fill(0).map((x, i) => <PillowPartialTag key={i} menu={menu} />);

        return (
            <div className={classes.page}>
                {tags}
            </div>
        );
    }
}

const styles = (theme) => ({
    page: {
        width: '8.5in',
        height: '11in',
        padding: '.5in 0in .5in .25in',
        display: 'flex',
        flexWrap: 'wrap',
    },
}) as StyleRules;

const mapStateToProps = (state) => ({
    pillowTagData: pillowTagDataSelector(state),
    menu: pillowTagMenuSelector(state),
});
const mapDispatchToProps = (dispatch) => ({
    fetchData: () => dispatch(fetchPillowTagData()),
});

export default (compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
)(PillowCardPage));
