import {ClearButton} from '@eq3/containers/retail/priceTags/components/Buttons';
import { StyleRules, WithStyles } from '@material-ui/core/styles';
import React from 'react';
import {compose} from 'redux';
import ProductDroppable from '../../../components/ProductDroppable';
import SelectMenu from '../../../components/SelectMenu';

import { withStyles } from '@material-ui/core';

interface IProps {
    menu: any;
    initialProduct?: any;
}
class SmallPartialTag extends React.Component<IProps & WithStyles<ReturnType<typeof styles>>> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            product: props.initialProduct,
            dropIsValid: false,
        };
    }

    private handleClearTag = () => {
        this.setState({product: undefined});
    };

    private handleMenuProductSelected = (menuItemPath) => {
        this.handleProductSelected(menuItemPath[menuItemPath.length - 1].data);
    };
    private handleProductSelected = (product) => {
        if (product) {
            this.setState({
                product,
            });
        }
    }

    public render(): JSX.Element {
        const {classes, menu} = this.props;
        const {product} = this.state;

        return (
            <ProductDroppable onProductDropped={this.handleProductSelected}>
                <div className={classes.tag}>
                    {!product &&
                        <SelectMenu itemTree={menu.itemTree} onLeafNodeSelected={this.handleMenuProductSelected}/>
                    }
                    {product && (
                        <>
                            {product.tag.tagRenderer(product)}
                            <ClearButton className={classes.clearTagButton} onClick={this.handleClearTag}/>
                        </>
                    )}
                </div>
            </ProductDroppable>
        );
    }
}

const styles = () => ({
    tag: {
        width: '3.25in',
        height: '1.4375in',
        margin: '0.109375in 0.15625in',
        boxShadow: '0 0 0 1px silver',
        position: 'relative',
        '@media print': {
            boxShadow: 'unset',
        },
    },
    clearTagButton: {
        position: 'absolute',
        top: '0',
        right: '0',
        transform: 'scale(.75) translateY(-50%)',
    },
}) as StyleRules;

export default (compose(
    withStyles(styles),
)(SmallPartialTag)) as React.ComponentType<IProps>;
