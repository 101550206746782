import {
    addRoleGroup,
    deleteRoleGroupAction,
    setAllUserRoles,
    setRoleGroups,
    updateRoleGroupAction,
} from '@eq3/redux/roleGroups/actions';
import { IRole, IRoleGroup } from '@eq3/redux/roleGroups/models';
import { createReducer } from 'deox';

export interface IRoleGroupsState {
    list: IRoleGroup[];
    roles: IRole[];
}

export interface IRoleGroupsReduxSlice {
    roleGroups: IRoleGroupsState;
}

const initialState: IRoleGroupsState = {
    list: [],
    roles: [],
};

const roleGroupsSortFn = (a: IRoleGroup, b: IRoleGroup) => a.description.localeCompare(b.description);

export default createReducer(initialState, (handle) => [
    handle(setRoleGroups, (state, { payload: { roleGroups } }) => {
        return ({
            ...state,
            list: roleGroups.sort(roleGroupsSortFn),
        });
    }),
    handle(addRoleGroup, (state, { payload: { newRoleGroup } }) => {
        return ({
            ...state,
            list: [...state.list, newRoleGroup].sort(roleGroupsSortFn),
        });
    }),
    handle(updateRoleGroupAction, (state, { payload: { updatedRoleGroup } }) => {
        const updateIndex = state.list.findIndex((r) => r.id === updatedRoleGroup.id);
        const updatedList = [...state.list];
        updatedList[updateIndex] = updatedRoleGroup;
        return ({
            ...state,
            list: updatedList.sort(roleGroupsSortFn),
        });
    }),
    handle(deleteRoleGroupAction, (state, { payload: { roleGroupId } }) => {
        return ({
            ...state,
            list: state.list.filter((x) => x.id !== roleGroupId),
        });
    }),
    handle(setAllUserRoles, (state, { payload: { roles } }) => {
        return ({
            ...state,
            roles,
        });
    }),
]);
