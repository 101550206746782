import { Locale } from '@eq3/utils/locales';
import { createSelector } from 'reselect';
import {IInvalidItems, IReduxState, IUpholsteryItem} from './models';

export const gradeExpandedSelector = (itemId: string) => createSelector(
    (state: IReduxState) => state.upholsteryItems.grades.expanded,
    (expanded) => expanded.includes(itemId),
);

export const gradeCountsSelector = (itemId: string) => createSelector(
    gradesChecklistSelector(itemId),
    (checklist) => Object.keys(checklist).filter((x) => checklist[x]).length,
);

export const gradesChecklistSelector = (itemId: string) => createSelector(
    (state: IReduxState) => state.upholsteryItems.grades.checklist,
    (selection) => selection[itemId],
);

export const gradeCodesValueLookupSelector = createSelector(
    (state: IReduxState) => state.upholsteryItems.grades.tree,
    (tree) => Object.keys(tree || {}).reduce((a, group) => ({
        ...a,
        ...Object.keys(tree[group]).reduce((b, code) => ({
            ...b, 
            [code]: tree[group][code],
        }), {}),
    }), {}) as { [code: string]: string },
);

export const recordDetailSelector = createSelector(
    (state: IReduxState) => state.upholsteryItems.records.data,
    (data) => data.map((x) => ({itemId: x.itemId, itemDescription: x.itemDescription})),
);

export const getPricingSelector = (itemId: string, locale?: Locale) => createSelector(
    (state: IReduxState) => state.upholsteryItems.pricing,
    (pricing) => !!locale ? pricing[itemId][locale] : pricing[itemId],
);

export const getCostsSelector = (itemId: string, locale?: Locale) => createSelector(
    (state: IReduxState) => state.upholsteryItems.costs,
    (costs) => !!locale ? costs[itemId][locale] : costs[itemId],
);

export const getItemSelector = (itemId: string, field: keyof Pick<IUpholsteryItem, 'activeStartDate' | 'activeEndDate' | 'disabled'>) => createSelector(
    (state: IReduxState) => state.upholsteryItems.items,
    (items) => items[itemId][field],
);

const invalidConditionsToIgnoreNotification = ['IS_RELATED_TO_PRODUCT_INSTANCE'];
const invalidItemsSelector = (state) => state.upholsteryItems.invalidItems;
export const upholsteryItemsHasInvalidItemsSelector = createSelector(
    invalidItemsSelector,
    (invalidItems: IInvalidItems) => invalidItems && Object.keys(invalidItems).find((key) => invalidItems[key].countOfInvalid > 0 && !invalidConditionsToIgnoreNotification.includes(key)) !== undefined,
);
