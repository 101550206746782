export enum COMPONENT_ITEM_TYPES {
    PRODUCT_DEFINITION = 'PRODUCT_DEFINITION',
    PARTNER_ITEM = 'PARTNER_ITEM',
    UPHOLSTERY_ITEM = 'UPHOLSTERY_ITEM',
}

export type ComponentItemType = keyof typeof COMPONENT_ITEM_TYPES;

export interface IComponentItemListItem {
    itemId: string;
    internalName: string;
    type: COMPONENT_ITEM_TYPES;
}

export interface IComponentItemsQuery {
    searchTerm?: string;
    pageSize?: number;
    pageIndex?: number;
}
